import { useEffect, useContext, useState } from 'react';
import axios, { AxiosError } from 'axios';
import { API_END_POINT } from './api';
import { SignedUser, Store } from '../context/GlobalStore';

export default function useConfirmEmail(token: string): {
  newEmailAddress: string;
  errorMessage: string;
  loading: boolean;
} {
  const { globalState, setGlobalState } = useContext(Store);
  const [newEmailAddress, setNewEmailAddress] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!globalState.session?.idToken) {
      return;
    }
    setLoading(true);
    axios
      .post(
        `${API_END_POINT}/app/account/me/confirm-email`,
        { token },
        {
          headers: {
            Authorization: globalState.session?.idToken,
            'Content-Type': 'application/json',
          },
        }
      )
      .then((r) => {
        setNewEmailAddress(r.data.email);
        setGlobalState({
          ...globalState,
          signedUser: {
            ...(globalState.signedUser as SignedUser),
            account: {
              ...(globalState.signedUser as SignedUser).account,
              email: r.data.email,
            },
          },
        });
      })
      .catch((e) => {
        setErrorMessage(
          (e as AxiosError).response?.data.message ??
            'システムで問題が発生しています。しばらく経ってから再度お試しください。'
        );
      })
      .finally(() => setLoading(false));
  }, [globalState.session?.idToken]);
  return {
    newEmailAddress,
    errorMessage,
    loading,
  };
}
