/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { Divider } from '@material-ui/core';
import BusinessIcon from '@material-ui/icons/Business';
import CachedIcon from '@material-ui/icons/Cached';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import EventNoteIcon from '@material-ui/icons/EventNote';
import FlagIcon from '@material-ui/icons/Flag';
import GroupIcon from '@material-ui/icons/Group';
import HomeIcon from '@material-ui/icons/Home';
import LanguageIcon from '@material-ui/icons/Language';
import ListAltIcon from '@material-ui/icons/ListAlt';
import LowPriorityIcon from '@material-ui/icons/LowPriority';
import NotificationsIcon from '@material-ui/icons/Notifications';
import PaletteIcon from '@material-ui/icons/Palette';
import PeopleIcon from '@material-ui/icons/People';
import PermContactCalendarIcon from '@material-ui/icons/PermContactCalendar';
import PersonIcon from '@material-ui/icons/Person';
import RecentActorsIcon from '@material-ui/icons/RecentActors';
import SettingsIcon from '@material-ui/icons/Settings';
import StorefrontIcon from '@material-ui/icons/Storefront';
import TuneIcon from '@material-ui/icons/Tune';
import useReactRouter from 'use-react-router';
import useShopSetting from '../../../api/use-shop-setting';
import useWorkspaceSetting from '../../../api/use-workspace-setting';
import useCurrentWorkspace from '../../../context/use-current-workspace';
import { globalColors } from '../../../styles/globalColors';
import { useExpandedMenu } from '../hooks/use-expanded-menu';
import { sideMenuStyles } from '../styles';
import { SideMenuCategoryItem, SideMenuItem } from './SideMenuItem';

export type ShopSideMenuItemId =
  | 'shop-top'
  | 'customers'
  | 'slot-basic'
  | 'slot-special'
  | 'course-detail'
  | 'reservation-rule'
  | 'resource-working-table'
  | 'resource-group'
  | 'resource-list'
  | 'resource-pattern'
  | 'notification'
  | 'theme'
  | 'member'
  | 'custom-domain'
  | 'integrations'
  | 'basic'
  | 'insight';

type ShopSideMenuProps = {
  current: ShopSideMenuItemId;
  showMainMenuItems?: boolean;
};

export function ShopSideMenu({
  current,
  showMainMenuItems,
}: ShopSideMenuProps): JSX.Element {
  const { match } = useReactRouter<{ workspaceUid: string; shopId: string }>();
  const { workspaceUid, shopId } = match.params;
  const { shopSetting } = useShopSetting(shopId);
  const workspace = useCurrentWorkspace(workspaceUid);
  const { workspaceSetting } = useWorkspaceSetting(workspaceUid);
  const { isExpandedSidebar, expandedSidebarButton } = useExpandedMenu();

  return (
    <>
      {expandedSidebarButton}
      <div
        css={[
          sideMenuStyles.sideMenu,
          !isExpandedSidebar && sideMenuStyles.hideSidebar,
        ]}
      >
        {showMainMenuItems && (
          <>
            <SideMenuItem
              label="ホーム"
              href={`/`}
              isCurrent={false}
              sideColor={globalColors.main}
              icon={<HomeIcon />}
            />
            <SideMenuItem
              label="ワークスペース"
              href={`/a/${workspaceUid}`}
              isCurrent={false}
              sideColor={globalColors.main}
              icon={<BusinessIcon />}
            />
            <SideMenuItem
              label="店舗"
              href={`/a/${workspaceUid}/shops/${shopId}`}
              isCurrent={true}
              sideColor={globalColors.main}
              currentColor={globalColors.shop}
              icon={<StorefrontIcon />}
            />
            <Divider
              css={css`
                margin: 4px 0;
              `}
            />
          </>
        )}
        <SideMenuItem
          label="店舗TOP (カレンダー)"
          href={`/a/${workspaceUid}/shops/${shopId}`}
          isCurrent={current === 'shop-top'}
          sideColor={globalColors.shop}
          icon={<EventNoteIcon />}
        />
        {Boolean(workspaceSetting?.enableCrm) && (
          <SideMenuItem
            label="顧客管理"
            href={`/a/${workspaceUid}/shops/${shopId}/customers`}
            isCurrent={current === 'customers'}
            sideColor={globalColors.shop}
            icon={<RecentActorsIcon />}
          />
        )}
        <SideMenuItem
          label="予約枠基本設定"
          href={`/a/${workspaceUid}/shops/${shopId}/settings/slot-settings`}
          isCurrent={current === 'slot-basic'}
          sideColor={globalColors.shop}
          icon={<ListAltIcon />}
        />
        <SideMenuItem
          label="予約枠臨時設定"
          href={`/a/${workspaceUid}/shops/${shopId}/settings/special-rule`}
          isCurrent={current === 'slot-special'}
          sideColor={globalColors.shop}
          icon={<LowPriorityIcon />}
        />
        <SideMenuItem
          label="コース詳細設定"
          href={`/a/${workspaceUid}/shops/${shopId}/settings/courses`}
          isCurrent={current === 'course-detail'}
          sideColor={globalColors.shop}
          icon={<FlagIcon />}
        />
        <SideMenuItem
          label="予約一般設定"
          href={`/a/${workspaceUid}/shops/${shopId}/settings/reservation`}
          isCurrent={current === 'reservation-rule'}
          sideColor={globalColors.shop}
          icon={<TuneIcon />}
        />
        <SideMenuItem
          label="統計データ (レポート)"
          href={`/a/${workspaceUid}/shops/${shopId}/insight`}
          isCurrent={current === 'insight'}
          sideColor={globalColors.shop}
          icon={<EqualizerIcon />}
        />
        {Boolean(shopSetting?.enableResource) && (
          <>
            <SideMenuCategoryItem
              label="リソース予約"
              themeColor={globalColors.shop}
            />
            <SideMenuItem
              label="稼働表"
              href={`/a/${workspaceUid}/shops/${shopId}/settings/resource-table`}
              isCurrent={current === 'resource-working-table'}
              sideColor={globalColors.shop}
              icon={<PermContactCalendarIcon />}
            />
            <SideMenuItem
              label="リソースグループ"
              href={`/a/${workspaceUid}/shops/${shopId}/settings/resource-group`}
              isCurrent={current === 'resource-group'}
              sideColor={globalColors.shop}
              icon={<GroupIcon />}
            />
            <SideMenuItem
              label="リソース"
              href={`/a/${workspaceUid}/shops/${shopId}/settings/resource-list`}
              isCurrent={current === 'resource-list'}
              sideColor={globalColors.shop}
              icon={<PersonIcon />}
            />
            <SideMenuItem
              label="稼働パターン"
              href={`/a/${workspaceUid}/shops/${shopId}/settings/resource-pattern`}
              isCurrent={current === 'resource-pattern'}
              sideColor={globalColors.shop}
              icon={<CachedIcon />}
            />
          </>
        )}
        <SideMenuCategoryItem label="詳細設定" themeColor={globalColors.shop} />
        <SideMenuItem
          label="通知設定"
          href={`/a/${workspaceUid}/shops/${shopId}/settings/destinations`}
          isCurrent={current === 'notification'}
          sideColor={globalColors.shop}
          icon={<NotificationsIcon />}
        />
        <SideMenuItem
          label={
            <>
              デザインと
              <br />
              計測タグ設定
            </>
          }
          href={`/a/${workspaceUid}/shops/${shopId}/settings/theme`}
          isCurrent={current === 'theme'}
          sideColor={globalColors.shop}
          icon={<PaletteIcon />}
        />
        <SideMenuItem
          label="メンバー設定"
          href={`/a/${workspaceUid}/shops/${shopId}/settings/members`}
          isCurrent={current === 'member'}
          sideColor={globalColors.shop}
          icon={<PeopleIcon />}
        />
        <SideMenuItem
          label="基本設定"
          href={`/a/${workspaceUid}/shops/${shopId}/settings/basic`}
          isCurrent={current === 'basic'}
          sideColor={globalColors.shop}
          icon={<SettingsIcon />}
        />
        <SideMenuItem
          label="独自ドメイン"
          href={`/a/${workspaceUid}/shops/${shopId}/settings/custom-domain`}
          isCurrent={current === 'custom-domain'}
          sideColor={globalColors.shop}
          icon={<LanguageIcon />}
        />
        <SideMenuItem
          label="Googleカレンダー連携"
          href={`/a/${workspaceUid}/shops/${shopId}/settings/integrations`}
          isCurrent={current === 'integrations'}
          sideColor={globalColors.shop}
          icon={<EventAvailableIcon />}
        />
      </div>
    </>
  );
}
