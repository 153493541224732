import Button from '@material-ui/core/Button';
import React from 'react';
import { Link } from 'react-router-dom';
import { commonCss } from './common-css';

interface Props {
  to: string;
  label: string;
  startIcon?: React.ReactNode;
  variant?: 'text' | 'outlined' | 'contained';
}

export default function LinkButton(props: Props) {
  const { to, label, startIcon, variant } = props;
  return (
    <Link to={to} style={{ textDecoration: 'none' }}>
      <Button
        startIcon={startIcon}
        variant={variant || 'contained'}
        color="primary"
        size="medium"
      >
        {label}
      </Button>
    </Link>
  );
}
