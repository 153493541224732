import { AxiosResponse } from 'axios';
import { UpdateResourceGroupRequestData } from '../../../@interfaces/resource/apis';
import { API_END_POINT } from '../../../api/api';
import { useMutation } from '../../common/api/use-mutation';

export const useUpdateResourceGroups = (): ((
  shopId: string,
  resourceGroupId: number,
  params: UpdateResourceGroupRequestData
) => Promise<AxiosResponse<''>>) => {
  const { request } = useMutation<'', UpdateResourceGroupRequestData>();
  return (shopId, resourceGroupId, params) => {
    return request(
      'put',
      `${API_END_POINT}/app/shops/${shopId}/resource-groups/${resourceGroupId}`,
      {},
      params
    );
  };
};
