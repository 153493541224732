import axios from 'axios';
import { FormResponse } from '../core/types/reservation-form-types';
import { SelectedResource } from '../core/types/reservation-resource-types';
import { DateTimeFormat } from '../core/types/reservation-types';
import { NotificationSetting } from '../pages/shop/ReservationEditPage/NotificationInfo/types';
import { API_END_POINT } from './api';

async function reserve(
  idToken: string,
  shopId: number,
  courseId: number,
  dateTime: DateTimeFormat,
  formResponse: FormResponse,
  note: string,
  inflowSource: string,
  selectedLabelIds: number[],
  selectedResources: SelectedResource[],
  selectedCustomerId: number | undefined,
  notificationSetting: NotificationSetting
) {
  const data = {
    courseId,
    dateTime,
    formResponse,
    note,
    inflowSource,
    selectedLabelIds,
    selectedResources,
    selectedCustomerId,
    notificationSetting,
  };
  return axios.post(`${API_END_POINT}/app/shops/${shopId}/reservations`, data, {
    headers: {
      Authorization: idToken,
    },
  });
}

async function canReserve(
  idToken: string,
  shopId: number,
  courseId: number,
  dateTime: DateTimeFormat,
  excludeReservationId: number | undefined,
  formResponse: FormResponse,
  selectedResources: SelectedResource[]
) {
  const data = {
    courseId,
    dateTime,
    formResponse,
    selectedResources,
    excludeReservationId,
  };
  return axios.post(
    `${API_END_POINT}/app/shops/${shopId}/reservations-can-reserve`,
    data,
    {
      headers: {
        Authorization: idToken,
      },
    }
  );
}

async function update(
  idToken: string,
  shopId: number,
  courseId: number,
  dateTime: DateTimeFormat,
  formResponse: FormResponse,
  note: string,
  inflowSource: string,
  selectedLabelIds: number[],
  selectedResources: SelectedResource[],
  selectedCustomerId: number | undefined,
  reservationId: number
) {
  const data = {
    courseId,
    dateTime,
    formResponse,
    note,
    inflowSource,
    selectedLabelIds,
    selectedResources,
    selectedCustomerId,
  };
  return axios.put(
    `${API_END_POINT}/app/shops/${shopId}/reservations/${reservationId}`,
    data,
    {
      headers: {
        Authorization: idToken,
      },
    }
  );
}

async function remove(idToken: string, shopId: number, reservationId: number) {
  return axios.delete(
    `${API_END_POINT}/app/shops/${shopId}/reservations/${reservationId}`,
    {
      headers: {
        Authorization: idToken,
      },
    }
  );
}

async function restore(idToken: string, shopId: number, reservationId: number) {
  return axios.put(
    `${API_END_POINT}/app/shops/${shopId}/reservations/${reservationId}/restore`,
    {},
    {
      headers: {
        Authorization: idToken,
      },
    }
  );
}

async function canRestore(
  idToken: string,
  shopId: number,
  reservationId: number
) {
  return axios.get(
    `${API_END_POINT}/app/shops/${shopId}/reservations/${reservationId}/can-restore`,
    {
      headers: {
        Authorization: idToken,
      },
    }
  );
}

async function updateLabels(
  idToken: string,
  shopId: number,
  selectedLabelIds: number[],
  reservationId: number
) {
  const data = {
    selectedLabelIds,
  };
  return axios.put(
    `${API_END_POINT}/app/shops/${shopId}/reservations/${reservationId}/labels`,
    data,
    {
      headers: {
        Authorization: idToken,
      },
    }
  );
}

async function updateNote(
  idToken: string,
  shopId: number,
  note: string,
  reservationId: number
) {
  const data = {
    note,
  };
  return axios.put(
    `${API_END_POINT}/app/shops/${shopId}/reservations/${reservationId}/note`,
    data,
    {
      headers: {
        Authorization: idToken,
      },
    }
  );
}

async function updateCustomer(
  idToken: string,
  shopId: number,
  selectedCustomerId: number | undefined,
  reservationId: number
) {
  const data = {
    selectedCustomerId,
  };
  return axios.put(
    `${API_END_POINT}/app/shops/${shopId}/reservations/${reservationId}/customer`,
    data,
    {
      headers: {
        Authorization: idToken,
      },
    }
  );
}

export const ReservationAPI = {
  reserve,
  canReserve,
  update,
  remove,
  restore,
  canRestore,
  updateLabels,
  updateNote,
  updateCustomer,
};
