import axios, { AxiosResponse } from 'axios';
import { useContext, useEffect, useState } from 'react';
import { ResponseBody } from '../../../frontend-api/src/handlers/shop/shop-handler';
import { Shop } from '../@interfaces/shop';
import { Store } from '../context/GlobalStore';
import { API_END_POINT } from './api';

const cache: { [shooId: string]: Shop } = {};

export default function useShop(shopId: string) {
  const { globalState, setGlobalState } = useContext(Store);
  const [shop, setShop] = useState<Shop>(cache[shopId || '']);
  const [loading, setLoading] = useState(cache[shopId || ''] == undefined);
  const reload = () => {
    if (!globalState.session?.idToken) {
      return;
    }
    axios
      .get(`${API_END_POINT}/app/shops/${shopId}`, {
        headers: {
          Authorization: globalState.session?.idToken,
        },
      })
      .then((response: AxiosResponse<ResponseBody>) => {
        setShop(response.data.shop);
        cache[shopId] = response.data.shop;
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    reload();
  }, [shopId, globalState.session?.idToken]);
  return {
    shop,
    isLoadingShop: loading,
  };
}
