import axios, { AxiosResponse } from 'axios';
import { useContext } from 'react';
import useSWR from 'swr';
import {
  GetCustomersCountResponse,
  GetCustomersResponse,
} from '../../../@interfaces/crm/api';
import { API_END_POINT } from '../../../api/api';
import { Store } from '../../../context/GlobalStore';

const fetcher = async (
  token: string,
  workspaceUid: string,
  keywords: string[],
  limit: number,
  offset: number,
  includesDeleted?: boolean
) =>
  axios
    .get(`${API_END_POINT}/app/ws/${workspaceUid}/customers`, {
      headers: {
        Authorization: token,
      },
      params: {
        keywords,
        includesDeleted: (() => {
          if (includesDeleted === undefined) {
            return includesDeleted;
          }
          return includesDeleted ? '1' : '0';
        })(),
        limit,
        offset,
      },
    })
    .then((r: AxiosResponse<GetCustomersResponse>) => r.data);

export const useCustomers = (
  workspaceUid: string | null,
  keywords: string[],
  limit: number,
  offset: number,
  includesDeleted?: boolean
) => {
  const { globalState } = useContext(Store);
  const { data, isLoading } = useSWR(
    globalState.session?.idToken && workspaceUid
      ? [
          globalState.session.idToken,
          workspaceUid,
          keywords,
          limit,
          offset,
          includesDeleted,
        ]
      : null,
    async ([token, workspaceUid, keywords, limit, offset, includesDeleted]) =>
      fetcher(token, workspaceUid, keywords, limit, offset, includesDeleted)
  );

  return {
    data: data?.customers ?? [],
    isLoading,
  };
};
