/** @jsxImportSource @emotion/react */

import {
  Breadcrumbs,
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  Paper,
  Switch,
  TextField,
} from '@material-ui/core';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import React, { ChangeEvent, useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import useReactRouter from 'use-react-router';
import { API_END_POINT } from '../../../api/api';
import useLineMessagingChannel from '../../../api/use-line-messaging-channel';
import {
  BreadcrumbLinkItem,
  BreadcrumbTextItem,
  BreadcrumbWorkspaceItem,
} from '../../../components/breadcrumb/BreadcrumbItem';
import { commonCss } from '../../../components/common-css';
import { WorkspacePageLayout } from '../../../components/layouts/WorkspacePageLayout';
import { PageTitleAndDescription } from '../../../components/PageTitleAndDescription';
import {
  FullscreenLoading,
  Head,
  Main,
  Root,
} from '../../../components/Shared';
import Spinner from '../../../components/Spinner';
import { Store } from '../../../context/GlobalStore';
import { globalColors } from '../../../styles/globalColors';
import { helps } from '../../../utils/helps';

interface PageParams {
  workspaceUid: string;
  channelId: string | undefined;
}

type Form = {
  channelName: string;
  channelId: string;
  accessToken: string;
  enabled: boolean;
};

export default function WorkspaceLineMessagingChannelSettingEditPage(
  props: any
) {
  const { globalState } = useContext(Store);
  const { history, match } = useReactRouter<PageParams>();
  const { workspaceUid, channelId } = match.params;
  const validationContext = useForm();
  const { handleSubmit } = validationContext;
  const { lineMessagingChannel, isLoadingLineMessagingChannel } =
    useLineMessagingChannel(workspaceUid, channelId);

  const [form, setForm] = useState<Form>({
    channelName: '',
    channelId: '',
    accessToken: '',
    enabled: true,
  });
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (!lineMessagingChannel) {
      return;
    }
    setForm({
      channelName: lineMessagingChannel.channelName,
      channelId: lineMessagingChannel.channelId,
      accessToken: lineMessagingChannel.accessToken,
      enabled: lineMessagingChannel.enabled,
    });
  }, [lineMessagingChannel]);

  const handleChangeChannelId = (e: ChangeEvent<HTMLInputElement>) => {
    const channelId = e.target.value;
    setForm({ ...form, channelId });
  };

  const handleChangeAccessToken = (e: ChangeEvent<HTMLInputElement>) => {
    const accessToken = e.target.value;
    setForm({ ...form, accessToken });
  };

  const handleChangeChannelName = (e: ChangeEvent<HTMLInputElement>) => {
    const channelName = e.target.value;
    setForm({ ...form, channelName });
  };

  const handleChangeEnabled = (e: React.ChangeEvent<HTMLInputElement>) => {
    const enabled = !form.enabled;
    setForm({ ...form, enabled });
  };

  const handleClickDelete = () => {
    if (!globalState.session?.idToken || !lineMessagingChannel) {
      return;
    }
    if (
      prompt(
        'このLINE公式アカウント設定を削除するには「削除」と入力してください。'
      ) === '削除'
    ) {
      setOpenBackdrop(true);
      axios
        .delete(
          `${API_END_POINT}/app/ws/${workspaceUid}/line/messaging-channels/${channelId}`,
          {
            headers: {
              Authorization: globalState.session?.idToken,
            },
          }
        )
        .then(() => {
          enqueueSnackbar('LINE公式アカウント設定を削除しました。', {
            variant: 'success',
          });
          history.push(`/a/${workspaceUid}/settings/line`);
        })
        .catch((e) => {
          alert('削除に失敗しました。');
        })
        .finally(() => {
          setOpenBackdrop(false);
        });
    }
  };

  const handleClickCancel = () => {
    history.push(`/a/${workspaceUid}/settings/line`);
  };

  const update = () => {
    const json = {
      channelName: form.channelName,
      channelId: form.channelId,
      accessToken: form.accessToken,
      enabled: form.enabled,
    };
    setOpenBackdrop(true);
    axios
      .put(
        `${API_END_POINT}/app/ws/${workspaceUid}/line/messaging-channels/${channelId}`,
        json,
        {
          headers: {
            Authorization: globalState.session?.idToken,
          },
        }
      )
      .then(() => {
        enqueueSnackbar('LINE公式アカウント設定を保存しました。', {
          variant: 'success',
        });
        history.push(`/a/${workspaceUid}/settings/line`);
      })
      .catch((e) => {
        enqueueSnackbar('LINE公式アカウント設定が保存できませんでした。', {
          variant: 'error',
        });
      })
      .finally(() => {
        setOpenBackdrop(false);
      });
  };

  const insert = () => {
    const json = {
      channelName: form.channelName,
      channelId: form.channelId,
      accessToken: form.accessToken,
      enabled: form.enabled,
    };
    setOpenBackdrop(true);
    axios
      .post(
        `${API_END_POINT}/app/ws/${workspaceUid}/line/messaging-channels`,
        json,
        {
          headers: {
            Authorization: globalState.session?.idToken,
          },
        }
      )
      .then(() => {
        enqueueSnackbar('LINE公式アカウント設定を追加しました。', {
          variant: 'success',
        });
        history.push(`/a/${workspaceUid}/settings/line`);
      })
      .catch((e) => {
        enqueueSnackbar('LINE公式アカウント設定が追加できませんでした。', {
          variant: 'error',
        });
      })
      .finally(() => {
        setOpenBackdrop(false);
      });
  };

  const handleSubmitForm = (e: React.FormEvent) => {
    if (channelId) {
      update();
    } else {
      insert();
    }
  };

  const buildRightNavigationButtons = () => {
    return (
      <>
        <Button
          css={commonCss.button.right}
          type="button"
          variant="text"
          color="primary"
          onClick={handleClickCancel}
        >
          キャンセル
        </Button>
        <Button
          css={commonCss.button.right}
          type="submit"
          variant="contained"
          color="primary"
        >
          &nbsp;保存&nbsp;
        </Button>
      </>
    );
  };

  const buildContents = () => {
    if (isLoadingLineMessagingChannel) {
      return <Spinner loading={true} />;
    }
    return (
      <form onSubmit={handleSubmit(handleSubmitForm)}>
        <Grid container style={{ marginTop: '20px' }}>
          <Grid item xs={6}>
            <h3>
              {lineMessagingChannel
                ? `${lineMessagingChannel.channelName}の編集`
                : 'LINE公式アカウント設定の追加'}
            </h3>
          </Grid>
          <Grid item container xs={6} alignItems="center" justify="flex-end">
            {buildRightNavigationButtons()}
          </Grid>
        </Grid>
        <Paper css={commonCss.paper}>
          <FormControl component="fieldset">
            <FormControlLabel
              control={
                <Switch
                  checked={form.enabled}
                  onChange={handleChangeEnabled}
                  color="primary"
                />
              }
              label="このチャネルを有効にする"
            />
            <FormHelperText>
              無効にするとこの公式アカウントとの連携は一時的にオフになります。
            </FormHelperText>
          </FormControl>
          <TextField
            label="アカウント名"
            helperText="公式LINEアカウント名を設定します。この情報はLocaop予約の管理画面での識別にのみ使用します。"
            value={form.channelName}
            fullWidth
            required
            onChange={handleChangeChannelName}
          />
          <TextField
            label="チャネルID"
            helperText="LINE Developerコンソール > 対象の公式アカウント > チャネル基本設定 > 表示されるチャネルIDの値を設定します。例: 1234567890"
            value={form.channelId}
            fullWidth
            required
            onChange={handleChangeChannelId}
          />
          <TextField
            label="チャネルアクセストークン"
            helperText="LINE Developerコンソール > 対象の公式アカウント > Messaging API設定 > 表示されるチャネルアクセストークン（長期） の値を設定します。"
            value={form.accessToken}
            fullWidth
            required
            multiline
            onChange={handleChangeAccessToken}
          />
        </Paper>
        <Grid container style={{ marginTop: '20px' }}>
          <Grid item xs={6}>
            {channelId ? (
              <Button
                type="button"
                variant="contained"
                color="default"
                onClick={handleClickDelete}
              >
                削除する
              </Button>
            ) : null}
          </Grid>
          <Grid item container xs={6} alignItems="center" justify="flex-end">
            {buildRightNavigationButtons()}
          </Grid>
        </Grid>
      </form>
    );
  };

  const isEdit = lineMessagingChannel?.id !== undefined;
  const pageTitle = `LINE公式アカウント設定の${isEdit ? '編集' : '追加'}`;
  const description = isEdit
    ? 'LINE連携に必要なLINE公式アカウントの設定の編集を行います。'
    : 'LINE連携に必要なLINEログインの設定を行います。';

  return (
    <Root>
      <Head
        title={`ワークスペース設定 - ${
          lineMessagingChannel?.channelName || 'LINE公式アカウント設定'
        }の編集`}
      />
      <Main>
        <WorkspacePageLayout
          workspaceUid={workspaceUid}
          current="line"
          breadcrumbs={
            <Breadcrumbs aria-label="breadcrumb">
              <BreadcrumbLinkItem to={`/`}>ホーム</BreadcrumbLinkItem>
              <BreadcrumbWorkspaceItem workspaceUid={workspaceUid} />
              <BreadcrumbLinkItem to={`/a/${workspaceUid}/settings/line`}>
                LINE連携
              </BreadcrumbLinkItem>
              <BreadcrumbTextItem>{}</BreadcrumbTextItem>
            </Breadcrumbs>
          }
          helpId={helps.workspace.setting.line}
        >
          <PageTitleAndDescription
            title="ワークスペース"
            subTitle={pageTitle}
            description={description}
            themeColor={globalColors.workspace}
          />
          {buildContents()}
        </WorkspacePageLayout>
      </Main>
      <FullscreenLoading open={openBackdrop} />
    </Root>
  );
}
