import { ResourceSchedules } from '../../../@interfaces/resource/apis';
import { ResourceSchedulePattern } from '../../../@interfaces/resource/resource-schedule-pattern';
import { ReservedResource } from '../../../core/types/reservation-resource-types';
import {
  isOverlappingOfTimeRanges,
  TimeRange,
  toTimeByTimeNumber,
} from '../../../core/types/reservation-types';
import { Timeline, TimelineColumn, TimelineEvent, Timelines } from '../types';

// タイムライン表示用のモデルを作成します。
export const buildTimelines = (
  schedules: ResourceSchedules[],
  reservedResources: ReservedResource[]
): Timelines => {
  const sortedReservedResources = reservedResources.sort(
    reservedResourcesSorter
  );
  const timelines = schedules.map((schedule) =>
    buildTimeline(schedule, sortedReservedResources)
  );
  const allColumns = timelines.flatMap((timeline) => {
    return timeline.columns;
  });
  return {
    timelines,
    allColumns,
  };
};

const buildTimeline = (
  schedules: ResourceSchedules,
  reservedResources: ReservedResource[]
): Timeline => {
  const columns: TimelineColumn[] = [{ events: [] }];
  const events = reservedResources
    .map(toTimelineEvent)
    .filter(
      (event) => event.reservedResource.resourceId === schedules.resource.id
    );
  // 予約の振り分け、重複した場合は列を増やす
  for (const event of events) {
    const column = columns.find((column) => isAvailable(column, event));
    if (column) {
      column.events.push(event);
    } else {
      const newColumn: TimelineColumn = {
        events: [event],
      };
      columns.push(newColumn);
    }
  }
  return {
    schedules,
    columns,
  };
};

const toTimelineEvent = (reservedResource: ReservedResource): TimelineEvent => {
  const startTimeValue =
    reservedResource.time.hour * 60 + reservedResource.time.minute;
  const timeRange: TimeRange = {
    start: reservedResource.time,
    end: toTimeByTimeNumber(
      startTimeValue + (reservedResource.minutesRequired || 0)
    ),
  };
  return {
    reservedResource,
    timeRange,
  };
};

// 指定した列に対象のイベントが重複せずに配置可能か？
const isAvailable = (
  column: TimelineColumn,
  target: TimelineEvent
): boolean => {
  return column.events.every(
    (column) => !isOverlappingOfTimeRanges(column.timeRange, target.timeRange)
  );
};

export const buildTimelineRange = (
  schedules: ResourceSchedules[],
  patterns: ResourceSchedulePattern[]
) => {
  let min = 12;
  let max = 12;
  if (schedules.length === 0) {
    return [min, max];
  }
  for (const schedule of schedules) {
    for (const date of schedule.dates) {
      const timeRanges: TimeRange[] =
        date.schedule?.timeRanges ||
        patterns.find(
          (pattern) => pattern.id === date.resourceSchedulePatternId
        )?.schedule.timeRanges ||
        patterns.find(
          (pattern) =>
            pattern.id === schedule.resource.resourceSchedulePatternId
        )?.schedule.timeRanges ||
        [];
      for (const timeRange of timeRanges) {
        min = Math.min(min, timeRange.start.hour);
        max = Math.max(max, timeRange.end.hour + 1);
      }
    }
    // リソースのデフォルトパターンも適用する
    if (schedule.dates.length === 0) {
      const timeRanges =
        patterns.find(
          (pattern) =>
            pattern.id === schedule.resource.resourceSchedulePatternId
        )?.schedule.timeRanges || [];
      for (const timeRange of timeRanges) {
        min = Math.min(min, timeRange.start.hour);
        max = Math.max(max, timeRange.end.hour + 1);
      }
    }
  }
  return [min, max];
};

const reservedResourcesSorter = (
  r1: ReservedResource,
  r2: ReservedResource
) => {
  if (r1.resourceId !== r2.resourceId) {
    return r1.resourceId < r2.resourceId ? -1 : 1;
  }
  return r1.time.hour * 60 + r1.time.minute < r2.time.hour * 60 + r2.time.minute
    ? -1
    : 1;
};
