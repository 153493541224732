import axios from 'axios';
import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router';

type RevisionJson = {
  revision: string;
};

const checkRevision = async () => {
  const response = await axios.get<RevisionJson>(
    `/revision.json?ts=${Date.now()}`
  );
  return response.data.revision;
};

/**
 * このコンポーネントを使うと、画面遷移時にアプリのリビジョンチェックを行い
 * 最新のリビジョンがリリース済みの場合自動でリロードしてくれます。
 */
export const AutoUpdater = (): null => {
  // アプリのリビジョンチェック
  const location = useLocation();
  const revisionRef = useRef<string>();

  useEffect(() => {
    if (!location) {
      return;
    }
    void checkRevision()
      .then((newVersion) => {
        if (!newVersion) {
          return;
        } else if (revisionRef.current === undefined) {
          // 初回は何もしない
          revisionRef.current = newVersion;
        } else if (newVersion !== revisionRef.current) {
          // 2回目以降で、revisionが変わっていたらリロード
          window.location.reload();
        }
      })
      .catch((e) => {
        // エラーが発生しても何もしない
        console.error(e);
      });
  }, [location]);

  return null;
};
