/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { CustomerInfo } from '../../../../@interfaces/customer';
import { commonCss } from '../../../../components/common-css';
import { useSizeType } from '../../../../hooks/use-size-type';
import { formatPhoneNumber } from '../../../../utils/phonenumber-utils';
import { toRelativeLastRelativeDate } from '../../utils/relative-last-reservation-date';
import { styles } from './CustomerSelector';
import { LineInfo } from './LineInfo';

type CustomerSearchResultTableProps = {
  customers: CustomerInfo[];
  onSelect: (customer: CustomerInfo | undefined) => void;
  emptyLabel: React.ReactNode;
};

type ElementsKey =
  | 'name'
  | 'tel'
  | 'email'
  | 'reservationCount'
  | 'note'
  | 'action';

export const CustomerSearchResultTable = (
  props: CustomerSearchResultTableProps
): JSX.Element => {
  const { customers, onSelect, emptyLabel } = props;
  const { isSpSize } = useSizeType();

  const buildReservationCount = (customer: CustomerInfo): JSX.Element => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    const lastInfo = toRelativeLastRelativeDate(customer.lastReservationDate);
    return (
      <div
        css={css`
          display: flex;
          flex-direction: row;
          align-items: center;
        `}
      >
        <span css={styles.reservationCount}>{customer.reservationCount}</span>
        <span
          css={[
            styles.canceledReservationCount,
            customer.canceledReservationCount > 0
              ? styles.canceledReservationCountAlert
              : null,
          ]}
        >
          ({customer.canceledReservationCount})
        </span>
        {lastInfo ? (
          <span css={styles.lastReservationInfo}>{lastInfo}</span>
        ) : null}
      </div>
    );
  };

  const buildEmptyRow = (): JSX.Element => {
    if (customers.length > 0) {
      return <></>;
    }
    return (
      <TableRow>
        <TableCell colSpan={6}>{emptyLabel}</TableCell>
      </TableRow>
    );
  };

  const elements: {
    key: ElementsKey;
    headerBody: JSX.Element | string;
    rowBody: (customer: CustomerInfo) => JSX.Element;
  }[] = [
    {
      key: 'name',
      headerBody: '名前',
      rowBody: (customer: CustomerInfo) => (
        <>
          <div
            css={css`
              font-size: 10px;
            `}
          >
            {customer.nameKana}
          </div>
          <div>
            {customer.name} <LineInfo lineUser={customer} />
          </div>
        </>
      ),
    },
    {
      key: 'tel',
      headerBody: '電話番号',
      rowBody: (customer: CustomerInfo) => (
        <>{formatPhoneNumber(customer.tel)}</>
      ),
    },
    {
      key: 'email',
      headerBody: 'メールアドレス',
      rowBody: (customer: CustomerInfo) => <>{customer.email}</>,
    },
    {
      key: 'reservationCount',
      headerBody: (
        <>
          利用回数
          <br />
          (キャンセル回数)
          <br />
          最終予約日
        </>
      ),
      rowBody: (customer: CustomerInfo) => (
        <>{buildReservationCount(customer)}</>
      ),
    },
    {
      key: 'note',
      headerBody: '顧客メモ',
      rowBody: (customer: CustomerInfo) => <>{customer.note}</>,
    },
    {
      key: 'action',
      headerBody: 'アクション',
      rowBody: (customer: CustomerInfo) => (
        <Button
          variant="outlined"
          color="primary"
          onClick={() => {
            onSelect(customer);
          }}
        >
          選択
        </Button>
      ),
    },
  ];

  return (
    <>
      {!isSpSize ? (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                {elements.map((elem, index) => (
                  <TableCell key={index}>{elem.headerBody}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {customers.map((customer) => (
                <TableRow key={customer.id}>
                  {elements.map((elem, index) => (
                    <TableCell key={index} scope={'row'}>
                      {elem.rowBody(customer)}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
            {buildEmptyRow()}
          </Table>
        </TableContainer>
      ) : (
        <>
          {customers.map((customer, index) => (
            <Box
              key={index}
              mt={3}
              css={css`
                width: 100%;
              `}
            >
              <TableContainer
                component={Paper}
                css={customer.deletedAt ? commonCss.deletedRow : null}
              >
                {elements.map((elem, elemIndex) => (
                  <Table key={`elem${elemIndex}`}>
                    <TableBody>
                      <TableRow>
                        <TableCell
                          css={[
                            commonCss.noWrap,
                            commonCss.noPrint,
                            styles.tableHeaderForSp,
                          ]}
                        >
                          {elem.headerBody}
                        </TableCell>
                        <TableCell
                          scope="row"
                          css={[commonCss.noPrint, styles.tableBodyForSp]}
                        >
                          {elem.rowBody(customer)}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                ))}
              </TableContainer>
            </Box>
          ))}
          {buildEmptyRow()}
        </>
      )}
    </>
  );
};
