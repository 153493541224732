/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import {
  Box,
  Breadcrumbs,
  Button,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  Paper,
  TextField,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Alert } from '@material-ui/lab';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import React, { ChangeEvent, useContext, useEffect, useState } from 'react';
import { ColorResult, CompactPicker } from 'react-color';
import { useForm } from 'react-hook-form';
import useReactRouter from 'use-react-router';
import { API_END_POINT } from '../../../api/api';
import useWorkspaceSetting from '../../../api/use-workspace-setting';
import {
  BreadcrumbLinkItem,
  BreadcrumbTextItem,
  BreadcrumbWorkspaceItem,
} from '../../../components/breadcrumb/BreadcrumbItem';
import { commonCss } from '../../../components/common-css';
import { ImageCropperDialog } from '../../../components/ImageCropperDialog';
import { WorkspacePageLayout } from '../../../components/layouts/WorkspacePageLayout';
import { PageTitleAndDescription } from '../../../components/PageTitleAndDescription';
import { PreviewHeader } from '../../../components/PreviewHeader';
import { PreviewSelectCoursePage } from '../../../components/PreviewSelectCoursePage';
import { PreviewSelectShopPage } from '../../../components/PreviewSelectShopPage';
import {
  FullscreenLoading,
  Head,
  Main,
  Root,
} from '../../../components/Shared';
import Spinner from '../../../components/Spinner';
import { useStyles } from '../../../components/Styles';
import { Store } from '../../../context/GlobalStore';
import useCurrentWorkspace from '../../../context/use-current-workspace';
import { SM_BREAKPOINT } from '../../../hooks/use-size-type';
import {
  DEFAULT_THEME_COLOR,
  LOGO_BASE_URL,
  THEME_COLORS,
} from '../../../models/theme';
import { globalColors } from '../../../styles/globalColors';
import { convertFileToBase64 } from '../../../utils/file';
import { helps } from '../../../utils/helps';

const cssStyles = {
  uploadImage: css`
    position: relative;
    width: 150px;
    margin: 0;

    @media screen and (max-width: ${SM_BREAKPOINT}px) {
      width: 100%;
    }
  `,
  closeIcon: css`
    position: absolute;
    top: -8px;
    right: -8px;
    background: #dddddd;
    width: 32px;
    height: 32px;

    img: {
      width: 24px;
    }
  `,
};

interface PageParams {
  workspaceUid: string;
}

type Form = {
  userScriptHead: string;
  userScriptBody: string;
  logoPath: string;
  imagePath: string;
  headline1: string;
  headline2: string;
  primaryColor: string;
  logoFile: File | undefined;
  imageFile: File | undefined;
};

export default function WorkspaceThemeSettingPage(props: any) {
  const { globalState, setGlobalState } = useContext(Store);
  const { match } = useReactRouter<PageParams>();
  const { workspaceUid } = match.params;
  const validationContext = useForm();
  const { handleSubmit } = validationContext;
  const [isShowIconCropperDialog, setIsShowIconCropperDialog] =
    useState<boolean>(false);
  const [isShowLogoCropperDialog, setIsShowLogoCropperDialog] =
    useState<boolean>(false);
  const styles = useStyles();
  const workspace = useCurrentWorkspace(workspaceUid);
  const {
    workspaceSetting,
    isLoadingWorkspaceSetting,
    reloadWorkspaceSetting,
  } = useWorkspaceSetting(workspaceUid);

  const [form, setForm] = useState<Form>({
    userScriptHead: '',
    userScriptBody: '',
    logoPath: '',
    imagePath: '',
    headline1: '',
    headline2: '',
    primaryColor: '',
    logoFile: undefined,
    imageFile: undefined,
  });
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (!workspaceSetting) {
      return;
    }
    setForm({
      userScriptHead: workspaceSetting.userScriptHead,
      userScriptBody: workspaceSetting.userScriptBody,
      logoPath: workspaceSetting.logoPath,
      imagePath: workspaceSetting.imagePath,
      headline1: workspaceSetting.headline1,
      headline2: workspaceSetting.headline2,
      primaryColor: workspaceSetting.primaryColor,
      logoFile: undefined,
      imageFile: undefined,
    });
  }, [JSON.stringify(workspaceSetting)]);

  const handleChangeUserScriptHead = (e: ChangeEvent<HTMLInputElement>) => {
    const userScriptHead = e.target.value;
    setForm({ ...form, userScriptHead });
  };

  const handleChangeUserScriptBody = (e: ChangeEvent<HTMLInputElement>) => {
    const userScriptBody = e.target.value;
    setForm({ ...form, userScriptBody });
  };

  const handleChangeHeadline1 = (e: ChangeEvent<HTMLInputElement>) => {
    const headline1 = e.target.value;
    setForm({ ...form, headline1 });
  };

  const handleChangeHeadline2 = (e: ChangeEvent<HTMLInputElement>) => {
    const headline2 = e.target.value;
    setForm({ ...form, headline2 });
  };

  const handleChangePrimaryColor = (
    color: ColorResult,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const primaryColor = color.hex;
    setForm({ ...form, primaryColor });
  };

  const handleChangeLogoFile = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return;
    }
    const logoFile = Array.from(e.target.files)[0];
    setForm({ ...form, logoFile });
    setIsShowLogoCropperDialog(true);
  };

  const handleClickRemoveLogo = () => {
    const logoFile = undefined;
    setForm({
      ...form,
      logoPath: '',
      logoFile,
    });
  };

  const handleChangeImageFile = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return;
    }
    const imageFile = Array.from(e.target.files)[0];
    setForm({ ...form, imageFile });
    setIsShowIconCropperDialog(true);
  };

  const handleClickRemoveImage = () => {
    const imageFile = undefined;
    setForm({
      ...form,
      imagePath: '',
      imageFile,
    });
  };

  const encodeText = (text: string) => {
    return process.env.REACT_APP_STAGE === 'local'
      ? encodeURIComponent(text)
      : text;
  };

  const handleSubmitForm = async (e: React.FormEvent) => {
    const params = new FormData();
    const json = {
      userScriptHead: encodeText(form.userScriptHead),
      userScriptBody: encodeText(form.userScriptBody),
      logoPath: form.logoPath,
      imagePath: form.imagePath,
      headline1: encodeText(form.headline1),
      headline2: encodeText(form.headline2),
      primaryColor: form.primaryColor,
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const headers: any = {
      Authorization: globalState.session?.idToken,
    };

    let logoFileBase64: string | undefined;
    if (form.logoFile) {
      if (process.env.REACT_APP_STAGE === 'local') {
        logoFileBase64 = await convertFileToBase64(form.logoFile);
      } else {
        params.append('logoFile', form.logoFile);
      }
    }

    let imageFileBase64: string | undefined;
    if (form.imageFile) {
      if (process.env.REACT_APP_STAGE === 'local') {
        imageFileBase64 = await convertFileToBase64(form.imageFile);
      } else {
        params.append('imageFile', form.imageFile);
      }
    }

    if (logoFileBase64 && imageFileBase64) {
      params.append(
        'json',
        JSON.stringify({
          ...json,
          logoMimetype: form.logoFile?.type,
          logoFileBase64,
          imageMimetype: form.imageFile?.type,
          imageFileBase64,
        })
      );
    } else if (logoFileBase64) {
      params.append(
        'json',
        JSON.stringify({
          ...json,
          logoMimetype: form.logoFile?.type,
          logoFileBase64,
        })
      );
    } else if (imageFileBase64) {
      params.append(
        'json',
        JSON.stringify({
          ...json,
          imageMimetype: form.imageFile?.type,
          imageFileBase64,
        })
      );
    } else {
      params.append('json', JSON.stringify(json));
    }

    setOpenBackdrop(true);
    axios
      .post(`${API_END_POINT}/app/ws/${workspaceUid}/setting`, params, {
        headers,
      })
      .then(() => {
        enqueueSnackbar('設定を保存しました。', { variant: 'success' });
        reloadWorkspaceSetting();
      })
      .catch((e) => {
        enqueueSnackbar('設定が保存できませんでした。', { variant: 'error' });
      })
      .finally(() => {
        setOpenBackdrop(false);
      });
  };

  const handleCropImage = async (croppedImageSrc: string) => {
    const imageFile: File = await fetch(croppedImageSrc)
      .then(async (r) => r.blob())
      .then(
        (blobFile) =>
          new File([blobFile], form.imageFile!.name, {
            type: form.imageFile!.type || 'image/jpeg',
          })
      );

    setForm({ ...form, imageFile });
    setIsShowIconCropperDialog(false);
  };

  const handleCropLogo = async (croppedImageSrc: string) => {
    const logoFile: File = await fetch(croppedImageSrc)
      .then(async (r) => r.blob())
      .then(
        (blobFile) =>
          new File([blobFile], form.logoFile!.name, {
            type: form.logoFile!.type || 'image/jpeg',
          })
      );

    setForm({ ...form, logoFile });
    setIsShowLogoCropperDialog(false);
  };

  const getLogoPath = () => {
    if (form.logoFile) {
      return URL.createObjectURL(form.logoFile);
    } else if (form.logoPath) {
      return `${LOGO_BASE_URL}/${form.logoPath}`;
    } else {
      return;
    }
  };

  const getImagePath = () => {
    if (form.imageFile) {
      return URL.createObjectURL(form.imageFile);
    } else if (form.imagePath) {
      return `${LOGO_BASE_URL}/${form.imagePath}`;
    } else {
      return;
    }
  };

  const buildRightNavigationButtons = () => {
    return (
      <>
        <Button
          css={commonCss.button.right}
          type="submit"
          variant="contained"
          color="primary"
        >
          &nbsp;保存&nbsp;
        </Button>
      </>
    );
  };

  const buildContents = () => {
    if (isLoadingWorkspaceSetting || !workspaceSetting) {
      return <Spinner loading={true} />;
    }
    const helperText =
      'Google AnalyticsやGoogle Tag Manager等のタグ(<script>, <style>など)を設定します。この設定はWeb予約のすべての画面で有効です。';
    return (
      <form onSubmit={handleSubmit(handleSubmitForm)}>
        <Grid container style={{ marginTop: '20px' }}>
          <Grid item xs={6} />
          <Grid
            item
            container
            xs={6}
            alignItems="center"
            justifyContent="flex-end"
          >
            {buildRightNavigationButtons()}
          </Grid>
        </Grid>
        <Alert
          css={css`
            margin-top: 4px;
          `}
          severity="info"
        >
          ここで設定されたワークスペースのテーマは全ての店舗で有効になります。
          <br />
          各店舗のテーマ設定で上書きすることも可能です。
        </Alert>
        <Paper className={styles.paper}>
          <FormControl required fullWidth component="fieldset">
            <Grid container alignItems="flex-start">
              <Grid item xs={6}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <CompactPicker
                        colors={THEME_COLORS}
                        color={form.primaryColor || DEFAULT_THEME_COLOR}
                        onChangeComplete={handleChangePrimaryColor}
                      />
                    }
                    labelPlacement="top"
                    label={`テーマのカラー選択`}
                    css={css`
                      text-align: left;
                    `}
                  />
                </FormGroup>
              </Grid>
            </Grid>
            <Grid
              container
              alignItems="flex-start"
              css={css`
                margin-top: 24px;
              `}
            >
              <Grid item xs={12} sm={6}>
                <p
                  css={css`
                    display: block;
                    text-align: left;
                    margin: 0 0 24px 0;
                  `}
                >
                  ロゴ画像の設定{' '}
                  <span
                    css={css`
                      font-size: 14px;
                    `}
                  >
                    推奨サイズ:240×160px
                  </span>
                </p>
                {form.logoFile || form.logoPath ? (
                  <>
                    <Box css={cssStyles.uploadImage}>
                      <img src={getLogoPath()} width="100%" alt="" />
                      <IconButton
                        css={cssStyles.closeIcon}
                        onClick={handleClickRemoveLogo}
                      >
                        <CloseIcon />
                      </IconButton>
                    </Box>
                  </>
                ) : (
                  <>
                    <Button
                      variant="contained"
                      component="label"
                      color="default"
                    >
                      ロゴ画像を選択する
                      <input
                        type="file"
                        css={css`
                          display: none;
                        `}
                        onChange={handleChangeLogoFile}
                      />
                    </Button>
                  </>
                )}
              </Grid>
              <Grid item xs={12} sm={6}>
                <p
                  css={css`
                    display: block;
                    text-align: left;
                    margin: 0 0 24px 0;
                  `}
                >
                  画像の設定{' '}
                  <span
                    css={css`
                      font-size: 14px;
                    `}
                  >
                    推奨サイズ:750×420px
                  </span>
                </p>
                {form.imageFile || form.imagePath ? (
                  <>
                    <Box css={cssStyles.uploadImage}>
                      <img src={getImagePath()} width="100%" alt="" />
                      <IconButton
                        onClick={handleClickRemoveImage}
                        css={cssStyles.closeIcon}
                      >
                        <CloseIcon />
                      </IconButton>
                    </Box>
                  </>
                ) : (
                  <>
                    <Button
                      variant="contained"
                      component="label"
                      color="default"
                    >
                      画像を選択する
                      <input
                        type="file"
                        css={css`
                          display: none;
                        `}
                        onChange={handleChangeImageFile}
                      />
                    </Button>
                  </>
                )}
              </Grid>
            </Grid>
            <Box mt={3}>
              <TextField
                label="店舗の紹介文(1段落目)"
                helperText="※店舗の紹介文はコース選択画面で表示されます。複数行入力可能、HTMLタグはimg, a, span, table, tr, th, tdが使用できます。"
                value={form.headline1}
                fullWidth
                multiline
                onChange={handleChangeHeadline1}
              />
            </Box>
            <Box mt={2} mb={3}>
              <TextField
                label="店舗の紹介文(2段落目)"
                helperText="※店舗の紹介文はコース選択画面で表示されます。複数行入力可能、HTMLタグはimg, a, span, table, tr, th, tdが使用できます。"
                value={form.headline2}
                fullWidth
                multiline
                onChange={handleChangeHeadline2}
              />
            </Box>
            <Box>
              <Grid container>
                <Grid item xs={12} sm={6}>
                  <p
                    css={css`
                      margin: 24px auto 8px;
                      font-size: 14px;
                    `}
                  >
                    ロゴ画像プレビュー(ヘッダー)
                  </p>
                  <PreviewHeader
                    color={
                      form.primaryColor ||
                      workspaceSetting?.primaryColor ||
                      DEFAULT_THEME_COLOR
                    }
                    shopName="店舗名 ◯◯"
                    imageSrc={getLogoPath()}
                  />
                  <p
                    css={css`
                      margin: 24px auto 8px;
                      font-size: 14px;
                    `}
                  >
                    ロゴ画像プレビュー(店舗選択画面)
                  </p>
                  <PreviewSelectShopPage
                    color={
                      form.primaryColor ||
                      workspaceSetting?.primaryColor ||
                      DEFAULT_THEME_COLOR
                    }
                    shopName="店舗名 ◯◯"
                    imageSrc={getLogoPath()}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <p
                    css={css`
                      margin: 24px auto 8px;
                      font-size: 14px;
                    `}
                  >
                    画像プレビュー(コース選択画面)
                  </p>
                  <PreviewSelectCoursePage
                    color={
                      form.primaryColor ||
                      workspaceSetting?.primaryColor ||
                      DEFAULT_THEME_COLOR
                    }
                    shopName="店舗名 ◯◯"
                    imageSrc={getImagePath()}
                    headline1={form.headline1 || workspaceSetting?.headline1}
                    headline2={form.headline2 || workspaceSetting?.headline2}
                  />
                </Grid>
              </Grid>
            </Box>
          </FormControl>
        </Paper>
        <h3>埋め込みタグ</h3>
        <Alert severity="info">
          ここで設定されたタグは全ての店舗のWeb予約画面に埋め込まれます。
        </Alert>
        <Paper className={styles.paper}>
          <TextField
            label="Web予約フォーム画面の埋め込みタグ(headタグ末尾)"
            helperText={helperText}
            value={form.userScriptHead}
            multiline
            fullWidth
            onChange={handleChangeUserScriptHead}
          />
          <TextField
            label="Web予約フォーム画面の埋め込みタグ(bodyタグ先頭)"
            helperText={helperText}
            value={form.userScriptBody}
            multiline
            fullWidth
            onChange={handleChangeUserScriptBody}
          />
        </Paper>
        <Grid
          container
          css={css`
            margin-top: 20px;
          `}
        >
          <Grid item xs={6}></Grid>
          <Grid
            item
            container
            xs={6}
            alignItems="center"
            justifyContent="flex-end"
          >
            {buildRightNavigationButtons()}
          </Grid>
        </Grid>
      </form>
    );
  };

  const pageTitle = 'デザインと計測タグ設定';

  return (
    <Root>
      <Head title={`ワークスペース設定 - ${workspace?.name || ''}`} />
      <Main>
        <WorkspacePageLayout
          workspaceUid={workspaceUid}
          current="theme"
          breadcrumbs={
            <Breadcrumbs aria-label="breadcrumb">
              <BreadcrumbLinkItem to={`/`}>ホーム</BreadcrumbLinkItem>
              <BreadcrumbWorkspaceItem workspaceUid={workspaceUid} />
              <BreadcrumbTextItem>{pageTitle}</BreadcrumbTextItem>
            </Breadcrumbs>
          }
          helpId={helps.workspace.setting.themeAndTag}
        >
          <PageTitleAndDescription
            title="ワークスペース"
            subTitle={pageTitle}
            description="予約画面のロゴやTOP画像、全体の色味などの設定、GA4等の計測タグの設定を行います。"
            themeColor={globalColors.workspace}
          />
          {buildContents()}
        </WorkspacePageLayout>
      </Main>
      <FullscreenLoading open={openBackdrop} />
      {isShowIconCropperDialog && form.imageFile && (
        <ImageCropperDialog
          file={form.imageFile}
          onCropImage={handleCropImage}
          onClose={() => {
            setIsShowIconCropperDialog(false);
          }}
          aspect={375 / 210}
        />
      )}
      {isShowLogoCropperDialog && form.logoFile && (
        <ImageCropperDialog
          file={form.logoFile}
          onCropImage={handleCropLogo}
          onClose={() => {
            setIsShowLogoCropperDialog(false);
          }}
          aspect={240 / 160}
        />
      )}
    </Root>
  );
}
