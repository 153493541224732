export const sendPreviewDataToIframe = (
  iframeId: string,
  type: string,
  previewData: any,
  delay = 0
) => {
  const message = {
    type,
    data: previewData,
  };
  if (delay === 0) {
    const iframe: HTMLIFrameElement | null = document.querySelector(
      `#${iframeId}`
    );
    iframe?.contentWindow?.postMessage(message, '*');
  } else {
    setTimeout(() => {
      const iframe: HTMLIFrameElement | null = document.querySelector(
        `#${iframeId}`
      );
      iframe?.contentWindow?.postMessage(message, '*');
    }, delay);
  }
};
