import { AxiosResponse } from 'axios';
import { UpdateResourceRequestData } from '../../../@interfaces/resource/apis';
import { API_END_POINT } from '../../../api/api';
import { useMutation } from '../../common/api/use-mutation';

export const useUpdateResources = (): ((
  shopId: string,
  resourceId: number,
  params: UpdateResourceRequestData
) => Promise<AxiosResponse<''>>) => {
  const { request } = useMutation<'', UpdateResourceRequestData>();
  return (shopId, resourceId, params) => {
    return request(
      'put',
      `${API_END_POINT}/app/shops/${shopId}/resources/${resourceId}`,
      {},
      params
    );
  };
};
