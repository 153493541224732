/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { Paper } from '@material-ui/core';
import dayjs, { Dayjs } from 'dayjs';
import React, { CSSProperties, useCallback, useMemo, useState } from 'react';
import { IDate, toDate, toDayjs } from '../../core/types/reservation-types';
import { range } from '../../utils/numbers';
import { CalendarCell, createCalendarLines } from './calendar-helper';
import { commonStyles, holiday } from './calendar-styles';
import * as holiday_jp from '@holiday-jp/holiday_jp';
import { MD_BREAKPOINT, SM_BREAKPOINT } from '../../hooks/use-size-type';
import { useWindowSize } from '../../hooks/use-window-size';

const styles = {
  container: css`
    padding: 5px;
    width: 300px;
    @media screen and (max-width: ${MD_BREAKPOINT}px) {
      padding: 0;
      width: 100%;
    }
  `,
  floating: css`
    position: absolute;
    top: 67px;
    left: 0;
    z-index: 10000;
  `,
  paper: css`
    width: 100%;
    padding-top: 8px;
    @media screen and (max-width: ${MD_BREAKPOINT}px) {
      padding: 16px 8px;
    }
  `,
  toolbar: css`
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 14px;
    margin-bottom: 8px;
    @media screen and (max-width: ${SM_BREAKPOINT}px) {
      justify-content: space-between;
      margin-bottom: 16px;
    }
  `,
  toolbarButton: css`
    font-size: 16px;
    @media screen and (max-width: ${MD_BREAKPOINT}px) {
      height: 48px;
      line-height: 48px;
      padding: 0 12px;
    }
  `,
  prevButton: css`
    margin-right: 16px;
    @media screen and (max-width: ${MD_BREAKPOINT}px) {
      height: 48px;
      line-height: 48px;
      padding: 0 12px;
    }
  `,
  nextButton: css`
    font-size: 16px;
    margin-left: 16px;
    @media screen and (max-width: ${MD_BREAKPOINT}px) {
      height: 48px;
      line-height: 48px;
      padding: 0 12px;
    }
  `,
  toolbarSelect: css`
    font-size: 16px;
    margin: 0px 5px;
    border: solid 0px #000;
  `,
  table: css`
    width: 100%;
    border-spacing: 0px;
  `,
  cells: {
    default: css`
      position: relative;
      padding: 3px;
      text-align: center;
      background: #fff;
      border: solid 2px rgba(1, 1, 1, 0);
      cursor: pointer;

      &:hover {
        border: solid 2px #aaa;
      }

      @media screen and (max-width: ${MD_BREAKPOINT}px) {
        font-size: 16px;
        padding: 8px;
      }
    `,
    current: css`
      border: solid 2px #0063da;
    `,
    today: css``,
  },
  annotations: {
    default: css`
      position: absolute;
      width: 4px;
      height: 4px;
      bottom: 0px;
      left: calc(50%);
      transform: translate(-50%, 0);
      border-radius: 2px;
      background-color: #00b397;

      @media screen and (max-width: ${MD_BREAKPOINT}px) {
        width: 6px;
        height: 6px;
        bottom: 4px;
      }
    `,
  },
};

export const calendarCss = styles;

export type RenderCellHandler = (
  cell: CalendarCell | null,
  courseId?: number
) => JSX.Element | null;
export type RenderCellStyleHandler = (
  cell: CalendarCell | null,
  courseId?: number
) => CSSProperties | undefined;

type Props = {
  shopId: string;
  courseId?: number;
  floating: boolean;
  currentDate: IDate;
  onChangeCurrentDate: (newDate: IDate) => void;
  onChangeSelectedViewDate: (newViewDate: dayjs.Dayjs) => void;
  onRenderCell?: RenderCellHandler;
  onRenderCellStyle?: RenderCellStyleHandler;
};

export function Calendar(props: Props) {
  const {
    shopId,
    courseId,
    currentDate,
    onChangeCurrentDate,
    onChangeSelectedViewDate,
    floating,
    onRenderCell,
    onRenderCellStyle,
  } = props;

  const today = dayjs();
  const currentDayJs = toDayjs(currentDate);
  const [selectedViewDate, setSelectedViewDate] =
    useState<dayjs.Dayjs>(currentDayJs);
  const windowSize = useWindowSize();

  const lines = useMemo(() => {
    return createCalendarLines(selectedViewDate);
  }, [selectedViewDate.format('YYYYMM')]);

  const years = useMemo(() => {
    return range(selectedViewDate.year() - 3, selectedViewDate.year() + 5);
  }, [selectedViewDate.format('YYYY')]);

  const months = useMemo(() => {
    return range(1, 12);
  }, []);

  const handlePrevMonth = useCallback(() => {
    const newSelectedViewDate = selectedViewDate
      .clone()
      .add(-1, 'month')
      .startOf('month');
    setSelectedViewDate(newSelectedViewDate);
    onChangeSelectedViewDate(newSelectedViewDate);
  }, [selectedViewDate.format('YYYYMMDD')]);

  const handleNextMonth = useCallback(() => {
    const newSelectedViewDate = selectedViewDate
      .clone()
      .add(1, 'month')
      .startOf('month');
    setSelectedViewDate(newSelectedViewDate);
    onChangeSelectedViewDate(newSelectedViewDate);
  }, [selectedViewDate.format('YYYYMMDD')]);

  const handleChangeYear = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      const value = parseInt(e.target.value);
      const newSelectedViewDate = selectedViewDate.clone().set('year', value);
      setSelectedViewDate(newSelectedViewDate);
      onChangeSelectedViewDate(newSelectedViewDate);
    },
    [selectedViewDate.format('YYYYMMDD')]
  );

  const handleChangeMonth = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      const value = parseInt(e.target.value);
      const newSelectedViewDate = selectedViewDate
        .clone()
        .set('month', value - 1);
      setSelectedViewDate(newSelectedViewDate);
      onChangeSelectedViewDate(newSelectedViewDate);
    },
    [selectedViewDate.format('YYYYMMDD')]
  );

  return (
    <div css={[styles.container, floating ? styles.floating : null]}>
      <Paper css={styles.paper}>
        {windowSize.width >= 350 ? (
          <div css={styles.toolbar}>
            <button
              type="button"
              css={[styles.toolbarButton, styles.prevButton]}
              onClick={handlePrevMonth}
            >
              前月
            </button>
            <div>
              <select
                css={styles.toolbarSelect}
                onChange={handleChangeYear}
                value={selectedViewDate.year()}
              >
                {years.map((year, index) => {
                  return (
                    <option key={`year_${index}`} value={year}>
                      {year}
                    </option>
                  );
                })}
              </select>
              年
              <select
                css={styles.toolbarSelect}
                onChange={handleChangeMonth}
                value={selectedViewDate.month() + 1}
              >
                {months.map((month, index) => {
                  return (
                    <option key={`month_${index}`} value={month}>
                      {month}
                    </option>
                  );
                })}
              </select>
              月
            </div>
            <button
              type="button"
              css={[styles.toolbarButton, styles.nextButton]}
              onClick={handleNextMonth}
            >
              次月
            </button>
          </div>
        ) : (
          <div css={styles.toolbar}>
            <div
              css={css`
                width: 100%;
              `}
            >
              <div
                css={css`
                  margin-bottom: 16px;
                `}
              >
                <button
                  type="button"
                  css={[styles.toolbarButton, styles.prevButton]}
                  onClick={handlePrevMonth}
                >
                  前月
                </button>
                <button
                  type="button"
                  css={[styles.toolbarButton, styles.nextButton]}
                  onClick={handleNextMonth}
                >
                  次月
                </button>
              </div>
              <div
                css={css`
                  font-size: 18px;
                `}
              >
                <select
                  css={[
                    styles.toolbarSelect,
                    css`
                      font-size: 18px;
                    `,
                  ]}
                  onChange={handleChangeYear}
                  value={selectedViewDate.year()}
                >
                  {years.map((year, index) => {
                    return (
                      <option key={`year_${index}`} value={year}>
                        {year}
                      </option>
                    );
                  })}
                </select>
                年
                <select
                  css={[
                    styles.toolbarSelect,
                    css`
                      font-size: 18px;
                    `,
                  ]}
                  onChange={handleChangeMonth}
                  value={selectedViewDate.month() + 1}
                >
                  {months.map((month, index) => {
                    return (
                      <option key={`month_${index}`} value={month}>
                        {month}
                      </option>
                    );
                  })}
                </select>
                月
              </div>
            </div>
          </div>
        )}
        <table css={styles.table}>
          <thead>
            <tr>
              <th css={commonStyles.dayOfWeek[0]}>日</th>
              <th css={commonStyles.dayOfWeek[1]}>月</th>
              <th css={commonStyles.dayOfWeek[2]}>火</th>
              <th css={commonStyles.dayOfWeek[3]}>水</th>
              <th css={commonStyles.dayOfWeek[4]}>木</th>
              <th css={commonStyles.dayOfWeek[5]}>金</th>
              <th css={commonStyles.dayOfWeek[6]}>土</th>
            </tr>
          </thead>
          <tbody>
            {lines.map((line, rowIndex) => {
              return (
                <Row
                  courseId={courseId}
                  currentDate={currentDate}
                  selectedViewDate={selectedViewDate}
                  today={today}
                  line={line}
                  rowIndex={rowIndex}
                  onChangeCurrentDate={onChangeCurrentDate}
                  onRenderCell={onRenderCell}
                  onRenderCellStyle={onRenderCellStyle}
                  key={rowIndex}
                />
              );
            })}
          </tbody>
        </table>
      </Paper>
    </div>
  );
}

type RowProps = {
  courseId?: number;
  currentDate: IDate;
  selectedViewDate: Dayjs;
  today: Dayjs;
  line: (CalendarCell | null)[];
  rowIndex: number;
  onChangeCurrentDate: (newDate: IDate) => void;
  onRenderCell?: RenderCellHandler;
  onRenderCellStyle?: RenderCellStyleHandler;
};

function Row(props: RowProps) {
  const {
    courseId,
    currentDate,
    selectedViewDate,
    today,
    line,
    rowIndex,
    onChangeCurrentDate,
    onRenderCell,
    onRenderCellStyle,
  } = props;

  return (
    <tr key={rowIndex}>
      {line.map((cell, colIndex) => {
        return (
          <Cell
            courseId={courseId}
            currentDate={currentDate}
            selectedViewDate={selectedViewDate}
            today={today}
            cell={cell}
            rowIndex={rowIndex}
            colIndex={colIndex}
            onChangeCurrentDate={onChangeCurrentDate}
            onRenderCell={onRenderCell}
            onRenderCellStyle={onRenderCellStyle}
            key={colIndex}
          />
        );
      })}
    </tr>
  );
}

type CellProps = {
  courseId?: number;
  currentDate: IDate;
  selectedViewDate: Dayjs;
  today: Dayjs;
  cell: CalendarCell | null;
  rowIndex: number;
  colIndex: number;
  onChangeCurrentDate: (newDate: IDate) => void;
  onRenderCell?: RenderCellHandler;
  onRenderCellStyle?: RenderCellStyleHandler;
};

function Cell(props: CellProps) {
  const {
    courseId,
    currentDate,
    selectedViewDate,
    today,
    cell,
    rowIndex,
    colIndex,
    onChangeCurrentDate,
    onRenderCell,
    onRenderCellStyle,
  } = props;

  const isCurrentDate = (date: number | undefined): boolean => {
    if (!date) {
      return false;
    }

    return (
      currentDate.year === selectedViewDate.year() &&
      currentDate.month === selectedViewDate.month() + 1 &&
      currentDate.date === date
    );
  };

  const isToday = (): boolean => {
    if (!cell?.date) {
      return false;
    }

    return (
      today.year() === selectedViewDate.year() &&
      today.month() === selectedViewDate.month() &&
      today.date() === cell.date
    );
  };

  const handleClickCell = (date: number | undefined) => {
    if (!date) {
      return;
    }
    const newSelectedDate = selectedViewDate.clone().set('date', date);
    onChangeCurrentDate(toDate(newSelectedDate));
  };

  const cellStyles = [styles.cells.default];
  if (isCurrentDate(cell?.date)) {
    cellStyles.push(styles.cells.current);
  }
  if (isToday()) {
    cellStyles.push(styles.cells.today);
  }

  return (
    <td
      key={colIndex}
      css={cellStyles}
      className={`day-${colIndex}`}
      onClick={() => {
        handleClickCell(cell?.date);
      }}
      style={onRenderCellStyle?.(cell, courseId)}
    >
      <span
        css={
          cell?.date &&
          holiday_jp.isHoliday(
            new Date(
              selectedViewDate.year(),
              selectedViewDate.month(),
              cell.date
            )
          )
            ? holiday
            : (commonStyles.dayOfWeek as any)[colIndex]
        }
      >
        {cell?.date}
      </span>
      {onRenderCell?.(cell, courseId)}
    </td>
  );
}
