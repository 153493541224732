/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { Breadcrumbs, FormHelperText, Grid } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useSnackbar } from 'notistack';
import useReactRouter from 'use-react-router';
import { useWorkspaceFeature } from '../../../api/use-workspace-feature';
import {
  BreadcrumbLinkItem,
  BreadcrumbTextItem,
  BreadcrumbWorkspaceItem,
} from '../../../components/breadcrumb/BreadcrumbItem';
import { WorkspacePageLayout } from '../../../components/layouts/WorkspacePageLayout';
import { PageTitleAndDescription } from '../../../components/PageTitleAndDescription';
import { Head, Main, Root } from '../../../components/Shared';
import Spinner from '../../../components/Spinner';
import useCurrentWorkspace from '../../../context/use-current-workspace';
import { useCreateCustomDomain } from '../../../features/custom-domain/api/useCreateCustomDomain';
import { useCustomDomains } from '../../../features/custom-domain/api/useCustomDomains';
import { CustomDomainTable } from '../../../features/custom-domain/components/CusomDomainTable';
import { CustomDomainCreator } from '../../../features/custom-domain/components/CustomDomainCreator';
import { globalColors } from '../../../styles/globalColors';

interface PageParams {
  workspaceUid: string;
}

export default function WorkspaceCustomDomainSettingPage() {
  const { match } = useReactRouter<PageParams>();
  const { workspaceUid } = match.params;
  const workspace = useCurrentWorkspace(workspaceUid);
  const {
    data: customDomains,
    isLoading,
    reload,
  } = useCustomDomains(workspaceUid);
  const createCustomDomain = useCreateCustomDomain();
  const { enqueueSnackbar } = useSnackbar();
  const { workspaceFeature } = useWorkspaceFeature(workspaceUid);

  if (!workspace) return null;

  const buildContents = () => (
    <>
      <Grid
        container
        css={css`
          padding: 10px 0;
        `}
      >
        <CustomDomainCreator
          onCreateCustomDomain={(fqdn: string) => {
            createCustomDomain(workspaceUid, { fqdn })
              .then(() => {
                enqueueSnackbar('独自ドメインを登録しました。');
                reload();
              })
              .catch((e) => {
                console.error(e);
              });
          }}
        />
      </Grid>
      <Grid container>
        {isLoading ? (
          <Spinner loading={isLoading} />
        ) : (
          <CustomDomainTable
            customDomains={customDomains}
            onDeleteCustomDomain={() => {
              enqueueSnackbar('独自ドメインを削除しました。');
              reload();
            }}
          />
        )}
      </Grid>
    </>
  );

  const pageTitle = '独自ドメイン';

  return (
    <Root>
      <Head title={`ワークスペース設定 - ${pageTitle}`} />
      <Main>
        <WorkspacePageLayout
          workspaceUid={workspaceUid}
          current="custom-domain"
          breadcrumbs={
            <Breadcrumbs aria-label="breadcrumb">
              <BreadcrumbLinkItem to={`/`}>ホーム</BreadcrumbLinkItem>
              <BreadcrumbWorkspaceItem workspaceUid={workspaceUid} />
              <BreadcrumbTextItem>{pageTitle}</BreadcrumbTextItem>
            </Breadcrumbs>
          }
        >
          <PageTitleAndDescription
            title="ワークスペース"
            subTitle={pageTitle}
            description="独自ドメインの設定を行います。"
            themeColor={globalColors.workspace}
          />
          {workspaceFeature?.isCustomDomainEnabled ? (
            buildContents()
          ) : (
            <Alert severity="warning">
              独自ドメインの利用には申請が必要です。詳しくはお問い合わせください。
              <FormHelperText>
                本機能を利用することで、公開する予約URLをロカオプのURLから所有している任意のドメインに変更することができます。
              </FormHelperText>
            </Alert>
          )}
        </WorkspacePageLayout>
      </Main>
    </Root>
  );
}
