import axios from 'axios';
import { useContext, useState } from 'react';
import { Store } from '../context/GlobalStore';
import { API_END_POINT } from './api';

type SortOrder = {
  courseId: number;
  sortOrder: number;
};

export default function useShopCoursesSettingSortOrder() {
  const { globalState } = useContext(Store);
  const [loading, setLoading] = useState(false);

  const shopCoursesSettingSortOrderApi = (
    shopId: string,
    sortOrders: SortOrder[]
  ) => {
    setLoading(true);
    axios
      .put(
        `${API_END_POINT}/app/shops/${shopId}/course-slot-setting-sort-order`,
        sortOrders,
        {
          headers: {
            Authorization: globalState.session?.idToken,
          },
        }
      )
      .finally(() => {
        setLoading(false);
      });
  };

  return {
    shopCoursesSettingSortOrderApi,
    isLoadingSortOrder: loading,
  };
}
