/** @jsxImportSource @emotion/react */

import React from 'react';
import {
  AnyField,
  FormSetting,
  NumberField,
} from '../../../../../core/types/reservation-form-types';
import { FormSettingSingleDefaultValue } from '../FormSettingSingleDefaultValue';

type FormSettingFieldNumberProps = {
  field: NumberField;
  setting: FormSetting;
  onChangeSetting: (setting: FormSetting) => void;
  originalField: AnyField | undefined;
};

export const FormSettingFieldNumber = (
  props: FormSettingFieldNumberProps
): JSX.Element => {
  const { field, setting, onChangeSetting } = props;

  return (
    <FormSettingSingleDefaultValue
      field={field}
      setting={setting}
      onChangeSetting={onChangeSetting}
      type="number"
    />
  );
};
