import { useCallback, useContext, useState } from 'react';
import axios from 'axios';
import { API_END_POINT } from './api';
import { Store } from '../context/GlobalStore';
import { SendMailBody } from '../@interfaces/reservation-send-mail';

export default function useSendMail() {
  const { globalState } = useContext(Store);
  const [loading, setLoading] = useState(false);

  const sendMail = useCallback(
    async (shopId: number, reservationId: number, mail: SendMailBody) => {
      if (!globalState.session?.idToken) {
        return;
      }
      setLoading(true);
      const response = await axios.post(
        `${API_END_POINT}/app/shops/${shopId}/reservations/${reservationId}/send-mail`,
        mail,
        {
          headers: {
            Authorization: globalState.session?.idToken,
            'Content-Type': 'application/json',
          },
        }
      );
      setLoading(false);
      if (response.status >= 400) {
        throw new Error(response.data?.message);
      }
    },
    [globalState.session?.idToken]
  );

  return {
    sendMail,
    loading,
  };
}
