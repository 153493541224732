import axios, { AxiosResponse } from 'axios';
import { useContext, useEffect, useState } from 'react';
import { ResponseBody } from '../../../frontend-api/src/handlers/reservation/reservations-handler';
import { ICourseFormSetting } from '../../../frontend-api/src/interfaces/course-form-setting';
import { RichReservation } from '../@interfaces/reservation';
import { Store } from '../context/GlobalStore';
import { API_END_POINT } from './api';

type CacheKey = `${string}`;

const createCacheKey = (
  shopId: string,
  startDate: string,
  endDate: string,
  includingDeleted: boolean
): CacheKey => {
  return `${shopId}/${startDate}/${endDate}/${includingDeleted}` as CacheKey;
};
const cache: { [cacheKey: string]: RichReservation[] } = {};
const cacheFormSetting: { [cacheKey: string]: ICourseFormSetting[] } = {};

export default function useReservations(
  shopId: string,
  startDate: string,
  endDate: string,
  includingDeleted: boolean
) {
  const { globalState, setGlobalState } = useContext(Store);
  const cacheKey = createCacheKey(shopId, startDate, endDate, includingDeleted);
  const [reservations, setReservations] = useState(
    cache[cacheKey] || ([] as RichReservation[])
  );
  const [formSettings, setFormSettings] = useState(
    cacheFormSetting[cacheKey] || ([] as ICourseFormSetting[])
  );
  const [loading, setLoading] = useState(cache[cacheKey] == undefined);

  const reload = () => {
    if (!globalState.session?.idToken) {
      return;
    }
    const params = {
      startDate,
      endDate,
      includingDeleted,
    };
    axios
      .get(`${API_END_POINT}/app/shops/${shopId}/reservations`, {
        params,
        headers: {
          Authorization: globalState.session?.idToken,
        },
      })
      .then((response: AxiosResponse<ResponseBody>) => {
        setReservations(response.data.reservations);
        setFormSettings(response.data.formSettings);
        cache[cacheKey] = response.data.reservations;
        cacheFormSetting[cacheKey] = response.data.formSettings;
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    reload();
  }, [
    shopId,
    startDate,
    endDate,
    includingDeleted,
    globalState.session?.idToken,
  ]);

  return {
    reservations,
    formSettings,
    isLoadingReservations: loading,
    reloadReservations: reload,
  };
}
