import axios from 'axios';
import { useEffect, useState } from 'react';
import { QueryResult } from '../../common/api/use-query';

let cache: any[] = [];

export const useLocaopMediaItems = (): QueryResult<any[]> => {
  const [feedItems, setFeedItems] = useState(cache);
  const [isLoading, setIsLoading] = useState(false);

  const reload = () => {
    setIsLoading(true);
    axios
      .get(`https://locaop.jp//media/feed/json`)
      .then((res) => {
        setFeedItems(res.data.items);
        cache = feedItems;
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  useEffect(() => {
    reload();
  }, []);

  return {
    data: feedItems,
    isLoading: cache.length > 0 ? false : isLoading,
    reload,
  };
};
