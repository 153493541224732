import { FormHelperText, Grid } from '@material-ui/core';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { Alert } from '@material-ui/lab';
import React from 'react';
import useReactRouter from 'use-react-router';
import useShop from '../../../api/use-shop';
import { useWorkspaceFeature } from '../../../api/use-workspace-feature';
import {
  BreadcrumbLinkItem,
  BreadcrumbShopItem,
  BreadcrumbTextItem,
  BreadcrumbWorkspaceItem,
} from '../../../components/breadcrumb/BreadcrumbItem';
import { ShopPageLayout } from '../../../components/layouts/ShopPageLayout';
import { PageTitleAndDescription } from '../../../components/PageTitleAndDescription';
import { Head, Main, Root } from '../../../components/Shared';
import useCurrentWorkspace from '../../../context/use-current-workspace';
import { ShopCustomDomainSettingContent } from '../../../features/custom-domain/components/ShopCustomDomainSettingContent';
import { globalColors } from '../../../styles/globalColors';
import { helps } from '../../../utils/helps';

interface PageParams {
  workspaceUid: string;
  shopId: string;
}

export default function ShopCustomDomainSettingPage() {
  const { match } = useReactRouter<PageParams>();
  const { workspaceUid, shopId } = match.params;
  const { shop } = useShop(shopId);
  const workspace = useCurrentWorkspace(workspaceUid);
  const { workspaceFeature } = useWorkspaceFeature(workspaceUid);

  if (!workspace) return null;

  const buildContents = () => (
    <>
      <Grid container item xs={12} alignItems="center">
        <h3>独自ドメイン設定</h3>
      </Grid>
      <Grid container spacing={3}>
        <Grid item container xs={12} alignItems="center">
          <ShopCustomDomainSettingContent />
        </Grid>
      </Grid>
    </>
  );

  const pageTitle = '独自ドメイン';

  return (
    <Root>
      <Head title={`独自ドメイン設定 - ${shop?.name || ''}`} />
      <Main>
        <ShopPageLayout
          workspaceUid={workspaceUid}
          shopId={shopId}
          current="custom-domain"
          helpId={helps.shop.setting.courseSlot}
          breadcrumbs={
            <Breadcrumbs aria-label="breadcrumb">
              <BreadcrumbLinkItem to={`/`}>ホーム</BreadcrumbLinkItem>
              <BreadcrumbWorkspaceItem workspaceUid={workspaceUid} />
              <BreadcrumbShopItem workspaceUid={workspaceUid} shop={shop} />
              <BreadcrumbTextItem>{pageTitle}</BreadcrumbTextItem>
            </Breadcrumbs>
          }
        >
          <PageTitleAndDescription
            title="店舗"
            subTitle={pageTitle}
            description="独自ドメインの設定を行います。"
            themeColor={globalColors.shop}
          />
          {workspaceFeature?.isCustomDomainEnabled ? (
            buildContents()
          ) : (
            <Alert severity="warning">
              独自ドメインの利用には申請が必要です。詳しくはお問い合わせください。
              <FormHelperText>
                本機能を利用することで、公開する予約URLをロカオプのURLから所有している任意のドメインに変更することができます。
              </FormHelperText>
            </Alert>
          )}
        </ShopPageLayout>
      </Main>
    </Root>
  );
}
