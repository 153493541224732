import {
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  Paper,
  Switch,
} from '@material-ui/core';
import { ShopCourseSetting } from '../../../../../../frontend-api/src/interfaces/course-slot-setting';
import { ResourceConstantSetting } from '../../../../@interfaces/course-slot-setting';
import Spinner from '../../../../components/Spinner';
import { useStyles } from '../../../../components/Styles';
import { FormSetting } from '../../../../core/types/reservation-form-types';
import {
  PararelResourceConstant,
  ResourceConstant,
} from '../../../../core/types/reservation-resource-types';
import { useResourceGroups } from '../../api/getResourceGroups';
import {
  createDefaultPararelResourceConstant,
  createDefaultResourceConstantSetting,
} from '../../models/resource-constant';
import { UpdateResourceConstantSettingHandler } from '../../types';
import { ResourceConstantEditor } from './ResourceConstantEditor';

type ResourceConstantSettingFormProps = {
  shopId: string;
  resourceConstantSetting: ResourceConstantSetting | undefined;
  shopCourseSetting: ShopCourseSetting;
  formSetting: FormSetting;
  onUpdateResourceConstantSetting: UpdateResourceConstantSettingHandler;
};

export const ResourceConstantSettingForm: React.VFC<
  ResourceConstantSettingFormProps
> = (props) => {
  const {
    shopId,
    resourceConstantSetting,
    shopCourseSetting,
    formSetting,
    onUpdateResourceConstantSetting,
  } = props;
  const styles = useStyles();
  const resourceGroupsQuery = useResourceGroups(shopId);

  if (resourceGroupsQuery.isLoading) {
    return <Spinner loading={resourceGroupsQuery.isLoading} />;
  }

  const handleChangeUseResourceConstant = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (resourceConstantSetting) {
      onUpdateResourceConstantSetting(undefined);
    } else {
      onUpdateResourceConstantSetting(createDefaultResourceConstantSetting());
    }
  };

  const handleClickAddConstant = () => {
    const constant = createDefaultPararelResourceConstant();
    resourceConstantSetting?.constants.push(constant);
    onUpdateResourceConstantSetting(resourceConstantSetting);
  };

  const handleDeleteConstant = (constant: ResourceConstant) => {
    const newConstants =
      resourceConstantSetting?.constants.filter((c) => c !== constant) || [];
    onUpdateResourceConstantSetting({
      ...resourceConstantSetting,
      constants: newConstants,
    });
  };

  return (
    <>
      <Grid container style={{ marginTop: '20px' }}>
        <Grid item xs={12}>
          <h3>リソースによる制約</h3>
        </Grid>
      </Grid>
      <Paper className={styles.paper}>
        <Grid container style={{ marginTop: '20px' }}>
          <Grid item xs={12}>
            <FormControl component="fieldset">
              <FormControlLabel
                control={
                  <Switch
                    checked={resourceConstantSetting != undefined}
                    onChange={handleChangeUseResourceConstant}
                    color="primary"
                  />
                }
                label="リソースの空きに応じて予約枠を制限する"
              />
              <FormHelperText>
                リソースの空き状況に応じて予約枠を調整します。空きがない場合、予約枠は閉じられます。
              </FormHelperText>
            </FormControl>
          </Grid>
        </Grid>
        {resourceConstantSetting && (
          <>
            {resourceConstantSetting?.constants.map((constant) => (
              <ResourceConstantEditor
                constant={constant}
                resourceConstantSetting={resourceConstantSetting}
                shopCourseSetting={shopCourseSetting}
                formSetting={formSetting}
                resourceGroups={resourceGroupsQuery.data}
                onUpdateResourceConstantSetting={
                  onUpdateResourceConstantSetting
                }
                onDeleteConstrant={handleDeleteConstant}
              />
            ))}
            <Button color="primary" onClick={handleClickAddConstant}>
              制約を追加する
            </Button>
          </>
        )}
      </Paper>
    </>
  );
};
