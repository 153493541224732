import axios from 'axios';
import { useContext } from 'react';
import useSWR from 'swr';
import { Store } from '../context/GlobalStore';
import { API_END_POINT } from './api';

const buildUrl = (workspaceUid: string) => {
  return `${API_END_POINT}/app/ws/${workspaceUid}/setting`;
};

const fetcher = async (token: string, workspaceUid: string) => {
  const response = await axios.get(buildUrl(workspaceUid), {
    headers: {
      Authorization: token,
    },
  });
  return response.data;
};

export default function useWorkspaceSetting(workspaceUid: string) {
  const { globalState } = useContext(Store);

  const { data, isLoading, mutate } = useSWR(
    globalState.session?.idToken
      ? [globalState.session.idToken, workspaceUid]
      : null,
    async ([token, workspaceUid]) => fetcher(token, workspaceUid)
  );

  const reload = () => {
    void mutate([buildUrl(workspaceUid), globalState.session?.idToken]);
  };

  return {
    workspaceSetting: data?.workspaceSetting,
    isLoadingWorkspaceSetting: isLoading,
    reloadWorkspaceSetting: reload,
  };
}
