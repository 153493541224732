import {
  AlwaysCondition,
  dayOfWeeksIndeies,
  MonthlyCondition,
  OptionalDateRange,
  RegularRule,
  Schedule,
  ScheduleCondition,
  SlotRule,
  SlotSetting,
  TimeRange,
  TimeRangeCapacity,
  toDayjs,
  toTimeValue,
  WeeklyCondition,
  isConflictDateRange,
  isOverlappingOfTimeRanges,
  CustomWeeklyCondition,
} from './reservation-types';

export type ValidationError = {
  path: string;
  message: string;
};

export function validateSlotSetting(
  slotSetting: SlotSetting
): ValidationError[] {
  return slotSetting.rules.reduce((errors, rule, index) => {
    return errors.concat(validateRule(rule, index));
  }, [] as ValidationError[]);
}

export function validateDateRange(
  newDateRange: OptionalDateRange,
  existDateRanges: OptionalDateRange[]
): ValidationError[] {
  if (!existDateRanges.some((d) => isConflictDateRange(d, newDateRange))) {
    return [];
  }
  return [
    {
      path: `rules/0/dateRange`,
      message: `他の予約枠設定と期間が重複しています`,
    },
  ];
}

export function validateRule(
  rule: SlotRule,
  ruleIndex: number
): ValidationError[] {
  if (rule.type == 'regular') {
    return validateRegularRule(rule, ruleIndex);
  } else if (rule.type == 'holiday') {
    // don't implement yet
    return [];
  } else {
    return [];
  }
}

export function validateRegularRule(
  rule: RegularRule,
  ruleIndex: number
): ValidationError[] {
  return [
    ...validateUnit(rule.unit, ruleIndex),
    ...validateOptionalDateRange(rule.dateRange, ruleIndex),
    ...validateSchedules(rule.schedules, ruleIndex),
  ];
}

export function validateUnit(
  unit: number,
  ruleIndex: number
): ValidationError[] {
  const errors = [] as ValidationError[];
  if (unit < 5 || unit >= 600) {
    errors.push({
      path: `rules/${ruleIndex}/unit`,
      message: `予約枠間隔が不正です。予約枠間隔は5から600以内である必要があります。`,
    });
  }
  return errors;
}

export function validateOptionalDateRange(
  dateRange: OptionalDateRange,
  ruleIndex: number
): ValidationError[] {
  const errors = [] as ValidationError[];
  if (dateRange.start && dateRange.end) {
    const startDate = toDayjs(dateRange.start);
    const endDate = toDayjs(dateRange.end);
    if (startDate.isAfter(endDate)) {
      errors.push({
        path: `rules/${ruleIndex}/dateRange`,
        message: `日付の範囲が不正です。`,
      });
    }
  }
  return errors;
}

export function validateSchedules(schedules: Schedule[], ruleIndex: number) {
  const errors = schedules.flatMap((schedule, index) => {
    return validateSchedule(schedule, ruleIndex, index);
  });
  const duplcatedErrors: ValidationError[] = [];
  const weeklyConditions = schedules
    .map((s) => s.condition)
    .filter((c) => c.type == 'weekly') as WeeklyCondition[];
  const allDayOfWeeks = weeklyConditions.flatMap((c) => c.dayOfWeeks);
  if (allDayOfWeeks.length != new Set(allDayOfWeeks).size) {
    duplcatedErrors.push({
      path: `rules/${ruleIndex}/schedules/weekly`,
      message: `曜日が重複しています。`,
    });
  }
  const allHolidays = weeklyConditions.filter((c) => c.holiday);
  if (allHolidays.length > 1) {
    duplcatedErrors.push({
      path: `rules/${ruleIndex}/schedules/weekly`,
      message: `祝日が重複しています。`,
    });
  }
  const monthlyConditions = schedules
    .map((s) => s.condition)
    .filter((c) => c.type == 'monthly') as MonthlyCondition[];
  const allDates = monthlyConditions.flatMap((c) => c.dates);
  if (allDates.length != new Set(allDates).size) {
    duplcatedErrors.push({
      path: `rules/${ruleIndex}/schedules/monthly`,
      message: `日付が重複しています。`,
    });
  }
  return [...errors, ...duplcatedErrors];
}

export function validateSchedule(
  schedule: Schedule,
  ruleIndex: number,
  scheduleIndex: number
): ValidationError[] {
  const errors = [] as ValidationError[];
  return [
    ...validateScheduleCondition(schedule.condition, ruleIndex, scheduleIndex),
    ...validateTimeRangeCapacities(
      schedule.timeRanges,
      ruleIndex,
      scheduleIndex
    ),
  ];
}

export function validateScheduleCondition(
  condition: ScheduleCondition,
  ruleIndex: number,
  scheduleIndex: number
): ValidationError[] {
  if (condition.type == 'weekly') {
    return validateWeeklyCondition(condition, ruleIndex, scheduleIndex);
  } else if (condition.type == 'monthly') {
    return validateMonthlyCondition(condition, ruleIndex, scheduleIndex);
  } else if (condition.type == 'custom-weekly') {
    return validateCustomWeeklyCondition(condition, ruleIndex, scheduleIndex);
  } else if (condition.type == 'always') {
    return validateAlwaysCondition(condition, ruleIndex, scheduleIndex);
  } else {
    return [];
  }
}

export function validateCustomWeeklyCondition(
  condition: CustomWeeklyCondition,
  ruleIndex: number,
  scheduleIndex: number
): ValidationError[] {
  const dayOfWeekErrors = condition.dayOfWeeks.flatMap((data) => {
    if (dayOfWeeksIndeies[data.dayOfWeek] === undefined) {
      return [
        {
          path: `rules/${ruleIndex}/schedules/${scheduleIndex}/condition`,
          message: `不正な曜日が含まれています。`,
        },
      ];
    }
    if (data.target > 5) {
      return [
        {
          path: `rules/${ruleIndex}/schedules/${scheduleIndex}/condition`,
          message: `不正な週が含まれています。`,
        },
      ];
    }
    return [];
  });
  return [...dayOfWeekErrors];
}

export function validateWeeklyCondition(
  condition: WeeklyCondition,
  ruleIndex: number,
  scheduleIndex: number
): ValidationError[] {
  const dayOfWeekErrors = condition.dayOfWeeks.flatMap((dayOfWeek) => {
    if (dayOfWeeksIndeies[dayOfWeek] === undefined) {
      return [
        {
          path: `rules/${ruleIndex}/schedules/${scheduleIndex}/condition`,
          message: `不正な曜日が含まれています。`,
        },
      ];
    }
    return [];
  });
  return [...dayOfWeekErrors];
}

export function validateMonthlyCondition(
  condition: MonthlyCondition,
  ruleIndex: number,
  scheduleIndex: number
): ValidationError[] {
  const datesErrors = condition.dates.flatMap((date) => {
    if (date < 1 || date > 31) {
      return [
        {
          path: `rules/${ruleIndex}/schedules/${scheduleIndex}/condition`,
          message: `不正な日付が含まれています。`,
        },
      ];
    }
    return [];
  });
  return [...datesErrors];
}

export function validateAlwaysCondition(
  condition: AlwaysCondition,
  ruleIndex: number,
  scheduleIndex: number
): ValidationError[] {
  return [];
  // nothing
}

export function validateTimeRangeCapacities(
  timeRangeCapacities: TimeRangeCapacity[],
  ruleIndex: number,
  scheduleIndex: number
): ValidationError[] {
  const errors = timeRangeCapacities.flatMap((timeRangeCapacity, index) => {
    return validateTimeRangeCapacity(
      timeRangeCapacity,
      ruleIndex,
      scheduleIndex,
      index
    );
  });
  const duplicatedErrors = timeRangeCapacities.flatMap(
    (timeRangeCapacity, index) => {
      const startValue = toTimeValue(timeRangeCapacity.start);
      const endValue = toTimeValue(timeRangeCapacity.end);
      return timeRangeCapacities
        .filter((range) => range != timeRangeCapacity)
        .flatMap((other) => {
          const otherStartValue = toTimeValue(other.start);
          const otherEndValue = toTimeValue(other.end);
          if (otherStartValue < endValue && startValue < otherEndValue) {
            return [
              {
                path: `rules/${ruleIndex}/schedules/${scheduleIndex}/timeRanges/${index}`,
                message: '予約枠の時間帯が重複しています。',
              },
            ];
          }
          return [];
        });
    }
  );
  return [...errors, ...duplicatedErrors];
}

export function validateTimeRangeCapacity(
  timeRangeCapacity: TimeRangeCapacity,
  ruleIndex: number,
  scheduleIndex: number,
  timeRangeIndex: number
): ValidationError[] {
  const errors = [] as ValidationError[];
  if (timeRangeCapacity.capacity && timeRangeCapacity.capacity.total < 0) {
    errors.push({
      path: `rules/${ruleIndex}/schedules/${scheduleIndex}/timeRanges/${timeRangeIndex}/capacity/total`,
      message: `予約受付可能数が不正です。`,
    });
  }

  return [
    ...errors,
    ...validateTimeRange(
      timeRangeCapacity,
      ruleIndex,
      scheduleIndex,
      timeRangeIndex
    ),
  ];
}

export function validateTimeRange(
  timeRange: TimeRange,
  ruleIndex: number,
  scheduleIndex: number,
  timeRangeIndex: number
): ValidationError[] {
  const errors = [] as ValidationError[];
  const start = toTimeValue(timeRange.start);
  const end = toTimeValue(timeRange.end);
  if (start >= end || timeRange.start.hour > 30 || timeRange.end.hour > 30) {
    errors.push({
      path: `rules/${ruleIndex}/schedules/${scheduleIndex}/timeRanges/${timeRangeIndex}`,
      message: `時間の範囲が不正です。`,
    });
  }
  return errors;
}

export function validateTimeRanges(
  timeRanges: TimeRange[],
  ruleIndex: number,
  scheduleIndex: number
): ValidationError[] {
  const errors: ValidationError[] = [];
  timeRanges.forEach((t, index) => {
    timeRanges.forEach((otherT, j) => {
      if (index === j) {
        return;
      }
      if (isOverlappingOfTimeRanges(t, otherT)) {
        errors.push({
          path: `rules/${ruleIndex}/schedules/${scheduleIndex}/timeRanges/${index}`,
          message: '時間の範囲が重複しています。',
        });
      }
    });
  });
  return errors;
}
