import { Shop } from '../../../@interfaces/shop';

export const getSeparateShopIds = (
  shops: Shop[],
  selectedShopIds: number[]
) => {
  const notApplicableShopIds: number[] = [];
  const applicableShopIds: number[] = [];
  selectedShopIds.forEach((selectedShopId) => {
    if (shops.map((shop) => shop.id).includes(selectedShopId)) {
      applicableShopIds.push(selectedShopId);
    } else {
      notApplicableShopIds.push(selectedShopId);
    }
  });

  return {
    notApplicableShopIds,
    applicableShopIds,
  };
};
