import axios, { AxiosResponse } from 'axios';
import { useContext } from 'react';
import useSWR from 'swr';
import { WorkspaceCourse } from '../@interfaces/course';
import { Store } from '../context/GlobalStore';
import { API_END_POINT } from './api';

const fetcher = async (url: string, token: string) => {
  return axios
    .get(url, {
      headers: {
        Authorization: token,
      },
    })
    .then((response: AxiosResponse) => response.data);
};

export default function useWorkspaceCourses(workspaceUid: string): {
  courses: WorkspaceCourse[];
  isLoadingCourses: boolean;
  reloadCourses: () => void;
} {
  const { globalState } = useContext(Store);

  const { data, isLoading, mutate } = useSWR(
    globalState.session?.idToken
      ? [
          `${API_END_POINT}/app/ws/${workspaceUid}/courses`,
          globalState.session.idToken,
        ]
      : null,
    async ([url, token]) => fetcher(url, token)
  );
  const reload = () => {
    void mutate([
      `${API_END_POINT}/app/ws/${workspaceUid}/courses`,
      globalState.session?.idToken,
    ]);
  };

  return {
    courses: data?.courses || [],
    isLoadingCourses: isLoading,
    reloadCourses: reload,
  };
}
