import axios from 'axios';
import dayjs from 'dayjs';
import { useContext, useEffect, useState } from 'react';
import { Information, NewInformation } from '../../../@interfaces/information';
import { API_END_POINT } from '../../../api/api';
import { Store } from '../../../context/GlobalStore';
import { MixedInformation } from '../types/mixed-information';

interface InformationData {
  informations: Information[];
}

let cache: MixedInformation[] = [];

export default function useInformations(): {
  informations: MixedInformation[];
  isLoadingInformations: boolean;
  reloadInformations: () => void;
} {
  const { globalState } = useContext(Store);
  const [informations, setInformations] = useState(cache);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    reload();
  }, [globalState.session?.idToken]);

  const reload = (forceLoading = false) => {
    if (!globalState.session?.idToken) {
      cache = [];
      setInformations([]);
      return;
    }
    if (cache.length === 0 || forceLoading) {
      setLoading(true);
    }
    Promise.all([
      // 管理画面のAPIから取得
      axios.get<InformationData>(`${API_END_POINT}/app/informations`, {
        headers: {
          Authorization: globalState.session?.idToken,
        },
      }),
      // 公式サイトのお知らせのRSSから取得
      axios.get(`https://locaop.jp/news/reservation/feed/`, {
        responseType: 'document',
      }),
    ])
      .then((res) => {
        const [res1, res2] = res;

        // APIのインフォメーション
        const adminInformations = res1.data.informations as MixedInformation[];

        // 公式サイトのお知らせのRSSをインフォメーションに変換
        const rss = res2.data as Document;
        const siteInformations = Array.from(rss.querySelectorAll('item'))
          .slice(0, 5)
          .map((item) => {
            const info: MixedInformation = {
              id: -1,
              releaseDate: dayjs(
                item.querySelector('pubDate')?.textContent
              ).format('YYYY-MM-DD HH:mm:ss'), // JST
              title: item.querySelector('title')?.textContent || '',
              contentHtml: item.querySelector('encoded')?.textContent || null,
              url: item.querySelector('link')?.textContent || '',
            };
            return info;
          });
        // 日付の降順に並び替え
        const allInformations = [
          ...adminInformations,
          ...siteInformations,
        ].sort((i1, i2) => {
          return i1.releaseDate < i2.releaseDate ? 1 : -1;
        });

        setInformations(allInformations);
        cache = allInformations;
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return {
    informations,
    isLoadingInformations: loading,
    reloadInformations: reload,
  };
}

export const addInformation = async (
  idToken: string,
  information: NewInformation
): Promise<unknown> => {
  return axios.post(`${API_END_POINT}/app/informations`, information, {
    headers: {
      Authorization: idToken,
    },
  });
};

export const updateInformation = async (
  idToken: string,
  information: Information
): Promise<unknown> => {
  return axios.put(
    `${API_END_POINT}/app/informations/${information.id}`,
    information,
    {
      headers: {
        Authorization: idToken,
      },
    }
  );
};

export const deleteInformation = async (
  idToken: string,
  informationId: number
): Promise<unknown> => {
  return axios.delete(`${API_END_POINT}/app/informations/${informationId}`, {
    headers: {
      Authorization: idToken,
    },
  });
};
