import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import dayjs from 'dayjs';
import React from 'react';
import { ShopAccountWithAccountNameAndLastLoginAt } from '../@interfaces/shop-account';
import { commonCss } from '../components/common-css';

interface Props {
  shopAccounts: ShopAccountWithAccountNameAndLastLoginAt[];
  onRemove: (shopAccount: ShopAccountWithAccountNameAndLastLoginAt) => void;
}

export default function ShopAccountsTable(props: Props) {
  const { shopAccounts, onRemove } = props;

  return (
    <TableContainer component={Paper}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>
              登録済み
              <br css={commonCss.forSP} />
              メンバー({shopAccounts.length})
            </TableCell>
            <TableCell>最終ログイン</TableCell>
            <TableCell>アクション</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {shopAccounts.map((shopAccount, index) => (
            <TableRow key={shopAccount.id}>
              <TableCell scope="row">{shopAccount.name}</TableCell>
              <TableCell scope="row">
                {shopAccount.lastLoginAt &&
                  dayjs(shopAccount.lastLoginAt).format('YYYY/MM/DD HH:mm:ss')}
              </TableCell>
              <TableCell component="th">
                <Button
                  color="primary"
                  startIcon={<DeleteIcon />}
                  onClick={() => {
                    onRemove(shopAccount);
                  }}
                >
                  店舗のメンバーから外す
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
