import axios, { AxiosResponse } from 'axios';
import { useCallback, useContext, useEffect, useState } from 'react';
import { ResponseBody } from '../../../frontend-api/src/handlers/line/line-messaging-channel-handler';
import { LineMessagingChannel } from '../@interfaces/line-messaging-channel';
import { Store } from '../context/GlobalStore';
import { API_END_POINT } from './api';

export default function useLineMessagingChannel(
  workspaceUid: string,
  channelId: string | undefined
) {
  const { globalState } = useContext(Store);
  const [lineMessagingChannel, setLineMessagingChannel] =
    useState<LineMessagingChannel>();
  const [loading, setLoading] = useState(false);
  const reload = useCallback(() => {
    if (!globalState.session?.idToken || !channelId) {
      return;
    }
    setLoading(true);
    axios
      .get(
        `${API_END_POINT}/app/ws/${workspaceUid}/line/messaging-channels/${channelId}`,
        {
          headers: {
            Authorization: globalState.session?.idToken,
          },
        }
      )
      .then((response: AxiosResponse<ResponseBody>) => {
        setLineMessagingChannel(response.data.lineMessagingChannel);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [workspaceUid, channelId, globalState.session?.idToken]);

  useEffect(() => {
    reload();
  }, [reload]);
  return {
    lineMessagingChannel,
    isLoadingLineMessagingChannel: loading,
  };
}
