import {
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { useState } from 'react';
import { ResourceGroupWithMember } from '../../../@interfaces/resource/resource-group';
import { DraggableDialog } from '../../../components/DraggableDialog';
import Spinner from '../../../components/Spinner';
import { useResourceGroups } from '../api/getResourceGroups';
import EditIcon from '@material-ui/icons/Edit';
import { ResourceGroupCreateOrUpdate } from './ResourceGroupCreateOrUpdate';

type ResourceGroupListProps = {
  shopId: string;
  resourceGroupsQuery: {
    data: ResourceGroupWithMember[];
    isLoading: boolean;
    reload: () => void;
  };
};

export const ResourceGroupList: React.VFC<ResourceGroupListProps> = (props) => {
  const { shopId, resourceGroupsQuery } = props;
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [editData, setEditData] = useState<ResourceGroupWithMember>();

  if (resourceGroupsQuery.isLoading) {
    return <Spinner loading={resourceGroupsQuery.isLoading} />;
  }

  const handleAdd = () => {
    setEditData(undefined);
    setDialogOpen(true);
  };

  const handleEdit = (resourceGroup: ResourceGroupWithMember) => {
    setEditData(resourceGroup);
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const reloadData = () => {
    resourceGroupsQuery.reload();
  };

  return (
    <>
      <Grid
        container
        spacing={3}
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item>
          <h3>リソースグループ({resourceGroupsQuery.data?.length})</h3>
        </Grid>
        <Grid item>
          <Button onClick={handleAdd} color="primary" variant="contained">
            追加
          </Button>
        </Grid>
      </Grid>
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>リソースグループ</TableCell>
              <TableCell>リソース</TableCell>
              <TableCell>アクション</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {resourceGroupsQuery.data.map((resource, index) => (
              <TableRow key={resource.id}>
                <TableCell scope="row">{resource.name}</TableCell>
                <TableCell scope="row">
                  {resource.members.map((member) => {
                    return <div key={member.id}>{member.name}</div>;
                  })}
                </TableCell>
                <TableCell scope="row">
                  <Button
                    color="primary"
                    startIcon={<EditIcon />}
                    onClick={() => {
                      handleEdit(resource);
                    }}
                  >
                    編集
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <DraggableDialog
        open={dialogOpen}
        closeFunc={() => {
          reloadData();
          handleDialogClose();
        }}
        title={editData ? 'リソースグループの編集' : 'リソースグループの作成'}
      >
        <ResourceGroupCreateOrUpdate
          editData={editData}
          dialogClose={handleDialogClose}
          reloadData={reloadData}
        />
      </DraggableDialog>
    </>
  );
};
