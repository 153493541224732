/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import {
  Box,
  Button,
  Checkbox,
  DialogActions,
  DialogContent,
  FormControl,
  FormControlLabel,
  Grid,
  Input,
  MenuItem,
  TextField,
  Typography,
} from '@material-ui/core';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import { Autocomplete } from '@material-ui/lab';
import { useSnackbar } from 'notistack';
import React, { ChangeEvent, useEffect, useState } from 'react';
import useReactRouter from 'use-react-router';
import { NewResourceRequestData } from '../../../@interfaces/resource/apis';
import { ResourceWithResourceGroups } from '../../../@interfaces/resource/resource';
import { ResourceMember } from '../../../@interfaces/resource/resource-group';
import { ValidationError } from '../../../core/types/reservation-types-validation';
import { useCreateResources } from '../api/createResources';
import { useDeleteResources } from '../api/deleteResources';
import { useResourceGroups } from '../api/getResourceGroups';
import { useResourceSchedulePatterns } from '../api/getResourceSchedulePatterns';
import { useUpdateResources } from '../api/updateResources';
import { toPatternRangeString } from './common';

const styles = {
  preview: (color: string) =>
    css`
      display: inline-block;
      padding: 2px 12px;
      background-color: ${color === '' ? '#FFFFFF' : color};
    `,
  reserveSetting: css`
    display: flex;
    justify-content: flex-start;
    align-items: center;
  `,
  error: css`
    color: red;
  `,
};

interface Props {
  editData?: ResourceWithResourceGroups;
  dialogClose: () => void;
  reloadData: () => void;
}

export const ResourceCreateOrUpdate: React.VFC<Props> = ({
  editData,
  dialogClose,
  reloadData,
}) => {
  const { match } = useReactRouter<{ shopId: string }>();
  const { shopId } = match.params;
  const [isAvailableSubmit, setIsAvailableSubmit] = useState<boolean>(true);
  const { enqueueSnackbar } = useSnackbar();
  const resourceGroups = useResourceGroups(shopId);
  const resourceSchedulePatterns = useResourceSchedulePatterns(shopId);
  const createResources = useCreateResources();
  const updateResources = useUpdateResources();
  const deleteResources = useDeleteResources();
  const [selectedOptions, setSelectedOptions] = useState<ResourceMember[]>([]);
  const [form, setForm] = useState<NewResourceRequestData>({
    name: '',
    groupIds: [],
  });
  const [errors, setErrors] = useState<ValidationError[]>([]);

  useEffect(() => {
    setForm({
      ...form,
      groupIds: selectedOptions.map((option) => option.id),
    });
  }, [selectedOptions]);

  useEffect(() => {
    if (editData) {
      setForm({
        name: editData.name,
        resourceSchedulePatternId: editData.resourceSchedulePatternId,
        groupIds: editData.groups.map((group) => group.id),
      });
      setSelectedOptions(editData.groups);
    }
  }, [editData]);

  const isValidationError = (): boolean => {
    // パターン名チェック
    if (form.name.trim() === '') {
      setErrors([
        {
          message: 'リソースを入力してください。',
          path: 'resource/pattern_name',
        },
      ]);
      return true;
    }

    return false;
  };

  const removeError = (path: string) => {
    const updateData = errors.filter((error) => {
      return error.path !== path;
    });
    setErrors(updateData);
  };

  const handleChangeName = (e: ChangeEvent<HTMLInputElement>) => {
    const name = e.target.value;
    setForm({ ...form, name });
  };

  const handleChangeResourceSchedulePatternId = (
    e: ChangeEvent<HTMLInputElement>
  ) => {
    const resourceSchedulePatternId = e.target.value
      ? parseInt(e.target.value)
      : undefined;
    setForm({ ...form, resourceSchedulePatternId });
  };

  const clickSave = (isContinue: boolean) => {
    if (isValidationError()) return;
    setIsAvailableSubmit(false);
    createResources(shopId, form)
      .then(() => {
        enqueueSnackbar(`リソースを追加しました。`, { variant: 'success' });
      })
      .catch((e) => {
        enqueueSnackbar('リソースの追加に失敗しました。', {
          variant: 'error',
        });
        console.error(e);
      })
      .finally(() => {
        setIsAvailableSubmit(true);
        reloadData();
        if (!isContinue) {
          dialogClose();
        } else {
          setForm({ ...form, name: '' });
        }
      });
  };

  const clickEdit = () => {
    if (isValidationError()) return;
    if (editData) {
      setIsAvailableSubmit(false);
      updateResources(shopId, editData.id, form)
        .then(() => {
          enqueueSnackbar(`リソースを編集しました。`, {
            variant: 'success',
          });
        })
        .catch((e) => {
          enqueueSnackbar('リソースの編集に失敗しました。', {
            variant: 'error',
          });
          console.error(e);
        })
        .finally(() => {
          setIsAvailableSubmit(true);
          reloadData();
          dialogClose();
        });
    }
  };

  const clickCancel = () => {
    reloadData();
    dialogClose();
  };

  const clickDelete = () => {
    if (
      editData &&
      prompt('このリソースを削除するには「削除」と入力してください。') ===
        '削除'
    ) {
      setIsAvailableSubmit(false);
      deleteResources(shopId, editData.id)
        .then(() => {
          enqueueSnackbar(`リソースを削除しました。`, {
            variant: 'success',
          });
        })
        .catch((e) => {
          enqueueSnackbar('リソースの削除に失敗しました。', {
            variant: 'error',
          });
          console.error(e);
        })
        .finally(() => {
          setIsAvailableSubmit(true);
          reloadData();
          dialogClose();
        });
    }
  };

  const handleChange = (
    event: React.ChangeEvent<{}>,
    newValue: ResourceMember[]
  ) => {
    setSelectedOptions(newValue);
  };

  return (
    <div>
      <DialogContent>
        {resourceGroups.data.length === 0 && (
          <h3
            style={{
              color: 'red',
              fontSize: '16px',
              position: 'absolute',
              top: '40px',
            }}
          >
            ※リソースグループを作成してから、リソースを作成してください
          </h3>
        )}
        <FormControl
          required
          component="fieldset"
          fullWidth
          style={{ marginTop: resourceGroups.data.length === 0 ? '24px' : '0' }}
        >
          <Box>
            <Typography color="primary">リソース</Typography>
            <FormControlLabel
              control={
                <Input
                  fullWidth
                  type="text"
                  value={form.name}
                  onChange={handleChangeName}
                  inputProps={{ maxLength: 30 }}
                  onFocus={() => {
                    removeError('resource/pattern_name');
                  }}
                />
              }
              labelPlacement="top"
              label={``}
              css={css`
                width: 100%;
                margin: 0;
              `}
            />
            <ErrorMessage errors={errors} path="resource/pattern_name" />
          </Box>
          <Box mt={2}>
            <Typography color="primary">リソースグループ</Typography>
            <Box>
              <Autocomplete
                id="multiple-resource-group-tags"
                multiple
                fullWidth
                disableCloseOnSelect
                options={resourceGroups.data.map((group) => ({
                  id: group.id,
                  name: group.name,
                }))}
                value={selectedOptions}
                onChange={handleChange}
                getOptionLabel={(option) => option.name}
                renderOption={(option) => (
                  <React.Fragment>
                    <Checkbox
                      icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                      checkedIcon={<CheckBoxIcon fontSize="small" />}
                      style={{ marginRight: 8 }}
                      checked={form.groupIds.includes(option.id)}
                    />
                    {option.name}
                  </React.Fragment>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label="リソースグループを選択"
                    helperText="リソースが所属するリソースグループを選択します。"
                  />
                )}
                getOptionSelected={(option, value) => option.id === value.id}
                css={css`
                  min-width: 210px;
                `}
              />
            </Box>
          </Box>
          <Box mt={2}>
            <Typography color="primary">デフォルトの稼働パターン</Typography>
            <Box>
              <TextField
                select
                value={form.resourceSchedulePatternId ?? 0}
                onChange={handleChangeResourceSchedulePatternId}
                css={css`
                  background-color: ${resourceSchedulePatterns.data.find(
                    (p) => p.id === form.resourceSchedulePatternId
                  )?.color ?? 'inherit'};
                `}
                fullWidth
              >
                <MenuItem value={0}>未設定</MenuItem>
                {resourceSchedulePatterns.data.map((pattern) => (
                  <MenuItem
                    key={pattern.id}
                    value={pattern.id}
                    css={css`
                      background-color: ${pattern.color} !important;
                    `}
                  >
                    {pattern.name}({toPatternRangeString(pattern)})
                  </MenuItem>
                ))}
              </TextField>
            </Box>
          </Box>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          css={css`
            margin-top: 24px;
          `}
        >
          <Grid
            item
            css={css`
              margin-right: 32px;
            `}
          >
            {editData && (
              <Button variant="contained" onClick={clickDelete}>
                削除
              </Button>
            )}
          </Grid>
          <Grid
            item
            container
            justifyContent="flex-end"
            alignItems="center"
            css={css`
              width: fit-content;
            `}
          >
            <Button
              color="primary"
              variant="outlined"
              css={css`
                margin-right: 16px;
              `}
              onClick={clickCancel}
            >
              キャンセル
            </Button>
            {!editData && (
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  clickSave(true);
                }}
                disabled={!isAvailableSubmit || errors.length > 0}
                style={{ marginRight: '16px' }}
              >
                保存後に続けて入力
              </Button>
            )}
            <Button
              color="primary"
              variant="contained"
              onClick={
                editData
                  ? clickEdit
                  : () => {
                      clickSave(false);
                    }
              }
              disabled={!isAvailableSubmit || errors.length > 0}
            >
              保存
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </div>
  );
};

function ErrorMessage(props: { errors: ValidationError[]; path: string }) {
  const { errors, path } = props;

  const error = errors.find((e) => e.path == path);
  if (!error) {
    return null;
  }

  return <div css={styles.error}>{error.message}</div>;
}
