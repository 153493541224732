/** @jsxImportSource @emotion/react */

import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import React from 'react';
import { InflowSource } from '../../../@interfaces/inflow-source';
import LinkRouter from '../../../components/LinkRouter';

interface Props {
  workspaceUid: string;
  inflowSources: InflowSource[];
}

export default function WorkspaceInflowSourcesTable(props: Props) {
  const { workspaceUid, inflowSources } = props;

  return (
    <TableContainer component={Paper}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>流入元</TableCell>
            <TableCell>アクション</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {inflowSources.map((inflowSource, index) => (
            <TableRow key={inflowSource.id}>
              <TableCell scope="row">
                {inflowSource.name}({inflowSource.uid})
              </TableCell>
              <TableCell scope="row">
                <LinkRouter
                  to={`/a/${workspaceUid}/settings/inflow-sources/${inflowSource.id}`}
                >
                  編集
                </LinkRouter>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
