import { ResourceConstantSetting } from '../../../@interfaces/course-slot-setting';
import {
  PararelResourceConstant,
  PararelResourceTarget,
  ResourceConstant,
  ResourceConstantType,
  SerialResourceConstant,
  SerialResourceTarget,
} from '../../../core/types/reservation-resource-types';
import { randomString } from '../../../utils/string';

export const createDefaultPararelResourceTarget = (): PararelResourceTarget => {
  return {
    uid: randomString(6),
    resourceGroupId: -1,
    assignmentRule: 'select',
  };
};

export const createDefaultSerialResourceTarget = (): SerialResourceTarget => {
  return {
    uid: randomString(6),
    resourceGroupId: -1,
    minutesRequired: 30,
    assignmentRule: 'select',
  };
};

export const createDefaultPararelResourceConstant =
  (): PararelResourceConstant => {
    return {
      type: 'pararel',
      targets: [createDefaultPararelResourceTarget()],
    };
  };

export const createDefaultSerialResourceConstant =
  (): SerialResourceConstant => {
    return {
      type: 'serial',
      orderRule: 'ordered',
      targets: [createDefaultSerialResourceTarget()],
    };
  };

export const createDefaultResourceConstantSetting =
  (): ResourceConstantSetting => {
    return {
      constants: [createDefaultPararelResourceConstant()],
    };
  };

export const createDefaultResourceConstant = (
  type: ResourceConstantType
): ResourceConstant => {
  switch (type) {
    case 'pararel': {
      return createDefaultPararelResourceConstant();
    }
    case 'serial': {
      return createDefaultSerialResourceConstant();
    }
    default: {
      const _exhaustiveCheck: never = type;
      throw new Error(`Invalid type: type=${type}`);
    }
  }
};
