/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  MenuItem,
  Select,
} from '@material-ui/core';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import React, { useContext, useState } from 'react';
import useReactRouter from 'use-react-router';
import {
  SimpleCourse,
  SlotSettingWithCourses,
} from '../../../@interfaces/slot-setting';
import { API_END_POINT } from '../../../api/api';
import useShop from '../../../api/use-shop';
import useSlotSettings from '../../../api/use-slot-settings';
import useWorkspaceCourses from '../../../api/use-workspace-courses';
import {
  BreadcrumbLinkItem,
  BreadcrumbShopItem,
  BreadcrumbTextItem,
  BreadcrumbWorkspaceItem,
} from '../../../components/breadcrumb/BreadcrumbItem';
import { commonCss } from '../../../components/common-css';
import { ShopPageLayout } from '../../../components/layouts/ShopPageLayout';
import { PageTitleAndDescription } from '../../../components/PageTitleAndDescription';
import {
  FullscreenLoading,
  Head,
  Main,
  Root,
} from '../../../components/Shared';
import Spinner from '../../../components/Spinner';
import { Store } from '../../../context/GlobalStore';
import { SM_BREAKPOINT } from '../../../hooks/use-size-type';
import { globalColors } from '../../../styles/globalColors';
import { helps } from '../../../utils/helps';
import ShopCourseChooserTable from './ShopCourseChooserTable';

const styles = {
  shareCourses: css`
    position: relative;
    padding-left: 16px;
    font-size: 14px;
    &:before {
      content: '';
      position: absolute;
      top: 8px;
      left: 4px;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background: rgba(0, 0, 0, 0.54);
    }
  `,
  dialogTitle: css`
    padding-bottom: 0;
    @media screen and (max-width: ${SM_BREAKPOINT}px) {
      padding: 16px;
    }
  `,
  dialogContent: css`
    @media screen and (max-width: ${SM_BREAKPOINT}px) {
      padding: 8px 16px;
    }
  `,
};

interface PageParams {
  workspaceUid: string;
  shopId: string;
}

export default function ShopCourseChooserPage(props: any) {
  const { globalState, setGlobalState } = useContext(Store);
  const { history, match } = useReactRouter<PageParams>();
  const { workspaceUid, shopId } = match.params;
  const { shop, isLoadingShop } = useShop(shopId);
  const { slotSettings, reloadSlotSettings } = useSlotSettings(shopId);
  const { courses, isLoadingCourses } = useWorkspaceCourses(workspaceUid);
  const [selectedSlotSettings, setSelectedSlotSettings] = useState<
    SlotSettingWithCourses[]
  >([]);

  const [openShareDialog, setOpenShareDialog] = useState(false);
  const [baseSlotSettingId, setBaseSlotSettingId] = useState<number>(-1);

  const [openUnshareDialog, setOpenUnshareDialog] = useState(false);
  const [seledctedUnshareSlotSetting, setSeledctedUnshareSlotSetting] =
    useState<SlotSettingWithCourses | undefined>(undefined);
  const [keepCourseId, setKeepCourseId] = useState<number>(-1);

  const [openBackdrop, setOpenBackdrop] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const handleChangeSelectedSlotSettings = (
    newSelectedSlotSettings: SlotSettingWithCourses[]
  ) => {
    setSelectedSlotSettings(newSelectedSlotSettings);
  };

  const handleShowShareDialog = () => {
    setOpenShareDialog(true);
  };
  const handleHideShareDialog = () => {
    setOpenShareDialog(false);
    setBaseSlotSettingId(-1);
  };

  const handleEnableSlotSetting = (courseId: number) => {
    setOpenBackdrop(true);
    const json = {
      courseId,
    };
    axios
      .post(`${API_END_POINT}/app/shops/${shopId}/slot-settings`, json, {
        headers: {
          Authorization: globalState.session?.idToken,
        },
      })
      .then(() => {
        enqueueSnackbar('コースを使用するに変更しました。', {
          variant: 'success',
        });
        reloadSlotSettings();
      })
      .catch((e) => {
        enqueueSnackbar('コースを使用するに変更できませんでした。', {
          variant: 'error',
        });
      })
      .finally(() => {
        setOpenBackdrop(false);
      });
  };

  const handleDisableSlotSetting = (slotSettingId: number) => {
    setOpenBackdrop(true);
    axios
      .delete(
        `${API_END_POINT}/app/shops/${shopId}/slot-settings/${slotSettingId}`,
        {
          headers: {
            Authorization: globalState.session?.idToken,
          },
        }
      )
      .then(() => {
        enqueueSnackbar('コースを未使用に変更しました。', {
          variant: 'success',
        });
        reloadSlotSettings();
      })
      .catch((e) => {
        enqueueSnackbar('コースを未使用に変更できませんでした。', {
          variant: 'error',
        });
      })
      .finally(() => {
        setOpenBackdrop(false);
      });
  };

  const handleSelectKeepCourseId = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    const newKeepCourseId = event.target.value as number;
    setKeepCourseId(newKeepCourseId);
  };

  const handleUnshare = () => {
    if (!seledctedUnshareSlotSetting) {
      return;
    }
    const json = {
      keepCourseId,
    };
    setOpenBackdrop(true);
    axios
      .post(
        `${API_END_POINT}/app/shops/${shopId}/slot-settings/${seledctedUnshareSlotSetting.id}/unshare`,
        json,
        {
          headers: {
            Authorization: globalState.session?.idToken,
          },
        }
      )
      .then(() => {
        enqueueSnackbar('予約枠の共有を解除しました。', { variant: 'success' });
        reloadSlotSettings();
        setKeepCourseId(-1);
      })
      .catch((e) => {
        enqueueSnackbar('予約枠の共有を解除できませんでした。', {
          variant: 'error',
        });
      })
      .finally(() => {
        setOpenBackdrop(false);
        setOpenUnshareDialog(false);
      });
  };

  const handleShowUnshareDialog = (slotSettingId: number) => {
    const slotSetting = slotSettings.find((s) => s.id == slotSettingId);
    setSeledctedUnshareSlotSetting(slotSetting);
    setOpenUnshareDialog(true);
  };

  const handleHideUnshareDialog = () => {
    setOpenUnshareDialog(false);
    setKeepCourseId(-1);
  };

  const handleClickCancel = () => {
    history.push(`/a/${workspaceUid}/shops/${shopId}/settings/slot-settings`);
  };

  const buildRightNavigationButtons = () => {
    return (
      <>
        <Button
          css={commonCss.button.right}
          type="button"
          variant="text"
          color="primary"
          onClick={handleClickCancel}
        >
          戻る
        </Button>
      </>
    );
  };

  const handleSelectBaseSlotSettingId = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    const newBaseSlotSettingId = event.target.value as number;
    setBaseSlotSettingId(newBaseSlotSettingId);
  };

  const handleShare = () => {
    const slotSettingIds = selectedSlotSettings.map((s) => s.id);
    const json = {
      baseSlotSettingId,
      slotSettingIds,
    };
    setOpenBackdrop(true);
    axios
      .post(`${API_END_POINT}/app/shops/${shopId}/slot-settings/share`, json, {
        headers: {
          Authorization: globalState.session?.idToken,
        },
      })
      .then(() => {
        enqueueSnackbar('予約枠を共有しました。', { variant: 'success' });
        setSelectedSlotSettings([]);
        setBaseSlotSettingId(-1);
        reloadSlotSettings();
      })
      .catch((e) => {
        enqueueSnackbar('予約枠を共有できませんでした。', { variant: 'error' });
      })
      .finally(() => {
        setOpenBackdrop(false);
        setOpenShareDialog(false);
      });
  };

  const buildShareDialog = (allCourses: SimpleCourse[]) => {
    return (
      <Dialog open={openShareDialog} onClose={handleHideShareDialog}>
        <DialogTitle css={styles.dialogTitle}>コースの予約枠を共有</DialogTitle>
        <DialogContent css={styles.dialogContent}>
          <DialogContentText>
            <h3
              css={css`
                margin: 0 auto 16px;
                font-weight: normal;
              `}
            >
              予約枠を共有するコース
            </h3>
            {selectedSlotSettings.map((slotSetting) => {
              return (
                <div css={styles.shareCourses}>
                  {slotSetting.courses.map((c) => c.name).join(' / ')}
                </div>
              );
            })}
          </DialogContentText>
          <Select
            value={baseSlotSettingId}
            css={css`
              min-width: 100px;
            `}
            onChange={handleSelectBaseSlotSettingId}
          >
            <MenuItem value={-1}>ベースの予約枠設定を選択</MenuItem>
            {selectedSlotSettings.map((slotSetting) => {
              return (
                <MenuItem value={slotSetting.id}>
                  {slotSetting.courses.map((c) => c.name).join('/')}
                </MenuItem>
              );
            })}
          </Select>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleHideShareDialog} color="primary">
            キャンセル
          </Button>
          <Button
            onClick={handleShare}
            color="primary"
            disabled={baseSlotSettingId == -1}
          >
            予約枠を共有する
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const buildActionBar = () => {
    if (selectedSlotSettings.length < 2) {
      return null;
    }
    const allCourses = selectedSlotSettings.flatMap((s) => s.courses);
    return (
      <div
        css={css`
          padding: 10px 0px;
        `}
      >
        <Button
          color="primary"
          variant="contained"
          onClick={handleShowShareDialog}
        >
          選択した{allCourses.length}個のコースの予約枠設定を共有...
        </Button>
        {buildShareDialog(allCourses)}
      </div>
    );
  };

  const buildUnshareDialog = () => {
    if (!seledctedUnshareSlotSetting) {
      return;
    }
    return (
      <Dialog open={openUnshareDialog} onClose={handleHideUnshareDialog}>
        <DialogTitle>コースの予約枠を解除</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {seledctedUnshareSlotSetting.courses.map((c) => c.name).join('/')}
            の予約枠の共有を解除します。
          </DialogContentText>
          <Select
            value={keepCourseId}
            css={css`
              min-width: 100px;
            `}
            onChange={handleSelectKeepCourseId}
          >
            <MenuItem value={-1}>
              解除後に現在の予約枠設定を紐付けるコースを選択してください
            </MenuItem>
            {seledctedUnshareSlotSetting.courses.map((course) => {
              return <MenuItem value={course.id}>{course.name}</MenuItem>;
            })}
          </Select>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleHideUnshareDialog} color="primary">
            キャンセル
          </Button>
          <Button
            onClick={handleUnshare}
            color="primary"
            disabled={keepCourseId == -1}
          >
            予約枠を解除する
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const buildContents = () => {
    if (isLoadingCourses || isLoadingShop) {
      return <Spinner loading={true} />;
    }

    return (
      <>
        <Grid container alignItems="center">
          <Grid item xs={6}>
            <h3>
              この店舗で使用する
              <br css={commonCss.forSP} />
              コースを編集
            </h3>
          </Grid>
          <Grid
            item
            container
            xs={6}
            alignItems="center"
            justifyContent="flex-end"
          >
            {buildRightNavigationButtons()}
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item container xs={12} alignItems="center">
            {buildActionBar()}
            <ShopCourseChooserTable
              courses={courses}
              selectedSlotSettings={selectedSlotSettings}
              slotSettings={slotSettings}
              onChangeSelectedSlotSettings={handleChangeSelectedSlotSettings}
              onEnableSlotSetting={handleEnableSlotSetting}
              onDisableSlotSetting={handleDisableSlotSetting}
              onUnshareSlotSetting={handleShowUnshareDialog}
            />
            {buildUnshareDialog()}
          </Grid>
        </Grid>
        <Grid container alignItems="center">
          <Grid item xs={6}></Grid>
          <Grid
            item
            container
            xs={6}
            alignItems="center"
            justifyContent="flex-end"
          >
            {buildRightNavigationButtons()}
          </Grid>
        </Grid>
      </>
    );
  };

  const pageTitle = 'コース選択';

  return (
    <Root>
      <Head title={`コース - ${shop?.name || ''}`} />
      <Main>
        <ShopPageLayout
          workspaceUid={workspaceUid}
          shopId={shopId}
          current="slot-basic"
          helpId={helps.shop.setting.courseChoose}
          breadcrumbs={
            <Breadcrumbs aria-label="breadcrumb">
              <BreadcrumbLinkItem to={`/`}>ホーム</BreadcrumbLinkItem>
              <BreadcrumbWorkspaceItem workspaceUid={workspaceUid} />
              <BreadcrumbShopItem workspaceUid={workspaceUid} shop={shop} />
              <BreadcrumbTextItem>{pageTitle}</BreadcrumbTextItem>
            </Breadcrumbs>
          }
        >
          <PageTitleAndDescription
            title="店舗"
            subTitle={pageTitle}
            description="店舗で使用するコースの選択を行います。"
            themeColor={globalColors.shop}
          />
          {buildContents()}
        </ShopPageLayout>
      </Main>
      <FullscreenLoading open={openBackdrop} />
    </Root>
  );
}
