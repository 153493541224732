import { Time, TimeFormat, zeroPadding } from '../core/types/reservation-types';

export function timeString(time: Time): TimeFormat {
  return `${zeroPadding(time.hour.toString(), 2)}:${zeroPadding(
    time.minute.toString(),
    2
  )}` as TimeFormat;
}

export const isSameTimeRange = (newTimeRange: Time, savedTimeRange: Time) =>
  newTimeRange.hour === savedTimeRange.hour &&
  newTimeRange.minute === savedTimeRange.minute;

export const isBeforeTimeRange = (
  targetTimeRange: Time,
  comparisonTimeRange: Time
) =>
  targetTimeRange.hour < comparisonTimeRange.hour ||
  (targetTimeRange.hour === comparisonTimeRange.hour &&
    targetTimeRange.minute < comparisonTimeRange.minute);

export const isAfterTimeRange = (
  targetTimeRange: Time,
  comparisonTimeRange: Time
) =>
  targetTimeRange.hour > comparisonTimeRange.hour ||
  (targetTimeRange.hour === comparisonTimeRange.hour &&
    targetTimeRange.minute > comparisonTimeRange.minute);

export const isBeforeOrEqualTimeRange = (
  targetTimeRange: Time,
  comparisonTimeRange: Time
) =>
  targetTimeRange.hour < comparisonTimeRange.hour ||
  (targetTimeRange.hour === comparisonTimeRange.hour &&
    targetTimeRange.minute <= comparisonTimeRange.minute);

export const isAfterOrEqualTimeRange = (
  targetTimeRange: Time,
  comparisonTimeRange: Time
) =>
  targetTimeRange.hour > comparisonTimeRange.hour ||
  (targetTimeRange.hour === comparisonTimeRange.hour &&
    targetTimeRange.minute >= comparisonTimeRange.minute);

export const isBetweenTimeRange = (
  targetTimeRange: Time,
  startTimeRange: Time,
  endTimeRange: Time
) =>
  isAfterOrEqualTimeRange(targetTimeRange, startTimeRange) &&
  isBeforeOrEqualTimeRange(targetTimeRange, endTimeRange);
