/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { Button, Input } from '@material-ui/core';
import { useState } from 'react';
import { isFQDN } from '../../../@interfaces/custom-domain/base';

export const CustomDomainCreator = ({
  onCreateCustomDomain,
}: {
  onCreateCustomDomain: (fqdn: string) => void;
}) => {
  const [domain, setDomain] = useState('');

  return (
    <>
      <Input
        css={css`
          width: 500px;
        `}
        value={domain}
        onChange={(e) => {
          setDomain(e.target.value);
        }}
        placeholder="追加したいドメインを入力"
      />
      <Button
        color="primary"
        variant="contained"
        css={css`
          margin-left: 10px;
        `}
        disabled={!isFQDN(domain)}
        onClick={() => {
          onCreateCustomDomain(domain);
          setDomain('');
        }}
      >
        独自ドメイン追加
      </Button>
    </>
  );
};
