/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
} from '@material-ui/core';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import axios from 'axios';
import React, { ChangeEvent, useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import useReactRouter from 'use-react-router';
import {
  ShopNotifyDestination,
  UpdateShopNotifyDestinationRequest,
} from '../../../@interfaces/shop-notify-destination';
import { API_END_POINT } from '../../../api/api';
import useShop from '../../../api/use-shop';
import useShopNotifyDestinations from '../../../api/use-shop-notify-destinations';
import {
  BreadcrumbLinkItem,
  BreadcrumbShopItem,
  BreadcrumbTextItem,
  BreadcrumbWorkspaceItem,
} from '../../../components/breadcrumb/BreadcrumbItem';
import { ShopPageLayout } from '../../../components/layouts/ShopPageLayout';
import { PageTitleAndDescription } from '../../../components/PageTitleAndDescription';
import {
  FullscreenLoading,
  Head,
  Main,
  Root,
} from '../../../components/Shared';
import Spinner from '../../../components/Spinner';
import { Store } from '../../../context/GlobalStore';
import { globalColors } from '../../../styles/globalColors';
import ShopNotifyDestinationsTable from '../../../tables/ShopNotifyDestinationsTable';
import { helps } from '../../../utils/helps';

const styles = {
  inputEmail: css({
    width: '300px',
    marginRight: '10px',
  }),
};

interface PageParams {
  workspaceUid: string;
  shopId: string;
}

export default function ShopNotifyDestinationSettingPage(props: any) {
  const { globalState } = useContext(Store);
  const { match } = useReactRouter<PageParams>();
  const { workspaceUid, shopId } = match.params;
  const validationContext = useForm();
  const { handleSubmit } = validationContext;

  const { shop } = useShop(shopId);
  const {
    shopNotifyDestinations,
    isLoadingShopNotifyDestinations,
    reloadShopNotifyDestinations,
  } = useShopNotifyDestinations(shopId);
  const [email, setEmail] = useState('');
  const [isReservationEnabled, setIsReservationEnabled] = useState(true);
  const [isReportEnabled, setIsReportEnabled] = useState(true);
  const [openBackdrop, setOpenBackdrop] = useState(false);

  const handleChangeEmail = (e: ChangeEvent<HTMLInputElement>) => {
    const email = e.target.value;
    setEmail(email);
  };

  const handleSubmitForm = (e: React.FormEvent) => {
    if (email.length == 0) {
      return;
    }
    const json = {
      email,
      isReservationEnabled,
      isReportEnabled,
    };
    setOpenBackdrop(true);
    axios
      .post(`${API_END_POINT}/app/shops/${shopId}/destinations`, json, {
        headers: {
          Authorization: globalState.session?.idToken,
        },
      })
      .then(() => {
        reloadShopNotifyDestinations();
        setEmail('');
        setIsReservationEnabled(true);
        setIsReportEnabled(true);
      })
      .finally(() => {
        setOpenBackdrop(false);
      });
  };

  const handleRemove = (destination: ShopNotifyDestination) => {
    setOpenBackdrop(true);
    axios
      .delete(
        `${API_END_POINT}/app/shops/${shopId}/destinations/${destination.id}`,
        {
          headers: {
            Authorization: globalState.session?.idToken,
          },
        }
      )
      .then(() => {
        reloadShopNotifyDestinations();
      })
      .finally(() => {
        setOpenBackdrop(false);
      });
  };

  const handleUpdate = async (destination: ShopNotifyDestination) => {
    setOpenBackdrop(true);

    const request: UpdateShopNotifyDestinationRequest = {
      isReservationEnabled: destination.isReservationEnabled,
      isReportEnabled: destination.isReportEnabled,
    };

    try {
      await axios.put(
        `${API_END_POINT}/app/shops/${shopId}/destinations/${destination.id}`,
        request,
        {
          headers: {
            Authorization: globalState.session?.idToken,
          },
        }
      );

      reloadShopNotifyDestinations();
    } catch (e) {
      console.error(e);
    } finally {
      setOpenBackdrop(false);
    }
  };

  const buildContents = () => (
    <>
      <Grid container spacing={3}>
        <Grid item container xs={12} alignItems="center">
          <Grid item xs={12}>
            <h3>通知先</h3>
          </Grid>
          <form
            onSubmit={handleSubmit(handleSubmitForm)}
            className="reservation-form"
          >
            <TextField
              value={email}
              type="email"
              placeholder="メールアドレス"
              css={styles.inputEmail}
              required={true}
              onChange={handleChangeEmail}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={isReservationEnabled}
                  onChange={() => {
                    setIsReservationEnabled(!isReservationEnabled);
                  }}
                />
              }
              label="予約通知"
            />

            <FormControlLabel
              control={
                <Checkbox
                  checked={isReportEnabled}
                  onChange={() => {
                    setIsReportEnabled(!isReportEnabled);
                  }}
                />
              }
              label="統計データ(月次レポート)受信"
            />
            <Button type="submit" variant="contained" color="primary">
              通知先を追加
            </Button>
          </form>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item container xs={12} alignItems="center">
          {isLoadingShopNotifyDestinations ? (
            <Spinner loading={true} />
          ) : (
            <ShopNotifyDestinationsTable
              destinations={shopNotifyDestinations}
              onRemove={handleRemove}
              onUpdate={handleUpdate}
            />
          )}
        </Grid>
      </Grid>
    </>
  );

  const pageTitle = '通知設定';

  return (
    <Root>
      <Head title={`通知先メールアドレス - ${shop?.name || ''}`} />
      <Main>
        <ShopPageLayout
          workspaceUid={workspaceUid}
          shopId={shopId}
          current="notification"
          helpId={helps.shop.setting.destination}
          breadcrumbs={
            <Breadcrumbs aria-label="breadcrumb">
              <BreadcrumbLinkItem to={`/`}>ホーム</BreadcrumbLinkItem>
              <BreadcrumbWorkspaceItem workspaceUid={workspaceUid} />
              <BreadcrumbShopItem workspaceUid={workspaceUid} shop={shop} />
              <BreadcrumbTextItem>{pageTitle}</BreadcrumbTextItem>
            </Breadcrumbs>
          }
        >
          <PageTitleAndDescription
            title="店舗"
            subTitle={pageTitle}
            description="予約や統計データ受信用の通知先の設定を行います。"
            themeColor={globalColors.shop}
          />
          {buildContents()}
        </ShopPageLayout>
      </Main>
      <FullscreenLoading open={openBackdrop} />
    </Root>
  );
}
