import {
  Button,
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { ShopNotifyDestination } from '../@interfaces/shop-notify-destination';

interface Props {
  destinations: ShopNotifyDestination[];
  onRemove: (destination: ShopNotifyDestination) => void;
  onUpdate: (destination: ShopNotifyDestination) => void;
}

export default function ShopNotifyDestinationsTable(props: Props) {
  const { destinations, onRemove, onUpdate } = props;

  return (
    <TableContainer component={Paper}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>通知先メールアドレス({destinations.length})</TableCell>
            <TableCell align="center">予約通知</TableCell>
            <TableCell align="center">統計データ(月次レポート)受信</TableCell>
            <TableCell align="center">アクション</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {destinations.map((destination, index) => (
            <TableRow key={destination.id}>
              <TableCell scope="row">{destination.email}</TableCell>
              <TableCell scope="row" align="center">
                <Checkbox
                  checked={Boolean(destination.isReservationEnabled)}
                  onChange={() => {
                    onUpdate({
                      ...destination,
                      isReservationEnabled: !destination.isReservationEnabled,
                    });
                  }}
                />
              </TableCell>
              <TableCell scope="row" align="center">
                <Checkbox
                  checked={Boolean(destination.isReportEnabled)}
                  onChange={() => {
                    onUpdate({
                      ...destination,
                      isReportEnabled: !destination.isReportEnabled,
                    });
                  }}
                />
              </TableCell>
              <TableCell component="th" align="center">
                <Button
                  color="primary"
                  startIcon={<DeleteIcon />}
                  onClick={() => {
                    onRemove(destination);
                  }}
                >
                  削除
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
