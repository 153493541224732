/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { CustomerInfo } from '../../../../@interfaces/customer';
import { toRelativeLastRelativeDate } from '../../utils/relative-last-reservation-date';

const styles = {
  reservationCount: css`
    font-size: 20px;
  `,
  canceledReservationCount: css`
    font-size: 12px;
    color: #777;
  `,
  canceledReservationCountAlert: css`
    color: red;
    font-weight: bold;
  `,
  lastReservationInfo: css`
    font-size: 12px;
    color: #777;
    margin-left: 5px;
  `,
};

export const ReservationCountInfo = ({
  customer,
}: {
  customer: CustomerInfo;
}): JSX.Element => {
  const lastInfo = toRelativeLastRelativeDate(customer.lastReservationDate);
  return (
    <div
      css={css`
        display: flex;
        flex-direction: row;
        align-items: center;
      `}
    >
      <span css={styles.reservationCount}>{customer.reservationCount}</span>
      <span
        css={[
          styles.canceledReservationCount,
          customer.canceledReservationCount > 0
            ? styles.canceledReservationCountAlert
            : null,
        ]}
      >
        ({customer.canceledReservationCount})
      </span>
      {lastInfo ? (
        <span css={styles.lastReservationInfo}>{lastInfo}</span>
      ) : null}
    </div>
  );
};
