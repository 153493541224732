import { useCallback, useMemo, useState } from 'react';
import { FormSetting } from '../../../core/types/reservation-form-types';
import { Preview } from '../components/Preview';
import { sendPreviewDataToIframe } from '../models/sendPreviewDataToIframe';

const IFRAME_ID = 'form-fields-preview';

export const useFormFieldsPreview = () => {
  const [previewOpen, setPreviewOpen] = useState(false);
  const [initialized, setInitialized] = useState(false);

  const preview = useMemo(() => {
    const previewUrl = `${process.env.REACT_APP_PUBLIC_BASE_URL}/preview/form-fields`;
    setInitialized(true);
    return (
      <Preview
        iframeId={IFRAME_ID}
        previewUrl={previewUrl}
        previewOpen={previewOpen}
        setPreviewOpen={setPreviewOpen}
      />
    );
  }, [previewOpen]);

  const updatePreview = useCallback(
    (formSetting: FormSetting) => {
      sendPreviewDataToIframe(
        IFRAME_ID,
        'updateFormFieldsPreview',
        {
          fields: formSetting.fields,
        },
        initialized ? 1000 : 0
      );
    },
    [initialized]
  );

  return {
    preview,
    previewOpen,
    updatePreview,
  };
};
