/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Input,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import DeleteIcon from '@material-ui/icons/Delete';
import { Autocomplete } from '@material-ui/lab';
import { useSnackbar } from 'notistack';
import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';
import useReactRouter from 'use-react-router';
import { NewResourceGroupRequestData } from '../../../@interfaces/resource/apis';
import {
  ResourceGroupWithMember,
  ResourceMember,
} from '../../../@interfaces/resource/resource-group';
import { ErrorMessage } from '../../../components/ErrorMessage';
import { ValidationError } from '../../../core/types/reservation-types-validation';
import { useCreateResourceGroups } from '../api/createResourceGroups';
import { useDeleteResourceGroups } from '../api/deleteResourceGroups';
import { useResources } from '../api/getResources';
import { useUpdateResourceGroups } from '../api/updateResourceGroups';

interface Props {
  editData?: ResourceGroupWithMember;
  dialogClose: () => void;
  reloadData: () => void;
}

export const ResourceGroupCreateOrUpdate: React.VFC<Props> = ({
  editData,
  dialogClose,
  reloadData,
}) => {
  const { match } = useReactRouter<{ shopId: string }>();
  const { shopId } = match.params;
  const [isAvailableSubmit, setIsAvailableSubmit] = useState<boolean>(true);
  const { enqueueSnackbar } = useSnackbar();
  const resourcesQuery = useResources(shopId);
  const createResources = useCreateResourceGroups();
  const updateResources = useUpdateResourceGroups();
  const deleteResources = useDeleteResourceGroups();
  const [form, setForm] = useState<NewResourceGroupRequestData>({
    name: '',
    memberIds: [],
  });
  const [pendingOptions, setPendingOptions] = React.useState<ResourceMember[]>(
    []
  );
  const allOptions = useMemo(() => {
    return (
      resourcesQuery?.data.map((member) => ({
        id: member.id,
        name: member.name,
      })) || []
    );
  }, [resourcesQuery?.data]);
  const selectedOptions: { id: number; name: string }[] = useMemo(() => {
    return form.memberIds.map(
      (memberId) =>
        allOptions.find((o) => o.id === memberId) || {
          id: memberId,
          name: '削除されたリソース',
        }
    );
  }, [allOptions, form.memberIds]);
  const unSelectedMembers = useMemo(() => {
    const selectedIds = selectedOptions.map((o) => o.id);
    const pendingIds = pendingOptions.map((o) => o.id);
    return allOptions.filter(
      (o) => !selectedIds.includes(o.id) && !pendingIds.includes(o.id)
    );
  }, [allOptions, selectedOptions, pendingOptions]);

  const [errors, setErrors] = useState<ValidationError[]>([]);

  useEffect(() => {
    if (editData) {
      setForm({
        name: editData.name,
        memberIds: editData.members.map((group) => group.id),
      });
    }
  }, [editData]);

  const isValidationError = (): boolean => {
    // パターン名チェック
    if (form.name.trim() === '') {
      setErrors([
        {
          message: 'リソースグループ名を入力してください。',
          path: 'resource-group/resource-group-name',
        },
      ]);
      return true;
    }

    return false;
  };

  const removeError = (path: string) => {
    const updateData = errors.filter((error) => {
      return error.path !== path;
    });
    setErrors(updateData);
  };

  const handleChangeName = (e: ChangeEvent<HTMLInputElement>) => {
    const name = e.target.value;
    setForm({ ...form, name });
  };

  const clickSave = (isContinue: boolean) => {
    if (isValidationError()) return;
    setIsAvailableSubmit(false);
    createResources(shopId, form)
      .then(() => {
        enqueueSnackbar(`リソースグループを追加しました。`, {
          variant: 'success',
        });
      })
      .catch((e) => {
        enqueueSnackbar('リソースグループの追加に失敗しました。', {
          variant: 'error',
        });
        console.error(e);
      })
      .finally(() => {
        setIsAvailableSubmit(true);
        reloadData();
        if (!isContinue) {
          dialogClose();
        }
      });
  };

  const clickEdit = () => {
    if (isValidationError()) return;
    if (editData) {
      setIsAvailableSubmit(false);
      updateResources(shopId, editData.id, form)
        .then(() => {
          enqueueSnackbar(`リソースグループを編集しました。`, {
            variant: 'success',
          });
        })
        .catch((e) => {
          enqueueSnackbar('リソースグループの編集に失敗しました。', {
            variant: 'error',
          });
          console.error(e);
        })
        .finally(() => {
          setIsAvailableSubmit(true);
          reloadData();
          dialogClose();
        });
    }
  };

  const clickCancel = () => {
    reloadData();
    dialogClose();
  };

  const clickDelete = () => {
    if (
      editData &&
      prompt(
        'このリソースグループを削除するには「削除」と入力してください。'
      ) === '削除'
    ) {
      setIsAvailableSubmit(false);
      deleteResources(shopId, editData.id)
        .then(() => {
          enqueueSnackbar(`リソースグループを削除しました。`, {
            variant: 'success',
          });
        })
        .catch((e) => {
          enqueueSnackbar('リソースグループの削除に失敗しました。', {
            variant: 'error',
          });
          console.error(e);
        })
        .finally(() => {
          setIsAvailableSubmit(true);
          reloadData();
          dialogClose();
        });
    }
  };

  const handleAddPendingMembers = (event: React.ChangeEvent<{}>) => {
    setForm({
      ...form,
      memberIds: [...form.memberIds, ...pendingOptions.map((o) => o.id)],
    });
    setPendingOptions([]);
  };

  const removeSelectedMember = (target: ResourceMember) => {
    const newMemberIds = form.memberIds.filter(
      (memberId) => memberId !== target.id
    );
    setForm({ ...form, memberIds: newMemberIds });
  };

  const handleClickUp = (target: ResourceMember) => {
    const newMemberIds = [...form.memberIds];
    const index = newMemberIds.indexOf(target.id);
    if (index < 1) {
      return;
    }
    newMemberIds.splice(index, 1);
    newMemberIds.splice(index - 1, 0, target.id);
    setForm({ ...form, memberIds: newMemberIds });
  };

  const handleClickDown = (target: ResourceMember) => {
    const newMemberIds = [...form.memberIds];
    const index = newMemberIds.indexOf(target.id);
    if (index === -1 || index === newMemberIds.length - 1) {
      return;
    }
    newMemberIds.splice(index, 1);
    newMemberIds.splice(index + 1, 0, target.id);
    setForm({ ...form, memberIds: newMemberIds });
  };

  return (
    <div>
      <DialogContent>
        <FormControl required component="fieldset" fullWidth>
          <Box>
            <Typography color="primary">リソースグループ名</Typography>
            <FormControlLabel
              control={
                <Input
                  fullWidth
                  type="text"
                  value={form.name}
                  onChange={handleChangeName}
                  onFocus={() => {
                    removeError('resource-group/resource-group-name');
                  }}
                />
              }
              labelPlacement="top"
              label={``}
              css={css`
                width: 100%;
                margin: 0;
              `}
            />
            <ErrorMessage
              errors={errors}
              path="resource-group/resource-group-name"
            />
          </Box>
          <Box mt={2}>
            <Grid container alignItems="center">
              <Grid xs={10} item>
                <Autocomplete
                  multiple
                  value={pendingOptions}
                  onChange={(event, newValue) => {
                    setPendingOptions(newValue);
                  }}
                  options={unSelectedMembers}
                  getOptionLabel={(option) => option.name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      label="リソースを選択"
                      helperText="リソースグループに追加するリソースを選択します。"
                    />
                  )}
                  getOptionSelected={(option, value) => option.id === value.id}
                  css={css`
                    min-width: 220px;
                  `}
                />
              </Grid>
              <Grid xs={2} item>
                <Button variant="contained" onClick={handleAddPendingMembers}>
                  追加
                </Button>
              </Grid>
            </Grid>
            <h3>リソース({selectedOptions.length})</h3>
            <Box>
              <Table size="small">
                <TableBody>
                  {selectedOptions.map((option, index) => {
                    return (
                      <TableRow key={option.id}>
                        <TableCell>{option.name}</TableCell>
                        <TableCell
                          css={css`
                            width: 120px;
                          `}
                        >
                          <Tooltip title="上へ移動">
                            <IconButton
                              size="small"
                              edge="end"
                              onClick={() => {
                                handleClickUp(option);
                              }}
                            >
                              <ArrowUpwardIcon />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="下へ移動">
                            <IconButton
                              size="small"
                              edge="end"
                              onClick={() => {
                                handleClickDown(option);
                              }}
                            >
                              <ArrowDownwardIcon />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="削除">
                            <IconButton
                              size="small"
                              edge="end"
                              onClick={() => {
                                removeSelectedMember(option);
                              }}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Box>
          </Box>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          css={css`
            margin-top: 24px;
          `}
        >
          <Grid
            item
            css={css`
              margin-right: 32px;
            `}
          >
            {editData && (
              <Button variant="contained" onClick={clickDelete}>
                削除
              </Button>
            )}
          </Grid>
          <Grid
            item
            container
            justifyContent="flex-end"
            alignItems="center"
            css={css`
              width: fit-content;
            `}
          >
            <Button
              color="primary"
              variant="outlined"
              css={css`
                margin-right: 16px;
              `}
              onClick={clickCancel}
            >
              キャンセル
            </Button>
            {!editData && (
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  clickSave(true);
                }}
                disabled={!isAvailableSubmit || errors.length > 0}
                style={{ marginRight: '16px' }}
              >
                保存後に続けて入力
              </Button>
            )}
            <Button
              color="primary"
              variant="contained"
              onClick={
                editData
                  ? clickEdit
                  : () => {
                      clickSave(false);
                    }
              }
              disabled={!isAvailableSubmit || errors.length > 0}
            >
              保存
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </div>
  );
};
