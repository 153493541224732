/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { Grid } from '@material-ui/core';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { Alert } from '@material-ui/lab';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import useReactRouter from 'use-react-router';
import useShop from '../../../api/use-shop';
import useShopCourses from '../../../api/use-shop-courses';
import useWorkspaceCourses from '../../../api/use-workspace-courses';
import {
  BreadcrumbLinkItem,
  BreadcrumbShopItem,
  BreadcrumbTextItem,
  BreadcrumbWorkspaceItem,
} from '../../../components/breadcrumb/BreadcrumbItem';
import { ShopPageLayout } from '../../../components/layouts/ShopPageLayout';
import { PageTitleAndDescription } from '../../../components/PageTitleAndDescription';
import { Head, Main, Root } from '../../../components/Shared';
import Spinner from '../../../components/Spinner';
import { ROLE_ADMIN } from '../../../context/GlobalStore';
import useCurrentWorkspace from '../../../context/use-current-workspace';
import { globalColors } from '../../../styles/globalColors';
import { helps } from '../../../utils/helps';
import ShopCoursesTable from './ShopCoursesTable';
import UnusedShopCoursesTable from './UnusedShopCoursesTable';

interface PageParams {
  workspaceUid: string;
  shopId: string;
}

export default function ShopCourseSettingPage(props: any) {
  const { match } = useReactRouter<PageParams>();
  const { workspaceUid, shopId } = match.params;
  const { shop, isLoadingShop } = useShop(shopId);
  const { shopCourses, isLoadingShopCourses } = useShopCourses(shopId);
  const { courses, isLoadingCourses } = useWorkspaceCourses(workspaceUid);
  const currentWorkspace = useCurrentWorkspace(workspaceUid);

  const unusedCourses = useMemo(() => {
    return courses.filter(
      (c) => shopCourses.find((sc) => sc.id === c.id) === undefined
    );
  }, [shopCourses, courses]);

  const buildContents = () => {
    return (
      <>
        <Grid container alignItems="center" justifyContent="flex-start">
          <h3>使用中のコース</h3>
        </Grid>
        <Alert
          color="info"
          css={css`
            margin-bottom: 10px;
          `}
        >
          {currentWorkspace?.role === ROLE_ADMIN ? (
            <>
              コースの追加、編集は
              <Link to={`/a/${workspaceUid}/settings`}>ワークスペース設定</Link>
              からできます。
            </>
          ) : (
            <>
              新しいコースを追加したい場合このワークスペースの管理者に依頼してください。
            </>
          )}
        </Alert>
        <Grid container spacing={3}>
          <Grid item container xs={12} alignItems="center">
            {isLoadingShopCourses || isLoadingShop ? (
              <Spinner loading={true} />
            ) : (
              <ShopCoursesTable
                workspaceUid={workspaceUid}
                shopId={shopId}
                shopUid={shop.uid}
                courses={shopCourses}
              />
            )}
          </Grid>
        </Grid>
        <Grid container alignItems="center">
          <Grid item xs={6}>
            <h3>未使用のコース</h3>
          </Grid>
          <Grid
            item
            container
            xs={6}
            alignItems="center"
            justifyContent="flex-end"
          ></Grid>
        </Grid>
        <Alert
          color="info"
          css={css`
            margin-bottom: 10px;
          `}
        >
          <Link
            to={`/a/${workspaceUid}/shops/${shopId}/settings/slot-settings`}
          >
            店舗設定の予約枠（基本設定）
          </Link>
          から使用するコースを編集することで使用できるようになります。
        </Alert>
        <Grid container spacing={3}>
          <Grid item container xs={12} alignItems="center">
            {isLoadingShopCourses || isLoadingCourses ? (
              <Spinner loading={true} />
            ) : (
              <UnusedShopCoursesTable courses={unusedCourses} />
            )}
          </Grid>
        </Grid>
      </>
    );
  };

  const pageTitle = 'コース詳細設定';

  return (
    <Root>
      <Head title={`コース - ${shop?.name || ''}`} />
      <Main>
        <ShopPageLayout
          workspaceUid={workspaceUid}
          shopId={shopId}
          current="course-detail"
          helpId={helps.shop.setting.courseSlot}
          breadcrumbs={
            <Breadcrumbs aria-label="breadcrumb">
              <BreadcrumbLinkItem to={`/`}>ホーム</BreadcrumbLinkItem>
              <BreadcrumbWorkspaceItem workspaceUid={workspaceUid} />
              <BreadcrumbShopItem workspaceUid={workspaceUid} shop={shop} />
              <BreadcrumbTextItem>{pageTitle}</BreadcrumbTextItem>
            </Breadcrumbs>
          }
        >
          <PageTitleAndDescription
            title="店舗"
            subTitle={pageTitle}
            description="使用中のコースごとの表示設定や所要時間の設定を行います。"
            themeColor={globalColors.shop}
          />
          {buildContents()}
        </ShopPageLayout>
      </Main>
    </Root>
  );
}
