import { AxiosResponse } from 'axios';
import { UpdateResourceSchedulePatternRequestData } from '../../../@interfaces/resource/apis';
import {} from '../../../@interfaces/resource/resource-schedule-pattern';
import { API_END_POINT } from '../../../api/api';
import { useMutation } from '../../common/api/use-mutation';

export const useUpdateResourceSchedulePatterns = (): ((
  shopId: string,
  resourceSchedulePatternId: number,
  params: UpdateResourceSchedulePatternRequestData
) => Promise<AxiosResponse<''>>) => {
  const { request } = useMutation<
    '',
    UpdateResourceSchedulePatternRequestData
  >();
  return (shopId, resourceSchedulePatternId, params) => {
    return request(
      'put',
      `${API_END_POINT}/app/shops/${shopId}/resource-schedule-patterns/${resourceSchedulePatternId}`,
      {},
      params
    );
  };
};
