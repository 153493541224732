import { Breadcrumbs } from '@material-ui/core';
import useReactRouter from 'use-react-router';
import useShop from '../../../api/use-shop';
import {
  BreadcrumbLinkItem,
  BreadcrumbShopItem,
  BreadcrumbTextItem,
  BreadcrumbWorkspaceItem,
} from '../../../components/breadcrumb/BreadcrumbItem';
import { ShopPageLayout } from '../../../components/layouts/ShopPageLayout';
import { PageTitleAndDescription } from '../../../components/PageTitleAndDescription';
import { Head, Main, Root } from '../../../components/Shared';
import { useResourceGroups, useResources } from '../../../features/resource';
import { useResourceSchedulePatterns } from '../../../features/resource/api/getResourceSchedulePatterns';
import { ResourceList } from '../../../features/resource/components/ResourceList';
import { ResourceTutorial } from '../../../features/resource/components/ResourceTutorial';
import { globalColors } from '../../../styles/globalColors';
import { helps } from '../../../utils/helps';

interface PageParams {
  workspaceUid: string;
  shopId: string;
}

export default function ShopResourceListSettingPage(): JSX.Element {
  const { match } = useReactRouter<PageParams>();
  const { workspaceUid, shopId } = match.params;
  const { shop } = useShop(shopId);
  const resourceGroupsQuery = useResourceGroups(shopId);
  const resourcesQuery = useResources(shopId);
  const resourceSchedulePatternQuery = useResourceSchedulePatterns(shopId);

  const pageTitle = 'リソース';

  return (
    <Root>
      <Head title={`店舗メンバー - ${shop?.name || ''}`} />
      <Main>
        <ShopPageLayout
          workspaceUid={workspaceUid}
          shopId={shopId}
          current="resource-list"
          helpId={helps.shop.setting.member}
          breadcrumbs={
            <Breadcrumbs aria-label="breadcrumb">
              <BreadcrumbLinkItem to={`/`}>ホーム</BreadcrumbLinkItem>
              <BreadcrumbWorkspaceItem workspaceUid={workspaceUid} />
              <BreadcrumbShopItem workspaceUid={workspaceUid} shop={shop} />
              <BreadcrumbTextItem>{pageTitle}</BreadcrumbTextItem>
            </Breadcrumbs>
          }
        >
          <PageTitleAndDescription
            title="店舗"
            subTitle={pageTitle}
            description="リソースの登録、稼働パターン、管理単位の設定を行います。"
            themeColor={globalColors.shop}
          />
          <ResourceTutorial
            workspaceUid={workspaceUid}
            shopId={shopId}
            resourceGroupsQuery={resourceGroupsQuery}
            resourcesQuery={resourcesQuery}
            resourceSchedulePatternQuery={resourceSchedulePatternQuery}
          />
          <ResourceList
            shopId={shopId}
            resourcesQuery={resourcesQuery}
            resourceSchedulePatternQuery={resourceSchedulePatternQuery}
          />
        </ShopPageLayout>
      </Main>
    </Root>
  );
}
