import {
  Button,
  FormControl,
  Grid,
  MenuItem,
  Paper,
  Select,
} from '@material-ui/core';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import React, { useContext, useEffect, useState } from 'react';
import useReactRouter from 'use-react-router';
import useCreateGoogleCalendarIntegration from '../../../api/integration/use-create-google-calendar-integration';
import useShop from '../../../api/use-shop';
import {
  BreadcrumbLinkItem,
  BreadcrumbShopItem,
  BreadcrumbTextItem,
  BreadcrumbWorkspaceItem,
} from '../../../components/breadcrumb/BreadcrumbItem';
import { ShopPageLayout } from '../../../components/layouts/ShopPageLayout';
import { Head, Main, Root } from '../../../components/Shared';
import { useStyles } from '../../../components/Styles';
import { Store } from '../../../context/GlobalStore';
import { helps } from '../../../utils/helps';

interface PageParams {
  workspaceUid: string;
  shopId: string;
}
type State = {
  calendars: any;
};

type Calendar = {
  id: string;
  summary: string;
  primary: boolean;
};

export default function ShopIntegrationsSettingEditPage(props: any) {
  const { globalState } = useContext(Store);
  const { match } = useReactRouter<PageParams>();
  const { workspaceUid, shopId } = match.params;
  const { shop } = useShop(shopId);
  const { isLoadingIntegration, googleCalendarIntegrationApi } =
    useCreateGoogleCalendarIntegration();
  const { location, history } = useReactRouter();
  const state = location.state as State;
  const primaryCalendar = state.calendars.calendars.find(
    (calendar: Calendar) => calendar.primary
  );
  const [calendarId, setCalendarId] = useState<string>(primaryCalendar.id);
  const [isSave, setIsSave] = useState<boolean>(false);
  const [calendarSummary, setCalendarSummary] = useState<string>(
    primaryCalendar.summary
  );
  const handleChangeCalendar = (
    e: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    const calendarValue = e.target.value as string;
    setCalendarId(calendarValue);
    const calendar = state.calendars.calendars.find(
      (calendar: Calendar) => calendar.id === calendarValue
    );
    setCalendarSummary(calendar.summary);
  };
  const handleSave = () => {
    if (globalState.signedUser?.account.id) {
      googleCalendarIntegrationApi(
        shopId,
        state.calendars.primaryId,
        globalState.signedUser?.account.id,
        calendarId,
        calendarSummary
      );
      setIsSave(true);
    }
  };
  useEffect(() => {
    if (!isLoadingIntegration && isSave) {
      history.push(`/a/${workspaceUid}/shops/${shopId}/settings/integrations`);
    }
  }, [isLoadingIntegration, isSave, history, workspaceUid, shopId]);
  const styles = useStyles();

  const buildContents = () => (
    <>
      <Grid item container xs={12}>
        <h3>アプリ統合</h3>
      </Grid>
      <Paper className={styles.paper}>
        <Grid item container xs={12} alignItems="center">
          <span>
            {primaryCalendar.id} {'　>　'}{' '}
          </span>
          <FormControl>
            <Select
              defaultValue={primaryCalendar.id}
              label="カレンダー"
              style={{ width: '200px' }}
              onChange={handleChangeCalendar}
            >
              {state.calendars.calendars.map((d: any) => (
                <MenuItem key={d.id} value={d.id}>
                  {d.summary}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <span>のカレンダーに全ての予約を登録</span>
        </Grid>
        <Grid item container xs={12} alignItems="center" justify="flex-end">
          <Button
            onClick={() => {
              handleSave();
            }}
            variant="contained"
            color="primary"
            size="medium"
          >
            連携を追加する
          </Button>
        </Grid>
      </Paper>
    </>
  );

  return (
    <Root>
      <Head title={`アプリ統合 - ${shop?.name || ''}`} />
      <Main>
        <ShopPageLayout
          workspaceUid={workspaceUid}
          shopId={shopId}
          current="integrations"
          helpId={helps.shop.setting.courseSlot}
          breadcrumbs={
            <Breadcrumbs aria-label="breadcrumb">
              <BreadcrumbLinkItem to={`/`}>ホーム</BreadcrumbLinkItem>
              <BreadcrumbWorkspaceItem workspaceUid={workspaceUid} />
              <BreadcrumbShopItem workspaceUid={workspaceUid} shop={shop} />
              <BreadcrumbLinkItem
                to={`/a/${workspaceUid}/shops/${shopId}/settings/integrations`}
              >
                Googleカレンダー連携
              </BreadcrumbLinkItem>
              <BreadcrumbTextItem>連携の追加</BreadcrumbTextItem>
            </Breadcrumbs>
          }
        >
          {buildContents()}
        </ShopPageLayout>
      </Main>
    </Root>
  );
}
