import { ResourceSchedulePatternListResponse } from '../../../@interfaces/resource/apis';
import { API_END_POINT } from '../../../api/api';
import { useQuery } from '../../common/api/use-query';

export const useResourceSchedulePatterns = (shopId: string) => {
  const { response, isLoading, reload } =
    useQuery<ResourceSchedulePatternListResponse>(
      `${API_END_POINT}/app/shops/${shopId}/resource-schedule-patterns`
    );
  return {
    data: response?.patterns || [],
    isLoading: isLoading,
    reload: reload,
  };
};
