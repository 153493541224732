/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { Box, Button, Grid, Paper, TextField } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import HomeRoudedIcon from '@material-ui/icons/HomeRounded';
import React, { ChangeEvent, FormEvent, useState } from 'react';
import useReactRouter from 'use-react-router';
import { newCognitoUser } from '../../api/auth/cognito';
import LinkRouter from '../../components/LinkRouter';
import {
  Head,
  Main,
  Root,
  TopAppBar,
  TopAppBarSpacer,
} from '../../components/Shared';
import { useStyles } from '../../components/Styles';
import { useSizeType } from '../../hooks/use-size-type';

interface Form {
  email: string;
  code: string;
  newPassword: string;
}

export default function PasswordReminderPage() {
  const { history } = useReactRouter();
  const [form, setForm] = useState<Form>({
    email: '',
    code: '',
    newPassword: '',
  });
  const [mode, setMode] = useState<'reset' | 'confirm'>('reset');
  const { isSpSize } = useSizeType();
  const classes = useStyles();

  const handleSubmitReset = (e: FormEvent) => {
    e.preventDefault();
    if (form.email) {
      newCognitoUser(form.email).forgotPassword({
        onSuccess: function (data) {
          alert(
            `「${form.email}」に検証コードを送信しました。\nメールを確認し、検証コードと新しいパスワードを設定してください。`
          );
          setMode('confirm');
        },
        onFailure: function (err: any) {
          if (err) {
            if (err.code === 'LimitExceededException') {
              alert(
                '試行回数制限を超えましたので、しばらく時間をおいてからお試しください。'
              );
            } else {
              alert(err.message || JSON.stringify(err));
            }
          }
        },
      });
    }
  };

  const handleSubmitConfirm = (e: FormEvent) => {
    e.preventDefault();
    if (form.code && form.newPassword) {
      newCognitoUser(form.email).confirmPassword(form.code, form.newPassword, {
        onSuccess: () => {
          alert(
            'パスワードの再設定が完了しました。\n登録したメールアドレスとパスワードでログインしてください。'
          );
          history.push(`/signin`);
        },
        onFailure: (err) => {
          if (err.name == 'CodeMismatchException') {
            alert(
              '検証コードが一致しません。\n検証コードを確認してもう１度実行してください。'
            );
          } else if (
            err.name == 'InvalidPasswordException' ||
            (err.name == 'InvalidParameterException' &&
              err.message.indexOf('Member must have length greater than') != -1)
          ) {
            alert(
              `パスワードは大文字/小文字/数字を含んだ8文字以上で設定してください。`
            );
          } else {
            alert(err.message || JSON.stringify(err));
          }
        },
      });
    }
  };

  const handleChangeEmail = (e: ChangeEvent<HTMLInputElement>) => {
    form.email = e.target.value;
    setForm(Object.assign({}, form));
  };

  const handleChangeCode = (e: ChangeEvent<HTMLInputElement>) => {
    form.code = e.target.value;
    setForm(Object.assign({}, form));
  };

  const handleChangeNewPassword = (e: ChangeEvent<HTMLInputElement>) => {
    form.newPassword = e.target.value;
    setForm(Object.assign({}, form));
  };

  const createForm = () => {
    if (mode == 'reset') {
      return (
        <form onSubmit={handleSubmitReset}>
          <div>パスワードを再設定するための検証コードを送信します。</div>
          <TextField
            label="メールアドレス"
            placeholder="yamada@example.com"
            type="email"
            value={form.email}
            name="email"
            onChange={handleChangeEmail}
            margin="normal"
            fullWidth
            required
          />
          <Grid container spacing={3} alignItems="center" justify="flex-end">
            <Grid
              item
              xs={12}
              css={css`
                text-align: right;
              `}
            >
              <Button type="submit" variant="contained" color="primary">
                &nbsp;検証コードを送信&nbsp;
              </Button>
            </Grid>
          </Grid>
        </form>
      );
    } else if (mode == 'confirm') {
      return (
        <form onSubmit={handleSubmitConfirm}>
          <div>検証コードと新しいパスワードを入力してください。</div>
          <TextField
            label="検証コード"
            type="number"
            value={form.code}
            name="code"
            onChange={handleChangeCode}
            margin="normal"
            fullWidth
            required
          />
          <TextField
            label="新しいパスワード"
            placeholder="大文字、小文字、数字をすべて含んだ8文字以上で設定してください"
            type="password"
            name="newPassword"
            value={form.newPassword}
            onChange={handleChangeNewPassword}
            margin="normal"
            fullWidth
            required
          />
          <Grid container spacing={3} alignItems="center" justify="flex-end">
            <Grid
              item
              xs={12}
              alignItems="center"
              css={css`
                text-align: right;
              `}
            >
              <Button type="submit" variant="contained" color="primary">
                &nbsp;パスワードを設定&nbsp;
              </Button>
            </Grid>
          </Grid>
        </form>
      );
    } else {
      return null;
    }
  };

  return (
    <Root>
      <Head title="パスワードリマインダ" />
      <TopAppBar />
      <Main>
        <TopAppBarSpacer />
        <Container maxWidth="lg">
          <Grid
            container
            spacing={3}
            alignItems="flex-start"
            direction={isSpSize ? 'column-reverse' : 'row'}
          >
            <Grid
              item
              xs={12}
              sm={6}
              css={css`
                width: 100%;
              `}
            >
              <img src="/img/signin.svg" alt="" width="100%" />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              css={css`
                width: 100%;
              `}
            >
              <Grid container alignItems="center">
                <HomeRoudedIcon />
                <h2>パスワードを忘れた方</h2>
              </Grid>
              <Paper className={classes.paper}>{createForm()}</Paper>
              <Box
                css={css`
                  padding: 10px;
                  text-align: center;
                `}
              >
                <LinkRouter to={`/signin`}>ログイン画面に戻る</LinkRouter>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Main>
    </Root>
  );
}
