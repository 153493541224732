/** @jsxImportSource @emotion/react */

import {
  AnyField,
  FormSetting,
  TelField,
} from '../../../../../core/types/reservation-form-types';

type FormSettingFieldTelProps = {
  field: TelField;
  setting: FormSetting;
  onChangeSetting: (setting: FormSetting) => void;
  originalField: AnyField | undefined;
};

export const FormSettingFieldTel = (props: FormSettingFieldTelProps): null => {
  return null;
};
