import { Button, Grid, Paper, TextField, Typography } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import HomeRoudedIcon from '@material-ui/icons/HomeRounded';
import axios from 'axios';
import dayjs from 'dayjs';
import React, {
  ChangeEvent,
  FormEvent,
  useContext,
  useEffect,
  useState,
} from 'react';
import useReactRouter from 'use-react-router';
import { API_END_POINT } from '../../api/api';
import { signOut, signup } from '../../api/auth/cognito';
import useInvitation, { Invitation } from '../../api/use-invitation';
import {
  FullscreenLoading,
  Head,
  Main,
  Root,
  TopAppBar,
  TopAppBarSpacer,
} from '../../components/Shared';
import { useStyles } from '../../components/Styles';
import { SignedUser, Store } from '../../context/GlobalStore';
import Spinner from '../../components/Spinner';

export default function InvitationPage() {
  const { globalState } = useContext(Store);
  const url = new URL(document.URL);
  const token = url.searchParams.get('token');
  const { invitation, isLoadingInvitation, isNoContent } = useInvitation(token);

  if (isLoadingInvitation) {
    return <Spinner loading={true} />;
  } else if (isNoContent) {
    return <InvitationNoContent />;
  } else if (dayjs().isAfter(dayjs(invitation?.expiredAt))) {
    return <InvitationExpired invitation={invitation} />;
  } else if (globalState.signedUser) {
    return (
      <ExistUserSignUp
        signedUser={globalState.signedUser}
        invitation={invitation}
      />
    );
  } else {
    return <NewUserSignUp invitation={invitation} />;
  }
}

interface ExistUserSignUpProps {
  signedUser: SignedUser;
  invitation: Invitation | undefined;
}

function ExistUserSignUp(props: ExistUserSignUpProps) {
  const { history, location, match } = useReactRouter();
  const { globalState, setGlobalState } = useContext(Store);
  const { signedUser, invitation } = props;
  const classes = useStyles();

  const handleJoinWithSignedAccount = () => {
    if (!invitation) {
      return;
    }
    const joinForm = {
      token: invitation.token,
    };
    return axios
      .post(`${API_END_POINT}/app/invitation-join`, joinForm, {
        headers: {
          Authorization: globalState.session?.idToken,
        },
      })
      .then(() => {
        alert(`ワークスペース「${invitation.workspace.name}」に参加しました`);
        window.location.href = `/a/${invitation.workspace.uid}/`;
      });
  };

  const handleSignoutAndReload = () => {
    signOut();
    setGlobalState({ session: undefined, signedUser: undefined });
    window.location.reload();
  };

  const handleCancel = () => {
    history.push(`/`);
  };

  return (
    <Root>
      <Head title="ワークスペースに参加" />
      <TopAppBar />
      <Main>
        <TopAppBarSpacer />
        <Container maxWidth="lg">
          <Grid container spacing={3} alignItems="center">
            <Grid item container xs={10} alignItems="center">
              <HomeRoudedIcon />
              <h2>ワークスペースに「{invitation?.workspace.name}」参加</h2>
            </Grid>
          </Grid>
          <Paper className={classes.paper}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              onClick={handleJoinWithSignedAccount}
            >
              ログイン済みのアカウント( {signedUser.account.name} )で{' '}
              {invitation?.workspace.name} に参加する
            </Button>
            <br />
            <br />
            または
            <br />
            <br />
            <Button
              type="submit"
              variant="contained"
              color="primary"
              onClick={handleSignoutAndReload}
            >
              ログアウトして別のユーザーとして参加する
            </Button>
            <br />
            <br />
            または
            <br />
            <br />
            <Button
              type="submit"
              variant="outlined"
              color="secondary"
              onClick={handleCancel}
            >
              このワークスペースには参加しない
            </Button>
          </Paper>
        </Container>
      </Main>
    </Root>
  );
}

interface Form {
  email: string;
  name: string;
  password: string;
}

interface NewUserSignUpProps {
  invitation: Invitation | undefined;
}

function NewUserSignUp(props: NewUserSignUpProps) {
  const { invitation } = props;
  const { history, location, match } = useReactRouter();
  const [form, setForm] = useState<Form>({
    email: '',
    name: '',
    password: '',
  });
  const classes = useStyles();
  const { globalState, setGlobalState } = useContext(Store);
  const [openFullscreenLoading, setOpenFullscreenLoading] = useState(false);

  useEffect(() => {
    if (invitation) {
      setForm({ ...form, email: invitation.email });
    }
  }, [invitation]);

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    setOpenFullscreenLoading(true);
    if (form.email && form.name && form.password && invitation) {
      signup(
        form.email,
        form.name,
        form.password,
        invitation.token,
        (err, result) => {
          setOpenFullscreenLoading(false);
          if (err) {
            console.log('サインアップ時にエラーが発生しました。', {
              err,
              result,
            });
            if (err.name == 'UsernameExistsException') {
              if (form.email !== invitation.email) {
                alert(
                  `メールアドレス「${form.email}」は登録済みです。\nログイン後に招待リンクをクリックして参加するか、別のメールアドレスを使用してください。`
                );
              } else {
                // メールアドレスが変更されてない場合、
                // UnconfirmedなCogniteユーザーが作成されているとみなして続行する
                alert(
                  `メールアドレス「${form.email}」は登録済みですが、サインアップは完了していません。処理を続行します。\n名前とパスワードは以前登録されたものを使用します。`
                );
                history.push(
                  `/invitation/activation?token=${encodeURIComponent(
                    invitation.token
                  )}&email=${encodeURIComponent(form.email)}`
                );
              }
            } else if (
              err.name == 'InvalidPasswordException' ||
              (err.name == 'InvalidParameterException' &&
                err.message.includes('Member must have length greater than'))
            ) {
              alert(
                `パスワードは大文字/小文字/数字を含んだ8文字以上で設定してください。`
              );
            } else {
              alert(err.message);
            }
          } else {
            if (form.email === invitation.email && result) {
              alert(
                'サインアップが完了しました。\n登録したメールアドレスとパスワードでログインしてください。'
              );
              history.push(`/signin`);
            } else {
              console.log('アクティベーション画面に遷移します。', {
                err,
                result,
              });
              history.push(
                `/invitation/activation?token=${encodeURIComponent(
                  invitation.token
                )}&email=${encodeURIComponent(form.email)}`
              );
            }
          }
        }
      );
    }
  };

  const handleChangeEmail = (e: ChangeEvent<HTMLInputElement>) => {
    form.email = e.target.value;
    setForm(Object.assign({}, form));
  };

  const handleChangeName = (e: ChangeEvent<HTMLInputElement>) => {
    form.name = e.target.value;
    setForm(Object.assign({}, form));
  };

  const handleChangePassword = (e: ChangeEvent<HTMLInputElement>) => {
    form.password = e.target.value;
    setForm(Object.assign({}, form));
  };

  return (
    <Root>
      <TopAppBar />
      <Main>
        <TopAppBarSpacer />
        <Container maxWidth="lg">
          <Grid container spacing={3} alignItems="center">
            <Grid item container xs={10} alignItems="center">
              <HomeRoudedIcon />
              <h2>
                Locaop予約アカウントを作成してワークスペース「
                {invitation?.workspace.name}
                」参加
              </h2>
            </Grid>
          </Grid>
          <Paper className={classes.paper}>
            <form onSubmit={handleSubmit}>
              <TextField
                label="メールアドレス"
                type="email"
                value={form.email}
                name="email"
                onChange={handleChangeEmail}
                margin="normal"
                fullWidth
                disabled
              />
              <TextField
                label="名前"
                helperText="あなたの氏名を設定してください"
                type="text"
                name="name"
                value={form.name}
                onChange={handleChangeName}
                margin="normal"
                fullWidth
                required
              />
              <TextField
                label="パスワード"
                helperText="大文字、小文字、数字をすべて含んだ8文字以上で設定してください"
                type="password"
                name="password"
                value={form.password}
                onChange={handleChangePassword}
                margin="normal"
                fullWidth
                required
              />
              <Grid container spacing={3} alignItems="center">
                <Grid item container xs={6} alignItems="center"></Grid>
                <Grid
                  item
                  container
                  xs={6}
                  alignItems="center"
                  justify="flex-end"
                >
                  <Button type="submit" variant="contained" color="primary">
                    &nbsp;次へ&nbsp;
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Paper>
          <FullscreenLoading open={openFullscreenLoading} />
        </Container>
      </Main>
    </Root>
  );
}

function InvitationExpired(props: { invitation: Invitation | undefined }) {
  const classes = useStyles();
  const { invitation } = props;

  return (
    <Root>
      <TopAppBar />
      <Main>
        <TopAppBarSpacer />
        <Container maxWidth="lg">
          <Grid container spacing={3} alignItems="center">
            <Grid item container xs={10} alignItems="center">
              <HomeRoudedIcon />
              <h2>ワークスペース「{invitation?.workspace.name}」に参加</h2>
            </Grid>
          </Grid>
          <Paper className={classes.paper}>
            <Typography style={{ fontSize: 16 }}>
              この招待URLは有効期限が切れています。
              <br />
              管理者に招待URLの再発行を依頼してください。
            </Typography>
          </Paper>
        </Container>
      </Main>
    </Root>
  );
}

function InvitationNoContent() {
  const classes = useStyles();

  return (
    <Root>
      <TopAppBar />
      <Main>
        <TopAppBarSpacer />
        <Container maxWidth="lg">
          <Grid container spacing={3} alignItems="center">
            <Grid item container xs={10} alignItems="center">
              <HomeRoudedIcon />
              <h2>ワークスペースへの招待</h2>
            </Grid>
          </Grid>
          <Paper className={classes.paper}>
            <Typography style={{ fontSize: 16 }}>
              この招待URLは無効です。
              <br />
              すでに参加済みか招待がキャンセルされた可能性があります。
            </Typography>
          </Paper>
        </Container>
      </Main>
    </Root>
  );
}
