/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { Chip } from '@material-ui/core';
import PeopleIcon from '@material-ui/icons/People';
import { useState } from 'react';
import { RichCustomer } from '../../../../@interfaces/customer';
import { CustomerDialog } from '../CustomerDialog';

const styles = {
  container: css`
    &.MuiChip-colorPrimary {
      color: rgb(30, 70, 32);
      background-color: rgb(237, 247, 237);
    }
  `,
};

type ExistReservationCustomerInfoProps = {
  workspaceUid: string;
  shopId: number;
  customer: RichCustomer;
  reloadReservations: () => void;
};

export const ExistReservationCustomerInfo = ({
  workspaceUid,
  shopId,
  customer,
  reloadReservations,
}: ExistReservationCustomerInfoProps): JSX.Element => {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleUpdate = () => {
    reloadReservations();
  };

  const buildLabel = () => {
    const name = customer.name || customer.lineDisplayName || customer.email;
    return `${name} (${customer.reservationCount}回予約, ${customer.canceledReservationCount}回キャンセル)`;
  };

  return (
    <div>
      <Chip
        css={styles.container}
        color="primary"
        size="small"
        icon={<PeopleIcon />}
        label={buildLabel()}
        onClick={handleClick}
      />
      {open && (
        <CustomerDialog
          workspaceUid={workspaceUid}
          shopId={shopId}
          customerId={customer.id}
          open={open}
          onUpdate={handleUpdate}
          onClose={handleClose}
        />
      )}
    </div>
  );
};
