import React from 'react';
import sanitizeHtml from 'sanitize-html';

export const lineBreakToParagraph = (str: string) =>
  str.split('\n').map((line) => <p>{line}</p>);

export const lineBreakToBr = (str: string) =>
  str.split('\n').map((line) => (
    <>
      {line}
      <br />
    </>
  ));

export const sanitize = (str: string) => {
  return sanitizeHtml(str, {
    allowedTags: ['img', 'a', 'span', 'table', 'tr', 'th', 'td'],
    allowedAttributes: {
      img: ['src', 'width', 'height', 'style'],
      a: ['href', 'target', 'style'],
      span: ['style'],
      table: ['style'],
      tr: ['style'],
      th: ['style'],
      td: ['style'],
    },
  });
};

export const lineBreakToParagraphAndHtml = (str: string) =>
  normalize(str)
    .split('\n')
    .map((line, index) => (
      <p
        dangerouslySetInnerHTML={{ __html: line ? sanitize(line) : '&nbsp;' }}
        key={`line${index}`}
      />
    ));

/**
 * レンダリングに不要なホワイトスペースや改行を取り除きます。
 * - テーブルタグ内部の改行を全て除去します。
 * @param str
 */
export const normalize = (html: string): string => {
  // テーブルタグ内を探す正規表現パターン
  const tablePattern = /(<table[^>]*>)([\s\S]*?)(<\/table>)/gim;

  // テーブルタグ内部の改行を全て除去する
  return html.replace(
    tablePattern,
    (match, p1: string, p2: string, p3: string) => {
      return `${p1}${p2.replace(/\r?\n|\r/g, '')}${p3}`;
    }
  );
};

export const copyToClipborad = (text: string) => {
  const tmp = document.createElement('div');
  const pre = document.createElement('pre');
  pre.style.webkitUserSelect = 'auto';
  pre.style.userSelect = 'auto';
  tmp.appendChild(pre).textContent = text;

  const s = tmp.style;
  s.position = 'fixed';
  s.right = '200%';

  document.body.appendChild(tmp);
  (document as any).getSelection().selectAllChildren(tmp);
  const result = document.execCommand('copy');
  document.body.removeChild(tmp);
};

/**
 * childの親にtargetが含まれるかどうかを判定します
 * @param child 子要素
 * @param target 対象要素
 */
export const isParent = (child: Element, target: Element): boolean => {
  const parent = child.parentElement;
  if (!parent) {
    return false;
  } else if (target == parent) {
    return true;
  } else {
    return isParent(parent, target);
  }
};

export interface HTMLElementEvent<T extends HTMLElement> extends Event {
  target: T;
}
