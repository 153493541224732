import axios, { AxiosResponse } from 'axios';
import dayjs from 'dayjs';
import { useContext, useEffect, useState } from 'react';
import { ResponseBody } from '../../../frontend-api/src/handlers/shop/shop-reports-handler';
import { ShopReport, ShopReportGroupingType } from '../@interfaces/reservation';
import { Store } from '../context/GlobalStore';
import { API_END_POINT } from './api';

export default function useShopReports(
  workspaceUid: string,
  startDate: string,
  endDate: string,
  groupingType: ShopReportGroupingType
) {
  const { globalState, setGlobalState } = useContext(Store);
  const [shopReports, setShopReports] = useState<ShopReport[]>([]);
  const [loading, setLoading] = useState(false);

  const reload = () => {
    const params = {
      startDate,
      endDate,
      groupingType,
    };
    setLoading(true);
    axios
      .get(`${API_END_POINT}/app/ws/${workspaceUid}/shop-reports`, {
        params,
        headers: {
          Authorization: globalState.session?.idToken,
        },
      })
      .then((response: AxiosResponse<ResponseBody>) => {
        setShopReports(response.data.shopReports);
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (!globalState.session?.idToken) {
      return;
    }
    if (
      dayjs(startDate).format('YYYY-MM-DD') === startDate &&
      dayjs(endDate).format('YYYY-MM-DD') === endDate
    ) {
      reload();
    }
  }, [
    workspaceUid,
    globalState.session?.idToken,
    groupingType,
    startDate,
    endDate,
  ]);
  return {
    shopReports,
    isLoadingShopReports: loading,
    reloadShopReportsg: reload,
  };
}
