/** @jsxImportSource @emotion/react */

import React from 'react';
import { MainMenu } from '../../features/main-menu/components/MainMenu';
import {
  WorkspaceSideMenu,
  WorkspaceSideMenuItemId,
} from '../../features/side-menu/components/WorkspaceSideMenu';
import { TopAppBar } from '../Shared';
import { BaseLayout } from './BaseLayout';

type WorkspacePageLayoutProps = {
  workspaceUid: string;
  current: WorkspaceSideMenuItemId;
  breadcrumbs: React.ReactNode;
  helpId?: string;
  children: React.ReactNode;
};

export function WorkspacePageLayout({
  workspaceUid,
  current,
  breadcrumbs,
  helpId,
  children,
}: WorkspacePageLayoutProps): JSX.Element {
  return (
    <BaseLayout
      header={
        <TopAppBar
          helpId={helpId}
          sideMenuContents={
            <WorkspaceSideMenu current={current} showMainMenuItems />
          }
        />
      }
      mainTop={breadcrumbs}
      mainMenu={<MainMenu current="workspace" workspaceUid={workspaceUid} />}
      side={<WorkspaceSideMenu current={current} />}
    >
      {children}
    </BaseLayout>
  );
}
