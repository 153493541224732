import { CustomerReservationsResponse } from '../../../@interfaces/crm/api';
import { API_END_POINT } from '../../../api/api';
import { useQuery } from '../../common/api/use-query';

export const useShopCustomerReservations = (
  shopId: string,
  customerId: string
): {
  data: CustomerReservationsResponse | undefined;
  isLoading: boolean;
  reload: () => void;
} => {
  const { response, isLoading, reload } =
    useQuery<CustomerReservationsResponse>(
      `${API_END_POINT}/app/shops/${shopId}/customers/${customerId}/reservations`
    );
  return {
    data: response,
    isLoading: isLoading,
    reload: reload,
  };
};
