/** @jsxImportSource @emotion/react */

import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
} from '@material-ui/core';
import React, { ChangeEvent } from 'react';
import {
  CheckboxField,
  Option,
  OptionValue,
} from '../../../../core/types/reservation-form-types';
import { styles } from '../ReservationEditPage';
import { FieldErrorMessage } from './FieldErrorMessageProps';
import {
  buildFieldValidation,
  fieldRequired,
  FormFieldProps,
} from './input-utils';

type CheckboxFormFieldProps = FormFieldProps & {
  field: CheckboxField;
  deletedField?: CheckboxField | undefined;
};
export function CheckboxFormField(props: CheckboxFormFieldProps) {
  const {
    field,
    deletedField,
    fieldResponse,
    validationContext,
    onChangeValue,
  } = props;
  const { errors, register } = validationContext;
  const fieldValidation = buildFieldValidation(
    field,
    onChangeValue === undefined
  );

  const isChecked = (option: Option) => {
    const { values } = fieldResponse;
    return (
      values.includes(option.text) ||
      values.find(
        (v) =>
          typeof v !== 'string' &&
          option.uid !== undefined &&
          option.uid === v.uid
      ) !== undefined
    );
  };

  const changeMultiValue = (textOrUidValue: string) => {
    const newValues = [...fieldResponse.values];
    let index = fieldResponse.values.indexOf(textOrUidValue);
    if (index === -1) {
      index = fieldResponse.values.findIndex(
        (v) => typeof v !== 'string' && textOrUidValue === v.uid
      );
    }
    if (index === -1) {
      const option = field.options.find(
        (o) => o.uid && o.uid === textOrUidValue
      );
      if (option) {
        newValues.push({ uid: option.uid });
      } else {
        newValues.push(textOrUidValue);
      }
    } else {
      newValues.splice(index, 1);
    }
    onChangeValue?.(field, newValues);
  };

  const handleChangeCheckBox = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    changeMultiValue(value);
  };

  const buildOption = (option: Option) => {
    return (
      <FormControlLabel
        key={option.uid}
        control={
          <Checkbox
            inputRef={register(fieldValidation)}
            name={field.uid}
            value={option.uid || option.text}
            checked={isChecked(option)}
            onChange={handleChangeCheckBox}
          />
        }
        label={option.text}
      />
    );
  };

  const buildDeletedOption = (option: Option) => {
    return (
      <FormControlLabel
        control={
          <Checkbox
            inputRef={register(fieldValidation)}
            name={field.uid}
            value={option.uid || option.text}
            checked={isChecked(option)}
            onChange={handleChangeCheckBox}
          />
        }
        label={option.text + '(削除済み)'}
      />
    );
  };

  const buildOptions = () => {
    const checkDeletedFields = fieldResponse.values
      ?.map((value) => {
        const v: OptionValue = value as OptionValue;
        const field = deletedField?.options?.find((f) => f.uid === v.uid);
        if (field) {
          return field;
        } else {
          return null;
        }
      })
      .filter(Boolean);
    return [
      ...field.options.map((option) => buildOption(option)),
      ...checkDeletedFields
        ?.map((option) => (option ? buildDeletedOption(option) : null))
        .filter(Boolean),
    ];
  };

  return (
    <div css={styles.fieldContainer}>
      <FormControl component="fieldset">
        <FormLabel component="legend">
          {field.name} {fieldRequired(field) ? '*' : ''}
        </FormLabel>
        <FormGroup row>{buildOptions()}</FormGroup>
        <FormHelperText>{field.description}</FormHelperText>
      </FormControl>
      <FieldErrorMessage field={field} errors={errors} />
    </div>
  );
}
