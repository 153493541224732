import { AxiosResponse } from 'axios';
import { API_END_POINT } from '../../../api/api';
import { useMutation } from '../../common/api/use-mutation';

export const useDeleteCustomDomain = (): ((
  workspaceUid: string,
  customDomainId: number
) => Promise<AxiosResponse<''>>) => {
  const { request } = useMutation<'', undefined>();
  return async (workspaceUid, customDomainId) => {
    return request(
      'DELETE',
      `${API_END_POINT}/app/ws/${workspaceUid}/custom-domains/${customDomainId}`,
      {}
    );
  };
};
