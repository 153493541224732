import { atom, useRecoilState } from 'recoil';
import { recoilPersist } from 'recoil-persist';

export const AUTO_RELOAD_RESERVATIONS_INTERVAL = 60 * 5 * 1000;

const { persistAtom } = recoilPersist({
  key: 'recoil-persist',
  storage: localStorage,
});

const state = atom<{ autoReload: boolean }>({
  key: 'autoReloadReservations',
  default: { autoReload: false },
  effects_UNSTABLE: [persistAtom],
});

export const useAutoReloadReservations = () => {
  const [autoReloadReservations, setAutoReloadReservations] =
    useRecoilState(state);

  return {
    autoReloadReservations,
    setAutoReloadReservations,
  };
};
