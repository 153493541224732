import { AxiosResponse } from 'axios';
import { API_END_POINT } from '../../../api/api';
import { useMutation } from '../../common/api/use-mutation';

export const useDeleteResourceSchedulePatterns = (): ((
  shopId: string,
  resourceSchedulePatternId: number
) => Promise<AxiosResponse<''>>) => {
  const { request } = useMutation<'', undefined>();
  return (shopId, resourceSchedulePatternId) => {
    return request(
      'delete',
      `${API_END_POINT}/app/shops/${shopId}/resource-schedule-patterns/${resourceSchedulePatternId}`
    );
  };
};
