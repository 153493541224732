/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { Button, DialogActions, DialogContent, Grid } from '@material-ui/core';
import { useRef } from 'react';
import { CustomerInfo } from '../../../../@interfaces/customer';
import { useCreateShopCustomer } from '../../api/useCreateShopCustomer';
import {
  CustomerEditForm,
  CustomerEditFormRef,
  CustomerEditFormSaveHandler,
} from './CustomerEditForm';
import { CustomerSelectorSuggestions } from './CustomerSelector';

type NewCustomerSelectorProps = {
  shopId: string;
  suggestions?: CustomerSelectorSuggestions;
  onClose: () => void;
  onSelect: (customer: CustomerInfo | undefined) => void;
};
export const NewCustomerSelector = (props: NewCustomerSelectorProps) => {
  const { shopId, suggestions, onClose, onSelect } = props;
  const createCustomer = useCreateShopCustomer();
  const customerEditFormRef = useRef<CustomerEditFormRef>(null);

  const handleSave: CustomerEditFormSaveHandler = (data, mode) => {
    void createCustomer(shopId, data).then((res) => {
      const { customer } = res.data;
      const customerInfo: CustomerInfo = {
        ...customer,
        reservationCount: 0,
        canceledReservationCount: 0,
      };
      onSelect(customerInfo);
    });
  };

  const handleSubmit = () => {
    customerEditFormRef.current?.submitForm();
  };

  return (
    <>
      <DialogContent>
        <CustomerEditForm
          ref={customerEditFormRef}
          customer={undefined}
          shopId={parseInt(shopId)}
          defaultNewCustomer={suggestions}
          onSave={handleSave}
          hideContinuousButton={true}
          hideButtonButtons={true}
          isShowInDialog={true}
        />
      </DialogContent>
      <DialogActions>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => {
                onSelect(undefined);
              }}
            >
              未設定に変更
            </Button>
          </Grid>
          <Grid item container xs justifyContent="flex-end">
            <Button variant="text" color="primary" onClick={onClose}>
              閉じる
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              css={css`
                margin-left: 10px;
              `}
            >
              登録する
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </>
  );
};
