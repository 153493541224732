/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import {
  Box,
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import dayjs, { extend, locale } from 'dayjs';
import 'dayjs/locale/ja';
import relativeTime from 'dayjs/plugin/relativeTime';
import { ChangeEvent } from 'react';
import { CustomerInfo } from '../../../../@interfaces/customer';
import { commonCss } from '../../../../components/common-css';
import LinkRouter from '../../../../components/LinkRouter';
import { useSizeType } from '../../../../hooks/use-size-type';
import { formatPhoneNumber } from '../../../../utils/phonenumber-utils';
import { LineInfo } from './LineInfo';
import { ReservationCountInfo } from './ReservationCountInfo';
locale('ja');
extend(relativeTime);

const styles = {
  reservationCount: css`
    font-size: 20px;
  `,
  lastReservationInfo: css`
    font-size: 12px;
    color: #777;
    margin-left: 5px;
  `,
  tableHeaderForSp: css`
    width: 130px;
  `,
  checkboxForSp: css`
    padding: 0 8px;
  `,
};

type CustomerTableProps = {
  workspaceUid: string;
  shopId: string;
  customers: CustomerInfo[];
  selectedCustomerIds: number[];
  onAddSelected: (removeCustomerId: number) => void;
  onRemoveSelected: (removeCustomerId: number) => void;
};

type ElementsKey =
  | 'checkbox'
  | 'name'
  | 'tel'
  | 'email'
  | 'reservationCount'
  | 'note'
  | 'action';

export const CustomerTable = (props: CustomerTableProps): JSX.Element => {
  const {
    workspaceUid,
    shopId,
    customers,
    selectedCustomerIds,
    onAddSelected,
    onRemoveSelected,
  } = props;
  const { isSpSize } = useSizeType();

  const elements: {
    key: ElementsKey;
    headerBody: JSX.Element | string;
    rowBody: (customer: CustomerInfo) => JSX.Element;
  }[] = [
    {
      key: 'checkbox',
      headerBody: '',
      rowBody: (customer: CustomerInfo) => (
        <Checkbox
          checked={selectedCustomerIds.includes(customer.id)}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            if (e.target.checked) {
              onAddSelected(customer.id);
            } else {
              onRemoveSelected(customer.id);
            }
          }}
        />
      ),
    },
    {
      key: 'name',
      headerBody: '名前',
      rowBody: (customer: CustomerInfo) => (
        <>
          <div
            css={css`
              font-size: 10px;
            `}
          >
            {customer.nameKana}
          </div>
          <div>
            {customer.name} <LineInfo lineUser={customer} />
          </div>
          {customer.deletedAt && <div>(削除済み)</div>}
        </>
      ),
    },
    {
      key: 'tel',
      headerBody: '電話番号',
      rowBody: (customer: CustomerInfo) => (
        <>{formatPhoneNumber(customer.tel)}</>
      ),
    },
    {
      key: 'email',
      headerBody: 'メールアドレス',
      rowBody: (customer: CustomerInfo) => <>{customer.email}</>,
    },
    {
      key: 'reservationCount',
      headerBody: (
        <>
          利用回数
          <br />
          (キャンセル回数)
          <br />
          最終予約日
        </>
      ),
      rowBody: (customer: CustomerInfo) => (
        <ReservationCountInfo customer={customer} />
      ),
    },
    {
      key: 'note',
      headerBody: '顧客メモ',
      rowBody: (customer: CustomerInfo) => <>{customer.note}</>,
    },
    {
      key: 'action',
      headerBody: 'アクション',
      rowBody: (customer: CustomerInfo) => (
        <LinkRouter
          to={`/a/${workspaceUid}/shops/${shopId}/customers/${String(
            customer.id || 0
          )}`}
          css={css`
            vertical-align: middle;
          `}
        >
          編集
        </LinkRouter>
      ),
    },
  ];

  return (
    <>
      {!isSpSize ? (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                {elements.map((elem, index) => (
                  <TableCell key={index}>{elem.headerBody}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {customers.map((customer) => (
                <TableRow
                  key={customer.id}
                  css={
                    customer.deletedAt
                      ? css`
                          background-color: #dddddd;
                        `
                      : null
                  }
                >
                  {elements.map((elem, index) => (
                    <TableCell
                      key={index}
                      scope={index > 0 ? 'row' : undefined}
                    >
                      {elem.rowBody(customer)}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <>
          {customers.map((customer, index) => (
            <Box
              key={index}
              mt={2}
              css={css`
                width: 100%;
              `}
            >
              <TableContainer
                component={Paper}
                css={customer.deletedAt ? commonCss.deletedRow : null}
              >
                {elements.map((elem, elemIndex) => (
                  <Table key={`elem${elemIndex}`}>
                    <TableBody>
                      <TableRow>
                        <TableCell
                          css={[
                            commonCss.noWrap,
                            commonCss.noPrint,
                            styles.tableHeaderForSp,
                            elem.key === 'checkbox' && styles.checkboxForSp,
                          ]}
                        >
                          {elem.key === 'checkbox'
                            ? elem.rowBody(customer)
                            : elem.headerBody}
                        </TableCell>
                        <TableCell
                          scope="row"
                          css={[
                            commonCss.noPrint,
                            elem.key === 'checkbox' && styles.checkboxForSp,
                          ]}
                        >
                          {elem.key !== 'checkbox' && elem.rowBody(customer)}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                ))}
              </TableContainer>
            </Box>
          ))}
        </>
      )}
    </>
  );
};
