/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined';
import DeleteIcon from '@material-ui/icons/Delete';
import ErrorIcon from '@material-ui/icons/Error';
import WarningIcon from '@material-ui/icons/Warning';
import { useEffect, useState } from 'react';
import useReactRouter from 'use-react-router';
import { CustomDomain } from '../../../@interfaces/custom-domain/api';
import { CheckStatusType } from '../../../@interfaces/custom-domain/base';
import { Shop } from '../../../@interfaces/shop';
import Spinner from '../../../components/Spinner';
import { useCustomDomainsShops } from '../api/useCustomDomainsShops';
import { useDeleteCustomDomain } from '../api/useDeleteCustomDomain';

export const CustomDomainTable = ({
  customDomains,
  onDeleteCustomDomain,
}: {
  customDomains: CustomDomain[];
  onDeleteCustomDomain: () => void;
}) => {
  const { match } = useReactRouter<PageParams>();
  const { workspaceUid } = match.params;
  const [domainShops, setDomainShops] = useState<{
    [customDomainId: number]: Shop[];
  }>(() => {
    const ids = customDomains.map((c) => c.id);
    const ret: {
      [customDomainId: number]: Shop[];
    } = {};
    for (const id of ids) {
      ret[id] = [];
    }
    return ret;
  });
  const deleteCustomDomain = useDeleteCustomDomain();
  const createHandleDelete = (domain: CustomDomain) => {
    return () => {
      if (domainShops[domain.id].length !== 0) {
        alert('ドメインを利用中の店舗があるため削除出来ません');
        return;
      }

      if (
        !window.confirm(`ドメイン ${domain.fqdn} を削除してもよろしいですか`)
      ) {
        return;
      }

      deleteCustomDomain(workspaceUid, domain.id)
        .then(() => {
          onDeleteCustomDomain();
        })
        .catch((e) => {
          console.error(e);
        });
    };
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ドメイン</TableCell>
              <TableCell>ステータス</TableCell>
              <TableCell>アクション</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {customDomains.map((customDomain) => (
              <TableRow key={customDomain.id}>
                <TableCell scope="row">{customDomain.fqdn}</TableCell>
                <TableCell scope="row">
                  <Status domain={customDomain} />
                  {customDomain.checkStatus === CheckStatusType.OK && (
                    <ShopsList
                      domain={customDomain}
                      loadedShops={(shops) => {
                        setDomainShops((prev) => {
                          const current = { ...prev };
                          current[customDomain.id] = shops;
                          return current;
                        });
                      }}
                    />
                  )}
                </TableCell>
                <TableCell scope="row">
                  <Button
                    color="primary"
                    startIcon={<DeleteIcon />}
                    onClick={createHandleDelete(customDomain)}
                  >
                    削除
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

const Status = ({ domain }: { domain: CustomDomain }) => {
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  if (domain.checkStatus === CheckStatusType.OK) {
    return (
      <Box
        display="flex"
        alignItems="center"
        css={css`
          color: #4caf50;
        `}
      >
        <CheckCircleOutlinedIcon />
        登録済み
      </Box>
    );
  } else if (domain.checkStatus === CheckStatusType.FAILURE) {
    return (
      <Box
        display="flex"
        alignItems="center"
        css={css`
          color: #f44336;
        `}
      >
        <ErrorIcon />
        登録失敗
      </Box>
    );
  } else {
    return (
      <>
        <Button
          css={css`
            display: flex;
            align-items: center;
            text-decoration: underline;
            text-decoration-color: #aaa;
            padding: 0;
            :hover {
              text-decoration: underline;
              text-decoration-color: black;
              background-color: transparent;
            }
          `}
          onClick={() => {
            setOpen(true);
          }}
        >
          <WarningIcon
            css={css`
              color: #ff9800;
            `}
          />
          DNSを設定してください
        </Button>
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>DNS設定</DialogTitle>
          <DialogContent dividers>
            <Typography>
              {domain.fqdn}のAレコードに以下のipアドレスを登録してください.
            </Typography>
            <Typography
              css={css`
                font-weight: bold;
              `}
            >
              {domain.ipv4Address}
            </Typography>
          </DialogContent>
        </Dialog>
      </>
    );
  }
};

type PageParams = {
  workspaceUid: string;
};
const ShopsList = ({
  domain,
  loadedShops,
}: {
  domain: CustomDomain;
  loadedShops: (shops: Shop[]) => void;
}) => {
  const { match } = useReactRouter<PageParams>();
  const { workspaceUid } = match.params;
  const { data: shops, isLoading } = useCustomDomainsShops(
    workspaceUid,
    domain.id
  );
  useEffect(() => {
    if (isLoading) {
      return;
    }
    loadedShops(shops);
  }, [shops, isLoading]);
  return isLoading ? (
    <Spinner loading={isLoading} />
  ) : (
    <Accordion>
      <AccordionSummary>利用中の店舗</AccordionSummary>
      <AccordionDetails>
        <ul>
          {shops.map((s) => (
            <li key={s.id}>
              <a href={`/a/${workspaceUid}/shops/${s.id}/settings`}>{s.name}</a>
            </li>
          ))}
        </ul>
      </AccordionDetails>
    </Accordion>
  );
};
