import axios, { AxiosResponse } from 'axios';
import { useContext, useEffect, useState } from 'react';
import { ResponseBody } from '../../../frontend-api/src/handlers/slot-setting/special-slot-setting-rules-handler';
import { SpecialShopHoliday } from '../@interfaces/special-shop-holiday';
import { SpecialSlotSettingRule } from '../@interfaces/special-slot-setting-rule';
import { Store } from '../context/GlobalStore';
import { API_END_POINT } from './api';

export default function useSpecialSlotSettingRules(
  shopId: string,
  startDate: string,
  endDate: string
) {
  const { globalState, setGlobalState } = useContext(Store);
  const [holidays, setHolidays] = useState<SpecialShopHoliday[]>([]);
  const [specialRules, setSpecialRules] = useState<SpecialSlotSettingRule[]>(
    []
  );
  const [loading, setLoading] = useState(false);
  const reload = () => {
    if (!globalState.session?.idToken) {
      return;
    }
    setLoading(true);
    const params = {
      startDate: startDate,
      endDate: endDate,
    };
    axios
      .get(`${API_END_POINT}/app/shops/${shopId}/special-slot-settings/rules`, {
        params,
        headers: {
          Authorization: globalState.session?.idToken,
        },
      })
      .then((response: AxiosResponse<ResponseBody>) => {
        setHolidays(response.data.holidays);
        setSpecialRules(response.data.specialRules);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    reload();
  }, [shopId, startDate, endDate, globalState.session?.idToken]);
  return {
    holidays,
    specialRules,
    reloadShopSpecialSlotSettingRules: reload,
    isLoadingShopSpecialSlotSettingRules: loading,
  };
}
