/** @jsxImportSource @emotion/react */

import React from 'react';
import {
  AnyField,
  FormSetting,
  TextField as ITextField,
} from '../../../../../core/types/reservation-form-types';
import { FormSettingSingleDefaultValue } from '../FormSettingSingleDefaultValue';

type FormSettingFieldTextProps = {
  field: ITextField;
  setting: FormSetting;
  onChangeSetting: (setting: FormSetting) => void;
  originalField: AnyField | undefined;
};

export const FormSettingFieldText = (
  props: FormSettingFieldTextProps
): JSX.Element => {
  const { field, setting, onChangeSetting } = props;

  return (
    <FormSettingSingleDefaultValue
      field={field}
      setting={setting}
      onChangeSetting={onChangeSetting}
      type="text"
    />
  );
};
