import axios, { AxiosResponse } from 'axios';
import { useContext, useEffect, useState } from 'react';
import { ResponseBody } from '../../../frontend-api/src/handlers/shop-notify-destination/shop-notify-destinations-handler';
import { ShopNotifyDestination } from '../@interfaces/shop-notify-destination';
import { Store } from '../context/GlobalStore';
import { API_END_POINT } from './api';

export default function useShopNotifyDestinations(shopId: string) {
  const { globalState, setGlobalState } = useContext(Store);
  const [shopNotifyDestinations, setShopNotifyDestinations] = useState(
    [] as ShopNotifyDestination[]
  );
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    reload();
  }, [shopId, globalState.session?.idToken]);

  const reload = () => {
    if (!globalState.session?.idToken) {
      return;
    }
    axios
      .get(`${API_END_POINT}/app/shops/${shopId}/destinations`, {
        headers: {
          Authorization: globalState.session?.idToken,
        },
      })
      .then((response: AxiosResponse<ResponseBody>) => {
        setShopNotifyDestinations(response.data.shopNotifyDestinations);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return {
    shopNotifyDestinations,
    isLoadingShopNotifyDestinations: loading,
    reloadShopNotifyDestinations: reload,
  };
}
