/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { ReactNode } from 'react';
import { IconType } from 'react-icons';
import {
  FaCalendarCheck,
  FaCalendarDays,
  FaPeopleGroup,
} from 'react-icons/fa6';
import { Link } from 'react-router-dom';
import useWorkspaceSetting from '../../../api/use-workspace-setting';
import { globalColors } from '../../../styles/globalColors';

export const styles = {
  rootContainer: css`
    padding: 0 16px;
    height: 52px;
    background-color: #fff;
    border-bottom: solid 1px #c3c3c3;
  `,
  flexContainer: css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 1920px;
    margin: 0 auto;
  `,
  menuLinkContainer: css`
    display: flex;
    align-items: center;
    padding: 0 16px;
    gap: 32px;
  `,
  sideDoorContainer: css`
    display: flex;
    align-items: center;
    gap: 8px;
  `,
  resetLink: css`
    color: inherit;
    text-decoration: none;
  `,
  mainMenuLink: css`
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    height: 52px;
    line-height: 52px;
    min-width: 80px;
    border-bottom: solid 8px;
    border-bottom-color: transparent;
    transition: border-bottom-color 0.2s ease-in-out;
    &:hover {
      border-bottom-color: ${globalColors.hover};
    }
  `,
  mainMenuCurrentLink: css`
    border-bottom: solid 8px ${globalColors.main};
  `,
  sideDoorLink: css`
    display: flex;
    align-items: center;
    background-color: ${globalColors.accent2};
    color: #fff;
    font-weight: bold;
    height: 40px;
    padding: 0 12px;
    border-radius: 5px;
    gap: 8px;
    transition: background-color 0.2s ease-in-out;
    &:hover {
      background-color: ${globalColors.emphasis};
    }
  `,
};

type MainMenuProps =
  | {
      current: 'none';
    }
  | {
      current: 'home';
    }
  | {
      current: 'workspace';
      workspaceUid: string;
    }
  | {
      current: 'shop';
      workspaceUid: string;
      shopId: string;
    };

export function MainMenu(props: MainMenuProps): JSX.Element {
  const showWorkspaceLink =
    props.current === 'workspace' || props.current === 'shop';
  const showShopLink = props.current === 'shop';

  return (
    <div css={styles.rootContainer}>
      <div css={styles.flexContainer}>
        <div css={styles.menuLinkContainer}>
          <MainMenuLink to="/" isCurrent={props.current === 'home'}>
            ホーム
          </MainMenuLink>
          {showWorkspaceLink && (
            <MainMenuLink
              to={`/a/${props.workspaceUid}`}
              isCurrent={props.current === 'workspace'}
            >
              ワークスペース
            </MainMenuLink>
          )}
          {showShopLink && (
            <MainMenuLink
              to={`/a/${props.workspaceUid}/shops/${props.shopId}`}
              isCurrent={props.current === 'shop'}
            >
              店舗
            </MainMenuLink>
          )}
        </div>
        <div>
          {props.current === 'workspace' && (
            <WorkSpaceSideDoors workspaceUid={props.workspaceUid} />
          )}
          {props.current === 'shop' && (
            <ShopSideDoors
              workspaceUid={props.workspaceUid}
              shopId={props.shopId}
            />
          )}
        </div>
      </div>
    </div>
  );
}

type MainMenuLinkProps = {
  to: string;
  isCurrent: boolean;
  children: ReactNode;
};

function MainMenuLink({
  to,
  isCurrent,
  children,
}: MainMenuLinkProps): JSX.Element {
  return (
    <Link
      css={[
        styles.resetLink,
        styles.mainMenuLink,
        isCurrent && styles.mainMenuCurrentLink,
      ]}
      to={to}
    >
      {children}
    </Link>
  );
}

type WorkSpaceSideDoorsProps = {
  workspaceUid: string;
};

export function WorkSpaceSideDoors({
  workspaceUid,
}: WorkSpaceSideDoorsProps): JSX.Element {
  return (
    <div css={styles.sideDoorContainer}>
      <SideDoorLink
        to={`/a/${workspaceUid}/shops/reports`}
        Icon={FaCalendarCheck}
      >
        集計
      </SideDoorLink>
    </div>
  );
}

type ShopSideDoorsProps = {
  workspaceUid: string;
  shopId: string;
};

export function ShopSideDoors({
  workspaceUid,
  shopId,
}: ShopSideDoorsProps): JSX.Element {
  const { workspaceSetting } = useWorkspaceSetting(workspaceUid);

  return (
    <div css={styles.sideDoorContainer}>
      {Boolean(workspaceSetting?.enableCrm) && (
        <SideDoorLink
          to={`/a/${workspaceUid}/shops/${shopId}/customers`}
          Icon={FaPeopleGroup}
        >
          顧客管理
        </SideDoorLink>
      )}
      <SideDoorLink
        to={`/a/${workspaceUid}/shops/${shopId}`}
        Icon={FaCalendarDays}
      >
        店舗トップ (カレンダー)
      </SideDoorLink>
    </div>
  );
}

type SideDoorLinkProps = { to: string; Icon: IconType; children: ReactNode };

function SideDoorLink({ to, Icon, children }: SideDoorLinkProps): JSX.Element {
  return (
    <Link css={[styles.resetLink, styles.sideDoorLink]} to={to}>
      <Icon size="24px" />
      {children}
    </Link>
  );
}
