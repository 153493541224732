/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { Card, CardActions, CardContent } from '@material-ui/core';
import dayjs from 'dayjs';
import {
  FaArrowRight,
  FaLongArrowAltDown,
  FaLongArrowAltUp,
} from 'react-icons/fa';
import {
  HourlyAggregation,
  TargetDateType,
} from '../../../@interfaces/insight/backend';
import LinkRouter from '../../../components/LinkRouter';
import useInsightReservationHourly from '../api/use-insight-reservation-hourly';
import { calcGrowthPercent, styles } from '../utils/insight-utils';
import { CardSpinner } from './CardSpinner';

type InsightItemCardProps = {
  shopId: string;
  primaryStartDate: dayjs.Dayjs;
  primaryEndDate: dayjs.Dayjs;
  comparisonStartDate: dayjs.Dayjs;
  comparisonEndDate: dayjs.Dayjs;
  title: string;
  url: string;
  targetDateType: TargetDateType;
};

export const InsightHourCard = ({
  shopId,
  primaryStartDate,
  primaryEndDate,
  comparisonStartDate,
  comparisonEndDate,
  title,
  url,
  targetDateType,
}: InsightItemCardProps): JSX.Element => {
  const { results, isLoading } = useInsightReservationHourly(
    shopId,
    primaryStartDate.format('YYYY-MM-DD'),
    primaryEndDate.format('YYYY-MM-DD'),
    comparisonStartDate.format('YYYY-MM-DD'),
    comparisonEndDate.format('YYYY-MM-DD'),
    targetDateType
  );

  const buildContent = () => {
    if (results === undefined || isLoading) {
      return <CardSpinner />;
    }

    const sortedPrimaryResults = [...results.primary.results].sort(
      (a, b) => b.total - a.total
    );

    return (
      <div>
        {sortedPrimaryResults
          .filter((_item, i) => i < 5)
          .map((result) => {
            return (
              <div
                key={result.hour}
                css={css`
                  display: flex;
                  align-items: center;
                  border-bottom: solid 1px #ccc;
                  padding: 0.5em 0;
                `}
              >
                <div
                  css={css`
                    flex: 1;
                  `}
                >
                  {result.hour}時
                </div>
                <div
                  css={css`
                    text-align: right;
                    margin: 0 1em;
                  `}
                >
                  {result.total.toLocaleString()}
                </div>
                <div
                  css={css`
                    min-width: 42px;
                  `}
                >
                  <CalculatedPercent
                    result={result}
                    comparisonResults={results.comparison.results}
                  />
                </div>
              </div>
            );
          })}
      </div>
    );
  };

  return (
    <>
      <Card
        css={css`
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        `}
      >
        <CardContent>
          <h3
            css={css`
              font-size: 24px;
              font-weight: normal;
              margin: 0.5em 0;
              padding: 0;
            `}
          >
            {title}
          </h3>
          {buildContent()}
        </CardContent>
        <CardActions
          css={[
            css`
              justify-content: flex-end;
              padding: 16px;
            `,
            styles.noPrint,
          ]}
        >
          <LinkRouter
            to={url}
            css={css`
              line-height: 1;
              display: flex;
              align-items: center;
              gap: 8px;
            `}
          >
            {title}の詳細を表示 <FaArrowRight />
          </LinkRouter>
        </CardActions>
      </Card>
    </>
  );
};

const CalculatedPercent = ({
  result,
  comparisonResults,
}: {
  result: HourlyAggregation;
  comparisonResults: HourlyAggregation[];
}) => {
  const { value, displayValue } = calcGrowthPercent(
    result.total,
    comparisonResults.find((r) => r.hour === result.hour)?.total ?? 0
  );

  const buildContent = () => {
    if (!isFinite(value)) {
      return <span>-</span>;
    }

    if (value > 0) {
      return (
        <span
          css={css`
            display: flex;
            align-items: center;
          `}
        >
          <FaLongArrowAltUp
            css={css`
              color: green;
            `}
          />
          {displayValue}
        </span>
      );
    }

    if (value < 0) {
      return (
        <span
          css={css`
            display: flex;
            align-items: center;
          `}
        >
          <FaLongArrowAltDown
            css={css`
              color: red;
            `}
          />
          {displayValue}
        </span>
      );
    }

    return <span>-</span>;
  };

  return (
    <div
      css={css`
        text-align: right;
      `}
    >
      {buildContent()}
    </div>
  );
};
