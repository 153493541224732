import {
  PhoneNumber,
  PhoneNumberFormat,
  PhoneNumberUtil,
} from 'google-libphonenumber';

export function formatPhoneNumber(phoneNumber: string): string {
  if (!phoneNumber) {
    return phoneNumber;
  }
  const region = 'JP';
  const util: PhoneNumberUtil = PhoneNumberUtil.getInstance();
  try {
    const number: PhoneNumber = util.parseAndKeepRawInput(
      phoneNumber || '',
      region
    );
    if (!util.isValidNumberForRegion(number, region)) {
      return phoneNumber;
    }
    return util.format(number, PhoneNumberFormat.NATIONAL);
  } catch (e) {
    return phoneNumber;
  }
}
