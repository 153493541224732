import axios, { AxiosResponse } from 'axios';
import { useContext } from 'react';
import useSWR from 'swr';
import { GetCustomerResponse } from '../../../@interfaces/crm/api';
import { API_END_POINT } from '../../../api/api';
import { Store } from '../../../context/GlobalStore';

const fetcher = async (
  token: string,
  workspaceUid: string,
  customerId: number
) =>
  axios
    .get(`${API_END_POINT}/app/ws/${workspaceUid}/customers/${customerId}`, {
      headers: {
        Authorization: token,
      },
    })
    .then((r: AxiosResponse<GetCustomerResponse>) => r.data);

export const useCustomer = (workspaceUid: string, customerId: number) => {
  const { globalState } = useContext(Store);
  const { data, isLoading } = useSWR(
    globalState.session?.idToken
      ? [globalState.session.idToken, workspaceUid, customerId]
      : null,
    async ([token, workspaceUid, customerId]) =>
      fetcher(token, workspaceUid, customerId)
  );
  return {
    data,
    isLoading,
  };
};
