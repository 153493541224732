import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MoreButton from './description/more-button.svg';
import FoldingButton from './description/folding-button.svg';
import { lineBreakToParagraphAndHtml } from '../utils/browsers';

type Props = {
  description: string;
  enableShowButton?: boolean;
};

const createStyles = makeStyles(() => ({
  description: {
    paddingTop: '16px',
    '& p': {
      fontSize: '14px',
      lineHeight: '21px',
    },
  },
  descriptionShort: {
    '& p': {
      display: '-webkit-box',
      WebkitBoxOrient: 'vertical',
      WebkitLineClamp: 3,
      overflow: 'hidden',
      fontSize: '14px',
      lineHeight: '21px',
    },
  },
  button: {
    display: 'block',
    border: 'none',
    background: 'none',
    margin: '16px auto 0',
    cursor: 'pointer',
  },
}));

export default function Description(props: Props) {
  const { description, enableShowButton } = props;
  const ref = useRef<HTMLParagraphElement>(null);
  const [isShowAll, setIsShowAll] = useState<boolean>(false);
  const [isShowButton, setIsShowButton] = useState<boolean>(false);
  const customStyles = createStyles();

  useEffect(() => {
    function handleResize() {
      if (ref.current) {
        if (ref.current.clientHeight < ref.current.scrollHeight) {
          setIsShowButton(true);
        } else {
          setIsShowButton(false);
        }
      }
    }

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const DescriptionShort = () => {
    return (
      <div
        className={
          customStyles.description + ' ' + customStyles.descriptionShort
        }
      >
        <p ref={ref}>{description}</p>
        {isShowButton && (
          <button
            onClick={() => {
              setIsShowAll(true);
            }}
            className={customStyles.button}
          >
            <img src={MoreButton} alt="詳細を見る" />
          </button>
        )}
      </div>
    );
  };

  const DescriptionAll = () => {
    return (
      <div className={customStyles.description}>
        <div>{lineBreakToParagraphAndHtml(description)}</div>
        <button
          onClick={() => {
            setIsShowAll(false);
          }}
          className={customStyles.button}
        >
          <img src={FoldingButton} alt="表示を短くする" />
        </button>
      </div>
    );
  };

  if (enableShowButton) {
    return <div>{isShowAll ? <DescriptionAll /> : <DescriptionShort />}</div>;
  } else {
    return (
      <div className={customStyles.description}>
        <div>{lineBreakToParagraphAndHtml(description)}</div>
      </div>
    );
  }
}
