/** @jsxImportSource @emotion/react */

import {
  AnyField,
  FormSetting,
  NameField,
} from '../../../../../core/types/reservation-form-types';

type FormSettingFieldNameProps = {
  field: NameField;
  setting: FormSetting;
  onChangeSetting: (setting: FormSetting) => void;
  originalField: AnyField | undefined;
};

export const FormSettingFieldName = (
  props: FormSettingFieldNameProps
): null => {
  return null;
};
