import axios, { AxiosResponse } from 'axios';
import { useContext, useEffect, useState } from 'react';
import { ResponseBody } from '../../../frontend-api/src/handlers/course/workspace-course-handler';
import { Course } from '../@interfaces/course';
import { Store } from '../context/GlobalStore';
import { API_END_POINT } from './api';

const cache: { [courseId: string]: Course } = {};

export default function useWorkspaceCourse(
  workspaceUid: string,
  courseId: string
) {
  const { globalState, setGlobalState } = useContext(Store);
  const [course, setCourse] = useState<Course>(cache[courseId || '']);
  const [loading, setLoading] = useState(cache[courseId || ''] == undefined);
  const reload = () => {
    if (!globalState.session?.idToken) {
      return;
    }
    axios
      .get(`${API_END_POINT}/app/ws/${workspaceUid}/courses/${courseId}`, {
        headers: {
          Authorization: globalState.session?.idToken,
        },
      })
      .then((response: AxiosResponse<ResponseBody>) => {
        setCourse(response.data.course);
        cache[courseId] = response.data.course;
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    reload();
  }, [workspaceUid, courseId, globalState.session?.idToken]);
  return {
    course,
    isLoadingCourse: loading,
  };
}
