/** @jsxImportSource @emotion/react */

import { Breadcrumbs, Grid } from '@material-ui/core';
import React from 'react';
import useReactRouter from 'use-react-router';
import useInflowSources from '../../../api/use-inflow-sources';
import {
  BreadcrumbLinkItem,
  BreadcrumbTextItem,
  BreadcrumbWorkspaceItem,
} from '../../../components/breadcrumb/BreadcrumbItem';
import { WorkspacePageLayout } from '../../../components/layouts/WorkspacePageLayout';
import LinkButton from '../../../components/LinkButton';
import { PageTitleAndDescription } from '../../../components/PageTitleAndDescription';
import { Head, Main, Root } from '../../../components/Shared';
import Spinner from '../../../components/Spinner';
import { globalColors } from '../../../styles/globalColors';
import { helps } from '../../../utils/helps';
import WorkspaceInflowSourcesTable from './WorkspaceInflowSourcesTable';

interface PageParams {
  workspaceUid: string;
}

export default function WorkspaceInflowSourceSettingPage() {
  const { match } = useReactRouter<PageParams>();
  const { workspaceUid } = match.params;
  const { inflowSources, isLoadingInflowSources } =
    useInflowSources(workspaceUid);

  const buildContents = () => (
    <>
      <Grid container alignItems="center">
        <Grid item xs={6}>
          <h3>流入元</h3>
        </Grid>
        <Grid
          item
          container
          xs={6}
          alignItems="center"
          justifyContent="flex-end"
        >
          <LinkButton
            to={`/a/${workspaceUid}/settings/inflow-sources/new`}
            label="流入元を追加"
          />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item container xs={12} alignItems="center">
          {isLoadingInflowSources ? (
            <Spinner loading={true} />
          ) : (
            <WorkspaceInflowSourcesTable
              workspaceUid={workspaceUid}
              inflowSources={inflowSources}
            />
          )}
        </Grid>
      </Grid>
    </>
  );

  const pageTitle = '流入元設定';

  return (
    <Root>
      <Head title={`ワークスペース設定 - ${pageTitle}`} />
      <Main>
        <WorkspacePageLayout
          workspaceUid={workspaceUid}
          current="inflow-source"
          breadcrumbs={
            <Breadcrumbs aria-label="breadcrumb">
              <BreadcrumbLinkItem to={`/`}>ホーム</BreadcrumbLinkItem>
              <BreadcrumbWorkspaceItem workspaceUid={workspaceUid} />
              <BreadcrumbTextItem>{pageTitle}</BreadcrumbTextItem>
            </Breadcrumbs>
          }
          helpId={helps.workspace.setting.inflowSource}
        >
          <PageTitleAndDescription
            title="ワークスペース"
            subTitle={pageTitle}
            description="顧客が予約画面にどこから来たかを判別するための設定を行います。流入元設定後、流入元ごとのURLが発行できます。"
            themeColor={globalColors.workspace}
          />
          {buildContents()}
        </WorkspacePageLayout>
      </Main>
    </Root>
  );
}
