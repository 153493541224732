import axios, { AxiosResponse } from 'axios';
import React, { createContext, FC, useEffect, useReducer } from 'react';
import { API_END_POINT } from '../api/api';
import { getSession } from '../api/auth/cognito';

type NewStateAction = Partial<S> | ((prevState: S) => Partial<S>);
type ContextValue = {
  globalState: S;
  setGlobalState: (newState: NewStateAction) => void;
};

export const ROLE_ADMIN = 0;
export const ROLE_NORMAL = 1;

export type Session = {
  idToken: string;
  accessToken: string;
  refreshToken: string;
};

export type Account = {
  id: number;
  uid: string;
  name: string;
  email: string;
  isInternalMember: boolean;
  isSuperUser: boolean;
};

export type Workspace = {
  id: number;
  uid: string;
  name: string;
  role: number;
};

export type SignedUser = {
  account: Account;
  workspaces: Workspace[];
};

type S = {
  session: Session | undefined;
  signedUser: SignedUser | undefined;
};

const initialState = {
  session: undefined,
} as S;

export const Store = createContext({} as ContextValue);

export const Provider: FC<{}> = ({ children }) => {
  const [globalState, setGlobalState] = useReducer(
    (prev: S, newState: NewStateAction) => {
      const _newState =
        typeof newState === 'function' ? newState(prev) : newState;
      return { ...prev, ..._newState };
    },
    initialState
  );

  useEffect(() => {
    getSession((err, session) => {
      if (!session) {
        return;
      }
      axios
        .get(`${API_END_POINT}/app/account/me`, {
          headers: {
            Authorization: session.getIdToken().getJwtToken(),
          },
        })
        .then((response: AxiosResponse) => {
          setGlobalState({
            session: {
              idToken: session.getIdToken().getJwtToken(),
              accessToken: session.getAccessToken().getJwtToken(),
              refreshToken: session.getRefreshToken().getToken(),
            },
            signedUser: response.data as SignedUser,
          });
        });
    });
  }, [globalState.session?.idToken]);

  // セッションが切れるのを防ぐために裏でセッションを更新する
  useEffect(() => {
    const refreshSession = () => {
      getSession((err, session) => {
        if (!session) {
          return;
        }
        console.log('refreshSession', session);
        setGlobalState({
          session: {
            idToken: session.getIdToken().getJwtToken(),
            accessToken: session.getAccessToken().getJwtToken(),
            refreshToken: session.getRefreshToken().getToken(),
          },
          signedUser: globalState.signedUser,
        });
      });
    };
    var timer = setInterval(refreshSession, 1000 * 60);
    window.addEventListener('focus', refreshSession);
    return () => {
      clearInterval(timer);
      window.removeEventListener('focus', refreshSession);
    };
  }, [globalState.signedUser]);

  return (
    <Store.Provider value={{ globalState, setGlobalState }}>
      {children}
    </Store.Provider>
  );
};
