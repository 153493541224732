import { IconButton, Menu, MenuItem } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { useState } from 'react';
import { ResourceSchedulePattern } from '../../../@interfaces/resource/resource-schedule-pattern';
import { toPatternRangeString } from './common';

interface Props {
  patterns: ResourceSchedulePattern[];
  onSelect: (pattern: ResourceSchedulePattern | undefined) => void;
}

export const BatchSchedulePatternChooser: React.VFC<Props> = ({
  patterns,
  onSelect,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelect = (pattern: ResourceSchedulePattern | undefined) => {
    onSelect(pattern);
    handleClose();
  };

  return (
    <div>
      <IconButton onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() => {
            handleSelect(undefined);
          }}
        >
          <div>クリア</div>
        </MenuItem>
        {patterns.map((pattern) => (
          <MenuItem
            key={pattern.id}
            onClick={() => {
              handleSelect(pattern);
            }}
            style={{ backgroundColor: pattern.color }}
          >
            {pattern.name}({toPatternRangeString(pattern)})
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};
