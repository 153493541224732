import axios, { AxiosResponse } from 'axios';
import { useContext, useEffect, useState } from 'react';
import { WorkspaceAccountWithAccount as OriginalWorkspaceAccountWithAccount } from '../@interfaces/workspace-account';
import { Store } from '../context/GlobalStore';
import { API_END_POINT } from './api';

export type WorkspaceAccount = OriginalWorkspaceAccountWithAccount;

interface ClientAccountData {
  workspaceAccounts: WorkspaceAccount[];
}

const cache: { [workspaceUid: string]: WorkspaceAccount[] } = {};

export default function useWorkspaceAccounts(workspaceUid: string) {
  const { globalState, setGlobalState } = useContext(Store);
  const [accounts, setAccounts] = useState(
    cache[workspaceUid] || ([] as WorkspaceAccount[])
  );
  const [loading, setLoading] = useState(cache[workspaceUid] == undefined);

  const reload = () => {
    axios
      .get(`${API_END_POINT}/app/ws/${workspaceUid}/accounts`, {
        headers: {
          Authorization: globalState.session?.idToken,
        },
      })
      .then((response: AxiosResponse<ClientAccountData>) => {
        setAccounts(response.data.workspaceAccounts);
        cache[workspaceUid] = response.data.workspaceAccounts;
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (!globalState.session?.idToken) {
      return;
    }
    reload();
  }, [workspaceUid, globalState.session?.idToken]);
  return {
    workspaceAccounts: accounts,
    isLoadingWorkspaceAccounts: loading,
    reloadWorkspaceAccounts: reload,
  };
}
