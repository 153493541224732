import axios, { AxiosResponse } from 'axios';
import { useContext, useEffect, useState } from 'react';
import { ResponseBody } from '../../../frontend-api/src/handlers/slot-setting/slot-setting-handler';
import { SlotSettingWithCourses } from '../@interfaces/slot-setting';
import { SlotSettingRule } from '../@interfaces/slot-setting-rule';
import { Store } from '../context/GlobalStore';
import { API_END_POINT } from './api';

export default function useSlotSetting(shopId: string, slotSettingId: string) {
  const { globalState, setGlobalState } = useContext(Store);
  const [slotSetting, setSlotSetting] = useState<SlotSettingWithCourses>();
  const [slotSettingRules, setSlotSettingRules] = useState<SlotSettingRule[]>(
    []
  );
  const [loading, setLoading] = useState(false);
  const reload = () => {
    if (!globalState.session?.idToken) {
      return;
    }
    setLoading(true);
    axios
      .get(
        `${API_END_POINT}/app/shops/${shopId}/slot-settings/${slotSettingId}`,
        {
          headers: {
            Authorization: globalState.session?.idToken,
          },
        }
      )
      .then((response: AxiosResponse<ResponseBody>) => {
        setSlotSetting(response.data.slotSetting);
        setSlotSettingRules(response.data.rules);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    reload();
  }, [shopId, slotSettingId, globalState.session?.idToken]);
  return {
    slotSetting,
    slotSettingRules,
    isLoadingSlotSetting: loading,
    reloadSlotSetting: reload,
  };
}
