import { GetCustomersResponse } from '../../../@interfaces/crm/api';
import { API_END_POINT } from '../../../api/api';
import { useQuery } from '../../common/api/use-query';

export const useShopCustomers = (
  shopId: string,
  keywords: string[],
  limit: number,
  offset: number,
  includesDeleted?: boolean
) => {
  const { response, isLoading, reload } = useQuery<GetCustomersResponse>(
    `${API_END_POINT}/app/shops/${shopId}/customers`,
    {
      keywords,
      limit,
      offset,
      includesDeleted: (() => {
        if (includesDeleted === undefined) {
          return includesDeleted;
        }
        return includesDeleted ? '1' : '0';
      })(),
    }
  );
  return {
    data: response?.customers || [],
    isLoading: isLoading,
    reload: reload,
  };
};
