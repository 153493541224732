import { useMediaQuery } from '@material-ui/core';
import { useEffect, useState } from 'react';

export const SM_BREAKPOINT = 599;
export const MD_BREAKPOINT = 899;

type SizeType = 'sm' | 'md' | 'pc';

export const useSizeType = () => {
  const [sizeType, setSizeType] = useState<SizeType>('pc');
  const isPcSize = useMediaQuery(`(min-width:${MD_BREAKPOINT + 1}px)`);
  const isTabletSize = useMediaQuery(
    `(min-width:${SM_BREAKPOINT + 1}px) and (max-width:${MD_BREAKPOINT}px)`
  );
  const isSpSize = useMediaQuery(`(max-width:${SM_BREAKPOINT}px)`);

  useEffect(() => {
    if (isPcSize) {
      setSizeType('pc');
    } else if (isTabletSize) {
      setSizeType('md');
    } else if (isSpSize) {
      setSizeType('sm');
    }
  }, [isPcSize, isTabletSize, isSpSize]);

  return { sizeType, isPcSize, isTabletSize, isSpSize };
};
