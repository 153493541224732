import xlsx from 'xlsx-js-style';

export function writeXlsx(
  cells: (string | number | undefined | null)[][],
  filename: string,
  colsWidths?: number[]
): void {
  const Workbook = xlsx.utils.book_new();
  const ws = xlsx.utils.aoa_to_sheet(cells);
  // セル幅の指定があれば適用する
  if (colsWidths) {
    ws['!cols'] = colsWidths.map((colsWidth) => {
      return { width: colsWidth };
    });
  }
  for (const rangeName in ws) {
    // !が付くのはセルではなく設定情報なので操作しない
    if (rangeName.indexOf('!') === 0) {
      continue;
    }
    // 各セル設定
    ws[rangeName].s = {
      alignment: {
        // テキスト折り返し
        wrapText: true,
        // 上付き
        vertical: 'top',
      },
    };
  }
  xlsx.utils.book_append_sheet(Workbook, ws);
  xlsx.writeFile(Workbook, filename);
}
