import { Breadcrumbs, Grid, Paper } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import React from 'react';
import useReactRouter from 'use-react-router';
import useLineLoginChannel from '../../../api/use-line-login-channel';
import useLineMessagingChannels from '../../../api/use-line-messaging-channels';
import {
  BreadcrumbLinkItem,
  BreadcrumbTextItem,
  BreadcrumbWorkspaceItem,
} from '../../../components/breadcrumb/BreadcrumbItem';
import { WorkspacePageLayout } from '../../../components/layouts/WorkspacePageLayout';
import LinkButton from '../../../components/LinkButton';
import { PageTitleAndDescription } from '../../../components/PageTitleAndDescription';
import { Head, Main, Root } from '../../../components/Shared';
import Spinner from '../../../components/Spinner';
import { useStyles } from '../../../components/Styles';
import { globalColors } from '../../../styles/globalColors';
import { helps } from '../../../utils/helps';
import { buildLineCallbackUrl } from '../../../utils/urls';
import WorkspaceLineMessagingChannelsTable from './WorkspaceLineMessagingChannelsTable';

interface PageParams {
  workspaceUid: string;
}

export default function WorkspaceLineSettingPage() {
  const classes = useStyles();
  const { match } = useReactRouter<PageParams>();
  const { workspaceUid } = match.params;
  const { lineMessagingChannels, isLoadingLineMessagingChannels } =
    useLineMessagingChannels(workspaceUid);
  const { lineLoginChannel, isLoadingLineLoginChannel } =
    useLineLoginChannel(workspaceUid);
  const { enqueueSnackbar } = useSnackbar();

  const buildLineLoginStatus = () => {
    if (isLoadingLineLoginChannel) {
      return <Spinner loading={true} />;
    }
    if (!lineLoginChannel) {
      return <div>未設定</div>;
    }
    const callbackUrl = buildLineCallbackUrl(workspaceUid);

    const handleClickCopy = async () => {
      await navigator.clipboard.writeText(callbackUrl);
      enqueueSnackbar(`「コールバックURL」をクリップボードにコピーしました。`);
    };

    return (
      <>
        <div>
          設定済み({lineLoginChannel.channelId}):{' '}
          {lineLoginChannel.enabled ? '有効' : '無効'}
        </div>
        <div style={{ marginTop: '10px' }}>
          コールバックURL:
          <input
            type="text"
            value={callbackUrl}
            readOnly
            style={{
              color: '#777',
              width: '300px',
              padding: '5px',
              margin: '10px',
            }}
          />
          <button onClick={handleClickCopy}>コピー</button>
          <div style={{ color: '#777', fontSize: '12px' }}>
            LINE Developer Console &gt; LINEログインチャンネル &gt;
            LINEログイン設定 の コールバックURLにこのURLを設定します。
          </div>
        </div>
      </>
    );
  };

  const buildContents = () => (
    <>
      <Grid container alignItems="center">
        <Grid item xs={6}>
          <h3>LINEログイン設定</h3>
        </Grid>
        <Grid
          item
          container
          xs={6}
          alignItems="center"
          justifyContent="flex-end"
        >
          <LinkButton
            to={`/a/${workspaceUid}/settings/line/login-channel`}
            label="LINEログインを設定"
          />
        </Grid>
      </Grid>
      <Paper className={classes.paper}>{buildLineLoginStatus()}</Paper>
      <Grid container alignItems="center">
        <Grid item xs={6}>
          <h3>LINE公式アカウント設定</h3>
        </Grid>
        <Grid
          item
          container
          xs={6}
          alignItems="center"
          justifyContent="flex-end"
        >
          <LinkButton
            to={`/a/${workspaceUid}/settings/line/messaging-channels/new`}
            label="LINE公式アカウントを追加"
          />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item container xs={12} alignItems="center">
          {isLoadingLineMessagingChannels ? (
            <Spinner loading={true} />
          ) : (
            <WorkspaceLineMessagingChannelsTable
              workspaceUid={workspaceUid}
              lineMessagingChannels={lineMessagingChannels}
            />
          )}
        </Grid>
      </Grid>
    </>
  );

  const pageTitle = 'LINE連携';

  return (
    <Root>
      <Head title={`ワークスペース設定 - ${pageTitle}`} />
      <Main>
        <WorkspacePageLayout
          workspaceUid={workspaceUid}
          current="line"
          breadcrumbs={
            <Breadcrumbs aria-label="breadcrumb">
              <BreadcrumbLinkItem to={`/`}>ホーム</BreadcrumbLinkItem>
              <BreadcrumbWorkspaceItem workspaceUid={workspaceUid} />
              <BreadcrumbTextItem>{pageTitle}</BreadcrumbTextItem>
            </Breadcrumbs>
          }
          helpId={helps.workspace.setting.line}
        >
          <PageTitleAndDescription
            title="ワークスペース"
            subTitle={pageTitle}
            description="LINE連携の設定を行います。"
            themeColor={globalColors.workspace}
          />
          {buildContents()}
        </WorkspacePageLayout>
      </Main>
    </Root>
  );
}
