export function range(start: number, end: number): number[] {
  const numbers = [];
  for (let i = start; i < end + 1; i++) {
    numbers.push(i);
  }
  return numbers;
}

export const toDoubleDigits = (num: number): string => {
  let number = `${num}`;
  number += '';
  if (number.length === 1) {
    number = '0' + number;
  }
  return number;
};
