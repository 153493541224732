import axios, { AxiosResponse } from 'axios';
import { useCallback, useContext, useEffect, useState } from 'react';
import { ResponseBody } from '../../../frontend-api/src/handlers/line/line-messaging-channels-handler';
import { LineMessagingChannelOverview } from '../@interfaces/line-messaging-channel';
import { Store } from '../context/GlobalStore';
import { API_END_POINT } from './api';

export default function useLineMessagingChannelOverviews(workspaceUid: string) {
  const { globalState } = useContext(Store);
  const [lineMessagingChannels, setLineMessagingChannels] = useState(
    [] as LineMessagingChannelOverview[]
  );
  const [loading, setLoading] = useState(false);

  const reload = useCallback(() => {
    if (!globalState.session?.idToken) {
      return;
    }
    setLoading(true);
    axios
      .get(
        `${API_END_POINT}/app/ws/${workspaceUid}/line/messaging-channel-overviews`,
        {
          headers: {
            Authorization: globalState.session.idToken,
          },
        }
      )
      .then((response: AxiosResponse<ResponseBody>) => {
        setLineMessagingChannels(response.data.lineMessagingChannels);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [workspaceUid, globalState.session?.idToken]);

  useEffect(() => {
    reload();
  }, [reload]);

  return {
    lineMessagingChannels,
    isLoadingLineMessagingChannels: loading,
    reloadLineMessagingChannels: reload,
  };
}
