/** @jsxImportSource @emotion/react */

import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import React, { useState } from 'react';
import { WorkspaceCourse } from '../../../@interfaces/course';
import LinkRouter from '../../../components/LinkRouter';
import { globalColors } from '../../../styles/globalColors';
import WorkspaceCoursesCopyConfirmationDialog from './WorkspaceCoursesCopyConfirmationDialog';

interface Props {
  workspaceUid: string;
  courses: WorkspaceCourse[];
  reloadCourses: () => void;
}

export default function WorkspaceCoursesTable(props: Props) {
  const { workspaceUid, courses, reloadCourses } = props;
  const [copyBased, setCopyBased] = useState<WorkspaceCourse>();

  const copyClick = (copyBased: WorkspaceCourse) => {
    setCopyBased(copyBased);
  };

  const onClose = () => {
    reloadCourses();
    setCopyBased(undefined);
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>コース名</TableCell>
              <TableCell>アクション</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {courses.map((course) => (
              <TableRow key={course.id}>
                <TableCell scope="row">{course.name}</TableCell>
                <TableCell scope="row">
                  <LinkRouter
                    to={`/a/${workspaceUid}/settings/courses/${course.id}`}
                  >
                    基本設定
                  </LinkRouter>
                  &nbsp;|&nbsp;
                  <LinkRouter
                    to={`/a/${workspaceUid}/settings/courses/${course.id}/form-setting`}
                  >
                    フォーム設定({course.formFieldCount}項目)
                  </LinkRouter>
                  &nbsp;|&nbsp;
                  <Button
                    variant="text"
                    onClick={() => {
                      copyClick(course);
                    }}
                    style={{
                      color: globalColors.link,
                      minWidth: '40px',
                      padding: '2px 4px',
                    }}
                  >
                    複製
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <WorkspaceCoursesCopyConfirmationDialog
        copyBased={copyBased}
        workspaceUid={workspaceUid}
        onClose={onClose}
      />
    </>
  );
}
