/** @jsxImportSource @emotion/react */

import { calcGrowthPercent, calcPercent, styles } from '../utils/insight-utils';

export function ComparisonItemValue({
  primaryValue,
  comparisonValue,
  comparisonTotal,
}: {
  primaryValue: number;
  comparisonValue: number;
  comparisonTotal?: number;
}): JSX.Element {
  const { displayValue } = calcGrowthPercent(primaryValue, comparisonValue);

  return (
    <div css={styles.tableNumberCell}>
      <div>
        <span css={styles.tableItemValue}>
          {comparisonValue.toLocaleString()}
        </span>
        {comparisonTotal ? (
          <span css={styles.tableItemPercent}>
            ({calcPercent(comparisonValue, comparisonTotal).displayValue})
          </span>
        ) : null}
      </div>
      <div>
        <span css={styles.tableItemValue}>{displayValue}</span>
        <span css={styles.tableItemPercent} />
      </div>
    </div>
  );
}
