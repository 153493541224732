import axios, { AxiosResponse } from 'axios';
import { useContext, useEffect, useState } from 'react';
import { ResponseBody } from '../../../frontend-api/src/handlers/inflow-source/inflow-sources-handler';
import { InflowSource } from '../@interfaces/inflow-source';
import { Store } from '../context/GlobalStore';
import { API_END_POINT } from './api';

type CacheKey = `${string}`;

const createCacheKey = (workspaceUid: string): CacheKey => {
  return `${workspaceUid}` as CacheKey;
};
const cache: { [cacheKey: string]: InflowSource[] } = {};

export const getInflowSources = (
  idToken: string,
  workspaceUid: string,
  callback: (inflowSources: InflowSource[]) => void
) => {
  return axios
    .get(`${API_END_POINT}/app/ws/${workspaceUid}/inflow-sources`, {
      headers: {
        Authorization: idToken,
      },
    })
    .then((response: AxiosResponse<ResponseBody>) => {
      callback(response.data.inflowSources);
    });
};

export default function useInflowSources(workspaceUid: string) {
  const { globalState, setGlobalState } = useContext(Store);
  const cacheKey = createCacheKey(workspaceUid);
  const [inflowSources, setInflowSources] = useState(
    cache[cacheKey] || ([] as InflowSource[])
  );
  const [loading, setLoading] = useState(cache[cacheKey] == undefined);

  const reload = () => {
    if (!globalState.session?.idToken) {
      return;
    }
    getInflowSources(
      globalState.session.idToken,
      workspaceUid,
      (inflowSources: InflowSource[]) => {
        setInflowSources(inflowSources);
        cache[cacheKey] = inflowSources;
      }
    ).finally(() => {
      setLoading(false);
    });
  };

  useEffect(() => {
    reload();
  }, [workspaceUid, globalState.session?.idToken]);

  return {
    inflowSources,
    isLoadingInflowSources: loading,
    reloadInflowSources: reload,
  };
}
