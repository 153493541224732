import axios, { AxiosResponse } from 'axios';
import { useContext, useState } from 'react';
import { ResponseBody } from '../../../../frontend-api/src/handlers/integration/google-integration-handler';
import { Store } from '../../context/GlobalStore';
import { API_END_POINT } from '../api';

export type StoredGoogleIntegration = ResponseBody;

export default function useGoogleIntegration() {
  const { globalState, setGlobalState } = useContext(Store);
  const [googleIntegration, setGoogleIntegration] =
    useState<StoredGoogleIntegration>();
  const [loading, setLoading] = useState(false);

  const googleIntegrationApi = (shopId: string, url: string) => {
    setLoading(true);
    axios
      .get(
        `${API_END_POINT}/app/shops/${shopId}/google/integration?url=${url}`,
        {
          headers: {
            Authorization: globalState.session?.idToken,
          },
        }
      )
      .then((response: AxiosResponse<ResponseBody>) => {
        setGoogleIntegration(response.data);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return {
    googleIntegration,
    googleIntegrationApi,
    isLoadingIntegration: loading,
  };
}
