/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { Chip } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { useSnackbar } from 'notistack';
import { useContext, useMemo, useState } from 'react';
import { SelectedCustomer } from '../../../../@interfaces/customer';
import { RichReservation } from '../../../../@interfaces/reservation';
import { ReservationAPI } from '../../../../api/reservations';
import { Store } from '../../../../context/GlobalStore';
import { AnyField } from '../../../../core/types/reservation-form-types';
import { collectSuggestions, CustomerSelector } from '../CustomerSelector';

const styles = {
  container: css`
    &.MuiChip-colorPrimary {
      color: rgb(97, 26, 21);
      background-color: rgb(253, 236, 234);
    }
  `,
};

type EmptyReservationCustomerInfoProps = {
  reservation: RichReservation;
  reloadReservations: () => void;
  formSettingFields: AnyField[] | undefined;
};

export const EmptyReservationCustomerInfo = ({
  reservation,
  reloadReservations,
  formSettingFields,
}: EmptyReservationCustomerInfoProps): JSX.Element => {
  const [open, setOpen] = useState(false);
  const { globalState, setGlobalState } = useContext(Store);
  const { enqueueSnackbar } = useSnackbar();

  const suggestions = useMemo(() => {
    if (!formSettingFields) {
      return undefined;
    }
    return collectSuggestions(
      formSettingFields,
      reservation.formResponse.fields
    );
  }, [formSettingFields, reservation.formResponse]);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSelect = (customer: SelectedCustomer | undefined) => {
    if (!globalState.session?.idToken) {
      return;
    }
    setOpen(false);
    void ReservationAPI.updateCustomer(
      globalState.session.idToken,
      reservation.shopId,
      customer?.id,
      reservation.id
    ).then(() => {
      enqueueSnackbar('顧客を設定しました。', { variant: 'success' });
      reloadReservations();
    });
  };

  return (
    <div>
      <Chip
        css={styles.container}
        color="primary"
        size="small"
        icon={<AddIcon />}
        label="顧客未選択"
        onClick={handleClick}
      />
      {open && (
        <CustomerSelector
          shopId={String(reservation.shopId)}
          suggestions={suggestions}
          initialTab="exist"
          onClose={handleClose}
          onSelect={handleSelect}
        />
      )}
    </div>
  );
};
