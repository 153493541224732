/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { Button } from '@material-ui/core';
import ListAltIcon from '@material-ui/icons/ListAlt';
import PersonIcon from '@material-ui/icons/Person';
import { Alert, AlertTitle } from '@material-ui/lab';
import React, { useMemo, useState } from 'react';
import { SelectedCustomer } from '../../../@interfaces/customer';
import {
  FormResponse,
  FormSetting,
} from '../../../core/types/reservation-form-types';
import {
  collectSuggestions,
  CustomerSelector,
} from '../../../features/crm/components/CustomerSelector';
import { formatPhoneNumber } from '../../../utils/phonenumber-utils';
import { LineInfo } from './LineInfo';

type CustomerInfoContainerProps = {
  workspaceUid: string;
  shopId: string;
  selectedCustomer: SelectedCustomer | undefined;
  formSetting: FormSetting;
  formResponse: FormResponse;
  onChangeSelectedCustomer: (customer: SelectedCustomer | undefined) => void;
};
export const CustomerInfoContainer = (props: CustomerInfoContainerProps) => {
  const {
    workspaceUid,
    shopId,
    selectedCustomer,
    formSetting,
    formResponse,
    onChangeSelectedCustomer,
  } = props;
  const [customerSelectorOpened, setCustomerSelectorOpened] = useState(false);
  const [customerSelectorTab, setCustomerSelectorTab] = useState<
    'exist' | 'new'
  >('exist');
  const buildSelectedCustomerLabel = () => {
    const name = selectedCustomer ? selectedCustomer?.name : '未選択';
    const personalInfos = [
      selectedCustomer?.nameKana,
      selectedCustomer?.tel
        ? formatPhoneNumber(selectedCustomer?.tel)
        : undefined,
      selectedCustomer?.email,
    ].filter(Boolean);
    return (
      <>
        <div
          css={css`
            color: rgba(0, 0, 0, 0.54);
            font-size: 12px;
          `}
        >
          顧客
        </div>
        <AlertTitle>
          {name}
          <LineInfo
            lineUser={{
              lineUserId: selectedCustomer?.lineUserId,
              lineDisplayName: selectedCustomer?.lineDisplayName,
            }}
          />
          {selectedCustomer && (
            <>
              ({selectedCustomer?.reservationCount}回予約、
              {selectedCustomer?.canceledReservationCount}回キャンセル)
            </>
          )}
        </AlertTitle>
        {personalInfos.map((personalInfo) => {
          return (
            <div
              css={css`
                color: #555;
              `}
            >
              {personalInfo}
            </div>
          );
        })}
      </>
    );
  };

  const handleChangeSelectedCustomer = (
    customer: SelectedCustomer | undefined
  ) => {
    onChangeSelectedCustomer(customer);
    setCustomerSelectorOpened(false);
  };

  const suggestions = useMemo(() => {
    return collectSuggestions(formSetting.fields, formResponse.fields);
  }, [formResponse.fields, formSetting.fields]);

  // 顧客がWebフォームから入力した値を表示
  const buildUserInputPersonalInfo = () => {
    const userInputPersonalInfos = ['name', 'tel', 'email']
      .map((type) => {
        const formSettingField = formSetting.fields.find((field) => {
          return field.type === type;
        });
        return {
          formSettingField,
          value: formResponse.fields
            .find((field) => {
              return field.uid === formSettingField?.uid;
            })
            ?.values[0].toString(),
        };
      })
      .filter((f) => Boolean(f.value));
    if (userInputPersonalInfos.length === 0) {
      return null;
    }
    const lines = userInputPersonalInfos.map((info) => {
      const value =
        info.formSettingField?.type === 'tel'
          ? formatPhoneNumber(info.value || '')
          : info.value;
      return (
        <div
          css={css`
            color: #555;
          `}
        >
          {value}
        </div>
      );
    });
    return (
      <Alert
        severity={'warning'}
        iconMapping={{
          warning: <ListAltIcon fontSize="inherit" />,
        }}
        css={css`
          margin-top: 20px;
        `}
      >
        <AlertTitle>予約フォームに入力された個人情報</AlertTitle>
        {lines}
      </Alert>
    );
  };

  return (
    <>
      <Alert
        severity={selectedCustomer ? 'success' : 'error'}
        iconMapping={{
          success: <PersonIcon fontSize="inherit" />,
          error: <PersonIcon fontSize="inherit" />,
        }}
        css={css`
          margin-top: 20px;
        `}
      >
        <div>
          {buildSelectedCustomerLabel()}
          <Button
            variant="contained"
            color="primary"
            size="small"
            css={css`
              margin-left: 20px;
            `}
            onClick={() => {
              setCustomerSelectorTab('exist');
              setCustomerSelectorOpened(true);
            }}
          >
            顧客一覧から顧客を選択
          </Button>
          <span
            css={css`
              display: inline-block;
              margin-left: 15px;
            `}
          >
            or
          </span>
          <Button
            variant="contained"
            color="primary"
            size="small"
            css={css`
              margin-left: 20px;
            `}
            onClick={() => {
              setCustomerSelectorTab('new');
              setCustomerSelectorOpened(true);
            }}
          >
            顧客を新規登録
          </Button>
          {customerSelectorOpened ? (
            <CustomerSelector
              shopId={shopId}
              suggestions={suggestions}
              initialTab={customerSelectorTab}
              onClose={() => {
                setCustomerSelectorOpened(false);
              }}
              onSelect={handleChangeSelectedCustomer}
            />
          ) : null}
        </div>
      </Alert>
      {buildUserInputPersonalInfo()}
    </>
  );
};
