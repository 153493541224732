import axios, { AxiosResponse } from 'axios';
import { useContext, useEffect, useState } from 'react';
import { ResponseBody } from '../../../frontend-api/src/handlers/reservation/reservation-handler';
import { Store } from '../context/GlobalStore';
import { API_END_POINT } from './api';

export type StoredReservation = ResponseBody;

export default function useReservation(
  shopId: string,
  reservationId: string | undefined
) {
  const { globalState, setGlobalState } = useContext(Store);
  const [reservation, setReservation] = useState<StoredReservation>();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (!globalState.session?.idToken || !reservationId) {
      return;
    }
    setLoading(true);
    axios
      .get(
        `${API_END_POINT}/app/shops/${shopId}/reservations/${reservationId}`,
        {
          headers: {
            Authorization: globalState.session?.idToken,
          },
        }
      )
      .then((response: AxiosResponse<ResponseBody>) => {
        setReservation(response.data);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [shopId, reservationId, globalState.session?.idToken]);
  return {
    reservation,
    isLoadingReservation: loading,
  };
}
