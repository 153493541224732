import Link, { LinkProps } from '@material-ui/core/Link';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

interface LinkRouterProps extends LinkProps {
  to: string;
  replace?: boolean;
}

export default function LinkRouter(props: LinkRouterProps) {
  const newProps = {
    ...props,
    children: props.children || '',
  };
  return <Link {...newProps} component={RouterLink as any} />;
}
