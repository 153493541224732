import { css } from '@emotion/react';
import dayjs, { Dayjs, ManipulateType } from 'dayjs';
import {
  AggregationUnit,
  ConversionAggregation,
  DateAggregation,
} from '../../../@interfaces/insight/backend';

export const styles = {
  tableHeader: (colLength: number) => css`
    color: white;
    width: ${100 / colLength}%;
    min-width: 130px;
  `,
  clickableTableHeader: css`
    cursor: pointer;
    &:hover {
      background-color: #333;
    }
  `,
  tableNumberCell: css`
    min-width: 80px;
    width: fit-content;
    margin: 0 auto;
    text-align: right;
  `,
  tableItemValue: css`
    display: inline-block;
    min-width: 4em;
    text-align: right;
  `,
  tableItemPercent: css`
    display: inline-block;
    min-width: 4em;
    text-align: right;
    color: #777;
  `,
  rateArrowContainer: css`
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  `,
  printRoot: css`
    @page {
      size: A3;
      margin: 4em;
    }
    @media print {
      .MuiPaper-elevation1 {
        box-shadow: none;
        border: 1px solid #555;
        border-radius: 10px;
      }
      .recharts-wrapper {
        margin: 0 auto;
      }
    }
  `,
  noPrint: css`
    @media print {
      display: none;
    }
  `,
  pageBreakAfter: css`
    page-break-after: always;
  `,
};

export type DisplayData = {
  __date: string;
  [key: string]: number | string;
};

// input:
// [
//   { date: '2024-01-01', aggregationId: 'gbp', label: 'GBP', total: 5 },
//   { date: '2024-01-01', aggregationId: 'line', label: 'LINE', total: 5 },
//   { date: '2024-01-02', aggregationId: 'gbp', label: 'GBP', total: 2 },
//   { date: '2024-01-02', aggregationId: 'line', label: 'LINE', total: 3 },
// ]

// output:
// [
//   { date: '2024-01-01', gbp: 5, line: 5 },
//   { date: '2024-01-02', gbp: 2, line: 3 },
// ]
export const toDisplayData = (
  uniqueKeys: UniqueKey[],
  targetDates: string[],
  results: DateAggregation[]
): DisplayData[] => {
  const aggregationIds = uniqueKeys.map((key) => key.key);

  const total = results.reduce((data, next) => {
    let target = data.find((d) => d.__date === next.date);
    if (!target) {
      target = {
        __date: next.date,
      };
      // テーブル出力用に、全てのキーをプロパティとして追加し、0で初期化しておく
      aggregationIds.forEach((id) => (target[id] = 0));
      data.push(target);
    }
    target[next.aggregationId] = next.total;
    return data;
  }, [] as any[]);

  // results にない日付も0で表示する
  return targetDates.map((date) => {
    const target = total.find((t) => t.__date === date);
    if (target) {
      return target;
    }
    const row = {} as any;
    row.__date = date;
    aggregationIds.forEach((id) => (row[id] = 0));
    return row;
  });
};

// in
// primary: [
//   { date: '2024-01-01', gbp: 5, line: 5 },
//   { date: '2024-01-02', gbp: 2, line: 3 },
// ]
// comparison: [
//   { date: '2024-01-01', gbp: 5, line: 5 },
//   { date: '2024-01-02', gbp: 2, line: 3 },
// ]

// out
// displayData: [
//   { date: '2024-01-01', gbp: 5, comparison_gpb: 5, line: 5, comparison_line: 5 },
//   { date: '2024-01-02', gbp: 2, comparison_gpb: 5, line: 3, comparison_line: 5 },
// ]
export const mergeDisplayData = (
  primary: DisplayData[],
  comparison: DisplayData[]
): DisplayData[] => {
  return primary.map((p, i) => {
    const c = comparison[i];
    return Object.keys(p).reduce((data, key) => {
      if (key === '__date') {
        data.__date = p.__date;
        return data;
      }
      data[key] = p?.[key] ?? 0;
      data[`comparison_${key}`] = c?.[key] ?? 0;
      return data;
    }, {} as any);
  });
};

export type UniqueKey = { key: string; label: string };

// input:
// [
//   { date: '2024-01-01', aggregationId: 'gbp', label: 'GBP', total: 5 },
//   { date: '2024-01-01', aggregationId: 'line', label: 'LINE', total: 5 },
//   { date: '2024-01-02', aggregationId: 'gbp', label: 'GBP', total: 2 },
//   { date: '2024-01-02', aggregationId: 'line', label: 'LINE', total: 3 },
// ]
// output:
// [
//    { key: 'gbp', label: 'GBP' },
//    { key: 'line', label: 'LINE' },
// ]
export const generateUniqueKey = (results: DateAggregation[]): UniqueKey[] => {
  return results.reduce((data, next) => {
    let target = data.find((d) => d.key === next.aggregationId);
    if (!target) {
      target = {
        key: next.aggregationId,
        label: next.label,
      };
      data.push(target);
    }
    return data;
  }, [] as any[]);
};

export const mergeUniqueKey = (
  primary: UniqueKey[],
  comparison: UniqueKey[]
): UniqueKey[] => {
  const merged = [...primary, ...comparison];

  const uniqueMap = merged.reduce((acc, item) => {
    if (!acc[item.key]) {
      acc[item.key] = item;
    }
    return acc;
  }, {} as Record<string, UniqueKey>);

  return Object.values(uniqueMap);
};

export const colors = [
  '#8884d8',
  '#82ca9d',
  '#ffc658',
  '#ff7300',
  '#a6cee3',
  '#b2df8a',
  '#ff7f50',
  '#ffb6c1',
  '#dda0dd',
  '#98fb98',
];

//colorsの2段階色彩を落としたカラーセット配列
export const comparisonColors = [
  '#b1adf0',
  '#b0e2c7',
  '#ffe6c3',
  '#ffb980',
  '#d3e7f2',
  '#d4efd2',
  '#ffbfa3',
  '#ffdbde',
  '#efd1ef',
  '#ccfde2',
];

export const dateRanges = (
  startDate: Dayjs,
  endDate: Dayjs,
  unit: ManipulateType
) => {
  const ranges: Dayjs[] = [];
  for (
    let date = startDate;
    date.isSame(endDate) || date.isBefore(endDate);
    date = date.add(1, unit)
  ) {
    ranges.push(date);
    if (ranges.length > 1000) {
      throw Error('Too much date ranges');
    }
  }
  return ranges.map((date) =>
    date.format(unit === 'day' ? 'YYYY-MM-DD' : 'YYYY-MM')
  );
};

//成長率を計算する
export const calcGrowthPercent = (
  primary: number,
  comparison: number
): { value: number; displayValue: string } => {
  const value = primary / comparison - 1;
  const displayValue = Number.isFinite(value)
    ? `${(value * 100).toFixed()}%`
    : '-';

  return { value, displayValue };
};

// 予約完了率を計算する
export const calcConversionPercent = (
  reservedTotal: number,
  courseSelectViewedTotal: number
): { value: number; displayValue: string } => {
  const value = reservedTotal / courseSelectViewedTotal;
  const displayValue = Number.isFinite(value)
    ? `${(value * 100).toFixed(1)}%`
    : '-';

  return { value, displayValue };
};

// パーセントを計算する
export const calcPercent = (
  itemValue: number,
  total: number
): { value: number; displayValue: string } => {
  const value = itemValue / total;
  const displayValue = Number.isFinite(value)
    ? `${(value * 100).toFixed(1)}%`
    : '-';

  return { value, displayValue };
};

export const toDisplayDate = (date: string, unit: AggregationUnit): string => {
  return dayjs(date).format(unit === 'daily' ? 'M/D' : 'YYYY/M');
};

export const weekdayLabels = [
  '月曜日',
  '火曜日',
  '水曜日',
  '木曜日',
  '金曜日',
  '土曜日',
  '日曜日',
];

export const sumConversionAggregation = (
  conversionAggregations: ConversionAggregation[]
): ConversionAggregation => {
  const initial: ConversionAggregation = {
    courseSelectViewedTotal: 0,
    dateSelectViewedTotal: 0,
    formViewedTotal: 0,
    reservedTotal: 0,
  };

  return conversionAggregations.reduce((prev, current) => {
    return {
      courseSelectViewedTotal:
        prev.courseSelectViewedTotal + current.courseSelectViewedTotal,
      dateSelectViewedTotal:
        prev.dateSelectViewedTotal + current.dateSelectViewedTotal,
      formViewedTotal: prev.formViewedTotal + current.formViewedTotal,
      reservedTotal: prev.reservedTotal + current.reservedTotal,
    };
  }, initial);
};
