import { css } from '@emotion/react';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { useRef } from 'react';
import { MD_BREAKPOINT, SM_BREAKPOINT } from '../../../hooks/use-size-type';
import { useCustomer } from '../api/useCustomer';
import { useUpdateCustomer } from '../api/useUpdateCustomer';
import {
  CustomerEditForm,
  CustomerEditFormRef,
  CustomerEditFormSaveHandler,
} from './CustomerSelector/CustomerEditForm';

const styles = {
  gridWrapper: css`
    margin-top: 20px;
    @media screen and (max-width: ${MD_BREAKPOINT}px) {
      margin-top: 0;
    }
  `,
  sidebarWrapper: css`
    max-width: 400px;
    @media screen and (max-width: ${MD_BREAKPOINT}px) {
      max-width: none;
    }
  `,
  buttonsWrapper: css`
    @media screen and (max-width: ${SM_BREAKPOINT}px) {
      margin-top: 16px;
    }
  `,
};

type CustomerDialogProps = {
  workspaceUid: string;
  shopId: number;
  customerId: number;
  open: boolean;
  onUpdate: () => void;
  onClose: () => void;
};

export const CustomerDialog = ({
  workspaceUid,
  shopId,
  customerId,
  open,
  onUpdate,
  onClose,
}: CustomerDialogProps): JSX.Element => {
  const { data: customer, isLoading } = useCustomer(workspaceUid, customerId);
  const customerEditFormRef = useRef<CustomerEditFormRef>(null);
  const updateCustomer = useUpdateCustomer();
  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = () => {
    customerEditFormRef.current?.submitForm();
  };

  const handleSave: CustomerEditFormSaveHandler = (form) => {
    void updateCustomer(workspaceUid, customerId, form).then(() => {
      enqueueSnackbar('顧客情報を更新しました。', { variant: 'success' });
      onUpdate();
      onClose();
    });
  };

  const buildContents = () => {
    if (isLoading) {
      return <CircularProgress />;
    }
    return (
      <Grid container spacing={3} css={styles.gridWrapper}>
        <Grid item xs={12}>
          <CustomerEditForm
            shopId={shopId}
            ref={customerEditFormRef}
            hideButtonButtons={true}
            customer={customer}
            onSave={handleSave}
            isShowInDialog={true}
          />
        </Grid>
      </Grid>
    );
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogContent>{buildContents()}</DialogContent>
      <DialogActions>
        <Button onClick={onClose}>閉じる</Button>
        <Button variant="contained" color="primary" onClick={handleSubmit}>
          更新する
        </Button>
      </DialogActions>
    </Dialog>
  );
};
