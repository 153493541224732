/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import React from 'react';
import { SlotSettingWithCourses } from '../../../@interfaces/slot-setting';
import LinkRouter from '../../../components/LinkRouter';

const styles = {
  actionCell: css`
    width: 110px;
    text-align: center;
  `,
};

interface Props {
  workspaceUid: string;
  shopId: string;
  shopUid: string;
  slotSettings: SlotSettingWithCourses[];
}

export default function ShopSlotSettingsTable(props: Props) {
  const { workspaceUid, shopId, slotSettings } = props;

  return (
    <TableContainer component={Paper}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>コース名</TableCell>
            <TableCell css={styles.actionCell}>アクション</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {slotSettings.map((slotSetting, index) => (
            <TableRow key={slotSetting.id}>
              <TableCell scope="row">
                {slotSetting.courses.map((c) => c.name).join('/')}
              </TableCell>
              <TableCell scope="row" css={styles.actionCell}>
                <LinkRouter
                  to={`/a/${workspaceUid}/shops/${shopId}/settings/slot-settings/${slotSetting.id}`}
                >
                  予約枠
                </LinkRouter>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
