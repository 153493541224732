/** @jsxImportSource @emotion/react */

import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import useReservationTimeTable, {
  ReservationTimeTable,
} from '../../api/use-reservation-time-table';
import {
  IDate,
  toDateStringByDate,
  toDateStringByDayjs,
  toDayjs,
} from '../../core/types/reservation-types';
import {
  AUTO_RELOAD_RESERVATIONS_INTERVAL,
  useAutoReloadReservations,
} from '../../hooks/use-auto-reload-reservations';
import { useInterval } from '../../hooks/use-interval';
import {
  Calendar,
  calendarCss,
  RenderCellHandler,
  RenderCellStyleHandler,
} from './Calendar';
import { CalendarCell } from './calendar-helper';

export function ReservationCalendar(props: {
  shopId: string;
  floating: boolean;
  currentDate: IDate;
  courseId?: number;
  onChangeCurrentDate: (newDate: IDate) => void;
  onChangeTimeTable?: (table: ReservationTimeTable | undefined) => void;
}) {
  const {
    shopId,
    floating,
    currentDate,
    courseId,
    onChangeCurrentDate,
    onChangeTimeTable,
  } = props;
  const currentDayJs = toDayjs(currentDate);
  const [selectedViewDate, setSelectedViewDate] =
    useState<dayjs.Dayjs>(currentDayJs);
  const {
    reservationTimeTable,
    isLoadingReservationTimeTable,
    reloadReservationTimeTable,
  } = useReservationTimeTable(
    shopId,
    toDateStringByDayjs(selectedViewDate.startOf('month')),
    toDateStringByDayjs(selectedViewDate.endOf('month'))
  );

  useEffect(() => {
    onChangeTimeTable?.(reservationTimeTable);
  }, [onChangeTimeTable, reservationTimeTable]);

  const { autoReloadReservations } = useAutoReloadReservations();

  useInterval(
    reloadReservationTimeTable,
    AUTO_RELOAD_RESERVATIONS_INTERVAL,
    autoReloadReservations.autoReload
  );

  const handleChangeSelectedViewDate = (newSelectedViewDate: dayjs.Dayjs) => {
    setSelectedViewDate(newSelectedViewDate);
  };

  const handleRenderCell: RenderCellHandler = (
    cell: CalendarCell | null,
    courseId?: number
  ) => {
    if (!cell) {
      return null;
    }

    const dateString = toDateStringByDate({
      year: selectedViewDate.year(),
      month: selectedViewDate.month() + 1,
      date: cell.date,
    });
    const reservedTotal =
      reservationTimeTable?.reservedTotalByDate[dateString] || 0;
    const reserved = reservationTimeTable?.tables?.flatMap((table) => {
      if (!cell?.date) {
        return [];
      }
      const targetDate = table.dates.find((date) => {
        return (
          date.date.year === selectedViewDate.year() &&
          date.date.month === selectedViewDate.month() + 1 &&
          date.date.date === cell.date
        );
      });
      if (!targetDate) {
        return [];
      }
      return targetDate.slots.filter((slot) => slot.reserved.total > 0);
    });
    return (reserved && reserved.length > 0) || reservedTotal > 0 ? (
      <div css={calendarCss.annotations.default}></div>
    ) : null;
  };

  const handleRenderCellStyle: RenderCellStyleHandler = (
    cell: CalendarCell | null,
    courseId?: number
  ) => {
    if (!cell) {
      return undefined;
    }

    const slots = reservationTimeTable?.tables?.flatMap((table) => {
      if (!cell?.date) {
        return [];
      }
      if (courseId && table.course.id !== courseId) {
        return [];
      }
      const targetDate = table.dates.find((date) => {
        return (
          date.date.year === selectedViewDate.year() &&
          date.date.month === selectedViewDate.month() + 1 &&
          date.date.date === cell.date
        );
      });
      if (!targetDate) {
        return [];
      }
      return targetDate.slots;
    });
    const reserved = slots?.reduce((total, slot) => {
      return total + slot.reserved.total;
    }, 0);
    const capacity = slots?.reduce((total, slot) => {
      return total + slot.capacity.total;
    }, 0);
    if (capacity === undefined || capacity === 0 || reserved === undefined) {
      return {
        backgroundColor: '#eee',
      };
    }
    const percent = reserved / capacity;
    return percent
      ? { backgroundColor: `rgba(255, 176, 163,${percent})` }
      : undefined;
  };

  return (
    <Calendar
      shopId={shopId}
      courseId={courseId}
      floating={floating}
      currentDate={currentDate}
      onChangeSelectedViewDate={handleChangeSelectedViewDate}
      onChangeCurrentDate={onChangeCurrentDate}
      onRenderCell={handleRenderCell}
      onRenderCellStyle={handleRenderCellStyle}
    />
  );
}
