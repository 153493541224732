/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { Breadcrumbs, Button, Grid } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import useReactRouter from 'use-react-router';
import { API_END_POINT } from '../../../../api/api';
import useFormSetting from '../../../../api/use-form-setting';
import useWorkspaceCourse from '../../../../api/use-workspace-course';
import {
  BreadcrumbLinkItem,
  BreadcrumbTextItem,
  BreadcrumbWorkspaceItem,
} from '../../../../components/breadcrumb/BreadcrumbItem';
import { commonCss } from '../../../../components/common-css';
import { WorkspacePageLayout } from '../../../../components/layouts/WorkspacePageLayout';
import { PageTitleAndDescription } from '../../../../components/PageTitleAndDescription';
import {
  FullscreenLoading,
  Head,
  Main,
  Root,
} from '../../../../components/Shared';
import Spinner from '../../../../components/Spinner';
import { Store } from '../../../../context/GlobalStore';
import { FormSetting } from '../../../../core/types/reservation-form-types';
import { useFormFieldsPreview } from '../../../../features/preview/hooks/useFormFieldsPreview';
import { globalColors } from '../../../../styles/globalColors';
import { helps } from '../../../../utils/helps';
import { randomString } from '../../../../utils/string';
import { FormAlertDialog } from './FormAlertDialog';
import { FormSettingFields } from './FormSettingFields';

interface PageParams {
  workspaceUid: string;
  courseId: string;
}

type Form = {
  setting: FormSetting;
  originalSetting: FormSetting;
};

export default function WorkspaceCourseFormSettingPage(): JSX.Element {
  const { globalState } = useContext(Store);
  const { history, match } = useReactRouter<PageParams>();
  const { workspaceUid, courseId } = match.params;
  const validationContext = useForm();
  const { handleSubmit } = validationContext;

  const { course } = useWorkspaceCourse(workspaceUid, courseId);
  const { formSetting, isLoadingFormSetting } = useFormSetting(
    workspaceUid,
    courseId
  );

  const [form, setForm] = useState<Form>({
    setting: {
      fields: [],
      deletedFields: [],
    },
    originalSetting: {
      fields: [],
    },
  });

  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [isShowAlert, setIsShowAlert] = useState(false);
  const [isSetDefaultValue, setIsSetDefaultValue] = useState(false);
  const [isExistsField, setIsExistsField] = useState(false);
  const [addItems, setAddItems] = useState<string[]>([]);
  const { enqueueSnackbar } = useSnackbar();
  const { preview, previewOpen, updatePreview } = useFormFieldsPreview();

  useEffect(() => {
    setIsShowAlert(addItems.length > 0 || isExistsField);
  }, [addItems, isExistsField]);

  useEffect(() => {
    if (!formSetting) {
      return;
    }
    // 過去のオプションにはUIDが設定されていないため、ロード時に強制的に設定
    formSetting.fields?.forEach((field) => {
      if (field.type === 'checkbox' || field.type === 'radio') {
        field.options.forEach((option) => {
          option.uid = option.uid || randomString(6);
        });
      }
    });
    setForm({
      setting: formSetting,
      originalSetting: JSON.parse(JSON.stringify(formSetting)) as FormSetting,
    });
    setIsSetDefaultValue(formSetting.fields?.length === 0);
  }, [formSetting]);

  useEffect(() => {
    updatePreview(form.setting);
  }, [form.setting, updatePreview]);

  const handleChangeSetting = (setting: FormSetting) => {
    setForm({ ...form, setting });
  };

  const handleClickCancel = () => {
    history.push(`/a/${workspaceUid}/settings/courses`);
  };

  const handleSubmitForm = (e: React.FormEvent) => {
    const json = {
      setting: form.setting,
    };
    setOpenBackdrop(true);
    axios
      .put(
        `${API_END_POINT}/app/ws/${workspaceUid}/courses/${courseId}/form-setting`,
        json,
        {
          headers: {
            Authorization: globalState.session?.idToken,
          },
        }
      )
      .then(() => {
        enqueueSnackbar('フォーム設定を保存しました。', { variant: 'success' });
        history.push(`/a/${workspaceUid}/settings/courses`);
      })
      .catch((e) => {
        enqueueSnackbar('フォーム設定が保存できませんでした。', {
          variant: 'error',
        });
      })
      .finally(() => {
        setOpenBackdrop(false);
      });
  };

  const buildRightNavigationButtons = () => {
    return (
      <>
        <Button
          css={commonCss.button.right}
          type="button"
          variant="text"
          color="primary"
          onClick={handleClickCancel}
        >
          キャンセル
        </Button>
        <Button
          css={commonCss.button.right}
          type="submit"
          variant="contained"
          color="primary"
          disabled={form.setting.fields.length === 0}
        >
          &nbsp;保存&nbsp;
        </Button>
      </>
    );
  };

  const buildContents = () => {
    if (isLoadingFormSetting) {
      return <Spinner loading={true} />;
    }
    if (!formSetting) {
      return <div>設定が取得できませんでした。</div>;
    }
    return (
      <>
        <form
          onSubmit={handleSubmit(handleSubmitForm)}
          css={
            previewOpen
              ? css`
                  margin-right: 480px;
                `
              : null
          }
        >
          <Grid container style={{ marginTop: '20px' }}>
            <Grid item xs={6}>
              <h3>{course?.name}のフォーム設定</h3>
            </Grid>
            <Grid
              item
              container
              xs={6}
              alignItems="center"
              justifyContent="flex-end"
            >
              {buildRightNavigationButtons()}
            </Grid>
          </Grid>
          <Alert severity="info" style={{ marginBottom: '20px' }}>
            <strong>
              名前、メールアドレス、電話番号の入力項目は特別な項目です。項目の種別から該当の種別を選択してください。
            </strong>
          </Alert>
          <FormSettingFields
            workspaceUid={workspaceUid}
            setting={form.setting}
            onChangeSetting={handleChangeSetting}
            setIsExistsField={setIsExistsField}
            setAddItems={(newValue: string[]) => {
              setAddItems(newValue);
            }}
            isSetDefaultValue={isSetDefaultValue}
            originalSetting={form.originalSetting}
          />
          <Grid container style={{ marginTop: '20px' }}>
            <Grid item xs={6}></Grid>
            <Grid
              item
              container
              xs={6}
              alignItems="center"
              justifyContent="flex-end"
            >
              {buildRightNavigationButtons()}
            </Grid>
          </Grid>
        </form>
        <FormAlertDialog
          isShowAlert={isShowAlert}
          setIsShowAlert={setIsShowAlert}
          addItems={addItems}
          isExistsField={isExistsField}
        />
        {preview}
      </>
    );
  };

  const pageTitle = 'コースフォーム設定';

  return (
    <Root>
      <Head title={`ワークスペース設定 -  ${course?.name || ''}`} />
      <Main>
        <WorkspacePageLayout
          workspaceUid={workspaceUid}
          current="course"
          breadcrumbs={
            <Breadcrumbs aria-label="breadcrumb">
              <BreadcrumbLinkItem to={`/`}>ホーム</BreadcrumbLinkItem>
              <BreadcrumbWorkspaceItem workspaceUid={workspaceUid} />
              <BreadcrumbLinkItem to={`/a/${workspaceUid}/settings/courses`}>
                コース設定
              </BreadcrumbLinkItem>
              <BreadcrumbTextItem>{pageTitle}</BreadcrumbTextItem>
            </Breadcrumbs>
          }
          helpId={helps.workspace.setting.course}
        >
          <PageTitleAndDescription
            title="ワークスペース"
            subTitle={pageTitle}
            description="コース予約時にユーザーが入力するフォームの設定を行います。"
            themeColor={globalColors.workspace}
          />
          {buildContents()}
        </WorkspacePageLayout>
      </Main>
      <FullscreenLoading open={openBackdrop} />
    </Root>
  );
}
