/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { Link } from 'react-router-dom';
import { useSizeType } from '../hooks/use-size-type';
import { ReactComponent as AppImage } from '../static/logos/app-logo.svg';
import StgImage from '../static/logos/stg-logo.png';

const styles = {
  logo: css`
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    transition: opacity 0.2s ease-in-out;

    &:hover {
      opacity: 0.7;
    }
  `,
  resetLinkStyles: css`
    color: inherit;
    text-decoration: none;

    &:hover,
    &:focus,
    &:active {
      color: inherit;
      text-decoration: none;
    }
  `,
};

export function AppLogo(): JSX.Element {
  return (
    <Link to="/" css={[styles.logo, styles.resetLinkStyles]}>
      {process.env.REACT_APP_STAGE === 'stg' ? (
        <img width={189} src={StgImage} alt="logo" />
      ) : (
        <AppImage />
      )}
    </Link>
  );
}

/*
 * AppLogo の直下で使用し、画像の中央あたりに文字列を表示するためのコンポーネント
 */
export function AppLabel(): JSX.Element {
  const { isPcSize } = useSizeType();

  return (
    <div
      css={css`
        padding-left: ${isPcSize ? '60px' : '68px'};
      `}
    >
      ロカオプ予約
    </div>
  );
}
