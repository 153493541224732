import React, { useContext } from 'react';
import { Redirect } from 'react-router-dom';
import { Store } from '../context/GlobalStore';
import { getCurrentUser } from '../api/auth/cognito';

export default function Auth(props: any) {
  const currentUser = getCurrentUser();

  const next = window.location.pathname + window.location.search;
  return currentUser ? (
    props.children
  ) : (
    <Redirect to={`/signin?next=${next}`} />
  );
}
