import {
  AuthenticationDetails,
  CognitoUser,
  CognitoUserAttribute,
  CognitoUserPool,
  IAuthenticationCallback,
  ISignUpResult,
  NodeCallback,
  CognitoUserSession,
} from 'amazon-cognito-identity-js';

// ユーザープールの設定
const poolData = {
  UserPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID ?? '',
  ClientId: process.env.REACT_APP_COGNITO_CLIENT_ID ?? '',
};

const userPool = new CognitoUserPool(poolData);

export const signup = (
  email: string,
  name: string,
  password: string,
  token: string | undefined,
  callback: NodeCallback<Error, ISignUpResult>
) => {
  const nameValue = {
    Name: 'name',
    Value: name,
  };
  const nameAttr = new CognitoUserAttribute(nameValue);
  const attrs = [];
  attrs.push(nameAttr);
  if (token) {
    const tokenValue = {
      Name: 'custom:token',
      Value: token,
    };
    const tokenAttr = new CognitoUserAttribute(tokenValue);
    attrs.push(tokenAttr);
  }
  userPool.signUp(email, password, attrs, [], callback);
};

export const activate = (
  email: string,
  code: string,
  callback: NodeCallback<Error, ISignUpResult>
) => {
  const cognitoUser = newCognitoUser(email);
  cognitoUser.confirmRegistration(code, true, callback);
};

export const newCognitoUser = (email: string) => {
  const userData = {
    Username: email,
    Pool: userPool,
  };
  return new CognitoUser(userData);
};

export const signin = (
  email: string,
  password: string,
  callback: IAuthenticationCallback
) => {
  const authenticationData = {
    Username: email,
    Password: password,
  };
  const authenticationDetails = new AuthenticationDetails(authenticationData);
  const cognitoUser = newCognitoUser(email);
  cognitoUser.authenticateUser(authenticationDetails, {
    onSuccess: function (result) {
      const idToken = result.getIdToken().getJwtToken();
      const accessToken = result.getAccessToken().getJwtToken();
      const refreshToken = result.getRefreshToken().getToken();
      callback.onSuccess(result);
    },
    onFailure: function (err) {
      callback.onFailure(err);
    },
  });
};

export const getCurrentUser = () => userPool.getCurrentUser();

export const getSession = (
  callback: NodeCallback<Error, CognitoUserSession>
) => {
  const cognitoUser = userPool.getCurrentUser();
  if (cognitoUser) {
    (cognitoUser as any).getSession(
      (err: Error | undefined, session: CognitoUserSession) => {
        callback(err, session);
      }
    );
  } else {
    const err = { name: 'NOT_LOGGED_IN', message: 'ログインしていません' };
    callback(err, undefined);
  }
};

export const signOut = () => {
  const cognitoUser = userPool.getCurrentUser();
  if (cognitoUser) {
    cognitoUser.signOut();
  }
};

export const changePassword = async (
  oldPassword: string,
  newPassword: string
): Promise<void> => {
  const cognitoUser = userPool.getCurrentUser();
  if (!cognitoUser) {
    throw new Error('ログインしていません');
  }
  return new Promise((resolve, reject) => {
    cognitoUser.getSession((sessionError: Error | null) => {
      if (sessionError) {
        reject(sessionError);
        return;
      }
      cognitoUser.changePassword(oldPassword, newPassword, (err) => {
        if (err) {
          reject(err);
        } else {
          resolve();
        }
      });
    });
  });
};
