/** @jsxImportSource @emotion/react */

import { calcGrowthPercent, styles } from '../utils/insight-utils';

export function ComparisonValue({
  primaryValue,
  comparisonValue,
}: {
  primaryValue: number;
  comparisonValue: number;
}): JSX.Element {
  const { displayValue } = calcGrowthPercent(primaryValue, comparisonValue);

  return (
    <div css={styles.tableNumberCell}>
      <div>{comparisonValue.toLocaleString()}</div>
      <div>{displayValue}</div>
    </div>
  );
}
