/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import {
  Button,
  IconButton,
  MenuItem,
  Popover,
  TextField,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Share from '@material-ui/icons/Share';
import { Alert } from '@material-ui/lab';
import { useSnackbar } from 'notistack';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { ShopCourse } from '../@interfaces/course';
import { InflowSource } from '../@interfaces/inflow-source';
import { getInflowSources } from '../api/use-inflow-sources';
import useShopCourses from '../api/use-shop-courses';
import { Store } from '../context/GlobalStore';
import { SM_BREAKPOINT } from '../hooks/use-size-type';
import { buildPublicCourseUrl, buildPublicShopUrl } from '../utils/urls';
import { commonCss } from './common-css';
import LinkRouter from './LinkRouter';

const styles = {
  container: css`
    position: relative;
    padding: 10px;
  `,
  row: css`
    padding: 5px;
  `,
  inflowSourceName: css`
    font-size: 14px;

    @media screen and (max-width: ${SM_BREAKPOINT}px) {
      margin-top: 8px;
    }
  `,
  input: css`
    color: #777;
    font-size: 14px;
    padding: 7px;
    width: 400px;
    border: solid 1px #aaa;
    border-radius: 5px 5px;

    @media screen and (max-width: ${SM_BREAKPOINT}px) {
      width: 100%;
    }
  `,
  copyButton: css`
    margin-left: 5px;

    @media screen and (max-width: ${SM_BREAKPOINT}px) {
      margin-top: 8px;
      margin-left: 0;
    }
  `,
  openButton: css`
    margin-left: 5px;

    @media screen and (max-width: ${SM_BREAKPOINT}px) {
      margin-top: 8px;
    }
  `,
  popoverCloseButton: css`
    position: absolute;
    top: 4px;
    right: 4px;
    background: #ffffff;
    z-index: 10000;
  `,
  closeButton: css`
    position: fixed;
    left: 0;
    bottom: 0;
    background: red;
    width: 100%;
  `,
};

const UNKNOWN_INFLOW_SOURCE_UID = '__unknown__';
const DEFAULT_COURSE_ID = -1;

const unknownInflowSource: InflowSource = {
  id: -1,
  workspaceId: -1,
  name: '流入元未指定',
  uid: UNKNOWN_INFLOW_SOURCE_UID,
  createdAt: '',
  updatedAt: '',
};

export default function ShareFormUrlButton(props: {
  workspaceUid: string;
  shopUid: string;
  shopId: string;
}) {
  const { globalState, setGlobalState } = useContext(Store);
  const { workspaceUid, shopUid, shopId } = props;
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleChangeSelectedCourseId = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = e.target.value;
    setSelectedCourseId(parseInt(value));
  };
  const [selectedCourseId, setSelectedCourseId] =
    React.useState<number>(DEFAULT_COURSE_ID);
  const { shopCourses: _shopCourses } = useShopCourses(shopId);

  const selectedCourse = useMemo(() => {
    return _shopCourses.find((course) => course.id === selectedCourseId);
  }, [selectedCourseId, _shopCourses]);

  const handleClickOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClickClose = () => {
    setAnchorEl(null);
  };

  const [inflowSources, setInflowSources] = useState<InflowSource[]>([]);

  useEffect(() => {
    if (!anchorEl || !globalState.session?.idToken) {
      return;
    }
    getInflowSources(
      globalState.session.idToken,
      workspaceUid,
      (inflowSources: InflowSource[]) => {
        setInflowSources(inflowSources);
      }
    );
  }, [anchorEl]);

  const buildInflowSource = (source: InflowSource) => {
    return (
      <InflowSourceRow
        workspaceUid={workspaceUid}
        shopUid={shopUid}
        source={source}
        selectedCourse={selectedCourse}
      />
    );
  };

  const buildInflowSourceList = () => {
    return inflowSources.map(buildInflowSource);
  };

  const buildUnknownInflowSource = () => {
    return buildInflowSource(unknownInflowSource);
  };

  return (
    <>
      <Button
        type="button"
        variant="text"
        size="small"
        startIcon={<Share />}
        color="primary"
        css={css`
          width: 100%;
        `}
        onClick={handleClickOpen}
      >
        <span css={commonCss.forPC}>共有</span>
        <span css={commonCss.forSP}>共有</span>
      </Button>
      {Boolean(anchorEl) && (
        <IconButton onClick={handleClickClose} css={styles.popoverCloseButton}>
          <CloseIcon />
        </IconButton>
      )}
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClickClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <div css={styles.container}>
          <Alert severity="info">
            流入元を特定できるWeb予約画面のURLをコピーします。
            <br />
            流入元未指定は極力使用しないようにしてください。
            <br />
            適切な流入元が存在しない場合は
            <LinkRouter to={`/a/${workspaceUid}/settings/inflow-sources`}>
              流入元の編集
            </LinkRouter>
            より追加をしてください。
          </Alert>
          {_shopCourses.length && (
            <TextField
              select
              label="コース"
              css={css`
                margin-top: 20px;
                margin-right: 20px;
                margin-left: 5px;
                margin-bottom: 10px;
                width: 300px;
              `}
              onChange={handleChangeSelectedCourseId}
              value={selectedCourseId}
            >
              <MenuItem key={DEFAULT_COURSE_ID} value={DEFAULT_COURSE_ID}>
                コース未指定（店舗トップを表示）
              </MenuItem>
              {_shopCourses.map((course) => {
                return (
                  <MenuItem key={course.id} value={course.id}>
                    {course.name} {course.deletedAt && '(削除済み)'}
                  </MenuItem>
                );
              })}
            </TextField>
          )}
          {buildUnknownInflowSource()}
          {buildInflowSourceList()}
        </div>
      </Popover>
    </>
  );
}

function InflowSourceRow(props: {
  workspaceUid: string;
  shopUid: string;
  source: InflowSource;
  selectedCourse: ShopCourse | undefined;
}) {
  const { workspaceUid, shopUid, source, selectedCourse } = props;
  const { enqueueSnackbar } = useSnackbar();

  const url = useMemo(() => {
    const baseUrl = selectedCourse
      ? buildPublicCourseUrl(workspaceUid, shopUid, selectedCourse.uid)
      : buildPublicShopUrl(workspaceUid, shopUid);
    if (source.uid == UNKNOWN_INFLOW_SOURCE_UID) {
      return baseUrl;
    } else {
      return `${baseUrl}?_src=${encodeURIComponent(source.uid)}`;
    }
  }, [workspaceUid, shopUid, source, selectedCourse]);

  const handleClickCopy = () => {
    navigator.clipboard.writeText(url);
    enqueueSnackbar(
      `流入元「${source.name}」のURLをクリップボードにコピーしました。`
    );
  };

  return (
    <div css={styles.row} key={source.id}>
      <div css={styles.inflowSourceName}>{source.name}:</div>
      <input css={styles.input} type="text" value={url} />
      <Button
        css={styles.copyButton}
        onClick={handleClickCopy}
        variant="outlined"
      >
        コピー
      </Button>
      <a
        href={url}
        target="_blank"
        rel="noreferrer"
        css={css`
          text-decoration: none;
        `}
      >
        <Button css={styles.openButton} variant="outlined">
          開く
        </Button>
      </a>
    </div>
  );
}
