import axios, { AxiosResponse } from 'axios';
import { useContext } from 'react';
import useSWR from 'swr';
import { GetReservationResponse } from '../../../@interfaces/insight/api';
import {
  AggregationGroup,
  AggregationUnit,
  DateAggregation,
  TargetDateType,
} from '../../../@interfaces/insight/backend';
import { API_END_POINT } from '../../../api/api';
import { Store } from '../../../context/GlobalStore';

const fetcher = async (
  url: string,
  token: string,
  startDate: string,
  endDate: string,
  aggregationUnit: AggregationUnit,
  targetDateType: TargetDateType,
  group: AggregationGroup
) => {
  return axios
    .get(url, {
      params: {
        startDate: startDate,
        endDate: endDate,
        aggregationUnit: aggregationUnit,
        targetDateType: targetDateType,
        group: group,
      },
      headers: {
        Authorization: token,
      },
    })
    .then((response: AxiosResponse<GetReservationResponse>) => response.data);
};

export default function useInsightReservation(
  shopId: string,
  startDate: string,
  endDate: string,
  aggregationUnit: AggregationUnit,
  targetDateType: TargetDateType,
  group: AggregationGroup,
  shouldFetch = true
): {
  results: DateAggregation[];
  isLoading: boolean;
} {
  const { globalState } = useContext(Store);

  const { data, isLoading } = useSWR(
    globalState.session?.idToken && shouldFetch
      ? [
          `${API_END_POINT}/app/shops/${shopId}/insight/reservation`,
          globalState.session.idToken,
          startDate,
          endDate,
          aggregationUnit,
          targetDateType,
          group,
        ]
      : null,
    async ([
      url,
      token,
      startDate,
      endDate,
      aggregationUnit,
      targetDateType,
      group,
    ]) =>
      fetcher(
        url,
        token,
        startDate,
        endDate,
        aggregationUnit,
        targetDateType,
        group
      )
  );

  return {
    results: data?.results ?? [],
    isLoading,
  };
}
