/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import {
  Button,
  Divider,
  FormControl,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  InputProps,
  Paper,
  TextField,
} from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { useSnackbar } from 'notistack';
import { ChangeEvent, FormEvent, useContext, useState } from 'react';
import { changePassword } from '../../api/auth/cognito';
import useCreateChangeEmailRequest from '../../api/use-create-change-email-request';
import useUpdateProfile from '../../api/use-update-profile';
import { BaseLayout } from '../../components/layouts/BaseLayout';
import { PageTitleAndDescription } from '../../components/PageTitleAndDescription';
import { Head, Main, Root, TopAppBar } from '../../components/Shared';
import { Account, SignedUser, Store } from '../../context/GlobalStore';
import { MainMenu } from '../../features/main-menu/components/MainMenu';
import {
  SideMenuItem,
  SideMenuItemButton,
} from '../../features/side-menu/components/SideMenuItem';
import { sideMenuStyles } from '../../features/side-menu/styles';
import { globalColors } from '../../styles/globalColors';

const styles = {
  tab: css`
    text-align: left;
    background: white;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  `,
  paper: css`
    padding: 16px;
  `,
  passwordInput: css`
    margin-top: 16px;
    margin-bottom: 8px;
  `,
  errorText: css`
    color: #f44336;
    margin: 0;
    margin-top: 3px;
    font-size: 0.75rem;
    text-align: left;
    font-weight: 400;
    line-height: 1.66;
    letter-spacing: 0.0333em;
  `,
  mailAddress: css`
    font-weight: bold;
    margin-left: 10px;
  `,
};

export default function UserProfilePage(): JSX.Element {
  return (
    <Root>
      <Head title="プロフィール編集" />
      <Main>
        <PageContent />
      </Main>
    </Root>
  );
}

const PageContent = () => {
  const { globalState, setGlobalState } = useContext(Store);
  const [selectedTab, setSelectedTab] = useState<TabValues>('USER_INFO');

  const updateUserInfo = (userProfile: { name: string }) => {
    setGlobalState({
      ...globalState,
      signedUser: {
        ...(globalState.signedUser as SignedUser),
        account: {
          ...(globalState.signedUser as SignedUser).account,
          ...userProfile,
        },
      },
    });
  };

  const buildSideMenu = (showMainMenuItems: boolean) => (
    <div css={sideMenuStyles.sideMenu}>
      {showMainMenuItems && (
        <>
          <SideMenuItem
            label="ホーム"
            href={`/`}
            isCurrent={false}
            sideColor={globalColors.main}
          />
          <Divider
            css={css`
              margin: 4px 0;
            `}
          />
        </>
      )}
      <SideMenuItemButton
        label="ユーザー情報変更"
        isCurrent={selectedTab === 'USER_INFO'}
        sideColor={globalColors.profile}
        onClick={() => {
          setSelectedTab('USER_INFO');
        }}
      />
      <SideMenuItemButton
        label="メールアドレス変更"
        isCurrent={selectedTab === 'CHANGE_EMAIL'}
        sideColor={globalColors.profile}
        onClick={() => {
          setSelectedTab('CHANGE_EMAIL');
        }}
      />
      <SideMenuItemButton
        label="パスワード変更"
        isCurrent={selectedTab === 'CHANGE_PASSWORD'}
        sideColor={globalColors.profile}
        onClick={() => {
          setSelectedTab('CHANGE_PASSWORD');
        }}
      />
    </div>
  );

  return (
    <BaseLayout
      header={<TopAppBar sideMenuContents={buildSideMenu(true)} />}
      side={buildSideMenu(false)}
      mainMenu={<MainMenu current="none" />}
    >
      {globalState.signedUser?.account && (
        <>
          <div hidden={selectedTab !== 'USER_INFO'}>
            <PageTitleAndDescription
              title="プロフィール変更"
              subTitle="ユーザー情報変更"
              description="ユーザー名の変更を行います。"
              themeColor={globalColors.profile}
            />
            <UserInfoTabContent
              account={globalState.signedUser.account}
              updateUserInfo={updateUserInfo}
            />
          </div>
          <div hidden={selectedTab !== 'CHANGE_EMAIL'}>
            <PageTitleAndDescription
              title="プロフィール変更"
              subTitle="メールアドレス変更"
              description="メールアドレスの変更を行います。"
              themeColor={globalColors.profile}
            />
            <ChangeEmailTabContent
              currentMailAddress={globalState.signedUser.account.email}
            />
          </div>
          <div hidden={selectedTab !== 'CHANGE_PASSWORD'}>
            <PageTitleAndDescription
              title="プロフィール変更"
              subTitle="パスワード変更"
              description="パスワードの変更を行います。"
              themeColor={globalColors.profile}
            />
            <ChangePasswordTabContent />
          </div>
        </>
      )}
    </BaseLayout>
  );
};

type TabValues = 'USER_INFO' | 'CHANGE_EMAIL' | 'CHANGE_PASSWORD';

const UserInfoTabContent = ({
  account,
  updateUserInfo,
}: {
  account: Account;
  updateUserInfo: (userProfile: { name: string }) => void;
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [name, setName] = useState(account.name);
  const [errorMessage, setErrorMessage] = useState('');
  const { updateProfile, loading } = useUpdateProfile();
  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    updateProfile({ name })
      .then(() => {
        enqueueSnackbar('ユーザ情報を保存しました');
        updateUserInfo({ name });
      })
      .catch((e) => {
        setErrorMessage(e.message);
      });
  };
  return (
    <>
      <Paper css={styles.paper}>
        <form onSubmit={handleSubmit}>
          <TextField
            label="ユーザ名"
            placeholder="変更後のユーザ名を入力"
            value={name}
            margin="normal"
            fullWidth
            inputProps={{ maxLength: 50 }}
            onChange={(e) => {
              setName(e.target.value);
            }}
            error={!!errorMessage}
            helperText={errorMessage}
            required
          />
          <Grid container alignItems="flex-end" justifyContent="flex-end">
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={loading}
            >
              保存
            </Button>
          </Grid>
        </form>
      </Paper>
    </>
  );
};

const ChangeEmailTabContent = ({
  currentMailAddress,
}: {
  currentMailAddress: string;
}) => {
  const [newEmail, setNewEmail] = useState('');
  const [errorText, setErrorText] = useState('');
  const { createChangeEmailRequest, loading } = useCreateChangeEmailRequest();
  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    createChangeEmailRequest(newEmail)
      .then(() => {
        alert(
          `指定されたメールアドレスにメールアドレス変更用のメールを送信しました。届いたメールから変更手続きを行ってください`
        );
      })
      .catch((e) => {
        setErrorText(e.message);
      });
  };
  const handleChangeNewEmail = (e: ChangeEvent<HTMLInputElement>) => {
    setNewEmail(e.target.value);
  };
  return (
    <>
      <Paper css={styles.paper}>
        <form onSubmit={handleSubmit}>
          <p>メールアドレス変更手続き用のメールを送信します</p>
          <p>
            現在のメールアドレス:
            <span css={styles.mailAddress}>{currentMailAddress}</span>
          </p>
          <TextField
            label="変更後のメールアドレス"
            type="email"
            placeholder="変更後のメールアドレスを入力"
            value={newEmail}
            onChange={handleChangeNewEmail}
            margin="normal"
            fullWidth
            required
            error={!!errorText}
            helperText={errorText}
          />
          <Grid container alignItems="flex-end" justifyContent="flex-end">
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={loading}
            >
              メール送信
            </Button>
          </Grid>
        </form>
      </Paper>
    </>
  );
};

const ChangePasswordTabContent = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [currentPasswordErrorMessage, setCurrentPasswordErrorMessage] =
    useState('');
  const [newPasswordErrorMessage, setNewPasswordErrorMessage] = useState('');

  const handleChangeCurrentPassword = (e: ChangeEvent<HTMLInputElement>) => {
    setCurrentPassword(e.target.value);
  };

  const handleChangeNewPassword = (e: ChangeEvent<HTMLInputElement>) => {
    setNewPassword(e.target.value);
  };

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    setCurrentPasswordErrorMessage('');
    setNewPasswordErrorMessage('');
    if (currentPassword === newPassword) {
      setCurrentPasswordErrorMessage('同じパスワードが入力されています');
      setNewPasswordErrorMessage('同じパスワードが入力されています');
      return;
    }
    if (!isValidPassword(newPassword)) {
      setNewPasswordErrorMessage(
        'パスワードは大文字/小文字/数字を含んだ8文字以上で設定してください。'
      );
      return;
    }

    changePassword(currentPassword, newPassword)
      .then(() => {
        setCurrentPassword('');
        setNewPassword('');
        enqueueSnackbar('パスワードを変更しました');
      })
      .catch((e) => {
        if (e.name === 'NotAuthorizedException') {
          setCurrentPasswordErrorMessage('パスワードが違います');
        } else if (e.name === 'LimitExceededException') {
          setCurrentPasswordErrorMessage(
            'パスワード変更リクエストの上限回数を超えました。1時間以上時間をおいて再度お試しください。'
          );
          setNewPasswordErrorMessage(
            'パスワード変更リクエストの上限回数を超えました。1時間以上時間をおいて再度お試しください。'
          );
        } else {
          setCurrentPasswordErrorMessage(e.message);
          setNewPasswordErrorMessage(e.message);
        }
      });
  };

  return (
    <>
      <Paper css={styles.paper}>
        <form onSubmit={handleSubmit}>
          <p>現在のパスワードと新しいパスワードを入力してください</p>
          <Grid container>
            <Grid item xs={12}>
              <PasswordInput
                id="current-password"
                label="現在のパスワード"
                value={currentPassword}
                onChange={handleChangeCurrentPassword}
                placeholder="現在のパスワードを入力"
                autoComplete="current-password"
                required
                error={!!currentPasswordErrorMessage}
                helperText={currentPasswordErrorMessage}
              />
            </Grid>
            <Grid item xs={12}>
              <PasswordInput
                id="new-password"
                label="新しいパスワード"
                value={newPassword}
                onChange={handleChangeNewPassword}
                placeholder="新しいパスワードを入力"
                autoComplete="new-password"
                required
                error={!!newPasswordErrorMessage}
                helperText={newPasswordErrorMessage}
              />
            </Grid>
          </Grid>
          <Grid container alignItems="flex-end" justifyContent="flex-end">
            <Button type="submit" variant="contained" color="primary">
              パスワード変更
            </Button>
          </Grid>
        </form>
      </Paper>
    </>
  );
};

const PasswordInput = (
  props: InputProps & { label: string; helperText?: string }
): JSX.Element => {
  const [showPassword, setShowPassword] = useState(false);
  const { label, helperText, ...inputProps } = props;
  return (
    <FormControl css={styles.passwordInput}>
      <InputLabel error={inputProps.error} required={inputProps.required}>
        {label}
      </InputLabel>
      <Input
        {...inputProps}
        type={showPassword ? 'text' : 'password'}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              onClick={() => {
                setShowPassword((current) => !current);
              }}
            >
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        }
      />
      <p css={styles.errorText}>{helperText}</p>
    </FormControl>
  );
};

const isValidPassword = (password: string) => {
  const regexBottom = /[a-z]/;
  const regexUpper = /[A-Z]/;
  const regexNumber = /[0-9]/;

  return (
    password.length >= 8 &&
    !!regexBottom.exec(password) &&
    !!regexUpper.exec(password) &&
    !!regexNumber.exec(password)
  );
};
