import { useCallback, useContext } from 'react';
import { Store } from '../context/GlobalStore';
import useStorage from './use-storage';

export default function useBookmarkedShops() {
  const { globalState } = useContext(Store);
  const userPrefix = globalState.signedUser?.account.uid || 'unknown';
  const [bookmarkedShopIds, setBookmarkedShopIds] = useStorage<number[]>(
    `${userPrefix}_bookmarkedShopIds`,
    []
  );

  const toggleBookmarkedShop = useCallback(
    (shopId: number) => {
      const shop = bookmarkedShopIds.find((id) => id === shopId);
      if (shop) {
        const newShopIds = bookmarkedShopIds.filter((id) => id !== shopId);
        setBookmarkedShopIds(newShopIds);
      } else {
        setBookmarkedShopIds([...bookmarkedShopIds, shopId]);
      }
    },
    [setBookmarkedShopIds, bookmarkedShopIds]
  );

  return {
    bookmarkedShopIds,
    toggleBookmarkedShop,
  };
}
