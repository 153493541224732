import { CustomerInfo, CustomerWithReservationCount } from '../customer';
import { CustomerLastInput } from '../customer-last-input';
import { LineUser } from '../line-user';
import { ShopCustomer } from '../shop-customer';

export type RichCustomerInfo = {
  customer: CustomerWithReservationCount;
  lineUser: LineUser | undefined;
  shopCustomers: ShopCustomer[];
  customerLastInput: CustomerLastInput | undefined;
};

export const ActivityType = {
  customerAdded: 1,
  customerModified: 2,
  customerDeleted: 3,
  customerMerged: 4,
} as const;

export const OperationSource = {
  user: 1,
  customer: 2,
  system: 3,
} as const;

export const CUSTOMER_OPERATOR_NAME = '予約者';
export const SYSTEM_NAME = 'システム';

export type OperationSourceType =
  typeof OperationSource[keyof typeof OperationSource];

export type CustomerActivityDiffItem = {
  name: string;
  old: string | null;
  new: string | null;
};
export type CustomerActivityDiff = {
  diffs: CustomerActivityDiffItem[];
};
export type MergedIntoActivityJson = {
  type: 'into';
  customerId: number;
};
export type MergedFromActivityJson = {
  type: 'from';
  customerId: number[];
};
export type MergedActivityJson =
  | MergedIntoActivityJson
  | MergedFromActivityJson;
