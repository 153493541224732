import axios from 'axios';
import { useContext, useState } from 'react';
import { Store } from '../../context/GlobalStore';
import { API_END_POINT } from '../api';

export default function useDeleteGoogleCalendarIntegration() {
  const { globalState } = useContext(Store);
  const [loading, setLoading] = useState(false);

  const googleDeleteCalendarIntegrationApi = (shopId: string, id: number) => {
    setLoading(true);
    axios
      .delete(
        `${API_END_POINT}/app/shops/${shopId}/google/calendar/integration/${id}`,
        {
          headers: {
            Authorization: globalState.session?.idToken,
          },
        }
      )
      .finally(() => {
        setLoading(false);
      });
  };

  return {
    googleDeleteCalendarIntegrationApi,
    isLoadingDeleteCalendarIntegration: loading,
  };
}
