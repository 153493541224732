/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { Button, Divider, Menu, MenuItem } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import chroma from 'chroma-js';
import { useState } from 'react';
import { Resource } from '../../../@interfaces/resource/resource';
import { ResourceSchedule } from '../../../@interfaces/resource/resource-schedule';
import { ResourceSchedulePattern } from '../../../@interfaces/resource/resource-schedule-pattern';
import { timeString } from '../../../utils/time';
import { toPatternRangeString } from './common';

interface Props {
  resource: Resource;
  patterns: ResourceSchedulePattern[];
  resourceSchedule?: ResourceSchedule;
  onClickManualScheduleItem: (open: boolean) => void;
  onSelectPattern: (pattern: ResourceSchedulePattern | undefined) => void;
  onClear: () => void;
  onRestoreDefaultPattern: () => void;
}

const SET_MANUAL_SCHEDULE_MENU_ITEM_VALUE = -1;

export const SchedulePatternChooser: React.VFC<Props> = ({
  resource,
  patterns,
  resourceSchedule,
  onClickManualScheduleItem,
  onSelectPattern,
  onClear,
  onRestoreDefaultPattern,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const label = (() => {
    if (resourceSchedule?.resourceSchedulePatternId) {
      return patterns.find(
        (p) => p.id === resourceSchedule.resourceSchedulePatternId
      )?.name;
    } else if (
      resourceSchedule?.schedule &&
      resourceSchedule.schedule.timeRanges !== null
    ) {
      const timeRange = resourceSchedule.schedule.timeRanges[0];
      return timeRange
        ? `${timeString(timeRange.start)} ~ ${timeString(timeRange.end)}${
            resourceSchedule.schedule.timeRanges.length > 1 ? '...' : ''
          }`
        : '';
    } else if (resource.resourceSchedulePatternId) {
      return patterns.find((p) => p.id === resource.resourceSchedulePatternId)
        ?.name;
    }
  })();

  const handleSelectPatternItem = (pattern: ResourceSchedulePattern) => {
    onSelectPattern(pattern);
    setAnchorEl(null);
  };

  const handleClickManual = () => {
    onClickManualScheduleItem(true);
    setAnchorEl(null);
  };

  const handleClickClear = () => {
    onClear();
    setAnchorEl(null);
  };

  const handleClickRestoreDefaultPattern = () => {
    onRestoreDefaultPattern();
    setAnchorEl(null);
  };

  const buildCellStyle = () => {
    // スケジュールに時間が指定されている場合
    if (resourceSchedule?.schedule) {
      return null;
    }
    // スケジュールにパターンが設定されている場合
    const patternColor = patterns.find(
      (p) => p.id === resourceSchedule?.resourceSchedulePatternId
    )?.color;
    if (patternColor) {
      return css`
        border: solid 2px ${patternColor};
        background-color: ${patternColor};
      `;
    }
    // リソースデフォルトにパターンが設定されている場合
    const defaultPatternColor = patterns.find(
      (p) => p.id === resource.resourceSchedulePatternId
    )?.color;
    if (defaultPatternColor) {
      const backgroundColor = chroma(defaultPatternColor).brighten(2);
      return css`
        border: solid 2px ${defaultPatternColor};
        background-color: ${backgroundColor.hex()};
        border-radius: 4px;
      `;
    }
    // なにも設定されていない場合
    return null;
  };

  return (
    <div css={buildCellStyle()}>
      <Button onClick={handleClick}>
        {label}
        <ArrowDropDownIcon />
      </Button>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem value={undefined} onClick={handleClickClear}>
          クリア
        </MenuItem>
        {resource.resourceSchedulePatternId && (
          <MenuItem
            value={resource.resourceSchedulePatternId}
            onClick={handleClickRestoreDefaultPattern}
          >
            デフォルトの稼働パターン(
            {
              patterns.find((p) => p.id === resource.resourceSchedulePatternId)
                ?.name
            }
            )に戻す
          </MenuItem>
        )}
        <MenuItem
          value={SET_MANUAL_SCHEDULE_MENU_ITEM_VALUE}
          onClick={handleClickManual}
        >
          {resourceSchedule?.schedule == null ||
          resourceSchedule.schedule?.timeRanges?.[0] == null
            ? '時間指定...'
            : (() => {
                const timeRange =
                  resourceSchedule.schedule.timeRanges !== null
                    ? resourceSchedule.schedule.timeRanges[0]
                    : undefined;
                return timeRange
                  ? `${timeString(timeRange.start)} ~ ${timeString(
                      timeRange.end
                    )}${
                      resourceSchedule.schedule.timeRanges.length > 1
                        ? '...'
                        : ''
                    }`
                  : '';
              })()}
        </MenuItem>
        {patterns.map((pattern) => (
          <MenuItem
            key={pattern.id}
            value={pattern.id}
            css={css`
              background-color: ${pattern.color} !important;
            `}
            onClick={() => {
              handleSelectPatternItem(pattern);
            }}
          >
            {pattern.name}({toPatternRangeString(pattern)})
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};
