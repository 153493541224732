import axios, { AxiosResponse, Method } from 'axios';
import { useContext } from 'react';
import { Store } from '../../../context/GlobalStore';

export const useMutation = <R, D>(): {
  request: (
    method: Method,
    url: string,
    queryParams?: any,
    data?: D
  ) => Promise<AxiosResponse<R>>;
} => {
  const { globalState } = useContext(Store);

  const request = (
    method: Method,
    url: string,
    queryParams?: any,
    data?: D
  ): Promise<AxiosResponse<R>> => {
    if (!globalState.session?.idToken) {
      return Promise.reject();
    }
    const params = queryParams || {};
    return axios.request({
      method,
      url,
      params,
      data,
      headers: {
        Authorization: globalState.session?.idToken,
      },
    });
  };

  return { request: request };
};

export type MutationResult<R> = {
  data: R;
  isLoading: boolean;
  reload: () => void;
};
