/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import Spinner from '../../../components/Spinner';
import { useReservationActivities } from '../api/useReservationActivities';
import { ReservationActivityListItem } from './ReservationActivityListItem';

const styles = {
  container: css`
    display: flex;
    flex-direction: column;
    padding: 0 12px;
    gap: 8px;
  `,
  spinnerContainer: css`
    height: 100%;
    display: grid;
    place-content: center;
  `,
  emptyMsgContainer: css`
    height: 100%;
    display: flex;
    margin-top: 8px;
    justify-content: center;
  `,
  itemWrapper: css`
    &:not(:first-of-type) {
      border-top: 2px solid lightgray;
    }
  `,
};

type Props = {
  shopId: string;
  reservationId: string;
};

export function ReservationActivityList({
  shopId,
  reservationId,
}: Props): JSX.Element {
  const { response, isLoading } = useReservationActivities(
    shopId,
    reservationId
  );

  if (isLoading) {
    return (
      <div css={styles.spinnerContainer}>
        <Spinner loading={true} />
      </div>
    );
  }

  if (response?.activities.length === 0) {
    return (
      <div css={styles.emptyMsgContainer}>
        <p>更新履歴機能のリリース後に記録された履歴はありません</p>
      </div>
    );
  }

  return (
    <div css={styles.container}>
      {response?.activities.map((activity) => (
        <div key={activity.id} css={styles.itemWrapper}>
          <ReservationActivityListItem activity={activity} />
        </div>
      ))}
    </div>
  );
}
