import { AxiosResponse } from 'axios';
import { API_END_POINT } from '../../../api/api';
import { useMutation } from '../../common/api/use-mutation';

export const useDeleteResourceGroups = (): ((
  shopId: string,
  resourceGroupId: number
) => Promise<AxiosResponse<''>>) => {
  const { request } = useMutation<'', undefined>();
  return (shopId, resourceGroupId) => {
    return request(
      'delete',
      `${API_END_POINT}/app/shops/${shopId}/resource-groups/${resourceGroupId}`
    );
  };
};
