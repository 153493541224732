/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import styled from '@emotion/styled';
import {
  Backdrop,
  Box,
  CircularProgress,
  Divider,
  TextField,
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import AccountCircle from '@material-ui/icons/AccountCircle';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import PersonIcon from '@material-ui/icons/Person';
import SearchIcon from '@material-ui/icons/Search';
import {
  Autocomplete,
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
  AutocompleteInputChangeReason,
  createFilterOptions,
} from '@material-ui/lab';
import React, { useContext, useRef, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useHotkeys } from 'react-hotkeys-hook';
import { Link } from 'react-router-dom';
import useReactRouter from 'use-react-router';
import { signOut } from '../api/auth/cognito';
import { SearchResult, useSearch } from '../api/use-search';
import { Store } from '../context/GlobalStore';
import useCurrentWorkspace from '../context/use-current-workspace';
import { SpSideMenuDrawer } from '../features/side-menu/components/SpSideMenuDrawer';
import { SM_BREAKPOINT, useSizeType } from '../hooks/use-size-type';
import { openHelpPage } from '../utils/helps';
import { AppLabel, AppLogo as NewAppLogo } from './AppLogo';
import { commonCss } from './common-css';
import LinkRouter from './LinkRouter';

const styles = {
  title: css``,
  searchBarContainer: css`
    padding: 0px 20px;
    flex-grow: 1;
  `,
  searchBar: css`
    max-width: 500px;
  `,
  logo: css`
    cursor: pointer;
    &:hover {
      opacity: 0.7;
    }
    @media screen and (max-width: ${SM_BREAKPOINT}px) {
      font-size: 14px;
    }
  `,
  stgLogo: css`
    cursor: pointer;
    color: cyan;
    font-weight: bold;
    &:hover {
      opacity: 0.7;
    }
    @media screen and (max-width: ${SM_BREAKPOINT}px) {
      font-size: 14px;
    }
  `,
  logoText: css`
    padding-left: 5px;
    font-weight: bold;
    cursor: pointer;
    &:hover {
      opacity: 0.7;
    }
    @media screen and (max-width: ${SM_BREAKPOINT}px) {
      font-size: 14px;
    }
  `,
  backdrop: css`
    z-index: 99999;
    color: #fff;
  `,
  headerButton: css`
    font-size: 14px;
    border-radius: 5px;
    @media screen and (max-width: ${SM_BREAKPOINT}px) {
      padding: 8px;
    }
  `,
};

const topAppBarStyles = {
  rootContainer: (isPcSize: boolean) => css`
    padding: ${isPcSize ? '12px 12px 0 12px' : '0 12px'};
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    background-color: #fff;
    border-bottom: 1px solid #c3c3c3;
    z-index: 1201; // theme.zIndex.drawer + 1 をログに出して確認して、暫定的に直接同じ値を指定している
  `,
  flexContainer: css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  `,
  leftArea: css`
    flex: 1 1 auto;
    display: flex;
    align-items: center;
    gap: 16px;
    min-width: 0;
  `,
  rightArea: css`
    flex: 0 0 auto;
    display: flex;
    align-items: center;
  `,
  wsLink: css`
    flex: 0 1 auto;
    color: #000;
    text-decoration: none;
    font-weight: 400;
    font-size: 20px;
    line-height: 22px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    &:visited {
      color: #000;
    }
  `,
  flexibleItem: css`
    flex: 1 1 auto;
  `,
  inflexibleItem: css`
    flex: 0 0 auto;
  `,
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    menuButton: {
      marginRight: theme.spacing(2),
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      '@media print': {
        display: 'none',
      },
    },
    searchBarInputWrapper: {
      position: 'relative',
      fontSize: '14px',
      backgroundColor: '#ffffff',
      borderRadius: '24px',
      border: '1px solid #DDDDDD',
    },
    searchIcon: {
      position: 'absolute',
      top: '50%',
      left: '12px',
      transform: 'translate(0,-50%)',
      fontSize: '24px',
      color: '#707070',
    },
    searchBar: {
      maxWidth: '500px',
    },
    searchBarForSp: {
      '& .MuiAutocomplete-endAdornment': {
        top: 'auto',
      },
    },
  })
);

export const Root = styled.div`
  display: flex;
`;

export const Main = styled.div`
  flex-grow: 1;
  padding: 3px;
  padding-bottom: 100px;
  min-width: 0;
`;

interface TopAppBarProps {
  helpId?: string;
  sideMenuContents?: JSX.Element;
}

let lastTypedAt = 0;
const TYPING_WAIT_MS = 300;

function SearchBar({ autoFocus = false }: { autoFocus?: boolean }) {
  const classes = useStyles();
  const searchInput = useRef<HTMLInputElement>(null);
  const [keyword, setKeyword] = useState<string>('');
  const [searchKeyword, setSearchKeyword] = useState<string>('');
  const { data, isLoading } = useSearch(searchKeyword.trim());
  const { isPcSize } = useSizeType();
  const store = useContext(Store);

  useHotkeys('ctrl+/', () => {
    searchInput.current?.focus();
  });

  const handleChange = (
    _: React.ChangeEvent<{}>,
    value: SearchResult | null,
    reason: AutocompleteChangeReason,
    detail?: AutocompleteChangeDetails<SearchResult>
  ): void => {
    if (!value) {
      return;
    }
    if (value.type == 'workspace') {
      window.location.href = `/a/${value.uid}`;
    } else if (value.type == 'shop') {
      const workspace = store.globalState.signedUser?.workspaces.find(
        (w) => w.id === value.workspaceId
      );
      if (!workspace) {
        return;
      }
      window.location.href = `/a/${workspace.uid}/shops/${value.id}`;
    }
  };

  const handleInputChange = (
    _: React.ChangeEvent<{}>,
    value: string,
    reason: AutocompleteInputChangeReason
  ): void => {
    if (reason !== 'input') {
      setKeyword('');
      return;
    }
    setKeyword(value);

    // 最後にキーが押された時間を記録
    lastTypedAt = new Date().getTime();
    setTimeout(() => {
      const currentTime = new Date().getTime();
      const isCompletedTyping = currentTime - lastTypedAt >= TYPING_WAIT_MS;
      if (isCompletedTyping) {
        setSearchKeyword(value);
      }
    }, TYPING_WAIT_MS);
  };

  const filterOptions = createFilterOptions({
    stringify: (option: SearchResult) => keyword,
  });

  const getOptionLabel = (result: SearchResult) => {
    return result.name;
  };

  const getGroupLabel = (result: SearchResult) => {
    if (result.type == 'shop') {
      const workspace = store.globalState.signedUser?.workspaces.find(
        (w) => w.id === result.workspaceId
      );
      return `${workspace?.name || ''} / 店舗`;
    }
    return 'ワークスペース';
  };

  return (
    <div
      className={classes.searchBarInputWrapper}
      style={{
        maxWidth: isPcSize ? '500px' : '100%',
        minWidth: isPcSize ? '300px' : '100%',
      }}
    >
      <SearchIcon className={classes.searchIcon} />
      <Autocomplete
        options={data}
        getOptionLabel={getOptionLabel}
        groupBy={getGroupLabel}
        filterOptions={filterOptions}
        className={isPcSize ? classes.searchBar : classes.searchBarForSp}
        getOptionSelected={(option, value) => true}
        size="small"
        loading={isLoading}
        inputValue={keyword}
        autoHighlight
        openOnFocus
        noOptionsText="検索結果はありません。"
        loadingText="読込中..."
        onChange={handleChange}
        onInputChange={handleInputChange}
        renderInput={(params) => (
          <TextField
            {...params}
            inputRef={searchInput}
            placeholder="検索(Ctrl+/)"
            variant="outlined"
            style={{ paddingLeft: '32px', fontSize: '14px', width: '100%' }}
            autoFocus={autoFocus}
          />
        )}
      />
    </div>
  );
}

export function TopAppBar({ helpId, sideMenuContents }: TopAppBarProps) {
  const { history, match } = useReactRouter();
  const { workspaceUid } = match.params as any;
  const workspace = useCurrentWorkspace(workspaceUid);
  const { globalState, setGlobalState } = useContext(Store);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const openMenu = Boolean(anchorEl);
  const { isPcSize } = useSizeType();
  const [isShowSearchElemForSp, setIsShowSearchElemForSp] =
    useState<boolean>(false);

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClickHelp = () => {
    openHelpPage(helpId);
  };

  const handleClickSearch = () => {
    setIsShowSearchElemForSp(!isShowSearchElemForSp);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEditProfile = () => {
    setAnchorEl(null);
    history.push('/profile');
  };

  const handleSignout = () => {
    signOut();
    setGlobalState({ session: undefined, signedUser: undefined });
    history.push('/signin');
  };

  const buildUserMenu = () => (
    <>
      {globalState.signedUser && (
        <div>
          <IconButton
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleMenu}
            color="inherit"
            css={styles.headerButton}
          >
            <AccountCircle />
            <span css={commonCss.forPC}>
              {globalState.signedUser.account.name}
            </span>
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={openMenu}
            onClose={handleClose}
          >
            <Box
              pl={2}
              display="flex"
              alignItems="center"
              css={css`
                color: rgba(0, 0, 0, 0.6);
              `}
            >
              <PersonIcon />
              <Box component="span" pl={0.5}>
                {globalState.signedUser.account.name}
              </Box>
            </Box>
            <MenuItem onClick={handleEditProfile}>プロフィール変更</MenuItem>
            <Divider />
            <MenuItem onClick={handleSignout}>ログアウト</MenuItem>
          </Menu>
        </div>
      )}
      {globalState.session === undefined && (
        <LinkRouter to="/signin" style={{ color: '#fff' }}>
          ログイン
        </LinkRouter>
      )}
    </>
  );

  return (
    <div css={[topAppBarStyles.rootContainer(isPcSize), commonCss.noPrint]}>
      <div css={topAppBarStyles.flexContainer}>
        <div css={topAppBarStyles.leftArea}>
          {!isPcSize && sideMenuContents && (
            <SpSideMenuDrawer>{sideMenuContents}</SpSideMenuDrawer>
          )}
          {isPcSize && (
            <div css={topAppBarStyles.inflexibleItem}>
              <NewAppLogo />
            </div>
          )}
          {workspace && (
            <Link
              css={[topAppBarStyles.flexibleItem, topAppBarStyles.wsLink]}
              to={`/a/${workspace.uid}/`}
            >
              {workspace.name}
            </Link>
          )}
          {globalState.signedUser && isPcSize && (
            <div css={topAppBarStyles.flexibleItem}>
              <SearchBar />
            </div>
          )}
        </div>
        <div css={topAppBarStyles.rightArea}>
          {globalState.signedUser && !isPcSize && (
            <IconButton
              onClick={handleClickSearch}
              color="inherit"
              css={styles.headerButton}
            >
              <SearchIcon />
            </IconButton>
          )}
          <IconButton
            onClick={handleClickHelp}
            color="inherit"
            css={styles.headerButton}
          >
            <HelpOutlineIcon />
            <span css={commonCss.forPC}>ヘルプ</span>
          </IconButton>
          {buildUserMenu()}
        </div>
      </div>
      {isPcSize && <AppLabel />}
      {isShowSearchElemForSp && !isPcSize && (
        <Box
          style={{
            width: '100%',
            margin: '0 auto',
            padding: '8px 16px 16px',
          }}
        >
          <SearchBar autoFocus={true} />
        </Box>
      )}
    </div>
  );
}

export function FullscreenLoading(props: { open: boolean }) {
  return (
    <Backdrop css={styles.backdrop} open={props.open}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
}

export const TopAppBarSpacer = styled.div`
  min-height: 132px;
  @media (max-width: 600px) {
    min-height: 180px;
  }
  //  カウントダウンラベルと新UI切り替えボタンが複数行になりラベルが広がるサイズ
  @media (min-width: 600px) and (max-width: 700px) {
    min-height: 160px;
  }
  //  ヘルプとユーザー名が下段に降りてトップバーの幅が広がるサイズ
  @media (min-width: 900px) and (max-width: 1072px) {
    min-height: 156px;
  }
`;

const APP_NAME = 'Locaop予約';

export function Head(props: { title?: string }) {
  const { title } = props;
  const titleContent = title ? `${title} | ${APP_NAME}` : APP_NAME;
  return (
    <Helmet defer={false}>
      <title>{titleContent}</title>
    </Helmet>
  );
}
