/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import {
  Breadcrumbs,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Grid,
  MenuItem,
  Select,
  Switch,
} from '@material-ui/core';
import dayjs from 'dayjs';
import React, { ChangeEvent, useState } from 'react';
import useReactRouter from 'use-react-router';
import {
  ShopReport,
  ShopReportGroupingType,
} from '../../@interfaces/reservation';
import useShopReports from '../../api/use-shop-reports';
import {
  BreadcrumbLinkItem,
  BreadcrumbTextItem,
  BreadcrumbWorkspaceItem,
} from '../../components/breadcrumb/BreadcrumbItem';
import { WorkspacePageLayout } from '../../components/layouts/WorkspacePageLayout';
import { PageTitleAndDescription } from '../../components/PageTitleAndDescription';
import { Head, Main, Root } from '../../components/Shared';
import Spinner from '../../components/Spinner';
import useCurrentWorkspace from '../../context/use-current-workspace';
import { SM_BREAKPOINT, useSizeType } from '../../hooks/use-size-type';
import { globalColors } from '../../styles/globalColors';
import ShopReportsTable from '../../tables/ShopReportsTable';
import { helps } from '../../utils/helps';
import { writeXlsx } from '../../utils/xlsx';

type PageParams = {
  workspaceUid: string;
};

const defaultStartDate = dayjs().startOf('month').format('YYYY-MM-DD');
const defaultEndDate = dayjs().endOf('month').format('YYYY-MM-DD');

interface GroupingTypeLabel {
  label: string;
}

const groupingTypes: ShopReportGroupingType[] = [
  'inflowSource',
  'createdHour',
  'reservationHour',
  'createdDayOfWeek',
  'reservationDayOfWeek',
  'course',
  'label',
];

const groupingTypeLabels: {
  [groupingType in ShopReportGroupingType]: GroupingTypeLabel;
} = {
  inflowSource: {
    label: '流入元',
  },
  createdHour: {
    label: '時間（登録時間）',
  },
  reservationHour: {
    label: '時間（予約時間）',
  },
  createdDayOfWeek: {
    label: '曜日（登録日）',
  },
  reservationDayOfWeek: {
    label: '曜日（予約日）',
  },
  course: {
    label: 'コース',
  },
  label: {
    label: 'ラベル',
  },
};

const cssStyles = {
  breadCrumb: css`
    @media screen and (max-width: ${SM_BREAKPOINT}px) {
      font-size: 14px;
    }
  `,
  buttonsWrapper: css`
    @media screen and (max-width: ${SM_BREAKPOINT}px) {
      margin-top: 8px;
      margin-bottom: 16px;
      button {
        margin-left: 8px;
      }
    }
  `,
};

export default function WorkspaceShopReportsPage() {
  const { match } = useReactRouter<PageParams>();
  const { workspaceUid } = match.params;

  const pageTitle = '集計';

  return (
    <Root>
      <Head title="集計" />
      <Main>
        <WorkspacePageLayout
          workspaceUid={workspaceUid}
          current="reports"
          breadcrumbs={
            <Breadcrumbs aria-label="breadcrumb">
              <BreadcrumbLinkItem to={`/`}>ホーム</BreadcrumbLinkItem>
              <BreadcrumbWorkspaceItem workspaceUid={workspaceUid} />
              <BreadcrumbTextItem>{pageTitle}</BreadcrumbTextItem>
            </Breadcrumbs>
          }
          helpId={helps.dashboard.analitics}
        >
          <PageTitleAndDescription
            title="ワークスペース"
            subTitle={pageTitle}
            description="店舗ごとの予約数集計"
            themeColor={globalColors.workspace}
          />
          <WorkspaceShopReports />
        </WorkspacePageLayout>
      </Main>
    </Root>
  );
}

function WorkspaceShopReports() {
  const { match } = useReactRouter<PageParams>();
  const { workspaceUid } = match.params;
  const [startDate, setStartDate] = useState(defaultStartDate);
  const [endDate, setEndDate] = useState(defaultEndDate);
  const [groupingType, setGroupingType] =
    useState<ShopReportGroupingType>('inflowSource');
  const { shopReports, isLoadingShopReports } = useShopReports(
    workspaceUid,
    startDate,
    endDate,
    groupingType
  );
  const { isSpSize } = useSizeType();

  const handleChangeGroupingType = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setGroupingType(event.target.value as ShopReportGroupingType);
  };

  const handleChangeStartDate = (event: ChangeEvent<HTMLInputElement>) => {
    setStartDate(event.target.value);
  };

  const handleChangeEndDate = (event: ChangeEvent<HTMLInputElement>) => {
    setEndDate(event.target.value);
  };

  const handleChangeDateRangeToLastMonth = () => {
    const lastMonth = dayjs().add(-1, 'month');
    setStartDate(lastMonth.startOf('month').format('YYYY-MM-DD'));
    setEndDate(lastMonth.endOf('month').format('YYYY-MM-DD'));
  };

  const handleChangeDateRangeToThisMonth = () => {
    const thisMonth = dayjs();
    setStartDate(thisMonth.startOf('month').format('YYYY-MM-DD'));
    setEndDate(thisMonth.endOf('month').format('YYYY-MM-DD'));
  };

  const handleChangeDateRangeToNextMonth = () => {
    const nextMonth = dayjs().add(1, 'month');
    setStartDate(nextMonth.startOf('month').format('YYYY-MM-DD'));
    setEndDate(nextMonth.endOf('month').format('YYYY-MM-DD'));
  };

  const getGridSpacing = () => {
    return !isSpSize ? 3 : 1;
  };

  return (
    <>
      <Grid container spacing={getGridSpacing()} alignItems="center">
        <Grid item container xs={2} sm={1} alignItems="center">
          <h3>集計</h3>
        </Grid>
        <Grid
          item
          container
          xs={10}
          sm={3}
          alignItems="center"
          justifyContent="flex-end"
        >
          グルーピング:
          <Select value={groupingType} onChange={handleChangeGroupingType}>
            {groupingTypes.map((groupingType) => (
              <MenuItem key={groupingType} value={groupingType}>
                {groupingTypeLabels[groupingType].label}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid
          item
          container
          xs={12}
          sm={4}
          alignItems="center"
          justifyContent="flex-end"
        >
          期間:
          <input
            type="date"
            value={startDate}
            onChange={handleChangeStartDate}
            max="9999-12-31"
          />
          〜
          <input
            type="date"
            value={endDate}
            onChange={handleChangeEndDate}
            max="9999-12-31"
          />
          &nbsp;
        </Grid>
        <Grid
          item
          container
          xs={12}
          sm={4}
          alignItems="center"
          justifyContent="flex-end"
          css={cssStyles.buttonsWrapper}
        >
          <Button
            variant="contained"
            color="default"
            size="small"
            onClick={handleChangeDateRangeToLastMonth}
          >
            先月
          </Button>
          &nbsp;
          <Button
            variant="contained"
            color="default"
            size="small"
            onClick={handleChangeDateRangeToThisMonth}
          >
            今月
          </Button>
          &nbsp;
          <Button
            variant="contained"
            color="default"
            size="small"
            onClick={handleChangeDateRangeToNextMonth}
          >
            来月
          </Button>
          &nbsp;
          <XlsxDownloadButton
            workspaceUid={workspaceUid}
            groupingType={groupingType}
            shopReports={shopReports}
            startDate={startDate}
            endDate={endDate}
          />
        </Grid>
      </Grid>
      {isLoadingShopReports ? (
        <Spinner loading={isLoadingShopReports} />
      ) : (
        <ShopReportsTable
          workspaceUid={workspaceUid}
          shopReports={shopReports}
        />
      )}
    </>
  );
}

function XlsxDownloadButton({
  workspaceUid,
  groupingType,
  shopReports,
  startDate,
  endDate,
}: {
  workspaceUid: string;
  groupingType: ShopReportGroupingType;
  shopReports: ShopReport[];
  startDate: string;
  endDate: string;
}) {
  const currentWorkspace = useCurrentWorkspace(workspaceUid);
  const [open, setOpen] = useState(false);
  const [includingDetails, setIncludingDetails] = useState(true);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = () => {
    setIncludingDetails(!includingDetails);
  };

  const handleClickXlsxDownload = () => {
    if (!currentWorkspace) {
      return;
    }
    if (includingDetails) {
      const groupingName = groupingTypeLabels[groupingType];
      const headers = [
        '店舗名',
        '店舗ID',
        groupingName.label,
        '予約数(Web)',
        '予約数(内部)',
        '予約数合計',
      ];
      const rows = shopReports.flatMap((shopReport) => {
        const row = [
          shopReport.shopName,
          shopReport.shopUid,
          '',
          shopReport.webTotal,
          shopReport.internalTotal,
          shopReport.total,
        ];
        const details =
          shopReport.grouping?.details.map((detail) => {
            return [
              '',
              '',
              detail.label,
              detail.webTotal,
              detail.internalTotal,
              detail.total,
            ];
          }) || [];
        const lines = [row, ...details];
        return lines;
      });
      // Excelファイル生成
      const cells = [headers, ...rows];
      const filename = `${currentWorkspace.name}予約数集計_${groupingName.label}_${startDate}_${endDate}.xlsx`;
      const colsWidths = [50, 15, 15, 15, 15, 15];
      writeXlsx(cells, filename, colsWidths);
    } else {
      const headers = [
        '店舗名',
        '店舗ID',
        '予約数(Web)',
        '予約数(内部)',
        '予約数合計',
      ];
      const rows = shopReports.map((shopReport) => {
        return [
          shopReport.shopName,
          shopReport.shopUid,
          shopReport.webTotal,
          shopReport.internalTotal,
          shopReport.total,
        ];
      });
      // Excelファイル生成
      const cells = [headers, ...rows];
      const filename = `${currentWorkspace.name}予約数集計_${startDate}_${endDate}.xlsx`;
      const colsWidths = [50, 15, 15, 15, 15, 15];
      writeXlsx(cells, filename, colsWidths);
    }
    setOpen(false);
  };

  return (
    <>
      <Button
        variant="contained"
        color="default"
        size="small"
        onClick={handleClickOpen}
      >
        XLSX出力
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>XLSX出力</DialogTitle>
        <DialogContent>
          <DialogContentText>
            予約の集計をXLSX形式でダウンロードします。
          </DialogContentText>
          <FormControlLabel
            control={
              <Switch
                checked={includingDetails}
                onChange={handleChange}
                name="includingDetails"
                color="primary"
              />
            }
            label="グルーピングの明細行も出力する"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            キャンセル
          </Button>
          <Button onClick={handleClickXlsxDownload} color="primary">
            XLSXを出力
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
