import {
  ResourceScheduleListRequestParams,
  ResourceScheduleListResponse,
} from '../../../@interfaces/resource/apis';
import { API_END_POINT } from '../../../api/api';
import { DateFormat } from '../../../core/types/reservation-types';
import { useQuery } from '../../common/api/use-query';

export const useResourceSchedules = (
  shopId: string,
  startDate: string,
  endDate: string
) => {
  const params: ResourceScheduleListRequestParams = {
    startDate: startDate as DateFormat,
    endDate: endDate as DateFormat,
  };
  const { response, isLoading, reload } = useQuery<
    ResourceScheduleListResponse | undefined
  >(`${API_END_POINT}/app/shops/${shopId}/resource-schedules`, params);
  return {
    data: response,
    isLoading: isLoading,
    reload: reload,
  };
};
