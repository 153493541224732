/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { FormControl, FormLabel } from '@material-ui/core';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { TargetDateType } from '../../../@interfaces/insight/backend';
import { useStoreParams } from './useStoreParams';

export const useTargetDateType = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const [targetDateType, setTargetDateType] = useState<TargetDateType>(
    (searchParams.get('targetDateType') as TargetDateType) ?? 'reservationDate'
  );
  const { storeParams } = useStoreParams();

  useEffect(() => {
    // ブラウザバック実行時にURLパラメータから集計期間を復元
    const searchParams = new URLSearchParams(location.search);
    setTargetDateType(
      (searchParams.get('targetDateType') as TargetDateType) ??
        'reservationDate'
    );
  }, [location, setTargetDateType]);

  const targetDateTypeSelector = (
    <FormControl component="fieldset">
      <FormLabel
        css={css`
          margin-bottom: 0.5em;
        `}
        component="legend"
      >
        対象日付
      </FormLabel>
      <ToggleButtonGroup
        size="small"
        exclusive
        value={targetDateType}
        onChange={(_event, value) => {
          if (!value) {
            return;
          }
          setTargetDateType(value as TargetDateType);
          storeParams({ targetDateType: value });
        }}
        aria-label="対象日付"
      >
        <ToggleButton
          value="reservationDate"
          aria-label="来店日"
          css={css`
            padding: 0.5em 1em;
          `}
        >
          来店日
        </ToggleButton>
        <ToggleButton
          value="createdAt"
          aria-label="登録日"
          css={css`
            padding: 0.5em 1em;
          `}
        >
          登録日
        </ToggleButton>
      </ToggleButtonGroup>
    </FormControl>
  );

  return {
    targetDateTypeSelector,
    targetDateType,
  };
};
