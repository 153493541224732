/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { InputAdornment, TextField } from '@material-ui/core';
import TodayIcon from '@material-ui/icons/Today';
import 'dayjs/locale/ja';
import React, { useEffect, useRef, useState } from 'react';
import { IDate, toDayjs } from '../../core/types/reservation-types';
import { isParent } from '../../utils/browsers';
import { ReservationCalendar } from './ReservationCalendar';

const styles = {
  input: css({
    position: 'relative',
    display: 'inline-block',
    verticalAlign: 'bottom',
  }),
  calendarIcon: css({
    cursor: 'pointer',
  }),
};

type Props = {
  shopId: string;
  courseId?: number;
  currentDate: IDate;
  onChangeCurrentDate: (newDate: IDate) => void;
};

export default function FloatingReservationCalendar(props: Props) {
  const { shopId, courseId, currentDate, onChangeCurrentDate } = props;

  const [visibleCalendar, setVisibleCalendar] = useState(false);
  const calendarContainerEl = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handler = (e: any) => {
      if (!calendarContainerEl?.current) {
        return;
      }
      const child = e.target as HTMLElement;
      if (isParent(e.target, calendarContainerEl.current)) {
        return;
      }
      setVisibleCalendar(false);
    };
    document.body.addEventListener('click', handler, false);
    return () => {
      document.body.removeEventListener('click', handler, false);
    };
  }, []);

  const handleClickShowCalendar = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    setVisibleCalendar(true);
  };

  const handleChangeCurrentDate = (newDate: IDate) => {
    onChangeCurrentDate(newDate);
    setTimeout(() => {
      setVisibleCalendar(false);
    }, 1);
  };

  return (
    <div
      ref={calendarContainerEl}
      css={[styles.input]}
      onClick={handleClickShowCalendar}
    >
      {visibleCalendar}
      <TextField
        value={toDayjs(currentDate).locale('ja').format('YYYY-MM-DD(ddd)')}
        onClick={handleClickShowCalendar}
        label="予約日付"
        style={{ marginTop: '20px', marginRight: '20px', width: '140px' }}
        required
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <TodayIcon css={[styles.calendarIcon]} />
            </InputAdornment>
          ),
        }}
      />
      {visibleCalendar ? (
        <ReservationCalendar
          shopId={shopId}
          courseId={courseId}
          currentDate={currentDate}
          onChangeCurrentDate={handleChangeCurrentDate}
          floating={true}
        />
      ) : null}
    </div>
  );
}
