import { AxiosResponse } from 'axios';
import { API_END_POINT } from '../../../api/api';
import { useMutation } from '../../common/api/use-mutation';

export const useDeleteResources = (): ((
  shopId: string,
  resourceId: number
) => Promise<AxiosResponse<''>>) => {
  const { request } = useMutation<'', undefined>();
  return (shopId, resourceId) => {
    return request(
      'delete',
      `${API_END_POINT}/app/shops/${shopId}/resources/${resourceId}`
    );
  };
};
