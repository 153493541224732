/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import {
  Box,
  Breadcrumbs,
  Button,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  MenuItem,
  Paper,
  Select,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import axios from 'axios';
import dayjs, { Dayjs } from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import { useSnackbar } from 'notistack';
import React, { useContext, useEffect, useState } from 'react';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import useReactRouter from 'use-react-router';
import { Course } from '../../../@interfaces/course';
import { SlotSettingWithCourses } from '../../../@interfaces/slot-setting';
import { SlotSettingRule } from '../../../@interfaces/slot-setting-rule';
import { SpecialShopHoliday } from '../../../@interfaces/special-shop-holiday';
import { SpecialSlotSettingRule } from '../../../@interfaces/special-slot-setting-rule';
import { API_END_POINT } from '../../../api/api';
import useReservationTimeTable, {
  ReservationTimeTable,
} from '../../../api/use-reservation-time-table';
import useShop from '../../../api/use-shop';
import useSlotSettingRules from '../../../api/use-slot-setting-rules';
import useSlotSettings from '../../../api/use-slot-settings';
import useSpecialSlotSettingRule from '../../../api/use-special-slot-setting-rule';
import useSpecialSlotSettingRules from '../../../api/use-special-slot-setting-rules';
import {
  BreadcrumbLinkItem,
  BreadcrumbShopItem,
  BreadcrumbTextItem,
  BreadcrumbWorkspaceItem,
} from '../../../components/breadcrumb/BreadcrumbItem';
import { SpecialSlotSettingCalendar } from '../../../components/calendar/SpecialSlotSettingCalendar';
import { commonCss } from '../../../components/common-css';
import { ShopPageLayout } from '../../../components/layouts/ShopPageLayout';
import { PageTitleAndDescription } from '../../../components/PageTitleAndDescription';
import {
  FullscreenLoading,
  Head,
  Main,
  Root,
} from '../../../components/Shared';
import Spinner from '../../../components/Spinner';
import { Store } from '../../../context/GlobalStore';
import {
  createDate,
  createDateRange,
  DateFormat,
  HolidayChecker,
  IDate,
  matchSchedule,
  normalizeRule,
  RegularRule,
  Schedule,
  Time,
  toDateStringByDayjs,
  toDayjs,
  toTimeStringByTime,
} from '../../../core/types/reservation-types';
import {
  validateRule,
  ValidationError,
} from '../../../core/types/reservation-types-validation';
import { MD_BREAKPOINT, SM_BREAKPOINT } from '../../../hooks/use-size-type';
import { globalColors } from '../../../styles/globalColors';
import ReservationTimeTableView from '../../../tables/ReservationTimeTableView';
import { helps } from '../../../utils/helps';
import { TimeRangesEditor, UNITS } from './ShopSlotSettingRulePage';

dayjs.extend(isBetween);
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

const styles = {
  gridWrapper: css`
    margin-top: 20px;
    @media screen and (max-width: ${MD_BREAKPOINT}px) {
      margin-top: 0;
    }
  `,
  sidebarWrapper: css`
    max-width: 400px;
    @media screen and (max-width: ${MD_BREAKPOINT}px) {
      max-width: none;
    }
  `,
  calendarWrapper: css`
    @media screen and (max-width: ${SM_BREAKPOINT}px) {
      width: 100%;
      margin: 0 auto;
    }
  `,
  tableWrapper: css`
    box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
      0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
    max-width: 700px;
    @media screen and (max-width: ${MD_BREAKPOINT}px) {
      overflow: scroll;
      max-width: calc(100vw - 54px);
    }
    @media screen and (max-width: ${SM_BREAKPOINT}px) {
      max-width: calc(100vw - 38px);
    }
  `,
  specialTableWrapper: css`
    width: 100%;
    @media screen and (max-width: ${MD_BREAKPOINT}px) {
      max-width: calc(100vw - 54px);
      overflow: scroll;
    }
    @media screen and (max-width: ${SM_BREAKPOINT}px) {
      max-width: calc(100vw - 38px);
    }
  `,
  courseNameTd: css`
    @media screen and (max-width: ${SM_BREAKPOINT}px) {
      padding: 16px 8px 16px 16px;
    }
  `,
  actionTd: css`
    @media screen and (max-width: ${SM_BREAKPOINT}px) {
      width: 132px;
      padding: 16px 16px 8px 16px;
      text-align: center;
    }
  `,
  switchLabel: css`
    @media screen and (max-width: ${SM_BREAKPOINT}px) {
      display: block;
    }
  `,
  loadingRow: css`
    height: 56px;
  `,
  bottomButtons: css`
    margin-top: 20px;
  `,
};

type PageParams = {
  workspaceUid: string;
  shopId: string;
};

type ValidationErrorMap = { [courseId: number]: ValidationError[] };

export default function ShopSpecialRuleSettingPage(props: any) {
  const { history, match } = useReactRouter<PageParams>();
  const { workspaceUid, shopId } = match.params;
  const searchParams = new URL(window.location.href).searchParams;
  const date = searchParams.get('date');
  const [selectedDate, setSelectedDate] = useState(
    date ? toDayjs(createDate(date as DateFormat)) : dayjs()
  );
  const { shop, isLoadingShop } = useShop(shopId);

  const {
    reservationTimeTable,
    isLoadingReservationTimeTable,
    reloadReservationTimeTable,
  } = useReservationTimeTable(
    shopId,
    toDateStringByDayjs(selectedDate),
    toDateStringByDayjs(selectedDate)
  );

  const [selectedViewDate, setSelectedViewDate] =
    useState<dayjs.Dayjs>(selectedDate);
  const { holidays, specialRules, reloadShopSpecialSlotSettingRules } =
    useSpecialSlotSettingRules(
      shopId,
      toDateStringByDayjs(selectedViewDate.startOf('month')),
      toDateStringByDayjs(selectedViewDate.endOf('month'))
    );

  const {
    reservationTimeTable: calendarReservationTimeTable,
    reloadReservationTimeTable: reloadCalenderReservationTimeTable,
  } = useReservationTimeTable(
    shopId,
    toDateStringByDayjs(selectedViewDate.startOf('month')),
    toDateStringByDayjs(selectedViewDate.endOf('month'))
  );

  // history.back時にパラメーターから日付を復元
  useEffect(() => {
    if (date) {
      setSelectedDate(toDayjs(createDate(date as DateFormat)));
    } else {
      setSelectedDate(dayjs());
    }
  }, [searchParams.get('date')]);

  const handleClickTimeTableCell = (course: Course, time: Time) => {
    const params = new URLSearchParams({
      courseId: String(course.id),
      date: toDateStringByDayjs(selectedDate),
      time: toTimeStringByTime(time),
    });
    const url = `/a/${workspaceUid}/shops/${shopId}/reservations/new?${params}`;
    history.push(url);
  };

  const handleChangeSelectedDate = (selectedDate: Dayjs) => {
    setSelectedDate(selectedDate);
    const params = new URLSearchParams({
      date: toDateStringByDayjs(selectedDate),
    });
    const url = `/a/${workspaceUid}/shops/${shopId}/settings/special-rule?${params}`;
    history.push(url);
  };

  const handleUpdateSpecialRuleSetting = () => {
    reloadReservationTimeTable();
    reloadCalenderReservationTimeTable();
    reloadShopSpecialSlotSettingRules();
  };

  const buildContents = () => {
    if (isLoadingShop) {
      return <Spinner loading={true} />;
    }
    if (!shop) {
      return <div>店舗情報が取得できませんでした。</div>;
    }
    return (
      <Grid container spacing={3} css={styles.gridWrapper}>
        <Grid item container xs={12} md={4} css={styles.sidebarWrapper}>
          <Sidebar
            shopId={shopId}
            selectedDate={selectedDate}
            onChangeSelectedDate={handleChangeSelectedDate}
            selectedViewDate={selectedViewDate}
            setSelectedViewDate={setSelectedViewDate}
            holidays={holidays}
            specialRules={specialRules}
            reservationTimeTable={calendarReservationTimeTable}
          />
        </Grid>
        <Grid item container xs={12} md={8}>
          <TimeTable
            shopId={shopId}
            selectedDate={selectedDate}
            reservationTimeTable={reservationTimeTable}
            isLoadingReservationTimeTable={isLoadingReservationTimeTable}
            onClickTimeTableCell={handleClickTimeTableCell}
          />
        </Grid>
        <Grid item container xs={12}>
          <SpecialRulesTable
            workspaceUid={workspaceUid}
            shopId={shopId}
            selectedDate={selectedDate}
            onUpdate={handleUpdateSpecialRuleSetting}
            isLoadingReservationTimeTable={isLoadingReservationTimeTable}
          />
        </Grid>
      </Grid>
    );
  };

  const buildPageTitle = () => (
    <Grid container alignItems="center" justifyContent="flex-start">
      <h3>予約枠（臨時設定）</h3>
    </Grid>
  );

  const pageTitle = '予約枠臨時設定';

  return (
    <Root>
      <Head title={`予約枠（臨時設定） - ${shop?.name || ''}`} />
      <Main>
        <ShopPageLayout
          workspaceUid={workspaceUid}
          shopId={shopId}
          current="slot-special"
          helpId={helps.shop.setting.specialRule}
          breadcrumbs={
            <Breadcrumbs aria-label="breadcrumb">
              <BreadcrumbLinkItem to={`/`}>ホーム</BreadcrumbLinkItem>
              <BreadcrumbWorkspaceItem workspaceUid={workspaceUid} />
              <BreadcrumbShopItem workspaceUid={workspaceUid} shop={shop} />
              <BreadcrumbTextItem>{pageTitle}</BreadcrumbTextItem>
            </Breadcrumbs>
          }
        >
          <PageTitleAndDescription
            title="店舗"
            subTitle={pageTitle}
            description="臨時休業、営業時間変更等、イレギュラーな予約枠の設定を行います。"
            themeColor={globalColors.shop}
          />
          {buildContents()}
        </ShopPageLayout>
      </Main>
    </Root>
  );
}

type SidebarProps = {
  shopId: string;
  selectedDate: Dayjs;
  onChangeSelectedDate: (selectedDate: Dayjs) => void;
  selectedViewDate: dayjs.Dayjs;
  setSelectedViewDate: (selectedViewDate: dayjs.Dayjs) => void;
  holidays: SpecialShopHoliday[];
  specialRules: SpecialSlotSettingRule[];
  reservationTimeTable?: ReservationTimeTable;
};

function Sidebar(props: SidebarProps) {
  const {
    shopId,
    selectedDate,
    onChangeSelectedDate,
    selectedViewDate,
    setSelectedViewDate,
    holidays,
    specialRules,
    reservationTimeTable,
  } = props;

  const handleChangeCurrentDate = (date: IDate) => {
    onChangeSelectedDate(toDayjs(date));
  };

  return (
    <div css={styles.calendarWrapper}>
      <SpecialSlotSettingCalendar
        shopId={shopId}
        floating={false}
        currentDate={{
          year: selectedDate.year(),
          month: selectedDate.month() + 1,
          date: selectedDate.date(),
        }}
        onChangeCurrentDate={handleChangeCurrentDate}
        selectedViewDate={selectedViewDate}
        setSelectedViewDate={setSelectedViewDate}
        holidays={holidays}
        specialRules={specialRules}
        reservationTimeTable={reservationTimeTable}
      />
    </div>
  );
}

type TimeTableProps = {
  shopId: string;
  selectedDate: Dayjs;
  isLoadingReservationTimeTable: boolean;
  reservationTimeTable: ReservationTimeTable | undefined;
  onClickTimeTableCell: (course: Course, time: Time) => void;
};

function TimeTable(props: TimeTableProps) {
  const {
    shopId,
    selectedDate,
    isLoadingReservationTimeTable,
    reservationTimeTable,
    onClickTimeTableCell,
  } = props;

  if (isLoadingReservationTimeTable) {
    return <Spinner loading={true} />;
  }
  if (!reservationTimeTable) {
    return <div>予約枠が取得できませんでした。</div>;
  }
  if (reservationTimeTable.tables.length == 0) {
    return <div>予約枠はありません</div>;
  }
  return (
    <Box css={styles.tableWrapper}>
      <ReservationTimeTableView
        shopId={shopId}
        reservationTimeTable={reservationTimeTable}
        onClickCell={onClickTimeTableCell}
      />
    </Box>
  );
}

function SpecialRulesTable(props: {
  workspaceUid: string;
  shopId: string;
  selectedDate: Dayjs;
  onUpdate: () => void;
  isLoadingReservationTimeTable: boolean;
}) {
  const {
    workspaceUid,
    shopId,
    selectedDate,
    onUpdate,
    isLoadingReservationTimeTable,
  } = props;
  const { globalState, setGlobalState } = useContext(Store);
  const { slotSettings, isLoadingSlotSettins } = useSlotSettings(shopId);
  const {
    holiday,
    specialRules,
    reloadShopSpecialSlotSettingRules,
    isLoadingShopSpecialSlotSettingRules,
  } = useSpecialSlotSettingRule(shopId, toDateStringByDayjs(selectedDate));
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const [isHoliday, setHoliday] = useState(false);
  const [edittingSpecialRules, setEdittingSpecialRules] = useState<
    SpecialSlotSettingRule[]
  >([]);
  const [validationErrorMap, setValidationErrorMap] =
    useState<ValidationErrorMap>({});

  useEffect(() => {
    setHoliday(holiday != undefined);
  }, [holiday?.date]);

  useEffect(() => {
    setEdittingSpecialRules(specialRules);
  }, [specialRules]);

  useEffect(() => {
    const newErrorMap = edittingSpecialRules.reduce((map, rule) => {
      const errors = validateRule(rule.rule, 0);
      if (errors.length > 0) {
        map[rule.slotSettingId] = errors;
      }
      return map;
    }, [] as ValidationErrorMap);
    setValidationErrorMap(newErrorMap);
  }, [JSON.stringify(edittingSpecialRules)]);

  const save = (isHoliday: boolean) => {
    const specialRules = edittingSpecialRules;
    specialRules.forEach((sr) => {
      normalizeRule(sr.rule);
    });
    const json = {
      date: toDateStringByDayjs(selectedDate),
      isHoliday: isHoliday,
      specialRules,
    };
    setOpenBackdrop(true);
    axios
      .put(
        `${API_END_POINT}/app/shops/${shopId}/special-slot-settings/rule`,
        json,
        {
          headers: {
            Authorization: globalState.session?.idToken,
          },
        }
      )
      .then(() => {
        enqueueSnackbar('臨時設定を保存しました。', { variant: 'success' });
        reloadShopSpecialSlotSettingRules();
        onUpdate();
      })
      .catch((e) => {
        enqueueSnackbar('臨時設定を保存できませんでした。', {
          variant: 'error',
        });
      })
      .finally(() => {
        setOpenBackdrop(false);
      });
  };

  const handleChangeHoliday = (event: React.ChangeEvent<HTMLInputElement>) => {
    // TimeTable(サーバーでデータ生成)を読み直すために
    // 定休日設定がおこなわれたら自動保存して、
    // 最新の定休日設定を元にしたTimeTableを取得
    // TimeTableは編集時の初期設定のために使用される
    save(event.target.checked);
  };

  const handleClickSave = () => {
    save(isHoliday);
  };

  const buildRightNavigationButtons = () => {
    return (
      <>
        <Button
          css={commonCss.button.right}
          variant="contained"
          color="primary"
          onClick={handleClickSave}
          disabled={Object.keys(validationErrorMap).length > 0}
        >
          &nbsp;保存&nbsp;
        </Button>
      </>
    );
  };

  const buildSpecialRulesTable = () => {
    if (isLoadingShopSpecialSlotSettingRules) {
      return <Spinner loading={true} />;
    }
    return (
      <>
        <FormControl required fullWidth component="fieldset">
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  checked={isHoliday}
                  onChange={handleChangeHoliday}
                  name={`holiday`}
                />
              }
              label={`この日を定休日にする`}
            />
          </FormGroup>
        </FormControl>
        {!isHoliday && (
          <Box css={styles.specialTableWrapper}>
            <SlotSettingSpecialRuleTable
              workspaceUid={workspaceUid}
              shopId={shopId}
              slotSettings={slotSettings}
              specialRules={edittingSpecialRules}
              setEdittingSpecialRules={setEdittingSpecialRules}
              date={toDateStringByDayjs(selectedDate)}
              isLoadingReservationTimeTable={isLoadingReservationTimeTable}
              validationErrorMap={validationErrorMap}
            />
          </Box>
        )}
      </>
    );
  };

  return (
    <>
      <Grid item container>
        <Grid item container>
          <Grid item container xs={6} alignItems="center">
            <h2>{selectedDate.format('YYYY年M月D日')}臨時設定</h2>
          </Grid>
          <Grid
            item
            container
            xs={6}
            alignItems="center"
            justifyContent="flex-end"
          >
            {buildRightNavigationButtons()}
          </Grid>
        </Grid>
        <Grid item container>
          {buildSpecialRulesTable()}
        </Grid>
        <Grid item container css={styles.bottomButtons}>
          <Grid item container xs={6} alignItems="center"></Grid>
          <Grid
            item
            container
            xs={6}
            alignItems="center"
            justifyContent="flex-end"
          >
            {buildRightNavigationButtons()}
          </Grid>
        </Grid>
      </Grid>
      <FullscreenLoading open={openBackdrop} />
    </>
  );
}

function SlotSettingSpecialRuleTable(props: {
  workspaceUid: string;
  shopId: string;
  slotSettings: SlotSettingWithCourses[];
  specialRules: SpecialSlotSettingRule[];
  setEdittingSpecialRules: (specialRules: SpecialSlotSettingRule[]) => void;
  date: DateFormat;
  isLoadingReservationTimeTable: boolean;
  validationErrorMap: ValidationErrorMap;
}) {
  const {
    workspaceUid,
    shopId,
    slotSettings,
    specialRules,
    setEdittingSpecialRules,
    date,
    isLoadingReservationTimeTable,
    validationErrorMap,
  } = props;

  const { rulesWithSlotSettingIds, isLoading: isLoadingSlotSettingRules } =
    useSlotSettingRules(parseInt(shopId));

  const buildContents = () => {
    if (isLoadingSlotSettingRules || isLoadingReservationTimeTable) {
      return (
        <TableRow>
          <TableCell>
            <Spinner loading={true} />
          </TableCell>
        </TableRow>
      );
    }

    return slotSettings.map((slotSetting, index) => {
      const specialRule = specialRules.find(
        (r) => r.slotSettingId == slotSetting.id
      );
      const slotSettingRules =
        rulesWithSlotSettingIds.find((r) => r.slotSettingId === slotSetting.id)
          ?.rules ?? [];
      const errors = validationErrorMap[slotSetting.id] || [];
      return (
        <CSlotSettingSpecialRuleTableRow
          slotSetting={slotSetting}
          specialRules={specialRules}
          setEdittingSpecialRules={setEdittingSpecialRules}
          specialRule={specialRule}
          date={date}
          errors={errors}
          key={index}
          slotSettingRules={slotSettingRules}
        />
      );
    });
  };

  return (
    <TableContainer component={Paper}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell css={styles.courseNameTd}>コース名</TableCell>
            <TableCell css={styles.actionTd}>アクション</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>{buildContents()}</TableBody>
      </Table>
    </TableContainer>
  );
}

function deepCopy<T extends Record<string, any>>(obj: T): T {
  return JSON.parse(JSON.stringify(obj));
}

function CSlotSettingSpecialRuleTableRow(props: {
  slotSetting: SlotSettingWithCourses;
  specialRules: SpecialSlotSettingRule[];
  setEdittingSpecialRules: (specialRules: SpecialSlotSettingRule[]) => void;
  specialRule: SpecialSlotSettingRule | undefined;
  date: DateFormat;
  errors: ValidationError[];
  slotSettingRules: SlotSettingRule[];
}) {
  const {
    slotSetting,
    specialRules,
    setEdittingSpecialRules,
    specialRule,
    date,
    errors,
    slotSettingRules,
  } = props;
  const [isSpecial, setSpecial] = useState(specialRule != undefined);
  const handleChangeSpecial = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSpecial(event.target.checked);
    if (event.target.checked) {
      setNewSpecialRule();
    } else {
      const newSpecialRules = specialRules.filter(
        (sr) => sr.slotSettingId != slotSetting.id
      );
      setEdittingSpecialRules(newSpecialRules);
    }
  };

  const [editingSlotRule, setEditingSlotRule] = useState<RegularRule>();

  const createEmptySchedule = () => {
    const newSchedule: Schedule = {
      condition: {
        type: 'always',
      },
      capacity: {
        total: 1,
      },
      timeRanges: [],
    };
    return newSchedule;
  };

  const createEmptySpecialRule = () => {
    const newSchedule = createEmptySchedule();
    const newRule: RegularRule = {
      type: 'regular',
      unit: 60,
      dateRange: createDateRange(date, date),
      schedules: [newSchedule],
    };
    return newRule;
  };

  const resetRule = (rule: RegularRule) => {
    const nothingHolidayChecker: HolidayChecker = (date) => {
      return false;
    };
    const schedule =
      rule.schedules.find((schedule) => {
        return matchSchedule(
          schedule.condition,
          createDate(date),
          nothingHolidayChecker
        );
      }) || createEmptySchedule();
    schedule.condition = {
      type: 'always',
    };
    rule.schedules = [schedule];
    rule.dateRange = createDateRange(date, date);
    return rule;
  };

  const setNewSpecialRule = () => {
    const baseRule = slotSettingRules.find((s) => {
      if (s.startDate && s.endDate) {
        return dayjs(date).isBetween(s.startDate, s.endDate, null, '[]');
      } else if (s.startDate) {
        return (
          dayjs(date).isSameOrAfter(s.startDate) && s.rule.type === 'regular'
        );
      } else if (s.endDate) {
        return (
          dayjs(date).isSameOrBefore(s.endDate) && s.rule.type === 'regular'
        );
      } else {
        return s.rule.type === 'regular';
      }
    });
    const newRule = baseRule?.rule
      ? resetRule(deepCopy(baseRule.rule) as RegularRule)
      : createEmptySpecialRule();
    setEditingSlotRule(newRule);
    const newSpecialRule: SpecialSlotSettingRule = {
      id: -1,
      slotSettingId: slotSetting.id,
      date: date,
      rule: newRule,
      createdAt: '',
      updatedAt: '',
    };
    setEdittingSpecialRules([...specialRules, newSpecialRule]);
  };

  useEffect(() => {
    if (specialRule) {
      setEditingSlotRule(specialRule.rule as RegularRule);
      setSpecial(true);
    } else if (isSpecial) {
      setNewSpecialRule();
      setSpecial(false);
    }
  }, [JSON.stringify(specialRule)]);

  const handleSetSchedule = (schedule: Schedule) => {
    if (!editingSlotRule) {
      return;
    }
    const newRule = {
      ...editingSlotRule,
      schedules: [schedule],
    };
    setEditingSlotRule(newRule);
    const index = specialRules.findIndex(
      (sr) => sr.slotSettingId == slotSetting.id
    );
    const newSpecialRules = [...specialRules];
    newSpecialRules[index].rule = newRule;
    setEdittingSpecialRules(newSpecialRules);
  };

  const handleChangeUnit = (e: React.ChangeEvent<{ value: unknown }>) => {
    if (!editingSlotRule) {
      return;
    }
    const unit = e.target.value as number;
    setEditingSlotRule({ ...editingSlotRule, unit });
    const newRule = {
      ...editingSlotRule,
      unit,
    };
    const index = specialRules.findIndex(
      (sr) => sr.slotSettingId == slotSetting.id
    );
    const newSpecialRules = [...specialRules];
    newSpecialRules[index].rule = newRule;
    setEdittingSpecialRules(newSpecialRules);
  };

  return (
    <>
      <TableRow key={`main${slotSetting.id}`}>
        <TableCell
          scope="row"
          css={[
            styles.courseNameTd,
            css`
              ${isSpecial ? 'border-bottom:solid 0px #000' : ''}
            `,
          ]}
        >
          {slotSetting.courses.map((c) => c.name).join('/')}
        </TableCell>
        <TableCell
          scope="row"
          css={[
            styles.actionTd,
            css`
              ${isSpecial ? 'border-bottom:solid 0px #000' : ''}
            `,
          ]}
        >
          <Switch
            checked={isSpecial}
            onChange={handleChangeSpecial}
            name={`slotSetting-${slotSetting.id}`}
          />
          <span css={styles.switchLabel}>設定を変更する</span>
        </TableCell>
      </TableRow>
      {isSpecial && editingSlotRule ? (
        <TableRow key={`timeSetting${slotSetting.id}`}>
          <TableCell
            scope="row"
            colSpan={2}
            css={css`
              background-color: #f8f8f8;
              border-top: solid 0px #000;
            `}
          >
            <Grid container justifyContent="flex-start" alignItems="center">
              <Grid item>
                <Select
                  value={editingSlotRule.unit}
                  onChange={handleChangeUnit}
                >
                  {UNITS.map((unit) => (
                    <MenuItem value={unit} key={unit}>
                      {unit}分
                    </MenuItem>
                  ))}
                </Select>
                ごとに予約枠を設定する
              </Grid>
            </Grid>
            <TimeRangesEditor
              scheduleIndex={0}
              errors={errors}
              unit={editingSlotRule.unit}
              schedule={editingSlotRule.schedules[0]}
              setSchedule={handleSetSchedule}
            />
          </TableCell>
        </TableRow>
      ) : null}
    </>
  );
}
