export const isFQDN = (fqdn: string) => {
  return (
    /^[-a-zA-Z0-9@:%._\\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\\+.~#?&//=]*)$/.exec(
      fqdn
    ) && fqdn.length <= 253
  );
};

export const CheckStatusType = {
  CONFIRMING: 0,
  OK: 1,
  FAILURE: 2,
} as const;
export type CheckStatus = typeof CheckStatusType[keyof typeof CheckStatusType];

export type CustomDomain = {
  id: number;
  fqdn: string;
  workspaceId: number;
  ipv4Address: string;
  certificateLastUpdatedAt: string;
  checkStatus: CheckStatus;
};
