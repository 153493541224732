import axios, { AxiosResponse } from 'axios';
import { useContext, useEffect, useState } from 'react';
import { Invitation as OriginalInvitation } from '../../../frontend-api/src/handlers/public/invitation-handler';
import { Store } from '../context/GlobalStore';
import { API_END_POINT } from './api';

export type Invitation = OriginalInvitation;

interface InvitationData {
  invitation: Invitation;
}

export default function useInvitation(token: string | null) {
  const [invitation, setInvitation] = useState<Invitation>();
  const [loading, setLoading] = useState(true);
  const [isNoContent, setIsNoContent] = useState(false);

  const reload = () => {
    if (!token) {
      return;
    }
    axios
      .get(`${API_END_POINT}/public/invitation?token=${token}`)
      .then((response: AxiosResponse<InvitationData>) => {
        if (response.status === 200) {
          setInvitation(response.data.invitation);
          setIsNoContent(false);
        } else if (response.status === 204) {
          setIsNoContent(true);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    reload();
  }, [token]);
  return {
    invitation,
    isLoadingInvitation: loading,
    reloadInvitation: reload,
    isNoContent,
  };
}
