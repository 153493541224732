import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import React from 'react';
import { ShopInvitationWithEmail } from '../@interfaces/shop-invitation';
import { commonCss } from '../components/common-css';

interface Props {
  shopInvitations: ShopInvitationWithEmail[];
  onRemove: (shopInvitation: ShopInvitationWithEmail) => void;
}

export default function ShopInvitationsTable(props: Props) {
  const { shopInvitations, onRemove } = props;

  return (
    <TableContainer component={Paper}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>
              招待中の
              <br css={commonCss.forSP} />
              メンバー({shopInvitations.length})
            </TableCell>
            <TableCell>アクション</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {shopInvitations.map((shopInvitation, index) => (
            <TableRow key={shopInvitation.workspaceInvitationId}>
              <TableCell scope="row">{shopInvitation.email}</TableCell>
              <TableCell component="th">
                <Button
                  color="primary"
                  startIcon={<DeleteIcon />}
                  onClick={() => onRemove(shopInvitation)}
                >
                  店舗のメンバーから外す
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
