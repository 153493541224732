/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import {
  Breadcrumbs,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import axios, { AxiosResponse } from 'axios';
import { useSnackbar } from 'notistack';
import React, { ChangeEvent, useContext, useState } from 'react';
import useReactRouter from 'use-react-router';
import { API_END_POINT } from '../../../api/api';
import useWorkspaceAccounts, {
  WorkspaceAccount,
} from '../../../api/use-workspace-accounts';
import useWorkspaceInvitations, {
  WorkspaceInvitation,
} from '../../../api/use-workspace-invitations';
import {
  BreadcrumbLinkItem,
  BreadcrumbTextItem,
  BreadcrumbWorkspaceItem,
} from '../../../components/breadcrumb/BreadcrumbItem';
import { commonCss } from '../../../components/common-css';
import { WorkspacePageLayout } from '../../../components/layouts/WorkspacePageLayout';
import { PageTitleAndDescription } from '../../../components/PageTitleAndDescription';
import {
  FullscreenLoading,
  Head,
  Main,
  Root,
} from '../../../components/Shared';
import Spinner from '../../../components/Spinner';
import { ROLE_NORMAL, Store } from '../../../context/GlobalStore';
import { useSizeType } from '../../../hooks/use-size-type';
import { globalColors } from '../../../styles/globalColors';
import AccountsTable from '../../../tables/AccountsTable';
import { helps } from '../../../utils/helps';

type PageParams = {
  workspaceUid: string;
};

type Form = {
  email: string;
  role: number;
};

export default function WorkspaceMemberSettingPage() {
  const { globalState } = useContext(Store);
  const { match } = useReactRouter<PageParams>();
  const { workspaceUid } = match.params;
  const [form, setForm] = useState<Form>({ email: '', role: ROLE_NORMAL });
  const {
    workspaceAccounts,
    isLoadingWorkspaceAccounts,
    reloadWorkspaceAccounts,
  } = useWorkspaceAccounts(workspaceUid);
  const { invitations, isLoadingInvitations, reloadInvitations } =
    useWorkspaceInvitations(workspaceUid);
  const [openFullscreenLoading, setOpenFullscreenLoading] = useState(false);
  const [openInvitation, setOpenInvitation] = useState(false);
  const snackbar = useSnackbar();
  const { isSpSize } = useSizeType();

  const handleInvite = () => {
    const json = {
      email: form.email,
      role: form.role,
    };
    setOpenFullscreenLoading(true);
    axios
      .post(`${API_END_POINT}/app/ws/${workspaceUid}/invitations`, json, {
        headers: {
          Authorization: globalState.session?.idToken,
        },
      })
      .then((response: AxiosResponse) => {
        const { invitationType } = response.data;
        setForm({ ...form, email: '' });
        if (invitationType === 'new_account') {
          snackbar.enqueueSnackbar(
            `${form.email}はまだアカウントがありません。招待メールを送信しました。`,
            {
              variant: 'success',
            }
          );
          reloadInvitations();
        } else if (invitationType === 'exist_account') {
          snackbar.enqueueSnackbar(
            `${form.email}のアカウントが見つかりました。ワークスペースに追加しました。`,
            {
              variant: 'success',
            }
          );
          reloadWorkspaceAccounts();
        }
      })
      .finally(() => {
        setOpenFullscreenLoading(false);
      });
  };

  const handleRemoveAccount = (account: WorkspaceAccount): void => {
    if (
      !window.confirm(
        `${account.name}をワークスペースから削除してもよろしいですか？`
      )
    ) {
      return;
    }
    setOpenFullscreenLoading(true);
    axios
      .delete(
        `${API_END_POINT}/app/ws/${workspaceUid}/accounts/${account.id}`,
        {
          headers: {
            Authorization: globalState.session?.idToken,
          },
        }
      )
      .then(() => {
        snackbar.enqueueSnackbar('ワークスペースから削除しました。', {
          variant: 'success',
        });
        reloadWorkspaceAccounts();
      })
      .finally(() => {
        setOpenFullscreenLoading(false);
      });
  };

  const handleRemoveInvitation = (invitation: WorkspaceInvitation): void => {
    if (
      !window.confirm(`${invitation.email}の招待を削除してもよろしいですか？`)
    ) {
      return;
    }
    setOpenFullscreenLoading(true);
    axios
      .delete(`${API_END_POINT}/app/invitations/${invitation.id}`, {
        headers: {
          Authorization: globalState.session?.idToken,
        },
      })
      .then(() => {
        reloadInvitations();
      })
      .finally(() => {
        setOpenFullscreenLoading(false);
      });
  };

  const handleResendInvitation = (invitation: WorkspaceInvitation): void => {
    setOpenFullscreenLoading(true);
    axios
      .post(
        `${API_END_POINT}/app/invitations/${invitation.id}/resend`,
        {},
        {
          headers: {
            Authorization: globalState.session?.idToken,
          },
        }
      )
      .then(() => {
        snackbar.enqueueSnackbar('招待メールを再送しました。', {
          variant: 'success',
        });
        reloadInvitations();
      })
      .finally(() => {
        setOpenFullscreenLoading(false);
      });
  };

  const handleChangeAccountRole = (
    account: WorkspaceAccount,
    role: number
  ): void => {
    if (account.role === role) {
      return;
    }
    const form = {
      role,
    };
    setOpenFullscreenLoading(true);
    axios
      .put(
        `${API_END_POINT}/app/ws/${workspaceUid}/accounts/${account.id}`,
        form,
        {
          headers: {
            Authorization: globalState.session?.idToken,
          },
        }
      )
      .then(() => {
        snackbar.enqueueSnackbar('権限を変更しました。', {
          variant: 'success',
        });
        reloadWorkspaceAccounts();
      })
      .finally(() => {
        setOpenFullscreenLoading(false);
      });
  };

  const handleChangeEmail = (e: ChangeEvent<HTMLInputElement>) => {
    const email = e.target.value;
    setForm({ ...form, email });
  };

  const handleChangeRole = (
    e: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    const role = e.target.value as number;
    setForm({ ...form, role });
  };

  const handleClickCloseInvitation = () => {
    setOpenInvitation(false);
  };

  const handleClickOpenInvitation = () => {
    setOpenInvitation(true);
  };

  const validate = () => {
    return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.exec(
      form.email.toLowerCase()
    );
  };

  const buildInvitation = () => {
    return (
      <>
        <Alert severity="info">
          ワークスペースのメンバーを招待します。
          <br />
          管理者権限の場合、すべての店舗が閲覧可能です。
          <br />
          一般ユーザー権限の場合、サインアップ後に店舗メンバーとして追加することで追加した店舗が閲覧可能になります。
        </Alert>
        <Paper style={{ padding: '20px', marginBottom: '20px' }}>
          <TextField
            type="email"
            value={form.email}
            variant="standard"
            label="メールアドレス"
            fullWidth
            onChange={handleChangeEmail}
            autoFocus
            placeholder="xxx@example.com"
          />
          <FormControl style={{ marginTop: '16px' }}>
            <InputLabel id="assignee-account-id">権限</InputLabel>
            <Select
              value={form.role}
              label="権限"
              style={{ width: '200px' }}
              onChange={handleChangeRole}
            >
              <MenuItem value={0}>管理者</MenuItem>
              <MenuItem value={1}>一般ユーザー</MenuItem>
            </Select>
          </FormControl>
          <Grid item container xs={12}>
            <Grid item container xs={6}></Grid>
            <Grid
              item
              container
              xs={6}
              alignContent="center"
              justifyContent="flex-end"
            >
              <Button
                css={commonCss.button.right}
                type="button"
                variant="text"
                color="primary"
                onClick={handleClickCloseInvitation}
              >
                キャンセル
              </Button>
              <Button
                type="button"
                variant="contained"
                color="primary"
                onClick={handleInvite}
                disabled={!validate()}
              >
                ワークスペースに招待
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </>
    );
  };

  const buildContents = () => {
    return (
      <>
        <Grid
          item
          container
          xs={12}
          css={css`
            margin: 24px auto;
          `}
        >
          <Grid item container xs={6}>
            <h3>
              メンバー
              {isLoadingWorkspaceAccounts
                ? null
                : `(${workspaceAccounts.length})`}
            </h3>
          </Grid>
          <Grid
            item
            container
            xs={6}
            alignContent="center"
            justifyContent="flex-end"
          >
            {openInvitation ? null : (
              <Button
                css={commonCss.button.right}
                type="button"
                variant="contained"
                color="primary"
                onClick={handleClickOpenInvitation}
              >
                新規招待
              </Button>
            )}
          </Grid>
        </Grid>
        {openInvitation ? buildInvitation() : null}
        {isLoadingWorkspaceAccounts || isLoadingInvitations ? (
          <Spinner loading={true} />
        ) : (
          <AccountsTable
            workspaceUid={workspaceUid}
            accounts={workspaceAccounts}
            onRemoveAccount={handleRemoveAccount}
            invitations={invitations}
            onRemoveInvitation={handleRemoveInvitation}
            onResendInvitation={handleResendInvitation}
            onChangeAccountRole={handleChangeAccountRole}
            isSpSize={isSpSize}
          />
        )}
      </>
    );
  };

  const pageTitle = 'メンバー設定';

  return (
    <Root>
      <Head title={pageTitle} />
      <Main>
        <WorkspacePageLayout
          workspaceUid={workspaceUid}
          current="member"
          breadcrumbs={
            <Breadcrumbs aria-label="breadcrumb">
              <BreadcrumbLinkItem to={`/`}>ホーム</BreadcrumbLinkItem>
              <BreadcrumbWorkspaceItem workspaceUid={workspaceUid} />
              <BreadcrumbTextItem>{pageTitle}</BreadcrumbTextItem>
            </Breadcrumbs>
          }
          helpId={helps.workspace.setting.member}
        >
          <PageTitleAndDescription
            title="ワークスペース"
            subTitle={pageTitle}
            description="予約を管理する店舗スタッフの設定を行います。"
            themeColor={globalColors.workspace}
          />
          {buildContents()}
        </WorkspacePageLayout>
      </Main>
      <FullscreenLoading open={openFullscreenLoading} />
    </Root>
  );
}
