/** @jsxImportSource @emotion/react */

import React from 'react';
import { FieldValues, UseFormMethods } from 'react-hook-form';
import { SelectedCustomer } from '../../../@interfaces/customer';
import { StoredReservation } from '../../../api/use-reservation';
import useWorkspaceSetting from '../../../api/use-workspace-setting';
import {
  AnyField,
  CheckboxField,
  Field,
  FieldResponse,
  FieldResponseValue,
  FormResponse,
  FormSetting,
  RadioField,
} from '../../../core/types/reservation-form-types';
import { CustomerInfoContainer } from './CustomerInfoContainer';
import { CheckboxFormField } from './input/CheckboxFormField';
import { EmailFormField } from './input/EmailFormField';
import { NameFormField } from './input/NameFormField';
import { NumberFormField } from './input/NumberFormField';
import { RadioFormField } from './input/RadioFormField';
import { TelFormField } from './input/TelFormField';
import { TextareaFormField } from './input/TextareaFormField';
import { TextFormField } from './input/TextFormField';

type FormFieldsProps = {
  workspaceUid: string;
  shopId: string;
  reservation: StoredReservation | undefined;
  formSetting: FormSetting;
  formResponse: FormResponse;
  validationContext: UseFormMethods<FieldValues>;
  onChangeValue?: (field: Field, newValues: FieldResponseValue[]) => void;
  selectedCustomer: SelectedCustomer | undefined;
  onChangeSelectedCustomer: (customer: SelectedCustomer | undefined) => void;
};
export const FormFields = (props: FormFieldsProps) => {
  const {
    workspaceUid,
    shopId,
    reservation,
    formSetting,
    formResponse,
    validationContext,
    onChangeValue,
    selectedCustomer,
    onChangeSelectedCustomer,
  } = props;
  const { workspaceSetting, isLoadingWorkspaceSetting } =
    useWorkspaceSetting(workspaceUid);

  const buildFiled = (
    field: AnyField,
    onChangeValueFlag: boolean,
    deletedField: AnyField | undefined
  ) => {
    const fieldResponse: FieldResponse = formResponse.fields.find((value) => {
      return value.uid == field.uid;
    }) || {
      uid: field.uid,
      values: [],
    };

    if (field.type == 'text') {
      return (
        <TextFormField
          key={field.uid}
          field={field}
          fieldResponse={fieldResponse}
          onChangeValue={onChangeValueFlag ? onChangeValue : undefined}
          validationContext={validationContext}
        />
      );
    } else if (field.type == 'textarea') {
      return (
        <TextareaFormField
          key={field.uid}
          field={field}
          fieldResponse={fieldResponse}
          onChangeValue={onChangeValueFlag ? onChangeValue : undefined}
          validationContext={validationContext}
        />
      );
    } else if (field.type == 'radio') {
      return (
        <RadioFormField
          key={field.uid}
          field={field}
          deletedField={deletedField as RadioField | undefined}
          fieldResponse={fieldResponse}
          onChangeValue={onChangeValueFlag ? onChangeValue : undefined}
          validationContext={validationContext}
        />
      );
    } else if (field.type == 'checkbox') {
      return (
        <CheckboxFormField
          key={field.uid}
          field={field}
          deletedField={deletedField as CheckboxField | undefined}
          fieldResponse={fieldResponse}
          onChangeValue={onChangeValueFlag ? onChangeValue : undefined}
          validationContext={validationContext}
        />
      );
    } else if (field.type == 'tel') {
      return (
        <TelFormField
          key={field.uid}
          field={field}
          fieldResponse={fieldResponse}
          onChangeValue={onChangeValueFlag ? onChangeValue : undefined}
          validationContext={validationContext}
        />
      );
    } else if (field.type == 'email') {
      return (
        <EmailFormField
          key={field.uid}
          field={field}
          fieldResponse={fieldResponse}
          onChangeValue={onChangeValueFlag ? onChangeValue : undefined}
          validationContext={validationContext}
        />
      );
    } else if (field.type == 'name') {
      return (
        <NameFormField
          key={field.uid}
          field={field}
          fieldResponse={fieldResponse}
          onChangeValue={onChangeValueFlag ? onChangeValue : undefined}
          validationContext={validationContext}
        />
      );
    } else if (field.type == 'number') {
      return (
        <NumberFormField
          key={field.uid}
          field={field}
          fieldResponse={fieldResponse}
          onChangeValue={onChangeValueFlag ? onChangeValue : undefined}
          validationContext={validationContext}
        />
      );
    }
  };

  const fields = formSetting.fields
    .filter((field) => {
      // 顧客管理onの場合は個人情報のフィールドは表示しない
      return workspaceSetting?.enableCrm
        ? !['name', 'tel', 'email'].includes(field.type)
        : true;
    })
    .map((field) => {
      const deletedField = formSetting.deletedFields?.find(
        (f) => f.uid === field.uid
      );
      return buildFiled(field, true, deletedField);
    });

  const deletedFields = formSetting.deletedFields
    ?.map((field) => {
      const formSettingField = formSetting.fields?.find(
        (f) => f.uid === field.uid
      );
      const formResponseField = formResponse.fields?.find(
        (f) => f.uid === field.uid
      );
      if (!formSettingField && formResponseField) {
        return (
          <>
            {formSetting.deletedFields?.length &&
              '次の項目はコースから削除されているため編集できません。'}
            {buildFiled(field, false, undefined)}
          </>
        );
      } else {
        return null;
      }
    })
    .filter(Boolean);

  const showCustomerInfo =
    !isLoadingWorkspaceSetting && (workspaceSetting?.enableCrm || false);

  return (
    <>
      {showCustomerInfo ? (
        <CustomerInfoContainer
          workspaceUid={workspaceUid}
          shopId={shopId}
          selectedCustomer={selectedCustomer}
          formSetting={formSetting}
          formResponse={formResponse}
          onChangeSelectedCustomer={onChangeSelectedCustomer}
        />
      ) : null}
      {fields}
      {deletedFields}
    </>
  );
};
