/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import {
  ConversionAggregation,
  WeekdayConversionAggregation,
} from '../../../@interfaces/insight/backend';
import {
  calcConversionPercent,
  styles,
  weekdayLabels,
} from '../utils/insight-utils';

type ConversionWeekdayTableProps = {
  results: WeekdayConversionAggregation[];
  totalAggregation: ConversionAggregation;
};

export const ConversionWeekdayTable = ({
  results,
  totalAggregation,
}: ConversionWeekdayTableProps): JSX.Element => {
  const buildTotalRow = () => {
    return (
      <TableRow
        css={css`
          background-color: #f5f5f5;
        `}
      >
        <TableCell
          scope="row"
          css={css`
            font-weight: bold;
          `}
        >
          合計
        </TableCell>
        <TableCell
          align="center"
          scope="row"
          css={css`
            font-weight: bold;
          `}
        >
          <div css={styles.tableNumberCell}>
            {totalAggregation.courseSelectViewedTotal.toLocaleString()}
          </div>
        </TableCell>
        <TableCell
          align="center"
          scope="row"
          css={css`
            font-weight: bold;
          `}
        >
          <div css={styles.tableNumberCell}>
            {totalAggregation.dateSelectViewedTotal.toLocaleString()}
          </div>
        </TableCell>
        <TableCell
          align="center"
          scope="row"
          css={css`
            font-weight: bold;
          `}
        >
          <div css={styles.tableNumberCell}>
            {totalAggregation.formViewedTotal.toLocaleString()}
          </div>
        </TableCell>
        <TableCell
          align="center"
          scope="row"
          css={css`
            font-weight: bold;
          `}
        >
          <div css={styles.tableNumberCell}>
            {totalAggregation.reservedTotal.toLocaleString()}
          </div>
        </TableCell>
        <TableCell
          align="center"
          scope="row"
          css={css`
            font-weight: bold;
          `}
        >
          <div css={styles.tableNumberCell}>
            {
              calcConversionPercent(
                totalAggregation.reservedTotal,
                totalAggregation.courseSelectViewedTotal
              ).displayValue
            }
          </div>
        </TableCell>
      </TableRow>
    );
  };

  const buildTableRow = (row: WeekdayConversionAggregation, index: number) => {
    return (
      <TableRow key={row.weekdayIndex}>
        <TableCell scope="row">
          <div>{weekdayLabels[row.weekdayIndex]}</div>
        </TableCell>
        <TableCell align="center" scope="row">
          <div css={styles.tableNumberCell}>
            {row.courseSelectViewedTotal.toLocaleString()}
          </div>
        </TableCell>
        <TableCell align="center" scope="row">
          <div css={styles.tableNumberCell}>
            {row.dateSelectViewedTotal.toLocaleString()}
          </div>
        </TableCell>
        <TableCell align="center" scope="row">
          <div css={styles.tableNumberCell}>
            {row.formViewedTotal.toLocaleString()}
          </div>
        </TableCell>
        <TableCell align="center" scope="row">
          <div css={styles.tableNumberCell}>
            {row.reservedTotal.toLocaleString()}
          </div>
        </TableCell>
        <TableCell align="center" scope="row">
          <div css={styles.tableNumberCell}>
            {
              calcConversionPercent(
                row.reservedTotal,
                row.courseSelectViewedTotal
              ).displayValue
            }
          </div>
        </TableCell>
      </TableRow>
    );
  };

  return (
    <TableContainer
      css={css`
        margin-top: 3em;
      `}
      component={Paper}
    >
      <Table size="small" aria-label="a dense table">
        <TableHead
          css={css`
            background-color: black;
          `}
        >
          <TableRow>
            <TableCell css={styles.tableHeader(6)} align="center">
              曜日/項目
            </TableCell>
            <TableCell css={styles.tableHeader(6)} align="center">
              コース選択ページ閲覧数
            </TableCell>
            <TableCell css={styles.tableHeader(6)} align="center">
              日時選択ページ閲覧数
            </TableCell>
            <TableCell css={styles.tableHeader(6)} align="center">
              フォーム閲覧数
            </TableCell>
            <TableCell css={styles.tableHeader(6)} align="center">
              予約完了数
            </TableCell>
            <TableCell css={styles.tableHeader(6)} align="center">
              予約完了率
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {buildTotalRow()}
          {results.map(buildTableRow)}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
