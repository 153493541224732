import axios, { AxiosResponse } from 'axios';
import { useContext } from 'react';
import useSWR from 'swr';
import { GetCustomersCountResponse } from '../../../@interfaces/crm/api';
import { API_END_POINT } from '../../../api/api';
import { Store } from '../../../context/GlobalStore';

const fetcher = async (
  token: string,
  shopId: number,
  keywords: string[],
  includesDeleted?: boolean
) =>
  axios
    .get(`${API_END_POINT}/app/shops/${shopId}/customers-count`, {
      headers: {
        Authorization: token,
      },
      params: {
        keywords,
        includesDeleted: (() => {
          if (includesDeleted === undefined) {
            return includesDeleted;
          }
          return includesDeleted ? '1' : '0';
        })(),
      },
    })
    .then((r: AxiosResponse<GetCustomersCountResponse>) => r.data);

export const useShopCustomersCount = (
  shopId: number,
  keywords: string[],
  includesDeleted?: boolean
) => {
  const { globalState } = useContext(Store);
  const { data, isLoading } = useSWR(
    globalState.session?.idToken
      ? [globalState.session.idToken, shopId, keywords, includesDeleted]
      : null,
    async ([token, shopId, keywords, includesDeleted]) =>
      fetcher(token, shopId, keywords, includesDeleted)
  );

  return {
    data,
    isLoading,
  };
};
