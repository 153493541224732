import {
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  MenuItem,
  Switch,
  TextField,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { ChangeEvent } from 'react';
import { ShopCourseSetting } from '../../../../@interfaces/course-slot-setting';
import { ResourceGroupWithMember } from '../../../../@interfaces/resource/resource-group';
import { FormSetting } from '../../../../core/types/reservation-form-types';
import {
  SerialResourceConstant,
  SerialResourceTarget,
} from '../../../../core/types/reservation-resource-types';
import { randomString } from '../../../../utils/string';
import { createDefaultSerialResourceTarget } from '../../models/resource-constant';
import { SelectAssignmentRule } from './SelectAssignmentRule';
import { SelectOptionalFields } from './SelectOptionalFields';

type SerialResourceConstantEditorProps = {
  constant: SerialResourceConstant;
  resourceGroups: ResourceGroupWithMember[];
  shopCourseSetting: ShopCourseSetting;
  formSetting: FormSetting;
  onUpdate: () => void;
};

export const SerialResourceConstantEditor: React.VFC<
  SerialResourceConstantEditorProps
> = (props) => {
  const { constant, resourceGroups, shopCourseSetting, formSetting, onUpdate } =
    props;
  console.log({ constant });

  const handleClickAddResourceGroup = () => {
    const target = createDefaultSerialResourceTarget();
    constant.targets.push(target);
    onUpdate();
  };

  const handleUpdateTarget = (target: SerialResourceTarget) => {
    onUpdate();
  };

  const handleDeleteTarget = (target: SerialResourceTarget) => {
    constant.targets = constant.targets.filter((t) => t !== target);
    onUpdate();
  };

  const handleChangeOrderRule = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (constant.orderRule === 'any') {
      constant.orderRule = 'ordered';
      onUpdate();
    } else {
      constant.orderRule = 'any';
      onUpdate();
    }
  };

  return (
    <Grid container item>
      <Grid item xs={12}>
        <FormControl component="fieldset">
          <FormControlLabel
            control={
              <Switch
                checked={constant.orderRule === 'any'}
                onChange={handleChangeOrderRule}
                color="primary"
              />
            }
            label="順番を変更して割当可能"
          />
        </FormControl>
      </Grid>
      {constant.targets.map((target) => {
        return (
          <TargetEditor
            target={target}
            resourceGroups={resourceGroups}
            shopCourseSetting={shopCourseSetting}
            formSetting={formSetting}
            onUpdateTarget={handleUpdateTarget}
            onDeleteTarget={handleDeleteTarget}
          />
        );
      })}
      <Button color="primary" onClick={handleClickAddResourceGroup}>
        割り当てるリソースグループを追加
      </Button>
    </Grid>
  );
};

type TargetEditorProps = {
  target: SerialResourceTarget;
  resourceGroups: ResourceGroupWithMember[];
  shopCourseSetting: ShopCourseSetting;
  formSetting: FormSetting;
  onUpdateTarget: (target: SerialResourceTarget) => void;
  onDeleteTarget: (target: SerialResourceTarget) => void;
};

const TargetEditor: React.VFC<TargetEditorProps> = (props) => {
  const {
    target,
    resourceGroups,
    shopCourseSetting,
    formSetting,
    onUpdateTarget,
    onDeleteTarget,
  } = props;

  const handleChangeResourceGroupId = (
    event: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    const resourceGroupId = event.target.value as number;
    target.resourceGroupId = resourceGroupId;
    onUpdateTarget(target);
  };

  const handleChangeMinutesRequired = (e: ChangeEvent<HTMLInputElement>) => {
    const minutesRequired = e.target.value ? parseInt(e.target.value) : 0;
    target.minutesRequired = minutesRequired;
    onUpdateTarget(target);
  };

  const handleChangeAssignmentRule = () => {
    onUpdateTarget(target);
  };

  const handleChangeOptionalFields = () => {
    onUpdateTarget(target);
  };

  const handleDeleteTarget = () => {
    onDeleteTarget(target);
  };

  return (
    <>
      <Grid container item xs={12} alignItems="flex-end">
        <IconButton onClick={handleDeleteTarget} size="small">
          <DeleteIcon />
        </IconButton>
        <TextField
          select
          label="リソースグループ"
          value={target.resourceGroupId}
          onChange={handleChangeResourceGroupId}
          style={{ minWidth: '200px' }}
        >
          {resourceGroups.map((resourceGroup) => (
            <MenuItem key={resourceGroup.id} value={resourceGroup.id}>
              {resourceGroup.name}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          label="使用時間（分）"
          type="number"
          value={target.minutesRequired}
          onChange={handleChangeMinutesRequired}
        />
        <SelectAssignmentRule
          target={target}
          onUpdate={handleChangeAssignmentRule}
        />
      </Grid>
      <Grid
        container
        item
        xs={12}
        alignItems="flex-end"
        style={{ marginBottom: '20px' }}
      >
        <SelectOptionalFields
          target={target}
          shopCourseSetting={shopCourseSetting}
          formSetting={formSetting}
          onUpdate={handleChangeOptionalFields}
        />
      </Grid>
    </>
  );
};
