/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@material-ui/core';
import React, { useState } from 'react';
import { commonCss } from './common-css';

export const labelStyles = {
  label: css({
    display: 'inline-block',
    background: '#ddd',
    color: '#fff',
    fontSize: '9px',
    borderRadius: '5px 5px',
    padding: '5px 10px',
    marginRight: '5px',
    textAlign: 'center',
    minWidth: '50px',
    marginTop: '2px',
    marginBottom: '2px',
  }),
  vertical: css({
    marginBottom: '5px',
  }),
  chooserHeader: css({
    padding: '5px 0px',
    backgroundColor: '#eee',
    borderBottom: 'solid 1px #ddd',
  }),
  chooserHeaderLabel: css({
    marginLeft: '15px',
  }),
};

type Form = {
  note: string;
};

export default function LabelChooser(props: {
  note: string;
  onChangeNote: (newNote: string) => void;
  onClose: () => void;
  button?: string | JSX.Element;
}) {
  const { note, onChangeNote, onClose, button } = props;
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [form, setForm] = useState<Form>({ note });

  const handleClickEdit = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    onClose();
  };

  const handleSave = () => {
    setAnchorEl(null);
    onChangeNote(form.note);
  };

  const handleChangeNote = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newNote = event.target.value;
    setForm({ ...form, note: newNote });
  };

  const open = Boolean(anchorEl);

  const buildDialog = () => {
    return (
      <Dialog open={open} fullWidth onClose={handleClose}>
        <DialogTitle>予約メモの編集</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            label="メモ"
            value={form.note}
            onChange={handleChangeNote}
            fullWidth
            minRows={5}
            maxRows={5}
            multiline
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            キャンセル
          </Button>
          <Button onClick={handleSave} color="primary">
            保存
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <>
      <Button
        color="primary"
        variant="text"
        size="small"
        onClick={handleClickEdit}
        css={[commonCss.noPrint]}
      >
        {button || '編集'}
      </Button>
      {open ? buildDialog() : null}
    </>
  );
}
