import { Button, IconButton, MenuItem, TextField } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import { useMemo } from 'react';
import { ShopCourseSetting } from '../../../../@interfaces/course-slot-setting';
import {
  AnyField,
  FormSetting,
} from '../../../../core/types/reservation-form-types';
import { OptionalFormField } from '../../../../core/types/reservation-resource-types';

type SelectOptionalFieldsProps = {
  target: {
    optionalFormFields?: OptionalFormField[];
  };
  shopCourseSetting: ShopCourseSetting;
  formSetting: FormSetting;
  onUpdate: () => void;
};

export const SelectOptionalFields: React.VFC<SelectOptionalFieldsProps> = (
  props
) => {
  const {
    target: { optionalFormFields },
    shopCourseSetting,
    formSetting,
    onUpdate,
  } = props;
  console.log({ optionalFormFields });

  const optionalFields = useMemo(() => {
    return formSetting.fields.filter(
      (field) => field.type === 'checkbox' || field.type === 'radio'
    );
  }, [formSetting]);

  const handleClickAdd = () => {
    optionalFormFields?.push({
      fieldUid: '',
    });
    onUpdate();
  };

  const fields = optionalFormFields?.map((optionalFormField, index) => {
    const formField = formSetting.fields.find(
      (f) => f.uid === optionalFormField.fieldUid
    );
    const onDelete = () => {
      optionalFormFields.splice(index, 1);
      onUpdate();
    };
    return (
      <SelectOptionalField
        key={formField?.uid}
        optionalFormField={optionalFormField}
        optionalFields={optionalFields}
        formField={formField}
        onUpdate={onUpdate}
        onDelete={onDelete}
      />
    );
  });
  return (
    <>
      <div style={{ width: '30px' }}></div>
      {fields}
      <Button color="primary" variant="text" onClick={handleClickAdd}>
        オプションの所要時間を加算
      </Button>
    </>
  );
};

type SelectOptionalFieldProps = {
  optionalFormField: OptionalFormField;
  formField: AnyField | undefined;
  optionalFields: AnyField[];
  onUpdate: () => void;
  onDelete: () => void;
};

export const SelectOptionalField: React.VFC<SelectOptionalFieldProps> = (
  props
) => {
  const { optionalFormField, formField, optionalFields, onUpdate, onDelete } =
    props;

  const handleChangeResourceGroupId = (
    event: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    const fieldUid = event.target.value as string;
    optionalFormField.fieldUid = fieldUid;
    onUpdate();
  };

  const handleClickDelete = () => {
    onDelete();
  };

  return (
    <>
      <TextField
        select
        label="追加所要時間"
        value={optionalFormField.fieldUid}
        onChange={handleChangeResourceGroupId}
        style={{ minWidth: '200px' }}
      >
        {optionalFields.map((optionalField) => (
          <MenuItem key={optionalField.uid} value={optionalField.uid}>
            {optionalField.name}
          </MenuItem>
        ))}
      </TextField>
      <IconButton onClick={handleClickDelete} size="small">
        <CancelIcon />
      </IconButton>
    </>
  );
};
