import axios, { AxiosResponse } from 'axios';
import dayjs from 'dayjs';
import { useContext, useEffect, useState } from 'react';
import { ResponseBody } from '../../../frontend-api/src/handlers/reservation/reservation-time-table-handler';
import { CourseReservationTable } from '../@interfaces/reservation';
import { Store } from '../context/GlobalStore';
import {
  ResourceResponse,
  SelectedOptionalField,
} from '../core/types/reservation-resource-types';
import {
  DateRange,
  Time,
  toDate,
  toDateStringByDate,
} from '../core/types/reservation-types';
import { concurrentPromise } from '../utils/concurrent';
import { API_END_POINT } from './api';

type CacheKey = `${string}`;

type ReservedTotalByDate = {
  [date: string]: number;
};

export type ReservationTimeTable = {
  tables: CourseReservationTable[];
  times: Time[];
  reservedTotalByDate: ReservedTotalByDate;
};

const createCacheKey = (
  shopId: string,
  startDate: string,
  endDate: string
): CacheKey => {
  return `${shopId}/${startDate}/${endDate}`;
};
const cache: { [cacheKey: string]: ReservationTimeTable } = {};

export default function useReservationTimeTable(
  shopId: string,
  startDate: string,
  endDate: string,
  excludeReservationId?: number,
  forceMinutesReqired?: number,
  forceMinutesReqiredCourseId?: number,
  resourceResponse?: ResourceResponse,
  selectedOptionalFields?: SelectedOptionalField[]
) {
  const { globalState, setGlobalState } = useContext(Store);
  const cacheKey = createCacheKey(shopId, startDate, endDate);
  const [reservationTimeTable, setReservationTimeTable] = useState<
    ReservationTimeTable | undefined
  >(cache[cacheKey]);
  const [loading, setLoading] = useState(cache[cacheKey] == undefined);

  const reload = (implicitLoading = false) => {
    if (!globalState.session?.idToken) {
      return;
    }
    if (implicitLoading == false) {
      setLoading(true);
      setReservationTimeTable(undefined);
    }
    const cacheKey = createCacheKey(shopId, startDate, endDate);
    if (cache[cacheKey]) {
      setReservationTimeTable(cache[cacheKey]);
    }
    const dateRanges = chunkDates(startDate, endDate, 7);
    const requests = dateRanges.map((dataRange) => async () => {
      const params = {
        startDate: toDateStringByDate(dataRange.start),
        endDate: toDateStringByDate(dataRange.end),
        excludeReservationId,
        forceMinutesReqired,
        forceMinutesReqiredCourseId,
        resourceResponseJson: JSON.stringify(resourceResponse),
        selectedOptionalFieldsJson: JSON.stringify(selectedOptionalFields),
      };
      return axios.get<ResponseBody>(
        `${API_END_POINT}/app/shops/${shopId}/reservation-time-table`,
        {
          params,
          headers: {
            Authorization: globalState.session?.idToken,
          },
        }
      );
    });
    concurrentPromise(requests, 5)
      .then((responses) => {
        const timeTable = margeResponses(responses);
        setReservationTimeTable(timeTable);
        cache[cacheKey] = timeTable;
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    reload();
  }, [
    shopId,
    startDate,
    endDate,
    excludeReservationId,
    forceMinutesReqired,
    forceMinutesReqiredCourseId,
    JSON.stringify(resourceResponse),
    JSON.stringify(selectedOptionalFields),
    globalState.session?.idToken,
  ]);
  return {
    reservationTimeTable: reservationTimeTable,
    isLoadingReservationTimeTable: loading,
    reloadReservationTimeTable: reload,
  };
}

const margeResponses = (
  responses: AxiosResponse<ResponseBody>[]
): ReservationTimeTable => {
  const datas = responses.map((response) => response.data);
  if (datas.length === 1) {
    return datas[0];
  }
  // 分割されたデータは結合して返す
  const timeTable: ReservationTimeTable = {
    tables: [],
    times: [],
    reservedTotalByDate: {},
  };
  for (const data of datas) {
    timeTable.tables = [...timeTable.tables, ...data.tables];
    timeTable.times = [...timeTable.times, ...data.times];
    timeTable.reservedTotalByDate = {
      ...timeTable.reservedTotalByDate,
      ...data.reservedTotalByDate,
    };
  }
  // timeTable.timesの重複を削除してソート
  timeTable.times.sort((a, b) => {
    if (a.hour === b.hour) {
      return a.minute - b.minute;
    }
    return a.hour - b.hour;
  });
  timeTable.times = timeTable.times.filter((time, index, self) => {
    return (
      self.findIndex(
        (t) => time.hour === t.hour && time.minute === t.minute
      ) === index
    );
  });

  // timeTable.tablesをコースID+日付でソートする
  timeTable.tables.sort((a, b) => {
    if (a.course.id === b.course.id) {
      return (
        a.dateRange.start.year * 10000 +
        a.dateRange.start.month * 100 +
        a.dateRange.start.date -
        (b.dateRange.start.year * 10000 +
          b.dateRange.start.month * 100 +
          b.dateRange.start.date)
      );
    } else {
      return a.course.id - b.course.id;
    }
  });
  // timeTable.tablesの同一のコースがあれば統合する
  const tables: CourseReservationTable[] = [];
  for (const table of timeTable.tables) {
    const courseTable = tables.find((t) => t.course.id === table.course.id);
    if (courseTable) {
      courseTable.dates = [...courseTable.dates, ...table.dates];
      courseTable.dateRange = {
        start: courseTable.dateRange.start,
        end: table.dateRange.end,
      };
    } else {
      tables.push(table);
    }
  }
  timeTable.tables = tables;
  return timeTable;
};

/**
 * 開始日から終了日までの日付を指定した日数ごとに分割する
 * @param startDate 開始日
 * @param endDate 終了日
 * @param days  分割する日数
 */
const chunkDates = (
  startDate: string,
  endDate: string,
  days: number
): DateRange[] => {
  const start = dayjs(startDate);
  const end = dayjs(endDate);
  const diff = end.diff(start, 'day');
  const dateRanges: DateRange[] = [];
  for (let i = 0; i <= diff; i += days) {
    const s = dayjs(startDate).add(i, 'day');
    let e = dayjs(startDate).add(i + days - 1, 'day');
    if (e.isAfter(end)) {
      e = end;
    }
    dateRanges.push({
      start: toDate(s),
      end: toDate(e),
    });
  }
  return dateRanges;
};
