import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(
  <React.StrictMode>
    <HelmetProvider>
      {window.location.hostname !== 'localhost' && (
        <Helmet>
          <script
            async
            src="https://www.googletagmanager.com/gtag/js?id=GTM-MWQR835"
          ></script>
          <script>
            {`
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'GTM-MWQR835');
    `}
          </script>
        </Helmet>
      )}
      <App />
    </HelmetProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
