export type ReservationActivityDiffItem = {
  name: string;
  old: string | null;
  new: string | null;
};
export type ReservationActivityDiff = {
  diffs: ReservationActivityDiffItem[];
};
export type EmailSentActivityJson = {
  type: 'reserved' | 'modified' | 'canceled' | 'reminder' | 'custom';
  toAddress: string;
};
export type LineSentActivityJson = {
  type: 'reserved' | 'modified' | 'canceled' | 'reminder' | 'custom';
  name: string;
};

export const OperationSource = {
  user: 1,
  customer: 2,
  system: 3,
} as const;

export const CUSTOMER_OPERATOR_NAME = '予約者';
export const SYSTEM_NAME = 'システム';

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type OperationSource =
  typeof OperationSource[keyof typeof OperationSource];

export const ActivityType = {
  reservationAdded: 1,
  reservationModified: 2,
  reservationCanceled: 3,
  reservationRestored: 4,
  emailSent: 5,
  lineSent: 6,
} as const;

export type NewReservationActivityBase = {
  reservationId: number;
  operationSource: OperationSource;
  operator: string;
};

export type AddedActivity = NewReservationActivityBase & {
  type: typeof ActivityType.reservationAdded;
  detail: null;
};

export type ModifiedActivity = NewReservationActivityBase & {
  type: typeof ActivityType.reservationModified;
  detail: ReservationActivityDiff;
};

export type CanceledActivity = NewReservationActivityBase & {
  type: typeof ActivityType.reservationCanceled;
  detail: null;
};

export type RestoredActivity = NewReservationActivityBase & {
  type: typeof ActivityType.reservationRestored;
  detail: null;
};

export type MailSentActivity = NewReservationActivityBase & {
  type: typeof ActivityType.emailSent;
  detail: EmailSentActivityJson;
};

export type LineSentActivity = NewReservationActivityBase & {
  type: typeof ActivityType.lineSent;
  detail: LineSentActivityJson;
};

export type NewReservationActivity =
  | AddedActivity
  | ModifiedActivity
  | CanceledActivity
  | RestoredActivity
  | MailSentActivity
  | LineSentActivity;

export type ReservationActivity = NewReservationActivity & {
  id: number;
  createdAt: string;
};
