import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  box: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  borderLeftCell: {
    borderLeft: '1px solid rgba(224, 224, 224, 1)',
  },
  weakText: {
    color: '#777',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  title: {
    fontSize: '16px',
    fontWeight: 'bold',
    margin: '0 0 8px',
  },
  link: {
    color: '#0042FF',
    '&:visited': {
      color: '#525252',
    },
  },
}));
