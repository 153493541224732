/** @jsxImportSource @emotion/react */

import React from 'react';
import { Box } from '@material-ui/core';
import { css } from '@emotion/react';
import { commonCss } from './common-css';

const styles = {
  previewWrapper: css`
    background: #f2f2f2;
    border: 1px solid #333333;
    border-radius: 5px;
  `,
  imageWrapper: css`
    padding: 48px 0;
    width: 55%;
    max-width: 300px;
    margin: 0 auto;
  `,
  shopNameWrapper: css`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-bottom: 1px solid #ffffff;
    padding: 10px 32px;
  `,
  shopName: css`
    font-weight: bold;
    font-size: 16px;
    margin-left: 10px;
    text-align: left;
    margin-top: 0;
    margin-bottom: 0;
  `,
};

interface Props {
  color: string;
  shopName: string;
  imageSrc?: string;
}

export const PreviewSelectShopPage: React.VFC<Props> = ({
  color,
  imageSrc,
  shopName,
}) => {
  return (
    <Box css={[styles.previewWrapper, commonCss.previewWrapper]}>
      <Box
        css={css`
          background: #ffffff;
        `}
      >
        <Box css={styles.imageWrapper}>
          <img src={imageSrc} alt="" width="100%" />
        </Box>
      </Box>
      <Box>
        {[...Array(3)].map((_, index) => (
          <Box css={styles.shopNameWrapper} key={index}>
            <h1 css={styles.shopName} style={{ color: color }}>
              {index === 0 ? shopName : '店舗名 ◯◯'}
            </h1>
          </Box>
        ))}
      </Box>
    </Box>
  );
};
