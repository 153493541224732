/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import React from 'react';
import { useSizeType } from '../../hooks/use-size-type';
import { commonCss } from '../common-css';

const styles = {
  mainMenuContainer: css`
    margin-top: 76px;
  `,
  middleFlexContainer: css`
    display: flex;
    gap: 32px;
    padding: 0 16px;
    max-width: 1920px;
    margin: 0 auto;
  `,
  sideContainer: css`
    padding-top: 16px;
  `,
  mainContainer: css`
    flex: 1;
    min-width: 0;
  `,
  mainTopContainer: (isPcSize: boolean) => css`
    padding: ${isPcSize ? '24px 0 12px 0' : '4px 0'};
  `,
};

type BaseLayoutProps = {
  header?: React.ReactNode;
  mainMenu?: React.ReactNode;
  side?: React.ReactNode;
  mainTop?: React.ReactNode;
  children: React.ReactNode;
};

export function BaseLayout({
  header,
  mainMenu,
  side,
  mainTop,
  children,
}: BaseLayoutProps): JSX.Element {
  const { isPcSize } = useSizeType();

  return (
    <div>
      {header}
      <div css={[styles.mainMenuContainer, commonCss.noPrint]}>
        {isPcSize && mainMenu && <>{mainMenu}</>}
      </div>
      <div css={styles.middleFlexContainer}>
        {isPcSize && side && (
          <div css={[styles.sideContainer, commonCss.noPrint]}>{side}</div>
        )}
        <div css={styles.mainContainer}>
          <div css={[styles.mainTopContainer(isPcSize), commonCss.noPrint]}>
            {mainTop}
          </div>
          {children}
        </div>
      </div>
    </div>
  );
}
