/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { FormControlLabel, Grid, Radio, RadioGroup } from '@material-ui/core';
import React, { useMemo, useState } from 'react';

type PreviewProps = {
  iframeId: string;
  previewUrl: string;
  previewOpen: boolean;
  setPreviewOpen: (newValue: boolean) => void;
  alertMessage?: string | React.ReactNode;
};

export const Preview = ({
  iframeId,
  previewUrl,
  previewOpen,
  setPreviewOpen,
  alertMessage,
}: PreviewProps) => {
  const [deviceWidth, setDeviceWidth] = useState('sp');
  const [firstPreview, setFirstPreview] = useState(true);

  const styles = useMemo(() => {
    return createStyles(previewOpen, deviceWidth);
  }, [previewOpen, deviceWidth]);

  const handlePreviewToggled = () => {
    if (!previewOpen && firstPreview) {
      // updatePreview(editingEnquete.setting, 1);
      setFirstPreview(false);
    }
    setPreviewOpen(!previewOpen);
  };

  const handleDeviceChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDeviceWidth(e.target.value);
  };

  return (
    <>
      {previewOpen && <Grid item style={{ width: '480px' }} />}
      <div>
        <div css={styles.previewMenu}>
          <button
            type="button"
            onClick={handlePreviewToggled}
            css={styles.previewMenuToggle}
          >
            <img src="/img/menu.webp" alt="メニューアイコン" />
            {previewOpen === true ? 'プレビューを閉じる' : 'プレビューを見る'}
          </button>
          <div css={styles.previewHeader}>
            <p
              css={css`
                margin: 0;
              `}
            >
              プレビュー
            </p>
            <div>
              <RadioGroup
                row
                defaultValue={'sp'}
                aria-label="デバイス表示幅変更"
                name="デバイス表示幅変更"
                onChange={handleDeviceChanged}
                css={css`
                  justify-content: center;
                `}
              >
                <FormControlLabel
                  value="pc"
                  control={<Radio css={styles.previewRadioButton} />}
                  label="PC表示"
                />
                <FormControlLabel
                  value="sp"
                  control={<Radio css={styles.previewRadioButton} />}
                  label="スマートフォン表示"
                />
              </RadioGroup>
            </div>
          </div>
          {alertMessage && <div css={styles.alertMessage}>{alertMessage}</div>}
          <iframe
            id={iframeId}
            title="Form Preview"
            width="100%"
            scrolling="yes"
            src={previewUrl}
            frameBorder="no"
            css={css`
              height: calc(100vh + -85px);
            `}
          />
        </div>
      </div>
    </>
  );
};

const createStyles = (previewOpen: boolean, deviceWidth: string) => {
  const styles = {
    previewHeader: css`
      text-align: center;
      padding: 0.2em 0;
      background-color: #008000;
      color: white;
      font-size: 1.5rem;
      font-weight: bold;
    `,
    previewMenu: css`
      position: fixed;
      z-index: 10000;
      top: 0;
      right: 0;
      background: #fff;
      width: ${deviceWidth === 'sp' ? '480px' : 'calc(100% - 100px)'};
      height: 100%;
      transform: translate3d(
        ${previewOpen
          ? '0, 0, 0'
          : deviceWidth === 'sp'
          ? '480px, 0, 0'
          : 'calc(100%), 0, 0'}
      );
      transition: transform 0.35s;
      box-shadow: ${previewOpen &&
      '0px 8px 16px -2px rgba(10, 10, 10, 0.1),-1px 0px 6px 1px rgba(10, 10, 10, 0.2);'};
    `,
    previewMenuToggle: css`
      position: absolute;
      font-size: medium;
      top: 15%;
      border: none;
      left: -120px;
      width: 200px;
      height: 40px;
      line-height: 0px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
      transform: rotate(270deg);
      cursor: pointer;
      box-shadow: 0px -1px 1px 1px rgb(10 10 10 / 10%),
        1px 0px 6px 1px rgb(10 10 10 / 20%);
      img {
        width: 24px;
        height: 24px;
      }
      &:focus {
        outline: none;
      }
    `,
    previewRadioButton: css`
      color: white !important;
      &:checked {
        color: white !important;
      }
    `,
    alertMessage: css`
      padding: 10px;
    `,
  };
  return styles;
};
