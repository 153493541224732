/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { Breadcrumbs, Button, Grid, Paper } from '@material-ui/core';
import { AccessTime } from '@material-ui/icons';
import dayjs from 'dayjs';
import { useSnackbar } from 'notistack';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { useForm } from 'react-hook-form';
import useReactRouter from 'use-react-router';
import { SelectedCustomer } from '../../../@interfaces/customer';
import { INFLOW_SOURCE_INTERNAL } from '../../../@interfaces/reservation';
import {
  AssignedType,
  InitialAssignedType,
} from '../../../@interfaces/resource/reservation-resource';
import { ReservationAPI } from '../../../api/reservations';
import useFormSetting from '../../../api/use-form-setting';
import useInflowSources from '../../../api/use-inflow-sources';
import useReservation from '../../../api/use-reservation';
import useShop from '../../../api/use-shop';
import useShopCourses from '../../../api/use-shop-courses';
import {
  BreadcrumbLinkItem,
  BreadcrumbShopItem,
  BreadcrumbTextItem,
  BreadcrumbWorkspaceItem,
} from '../../../components/breadcrumb/BreadcrumbItem';
import { ShopPageLayout } from '../../../components/layouts/ShopPageLayout';
import LinkRouter from '../../../components/LinkRouter';
import { PageTitleAndDescription } from '../../../components/PageTitleAndDescription';
import { FullscreenLoading, Main, Root } from '../../../components/Shared';
import Spinner from '../../../components/Spinner';
import { Store } from '../../../context/GlobalStore';
import {
  createDefaultFieldResponses,
  Field,
  FieldResponseValue,
  FormResponse,
} from '../../../core/types/reservation-form-types';
import { SelectedResource } from '../../../core/types/reservation-resource-types';
import {
  Capacity,
  createDate,
  createDateTime,
  createTime,
  DateFormat,
  IDate,
  ReservedSlotCapacity,
  Slot,
  Time,
  TimeFormat,
  TimeRange,
  toDateStringByDate,
  toDateTimeString,
} from '../../../core/types/reservation-types';
import { useShopCustomer } from '../../../features/crm/api/useShopCustomer';
import { ReservationActivityComponent } from '../../../features/reservation-activity/components/ReservationActivityComponent';
import { MD_BREAKPOINT, useSizeType } from '../../../hooks/use-size-type';
import { globalColors } from '../../../styles/globalColors';
import { helps } from '../../../utils/helps';
import { Form } from './Form';
import { useNotificationInfo } from './NotificationInfo/useNotificationInfo';

export const styles = {
  rightInfo: css`
    font-size: 14px;
    color: #777;
    margin-left: 10px;
  `,
  fieldContainer: css`
    margin-bottom: 15px;
  `,
  optionGroup: css`
    margin-top: 15px;
  `,
  tableWrapperNew: css`
    max-width: calc(100vw - 54px - 315px);
    @media screen and (max-width: ${MD_BREAKPOINT}px) {
      max-width: 100vw;
    }
  `,
  clearOptionLabel: css`
    display: block;
    width: fit-content;
    line-height: 100%;
    cursor: pointer;
    font-size: 12px;
    border-radius: 4px;
    border: 1px solid #333333;
    margin: 0 12px;
    padding: 4px;
  `,
  pulldown: css`
    padding: 12px 10px;
    width: 100%;
    margin-top: 8px;
    font-size: 16px;
    color: #2c3f4c;
    border: 1px solid rgba(0, 0, 0, 0.42);
    border-radius: 4px;
  `,
};

function createDefaultFormResponse(): FormResponse {
  return {
    fields: [],
  };
}

type VirtualType = 'originalTime' | 'selectedTime';

type VirtualCapacity = ReservedSlotCapacity & {
  virtual: VirtualType;
};

export const isVirtualCapacity = (
  capacity: ReservedSlotCapacity
): capacity is VirtualCapacity => {
  return (capacity as VirtualCapacity).virtual !== undefined;
};

export const createVirtualCapacity = (
  time: Time,
  type: VirtualType
): VirtualCapacity => {
  return {
    slot: {
      timeRange: {
        start: time,
        end: time,
      } as TimeRange,
    } as Slot,
    capacity: {
      total: 1,
    } as Capacity,
    reserved: {
      total: 1,
      courseTotal: 1,
      canReserve: true,
    },
    virtual: type,
  };
};

// 登録時のモード
// none: 登録前状態
// single: １件登録して予約一覧に戻る
// continuous: 連続登録して予約編集画面のまま
// reloading: 連続登録後に予約時間表を再読み込みするための状態
//
// シーケンス
// 1. none
// 2. single or continuous
// 3. reloading
// 4. none
export type RegistrationMode = 'none' | 'single' | 'continuous' | 'reloading';

type PageParams = {
  workspaceUid: string;
  shopId: string;
  reservationId: string | undefined;
};

export type SelectedResourceWithAssignedType = SelectedResource & {
  initialAssignedType?: InitialAssignedType;
  assignedType: AssignedType;
};

export default function ReservationEditPage(props: any) {
  const { globalState } = useContext(Store);
  const { history, match } = useReactRouter<PageParams>();
  const { workspaceUid, shopId, reservationId } = match.params;
  const { shop, isLoadingShop } = useShop(shopId);

  const searchParams = new URL(window.location.href).searchParams;
  const fromId = searchParams.get('fromId') || undefined;
  const customerId = searchParams.get('customerId') || '0';

  const { reservation } = useReservation(shopId, reservationId);
  const { reservation: fromReservation } = useReservation(shopId, fromId);
  const { inflowSources } = useInflowSources(workspaceUid);
  const { data: customer } = useShopCustomer(shopId, customerId);
  const [registrationMode, setRegistrationMode] =
    useState<RegistrationMode>('none');

  useEffect(() => {
    const res = reservation || fromReservation;
    if (res) {
      setSelectedCourseId(res.courseId);
      const dateTime = createDateTime(res.dateTime);
      setSelectedDate(dateTime.date);
      // コピーして新規作成時は時間はクリアする
      if (reservation) {
        setSelectedTime(dateTime.time);
      }
      setFormResponse({ ...res.formResponse });
      setNote(res.note);
      setInflowSource(res.inflowSource);
      setSelectedLabelIds(res.selectedLabelIds);
      setSelectedResources(res.selectedResources);
      setSelectedCustomer(res.selectedCustomer);
    } else if (customer) {
      setSelectedCustomer({
        ...customer.customer,
        lineUserId: customer.lineUser?.id.toString(),
        lineDisplayName: customer.lineUser?.displayName,
      });
    }
  }, [reservation, fromReservation, customer]);

  const courseIdParam = searchParams.get('courseId');
  const { shopCourses, isLoadingShopCourses } = useShopCourses(shopId, true);
  const availableShopCourses = useMemo(() => {
    return shopCourses.filter((sc) => !sc.deletedAt);
  }, [shopCourses]);
  const [selectedCourseId, setSelectedCourseId] = useState<number | undefined>(
    courseIdParam ? parseInt(courseIdParam) : undefined
  );
  useEffect(() => {
    if (selectedCourseId || reservationId) {
      return;
    }
    setSelectedCourseId(availableShopCourses[0]?.id);
    // 初回表示時のみコースの初期値設定を行うために依存は availableShopCourses のみ
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availableShopCourses]);

  const dateParam = searchParams.get('date') || dayjs().format('YYYY-MM-DD');
  const [selectedDate, setSelectedDate] = useState<IDate>(
    createDate(`${dateParam}` as DateFormat)
  );

  const timeParam = searchParams.get('time');
  const defaultTime = timeParam
    ? createTime(`${timeParam}` as TimeFormat)
    : undefined;
  const [selectedTime, setSelectedTime] = useState<Time | undefined>(
    defaultTime
  );
  const timeInputRef = useRef<HTMLDivElement>(null);
  const resourceIdParam = searchParams.get('resourceId');
  const selectedResourceId = resourceIdParam
    ? parseInt(resourceIdParam)
    : undefined;

  const { formSetting, isLoadingFormSetting } = useFormSetting(
    workspaceUid,
    selectedCourseId ? String(selectedCourseId) : undefined
  );
  const [formResponse, setFormResponse] = useState(createDefaultFormResponse());
  const [note, setNote] = useState('');
  const [inflowSource, setInflowSource] = useState(INFLOW_SOURCE_INTERNAL);
  const [selectedLabelIds, setSelectedLabelIds] = useState<number[]>([]);
  const [selectedResources, setSelectedResources] = useState<
    SelectedResourceWithAssignedType[]
  >([]);
  const [selectedCustomer, setSelectedCustomer] = useState<
    SelectedCustomer | undefined
  >(undefined);
  const validationContext = useForm();
  const { handleSubmit } = validationContext;
  const [openFullscreenLoading, setOpenFullscreenLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { isSpSize, isTabletSize } = useSizeType();
  const [isHistoryOpened, setIsHistoryOpened] = useState(false);
  const { notificationSetting, notificationInfo } = useNotificationInfo(
    parseInt(shopId),
    formSetting,
    formResponse,
    selectedCustomer
  );

  useEffect(() => {
    // 有効なフィールドの入力値のみコースが変わっても引き継ぐ
    const availableFormResponseValues = formResponse.fields.filter((rf) => {
      return formSetting.fields.some((ff) => ff.uid === rf.uid);
    });
    if (!formSetting || availableFormResponseValues.length > 0) {
      return;
    }
    const defaultFieldValues = createDefaultFieldResponses(formSetting);
    setFormResponse({ ...formResponse, fields: defaultFieldValues });
    // フォーム設定が変わったときだけ、フォームの入力値を初期化するために依存は formSetting のみ
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formSetting]);

  const handleChangeValue = (field: Field, newValues: FieldResponseValue[]) => {
    const { fields } = formResponse;
    const newFieldResponses = [...fields];
    const targetIndex = fields.findIndex((f) => f.uid == field.uid);
    if (targetIndex == -1) {
      newFieldResponses.push({
        uid: field.uid,
        values: newValues,
      });
    } else {
      newFieldResponses[targetIndex].values = newValues;
    }
    setFormResponse({ fields: newFieldResponses });
  };

  const confirmReservatioCapacityAlert = () => {
    const lines = [
      '指定された日時は予約ができない時間です。続行しますか？',
      '・予約枠の設定がない可能性があります',
      '・予約が埋まっている可能性があります。',
    ];
    if (selectedResources.length > 0) {
      lines.push('・リソースが予約済みもしくは稼働外の可能性があります。');
    }
    const msg = lines.join('\n');
    return window.confirm(msg);
  };

  const handleSubmitForm = (e: React.FormEvent) => {
    if (!globalState.session?.idToken || !selectedCourseId || !selectedTime) {
      return;
    }
    const dateTime = toDateTimeString(selectedDate, selectedTime);
    if (reservationId) {
      setOpenFullscreenLoading(true);
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      ReservationAPI.canReserve(
        globalState.session?.idToken,
        shop.id,
        selectedCourseId,
        dateTime,
        parseInt(reservationId),
        formResponse,
        selectedResources
      ).then((res) => {
        if (!globalState.session?.idToken) {
          setOpenFullscreenLoading(false);
          return;
        }
        const canReserve = res.data.canReserve as boolean;
        if (!canReserve) {
          if (!confirmReservatioCapacityAlert()) {
            setOpenFullscreenLoading(false);
            return;
          }
        }
        ReservationAPI.update(
          globalState.session?.idToken,
          shop.id,
          selectedCourseId,
          dateTime,
          formResponse,
          note,
          inflowSource,
          selectedLabelIds,
          selectedResources,
          selectedCustomer?.id,
          parseInt(reservationId)
        )
          .then(() => {
            enqueueSnackbar('予約を更新しました。', { variant: 'success' });
            history.push(
              `/a/${workspaceUid}/shops/${shopId}?date=${toDateStringByDate(
                selectedDate
              )}`
            );
          })
          .catch((e) => {
            alert('保存に失敗しました。');
          })
          .finally(() => {
            setOpenFullscreenLoading(false);
          });
      });
    } else {
      setOpenFullscreenLoading(true);

      // 予約可能かチェック
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      ReservationAPI.canReserve(
        globalState.session?.idToken,
        shop.id,
        selectedCourseId,
        dateTime,
        undefined,
        formResponse,
        selectedResources
      ).then((res) => {
        if (!globalState.session?.idToken) {
          setOpenFullscreenLoading(false);
          return;
        }
        const canReserve = res.data.canReserve as boolean;
        if (!canReserve) {
          if (!confirmReservatioCapacityAlert()) {
            setOpenFullscreenLoading(false);
            return;
          }
        }
        ReservationAPI.reserve(
          globalState.session?.idToken,
          shop.id,
          selectedCourseId,
          dateTime,
          formResponse,
          note,
          inflowSource,
          selectedLabelIds,
          selectedResources,
          selectedCustomer?.id,
          notificationSetting
        )
          .then(() => {
            enqueueSnackbar('予約を登録しました。', { variant: 'success' });
            if (registrationMode === 'single') {
              // 通常登録
              history.push(
                `/a/${workspaceUid}/shops/${shopId}?date=${toDateStringByDate(
                  selectedDate
                )}`
              );
            } else if (registrationMode === 'continuous') {
              // 連続登録の際は画面遷移せず時間のみをクリア
              setSelectedTime(undefined);
              // 連続登録の際に予約時間表を再読み込みする
              setRegistrationMode('reloading');
              document.documentElement.scrollTop = 0;
            }
          })
          .catch((e) => {
            alert('保存に失敗しました。');
          })
          .finally(() => {
            setOpenFullscreenLoading(false);
          });
      });
    }
  };

  const handleClickDelete = () => {
    if (!globalState.session?.idToken || !reservationId) {
      return;
    }
    if (!window.confirm('予約をキャンセルします。よろしいですか？')) {
      return;
    }
    setOpenFullscreenLoading(true);
    ReservationAPI.remove(
      globalState.session?.idToken,
      shop.id,
      parseInt(reservationId)
    )
      .then(() => {
        enqueueSnackbar('予約をキャンセルしました。', { variant: 'success' });
        history.push(
          `/a/${workspaceUid}/shops/${shopId}?date=${toDateStringByDate(
            selectedDate
          )}`
        );
      })
      .catch((e) => {
        alert('キャンセルに失敗しました。');
      })
      .finally(() => {
        setOpenFullscreenLoading(false);
      });
  };

  const handleClickRestore = () => {
    if (
      !globalState.session?.idToken ||
      !reservationId ||
      !selectedCourseId ||
      !selectedTime
    ) {
      return;
    }
    setOpenFullscreenLoading(true);
    // 復元可能かチェック
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    ReservationAPI.canRestore(
      globalState.session.idToken,
      shop.id,
      parseInt(reservationId)
    ).then((res) => {
      if (!globalState.session?.idToken) {
        setOpenFullscreenLoading(false);
        return;
      }
      const canReserve = res.data.canReserve as boolean;
      if (!canReserve) {
        if (!confirmReservatioCapacityAlert()) {
          setOpenFullscreenLoading(false);
          return;
        }
      }
      ReservationAPI.restore(
        globalState.session.idToken,
        shop.id,
        parseInt(reservationId)
      )
        .then(() => {
          enqueueSnackbar('予約を復元しました。', { variant: 'success' });
          history.push(
            `/a/${workspaceUid}/shops/${shopId}?date=${toDateStringByDate(
              selectedDate
            )}`
          );
        })
        .catch((e) => {
          alert('復元に失敗しました。');
        })
        .finally(() => {
          setOpenFullscreenLoading(false);
        });
    });
  };

  const buildLeftButtons = () => {
    if (!reservation) {
      return null;
    }
    if (reservation.deletedAt) {
      return (
        <Button
          type="button"
          variant="contained"
          color="default"
          onClick={handleClickRestore}
        >
          復元する
        </Button>
      );
    } else {
      return (
        <Button
          type="button"
          variant="contained"
          color="default"
          onClick={handleClickDelete}
        >
          予約をキャンセルする
        </Button>
      );
    }
  };

  const showUpdateHistory = shopId && reservationId;

  const buildContents = () => {
    if (isLoadingShop || isLoadingShopCourses || isLoadingFormSetting) {
      return <Spinner loading={true} />;
    }
    if (!shop) {
      <div>店舗情報が取得できませんでした。</div>;
    }
    if (!shopCourses) {
      <div>コース情報が取得できませんでした。</div>;
    }
    if (!formSetting) {
      <div>フォーム設定情報が取得できませんでした。</div>;
    }

    const validateCustom = (event: React.MouseEvent) => {
      if (!selectedTime) {
        window.alert('予約時間を選択してください。');
        event.preventDefault();
        event.stopPropagation();
        if (timeInputRef.current) {
          const position = timeInputRef.current.offsetTop;
          window.scrollTo({
            top: position - 70,
            behavior: 'auto',
          });
        }
        return false;
      }
      return true;
    };

    return (
      <form
        onSubmit={handleSubmit(handleSubmitForm)}
        className="reservation-form"
      >
        <Grid
          container
          css={css`
            margin: 16px auto;
          `}
        >
          <Grid
            item
            container
            xs
            alignItems="center"
            justifyContent="flex-start"
          >
            <h2
              css={css`
                margin: 0;
              `}
            >
              {reservation?.deletedAt && '(キャンセル済みの予約)'}
            </h2>
          </Grid>
          <Grid
            item
            container
            xs={8}
            alignItems="center"
            justifyContent="flex-end"
          >
            {reservation && (
              <div css={styles.rightInfo}>作成: {reservation.createdAt}</div>
            )}
            {reservation && (
              <div css={styles.rightInfo}>更新: {reservation.updatedAt}</div>
            )}
            {reservation?.deletedAt && (
              <div css={styles.rightInfo}>
                キャンセル: {reservation.deletedAt}
              </div>
            )}
            {showUpdateHistory && (
              <div css={styles.rightInfo}>
                <Button
                  variant="outlined"
                  color="primary"
                  startIcon={<AccessTime />}
                  onClick={() => {
                    setIsHistoryOpened(true);
                  }}
                >
                  更新履歴
                </Button>
              </div>
            )}
          </Grid>
        </Grid>
        <Paper
          css={css`
            padding: 20px;
          `}
        >
          <Form
            workspaceUid={workspaceUid}
            shopId={shopId}
            courses={shopCourses}
            inflowSources={inflowSources}
            formSetting={formSetting}
            formResponse={formResponse}
            note={note}
            inflowSource={inflowSource}
            selectedLabelIds={selectedLabelIds}
            selectedResources={selectedResources}
            validationContext={validationContext}
            selectedDate={selectedDate}
            onChangeSelectedDate={setSelectedDate}
            selectedCourseId={selectedCourseId}
            onChangeSelctedCourseId={setSelectedCourseId}
            selectedTime={selectedTime}
            onChangeSelecteTime={setSelectedTime}
            reservation={reservation}
            onChangeValue={handleChangeValue}
            onChangeNote={setNote}
            onChangeInflowSource={setInflowSource}
            onChangeSelectedLabelIds={setSelectedLabelIds}
            onChangeSelectedResources={setSelectedResources}
            selectedResourceId={selectedResourceId}
            registrationMode={registrationMode}
            onChangeRegistrationMode={setRegistrationMode}
            selectedCustomer={selectedCustomer}
            onChangeSelectedCustomer={setSelectedCustomer}
            timeInputRef={timeInputRef}
          />
          {reservationId ? null : notificationInfo}
        </Paper>
        {!isSpSize && !isTabletSize ? (
          <Grid
            item
            container
            xs={12}
            css={css`
              margin-top: 20px;
              margin-bottom: 20px;
            `}
          >
            <Grid
              item
              container
              xs={6}
              alignItems="center"
              justifyContent="flex-start"
            >
              {buildLeftButtons()}
            </Grid>
            <Grid
              item
              container
              xs={6}
              alignItems="center"
              justifyContent="flex-end"
            >
              <LinkRouter
                to={`/a/${workspaceUid}/shops/${shopId}?date=${toDateStringByDate(
                  selectedDate
                )}`}
                css={css`
                  margin-right: 20px;
                `}
              >
                キャンセル
              </LinkRouter>
              {!reservationId && (
                <Button
                  type="submit"
                  variant="outlined"
                  color="primary"
                  onClick={(e) => {
                    if (validateCustom(e)) {
                      setRegistrationMode('continuous');
                    }
                  }}
                  css={css`
                    margin-right: 20px;
                  `}
                >
                  登録して続けて入力
                </Button>
              )}
              <Button
                type="submit"
                variant="contained"
                color="primary"
                onClick={(e) => {
                  if (validateCustom(e)) {
                    setRegistrationMode('single');
                  }
                }}
              >
                {reservationId ? '更新' : '登録'}する
              </Button>
            </Grid>
          </Grid>
        ) : (
          <Grid
            item
            container
            xs={12}
            css={css`
              margin-top: 20px;
              margin-bottom: 20px;
            `}
          >
            <Grid
              item
              container
              xs={12}
              alignItems="center"
              justifyContent="center"
            >
              <LinkRouter
                to={`/a/${workspaceUid}/shops/${shopId}?date=${toDateStringByDate(
                  selectedDate
                )}`}
                css={css`
                  margin-right: 20px;
                `}
              >
                キャンセル
              </LinkRouter>
              {!reservationId && (
                <Button
                  type="submit"
                  variant="outlined"
                  color="primary"
                  onClick={(e) => {
                    if (validateCustom(e)) {
                      setRegistrationMode('continuous');
                    }
                  }}
                  css={css`
                    margin-right: 20px;
                  `}
                >
                  登録して続けて入力
                </Button>
              )}
              <Button
                type="submit"
                variant="contained"
                color="primary"
                onClick={(e) => {
                  if (validateCustom(e)) {
                    setRegistrationMode('single');
                  }
                }}
              >
                {reservationId ? '更新' : '登録'}する
              </Button>
            </Grid>
            <Grid
              item
              container
              xs={12}
              alignItems="center"
              justifyContent="center"
              css={css`
                margin-top: 24px;
              `}
            >
              {buildLeftButtons()}
            </Grid>
          </Grid>
        )}
      </form>
    );
  };

  const pageTitle = reservationId ? '予約の編集' : '予約の登録';
  const description = reservationId
    ? 'すでにはいっている予約内容の編集やキャンセルを行います。'
    : 'Web以外の予約の登録を行います。';

  return (
    <Root>
      <Main>
        <ShopPageLayout
          workspaceUid={workspaceUid}
          shopId={shopId}
          current="shop-top"
          helpId={helps.shop.reservationEdit}
          breadcrumbs={
            <Breadcrumbs aria-label="breadcrumb">
              <BreadcrumbLinkItem to={`/`}>ホーム</BreadcrumbLinkItem>
              <BreadcrumbWorkspaceItem workspaceUid={workspaceUid} />
              <BreadcrumbShopItem workspaceUid={workspaceUid} shop={shop} />
              <BreadcrumbTextItem>{pageTitle}</BreadcrumbTextItem>
            </Breadcrumbs>
          }
        >
          <PageTitleAndDescription
            title="店舗"
            subTitle={pageTitle}
            description={description}
            themeColor={globalColors.shop}
          />
          {buildContents()}
        </ShopPageLayout>
      </Main>
      {showUpdateHistory && (
        <ReservationActivityComponent
          isOpen={isHistoryOpened}
          onClose={() => {
            setIsHistoryOpened(false);
          }}
          shopId={shopId}
          resId={reservationId}
        />
      )}
      <FullscreenLoading open={openFullscreenLoading} />
    </Root>
  );
}
