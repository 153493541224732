/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { Divider, Drawer, IconButton } from '@material-ui/core';
import { ReactNode, useState } from 'react';
import { GiHamburgerMenu } from 'react-icons/gi';
import { MdOutlineClose } from 'react-icons/md';
import { AppLabel, AppLogo } from '../../../components/AppLogo';
import { globalColors } from '../../../styles/globalColors';

const styles = {
  menuDrawer: css`
    width: 75vw;
  `,
  divider: css`
    margin: 8px 0;
  `,
  drawerHeader: css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 12px 4px 12px;
  `,
};

type SpSideMenuDrawerProps = {
  children: ReactNode;
};

export function SpSideMenuDrawer({
  children,
}: SpSideMenuDrawerProps): JSX.Element | null {
  const [isOpen, setIsOpen] = useState(false);

  const closeDrawer = () => {
    setIsOpen(false);
  };

  return (
    <>
      <IconButton
        aria-label="toggle sidemenu"
        onClick={() => {
          setIsOpen((prev) => !prev);
        }}
      >
        <GiHamburgerMenu color={globalColors.text} size="40px" />
      </IconButton>
      <Drawer anchor="left" open={isOpen} onClose={closeDrawer}>
        {isOpen ? (
          <div css={styles.menuDrawer}>
            <div css={styles.drawerHeader}>
              <AppLogo />
              <IconButton
                aria-label="close sidemenu"
                onClick={closeDrawer}
                css={css`
                  padding: 0;
                  min-height: auto;
                `}
              >
                <MdOutlineClose size="40px" />
              </IconButton>
            </div>
            <AppLabel />
            <Divider css={styles.divider} />
            <div onClick={closeDrawer}>{children}</div>
          </div>
        ) : null}
      </Drawer>
    </>
  );
}
