import axios, { AxiosResponse } from 'axios';
import { useContext, useEffect, useState } from 'react';
import { ResponseBody } from '../../../frontend-api/src/handlers/slot-setting/slot-settings-handler';
import { SlotSettingWithCourses } from '../../../frontend-api/src/interfaces/slot-setting';
import { Store } from '../context/GlobalStore';
import { API_END_POINT } from './api';

type CacheKey = `${string}`;

const createCacheKey = (shopId: string): CacheKey => {
  return `${shopId}` as CacheKey;
};
const cache: { [cacheKey: string]: SlotSettingWithCourses[] } = {};

export default function useSlotSettings(shopId: string | undefined) {
  const { globalState, setGlobalState } = useContext(Store);
  const cacheKey = createCacheKey(shopId || '-1');
  const [slotSettings, setSlotSettings] = useState(
    cache[cacheKey] || ([] as SlotSettingWithCourses[])
  );
  const [loading, setLoading] = useState(
    cache[cacheKey] == undefined && shopId != undefined
  );

  const reload = () => {
    if (!globalState.session?.idToken || !shopId) {
      return;
    }
    axios
      .get(`${API_END_POINT}/app/shops/${shopId}/slot-settings`, {
        headers: {
          Authorization: globalState.session?.idToken,
        },
      })
      .then((response: AxiosResponse<ResponseBody>) => {
        setSlotSettings(response.data.slotSettings);
        cache[cacheKey] = response.data.slotSettings;
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    reload();
  }, [shopId, globalState.session?.idToken]);

  return {
    slotSettings: slotSettings,
    isLoadingSlotSettins: loading,
    reloadSlotSettings: reload,
  };
}
