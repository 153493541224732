/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import {
  Box,
  Breadcrumbs,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  IconButton,
  Paper,
  TextField,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Alert } from '@material-ui/lab';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import React, { ChangeEvent, useContext, useEffect, useState } from 'react';
import { ColorResult, CompactPicker } from 'react-color';
import { useForm } from 'react-hook-form';
import useReactRouter from 'use-react-router';
import { ShopImage } from '../../../@interfaces/shop-setting';
import { API_END_POINT } from '../../../api/api';
import { useImageUpload } from '../../../api/use-image-upload';
import useShop from '../../../api/use-shop';
import useShopSetting from '../../../api/use-shop-setting';
import useWorkspaceSetting from '../../../api/use-workspace-setting';
import {
  BreadcrumbLinkItem,
  BreadcrumbShopItem,
  BreadcrumbTextItem,
  BreadcrumbWorkspaceItem,
} from '../../../components/breadcrumb/BreadcrumbItem';
import { commonCss } from '../../../components/common-css';
import { ImageCropperDialog } from '../../../components/ImageCropperDialog';
import { ShopPageLayout } from '../../../components/layouts/ShopPageLayout';
import { PageTitleAndDescription } from '../../../components/PageTitleAndDescription';
import { PreviewHeader } from '../../../components/PreviewHeader';
import { PreviewSelectCoursePage } from '../../../components/PreviewSelectCoursePage';
import { PreviewSelectShopPage } from '../../../components/PreviewSelectShopPage';
import {
  FullscreenLoading,
  Head,
  Main,
  Root,
} from '../../../components/Shared';
import { SortableImages } from '../../../components/SortableImages';
import Spinner from '../../../components/Spinner';
import { useStyles } from '../../../components/Styles';
import { Store } from '../../../context/GlobalStore';
import { SM_BREAKPOINT } from '../../../hooks/use-size-type';
import {
  DEFAULT_THEME_COLOR,
  LOGO_BASE_URL,
  THEME_COLORS,
} from '../../../models/theme';
import { globalColors } from '../../../styles/globalColors';
import { convertFileToBase64 } from '../../../utils/file';
import { helps } from '../../../utils/helps';

interface PageParams {
  workspaceUid: string;
  shopId: string;
}

type Files = {
  logoFile: File | undefined;
  bannerFile: File | undefined;
};

type OriginForm = {
  userScriptHead: string;
  userScriptBody: string;
  logoPath: string;
  imagePath: string;
  headline1: string;
  headline2: string;
  primaryColor: string;
  images: ShopImage[];
  displayBanner: boolean;
  displayReservationBanner: boolean;
  displayCourseBanner: boolean;
  bannerUrl: string;
  bannerPath: string;
};

type Form = OriginForm & Files;

const cssStyles = {
  shopImageWrapper: css`
    @media screen and (max-width: ${SM_BREAKPOINT}px) {
      margin-top: 20px;
    }
  `,
  uploadLogoWrapper: css`
    position: relative;
    width: 200px;
    margin: 0;

    @media screen and (max-width: ${SM_BREAKPOINT}px) {
      width: 100%;
    }
  `,
  uploadBannerWrapper: css`
    position: relative;
    width: 200px;
    margin: 0;

    @media screen and (max-width: ${SM_BREAKPOINT}px) {
      width: 100%;
    }
  `,
  closeIconButton: css`
    position: absolute;
    top: -16px;
    right: -16px;
    background: #dddddd;
    width: 32px;
    height: 32px;
  `,
};

const SHOP_IMAGE_LIMIT = 10;

export default function ShopThemeSettingPage(props: any) {
  const { globalState, setGlobalState } = useContext(Store);
  const { match } = useReactRouter<PageParams>();
  const { workspaceUid, shopId } = match.params;
  const validationContext = useForm();
  const { handleSubmit } = validationContext;
  const [isShowIconCropperDialog, setIsShowIconCropperDialog] =
    useState<boolean>(false);
  const [isShowLogoCropperDialog, setIsShowLogoCropperDialog] =
    useState<boolean>(false);
  const styles = useStyles();
  const { shop } = useShop(shopId);
  const { workspaceSetting } = useWorkspaceSetting(workspaceUid);
  const { shopSetting, isLoadingShopSetting, reloadShopSetting } =
    useShopSetting(shopId);
  const uploadImage = useImageUpload();

  const [form, setForm] = useState<Form>({
    userScriptHead: '',
    userScriptBody: '',
    logoPath: '',
    imagePath: '',
    images: [],
    headline1: '',
    headline2: '',
    primaryColor: '',
    logoFile: undefined,
    displayBanner: true,
    displayReservationBanner: true,
    displayCourseBanner: true,
    bannerFile: undefined,
    bannerUrl: '',
    bannerPath: '',
  });
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [croppingImageFiles, setCroppoingImageFiles] = useState<File[]>([]);
  useEffect(() => {
    if (!shopSetting) {
      return;
    }
    // https://adlive.backlog.jp/view/YOYAKU-585
    // のマイグレーション対応(imagePathをimagesに変更する)
    const images = (() => {
      if (shopSetting?.images?.length) {
        return shopSetting.images;
      }
      if (shopSetting.imagePath) {
        return [{ url: `${LOGO_BASE_URL}/${shopSetting.imagePath}` }];
      }
      return [];
    })();
    setForm({
      userScriptHead: shopSetting.userScriptHead,
      userScriptBody: shopSetting.userScriptBody,
      logoPath: shopSetting.logoPath,
      imagePath: '',
      headline1: shopSetting.headline1,
      headline2: shopSetting.headline2,
      primaryColor: shopSetting.primaryColor,
      logoFile: undefined,
      images,
      displayBanner: shopSetting.displayBanner,
      displayReservationBanner: shopSetting.displayReservationBanner,
      displayCourseBanner: shopSetting.displayCourseBanner,
      bannerFile: undefined,
      bannerUrl: shopSetting.bannerUrl || '',
      bannerPath: shopSetting.bannerPath || '',
    });
  }, [JSON.stringify(shopSetting)]);

  const handleChangeUserScriptHead = (e: ChangeEvent<HTMLInputElement>) => {
    const userScriptHead = e.target.value;
    setForm({ ...form, userScriptHead });
  };

  const handleChangeUserScriptBody = (e: ChangeEvent<HTMLInputElement>) => {
    const userScriptBody = e.target.value;
    setForm({ ...form, userScriptBody });
  };

  const handleChangeHeadline1 = (e: ChangeEvent<HTMLInputElement>) => {
    const headline1 = e.target.value;
    setForm({ ...form, headline1 });
  };

  const handleChangeHeadline2 = (e: ChangeEvent<HTMLInputElement>) => {
    const headline2 = e.target.value;
    setForm({ ...form, headline2 });
  };

  const handleChangePrimaryColor = (
    color: ColorResult,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const primaryColor = color.hex;
    setForm({ ...form, primaryColor });
  };

  const handleChangeLogoFile = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return;
    }
    const logoFile = Array.from(e.target.files)[0];
    setForm({ ...form, logoFile });
    setIsShowLogoCropperDialog(true);
  };

  const handleClickDeleteLogoPath = () => {
    const logoFile = undefined;
    setForm({ ...form, logoPath: '', logoFile });
  };

  const handleChangeImageFile = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return;
    }
    if (e.target.files.length + form.images.length > 10) {
      alert('設定できる画像は最大で10枚です');
      return;
    }
    setCroppoingImageFiles(Array.from(e.target.files));
    e.target.value = '';
    setIsShowIconCropperDialog(true);
  };

  const handleChangeEnableThemeColor = () => {
    const primaryColor = form.primaryColor
      ? ''
      : workspaceSetting?.primaryColor || DEFAULT_THEME_COLOR;
    setForm({ ...form, primaryColor });
  };

  const handleChangeBannerFile = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return;
    }
    const bannerFile = Array.from(e.target.files)[0];
    setForm({ ...form, bannerFile });
  };

  const handleClickDeleteBannerPath = () => {
    const bannerFile = undefined;
    setForm({ ...form, bannerPath: '', bannerFile });
  };

  const handleChangeBanner = () => {
    if (
      !shopSetting?.displayBanner &&
      !shopSetting?.displayCourseBanner &&
      !shopSetting?.displayReservationBanner &&
      !form.displayBanner &&
      !form.displayCourseBanner &&
      !form.displayReservationBanner
    ) {
      setForm({
        ...form,
        displayBanner: true,
        displayReservationBanner: true,
        displayCourseBanner: true,
      });
    } else {
      const displayBanner = !form.displayBanner;
      setForm({ ...form, displayBanner });
    }
  };

  const handleChangeReservationBanner = () => {
    const displayReservationBanner = !form.displayReservationBanner;
    setForm({ ...form, displayReservationBanner });
  };

  const handleChangeCourseBanner = () => {
    const displayCourseBanner = !form.displayCourseBanner;
    setForm({ ...form, displayCourseBanner });
  };

  const handleChangeBannerUrl = (e: ChangeEvent<HTMLInputElement>) => {
    const bannerUrl = e.target.value;
    setForm({ ...form, bannerUrl });
  };

  const encodeText = (text: string) => {
    return process.env.REACT_APP_STAGE === 'local'
      ? encodeURIComponent(text)
      : text;
  };

  const handleSubmitForm = async (e: React.FormEvent) => {
    const params = new FormData();
    type BannerLogo = {
      logoMimetype?: string | undefined;
      logoFileBase64?: string | undefined;
      bannerMimetype?: string | undefined;
      bannerFileBase64?: string | undefined;
    };
    let json: OriginForm & BannerLogo = {
      userScriptHead: encodeText(form.userScriptHead),
      userScriptBody: encodeText(form.userScriptBody),
      logoPath: form.logoPath,
      imagePath: form.imagePath,
      headline1: encodeText(form.headline1),
      headline2: encodeText(form.headline2),
      primaryColor: form.primaryColor,
      images: form.images,
      displayBanner: form.displayBanner,
      displayReservationBanner: form.displayReservationBanner,
      displayCourseBanner: form.displayCourseBanner,
      bannerUrl: form.bannerUrl,
      bannerPath: form.bannerPath,
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const headers: any = {
      Authorization: globalState.session?.idToken,
    };

    let logoFileBase64: string | undefined;
    if (form.logoFile) {
      if (process.env.REACT_APP_STAGE === 'local') {
        logoFileBase64 = await convertFileToBase64(form.logoFile);
      } else {
        params.append('logoFile', form.logoFile);
      }
    }

    if (logoFileBase64) {
      json = {
        ...json,
        logoMimetype: form.logoFile?.type,
        logoFileBase64,
      };
    }

    let bannerFileBase64: string | undefined;
    if (form.bannerFile) {
      if (process.env.REACT_APP_STAGE === 'local') {
        bannerFileBase64 = await convertFileToBase64(form.bannerFile);
      } else {
        params.append('bannerFile', form.bannerFile);
      }
    }

    if (bannerFileBase64) {
      json = {
        ...json,
        bannerMimetype: form.bannerFile?.type,
        bannerFileBase64,
      };
    }

    if (!bannerFileBase64 && !logoFileBase64) {
      params.append('json', JSON.stringify(json));
    } else {
      params.append('json', JSON.stringify(json));
    }

    setOpenBackdrop(true);
    axios
      .post(`${API_END_POINT}/app/shops/${shopId}/setting-theme`, params, {
        headers,
      })
      .then(() => {
        enqueueSnackbar('設定を保存しました。', { variant: 'success' });
        reloadShopSetting();
      })
      .catch((e) => {
        enqueueSnackbar('設定が保存できませんでした。', { variant: 'error' });
      })
      .finally(() => {
        setOpenBackdrop(false);
      });
  };

  const handleCropIconImage = async (croppedImageSrc: string) => {
    const imageFile: File = await fetch(croppedImageSrc)
      .then(async (r) => r.blob())
      .then(
        (blobFile) =>
          new File([blobFile], croppingImageFiles[0]?.name ?? '', {
            type: croppingImageFiles[0]?.type || 'image/jpeg',
          })
      );

    const url = await uploadImage(workspaceUid, shop, imageFile);

    setForm({
      ...form,
      images: [...form.images, { url }],
    });
    if (croppingImageFiles.length === 1) {
      setIsShowIconCropperDialog(false);
    }
    setCroppoingImageFiles([
      ...croppingImageFiles.slice(1, croppingImageFiles.length),
    ]);
  };

  const handleCropIconLogo = async (croppedImageSrc: string) => {
    const logoFile: File = await fetch(croppedImageSrc)
      .then(async (r) => r.blob())
      .then(
        (blobFile) =>
          new File([blobFile], form.logoFile!.name, {
            type: form.logoFile!.type || 'image/jpeg',
          })
      );

    setForm({ ...form, logoFile });
    setIsShowLogoCropperDialog(false);
  };

  const handleCropIconBanner = async (croppedImageSrc: string) => {
    const bannerFile: File = await fetch(croppedImageSrc)
      .then(async (r) => r.blob())
      .then(
        (blobFile) =>
          new File([blobFile], form.bannerFile!.name, {
            type: form.bannerFile!.type || 'image/jpeg',
          })
      );

    setForm({ ...form, bannerFile });
  };

  const getLogoPath = () => {
    if (form.logoFile) {
      return URL.createObjectURL(form.logoFile);
    } else if (form.logoPath || workspaceSetting?.logoPath) {
      return `${LOGO_BASE_URL}/${form.logoPath || workspaceSetting?.logoPath}`;
    } else {
      return;
    }
  };

  const getImagePath = () => {
    if (form.imagePath) {
      return `${LOGO_BASE_URL}/${form.imagePath}`;
    } else if (
      workspaceSetting?.imagePath &&
      !form.imagePath &&
      form.images.length == 0
    ) {
      return `${LOGO_BASE_URL}/${workspaceSetting?.imagePath}`;
    } else {
      return;
    }
  };

  const getBannerPath = () => {
    if (form.bannerFile) {
      return URL.createObjectURL(form.bannerFile);
    } else if (form.bannerPath) {
      return `${LOGO_BASE_URL}/${form.bannerPath}`;
    } else {
      return;
    }
  };

  const buildRightNavigationButtons = () => {
    return (
      <>
        <Button
          css={commonCss.button.right}
          type="submit"
          variant="contained"
          color="primary"
        >
          &nbsp;保存&nbsp;
        </Button>
      </>
    );
  };

  const buildContents = () => {
    if (isLoadingShopSetting || !shopSetting) {
      return <Spinner loading={true} />;
    }
    const helperText =
      'Google AnaliticsやGoogle Tag Manager等のタグ(<script>, <style>など)を設定します。この設定はWeb予約のすべての画面で有効です。';
    return (
      <form onSubmit={handleSubmit(handleSubmitForm)}>
        <Grid container style={{ marginTop: '20px' }}>
          <Grid item xs={6}>
            <h3>テーマとタグ</h3>
          </Grid>
          <Grid
            item
            container
            xs={6}
            alignItems="center"
            justifyContent="flex-end"
          >
            {buildRightNavigationButtons()}
          </Grid>
        </Grid>
        <Alert severity="info">
          ここで設定された店舗のテーマはワークスペース設定よりも優先されます。
        </Alert>
        <Paper className={styles.paper}>
          <FormControl required fullWidth component="fieldset">
            <Grid container alignItems="flex-start">
              <Grid item>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={form.primaryColor ? true : false}
                        onChange={handleChangeEnableThemeColor}
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                      />
                    }
                    label={`店舗でテーマカラーを設定する`}
                  />

                  {form.primaryColor && (
                    <FormControlLabel
                      control={
                        <CompactPicker
                          colors={THEME_COLORS}
                          color={form.primaryColor}
                          onChangeComplete={handleChangePrimaryColor}
                        />
                      }
                      labelPlacement="top"
                      label={`テーマカラー`}
                      disabled={form.primaryColor ? false : true}
                    />
                  )}
                </FormGroup>
              </Grid>
            </Grid>
            <Grid
              container
              alignItems="flex-start"
              css={css`
                margin-top: 24px;
              `}
            >
              <Grid item xs={12} sm={6}>
                <p
                  css={css`
                    display: block;
                    text-align: left;
                    margin: 0 0 24px 0;
                  `}
                >
                  ロゴの設定{' '}
                  <span
                    css={css`
                      font-size: 14px;
                    `}
                  >
                    推奨サイズ:240×160px
                  </span>
                </p>
                {form.logoPath || form.logoFile ? (
                  <Box css={cssStyles.uploadLogoWrapper}>
                    <img src={getLogoPath()} width="100%" alt="" />
                    <IconButton
                      css={cssStyles.closeIconButton}
                      onClick={handleClickDeleteLogoPath}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Box>
                ) : (
                  <Button variant="contained" component="label" color="default">
                    ロゴ画像を選択する
                    <input
                      type="file"
                      css={css`
                        display: none;
                      `}
                      onChange={handleChangeLogoFile}
                      accept="image/*"
                    />
                  </Button>
                )}
              </Grid>
              <Grid item xs={12} sm={6} css={cssStyles.shopImageWrapper}>
                <p
                  css={css`
                    display: block;
                    text-align: left;
                    margin: 0 0 24px 0;
                  `}
                >
                  店舗画像の設定(最大10枚){' '}
                  <span
                    css={css`
                      font-size: 14px;
                    `}
                  >
                    推奨サイズ:750×420px
                  </span>
                </p>
                <SortableImages
                  images={form.images}
                  onChangeImages={(images) => {
                    setForm({ ...form, images });
                  }}
                />
                {form.images.length < SHOP_IMAGE_LIMIT && (
                  <Button
                    variant="contained"
                    component="label"
                    color="default"
                    css={css`
                      margin-top: 10px;
                    `}
                  >
                    店舗画像を追加する
                    <input
                      type="file"
                      css={css`
                        display: none;
                      `}
                      multiple
                      onChange={handleChangeImageFile}
                      accept="image/*"
                    />
                  </Button>
                )}
              </Grid>
            </Grid>
            <Box mt={3}>
              <TextField
                label={'店舗の紹介文(1段落目)'}
                helperText={
                  '※店舗の紹介文はコース選択画面で表示されます。複数行入力可能、HTMLタグはimg, a, span, table, tr, th, tdが使用できます。'
                }
                value={form.headline1}
                fullWidth
                multiline
                onChange={handleChangeHeadline1}
              />
            </Box>
            <Box mt={2} mb={3}>
              <TextField
                label={'店舗の紹介文(2段落目)'}
                helperText={
                  '※店舗の紹介文はコース選択画面で表示されます。複数行入力可能、HTMLタグはimg, a, span, table, tr, th, tdが使用できます。'
                }
                value={form.headline2}
                fullWidth
                multiline
                onChange={handleChangeHeadline2}
              />
            </Box>
            <Grid container alignItems="flex-start">
              <Grid item>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={form.displayBanner ? true : false}
                        onChange={handleChangeBanner}
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                      />
                    }
                    label={`バナーを設定する`}
                  />

                  {form.displayBanner ? (
                    <Grid
                      container
                      alignItems="flex-start"
                      css={css`
                        margin-top: 5px;
                      `}
                    >
                      <FormControl component="fieldset">
                        <div
                          css={css`
                            display: block;
                            text-align: left;
                            margin: 0 0 10px 10px;
                          `}
                        >
                          <Box>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    form.displayCourseBanner ? true : false
                                  }
                                  onChange={handleChangeCourseBanner}
                                  inputProps={{
                                    'aria-label': 'primary checkbox',
                                  }}
                                />
                              }
                              label={`コース選択画面に表示する`}
                            />
                          </Box>
                          <Box>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    form.displayReservationBanner ? true : false
                                  }
                                  onChange={handleChangeReservationBanner}
                                  inputProps={{
                                    'aria-label': 'primary checkbox',
                                  }}
                                />
                              }
                              label={`予約完了画面に表示する`}
                            />
                          </Box>
                        </div>
                        <p
                          css={css`
                            display: block;
                            text-align: left;
                            margin: 0 0 24px 10px;
                            width: 100%;
                          `}
                        >
                          バナーの設定{' '}
                          <span
                            css={css`
                              font-size: 14px;
                            `}
                          >
                            推奨サイズ: 幅600px以上
                          </span>
                        </p>
                        {form.bannerPath || form.bannerFile ? (
                          <Box css={cssStyles.uploadBannerWrapper}>
                            <img src={getBannerPath()} width="100%" alt="" />
                            <IconButton
                              css={cssStyles.closeIconButton}
                              onClick={handleClickDeleteBannerPath}
                            >
                              <CloseIcon />
                            </IconButton>
                          </Box>
                        ) : (
                          <Button
                            variant="contained"
                            component="label"
                            color="default"
                          >
                            バナー画像を選択する
                            <input
                              type="file"
                              css={css`
                                display: none;
                              `}
                              onChange={handleChangeBannerFile}
                              accept="image/*"
                            />
                          </Button>
                        )}
                        <div
                          css={css`
                            display: block;
                            text-align: left;
                            margin: 0 0 24px 10px;
                          `}
                        >
                          <Box>
                            <TextField
                              label={'リンク先URL'}
                              helperText={
                                'バナークリック時のリンク先URLを設定します。'
                              }
                              value={form.bannerUrl}
                              fullWidth
                              multiline
                              onChange={handleChangeBannerUrl}
                            />
                          </Box>
                        </div>
                      </FormControl>
                    </Grid>
                  ) : (
                    <></>
                  )}
                </FormGroup>
              </Grid>
            </Grid>
          </FormControl>
          <FormHelperText
            css={css`
              margin: 24px auto 8px;
            `}
          >
            プレビュー（店舗の設定がワークスペースの設定より優先されます）
          </FormHelperText>
          <Box>
            <Grid container>
              <Grid
                item
                xs={12}
                sm={6}
                css={css`
                  padding: 0 12px;
                `}
              >
                <p
                  css={css`
                    font-size: 14px;
                  `}
                >
                  ヘッダー
                </p>
                <PreviewHeader
                  color={
                    form.primaryColor ||
                    workspaceSetting?.primaryColor ||
                    DEFAULT_THEME_COLOR
                  }
                  shopName={shop?.name}
                  imageSrc={getLogoPath()}
                />
                <p
                  css={css`
                    font-size: 14px;
                  `}
                >
                  店舗選択画面
                </p>
                <PreviewSelectShopPage
                  color={
                    form.primaryColor ||
                    workspaceSetting?.primaryColor ||
                    DEFAULT_THEME_COLOR
                  }
                  shopName={shop?.name}
                  imageSrc={getLogoPath()}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                css={css`
                  padding: 0 12px;
                `}
              >
                <p
                  css={css`
                    font-size: 14px;
                  `}
                >
                  コース選択画面
                </p>
                <PreviewSelectCoursePage
                  color={
                    form.primaryColor ||
                    workspaceSetting?.primaryColor ||
                    DEFAULT_THEME_COLOR
                  }
                  shopName={shop?.name}
                  imageSrc={getImagePath()}
                  headline1={form.headline1 || workspaceSetting?.headline1}
                  headline2={form.headline2 || workspaceSetting?.headline2}
                  images={form.images}
                  bannerImageSrc={
                    form.displayBanner && form.displayCourseBanner
                      ? getBannerPath()
                      : undefined
                  }
                />
              </Grid>
            </Grid>
          </Box>
        </Paper>
        <h3>埋め込みタグ</h3>
        <Alert severity="info">
          ここで設定されたタグとワークスペース設定で設定されたタグがこの店舗のWeb予約画面に埋め込まれます。
        </Alert>
        <Paper className={styles.paper}>
          <TextField
            label="Web予約フォーム画面の埋め込みタグ(headタグ末尾)"
            helperText={helperText}
            value={form.userScriptHead}
            multiline
            fullWidth
            onChange={handleChangeUserScriptHead}
          />
          <TextField
            label="Web予約フォーム画面の埋め込みタグ(bodyタグ先頭)"
            helperText={helperText}
            value={form.userScriptBody}
            multiline
            fullWidth
            onChange={handleChangeUserScriptBody}
          />
        </Paper>
        <Grid
          container
          css={css`
            margin-top: 20px;
          `}
        >
          <Grid item xs={6}></Grid>
          <Grid
            item
            container
            xs={6}
            alignItems="center"
            justifyContent="flex-end"
          >
            {buildRightNavigationButtons()}
          </Grid>
        </Grid>
      </form>
    );
  };

  const pageTitle = 'デザインと計測タグ設定';

  return (
    <Root>
      <Head title={`店舗設定 - ${shop?.name || ''}`} />
      <Main>
        <ShopPageLayout
          workspaceUid={workspaceUid}
          shopId={shopId}
          current="theme"
          helpId={helps.shop.setting.themeAndTag}
          breadcrumbs={
            <Breadcrumbs aria-label="breadcrumb">
              <BreadcrumbLinkItem to={`/`}>ホーム</BreadcrumbLinkItem>
              <BreadcrumbWorkspaceItem workspaceUid={workspaceUid} />
              <BreadcrumbShopItem workspaceUid={workspaceUid} shop={shop} />
              <BreadcrumbTextItem>{pageTitle}</BreadcrumbTextItem>
            </Breadcrumbs>
          }
        >
          <PageTitleAndDescription
            title="店舗"
            subTitle={pageTitle}
            description="予約画面のロゴやTOP画像、全体の色味などの設定、GA4等の計測タグの設定を行います。ワークスペースでの同設定よりも優先されます。"
            themeColor={globalColors.shop}
          />
          {buildContents()}
        </ShopPageLayout>
      </Main>
      <FullscreenLoading open={openBackdrop} />
      {isShowIconCropperDialog && croppingImageFiles.length > 0 && (
        <ImageCropperDialog
          file={croppingImageFiles[0]}
          onCropImage={handleCropIconImage}
          onClose={() => {
            setIsShowIconCropperDialog(false);
          }}
          aspect={375 / 210}
        />
      )}
      {isShowLogoCropperDialog && form.logoFile && (
        <ImageCropperDialog
          file={form.logoFile}
          onCropImage={handleCropIconLogo}
          onClose={() => {
            setIsShowLogoCropperDialog(false);
          }}
          aspect={240 / 160}
        />
      )}
    </Root>
  );
}
