/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { useSizeType } from '../hooks/use-size-type';
import { commonCss } from './common-css';

const styles = {
  container: css`
    margin-bottom: 32px;
    ${commonCss.noPrint};
  `,
  title: (themeColor: string) => css`
    &:before {
      content: '';
      display: inline-block;
      width: 30px;
      height: 30px;
      background-color: ${themeColor};
      margin-right: 8px;
    }
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    font-size: 24px;
  `,
  subTitle: css`
    font-size: 20px;
  `,
  description: (themeColor: string) => css`
    display: inline-block;
    padding-top: 6px;
    border-top: 8px solid ${themeColor};
  `,
  mobileWidth: css`
    width: 100%;
  `,
  pcWidth: css`
    min-width: 468px;
  `,
};

type PageTitleAndDescriptionProps = {
  title: string;
  subTitle: string;
  description: string;
  themeColor: string;
};

export function PageTitleAndDescription({
  title,
  subTitle,
  description,
  themeColor,
}: PageTitleAndDescriptionProps): JSX.Element {
  const { isSpSize } = useSizeType();

  return (
    <div css={styles.container}>
      <h1 css={styles.title(themeColor)}>{title}</h1>
      <h2 css={styles.subTitle}>{subTitle}</h2>
      <span
        css={[
          styles.description(themeColor),
          isSpSize ? styles.mobileWidth : styles.pcWidth,
        ]}
      >
        {description}
      </span>
    </div>
  );
}
