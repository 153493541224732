import axios, { AxiosResponse } from 'axios';
import { useContext, useEffect, useState } from 'react';
import { ResponseBody } from '../../../frontend-api/src/handlers/course/workspace-course-setting-handler';
import { Course } from '../@interfaces/course';
import { ICourseSetting } from '../@interfaces/course-setting';
import { Store } from '../context/GlobalStore';
import { API_END_POINT } from './api';

export default function useCourseSetting(
  workspaceUid: string,
  courseId: string | undefined
) {
  const { globalState, setGlobalState } = useContext(Store);
  const [course, setCourse] = useState<Course>();
  const [setting, setSetting] = useState<ICourseSetting>();
  const [loading, setLoading] = useState(false);
  const reload = () => {
    if (!globalState.session?.idToken || !courseId) {
      return;
    }
    axios
      .get(
        `${API_END_POINT}/app/ws/${workspaceUid}/courses/${courseId}/setting`,
        {
          headers: {
            Authorization: globalState.session?.idToken,
          },
        }
      )
      .then((response: AxiosResponse<ResponseBody>) => {
        setCourse(response.data.course);
        setSetting(response.data.setting);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    reload();
  }, [workspaceUid, courseId, globalState.session?.idToken]);
  return {
    course,
    setting,
    isLoadingCourseSetting: loading,
  };
}
