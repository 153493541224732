/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { Button, Grid, Popover } from '@material-ui/core';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import React, { useContext, useEffect, useState } from 'react';
import useReactRouter from 'use-react-router';
import useDeleteGoogleCalendarIntegration from '../../../api/integration/use-delete-google-calendar-integration';
import useGoogleCalendarIntegration from '../../../api/integration/use-google-calendar-integration';
import useGoogleIntegration from '../../../api/integration/use-google-integration';
import useShop from '../../../api/use-shop';
import {
  BreadcrumbLinkItem,
  BreadcrumbShopItem,
  BreadcrumbTextItem,
  BreadcrumbWorkspaceItem,
} from '../../../components/breadcrumb/BreadcrumbItem';
import { ShopPageLayout } from '../../../components/layouts/ShopPageLayout';
import { PageTitleAndDescription } from '../../../components/PageTitleAndDescription';
import { Head, Main, Root } from '../../../components/Shared';
import Spinner from '../../../components/Spinner';
import { Store } from '../../../context/GlobalStore';
import { useGoogleRedirectUrl } from '../../../hooks/use-google-redirect-url';
import { globalColors } from '../../../styles/globalColors';
import { helps } from '../../../utils/helps';
import ShopIntegrationsSettingTable from './ShopIntegrationsSettingTable';

const styles = {
  popover: css`
    padding: 20px;
    width: 400px;
  `,
  googleSignIn: css`
    cursor: pointer;
  `,
};

interface PageParams {
  workspaceUid: string;
  shopId: string;
}

export default function ShopIntegrationsSettingPage(props: any) {
  const { match } = useReactRouter<PageParams>();
  const { workspaceUid, shopId } = match.params;
  const { shop } = useShop(shopId);
  const url = `${window.location.origin}/google/oauth/callback`;
  const redirectPath = `/a/${workspaceUid}/shops/${shopId}/settings/integrations/new`;
  const { setGoogleRedirectUrl } = useGoogleRedirectUrl();
  useEffect(() => {
    setGoogleRedirectUrl({ redirectUrl: redirectPath });
  }, []);
  const { googleIntegration, googleIntegrationApi } = useGoogleIntegration();
  const {
    googleCalendarIntegration,
    isLoadingCalendarIntegration,
    googleCalendarIntegrationApi,
    setGoogleCalendarIntegration,
  } = useGoogleCalendarIntegration();
  const {
    isLoadingDeleteCalendarIntegration,
    googleDeleteCalendarIntegrationApi,
  } = useDeleteGoogleCalendarIntegration();
  const [removeId, setRemoveId] = useState<number | null>(null);
  const { globalState } = useContext(Store);

  useEffect(() => {
    if (globalState.session?.idToken) googleCalendarIntegrationApi(shopId);
  }, [globalState.session?.idToken]);

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handleGoogleOauth = () => {
    googleIntegrationApi(shopId, url);
  };

  useEffect(() => {
    if (googleIntegration) {
      window.location.href = googleIntegration.url;
    }
  }, [googleIntegration]);

  const handleRemove = (id: number) => {
    if (!window.confirm('連携を解除してもよろしいですか？')) {
      return;
    }
    googleDeleteCalendarIntegrationApi(shopId, id);
    setRemoveId(id);
  };

  useEffect(() => {
    if (!isLoadingDeleteCalendarIntegration) {
      const data = googleCalendarIntegration.filter(
        (item) => item.googleCalendarId !== removeId
      );
      setGoogleCalendarIntegration(data);
    }
  }, [isLoadingDeleteCalendarIntegration]);

  const buildContents = () => (
    <>
      <Grid container xs={12} alignItems="center">
        <Grid item xs={5}>
          <h3>Googleカレンダー</h3>
        </Grid>
        <Grid item container xs={7} alignItems="center" justify="flex-end">
          <Button
            onClick={handleClick}
            variant="contained"
            color="primary"
            size="medium"
          >
            連携を追加
          </Button>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <div css={styles.popover}>
              <div>
                以下のボタンをクリックし予約を反映したいGoogleアカウントとカレンダーを選択してします。
              </div>
              <img
                src="/img/google_signin.png"
                width="200"
                alt=""
                css={styles.googleSignIn}
                onClick={handleGoogleOauth}
              />
            </div>
          </Popover>
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item container xs={12} alignItems="center">
          {isLoadingCalendarIntegration ? (
            <Spinner loading={true} />
          ) : (
            <ShopIntegrationsSettingTable
              calendars={googleCalendarIntegration}
              handleRemove={handleRemove}
            />
          )}
        </Grid>
      </Grid>
    </>
  );

  const pageTitle = 'Googleカレンダー連携';

  return (
    <Root>
      <Head title={`アプリ統合 - ${shop?.name || ''}`} />
      <Main>
        <ShopPageLayout
          workspaceUid={workspaceUid}
          shopId={shopId}
          current="integrations"
          helpId={helps.shop.setting.integrations}
          breadcrumbs={
            <Breadcrumbs aria-label="breadcrumb">
              <BreadcrumbLinkItem to={`/`}>ホーム</BreadcrumbLinkItem>
              <BreadcrumbWorkspaceItem workspaceUid={workspaceUid} />
              <BreadcrumbShopItem workspaceUid={workspaceUid} shop={shop} />
              <BreadcrumbTextItem>{pageTitle}</BreadcrumbTextItem>
            </Breadcrumbs>
          }
        >
          <PageTitleAndDescription
            title="店舗"
            subTitle={pageTitle}
            description="Googleカレンダーの連携設定を行います。"
            themeColor={globalColors.shop}
          />
          {buildContents()}
        </ShopPageLayout>
      </Main>
    </Root>
  );
}
