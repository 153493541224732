import { css } from '@emotion/react';
import { MD_BREAKPOINT, SM_BREAKPOINT } from '../../../hooks/use-size-type';
import { globalColors } from '../../../styles/globalColors';

export const sideMenuStyles = {
  sideMenu: css`
    background-color: #fff;
    min-width: 260px;
    br {
      display: none;
    }
    @media screen and (min-width: ${SM_BREAKPOINT}px) {
      box-shadow: 1px 1px 2px 0 rgba(0, 0, 0, 0.25);
      br {
        display: inline;
      }
    }
  `,
  toggleButton: css`
    color: #999;
    display: none;
    @media screen and (min-width: ${MD_BREAKPOINT}px) {
      display: flex;
    }
  `,
  hideSidebar: css`
    display: block;
    @media screen and (min-width: ${SM_BREAKPOINT}px) {
      display: none;
    }
  `,
};

export const sideMenuItemStyles = {
  sideMenuItem: css`
    display: flex;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
    height: 48px;
    width: 100%;
    color: ${globalColors.text};
    text-decoration: none;
    font-size: 15px;
    transition: background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
    &:hover {
      background-color: ${globalColors.hover};
      border-color: ${globalColors.hover};
    }
  `,
  sideMenuCurrentItem: css`
    background-color: ${globalColors.currentPage};
    font-weight: bold;
    border-left: 4px solid ${globalColors.currentPage};
    transition: none;
    &:hover {
      background-color: ${globalColors.currentPage};
      border-color: ${globalColors.currentPage};
    }
  `,
  categoryItem: css`
    padding: 4px 16px;
    height: 28px;
    font-size: 15px;
    color: ${globalColors.text};
  `,
};

export const buttonReset = css`
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  padding: 0;
  appearance: none;
`;
