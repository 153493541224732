/** @jsxImportSource @emotion/react */

import { Grid, Switch } from '@material-ui/core';
import React from 'react';
import {
  AnyField,
  FormSetting,
  RadioField,
} from '../../../../../core/types/reservation-form-types';
import { FormSettingOptions } from './FormSettingOptions';

type FormSettingFieldRadioProps = {
  field: RadioField;
  setting: FormSetting;
  onChangeSetting: (setting: FormSetting) => void;
  originalField: AnyField | undefined;
};

export const FormSettingFieldRadio = (
  props: FormSettingFieldRadioProps
): JSX.Element => {
  const { field, setting, onChangeSetting, originalField } = props;

  const handleChangeIsShowPulldown = () => {
    field.isShowPulldown = field.isShowPulldown ? !field.isShowPulldown : true;
    onChangeSetting({ ...setting });
  };

  return (
    <>
      <FormSettingOptions
        field={field}
        setting={setting}
        onChangeSetting={onChangeSetting}
        originalField={originalField}
      />
      <Grid item container alignItems="center" xs={6}>
        選択肢の形式:
        <Switch
          checked={field.isShowPulldown || false}
          color="primary"
          onChange={handleChangeIsShowPulldown}
        />
        プルダウン形式で表示する
      </Grid>
    </>
  );
};
