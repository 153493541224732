import { Button, Grid, TextField } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import BusinessCenterRoundedIcon from '@material-ui/icons/BusinessCenterRounded';
import axios from 'axios';
import React, { ChangeEvent, FormEvent, useContext, useState } from 'react';
import { API_END_POINT } from '../api/api';
import { Main, Root, TopAppBar, TopAppBarSpacer } from '../components/Shared';
import { Store } from '../context/GlobalStore';
import { useSnackbar } from 'notistack';

interface WorkspaceForm {
  uid: string;
  name: string;
}

export default function WorkspacesNewPage() {
  const { globalState, setGlobalState } = useContext(Store);
  const { enqueueSnackbar } = useSnackbar();

  const [form, setForm] = useState<WorkspaceForm>({
    uid: '',
    name: '',
  });

  const handleChangeUid = (e: ChangeEvent<HTMLInputElement>) => {
    const uid = e.target.value.replace(/[^0-9a-zA-Z-_]/g, '').toLowerCase();
    setForm({ ...form, uid });
  };

  const handleChangeName = (e: ChangeEvent<HTMLInputElement>) => {
    const name = e.target.value;
    setForm({ ...form, name });
  };

  const handleSubmitForm = (e: FormEvent) => {
    e.preventDefault();

    // 新規登録
    axios
      .post(`${API_END_POINT}/app/ws`, form, {
        headers: {
          Authorization: globalState.session?.idToken,
        },
      })
      .then((response) => {
        alert('ワークスペースを登録しました。');
        window.location.href = `/a/${form.uid}/`;
      })
      .catch((e) => {
        if (e.response && e.response.status === 409) {
          enqueueSnackbar(
            '同一IDのワークスペースが存在するため、追加できませんでした。',
            { variant: 'error' }
          );
        } else {
          enqueueSnackbar('ワークスペースが追加できませんでした。', {
            variant: 'error',
          });
        }
      });
  };

  return (
    <Root>
      <TopAppBar />
      <Main>
        <TopAppBarSpacer />
        <Container maxWidth="lg">
          <Grid container spacing={3} alignItems="center">
            <Grid item container xs={12} alignItems="center">
              <BusinessCenterRoundedIcon />
              <h2>ワークスペースの新規作成</h2>
            </Grid>
            <Grid item container xs={12} alignItems="center">
              <form onSubmit={handleSubmitForm}>
                <TextField
                  label="ワークスペースID"
                  helperText="半角英数小文字、ハイフン、アンダーバーが使用できます。"
                  placeholder="例: adlive"
                  value={form.uid}
                  onChange={handleChangeUid}
                  margin="normal"
                  fullWidth
                  required
                  inputProps={{ maxLength: 30, pattern: '^[a-z0-9_-]+$' }}
                />
                <TextField
                  label="ワークスペース名"
                  placeholder="例: ADlive株式会社"
                  value={form.name}
                  onChange={handleChangeName}
                  margin="normal"
                  fullWidth
                  required
                  inputProps={{ maxLength: 50 }}
                />
                <Button type="submit" variant="contained" color="primary">
                  作成
                </Button>
              </form>
            </Grid>
          </Grid>
        </Container>
      </Main>
    </Root>
  );
}
