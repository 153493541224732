/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { Box } from '@material-ui/core';
import TimeTextField from '../../../components/TimeTextField';
import { TimeRange } from '../../../core/types/reservation-types';
import { timeString } from '../../../utils/time';

export const TimeRangeEditor = ({
  timeRange,
  onChange,
}: {
  timeRange: TimeRange;
  onChange: (newTimeRange: TimeRange) => void;
}): JSX.Element => {
  const handleChangeStart = (start: string) => {
    const splits = start.split(':');
    onChange({
      ...timeRange,
      start: {
        hour: parseInt(splits[0] || '0'),
        minute: parseInt(splits[1] || '0'),
      },
    });
  };
  const handleChangeEnd = (end: string) => {
    const splits = end.split(':');
    onChange({
      ...timeRange,
      end: {
        hour: parseInt(splits[0] || '0'),
        minute: parseInt(splits[1] || '0'),
      },
    });
  };
  return (
    <Box
      css={css`
        display: flex;
        justify-content: flex-start;
        align-items: center;
      `}
    >
      <TimeTextField
        value={timeString(timeRange.start)}
        onChange={handleChangeStart}
      />
      〜
      <TimeTextField
        value={timeString(timeRange.end)}
        onChange={handleChangeEnd}
      />
    </Box>
  );
};
