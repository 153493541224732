/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import React from 'react';
import { DeepMap, FieldError, FieldValues } from 'react-hook-form';
import { Field } from '../../../../core/types/reservation-form-types';

type FieldErrorMessageProps = {
  field: Field;
  errors: DeepMap<FieldValues, FieldError>;
};

export function FieldErrorMessage(props: FieldErrorMessageProps) {
  const { field, errors } = props;

  const fieldError = errors[field.uid];
  if (!fieldError) {
    return null;
  }

  const message = fieldError.type === 'required' ? '必須です。' : '';

  return (
    <div
      css={css`
        color: red;
      `}
    >
      {message}
    </div>
  );
}
