/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import {
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  Grid,
  TextField,
} from '@material-ui/core';
import { useState } from 'react';
import { CustomerInfo } from '../../../../@interfaces/customer';
import { Pager } from '../../../../components/Pager';
import { useSizeType } from '../../../../hooks/use-size-type';
import { useShopCustomers } from '../../api/useShopCustomers';
import { useShopCustomersCount } from '../../api/useShopCustomersCount';
import { CustomerSearchResultTable } from './CustomerSearchResultTable';
import { CustomerSelectorSuggestions } from './CustomerSelector';

type ExistCustomerSelectorProps = {
  shopId: string;
  suggestions?: CustomerSelectorSuggestions;
  onClose: () => void;
  onSelect: (customer: CustomerInfo | undefined) => void;
  onMoveToNewCustomer?: () => void;
};
const COUNT_PER_PAGE = 20;
const splitKeyword = (keyword: string): string[] =>
  keyword
    .trim()
    .split(new RegExp(' |　'))
    .filter((s) => !!s);
export const ExistCustomerSelector = (props: ExistCustomerSelectorProps) => {
  const { shopId, suggestions, onClose, onSelect, onMoveToNewCustomer } = props;
  const [keyword, setKeyword] = useState('');
  const [keywords, setKeywords] = useState<string[]>([]);
  const [page, setPage] = useState(1);
  const { data: customers, isLoading: isLoadingShopCustomer } =
    useShopCustomers(
      shopId,
      keywords,
      COUNT_PER_PAGE,
      COUNT_PER_PAGE * (page - 1)
    );
  const { data: customersCount, isLoading: isLoadingCount } =
    useShopCustomersCount(parseInt(shopId), keywords);

  const maxPage = Math.ceil((customersCount?.count ?? 0) / COUNT_PER_PAGE);
  const { isSpSize } = useSizeType();

  const handleClose = () => {
    onClose();
  };

  const buildSuggestions = (): JSX.Element[] => {
    return [suggestions?.name, suggestions?.tel, suggestions?.email]
      .filter((s) => s !== undefined)
      .map((s) => (
        <Button
          variant="text"
          color="primary"
          size="small"
          onClick={(e) => {
            e.preventDefault();
            setKeyword(s ?? '');
            search();
          }}
        >
          {s}で検索
        </Button>
      ));
  };

  const search = () => {
    setPage(1);
    setKeywords(splitKeyword(keyword));
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    e.stopPropagation();
    search();
  };

  const handleClear = () => {
    setKeyword('');
    setKeywords([]);
    setPage(1);
  };

  const buildSearchForm = () => {
    return (
      <form onSubmit={handleSubmit}>
        <Grid
          container
          spacing={1}
          css={css`
            margin-bottom: ${isSpSize ? '16px' : 0};
          `}
        >
          <Grid
            item
            css={css`
              width: ${isSpSize ? '100%' : '300px'};
            `}
          >
            <TextField
              margin="dense"
              fullWidth
              value={keyword}
              onChange={(e) => {
                setKeyword(e.target.value);
              }}
              placeholder="キーワードで検索"
              autoFocus
            />
          </Grid>
          <Grid item>
            <Button color="primary" type="submit" variant="contained">
              検索
            </Button>
          </Grid>
          <Grid item>
            <Button type="button" variant="contained" onClick={handleClear}>
              クリア
            </Button>
          </Grid>
        </Grid>
      </form>
    );
  };

  const buildPager = () => {
    return (
      <Grid item xs={12}>
        {customersCount && customersCount?.count > 0 && (
          <>
            <Pager
              page={page}
              count={customersCount?.count ?? 0}
              countPerPage={COUNT_PER_PAGE}
              onChangePage={(page) => {
                if (page <= maxPage) {
                  setPage(page);
                }
              }}
            />
          </>
        )}
      </Grid>
    );
  };

  const buildTable = () => {
    return (
      <CustomerSearchResultTable
        customers={customers}
        onSelect={onSelect}
        emptyLabel={
          <div>
            顧客が見つかりませんでした。
            <div>
              <Button
                variant="contained"
                color="primary"
                onClick={onMoveToNewCustomer}
                size="small"
              >
                予約情報を元に顧客を新規登録
              </Button>
            </div>
          </div>
        }
      />
    );
  };

  return (
    <>
      <DialogContent>
        {buildSearchForm()}
        {buildSuggestions()}
        {isLoadingShopCustomer && <CircularProgress />}
        {buildPager()}
        {buildTable()}
        {buildPager()}
      </DialogContent>
      <DialogActions>
        <Grid container>
          <Grid item xs={6}>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => {
                onSelect(undefined);
              }}
            >
              未設定に変更
            </Button>
          </Grid>
          <Grid item container xs={6} justifyContent="flex-end">
            <Button variant="text" color="primary" onClick={handleClose}>
              閉じる
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </>
  );
};
