/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import {
  Button,
  Checkbox,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Popover,
} from '@material-ui/core';
import React, { useMemo } from 'react';
import { Label } from '../@interfaces/label';
import CloseIcon from '@material-ui/icons/Close';
import { commonCss } from './common-css';

export const labelStyles = {
  label: css({
    display: 'inline-block',
    background: '#ddd',
    color: '#fff',
    fontSize: '9px',
    borderRadius: '5px 5px',
    padding: '5px 10px',
    marginRight: '5px',
    textAlign: 'center',
    minWidth: '50px',
    marginTop: '2px',
    marginBottom: '2px',
    '@media print': {
      color: '#000',
      border: 'solid 1px #777',
      fontSize: 10,
      padding: '2px 2px',
      borderRadius: '2px 2px',
    },
  }),
  vertical: css({
    marginBottom: '5px',
  }),
  chooserHeader: css({
    padding: '5px 0px',
    backgroundColor: '#eee',
    borderBottom: 'solid 1px #ddd',
  }),
  chooserHeaderLabel: css({
    marginLeft: '15px',
  }),
};

export default function LabelChooser(props: {
  workspaceUid: string;
  selectedLabelIds: number[];
  labels: Label[];
  onChangeSelectedLabelIds: (selectedLabelIds: number[]) => void;
  onClose: () => void;
  button?: string | JSX.Element;
  vertical?: boolean;
}) {
  const {
    workspaceUid,
    selectedLabelIds,
    labels,
    onChangeSelectedLabelIds,
    onClose,
    button,
    vertical,
  } = props;
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handleClickEdit = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    onClose();
  };

  const selectedLabels = useMemo(() => {
    return labels.filter((l) => selectedLabelIds.includes(l.id));
  }, [labels, selectedLabelIds]);

  const handleToggleSelectedLabel = (label: Label) => {
    const index = selectedLabelIds.indexOf(label.id);
    if (index === -1) {
      selectedLabelIds.push(label.id);
    } else {
      selectedLabelIds.splice(index, 1);
    }
    onChangeSelectedLabelIds([...selectedLabelIds]);
  };

  const open = Boolean(anchorEl);

  const buildSelectedLabel = (label: Label) => {
    if (vertical) {
      return (
        <div key={label.id} css={labelStyles.vertical}>
          <LabelPreview label={label} />
        </div>
      );
    } else {
      return <LabelPreview key={label.id} label={label} />;
    }
  };

  const buildSelectedLabels = () => {
    return selectedLabels.map((label) => buildSelectedLabel(label));
  };

  return (
    <>
      {buildSelectedLabels()}
      <Button
        color="primary"
        variant="text"
        size="small"
        onClick={handleClickEdit}
        css={[commonCss.noPrint]}
      >
        {button || '編集'}
      </Button>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Grid
          container
          alignItems="center"
          justifyContent="flex-end"
          css={labelStyles.chooserHeader}
        >
          <Grid
            container
            item
            xs={10}
            alignItems="center"
            justifyContent="flex-start"
          >
            <div css={labelStyles.chooserHeaderLabel}>ラベルを選択</div>
          </Grid>
          <Grid
            container
            item
            xs={2}
            alignItems="center"
            justifyContent="flex-end"
          >
            <IconButton
              size="small"
              color="primary"
              component="span"
              onClick={handleClose}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
        <List>
          {labels.map((label) => {
            const labelId = `checkbox-list-label-${label.id}`;
            return (
              <ListItem
                key={label.id}
                role={undefined}
                dense
                button
                onClick={(e) => {
                  handleToggleSelectedLabel(label);
                }}
              >
                <ListItemIcon>
                  <Checkbox
                    edge="start"
                    checked={selectedLabelIds.includes(label.id)}
                    tabIndex={-1}
                    disableRipple
                  />
                </ListItemIcon>
                <ListItemText id={labelId}>
                  <LabelPreview label={label} />
                </ListItemText>
              </ListItem>
            );
          })}
        </List>
      </Popover>
    </>
  );
}

export function LabelPreview(props: { label: Label }) {
  const { label } = props;
  return (
    <RawLabelPreview text={label.name} primaryColor={label.primaryColor} />
  );
}

export function RawLabelPreview(props: { text: string; primaryColor: string }) {
  const { text, primaryColor } = props;
  return (
    <span css={labelStyles.label} style={{ backgroundColor: primaryColor }}>
      {text}
    </span>
  );
}
