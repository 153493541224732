/** @jsxImportSource @emotion/react */

import React from 'react';
import {
  AnyField,
  CheckboxField,
  FormSetting,
} from '../../../../../core/types/reservation-form-types';
import { FormSettingOptions } from './FormSettingOptions';

type FormSettingFieldCheckboxProps = {
  field: CheckboxField;
  setting: FormSetting;
  onChangeSetting: (setting: FormSetting) => void;
  originalField: AnyField | undefined;
};

export const FormSettingFieldCheckbox = (
  props: FormSettingFieldCheckboxProps
): JSX.Element => {
  const { field, setting, onChangeSetting, originalField } = props;

  return (
    <FormSettingOptions
      field={field}
      setting={setting}
      onChangeSetting={onChangeSetting}
      originalField={originalField}
    />
  );
};
