import axios, { AxiosResponse } from 'axios';
import { useContext, useState } from 'react';
import { ResponseBody } from '../../../../frontend-api/src/handlers/integration/google-calendar-handler';
import { Store } from '../../context/GlobalStore';
import { API_END_POINT } from '../api';
import { GoogleCalendarsByShopId } from '../../@interfaces/google-oauth-token';

export type StoredGoogleCalendarIntegration = GoogleCalendarsByShopId[];

export default function useGoogleCalendarIntegration() {
  const { globalState, setGlobalState } = useContext(Store);
  const [googleCalendarIntegration, setGoogleCalendarIntegration] = useState<
    GoogleCalendarsByShopId[]
  >([]);
  const [loading, setLoading] = useState(false);

  const googleCalendarIntegrationApi = (shopId: string) => {
    setLoading(true);
    axios
      .get(`${API_END_POINT}/app/shops/${shopId}/google/calendar/integration`, {
        headers: {
          Authorization: globalState.session?.idToken,
        },
      })
      .then((response: AxiosResponse<ResponseBody>) => {
        setGoogleCalendarIntegration(response.data);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return {
    googleCalendarIntegration,
    googleCalendarIntegrationApi,
    setGoogleCalendarIntegration,
    isLoadingCalendarIntegration: loading,
  };
}
