import { FormResponse } from '../../core/types/reservation-form-types';
import { DateFormat } from '../../core/types/reservation-types';

export type InitialAssignedType = 'select' | 'auto';
export type AssignedType = 'select' | 'auto';

export type ReservationResource = {
  reservationId: number;
  resourceTargetUid: string;
  no: number;
  resourceId: number;
  initialAssignedType: InitialAssignedType;
  assignedType: AssignedType;
};

export type ReservationResourceWithResource = ReservationResource & {
  resourceName: string;
};

export type ReservationResourceWithReservation = ReservationResource & {
  courseId: number;
  reservationDate: DateFormat;
  reservationHour: number;
  reservationMinute: number;
  formResponse: FormResponse;
};

export type AssignedTypeLabel = {
  text: string;
};

export const assignedTypeLabels: {
  [assignedType in AssignedType]: AssignedTypeLabel;
} = {
  auto: {
    text: '指定なし',
  },
  select: {
    text: '指定あり',
  },
};
