/** @jsxImportSource @emotion/react */

import dayjs from 'dayjs';
import React, { useEffect } from 'react';
import { SpecialShopHoliday } from '../../@interfaces/special-shop-holiday';
import { SpecialSlotSettingRule } from '../../@interfaces/special-slot-setting-rule';
import useReservationTimeTable, {
  ReservationTimeTable,
} from '../../api/use-reservation-time-table';
import {
  createDate,
  IDate,
  toDateStringByDayjs,
  toDayjs,
} from '../../core/types/reservation-types';
import { Calendar, calendarCss, RenderCellStyleHandler } from './Calendar';
import { CalendarCell } from './calendar-helper';

export function SpecialSlotSettingCalendar(props: {
  shopId: string;
  floating: boolean;
  currentDate: IDate;
  onChangeCurrentDate: (newDate: IDate) => void;
  selectedViewDate: dayjs.Dayjs;
  setSelectedViewDate: (selectedViewDate: dayjs.Dayjs) => void;
  holidays: SpecialShopHoliday[];
  specialRules: SpecialSlotSettingRule[];
  reservationTimeTable?: ReservationTimeTable;
}) {
  const {
    shopId,
    floating,
    currentDate,
    onChangeCurrentDate,
    selectedViewDate,
    setSelectedViewDate,
    holidays,
    specialRules,
    reservationTimeTable,
  } = props;

  const handleChangeSelectedViewDate = (newSelectedViewDate: dayjs.Dayjs) => {
    setSelectedViewDate(newSelectedViewDate);
  };

  const handleRenderCell = (cell: CalendarCell | null) => {
    if (!cell) {
      return null;
    }
    const { date } = cell;
    const isHoliday = holidays.find((h) => createDate(h.date).date == date);
    const isSpecialDay = specialRules.find(
      (h) => createDate(h.date).date == date
    );
    return isHoliday || isSpecialDay ? (
      <div css={calendarCss.annotations.default}></div>
    ) : null;
  };

  const handleRenderCellStyle: RenderCellStyleHandler = (
    cell: CalendarCell | null,
    courseId?: number
  ) => {
    if (!cell) {
      return undefined;
    }

    const slots = reservationTimeTable?.tables?.flatMap((table) => {
      if (!cell?.date) {
        return [];
      }
      if (courseId && table.course.id !== courseId) {
        return [];
      }
      const targetDate = table.dates.find((date) => {
        return (
          date.date.year === selectedViewDate.year() &&
          date.date.month === selectedViewDate.month() + 1 &&
          date.date.date === cell.date
        );
      });
      if (!targetDate) {
        return [];
      }
      return targetDate.slots;
    });
    const reserved = slots?.reduce((total, slot) => {
      return total + slot.reserved.total;
    }, 0);
    const capacity = slots?.reduce((total, slot) => {
      return total + slot.capacity.total;
    }, 0);
    if (capacity === undefined || capacity === 0 || reserved === undefined) {
      return {
        backgroundColor: '#eee',
      };
    }
    const percent = reserved / capacity;
    return percent
      ? {
          backgroundColor: `rgba(255,192,203,${percent})`,
        }
      : undefined;
  };

  return (
    <Calendar
      shopId={shopId}
      floating={floating}
      currentDate={currentDate}
      onChangeSelectedViewDate={handleChangeSelectedViewDate}
      onChangeCurrentDate={onChangeCurrentDate}
      onRenderCell={handleRenderCell}
      onRenderCellStyle={handleRenderCellStyle}
    />
  );
}
