import { AxiosResponse } from 'axios';
import { ShopAttachRequestParam } from '../../../@interfaces/custom-domain/api';
import { API_END_POINT } from '../../../api/api';
import { useMutation } from '../../common/api/use-mutation';

export const useAttachCustomDomain = (): ((
  shopId: number,
  customDomainId: number
) => Promise<AxiosResponse<''>>) => {
  const { request } = useMutation<'', ShopAttachRequestParam>();
  return async (shopId, customDomainId) => {
    return request(
      'PUT',
      `${API_END_POINT}/app/shops/${shopId}/custom-domain`,
      {},
      { customDomainId }
    );
  };
};

export const useDetachCustomDomain = (): ((
  shopId: number
) => Promise<AxiosResponse<''>>) => {
  const { request } = useMutation<'', ShopAttachRequestParam>();
  return async (shopId) => {
    return request(
      'PUT',
      `${API_END_POINT}/app/shops/${shopId}/custom-domain`,
      {},
      { customDomainId: null }
    );
  };
};
