import axios, { AxiosResponse } from 'axios';
import { useContext, useEffect, useState } from 'react';
import { ResponseBody } from '../../../frontend-api/src/handlers/label/labels-handler';
import { Label } from '../@interfaces/label';
import { Store } from '../context/GlobalStore';
import { API_END_POINT } from './api';

type CacheKey = `${string}`;

const createCacheKey = (workspaceUid: string): CacheKey => {
  return `${workspaceUid}` as CacheKey;
};
const cache: { [cacheKey: string]: Label[] } = {};

export const getLabels = (
  idToken: string,
  workspaceUid: string,
  callback: (labels: Label[]) => void
) => {
  return axios
    .get(`${API_END_POINT}/app/ws/${workspaceUid}/labels`, {
      headers: {
        Authorization: idToken,
      },
    })
    .then((response: AxiosResponse<ResponseBody>) => {
      callback(response.data.labels);
    });
};

export default function useLabels(workspaceUid: string) {
  const { globalState, setGlobalState } = useContext(Store);
  const cacheKey = createCacheKey(workspaceUid);
  const [labels, setLabels] = useState(cache[cacheKey] || ([] as Label[]));
  const [loading, setLoading] = useState(cache[cacheKey] == undefined);

  const reload = () => {
    if (!globalState.session?.idToken) {
      return;
    }
    getLabels(globalState.session.idToken, workspaceUid, (labels: Label[]) => {
      setLabels(labels);
      cache[cacheKey] = labels;
    }).finally(() => {
      setLoading(false);
    });
  };

  useEffect(() => {
    reload();
  }, [workspaceUid, globalState.session?.idToken]);

  return {
    labels,
    isLoadingLabels: loading,
    reloadLabels: reload,
  };
}
