/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { Box, Button, Grid } from '@material-ui/core';
import PermContactCalendarIcon from '@material-ui/icons/PermContactCalendar';
import {
  default as useReactRouter,
  default as useRouter,
} from 'use-react-router';
import { ReservationCustomerWithReservationAndCourseName } from '../../../@interfaces/reservation-customer';
import { toDoubleDigits } from '../../../utils/numbers';

const styles = {
  container: css`
    padding: 16px;
  `,
  reserveContainer: css`
    padding: 12px 0 16px;
    border-bottom: 2px solid lightgray;
  `,
  reserveContainerCanceled: css`
    background-color: #dddddd;
  `,
  detailLink: css`
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  `,
  reserveCourseName: css`
    font-weight: bold;
    color: #333;
  `,
  reserveDateTime: css`
    color: #888888;
  `,
};

export function ReservationActivityList({
  customerReservations,
  customerId,
}: {
  customerReservations: ReservationCustomerWithReservationAndCourseName[];
  customerId: string;
}): JSX.Element {
  const { match } = useReactRouter<{
    workspaceUid: string;
    shopId: string;
  }>();
  const { workspaceUid, shopId } = match.params;
  const { history } = useRouter();

  const toReservationDetailPage = (shopId: number, reservationId: number) => {
    history.push(
      `/a/${workspaceUid}/shops/${shopId}/reservations/${reservationId}`
    );
  };

  const createReservation = () => {
    history.push(
      `/a/${workspaceUid}/shops/${shopId}/reservations/new?customerId=${customerId}`
    );
  };

  return (
    <Box css={styles.container}>
      <Grid container justifyContent="flex-end">
        <Button variant="contained" color="primary" onClick={createReservation}>
          予約を追加
        </Button>
      </Grid>
      <Box>
        {customerReservations.length === 0 && (
          <Box mt={4}>予約はまだありません。</Box>
        )}
        {customerReservations.map((reservation, index) => (
          <Grid
            container
            css={[
              styles.reserveContainer,
              reservation.deletedAt && styles.reserveContainerCanceled,
            ]}
            key={index}
            onClick={() => {
              toReservationDetailPage(
                reservation.shopId,
                reservation.reservationId
              );
            }}
          >
            <Grid item>
              <PermContactCalendarIcon color="primary" />
            </Grid>
            <Grid item style={{ marginLeft: '4px' }}>
              <Box css={styles.detailLink}>
                <Grid container>
                  <Grid item css={styles.reserveCourseName}>
                    {reservation.courseName}
                    {reservation.deletedAt && <span>(キャンセル済み)</span>}
                  </Grid>
                </Grid>
              </Box>
              <Box css={styles.reserveDateTime}>
                {`${reservation.reservationDate} ${toDoubleDigits(
                  reservation.reservationHour
                )}:${toDoubleDigits(reservation.reservationMinute)}`}
                {reservation.note && <div>{reservation.note}</div>}
              </Box>
            </Grid>
          </Grid>
        ))}
      </Box>
    </Box>
  );
}
