/** @jsxImportSource @emotion/react */

import { TextField } from '@material-ui/core';
import React from 'react';
import { NameField } from '../../../../core/types/reservation-form-types';
import { styles } from '../ReservationEditPage';
import { FieldErrorMessage } from './FieldErrorMessageProps';
import {
  buildFieldValidation,
  buildPlaceholder,
  fieldRequired,
  FormFieldProps,
  handleEnterDisable,
} from './input-utils';

type NameFormFieldProps = FormFieldProps & {
  field: NameField;
};
export function NameFormField(props: NameFormFieldProps) {
  const { field, fieldResponse, onChangeValue, validationContext } = props;
  const { errors, register } = validationContext;

  const fieldValidation = buildFieldValidation(
    field,
    onChangeValue === undefined
  );

  const handleChangeValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    onChangeValue?.(field, [newValue]);
  };

  return (
    <div css={styles.fieldContainer}>
      <TextField
        type="text"
        inputRef={register(fieldValidation)}
        name={field.uid}
        label={field.name}
        placeholder={buildPlaceholder(field)}
        margin="normal"
        fullWidth
        required={fieldRequired(field)}
        helperText={field.description}
        onChange={handleChangeValue}
        value={fieldResponse.values[0]}
        onKeyDown={handleEnterDisable}
      />
      <FieldErrorMessage field={field} errors={errors} />
    </div>
  );
}
