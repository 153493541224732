import { CustomDomainAttachedShopsResponse } from '../../../@interfaces/custom-domain/api';
import { API_END_POINT } from '../../../api/api';
import { useQuery } from '../../common/api/use-query';

export const useCustomDomainsShops = (
  workspaceUid: string,
  customDomainId: number
) => {
  const { response, isLoading, reload } =
    useQuery<CustomDomainAttachedShopsResponse>(
      `${API_END_POINT}/app/ws/${workspaceUid}/custom-domains/${customDomainId}/shops`
    );
  return {
    data: response?.shops || [],
    isLoading: isLoading,
    reload: reload,
  };
};
