import { RichReservation } from '../../../../@interfaces/reservation';
import { AnyField } from '../../../../core/types/reservation-form-types';
import { EmptyReservationCustomerInfo } from './EmptyReservationCustomerInfo';
import { ExistReservationCustomerInfo } from './ExistReservationCustomerInfo';

type ReservationCustomerInfoButtonProps = {
  workspaceUid: string;
  reservation: RichReservation;
  formSettingFields: AnyField[] | undefined;
  reloadReservations: () => void;
};

export const ReservationCustomerInfoButton = ({
  workspaceUid,
  reservation,
  formSettingFields,
  reloadReservations,
}: ReservationCustomerInfoButtonProps): JSX.Element => {
  const { customer } = reservation;

  if (customer) {
    return (
      <ExistReservationCustomerInfo
        workspaceUid={workspaceUid}
        shopId={reservation.shopId}
        customer={customer}
        reloadReservations={reloadReservations}
      />
    );
  } else {
    return (
      <EmptyReservationCustomerInfo
        reservation={reservation}
        reloadReservations={reloadReservations}
        formSettingFields={formSettingFields}
      />
    );
  }
};
