/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
} from '@material-ui/core';
import React, { ChangeEvent } from 'react';
import {
  defaultFieldCondition,
  Option,
  OptionValue,
  RadioField,
} from '../../../../core/types/reservation-form-types';
import { styles } from '../ReservationEditPage';
import { FieldErrorMessage } from './FieldErrorMessageProps';
import {
  buildFieldValidation,
  fieldRequired,
  FormFieldProps,
} from './input-utils';

type RadioFormFieldProps = FormFieldProps & {
  field: RadioField;
  deletedField?: RadioField | undefined;
};
export function RadioFormField(props: RadioFormFieldProps) {
  const {
    field,
    deletedField,
    fieldResponse,
    validationContext,
    onChangeValue,
  } = props;
  const { errors, register } = validationContext;
  const fieldValidation = buildFieldValidation(
    field,
    onChangeValue === undefined
  );
  const isChecked = (option: Option) => {
    const { values } = fieldResponse;
    return (
      values.includes(option.text) ||
      values.find(
        (v) =>
          typeof v !== 'string' &&
          option.uid !== undefined &&
          option.uid === v.uid
      ) !== undefined
    );
  };

  const changeSingleValue = (textOrUidValue: string) => {
    let index = fieldResponse.values.indexOf(textOrUidValue);
    if (index === -1) {
      index = fieldResponse.values.findIndex(
        (v) => typeof v !== 'string' && textOrUidValue === v.uid
      );
    }
    if (index === -1) {
      const option = field.options.find(
        (o) => o.uid && o.uid === textOrUidValue
      );
      if (option) {
        onChangeValue?.(field, [{ uid: option.uid }]);
      } else {
        onChangeValue?.(field, [textOrUidValue]);
      }
    } else {
      onChangeValue?.(field, []);
    }
  };

  const handleChangeRadioOrSelect = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const value = e.target.value;
    changeSingleValue(value);
  };

  const ClearOption = () => (
    <div>
      <input
        id={`${field.uid}_clear-option`}
        name={field.uid}
        type="radio"
        value={undefined}
        onChange={handleChangeRadioOrSelect}
        css={css`
          display: none;
        `}
      />
      <label
        htmlFor={`${field.uid}_clear-option`}
        css={styles.clearOptionLabel}
      >
        選択をクリアする
      </label>
    </div>
  );

  const buildOption = (option: Option) => {
    return (
      <FormControlLabel
        control={
          <Radio
            inputRef={register(fieldValidation)}
            name={field.uid}
            value={option.uid || option.text}
            checked={isChecked(option)}
            required={fieldRequired(field)}
            onChange={handleChangeRadioOrSelect}
          />
        }
        label={option.text}
      />
    );
  };

  const buildDeletedOption = (option: Option) => {
    return (
      <FormControlLabel
        control={
          <Radio
            inputRef={register(fieldValidation)}
            name={field.uid}
            value={option.uid || option.text}
            checked={isChecked(option)}
            required={fieldRequired(field)}
            onChange={handleChangeRadioOrSelect}
          />
        }
        label={option.text + '(削除済み)'}
      />
    );
  };

  const buildOptions = () => {
    const checkDeletedFields = fieldResponse.values
      ?.map((value) => {
        const v: OptionValue = value as OptionValue;
        const field = deletedField?.options?.find((f) => f.uid === v.uid);
        if (field) {
          return field;
        } else {
          return null;
        }
      })
      .filter(Boolean);
    return [
      ...field.options.map((option) => buildOption(option)),
      ...checkDeletedFields
        ?.map((option) => (option ? buildDeletedOption(option) : null))
        .filter(Boolean),
    ];
  };

  const buildPulldown = () => {
    const getValue = () => {
      const value = fieldResponse.values[0];
      return value ? (typeof value === 'string' ? value : value.uid) : '';
    };
    return (
      <div>
        <select
          name={field.uid}
          multiple={false}
          value={getValue()}
          onChange={handleChangeRadioOrSelect}
          required={fieldRequired(field)}
          ref={register(fieldValidation)}
          css={styles.pulldown}
        >
          <option value="" disabled>
            選択してください
          </option>
          {field.options.map((option) => {
            return (
              <option value={option.uid || option.text} key={option.uid}>
                {option.text}
              </option>
            );
          })}
        </select>
      </div>
    );
  };

  return (
    <div css={[styles.fieldContainer, styles.optionGroup]}>
      <FormControl component="fieldset">
        <Grid container alignItems="center">
          <Grid item>
            <FormLabel component="legend">
              {field.name} {fieldRequired(field) ? '*' : ''}
            </FormLabel>
          </Grid>
          <Grid item>
            {fieldResponse.values.length > 0 &&
              fieldResponse.values[0] !== '' &&
              !(field.condition || defaultFieldCondition).internal.required && (
                <ClearOption />
              )}
          </Grid>
        </Grid>
        <RadioGroup row>
          {field.isShowPulldown ? buildPulldown() : buildOptions()}
        </RadioGroup>
        <FormHelperText>{field.description}</FormHelperText>
      </FormControl>
      <FieldErrorMessage field={field} errors={errors} />
    </div>
  );
}
