import { AxiosResponse } from 'axios';
import { UpdateCustomerRequestData } from '../../../@interfaces/crm/api';
import { API_END_POINT } from '../../../api/api';
import { useMutation } from '../../common/api/use-mutation';

export const useUpdateCustomer = (): ((
  workspaceUid: string,
  customerId: number,
  data: UpdateCustomerRequestData
) => Promise<AxiosResponse<''>>) => {
  // eslint-disable-next-line @typescript-eslint/ban-types
  const { request } = useMutation<'', {}>();
  return async (workspaceUid, customerId, data) => {
    return request(
      'PUT',
      `${API_END_POINT}/app/ws/${workspaceUid}/customers/${customerId}`,
      {},
      data
    );
  };
};
