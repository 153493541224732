import {
  Button,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from '@material-ui/core';
import Spinner from '../../../components/Spinner';
import EditIcon from '@material-ui/icons/Edit';
import { useEffect, useState } from 'react';
import { DraggableDialog } from '../../../components/DraggableDialog';
import { ResourceCreateOrUpdate } from './ResourceCreateOrUpdate';
import React from 'react';
import { ResourceWithResourceGroups } from '../../../@interfaces/resource/resource';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import { useUpdateResourceOrders } from '../api/updateResourceOrders';
import { useSnackbar } from 'notistack';
import { ResourceSchedulePattern } from '../../../@interfaces/resource/resource-schedule-pattern';
import { toPatternRangeString } from './common';
import chroma from 'chroma-js';

type ResourceListProps = {
  shopId: string;
  resourcesQuery: {
    data: ResourceWithResourceGroups[];
    isLoading: boolean;
    reload: () => void;
  };
  resourceSchedulePatternQuery: {
    data: ResourceSchedulePattern[];
    isLoading: boolean;
    reload: () => void;
  };
};

export const ResourceList: React.VFC<ResourceListProps> = (props) => {
  const { shopId, resourcesQuery, resourceSchedulePatternQuery } = props;
  const updateResourceOrders = useUpdateResourceOrders();
  const [resources, setResources] = useState<ResourceWithResourceGroups[]>([]);
  const [editData, setEditData] = useState<ResourceWithResourceGroups>();
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    setResources(resourcesQuery.data);
  }, [resourcesQuery.data]);

  if (!dialogOpen && resourcesQuery.isLoading) {
    return <Spinner loading={resourcesQuery.isLoading} />;
  }

  const handleAdd = () => {
    setEditData(undefined);
    setDialogOpen(true);
  };

  const handleEdit = (resource: ResourceWithResourceGroups) => {
    setEditData(resource);
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const reloadData = () => {
    resourcesQuery.reload();
  };

  const handleClickUp = (target: ResourceWithResourceGroups, index: number) => {
    const newResources = [...resources];
    if (index < 1) {
      return;
    }
    newResources.splice(index, 1);
    newResources.splice(index - 1, 0, target);
    setResources(newResources);
    updateResourceOrders(
      shopId,
      newResources.map((resource, index) => {
        return { ...resource, resourceOrder: index };
      })
    ).catch((e) => {
      enqueueSnackbar('リソースの順番更新に失敗しました。', {
        variant: 'error',
      });
      console.error(e);
    });
  };

  const handleClickDown = (
    target: ResourceWithResourceGroups,
    index: number
  ) => {
    const newResources = [...resources];
    if (index === newResources.length - 1) {
      return;
    }
    newResources.splice(index, 1);
    newResources.splice(index + 1, 0, target);
    setResources(newResources);
    updateResourceOrders(
      shopId,
      newResources.map((resource, index) => {
        return { ...resource, resourceOrder: index };
      })
    ).catch((e) => {
      enqueueSnackbar('リソースの順番更新に失敗しました。', {
        variant: 'error',
      });
      console.error(e);
    });
  };

  const buildResourceSchedulePattern = (
    resource: ResourceWithResourceGroups
  ) => {
    if (!resource.resourceSchedulePatternId) {
      return null;
    }

    const pattern = resourceSchedulePatternQuery.data.find(
      (pattern) => pattern.id === resource.resourceSchedulePatternId
    );
    if (!pattern) {
      return null;
    }
    const backgroundColor = chroma(pattern.color).brighten(2);
    return (
      <span
        style={{
          padding: '5px',
          border: `solid 2px ${pattern.color}`,
          backgroundColor: backgroundColor.hex(),
          borderRadius: '4px',
        }}
      >
        {pattern.name}({toPatternRangeString(pattern)})
      </span>
    );
  };

  return (
    <>
      <Grid
        container
        spacing={3}
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item>
          <h3>
            リソース({resourcesQuery.data.length})
            <span
              style={{ color: '#808080', fontSize: '12px', marginLeft: '8px' }}
            >
              ※リソースグループを作成してから、リソースを作成してください
            </span>
          </h3>
        </Grid>
        <Grid item>
          <Button onClick={handleAdd} color="primary" variant="contained">
            追加
          </Button>
        </Grid>
      </Grid>
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>リソース</TableCell>
              <TableCell>リソースグループ</TableCell>
              <TableCell>
                デフォルトの
                <br />
                稼働パターン
              </TableCell>
              <TableCell>アクション</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {resources.map((resource, index) => (
              <TableRow key={resource.id}>
                <TableCell scope="row">{resource.name}</TableCell>
                <TableCell scope="row">
                  {resource.groups.map((group, index) => (
                    <div key={index}>{group.name}</div>
                  ))}
                </TableCell>
                <TableCell scope="row">
                  {buildResourceSchedulePattern(resource)}
                </TableCell>
                <TableCell scope="row">
                  <Button
                    color="primary"
                    startIcon={<EditIcon />}
                    onClick={() => {
                      handleEdit(resource);
                    }}
                  >
                    編集
                  </Button>
                  <Tooltip title="上へ移動" style={{ marginLeft: '8px' }}>
                    <IconButton
                      size="small"
                      edge="end"
                      onClick={() => {
                        handleClickUp(resource, index);
                      }}
                    >
                      <ArrowUpwardIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="下へ移動" style={{ marginLeft: '8px' }}>
                    <IconButton
                      size="small"
                      edge="end"
                      onClick={() => {
                        handleClickDown(resource, index);
                      }}
                    >
                      <ArrowDownwardIcon />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <DraggableDialog
        open={dialogOpen}
        closeFunc={() => {
          reloadData();
          handleDialogClose();
        }}
        title={editData ? 'リソースの編集' : 'リソースの作成'}
      >
        <ResourceCreateOrUpdate
          editData={editData}
          dialogClose={handleDialogClose}
          reloadData={reloadData}
        />
      </DraggableDialog>
    </>
  );
};
