import { Shop } from '../@interfaces/shop';
import { useMutation } from '../features/common/api/use-mutation';
import dayjs from 'dayjs';
import { convertFileToBase64 } from '../utils/file';
import { API_END_POINT } from './api';

const UPLOAD_IMAGE_URL_BASE = process.env.REACT_APP_UPLOAD_IMAGE_URL_BASE ?? '';

/**
 *
 * @returns image url
 */
export const useImageUpload = (): ((
  workspaceUid: string,
  shop: Shop,
  imageFile: File
) => Promise<string>) => {
  const { request } = useMutation<'', FormData>();
  return async (workspaceUid: string, shop: Shop, imageFile: File) => {
    const uploadPath = `${shop.workspaceId}/shops/${shop.id}/${dayjs()
      .valueOf()
      .toString()}`;
    const params = new FormData();
    const json = {
      uploadPath: uploadPath,
    };

    if (process.env.REACT_APP_STAGE === 'local') {
      const fileBase64 = await convertFileToBase64(imageFile);
      params.append(
        'json',
        JSON.stringify({
          ...json,
          mimetype: imageFile.type,
          fileBase64,
        })
      );
    } else {
      params.append('json', JSON.stringify(json));
      params.append('file', imageFile);
    }
    await request(
      'POST',
      `${API_END_POINT}/app/ws/${workspaceUid}/shops/${shop.id}/image-upload`,
      {},
      params
    );
    return `${UPLOAD_IMAGE_URL_BASE}/${uploadPath}`;
  };
};
