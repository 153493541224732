/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import Spinner from '../../../components/Spinner';

export const CardSpinner = (): JSX.Element => {
  return (
    <div
      css={css`
        text-align: center;
      `}
    >
      <Spinner loading={true} />
    </div>
  );
};
