import { Breadcrumbs, Grid } from '@material-ui/core';
import React from 'react';
import useReactRouter from 'use-react-router';
import useLabels from '../../../api/use-labels';
import {
  BreadcrumbLinkItem,
  BreadcrumbTextItem,
  BreadcrumbWorkspaceItem,
} from '../../../components/breadcrumb/BreadcrumbItem';
import { WorkspacePageLayout } from '../../../components/layouts/WorkspacePageLayout';
import LinkButton from '../../../components/LinkButton';
import { PageTitleAndDescription } from '../../../components/PageTitleAndDescription';
import { Head, Main, Root } from '../../../components/Shared';
import Spinner from '../../../components/Spinner';
import { globalColors } from '../../../styles/globalColors';
import { helps } from '../../../utils/helps';
import WorkspaceLabelsTable from './WorkspaceLabelsTable';

interface PageParams {
  workspaceUid: string;
}

export default function WorkspaceLabelSettingPage(props: any) {
  const { match } = useReactRouter<PageParams>();
  const { workspaceUid } = match.params;
  const { labels, isLoadingLabels } = useLabels(workspaceUid);

  const buildContents = () => (
    <>
      <Grid container alignItems="center">
        <Grid item xs={6}>
          <h3>ラベル</h3>
        </Grid>
        <Grid
          item
          container
          xs={6}
          alignItems="center"
          justifyContent="flex-end"
        >
          <LinkButton
            to={`/a/${workspaceUid}/settings/labels/new`}
            label="ラベルを追加"
          />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item container xs={12} alignItems="center">
          {isLoadingLabels ? (
            <Spinner loading={true} />
          ) : (
            <WorkspaceLabelsTable workspaceUid={workspaceUid} labels={labels} />
          )}
        </Grid>
      </Grid>
    </>
  );

  const pageTitle = 'ラベル設定';

  return (
    <Root>
      <Head title={`ワークスペース設定 - ${pageTitle}`} />
      <Main>
        <WorkspacePageLayout
          workspaceUid={workspaceUid}
          current="label"
          breadcrumbs={
            <Breadcrumbs aria-label="breadcrumb">
              <BreadcrumbLinkItem to={`/`}>ホーム</BreadcrumbLinkItem>
              <BreadcrumbWorkspaceItem workspaceUid={workspaceUid} />
              <BreadcrumbTextItem>{pageTitle}</BreadcrumbTextItem>
            </Breadcrumbs>
          }
          helpId={helps.workspace.setting.label}
        >
          <PageTitleAndDescription
            title="ワークスペース"
            subTitle={pageTitle}
            description="顧客を識別するためのラベルの設定を行います。例：新規顧客、クレーム、入った予約に対して手動でラベルを選択できます。"
            themeColor={globalColors.workspace}
          />
          {buildContents()}
        </WorkspacePageLayout>
      </Main>
    </Root>
  );
}
