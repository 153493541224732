/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import {
  Box,
  Button,
  Checkbox,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';

import React from 'react';
import { WorkspaceCourse } from '../../../@interfaces/course';
import { SlotSettingWithCourses } from '../../../@interfaces/slot-setting';
import { SM_BREAKPOINT, useSizeType } from '../../../hooks/use-size-type';

const styles = {
  publicShopUrl: css`
    font-size: 11px;
    color: #999;
  `,
  openIcon: css`
    height: 12px;
    vertical-align: middle;
  `,
  cell: css`
    @media screen and (max-width: ${SM_BREAKPOINT}px) {
      padding: 16px 8px;
    }
  `,
  checkbox: css`
    @media screen and (max-width: ${SM_BREAKPOINT}px) {
      padding: 4px;
    }
  `,
  error: css`
    color: red;
    font-size: 12px;
  `,
};

interface Props {
  courses: WorkspaceCourse[];
  selectedSlotSettings: SlotSettingWithCourses[];
  slotSettings: SlotSettingWithCourses[];
  onChangeSelectedSlotSettings: (
    newSelectedSlotSettings: SlotSettingWithCourses[]
  ) => void;
  onEnableSlotSetting: (courseId: number) => void;
  onDisableSlotSetting: (slotSettingId: number) => void;
  onUnshareSlotSetting: (slotSettingId: number) => void;
}

export default function ShopCourseChooserTable(props: Props) {
  const {
    courses,
    slotSettings,
    selectedSlotSettings,
    onChangeSelectedSlotSettings,
    onEnableSlotSetting,
    onDisableSlotSetting,
    onUnshareSlotSetting,
  } = props;

  const { isSpSize } = useSizeType();

  const buildRows = () => {
    const enabledRows = slotSettings.map((slotSetting, index) => {
      return (
        <EnabledTableRow
          isSpSize={isSpSize}
          slotSetting={slotSetting}
          selectedSlotSettings={selectedSlotSettings}
          onChangeSelectedSlotSettings={onChangeSelectedSlotSettings}
          onDisableSlotSetting={onDisableSlotSetting}
          onUnshareSlotSetting={onUnshareSlotSetting}
          key={index}
        />
      );
    });
    const disabledRows = courses
      .filter(
        (course) =>
          slotSettings.find((s) => s.courses.find((c) => c.id == course.id)) ==
          undefined
      )
      .map((course, index) => {
        return (
          <DisabledTableRow
            isSpSize={isSpSize}
            course={course}
            onEnableSlotSetting={onEnableSlotSetting}
            key={index}
          />
        );
      });
    return (
      <>
        {enabledRows}
        {disabledRows}
      </>
    );
  };

  return (
    <TableContainer component={Paper}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell css={styles.cell}></TableCell>
            <TableCell css={styles.cell}>使用</TableCell>
            <TableCell css={styles.cell}>コース名</TableCell>
            {!isSpSize && <TableCell>アクション</TableCell>}
          </TableRow>
        </TableHead>
        <TableBody>{buildRows()}</TableBody>
      </Table>
    </TableContainer>
  );
}

function EnabledTableRow(props: {
  isSpSize: boolean;
  slotSetting: SlotSettingWithCourses;
  selectedSlotSettings: SlotSettingWithCourses[];
  onChangeSelectedSlotSettings: (
    newSelectedSlotSettings: SlotSettingWithCourses[]
  ) => void;
  onDisableSlotSetting: (slotSettingId: number) => void;
  onUnshareSlotSetting: (slotSettingId: number) => void;
}) {
  const {
    isSpSize,
    slotSetting,
    selectedSlotSettings,
    onChangeSelectedSlotSettings,
    onDisableSlotSetting,
    onUnshareSlotSetting,
  } = props;

  const isSelected =
    selectedSlotSettings.find((s) => s.id == slotSetting.id) != undefined;

  const handleChangeCheckbox = () => {
    if (isSelected) {
      const newSelectedSlotSettings = selectedSlotSettings.filter(
        (s) => s.id != slotSetting.id
      );
      onChangeSelectedSlotSettings(newSelectedSlotSettings);
    } else {
      const newSelectedSlotSettings = [...selectedSlotSettings, slotSetting];
      onChangeSelectedSlotSettings(newSelectedSlotSettings);
    }
  };

  const handleChangeSwitch = (event: React.ChangeEvent<HTMLInputElement>) => {
    onDisableSlotSetting(slotSetting.id);
  };

  const handleClickUnshare = () => {
    onUnshareSlotSetting(slotSetting.id);
  };

  return (
    <TableRow key={slotSetting.id}>
      <TableCell scope="row" css={styles.cell}>
        <Checkbox
          checked={isSelected}
          onChange={handleChangeCheckbox}
          css={styles.checkbox}
        />
      </TableCell>
      <TableCell scope="row" css={styles.cell}>
        <Switch
          checked={slotSetting.deletedAt === null}
          onChange={handleChangeSwitch}
          size="medium"
        />
      </TableCell>
      <TableCell scope="row" css={styles.cell}>
        {slotSetting.courses.map((course) => course.name).join('/')}
        {isSpSize && slotSetting.courses.length > 1 && (
          <Box
            css={css`
              margin-top: 8px;
            `}
          >
            <Button
              color="primary"
              variant="contained"
              onClick={handleClickUnshare}
            >
              共有を解除する
            </Button>
          </Box>
        )}
      </TableCell>
      {!isSpSize && (
        <TableCell scope="row" css={styles.cell}>
          {slotSetting.courses.length > 1 && (
            <Button
              color="primary"
              variant="contained"
              onClick={handleClickUnshare}
            >
              共有を解除する
            </Button>
          )}
        </TableCell>
      )}
    </TableRow>
  );
}

export function DisabledTableRow(props: {
  isSpSize: boolean;
  course: WorkspaceCourse;
  onEnableSlotSetting: (courseId: number) => void;
}) {
  const { isSpSize, course, onEnableSlotSetting } = props;

  const handleChangeSwitch = (event: React.ChangeEvent<HTMLInputElement>) => {
    onEnableSlotSetting(course.id);
  };

  return (
    <TableRow key={course.id}>
      <TableCell scope="row" css={styles.cell}></TableCell>
      <TableCell scope="row" css={styles.cell}>
        {course.formFieldCount === 0 ? (
          <span css={styles.error}>フォームの設定が必要です</span>
        ) : (
          <Switch checked={false} onChange={handleChangeSwitch} size="medium" />
        )}
      </TableCell>
      <TableCell scope="row" css={styles.cell}>
        {course.name}
      </TableCell>
      {!isSpSize && <TableCell scope="row" css={styles.cell}></TableCell>}
    </TableRow>
  );
}
