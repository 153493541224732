/** @jsxImportSource @emotion/react */

import React from 'react';
import { css } from '@emotion/react';
import { ValidationError } from '../../../core/src/types/reservation-types-validation';

interface Props {
  errors: ValidationError[];
  path: string;
}

const styles = {
  error: css`
    color: red;
  `,
};

export const ErrorMessage: React.VFC<Props> = ({ errors, path }) => {
  const error = errors.find((e) => e.path === path);
  if (!error) {
    return <></>;
  }

  return <div css={styles.error}>{error.message}</div>;
};
