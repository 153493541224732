import { ResourceListResponse } from '../../../@interfaces/resource/apis';
import { API_END_POINT } from '../../../api/api';
import { useQuery } from '../../common/api/use-query';

export const useResources = (shopId: string) => {
  const { response, isLoading, reload } = useQuery<ResourceListResponse>(
    `${API_END_POINT}/app/shops/${shopId}/resources`
  );
  return {
    data: response?.resources || [],
    isLoading: isLoading,
    reload: reload,
  };
};
