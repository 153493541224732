import { useCallback, useMemo, useState } from 'react';
import {
  PREVIEW_MESSAGE_TYPE_RESERVATION_TABLE,
  ReservationTablePreviewData,
} from '../../../@interfaces/preview/reservation-table-preview';
import { SlotRule } from '../../../core/types/reservation-types';
import { Preview } from '../components/Preview';
import { sendPreviewDataToIframe } from '../models/sendPreviewDataToIframe';

const IFRAME_ID = 'reservation-table-preview';

export const useReservationTablePreview = () => {
  const [previewOpen, setPreviewOpen] = useState(false);
  const [initialized, setInitialized] = useState(false);

  const preview = useMemo(() => {
    const previewUrl = `${process.env.REACT_APP_PUBLIC_BASE_URL}/preview/reservation-table`;
    setInitialized(true);
    return (
      <Preview
        iframeId={IFRAME_ID}
        previewUrl={previewUrl}
        previewOpen={previewOpen}
        setPreviewOpen={setPreviewOpen}
        alertMessage={
          <div>
            このプレビューには基本設定の編集中の設定のみが反映されています。
            <br />
            臨時設定、定休日設定、予約済みの情報、リソース設定、他の期間の設定などは反映されません。
          </div>
        }
      />
    );
  }, [previewOpen]);

  const updatePreview = useCallback(
    (rules: SlotRule[]) => {
      const data: ReservationTablePreviewData = {
        rules,
      };
      sendPreviewDataToIframe(
        IFRAME_ID,
        PREVIEW_MESSAGE_TYPE_RESERVATION_TABLE,
        data,
        initialized ? 1000 : 0
      );
    },
    [initialized]
  );

  return {
    preview,
    previewOpen,
    updatePreview,
  };
};
