import axios, { AxiosResponse } from 'axios';
import { useContext, useEffect, useState } from 'react';
import { ShopAttachedCustomDomainResponse } from '../../../@interfaces/custom-domain/api';
import { API_END_POINT } from '../../../api/api';
import { Store } from '../../../context/GlobalStore';

export const useCustomDomainAttachedShop = (shopId: number | undefined) => {
  const { globalState } = useContext(Store);
  const [isLoading, setLoading] = useState(false);
  const [response, setResponse] =
    useState<ShopAttachedCustomDomainResponse | null>(null);
  const reload = () => {
    if (!globalState.session?.idToken || !shopId) {
      return;
    }
    setLoading(true);
    axios
      .get(`${API_END_POINT}/app/shops/${shopId}/custom-domain`, {
        headers: {
          Authorization: globalState.session?.idToken,
        },
      })

      .then((response: AxiosResponse<ShopAttachedCustomDomainResponse>) => {
        setResponse(response.data);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    reload();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shopId, globalState.session?.idToken]);
  return {
    data: response?.customDomain || null,
    isLoading: isLoading,
    reload: reload,
  };
};
