/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import {
  Box,
  Button,
  Grid,
  IconButton,
  MenuItem,
  TextField,
} from '@material-ui/core';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Alert } from '@material-ui/lab';
import dayjs from 'dayjs';
import React, { useEffect, useMemo, useState } from 'react';
import { FieldValues, UseFormMethods } from 'react-hook-form';
import { Course } from '../../../@interfaces/course';
import {
  calcSelectedOptionalFields,
  calcTotalMinutesReqired,
} from '../../../@interfaces/course-slot-setting';
import { SelectedCustomer } from '../../../@interfaces/customer';
import { InflowSource } from '../../../@interfaces/inflow-source';
import { getInflowSourceLabel } from '../../../@interfaces/reservation';
import { assignedTypeLabels } from '../../../@interfaces/resource/reservation-resource';
import useCourseSlotSetting from '../../../api/use-course-slot-setting';
import useLabels from '../../../api/use-labels';
import { StoredReservation } from '../../../api/use-reservation';
import useReservationTimeTable from '../../../api/use-reservation-time-table';
import useShopSetting from '../../../api/use-shop-setting';
import FloatingReservationCalendar from '../../../components/calendar/FloatingReservationCalendar';
import LabelChooser from '../../../components/LabelChooser';
import {
  Field,
  FieldResponse,
  FieldResponseValue,
  FormResponse,
  FormSetting,
  Option,
  OptionValue,
  RadioField,
} from '../../../core/types/reservation-form-types';
import {
  ResourceConstant,
  ResourceTarget,
} from '../../../core/types/reservation-resource-types';
import {
  createDateTime,
  createTime,
  IDate,
  ReservedSlotCapacity,
  Time,
  TimeFormat,
  toDateStringByDate,
  toTimeStringByTime,
  zeroPadding,
} from '../../../core/types/reservation-types';
import { useResourceGroups } from '../../../features/resource';
import useStorage from '../../../hooks/use-storage';
import ReservationTimeTableView from '../../../tables/ReservationTimeTableView';
import { swap } from '../../../utils/arrays';
import { FormFields } from './FormFields';
import { InflowSourceOption } from './InflowSourceOption';
import { RadioFormField } from './input/RadioFormField';
import {
  createVirtualCapacity,
  isVirtualCapacity,
  RegistrationMode,
  SelectedResourceWithAssignedType,
  styles,
} from './ReservationEditPage';

type FormProps = {
  workspaceUid: string;
  shopId: string;
  courses: Course[];
  inflowSources: InflowSource[];
  formSetting: FormSetting;
  formResponse: FormResponse;
  note: string;
  inflowSource: string;
  selectedLabelIds: number[];
  selectedResources: SelectedResourceWithAssignedType[];
  validationContext: UseFormMethods<FieldValues>;
  selectedResourceId: number | undefined;

  selectedDate: IDate;
  onChangeSelectedDate: (newDate: IDate) => void;

  selectedCourseId: number | undefined;
  onChangeSelctedCourseId: (selectedCourseId: number | undefined) => void;

  selectedTime: Time | undefined;
  onChangeSelecteTime: (newTime: Time | undefined) => void;

  reservation: StoredReservation | undefined;

  onChangeValue?: (field: Field, newValues: FieldResponseValue[]) => void;

  onChangeNote: (newNote: string) => void;
  onChangeInflowSource: (newInfowSource: string) => void;
  onChangeSelectedLabelIds: (selectedLabelIds: number[]) => void;
  onChangeSelectedResources: (
    selectedResources: SelectedResourceWithAssignedType[]
  ) => void;
  registrationMode: RegistrationMode;
  onChangeRegistrationMode: (registrationMode: RegistrationMode) => void;
  selectedCustomer: SelectedCustomer | undefined;
  onChangeSelectedCustomer: (customer: SelectedCustomer | undefined) => void;

  timeInputRef: React.RefObject<HTMLDivElement>;
};
export function Form(props: FormProps) {
  const {
    workspaceUid,
    shopId,
    courses,
    inflowSources,
    selectedLabelIds,
    selectedResources,
    formSetting,
    formResponse,
    note,
    inflowSource,
    validationContext,
    onChangeValue,
    selectedDate,
    onChangeSelectedDate,
    selectedCourseId,
    onChangeSelctedCourseId,
    selectedTime,
    reservation,
    onChangeSelecteTime,
    onChangeNote,
    onChangeInflowSource,
    onChangeSelectedLabelIds,
    onChangeSelectedResources,
    selectedResourceId,
    registrationMode,
    onChangeRegistrationMode,
    selectedCustomer,
    onChangeSelectedCustomer,
    timeInputRef,
  } = props;
  const { courseSlotSetting } = useCourseSlotSetting(
    shopId,
    selectedCourseId ? String(selectedCourseId) : undefined
  );
  const { shopSetting } = useShopSetting(shopId);

  // selectedResourcesの初期値を設定（全て未選択）
  useEffect(() => {
    if (
      !courseSlotSetting?.resourceConstantSetting ||
      !shopSetting?.enableResource ||
      selectedResources.length > 0 ||
      selectedCourseId !== courseSlotSetting.courseId
    ) {
      return;
    }
    const targets = courseSlotSetting.resourceConstantSetting.constants.flatMap(
      (constant) => {
        return constant.targets;
      }
    );
    const newSelectedResources: SelectedResourceWithAssignedType[] =
      targets.map((target) => {
        return {
          resourceTargetUid: target.uid,
          resourceId: selectedResourceId,
          initialAssignedType: 'auto',
          assignedType: 'auto',
        };
      });
    onChangeSelectedResources(newSelectedResources);
  }, [
    selectedCourseId,
    courseSlotSetting,
    onChangeSelectedResources,
    selectedResources,
    shopSetting?.enableResource,
  ]);

  const forceMinutesRequired = useMemo(() => {
    return calcTotalMinutesReqired(
      formResponse,
      courseSlotSetting?.shopCourseSetting
    );
  }, [formResponse, courseSlotSetting?.shopCourseSetting]);

  const resourceResponse = useMemo(() => {
    return {
      resources: selectedResources.map((selectedResource) => {
        return {
          targetUid: selectedResource.resourceTargetUid,
          resourceId: selectedResource.resourceId,
        };
      }),
    };
  }, [selectedResources]);

  const selectedOptionalFields = useMemo(() => {
    return calcSelectedOptionalFields(
      formResponse,
      courseSlotSetting?.shopCourseSetting
    );
  }, [courseSlotSetting?.shopCourseSetting, formResponse]);

  const {
    reservationTimeTable,
    isLoadingReservationTimeTable,
    reloadReservationTimeTable,
  } = useReservationTimeTable(
    shopId,
    toDateStringByDate(selectedDate),
    toDateStringByDate(selectedDate),
    reservation?.reservationId,
    forceMinutesRequired,
    selectedCourseId,
    resourceResponse,
    selectedOptionalFields
  );
  useEffect(() => {
    // 連続登録の際に予約時間表を再読み込みする
    if (registrationMode === 'reloading') {
      reloadReservationTimeTable();
      onChangeRegistrationMode('none');
      return;
    }
  }, [registrationMode]);
  const { labels } = useLabels(workspaceUid);
  const resourceGroupQuery = useResourceGroups(shopId);
  const [timeDirectInput, setTimeDirectInput] = useState(false);
  const [openTimeTable, setOpenTimeTable] = useStorage('openTimeTable', false);

  const inflowSourceOptions = useMemo(() => {
    const options = inflowSources.map((s) => {
      return {
        uid: s.uid,
        name: s.name,
      } as InflowSourceOption;
    });
    options.push({
      uid: 'internal',
      name: getInflowSourceLabel('internal', inflowSources),
    });
    options.push({
      uid: 'unknown',
      name: getInflowSourceLabel('unknown', inflowSources),
    });
    const includingInfowSources = options.find((s) => s.uid == inflowSource);
    if (includingInfowSources) {
      return options;
    }
    const specialInflowSource: InflowSourceOption = {
      uid: inflowSource,
      name: getInflowSourceLabel(inflowSource, inflowSources),
    };
    const newInfowSourceOptions: InflowSourceOption[] = [
      specialInflowSource,
      ...options,
    ];
    return newInfowSourceOptions;
  }, [inflowSources, inflowSource]);

  const buildResourceField = (
    constant: ResourceConstant,
    target: ResourceTarget
  ) => {
    const resourceGroup = resourceGroupQuery?.data.find(
      (group) => group.id === target.resourceGroupId
    );
    if (!resourceGroup) {
      return null;
    }
    const anyoneOption: Option = {
      uid: '-1',
      text: '指定なし',
    };
    const selectedResource = selectedResources.find(
      (sr) => sr.resourceTargetUid === target.uid
    );
    const selectedOptionUid = selectedResource?.resourceId
      ? String(selectedResource.resourceId)
      : anyoneOption.uid;
    const fieldResponse: FieldResponse = {
      uid: target.uid,
      values: [{ uid: selectedOptionUid }],
    };
    const memberOptions: Option[] = resourceGroup.members.map((m) => {
      const text =
        selectedResource?.resourceId === m.id
          ? `${m.name}(${
              assignedTypeLabels[selectedResource.assignedType].text
            })`
          : m.name;
      return {
        uid: String(m.id),
        text,
      };
    });
    const fieldName = (() => {
      if (reservation?.reservationId && selectedResource?.initialAssignedType) {
        const initialAssignedType =
          assignedTypeLabels[selectedResource.initialAssignedType].text;
        return `${resourceGroup.name}(予約当初の指定: ${initialAssignedType})`;
      } else {
        return resourceGroup.name;
      }
    })();
    const field: RadioField = {
      uid: target.uid,
      type: 'radio',
      name: fieldName,
      options: [anyoneOption, ...memberOptions],
      condition: {
        public: {
          visibled: target.assignmentRule === 'select',
          required: target.assignmentRule === 'select',
        },
        internal: {
          required: true,
        },
      },
    };
    const handleChangeValue = (
      field: Field,
      newValues: FieldResponseValue[]
    ) => {
      const optionUid = (newValues[0] as OptionValue)?.uid;
      const resourceId =
        optionUid && optionUid !== anyoneOption.uid
          ? parseInt(optionUid)
          : undefined;

      if (selectedResource) {
        selectedResource.resourceId = resourceId;
        selectedResource.assignedType = resourceId ? 'select' : 'auto';
        onChangeSelectedResources([...selectedResources]);
      } else {
        const newSelectedResource: SelectedResourceWithAssignedType = {
          resourceTargetUid: target.uid,
          resourceId: resourceId,
          initialAssignedType: resourceId ? 'select' : 'auto',
          assignedType: resourceId ? 'select' : 'auto',
        };
        onChangeSelectedResources([...selectedResources, newSelectedResource]);
      }
    };
    const handleUpTarget = () => {
      if (!selectedResource) {
        return;
      }
      const index = selectedResources.indexOf(selectedResource);
      if (index < 1) {
        return;
      }
      const newSelectedResources = swap(selectedResources, index, index - 1);
      onChangeSelectedResources(newSelectedResources);
    };
    const handleDownTarget = () => {
      if (!selectedResource) {
        return;
      }
      const index = selectedResources.indexOf(selectedResource);
      if (index >= selectedResources.length - 1) {
        return;
      }
      const newSelectedResources = swap(selectedResources, index, index + 1);
      onChangeSelectedResources(newSelectedResources);
    };

    const sortable = constant.type === 'serial' && constant.orderRule === 'any';
    return (
      <>
        <Grid container direction="row" alignItems="center">
          <RadioFormField
            key={target.uid}
            field={field}
            fieldResponse={fieldResponse}
            onChangeValue={handleChangeValue}
            validationContext={validationContext}
          />
          {sortable && (
            <Grid item>
              <IconButton
                onClick={() => {
                  handleUpTarget();
                }}
                size="small"
              >
                <ArrowUpwardIcon />
              </IconButton>
              <IconButton
                onClick={() => {
                  handleDownTarget();
                }}
                size="small"
              >
                <ArrowDownwardIcon />
              </IconButton>
            </Grid>
          )}
        </Grid>
      </>
    );
  };

  const buildResourceConstant = (constant: ResourceConstant) => {
    const targets = [...constant.targets];
    const selectedResourceTargetUids = selectedResources.map(
      (sr) => sr.resourceTargetUid
    );
    targets.sort((a, b) => {
      const aa = selectedResourceTargetUids.indexOf(a.uid);
      const bb = selectedResourceTargetUids.indexOf(b.uid);
      return aa === bb ? 0 : aa < bb ? -1 : 1;
    });
    return targets.map((target: ResourceTarget) => {
      return buildResourceField(constant, target);
    });
  };

  const resourceFields = shopSetting?.enableResource
    ? courseSlotSetting?.resourceConstantSetting?.constants.flatMap(
        (constant) => {
          return buildResourceConstant(constant);
        }
      )
    : [];

  const handleChangeSelectedCourseId = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = e.target.value;
    setTimeDirectInput(false);
    onChangeSelctedCourseId(value ? parseInt(value) : undefined);
    onChangeSelectedResources([]);
  };

  const handleChangeSelectedDate = (date: IDate) => {
    onChangeSelectedDate(date);
    onChangeSelecteTime(undefined);
  };

  const handleChangeSelectedTime = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value as TimeFormat;
    onChangeSelecteTime(value ? createTime(value) : undefined);
  };

  const toggleTimeDirectInput = () => {
    if (timeDirectInput) {
      setTimeDirectInput(false);
    } else {
      if (!selectedTime) {
        const now = dayjs();
        const time: Time = { hour: now.hour(), minute: now.minute() };
        onChangeSelecteTime(time);
      }
      setTimeDirectInput(true);
    }
  };

  const courseTable = reservationTimeTable?.tables.find(
    (table) => table.course.id == selectedCourseId
  );
  const times = useMemo((): ReservedSlotCapacity[] => {
    // 予約可能な時間を取得
    // 他のコースで選択された時間の場合 or 予約編集時の編集前の時間の場合は選択肢になくても追加する(virtualフラグが立つ)
    // 目的：ユーザーが意図的に選択している可能性があり、選択した時間をキープするため
    const todayTable = courseTable?.dates.find((date) => {
      return (
        date.date.year == selectedDate.year &&
        date.date.month == selectedDate.month &&
        date.date.date == selectedDate.date
      );
    });
    const times =
      todayTable?.slots.filter((slot) => {
        return (
          slot.capacity.total > slot.reserved.total && slot.reserved.canReserve
        );
      }) || [];
    if (reservation?.dateTime) {
      const reservationDateTime = createDateTime(reservation.dateTime);
      const foundOriginalTime = times.some((slot) => {
        return (
          slot.slot.timeRange.start.hour === reservationDateTime.time.hour &&
          slot.slot.timeRange.start.minute === reservationDateTime.time.minute
        );
      });
      if (foundOriginalTime === false) {
        // 予約編集時に予約枠にない時間が選択されている場合、
        // 他の時間に変更しても戻せるように選択肢に追加する
        // virtualフラグが立っているため、選択肢は赤字で表示される
        const currentReservedTime = reservationDateTime.time;
        const currentReservedCapacity = createVirtualCapacity(
          currentReservedTime,
          'originalTime'
        );
        times.unshift(currentReservedCapacity);
      }
    }
    const foundSelectedTime = times.some((slot) => {
      return (
        slot.slot.timeRange.start.hour === selectedTime?.hour &&
        slot.slot.timeRange.start.minute === selectedTime?.minute
      );
    });
    if (selectedTime && foundSelectedTime === false) {
      // 予約枠にない時間が選択されている場合、選択肢に追加する
      // virtualフラグが立っているため、選択肢は赤字で表示される
      const currentReservedTime = selectedTime;
      const currentReservedCapacity = createVirtualCapacity(
        currentReservedTime,
        'selectedTime'
      );
      times.unshift(currentReservedCapacity);
    }
    return times.sort((a, b) => {
      return a.slot.timeRange.start.hour === b.slot.timeRange.start.hour
        ? a.slot.timeRange.start.minute - b.slot.timeRange.start.minute
        : a.slot.timeRange.start.hour - b.slot.timeRange.start.hour;
    });
  }, [
    courseTable?.dates,
    reservation?.dateTime,
    selectedDate.date,
    selectedDate.month,
    selectedDate.year,
    selectedTime,
  ]);

  const handleChangeNote = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    onChangeNote(newValue);
  };

  const handleChangeInflowSource = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    onChangeInflowSource(newValue);
  };

  const handleChangeSelectedLabelIds = (newSelectedLabelIds: number[]) => {
    onChangeSelectedLabelIds(newSelectedLabelIds);
  };

  const handleToggleOpenTimeTable = () => {
    setOpenTimeTable(!openTimeTable);
  };

  const handleClickCalendarCell = (course: Course, time: Time) => {
    onChangeSelctedCourseId(course.id);
    onChangeSelecteTime(time);
  };

  // 削除されていないコース＋削除済みであっても使用しているコースの一覧を選択肢として表示
  const targetCourses = useMemo(() => {
    return courses.filter((c) => !c.deletedAt || c.id === selectedCourseId);
  }, [courses, selectedCourseId]);

  return (
    <>
      <div css={styles.fieldContainer}>
        <div>
          <LabelChooser
            workspaceUid={workspaceUid}
            selectedLabelIds={selectedLabelIds}
            labels={labels}
            onChangeSelectedLabelIds={handleChangeSelectedLabelIds}
            onClose={() => {}}
            button="ラベルを設定"
          />
        </div>
        {forceMinutesRequired && (
          <Alert
            color="info"
            css={css`
              margin-top: 20px;
            `}
          >
            <strong>
              この予約の総所要時間（オプション含む）: {forceMinutesRequired}分
            </strong>
          </Alert>
        )}
        {timeDirectInput && (
          <div>
            <Alert severity="warning">
              予約可能な枠以外の時間に設定された場合、予約枠は埋まりません。（当日の予約一覧には表示されます。）
            </Alert>
          </div>
        )}
        {times.length === 0 && (
          <div>
            <Alert severity="warning">
              予約日付に予約可能な時間がありません。時間を手動で入力してください。
            </Alert>
          </div>
        )}
        <TextField
          select
          label="コース"
          css={css`
            margin-top: 20px;
            margin-right: 20px;
            width: 200px;
          `}
          onChange={handleChangeSelectedCourseId}
          required
          value={selectedCourseId}
        >
          {targetCourses.map((course) => {
            return (
              <MenuItem key={course.id} value={course.id}>
                {course.name} {course.deletedAt && '(削除済み)'}
              </MenuItem>
            );
          })}
        </TextField>
        <FloatingReservationCalendar
          shopId={shopId}
          courseId={selectedCourseId}
          currentDate={selectedDate}
          onChangeCurrentDate={handleChangeSelectedDate}
        />
        <div
          css={css`
            display: inline-block;
          `}
        >
          {times.length === 0 || timeDirectInput ? (
            <>
              <TextField
                ref={timeInputRef}
                autoFocus
                type="time"
                label="時間"
                css={css`
                  margin-top: 20px;
                  width: 100px;
                `}
                // type=timeの値は時間もゼロパディングする必要があるためフォーマットしてセット
                value={
                  selectedTime
                    ? `${zeroPadding(
                        selectedTime.hour.toString(),
                        2
                      )}:${zeroPadding(selectedTime.minute.toString(), 2)}`
                    : undefined
                }
                onChange={handleChangeSelectedTime}
              ></TextField>
              {times.length !== 0 && (
                <Button
                  color="primary"
                  css={css`
                    margin-top: 38px;
                  `}
                  size="small"
                  onClick={toggleTimeDirectInput}
                >
                  直接入力をキャンセル
                </Button>
              )}
            </>
          ) : (
            <>
              <TextField
                ref={timeInputRef}
                select
                label="時間"
                css={css`
                  margin-top: 20px;
                `}
                SelectProps={{
                  style: {
                    color:
                      // 予約枠外の時間が選択されている場合、赤字で表示(予約枠情報が取得できている場合のみ)
                      !isLoadingReservationTimeTable &&
                      times.find(
                        (t: any) =>
                          t.slot.timeRange.start.hour === selectedTime?.hour &&
                          t.slot.timeRange.start.minute ===
                            selectedTime?.minute &&
                          t.virtual
                      )
                        ? 'red'
                        : 'inherit',
                  },
                }}
                value={selectedTime ? toTimeStringByTime(selectedTime) : ''}
                onChange={handleChangeSelectedTime}
                required
              >
                <MenuItem key="" value="">
                  &nbsp;
                </MenuItem>
                {times.map((reservedSlotCapacity) => {
                  // 予約枠外の時間は赤字で表示(他のコースで選択された時間の場合 or 予約編集時の編集前の時間の場合)
                  const time = reservedSlotCapacity.slot.timeRange.start;
                  const timeString = toTimeStringByTime(time);
                  const isVirtual = isVirtualCapacity(reservedSlotCapacity)
                    ? true
                    : false;
                  return (
                    <MenuItem
                      css={css`
                        color: ${isVirtual ? 'red' : 'inherit'};
                      `}
                      key={timeString}
                      value={timeString}
                    >
                      {timeString}
                      {isVirtual ? ' (予約枠外)' : ''}
                    </MenuItem>
                  );
                })}
              </TextField>
              <Button
                color="primary"
                css={css`
                  margin-top: 38px;
                `}
                size="small"
                onClick={toggleTimeDirectInput}
              >
                予約枠外の時間を直接入力
              </Button>
            </>
          )}
        </div>
      </div>
      <div>
        <Button
          color="primary"
          size="small"
          onClick={handleToggleOpenTimeTable}
          startIcon={openTimeTable ? <ExpandMoreIcon /> : <ChevronRightIcon />}
        >
          {openTimeTable ? '予約枠テーブルを非表示' : '予約枠テーブルを表示'}
        </Button>
        {openTimeTable && reservationTimeTable && (
          <Box css={styles.tableWrapperNew}>
            <ReservationTimeTableView
              shopId={shopId}
              reservationTimeTable={reservationTimeTable}
              onClickCell={handleClickCalendarCell}
            />
          </Box>
        )}
      </div>
      {resourceFields}
      <FormFields
        workspaceUid={workspaceUid}
        shopId={shopId}
        reservation={reservation}
        formSetting={formSetting}
        formResponse={formResponse}
        validationContext={validationContext}
        onChangeValue={onChangeValue}
        selectedCustomer={selectedCustomer}
        onChangeSelectedCustomer={onChangeSelectedCustomer}
      />
      <div css={styles.fieldContainer}>
        <TextField
          label="メモ"
          helperText="この予約に関するメモ、内部的な連絡事項等を自由に登録できます。"
          multiline
          fullWidth
          value={note}
          onChange={handleChangeNote}
        />
      </div>
      <div css={styles.fieldContainer}>
        <TextField
          label="流入元"
          helperText="この予約の流入元を変更できます。電話予約等であっても流入元が特定できる場合は「内部」以外を設定してください。"
          select
          fullWidth
          value={inflowSource}
          onChange={handleChangeInflowSource}
        >
          {inflowSourceOptions.map((option) => (
            <MenuItem key={option.uid} value={option.uid}>
              {option.name}
            </MenuItem>
          ))}
        </TextField>
      </div>
    </>
  );
}
