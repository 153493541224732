/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import DeleteIcon from '@material-ui/icons/Delete';
import EventIcon from '@material-ui/icons/Event';
import { GoogleCalendarsByShopId } from '../../../@interfaces/google-oauth-token';

const styles = {
  actionCell: css`
    width: 150px;
    text-align: center;
  `,
  userName: css`
    text-align: center;
  `,
  expireText: css`
    color: red;
  `,
  calendarInfo: css`
    display: flex;
    align-items: center;
  `,
};

interface Props {
  calendars: GoogleCalendarsByShopId[];
  handleRemove: (id: number) => void;
}

export default function ShopIntegrationsSettingTable(props: Props) {
  const { calendars, handleRemove } = props;

  return (
    <TableContainer component={Paper}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>連携先カレンダー</TableCell>
            <TableCell css={styles.userName}>作成者</TableCell>
            <TableCell css={styles.actionCell}>アクション</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {calendars.map((calendar) => (
            <TableRow key={calendar.googleCalendarId}>
              <TableCell scope="row">
                <div css={styles.calendarInfo}>
                  <AccountCircleIcon />
                  {calendar.email}
                  <ArrowRightIcon />
                  <EventIcon />
                  {calendar.summary}
                </div>
              </TableCell>
              <TableCell scope="row" css={styles.userName}>
                {calendar.accountName}
              </TableCell>
              <TableCell scope="row" css={styles.actionCell}>
                <Button
                  color="primary"
                  startIcon={<DeleteIcon />}
                  onClick={() => handleRemove(calendar.googleCalendarId)}
                >
                  連携を解除
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
