/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { Drawer, IconButton, Tab, Tabs, useTheme } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useState } from 'react';
import useReactRouter from 'use-react-router';
import { CustomerActivitiesResponse } from '../../../../../frontend-api/src/interfaces/crm/api';
import { CustomerReservationsResponse } from '../../../@interfaces/crm/api';
import { RichCustomerInfo } from '../../../@interfaces/crm/backend';
import Spinner from '../../../components/Spinner';
import { useSizeType } from '../../../hooks/use-size-type';
import { useCustomerActivities } from '../api/useCustomerActivities';
import { useShopCustomerReservations } from '../api/useShopCustomerReservations';
import { CustomerActivityList } from './CustomerActivityList';
import { ReservationActivityList } from './ReservationActivityList';

const styles = {
  container: css`
    width: 400px;
    height: 100%;
    display: flex;
    flex-direction: column;
  `,
  containerForSP: css`
    width: 80dvw;
    height: 100%;
    display: flex;
    flex-direction: column;
  `,
  spinnerContainer: css`
    height: 100%;
    display: grid;
    place-content: center;
  `,
  header: (primaryColor: string) => css`
    color: #fff;
    background-color: ${primaryColor};
    padding: 0 0 0 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 1.1rem;
  `,
  listContainer: css`
    overflow: auto;
  `,
  closeButton: css`
    color: #fff;
  `,
};

type Props = {
  isOpen: boolean;
  onClose: () => void;
  customer?: RichCustomerInfo;
};

export function HistoryDrawer({
  isOpen,
  onClose,
  customer,
}: Props): JSX.Element {
  const { match } = useReactRouter<{
    shopId: string;
    customerId?: string;
  }>();

  const { shopId, customerId } = match.params;
  const {
    data: customerReservations,
    isLoading: isLoadingCustomerReservations,
  } = useShopCustomerReservations(
    shopId,
    customerId || customer?.customer.id.toString() || ''
  );
  const { data: customerActivities, isLoading: isLoadingCustomerActivities } =
    useCustomerActivities(
      shopId,
      customerId || customer?.customer.id.toString() || ''
    );
  const [tab, setTab] = useState(0);
  const handleChangeTab = (event: React.ChangeEvent<any>, newValue: number) => {
    setTab(newValue);
  };
  const { isSpSize } = useSizeType();
  const theme = useTheme();

  return (
    <Drawer anchor="right" open={isOpen} onClose={onClose}>
      {isOpen && (
        <div css={isSpSize ? styles.containerForSP : styles.container}>
          <div css={styles.header(theme.palette.primary.main)}>
            <Tabs
              value={tab}
              onChange={handleChangeTab}
              indicatorColor="primary"
            >
              <Tab
                label={`予約履歴(${
                  (customerReservations?.customerReservations || []).length
                })`}
              />
              <Tab label="更新履歴" />
            </Tabs>

            <IconButton onClick={onClose}>
              <CloseIcon css={styles.closeButton} />
            </IconButton>
          </div>
          <div css={styles.listContainer}>
            {tab === 0 && (
              <ReservationActivity
                data={customerReservations}
                isLoading={isLoadingCustomerReservations}
                customerId={
                  customerId || customer?.customer.id.toString() || ''
                }
              />
            )}
            {tab === 1 && (
              <CustomerActivity
                data={customerActivities}
                isLoading={isLoadingCustomerActivities}
              />
            )}
          </div>
        </div>
      )}
    </Drawer>
  );
}

const ReservationActivity = ({
  data,
  isLoading,
  customerId,
}: {
  data: CustomerReservationsResponse | undefined;
  isLoading: boolean;
  customerId: string;
}) => {
  return (
    <>
      {isLoading ? (
        <div css={styles.spinnerContainer}>
          <Spinner loading={true} />
        </div>
      ) : (
        <ReservationActivityList
          customerReservations={data?.customerReservations || []}
          customerId={customerId}
        />
      )}
    </>
  );
};

const CustomerActivity = ({
  data,
  isLoading,
}: {
  data: CustomerActivitiesResponse | undefined;
  isLoading: boolean;
}) => {
  return (
    <>
      {isLoading ? (
        <div css={styles.spinnerContainer}>
          <Spinner loading={true} />
        </div>
      ) : (
        <CustomerActivityList
          customerActivities={data?.customerActivities || []}
        />
      )}
    </>
  );
};
