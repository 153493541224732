import axios, { AxiosResponse } from 'axios';
import { useContext, useEffect, useState } from 'react';
import { WorkspaceInvitation as OriginalWorkspaceInvitation } from '../../../frontend-api/src/dao/workspace-invitation-dao';
import { Store } from '../context/GlobalStore';
import { API_END_POINT } from './api';

export type WorkspaceInvitation = OriginalWorkspaceInvitation;

interface WorkspaceInvitationData {
  invitations: WorkspaceInvitation[];
}

const cache: { [workspaceUid: string]: WorkspaceInvitation[] } = {};

export default function useWorkspaceInvitations(workspaceUid: string) {
  const { globalState, setGlobalState } = useContext(Store);
  const [invitations, setInvitations] = useState(
    cache[workspaceUid] || ([] as WorkspaceInvitation[])
  );
  const [loading, setLoading] = useState(cache[workspaceUid] == undefined);

  const reload = () => {
    if (!globalState.session?.idToken) {
      return;
    }
    axios
      .get(`${API_END_POINT}/app/ws/${workspaceUid}/invitations`, {
        headers: {
          Authorization: globalState.session?.idToken,
        },
      })
      .then((response: AxiosResponse<WorkspaceInvitationData>) => {
        setInvitations(response.data.invitations);
        cache[workspaceUid] = response.data.invitations;
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    reload();
  }, [workspaceUid, globalState.session?.idToken]);
  return {
    invitations,
    isLoadingInvitations: loading,
    reloadInvitations: reload,
  };
}
