import { AxiosResponse } from 'axios';
import {
  ResourceSchedules,
  UpdateResourceScheduleListRequestData,
  UpdateResourceScheduleListResponse,
} from '../../../@interfaces/resource/apis';
import { API_END_POINT } from '../../../api/api';
import { DateRange } from '../../../core/types/reservation-types';
import { useMutation } from '../../common/api/use-mutation';

export const useUpdateResourceSchedules = (): ((
  shopId: string,
  dateRange: DateRange,
  schedules: ResourceSchedules[]
) => Promise<AxiosResponse<UpdateResourceScheduleListResponse>>) => {
  const { request } = useMutation<
    UpdateResourceScheduleListResponse,
    UpdateResourceScheduleListRequestData
  >();
  return (shopId, dateRange, schedules) => {
    return request(
      'put',
      `${API_END_POINT}/app/shops/${shopId}/resource-schedules`,
      {},
      { dateRange, schedules }
    );
  };
};
