import { PaperProps, Paper, Dialog, DialogTitle } from '@material-ui/core';
import React from 'react';
import Draggable from 'react-draggable';
import { useSizeType } from '../hooks/use-size-type';

interface Props {
  open: boolean;
  closeFunc: () => void;
  title: string;
  children: React.ReactNode;
}

const PaperComponent = (props: PaperProps) => {
  const { isSpSize, isTabletSize } = useSizeType();

  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper
        {...props}
        style={{ width: isSpSize || isTabletSize ? '100vw' : '50vw' }}
      />
    </Draggable>
  );
};

export const DraggableDialog: React.VFC<Props> = ({
  open,
  closeFunc,
  title,
  children,
}) => {
  return (
    <Dialog
      open={open}
      onClose={closeFunc}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
    >
      <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
        {title}
      </DialogTitle>
      {children}
    </Dialog>
  );
};
