/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { Breadcrumbs } from '@material-ui/core';
import dayjs from 'dayjs';
import { useMemo } from 'react';
import useReactRouter from 'use-react-router';
import useShop from '../../../api/use-shop';
import {
  BreadcrumbLinkItem,
  BreadcrumbShopItem,
  BreadcrumbTextItem,
  BreadcrumbWorkspaceItem,
} from '../../../components/breadcrumb/BreadcrumbItem';
import { ShopPageLayout } from '../../../components/layouts/ShopPageLayout';
import { PageTitleAndDescription } from '../../../components/PageTitleAndDescription';
import { Head, Main, Root } from '../../../components/Shared';
import { globalColors } from '../../../styles/globalColors';
import { helps } from '../../../utils/helps';
import useInsightConversionDate from '../api/use-insight-conversion-date';
import useInsightConversionFilterItems from '../api/use-insight-conversion-filter-items';
import useInsightConversionWeekday from '../api/use-insight-conversion-weekday';
import { ConversionDateTable } from '../components/ConversionDateTable';
import { ConversionFunnelGraph } from '../components/ConversionFunnelGraph';
import { ConversionWeekdayTable } from '../components/ConversionWeekdayTable';
import { DateRangePreview } from '../components/DateRangePreview';
import { useDateRange } from '../hooks/useDateRange';
import { useItemFilter } from '../hooks/useItemFilter';
import { sumConversionAggregation } from '../utils/insight-utils';

type PageParams = {
  workspaceUid: string;
  shopId: string;
};

export default function CoursePage() {
  const { match, location } = useReactRouter<PageParams>();
  const { workspaceUid, shopId } = match.params;
  const { shop } = useShop(shopId);
  const { startDate, endDate, aggregationUnit, dateRangePicker } =
    useDateRange();
  const { courseItems, inflowSourceItems } = useInsightConversionFilterItems(
    shopId,
    startDate.format('YYYY-MM-DD'),
    endDate.format('YYYY-MM-DD')
  );
  const { itemFilter: courseItemFilter, filteredItems: courseFilteredItems } =
    useItemFilter(
      courseItems.map((i) => {
        return {
          key: i.aggregationId,
          label: i.label,
        };
      }),
      'コース'
    );
  const {
    itemFilter: inflowSourceItemFilter,
    filteredItems: inflowSourceFilteredItems,
  } = useItemFilter(
    inflowSourceItems.map((i) => {
      return {
        key: i.aggregationId,
        label: i.label,
      };
    }),
    '流入元'
  );
  const targetCourseIds = courseFilteredItems.map((i) => i.key);
  const targetInflowSources = inflowSourceFilteredItems.map((i) => i.key);
  const { results: dateResults } = useInsightConversionDate(
    shopId,
    startDate.format('YYYY-MM-DD'),
    endDate.format('YYYY-MM-DD'),
    aggregationUnit,
    targetCourseIds,
    targetInflowSources,
    targetCourseIds.length > 0 && targetInflowSources.length > 0
  );
  const { results: weekdayResults } = useInsightConversionWeekday(
    shopId,
    startDate.format('YYYY-MM-DD'),
    endDate.format('YYYY-MM-DD'),
    targetCourseIds,
    targetInflowSources,
    targetCourseIds.length > 0 && targetInflowSources.length > 0
  );
  const totalAggregation = useMemo(() => {
    return sumConversionAggregation(dateResults);
  }, [dateResults]);

  const buildContents = () => {
    return (
      <div>
        <div
          css={css`
            display: flex;
            justify-content: space-between;
            margin-bottom: 1em;
            gap: 2em;
            flex-wrap: wrap;
          `}
        >
          <div
            css={css`
              display: flex;
              gap: 2em;
              flex-wrap: wrap;
            `}
          >
            {dateRangePicker}
          </div>
          <div
            css={css`
              margin-bottom: 1em;
            `}
          >
            <DateRangePreview
              primaryStartDate={startDate}
              primaryEndDate={endDate}
              comparisonStartDate={dayjs()}
              comparisonEndDate={dayjs()}
              showComparison={false}
            />
          </div>
        </div>
        <div
          css={css`
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            gap: 2em;
            margin-bottom: 4em;
          `}
        >
          {courseItemFilter}
          {inflowSourceItemFilter}
        </div>
        <ConversionFunnelGraph totalAggregation={totalAggregation} />
        <ConversionDateTable
          results={dateResults}
          aggregationUnit={aggregationUnit}
          totalAggregation={totalAggregation}
        />
        <ConversionWeekdayTable
          results={weekdayResults}
          totalAggregation={totalAggregation}
        />
      </div>
    );
  };

  return (
    <Root>
      <Head title="統計データ（レポート）- 予約完了率詳細" />
      <Main>
        <ShopPageLayout
          workspaceUid={workspaceUid}
          shopId={shopId}
          current="insight"
          helpId={helps.customer.list}
          breadcrumbs={
            <Breadcrumbs aria-label="breadcrumb">
              <BreadcrumbLinkItem to={`/`}>ホーム</BreadcrumbLinkItem>
              <BreadcrumbWorkspaceItem workspaceUid={workspaceUid} />
              <BreadcrumbShopItem workspaceUid={workspaceUid} shop={shop} />
              <BreadcrumbLinkItem
                to={`/a/${workspaceUid}/shops/${shopId}/insight${location.search}`}
              >
                統計データ（レポート）
              </BreadcrumbLinkItem>
              <BreadcrumbTextItem>予約完了率詳細</BreadcrumbTextItem>
            </Breadcrumbs>
          }
        >
          <PageTitleAndDescription
            title="店舗"
            subTitle="統計データ（レポート）: 予約完了率詳細"
            description="選択した期間内の予約完了までの統計データの確認を行います。2024年7月12日10時以降の計測データを表示しています。"
            themeColor={globalColors.shop}
          />
          {buildContents()}
        </ShopPageLayout>
      </Main>
    </Root>
  );
}
