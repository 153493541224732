/** @jsxImportSource @emotion/react */

import React from 'react';
import {
  AnyField,
  FormSetting,
} from '../../../../core/types/reservation-form-types';
import { FormSettingFieldCheckbox } from './input/FormSettingFieldCheckbox';
import { FormSettingFieldEmail } from './input/FormSettingFieldEmail';
import { FormSettingFieldName } from './input/FormSettingFieldName';
import { FormSettingFieldNumber } from './input/FormSettingFieldNumber';
import { FormSettingFieldRadio } from './input/FormSettingFieldRadio';
import { FormSettingFieldTel } from './input/FormSettingFieldTel';
import { FormSettingFieldText } from './input/FormSettingFieldText';
import { FormSettingFieldTextarea } from './input/FormSettingFieldTextarea';

type FormSettingFieldProps = {
  setting: FormSetting;
  onChangeSetting: (setting: FormSetting) => void;
  field: AnyField;
  originalField: AnyField | undefined;
};

export const FormSettingField = (props: FormSettingFieldProps): JSX.Element => {
  const { setting, onChangeSetting, field, originalField } = props;

  if (field.type === 'text') {
    return (
      <FormSettingFieldText
        field={field}
        setting={setting}
        onChangeSetting={onChangeSetting}
        originalField={originalField}
      />
    );
  } else if (field.type === 'textarea') {
    return (
      <FormSettingFieldTextarea
        field={field}
        setting={setting}
        onChangeSetting={onChangeSetting}
        originalField={originalField}
      />
    );
  } else if (field.type === 'radio') {
    return (
      <FormSettingFieldRadio
        field={field}
        setting={setting}
        onChangeSetting={onChangeSetting}
        originalField={originalField}
      />
    );
  } else if (field.type === 'checkbox') {
    return (
      <FormSettingFieldCheckbox
        field={field}
        setting={setting}
        onChangeSetting={onChangeSetting}
        originalField={originalField}
      />
    );
  } else if (field.type === 'tel') {
    return (
      <FormSettingFieldTel
        field={field}
        setting={setting}
        onChangeSetting={onChangeSetting}
        originalField={originalField}
      />
    );
  } else if (field.type === 'email') {
    return (
      <FormSettingFieldEmail
        field={field}
        setting={setting}
        onChangeSetting={onChangeSetting}
        originalField={originalField}
      />
    );
  } else if (field.type === 'name') {
    return (
      <FormSettingFieldName
        field={field}
        setting={setting}
        onChangeSetting={onChangeSetting}
        originalField={originalField}
      />
    );
  } else if (field.type === 'number') {
    return (
      <FormSettingFieldNumber
        field={field}
        setting={setting}
        onChangeSetting={onChangeSetting}
        originalField={originalField}
      />
    );
  } else {
    return <>Invalid field type: {(field as any).type}</>;
  }
};
