/** @jsxImportSource @emotion/react */

import React from 'react';
import { Box } from '@material-ui/core';
import { css } from '@emotion/react';
import { commonCss } from './common-css';

const styles = {
  headerWrapper: css`
    display: flex;
    align-items: center;
    border: 1px solid #333333;
    border-radius: 5px;
    padding: 10px 20px;
  `,
  imageWrapper: css`
    height: 40px;
  `,
  shopName: css`
    font-weight: bold;
    font-size: 16px;
    margin-left: 8px;
  `,
};

interface Props {
  color: string;
  shopName: string;
  imageSrc?: string;
}

export const PreviewHeader: React.VFC<Props> = ({
  color,
  imageSrc,
  shopName,
}) => {
  return (
    <Box css={[styles.headerWrapper, commonCss.previewWrapper]}>
      <Box>
        {imageSrc && (
          <Box css={styles.imageWrapper}>
            <img src={imageSrc} alt="ロゴ画像" height="100%" />
          </Box>
        )}
      </Box>
      <Box css={styles.shopName} style={{ color: color }}>
        {shopName}
      </Box>
    </Box>
  );
};
