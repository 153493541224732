/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import ChatBubbleIcon from '@material-ui/icons/ChatBubble';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import EmailIcon from '@material-ui/icons/Email';
import PermContactCalendarIcon from '@material-ui/icons/PermContactCalendar';
import RestoreFromTrashIcon from '@material-ui/icons/RestoreFromTrash';
import { ReactNode } from 'react';
import {
  ActivityType,
  EmailSentActivityJson,
  LineSentActivityJson,
  ReservationActivity,
  ReservationActivityDiff,
  ReservationActivityDiffItem,
} from '../../../@interfaces/reservation-activity/reservation-activity';

const styles = {
  container: css`
    padding: 8px 0;
    display: flex;
    flex-direction: column;
  `,
  activityIconEmailSent: css`
    color: #fb9e00;
  `,
  activityIconLineSent: css`
    color: #4cc764;
  `,
  activityTitle: css`
    display: flex;
    align-items: center;
    gap: 4px;
    font-weight: bold;
    color: #333;
  `,
  activityDate: css`
    padding-left: 28px;
    color: #888888;
  `,
  activityDetail: css`
    margin-top: 6px;
    padding-left: 28px;
    color: #888888;
  `,
  diffDetail: css`
    color: #000000;
  `,
  deletedDiff: css`
    text-decoration: line-through;
  `,
};

type Props = {
  activity: ReservationActivity;
};

export function ReservationActivityListItem({ activity }: Props): JSX.Element {
  const buildActivity = (): JSX.Element => {
    switch (activity.type) {
      case ActivityType.reservationAdded: {
        return <ResAddedActivity activity={activity} />;
      }
      case ActivityType.reservationModified: {
        return (
          <ResModifiedActivity activity={activity} detail={activity.detail} />
        );
      }
      case ActivityType.reservationCanceled: {
        return <ResCanceledActivity activity={activity} />;
      }
      case ActivityType.reservationRestored: {
        return <ResRestoredActivity activity={activity} />;
      }
      case ActivityType.emailSent: {
        return (
          <MailSentActivity activity={activity} detail={activity.detail} />
        );
      }
      case ActivityType.lineSent: {
        return (
          <LineSentActivity activity={activity} detail={activity.detail} />
        );
      }
      default: {
        exhaustiveCheck(activity);
      }
    }
  };

  return (
    <div css={styles.container}>
      <div>{buildActivity()}</div>
    </div>
  );
}

function ResAddedActivity({ activity }: Props): JSX.Element {
  return (
    <>
      <div css={styles.activityTitle}>
        <PermContactCalendarIcon color="primary" />
        <div>{`${activity.operator}が予約を追加`}</div>
      </div>
      <div css={styles.activityDate}>{activity.createdAt}</div>
    </>
  );
}

function ResModifiedActivity({
  activity,
  detail,
}: {
  activity: ReservationActivity;
  detail: ReservationActivityDiff;
}): JSX.Element {
  return (
    <>
      <div css={styles.activityTitle}>
        <EditIcon color="primary" />
        <div>{`${activity.operator}が予約を変更`}</div>
      </div>
      <div css={styles.activityDate}>{activity.createdAt}</div>
      <div css={styles.activityDetail}>
        {detail.diffs.map((diff, index) => (
          <DiffItem key={index} diff={diff} />
        ))}
      </div>
    </>
  );
}

function ResCanceledActivity({ activity }: Props): JSX.Element {
  return (
    <>
      <div css={styles.activityTitle}>
        <DeleteIcon color="primary" />
        <div>{`${activity.operator}が予約をキャンセル`}</div>
      </div>
      <div css={styles.activityDate}>{activity.createdAt}</div>
    </>
  );
}

function ResRestoredActivity({ activity }: Props): JSX.Element {
  return (
    <>
      <div css={styles.activityTitle}>
        <RestoreFromTrashIcon color="primary" />
        <div>{`${activity.operator}が予約を復元`}</div>
      </div>
      <div css={styles.activityDate}>{activity.createdAt}</div>
    </>
  );
}

function MailSentActivity({
  activity,
  detail,
}: {
  activity: ReservationActivity;
  detail: EmailSentActivityJson;
}): JSX.Element {
  const buildLabel = () => {
    switch (detail.type) {
      case 'reserved': {
        return `${activity.operator}が予約完了メールを送信`;
      }
      case 'modified': {
        return `予約変更メールを送信`;
      }
      case 'canceled': {
        return `予約キャンセルメールを送信`;
      }
      case 'reminder': {
        return `${activity.operator}がリマインダーメールを送信`;
      }
      case 'custom': {
        return `${activity.operator}がメールを送信`;
      }
      default: {
        exhaustiveCheck(detail.type);
      }
    }
  };
  return (
    <>
      <div css={styles.activityTitle}>
        <EmailIcon css={styles.activityIconEmailSent} />
        <div>{buildLabel()}</div>
      </div>
      <div css={styles.activityDate}>{activity.createdAt}</div>
      <div css={styles.activityDetail}>{`送信先: ${detail.toAddress}`}</div>
    </>
  );
}

function LineSentActivity({
  activity,
  detail,
}: {
  activity: ReservationActivity;
  detail: LineSentActivityJson;
}): JSX.Element {
  const buildLabel = () => {
    switch (detail.type) {
      case 'reserved': {
        return `${activity.operator}が予約完了LINEを送信`;
      }
      case 'modified': {
        return `予約変更LINEを送信`;
      }
      case 'canceled': {
        return `予約キャンセルLINEを送信`;
      }
      case 'reminder': {
        return `${activity.operator}がリマインダーLINEを送信`;
      }
      case 'custom': {
        return `${activity.operator}がLINEを送信`;
      }
      default: {
        exhaustiveCheck(detail.type);
      }
    }
  };

  return (
    <>
      <div css={styles.activityTitle}>
        <ChatBubbleIcon css={styles.activityIconLineSent} />
        <div>{buildLabel()}</div>
      </div>
      <div css={styles.activityDate}>{activity.createdAt}</div>
      <div css={styles.activityDetail}>{`送信先: ${detail.name}`}</div>
    </>
  );
}

type DiffItemProps = {
  diff: ReservationActivityDiffItem;
};

function DiffItem({ diff }: DiffItemProps): JSX.Element {
  const buildDiffLabel = (): ReactNode => {
    if (!diff.old && diff.new) {
      return diff.new;
    }
    if (diff.old && !diff.new) {
      return <span css={styles.deletedDiff}>{diff.old}</span>;
    }
    return `${diff?.old ?? ''} → ${diff?.new ?? ''}`;
  };

  return (
    <div>
      <span>{`${diff.name}: `}</span>
      <span css={styles.diffDetail}>{buildDiffLabel()}</span>
    </div>
  );
}

function exhaustiveCheck(type: never): never {
  throw new Error(`Unhandled type: ${JSON.stringify(type)}`);
}
