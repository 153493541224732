/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogProps,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import dayjs from 'dayjs';
import 'dayjs/locale/ja';
import { useSnackbar } from 'notistack';
import React, {
  ChangeEvent,
  Fragment,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useHistory, useLocation } from 'react-router';
import { useResourceSchedules } from '../';
import { ResourceSchedules } from '../../../@interfaces/resource/apis';
import { Resource } from '../../../@interfaces/resource/resource';
import {
  ResourceSchedule,
  ResourceScheduleSetting,
} from '../../../@interfaces/resource/resource-schedule';
import { ResourceSchedulePattern } from '../../../@interfaces/resource/resource-schedule-pattern';
import {
  commonStyles,
  DayOfWeek,
} from '../../../components/calendar/calendar-styles';
import { DraggableDialog } from '../../../components/DraggableDialog';
import { ErrorMessage } from '../../../components/ErrorMessage';
import Spinner from '../../../components/Spinner';
import {
  createDate,
  createDateRange,
  DateFormat,
  DateRange,
  IDate,
  TimeRange,
  toDates,
  toDateStringByDate,
} from '../../../core/types/reservation-types';
import {
  validateTimeRange,
  validateTimeRanges,
  ValidationError,
} from '../../../core/types/reservation-types-validation';
import { useQuery } from '../../../hooks/use-query';
import { useSizeType } from '../../../hooks/use-size-type';
import { useExpandedMenu } from '../../side-menu/hooks/use-expanded-menu';
import { useUpdateResourceSchedules } from '../api/updateResourceSchedules.ts';
import { BatchSchedulePatternChooser } from './BatchSchedulePatternChooser';
import { toPatternRangeString } from './common';
import { SchedulePatternChooser } from './SchedulePatternChooser';
import { TimeRangeEditor } from './TimeRangeEditor';

const defaultStartDate = dayjs().startOf('month').format('YYYY-MM-DD');
const defaultEndDate = dayjs().endOf('month').format('YYYY-MM-DD');

type ResourceScheduleTableDatas = {
  // cellKey = `${resource.id}-${date.format('Y-M-D')}`
  [cellKey: string]: ResourceSchedule;
};

const createResourceScheduleTableDatasKey = (
  resourceId: number,
  date: IDate
): string => {
  return `${resourceId}-${date.year}-${date.month}-${date.date}`;
};

const convertResourceScheduleDatas2ResourceScheduleTableDatas = (
  resourceSchedules: ResourceSchedules[]
): ResourceScheduleTableDatas => {
  const resourceScheduleTableDatas: ResourceScheduleTableDatas = {};
  resourceSchedules.forEach((r) => {
    r.dates.forEach((d) => {
      resourceScheduleTableDatas[
        createResourceScheduleTableDatasKey(r.resource.id, createDate(d.date))
      ] = d;
    });
  });
  return resourceScheduleTableDatas;
};

const convertResourceScheduleTableDatas2ResourceScheduleDatas = (
  resources: Resource[],
  resourceScheduleTableDatas: ResourceScheduleTableDatas
): ResourceSchedules[] => {
  const ret: ResourceSchedules[] = [];
  resources.forEach((resource) => {
    const dates: ResourceSchedule[] = [];
    Object.values(resourceScheduleTableDatas).forEach((r) => {
      if (r.resourceId === resource.id) {
        dates.push(r);
      }
    });
    ret.push({
      resource,
      dates,
    });
  });
  return ret;
};

type ResourceScheduleListProps = {
  shopId: string;
};

export const ResourceScheduleList: React.VFC<ResourceScheduleListProps> = (
  props
) => {
  const { shopId } = props;
  const query = useQuery();
  const location = useLocation();
  const history = useHistory();
  const [startDate, setStartDate] = useState(
    query.get('startDate')
      ? dayjs(query.get('startDate')).format('YYYY-MM-DD')
      : defaultStartDate
  );
  const [endDate, setEndDate] = useState(
    query.get('endDate')
      ? dayjs(query.get('endDate')).format('YYYY-MM-DD')
      : defaultEndDate
  );
  const [inputStartDate, setInputStartDate] = useState(startDate);
  const [inputEndDate, setInputEndDate] = useState(endDate);
  const resourceSchedulesQuery = useResourceSchedules(
    shopId,
    startDate,
    endDate
  );
  const { isSpSize, isTabletSize } = useSizeType();

  const [resources, setResources] = useState<Resource[]>([]);

  const [resourceScheduleTableDatas, setResourceScheduleTableDatas] =
    useState<ResourceScheduleTableDatas>({});

  const updateResourceSchedule = useUpdateResourceSchedules();

  const { enqueueSnackbar } = useSnackbar();

  const { isExpandedSidebar } = useExpandedMenu();

  const styles = useMemo(() => {
    return {
      tableWrapper: css`
        width: 100%;
        overflow-x: auto;
        max-width: calc(100vw - ${isExpandedSidebar ? 335 : 140}px);
        @media screen and (max-width: 900px) {
          max-width: calc(100vw - 40px);
        }
      `,
      table: css``,
    };
  }, [isExpandedSidebar]);

  const dates = useMemo(() => {
    const dateRange: DateRange = {
      start: createDate(startDate as DateFormat),
      end: createDate(endDate as DateFormat),
    };
    return toDates(dateRange);
  }, [startDate, endDate]);

  useEffect(() => {
    history.push({
      pathname: location.pathname,
      search: '?startDate=' + startDate + '&endDate=' + endDate,
      hash: location.hash,
    });
  }, [startDate, endDate]);

  useEffect(() => {
    if (!resourceSchedulesQuery.data?.schedules) {
      setResources([]);
      setResourceScheduleTableDatas({});
    } else {
      setResources(
        resourceSchedulesQuery.data.schedules.map((s) => s.resource)
      );
      setResourceScheduleTableDatas(
        convertResourceScheduleDatas2ResourceScheduleTableDatas(
          resourceSchedulesQuery.data.schedules
        )
      );
    }
  }, [resourceSchedulesQuery.data?.schedules]);

  const handleChangeInputStartDate = (event: ChangeEvent<HTMLInputElement>) => {
    setInputStartDate(event.target.value);
  };

  const handleChangeInputEndDate = (event: ChangeEvent<HTMLInputElement>) => {
    setInputEndDate(event.target.value);
  };

  const handleChangeDate = () => {
    // inputStartDateが不正な日付であれがエラーメッセージを表示
    if (!dayjs(inputStartDate).isValid()) {
      enqueueSnackbar('開始日が不正です。', { variant: 'error' });
      return;
    }
    // inputEndDateが不正な日付であれがエラーメッセージを表示
    if (!dayjs(inputEndDate).isValid()) {
      enqueueSnackbar('終了日が不正です。', { variant: 'error' });
      return;
    }
    const diff = dayjs(inputEndDate).diff(dayjs(inputStartDate), 'day');
    if (diff < 0) {
      enqueueSnackbar('終了日は開始日より後にしてください。', {
        variant: 'error',
      });
      return;
    }
    if (diff > 62) {
      enqueueSnackbar('期間は2ヶ月以内にしてください。', { variant: 'error' });
      return;
    }
    setStartDate(inputStartDate);
    setEndDate(inputEndDate);
  };

  const updateDates = (newStartDate: string, newEndDate: string) => {
    setStartDate(newStartDate);
    setEndDate(newEndDate);
    setInputStartDate(newStartDate);
    setInputEndDate(newEndDate);
  };

  // 前の月へ移動
  const handleChangeDateRangeToLastMonth = () => {
    const newStartDate = dayjs(startDate).subtract(1, 'month').startOf('month');
    const newEndDate = newStartDate.endOf('month');
    updateDates(
      newStartDate.format('YYYY-MM-DD'),
      newEndDate.format('YYYY-MM-DD')
    );
  };

  // 今月に移動
  const handleChangeDateRangeToThisMonth = () => {
    const newStartDate = dayjs().startOf('month');
    const newEndDate = newStartDate.endOf('month');
    updateDates(
      newStartDate.format('YYYY-MM-DD'),
      newEndDate.format('YYYY-MM-DD')
    );
  };

  // 次の月へ移動
  const handleChangeDateRangeToNextMonth = () => {
    const newStartDate = dayjs(startDate).add(1, 'month').startOf('month');
    const newEndDate = newStartDate.endOf('month');
    updateDates(
      newStartDate.format('YYYY-MM-DD'),
      newEndDate.format('YYYY-MM-DD')
    );
  };

  const handleUpdate = useCallback(
    (
      resourceId: number,
      date: DateFormat,
      resourceSchedulePatternId?: number,
      schedule?: ResourceScheduleSetting
    ) => {
      setResourceScheduleTableDatas((prev) => ({
        ...prev,
        ...{
          [createResourceScheduleTableDatasKey(resourceId, createDate(date))]: {
            date,
            resourceId,
            resourceSchedulePatternId,
            schedule,
          },
        },
      }));
    },
    [setResourceScheduleTableDatas]
  );

  const handleClickSave = () => {
    const dateRange: DateRange = {
      start: createDate(startDate as DateFormat),
      end: createDate(endDate as DateFormat),
    };
    updateResourceSchedule(
      shopId,
      dateRange,
      convertResourceScheduleTableDatas2ResourceScheduleDatas(
        resources,
        resourceScheduleTableDatas
      )
    )
      .then(() => {
        enqueueSnackbar(`稼働表を保存しました。`, { variant: 'success' });
      })
      .catch((e) => {
        enqueueSnackbar('稼働表の保存に失敗しました。', { variant: 'error' });
        console.error(e);
      });
  };

  const handleSelectBatchPattern = (
    resource: Resource,
    pattern: ResourceSchedulePattern | undefined
  ) => {
    const dateRange = createDateRange(
      startDate as DateFormat,
      endDate as DateFormat
    );
    toDates(dateRange)
      .map(toDateStringByDate)
      .forEach((date) => {
        handleUpdate(resource.id, date, pattern?.id, undefined);
      });
  };

  const buildDateColumnHeader = (date: IDate) => {
    const day = dayjs(toDateStringByDate(date)).locale('ja');
    const color = commonStyles.dayOfWeek[day.day() as DayOfWeek];
    return (
      <>
        <div css={color}>{day.format('M/D')}</div>
        <div css={color}>{day.format('(ddd)')}</div>
      </>
    );
  };

  const buildDateHeader = (visibleFirstCell: boolean) => {
    return (
      <TableRow>
        <TableCell
          style={{
            minWidth: isSpSize || isTabletSize ? '100px' : '150px',
            position: 'sticky',
            left: 0,
            top: 0,
            backgroundColor: '#fff',
            zIndex: 10,
          }}
        >
          {visibleFirstCell && 'リソース'}
        </TableCell>
        {dates.map((date, index) => {
          return (
            <TableCell
              key={index}
              style={{
                backgroundColor: '#fff',
                textAlign: 'center',
              }}
            >
              {buildDateColumnHeader(date)}
            </TableCell>
          );
        })}
      </TableRow>
    );
  };

  // 何行ごとにヘッダーを出力するかの行の定数
  const HEADER_ROW_COUNT = 5;
  return (
    <>
      <h3>リソース稼働表</h3>
      <Grid
        container
        spacing={3}
        style={{ marginBottom: '10px' }}
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid
          item
          container
          xs={12}
          sm={8}
          justifyContent="flex-start"
          alignItems="center"
          style={{ width: 'fit-content' }}
        >
          <Grid item>
            期間:
            <input
              type="date"
              value={inputStartDate}
              disabled={resourceSchedulesQuery.isLoading}
              onChange={handleChangeInputStartDate}
            />
            〜
            <input
              type="date"
              value={inputEndDate}
              disabled={resourceSchedulesQuery.isLoading}
              onChange={handleChangeInputEndDate}
            />
          </Grid>
          <Grid
            item
            css={css`
              margin-left: 8px;
            `}
            style={{ marginTop: isSpSize || isTabletSize ? '16px' : '0' }}
          >
            <Button
              variant="contained"
              color="primary"
              size="small"
              disabled={resourceSchedulesQuery.isLoading}
              onClick={handleChangeDate}
            >
              日付変更
            </Button>
          </Grid>
          <Grid
            item
            css={css`
              margin-left: 8px;
            `}
            style={{ marginTop: isSpSize || isTabletSize ? '16px' : '0' }}
          >
            <Button
              variant="contained"
              color="default"
              size="small"
              disabled={resourceSchedulesQuery.isLoading}
              onClick={handleChangeDateRangeToThisMonth}
            >
              今月
            </Button>
          </Grid>
          <Grid
            item
            css={css`
              margin-left: 8px;
            `}
            style={{ marginTop: isSpSize || isTabletSize ? '16px' : '0' }}
          >
            <Button
              variant="contained"
              color="default"
              size="small"
              disabled={resourceSchedulesQuery.isLoading}
              onClick={handleChangeDateRangeToLastMonth}
            >
              ←前の月
            </Button>
          </Grid>
          <Grid
            item
            css={css`
              margin-left: 8px;
            `}
            style={{ marginTop: isSpSize || isTabletSize ? '16px' : '0' }}
          >
            <Button
              variant="contained"
              color="default"
              size="small"
              disabled={resourceSchedulesQuery.isLoading}
              onClick={handleChangeDateRangeToNextMonth}
            >
              次の月→
            </Button>
          </Grid>
          {(isSpSize || isTabletSize) && (
            <Grid
              item
              css={css`
                margin-left: 24px;
              `}
              style={{ marginTop: '16px' }}
            >
              <Button
                color="primary"
                variant="contained"
                disabled={resourceSchedulesQuery.isLoading}
                onClick={handleClickSave}
                css={css`
                  width: 100px;
                `}
              >
                保存
              </Button>
            </Grid>
          )}
        </Grid>
        {!isSpSize && !isTabletSize && (
          <Grid
            item
            container
            xs={12}
            sm={4}
            justifyContent="flex-end"
            alignItems="flex-start"
          >
            <Button
              color="primary"
              variant="contained"
              disabled={resourceSchedulesQuery.isLoading}
              onClick={handleClickSave}
              css={css`
                width: 100px;
              `}
            >
              保存
            </Button>
          </Grid>
        )}
      </Grid>
      <Grid container justifyContent="flex-end">
        <div
          style={{
            color: '#777',
          }}
        >
          ※角丸のパターンはリソースのデフォルトのパターンです。上書きしたい場合のみ変更してください。
        </div>
      </Grid>
      {resourceSchedulesQuery.isLoading ? (
        <Spinner loading={resourceSchedulesQuery.isLoading} />
      ) : (
        <Box css={styles.tableWrapper}>
          <TableContainer component={Paper} css={styles.table}>
            <Table aria-label="simple table" css={styles.table}>
              <TableHead>{buildDateHeader(true)}</TableHead>
              <TableBody>
                {resources.map((resource, index) => (
                  <Fragment key={resource.id}>
                    {index !== 0 &&
                      index % HEADER_ROW_COUNT === 0 &&
                      buildDateHeader(false)}
                    <TableRow key={resource.id}>
                      <TableCell
                        scope="row"
                        component="th"
                        style={{
                          minWidth:
                            isSpSize || isTabletSize ? '100px' : '150px',
                          position: 'sticky',
                          left: 0,
                          backgroundColor: '#fff',
                          zIndex: 1,
                        }}
                      >
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          {resource.name}
                          <BatchSchedulePatternChooser
                            patterns={
                              resourceSchedulesQuery.data?.patterns || []
                            }
                            onSelect={(pattern) => {
                              handleSelectBatchPattern(resource, pattern);
                            }}
                          />
                        </div>
                      </TableCell>
                      {dates.map((date, index) => {
                        const key = createResourceScheduleTableDatasKey(
                          resource.id,
                          date
                        );
                        const target = resourceScheduleTableDatas[key];

                        return (
                          <TableCell
                            style={{
                              padding: '0px 2px',
                              minWidth: '80px',
                              zIndex: 10,
                            }}
                            key={key}
                          >
                            <MemoScheduleEditor
                              resource={resource}
                              date={toDateStringByDate(date)}
                              resourceSchedule={target}
                              patterns={
                                resourceSchedulesQuery.data?.patterns || []
                              }
                              onUpdate={handleUpdate}
                            />
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  </Fragment>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      )}
    </>
  );
};

type ScheduleEditorProps = {
  resource: Resource;
  date: DateFormat;
  resourceSchedule?: ResourceSchedule;
  patterns: ResourceSchedulePattern[];
  onUpdate: (
    resourceId: number,
    date: DateFormat,
    resourceSchedulePatternId?: number,
    schedule?: ResourceScheduleSetting
  ) => void;
};

const ScheduleEditor: React.VFC<ScheduleEditorProps> = (props) => {
  const { resource, date, resourceSchedule, patterns, onUpdate } = props;
  const [open, setOpen] = useState(false);

  const handleChangeResourceSchedulePatternId = (
    pattern: ResourceSchedulePattern | undefined
  ) => {
    const resourceSchedulePatternId = pattern?.id;
    const schedule = resourceSchedulePatternId
      ? undefined
      : resourceSchedule?.schedule;
    onUpdate(resource.id, date, resourceSchedulePatternId, schedule);
  };

  const handleUpdateResouceSchedule = (
    timeRanges: TimeRange[] | null,
    patternId: number | null
  ) => {
    if (patternId) {
      onUpdate(resource.id, date, patternId);
    } else if (timeRanges) {
      onUpdate(resource.id, date, undefined, { timeRanges });
    } else {
      onUpdate(resource.id, date, undefined, undefined);
    }
  };

  const handleClear = () => {
    if (resource.resourceSchedulePatternId) {
      // デフォルトパターンが設定されている場合は強制的に稼働時間を空にする（終日休みと同等）
      onUpdate(resource.id, date, undefined, {
        timeRanges: [],
      });
    } else {
      // デフォルトパターンが設定されていない場合はクリア
      onUpdate(resource.id, date, undefined, undefined);
    }
  };

  const handleRestoreDefaultPattern = () => {
    if (resource.resourceSchedulePatternId) {
      // 未設定に戻すことででデフォルトパターンが適用される
      onUpdate(resource.id, date, undefined, undefined);
    }
  };

  return (
    <>
      <SchedulePatternChooser
        resource={resource}
        patterns={patterns}
        resourceSchedule={resourceSchedule}
        onClickManualScheduleItem={setOpen}
        onSelectPattern={handleChangeResourceSchedulePatternId}
        onClear={handleClear}
        onRestoreDefaultPattern={handleRestoreDefaultPattern}
      />
      <ResourceScheduleEditDialog
        open={open}
        patterns={patterns}
        initialTimeRanges={resourceSchedule?.schedule?.timeRanges}
        title={`稼働時間(${resource.name}, ${dayjs(date).format('M/D')})`}
        closeFunc={() => {
          setOpen(false);
        }}
        onUpdateResourceSchedule={handleUpdateResouceSchedule}
        initialSelectedPatternId={resourceSchedule?.resourceSchedulePatternId}
      />
    </>
  );
};

const MemoScheduleEditor = memo(ScheduleEditor, (prev, next) => {
  return (
    prev.resource.id === next.resource.id &&
    prev.date === next.date &&
    prev.resourceSchedule === next.resourceSchedule &&
    prev.patterns.length === next.patterns.length &&
    prev.onUpdate === next.onUpdate
  );
});

const RADIO_PATTERN = 'pattern';
const RADIO_MANUAL = 'manual';
const defaultTimeRange = {
  start: { hour: 10, minute: 0 },
  end: { hour: 18, minute: 0 },
};

// 店舗画面(/shops/{shopId})からも稼働表の更新ができるようにexport化
export const ResourceScheduleEditDialog = ({
  patterns,
  initialTimeRanges,
  title,
  closeFunc,
  onUpdateResourceSchedule,
  initialSelectedPatternId,
  ...props
}: DialogProps & {
  patterns: ResourceSchedulePattern[];
  initialTimeRanges?: TimeRange[];
  initialSelectedPatternId?: number;
  title: string;
  closeFunc: () => void;
  onUpdateResourceSchedule: (
    timeRanges: TimeRange[] | null,
    patternId: number | null
  ) => void;
}): JSX.Element => {
  const [radio, setRadio] = useState(
    initialSelectedPatternId ? RADIO_PATTERN : RADIO_MANUAL
  );
  const [selectedPatternId, setSelectedPatternId] = useState<string>(
    initialSelectedPatternId
      ? initialSelectedPatternId.toString()
      : `${patterns[0]?.id || ''}`
  );
  const [timeRanges, setTimeRanges] = useState<TimeRange[]>(
    initialTimeRanges ?? [defaultTimeRange]
  );
  const [errors, setErrors] = useState<ValidationError[]>([]);

  const removeTimeRange = (index: number) => {
    setTimeRanges(timeRanges.filter((_, i) => i !== index));
  };

  const detachResourceSchedule = () => {
    onUpdateResourceSchedule(null, null);
    closeFunc();
  };

  const confirmResourceSchedule = () => {
    if (radio === RADIO_PATTERN) {
      if (!selectedPatternId) {
        return;
      }
      onUpdateResourceSchedule(null, parseInt(selectedPatternId));
    } else {
      onUpdateResourceSchedule(timeRanges, null);
    }
    closeFunc();
  };

  const handleChangeTimerange = (index: number) => {
    return (timeRange: TimeRange) => {
      setTimeRanges(
        timeRanges.map((t, i) => {
          if (i === index) {
            return timeRange;
          }
          return t;
        })
      );
      setErrors([
        ...validateTimeRange(timeRange, 0, 0, index),
        ...validateTimeRanges(timeRanges, 0, 0),
      ]);
    };
  };

  const addTimeRange = () => {
    setTimeRanges([...timeRanges, defaultTimeRange]);
    setErrors([...validateTimeRanges([...timeRanges, defaultTimeRange], 0, 0)]);
  };
  return (
    <DraggableDialog open={props.open} closeFunc={closeFunc} title={title}>
      <DialogContent>
        <FormControl component="fieldset" fullWidth>
          <RadioGroup
            name="setting"
            value={radio}
            onChange={(e) => {
              setRadio(e.target.value);
            }}
          >
            <FormControlLabel
              value={RADIO_PATTERN}
              control={<Radio />}
              label="稼働パターンから指定"
            />
            <TextField
              select
              value={selectedPatternId}
              onChange={(e) => {
                setSelectedPatternId(e.target.value);
              }}
              disabled={radio !== RADIO_PATTERN}
              css={css`
                background-color: ${patterns.find(
                  (p) => p.id === parseInt(selectedPatternId ?? '0')
                )?.color ?? 'inherit'};
              `}
            >
              {patterns.map((pattern) => (
                <MenuItem
                  key={pattern.id}
                  value={pattern.id}
                  css={css`
                    background-color: ${pattern.color} !important;
                  `}
                >
                  {pattern.name}({toPatternRangeString(pattern)})
                </MenuItem>
              ))}
            </TextField>
            <FormControlLabel
              value={RADIO_MANUAL}
              control={<Radio />}
              label="時間指定"
            />
            <Box>
              {timeRanges.map((timeRange, index) => (
                <Grid
                  container
                  justifyContent="flex-start"
                  alignItems="center"
                  key={index}
                >
                  <Grid item>
                    <TimeRangeEditor
                      timeRange={timeRange}
                      onChange={handleChangeTimerange(index)}
                    />
                    <ErrorMessage
                      errors={errors}
                      path={`rules/0/schedules/0/timeRanges/${index}`}
                    />
                  </Grid>
                  {index > 0 && (
                    <Grid item>
                      <IconButton
                        onClick={() => {
                          removeTimeRange(index);
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Grid>
                  )}
                </Grid>
              ))}
              <Box mt={2}>
                <Button color="primary" variant="text" onClick={addTimeRange}>
                  時間帯を追加
                </Button>
              </Box>
            </Box>
          </RadioGroup>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid
            item
            css={css`
              margin-right: 32px;
            `}
          >
            <Button
              variant="contained"
              onClick={detachResourceSchedule}
              disabled={!initialSelectedPatternId && !initialTimeRanges}
            >
              未設定に戻す
            </Button>
          </Grid>
          <Grid
            item
            container
            justifyContent="flex-end"
            alignItems="center"
            css={css`
              width: fit-content;
            `}
          >
            <Button
              color="primary"
              variant="outlined"
              css={css`
                margin-right: 16px;
              `}
              onClick={() => {
                closeFunc();
              }}
            >
              キャンセル
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={confirmResourceSchedule}
              disabled={errors.length >= 1}
            >
              保存
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </DraggableDialog>
  );
};
