import {
  Button,
  Grid,
  IconButton,
  MenuItem,
  TextField,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { ResourceGroupWithMember } from '../../../../@interfaces/resource/resource-group';
import {
  PararelResourceConstant,
  PararelResourceTarget,
} from '../../../../core/types/reservation-resource-types';
import { createDefaultPararelResourceTarget } from '../../models/resource-constant';
import { SelectAssignmentRule } from './SelectAssignmentRule';

type PararelResourceConstantEditorProps = {
  constant: PararelResourceConstant;
  resourceGroups: ResourceGroupWithMember[];
  onUpdate: () => void;
};

export const PararelResourceConstantEditor: React.VFC<
  PararelResourceConstantEditorProps
> = (props) => {
  const { constant, resourceGroups, onUpdate } = props;

  const handleClickAddResourceGroup = () => {
    const target = createDefaultPararelResourceTarget();
    constant.targets.push(target);
    onUpdate();
  };

  const handleUpdateTarget = (target: PararelResourceTarget) => {
    onUpdate();
  };

  const handleDeleteTarget = (target: PararelResourceTarget) => {
    constant.targets = constant.targets.filter((t) => t !== target);
    onUpdate();
  };

  return (
    <Grid container item>
      {constant.targets.map((target) => {
        return (
          <TargetEditor
            target={target}
            resourceGroups={resourceGroups}
            onUpdateTarget={handleUpdateTarget}
            onDeleteTarget={handleDeleteTarget}
          />
        );
      })}
      <Button color="primary" onClick={handleClickAddResourceGroup}>
        割り当てるリソースグループを追加
      </Button>
    </Grid>
  );
};

type TargetEditorProps = {
  target: PararelResourceTarget;
  resourceGroups: ResourceGroupWithMember[];
  onUpdateTarget: (target: PararelResourceTarget) => void;
  onDeleteTarget: (target: PararelResourceTarget) => void;
};

const TargetEditor: React.VFC<TargetEditorProps> = (props) => {
  const { target, resourceGroups, onUpdateTarget, onDeleteTarget } = props;

  const handleChangeResourceGroupId = (
    event: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    const resourceGroupId = event.target.value as number;
    target.resourceGroupId = resourceGroupId;
    onUpdateTarget(target);
  };

  const handleChangeAssignmentRule = () => {
    onUpdateTarget(target);
  };

  const handleDeleteTarget = () => {
    onDeleteTarget(target);
  };

  return (
    <Grid container item xs={12} alignItems="center">
      <TextField
        select
        label="リソースグループ"
        value={
          target.resourceGroupId === -1 ? undefined : target.resourceGroupId
        }
        onChange={handleChangeResourceGroupId}
        style={{ minWidth: '200px' }}
        required
      >
        {resourceGroups.map((resourceGroup) => (
          <MenuItem key={resourceGroup.id} value={resourceGroup.id}>
            {resourceGroup.name}
          </MenuItem>
        ))}
      </TextField>
      <SelectAssignmentRule
        target={target}
        onUpdate={handleChangeAssignmentRule}
      />
      <IconButton onClick={handleDeleteTarget} size="small">
        <DeleteIcon />
      </IconButton>
    </Grid>
  );
};
