/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import {
  Button,
  Checkbox,
  Chip,
  FormControl,
  FormLabel,
  TextField,
} from '@material-ui/core';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import { Autocomplete } from '@material-ui/lab';
import { useState } from 'react';
import { colors } from '../utils/insight-utils';

// TODO: URL 同期する？
export const useItemFilter = (
  originalItems: {
    key: string;
    label: string;
  }[],
  itemLabel: string
) => {
  const [autoCompleteText, setAutoCompleteText] = useState('');
  const [selectedItemIds, setSelectedItemIds] = useState<any[] | undefined>(
    undefined
  );

  const itemFilter = (
    <FormControl
      component="fieldset"
      css={css`
        width: 400px;
      `}
    >
      <div
        css={css`
          display: flex;
          align-items: center;
          justify-content: space-between;
        `}
      >
        <FormLabel
          css={css`
            margin-bottom: 0.5em;
          `}
          component="legend"
        >
          {`${itemLabel}のフィルタ`}
        </FormLabel>
        {selectedItemIds !== undefined &&
          selectedItemIds.length !== originalItems.length && (
            <Button
              css={css`
                width: 100px;
                height: 24px;
              `}
              color="primary"
              onClick={() => {
                setSelectedItemIds(undefined);
              }}
            >
              すべて選択
            </Button>
          )}
      </div>
      <Autocomplete
        fullWidth
        multiple
        onChange={(_e, value) => {
          if (value.length) {
            setSelectedItemIds(value.map((v) => v.key));
          }
        }}
        options={originalItems}
        disableClearable
        disableCloseOnSelect
        getOptionLabel={(option) => option.label}
        value={
          selectedItemIds === undefined
            ? originalItems
            : originalItems.filter((key) => selectedItemIds?.includes(key.key))
        }
        inputValue={autoCompleteText}
        onInputChange={(_event, value, reason) => {
          if (reason !== 'reset') {
            setAutoCompleteText(value);
          }
        }}
        renderOption={(option, state) => {
          const index = originalItems.findIndex(
            (item) => item.key === option.key
          );
          return (
            <div
              css={css`
                display: flex;
                align-items: center;
              `}
            >
              <Checkbox
                icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                checkedIcon={<CheckBoxIcon fontSize="small" />}
                style={{ marginRight: 8 }}
                checked={selectedItemIds === undefined ? true : state.selected}
              />
              <span
                css={css`
                  flex: 0 0 20px;
                  display: inline-block;
                  background-color: ${colors[index % colors.length]};
                  width: 20px;
                  height: 20px;
                  margin-right: 8px;
                `}
              />
              {option.label}
            </div>
          );
        }}
        renderInput={(params) => <TextField {...params} variant="standard" />}
        renderTags={(value, _getTagProps) => (
          <Chip
            label={
              value.length === originalItems.length
                ? `全ての${itemLabel}を選択中`
                : `${value.length} 件選択中`
            }
            onDelete={() => {
              setSelectedItemIds([]);
            }}
          />
        )}
        noOptionsText={`一致する${itemLabel}はありません`}
      />
    </FormControl>
  );

  return {
    itemFilter,
    filteredItems: originalItems.filter((i) =>
      selectedItemIds === undefined ? true : selectedItemIds?.includes(i.key)
    ),
  };
};
