import axios, { AxiosResponse } from 'axios';
import { useContext, useEffect, useState } from 'react';
import { Store } from '../../../context/GlobalStore';

/**
 * GETリクエストを発行して結果を返します。
 * @param url URL: nullの場合、リクエストは発行しない
 * @param queryParams クエリー文字列
 * @returns
 */
export const useQuery = <T>(
  url: string | null,
  queryParams?: any
): {
  response: T | undefined;
  isLoading: boolean;
  reload: () => void;
} => {
  const { globalState } = useContext(Store);
  const [response, setResponse] = useState<T>();
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    reload();
  }, [JSON.stringify(queryParams), globalState.session?.idToken]);

  const reload = () => {
    if (!globalState.session?.idToken) {
      return;
    }
    if (!url) {
      setLoading(false);
      return;
    }
    const params = queryParams || {};
    setLoading(true);
    axios
      .get(url, {
        params,
        headers: {
          Authorization: globalState.session?.idToken,
        },
      })
      .then((response: AxiosResponse<T>) => {
        setResponse(response.data);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return {
    response,
    isLoading: loading,
    reload,
  };
};

export type QueryResult<T> = {
  data: T;
  isLoading: boolean;
  reload: () => void;
};
