/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { Breadcrumbs, Button, Grid } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React from 'react';
import useReactRouter from 'use-react-router';
import { Shop } from '../../@interfaces/shop';
import useShops from '../../api/use-shops';
import useWorkspaceAccounts from '../../api/use-workspace-accounts';
import useWorkspaceCourses from '../../api/use-workspace-courses';
import {
  BreadcrumbLinkItem,
  BreadcrumbTextItem,
} from '../../components/breadcrumb/BreadcrumbItem';
import { WorkspacePageLayout } from '../../components/layouts/WorkspacePageLayout';
import LinkButton from '../../components/LinkButton';
import { PageTitleAndDescription } from '../../components/PageTitleAndDescription';
import { ReservationDataAllAffiliatedShopDownloadButton } from '../../components/ReservationDataAllAffiliatedShopDownloadButton';
import { Head, Main, Root } from '../../components/Shared';
import Spinner from '../../components/Spinner';
import { ROLE_ADMIN } from '../../context/GlobalStore';
import useCurrentWorkspace from '../../context/use-current-workspace';
import { SM_BREAKPOINT } from '../../hooks/use-size-type';
import useStorage from '../../hooks/use-storage';
import { globalColors } from '../../styles/globalColors';
import ShopsTable from '../../tables/ShopsTable';
import { helps } from '../../utils/helps';

type PageParams = {
  workspaceUid: string;
};

const styles = {
  breadCrumb: css`
    @media screen and (max-width: ${SM_BREAKPOINT}px) {
      font-size: 14px;
    }
  `,
  gridWrapper: css`
    @media screen and (max-width: ${SM_BREAKPOINT}px) {
      margin-bottom: 0;
    }
  `,
  reservationOutputButtonWrapper: css`
    display: flex;
    justify-content: flex-end;
    margin-bottom: 12px;
  `,
};

export default function WorkspaceDashboardPage() {
  const { match } = useReactRouter<PageParams>();
  const { workspaceUid } = match.params;
  const currentWorkspace = useCurrentWorkspace(workspaceUid);

  return (
    <Root>
      <Head title="店舗一覧" />
      <Main>
        <WorkspacePageLayout
          workspaceUid={workspaceUid}
          current="shops"
          breadcrumbs={
            <Breadcrumbs aria-label="breadcrumb">
              <BreadcrumbLinkItem to={`/`}>ホーム</BreadcrumbLinkItem>
              <BreadcrumbTextItem>{currentWorkspace?.name}</BreadcrumbTextItem>
            </Breadcrumbs>
          }
          helpId={helps.dashboard.shops}
        >
          <PageTitleAndDescription
            title="ワークスペース"
            subTitle="店舗一覧"
            description="設定店舗の一覧です。"
            themeColor={globalColors.workspace}
          />
          <div css={styles.reservationOutputButtonWrapper}>
            <ReservationDataAllAffiliatedShopDownloadButton
              workspaceUid={workspaceUid}
            />
          </div>
          <ShopList workspaceUid={workspaceUid} />
        </WorkspacePageLayout>
      </Main>
    </Root>
  );
}

export function ShopList({ workspaceUid }: { workspaceUid: string }) {
  const currentWorkspace = useCurrentWorkspace(workspaceUid);

  const isAdmin = currentWorkspace
    ? currentWorkspace.role === ROLE_ADMIN
    : false;
  const { shops, isLoadingShops } = useShops(workspaceUid);

  return (
    <>
      <Grid container spacing={3} alignItems="center" css={styles.gridWrapper}>
        <Grid item container xs={6} alignItems="center">
          <h3>店舗一覧 {isLoadingShops ? '' : `(${shops.length})`}</h3>
        </Grid>
        <Grid
          item
          container
          xs={6}
          alignItems="center"
          justifyContent="flex-end"
        >
          {isAdmin ? (
            <LinkButton
              to={`/a/${workspaceUid}/shops/new`}
              label="店舗を追加"
            />
          ) : null}
        </Grid>
      </Grid>
      {isLoadingShops ? (
        <Spinner loading={isLoadingShops} />
      ) : (
        <>
          {isAdmin ? (
            <OnboardingNavigation workspaceUid={workspaceUid} shops={shops} />
          ) : null}
          <ShopsTable workspaceUid={workspaceUid} shops={shops} />
        </>
      )}
    </>
  );
}

const useOnboardingStart = (workspaceUid: string) => {
  return useStorage(`ws_onboarding_start:${workspaceUid}`, false);
};

const useOnboardingInvite = (workspaceUid: string) => {
  return useStorage(`ws_onboarding_invite:${workspaceUid}`, true);
};

const OnboardingNavigation = ({
  workspaceUid,
  shops,
}: {
  workspaceUid: string;
  shops: Shop[];
}): JSX.Element | null => {
  const { history } = useReactRouter<PageParams>();
  const [onboardingStart, setOnboardingStart] =
    useOnboardingStart(workspaceUid);
  const [invite, setInvite] = useOnboardingInvite(workspaceUid);
  const { courses, isLoadingCourses } = useWorkspaceCourses(workspaceUid);
  const { workspaceAccounts, isLoadingWorkspaceAccounts } =
    useWorkspaceAccounts(workspaceUid);

  const handleClickAddShop = () => {
    setOnboardingStart(true);
    history.push(`/a/${workspaceUid}/shops/new`);
  };

  const handleClickAddCourse = () => {
    setOnboardingStart(true);
    history.push(`/a/${workspaceUid}/settings/courses/new`);
  };

  const handleClickAddMember = () => {
    setOnboardingStart(true);
    history.push(`/a/${workspaceUid}/settings/member`);
  };

  const handleClickCancelInvite = () => {
    setOnboardingStart(true);
    setInvite(false);
  };

  const handleClickClose = () => {
    setOnboardingStart(false);
  };

  if (
    isLoadingCourses ||
    isLoadingWorkspaceAccounts ||
    (shops.length !== 0 && courses.length !== 0 && !onboardingStart)
  ) {
    return null;
  } else if (shops.length === 0) {
    return (
      <div
        css={css`
          margin-bottom: 20px;
        `}
      >
        <Alert severity="info">
          <strong>Step 1. まずは店舗を作成しましょう。</strong>
          <div>
            最初に店舗を作成しましょう。店舗単位で、使用するコースや予約枠、Web予約画面の管理が可能です。
            <div
              css={css`
                margin-top: 20px;
              `}
            >
              <Button
                variant="contained"
                color="primary"
                size="medium"
                onClick={handleClickAddShop}
              >
                店舗を追加する
              </Button>
            </div>
          </div>
        </Alert>
        <Alert
          severity="info"
          css={css`
            background-color: #ddd;
          `}
        >
          Step 2. 次にコースを作成しましょう。
        </Alert>
        <Alert
          severity="info"
          css={css`
            background-color: #ddd;
          `}
        >
          Step 3. メンバーを招待しましょう。
        </Alert>
      </div>
    );
  } else if (courses.length === 0) {
    return (
      <div
        css={css`
          margin-bottom: 20px;
        `}
      >
        <Alert severity="success">Step 1. まずは店舗を作成しましょう。</Alert>
        <Alert severity="info">
          <strong>Step 2. 次にコースを作成しましょう。</strong>
          <div>
            予約のコースを作成します。作成したコースは店舗設定で店舗ごとに使用するかどうかを設定することができます。
            <div
              css={css`
                margin-top: 20px;
              `}
            >
              <Button
                variant="contained"
                color="primary"
                size="medium"
                onClick={handleClickAddCourse}
              >
                コースを追加する
              </Button>
            </div>
          </div>
        </Alert>
        <Alert
          severity="info"
          css={css`
            background-color: #ddd;
          `}
        >
          Step 3.メンバーを招待しましょう。
        </Alert>
      </div>
    );
  } else if (workspaceAccounts.length === 1 && invite) {
    return (
      <div
        css={css`
          margin-bottom: 20px;
        `}
      >
        <Alert severity="success">Step 1. まずは店舗を作成しましょう。</Alert>
        <Alert severity="success">Step 2. 次にコースを作成しましょう。</Alert>
        <Alert severity="info">
          <strong>Step 3. メンバーを招待しましょう。</strong>
          <div>
            Locaop予約を一緒に利用する利用するメンバーをワークスペースに招待しましょう。
            <div
              css={css`
                margin-top: 20px;
              `}
            >
              <Button
                variant="contained"
                color="primary"
                size="medium"
                onClick={handleClickAddMember}
              >
                メンバーを招待する
              </Button>
              <Button
                variant="text"
                color="primary"
                size="medium"
                onClick={handleClickCancelInvite}
              >
                今はメンバーを招待しない
              </Button>
            </div>
          </div>
        </Alert>
      </div>
    );
  } else {
    return (
      <div
        css={css`
          margin-bottom: 20px;
        `}
      >
        <Alert severity="success">Step 1. まずは店舗を作成しましょう。</Alert>
        <Alert severity="success">Step 2. 次にコースを作成しましょう。</Alert>
        <Alert severity="success">Step 3. メンバーを招待しましょう。</Alert>
        <Alert severity="info">
          全体の設定は完了です。店舗名をクリックして店舗の設定に進みましょう。
          <Button
            variant="text"
            color="primary"
            size="medium"
            onClick={handleClickClose}
          >
            この説明を閉じる
          </Button>
        </Alert>
      </div>
    );
  }
};
