import axios from 'axios';
import { useContext } from 'react';
import useSWR from 'swr';
import { Store } from '../context/GlobalStore';
import { API_END_POINT } from './api';

type UseWorkspaceFeatureResponseType = {
  workspaceFeature: {
    id: string;
    workspaceId: string;
    isSmsEnabled: number;
    isCustomDomainEnabled: number;
  };
  isLoadingWorkspaceFeature: boolean;
};

const fetcher = async ([url, token]: [string, string]) => {
  const config = {
    headers: {
      Authorization: token,
    },
  };
  const response = await axios.get(url, config);
  return response.data;
};

export const useWorkspaceFeature = (
  workspaceUid: string
): UseWorkspaceFeatureResponseType => {
  const { globalState } = useContext(Store);

  // SWR
  const url = `${API_END_POINT}/app/ws/${workspaceUid}/feature-control`;
  const token = globalState.session?.idToken;
  const swrKey = token ? [url, token] : null;
  const { data, isLoading } = useSWR(swrKey, { fetcher });

  return {
    workspaceFeature: data?.workspaceFeature,
    isLoadingWorkspaceFeature: isLoading,
  };
};
