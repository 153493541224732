/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import {
  Breadcrumbs,
  Button,
  FormControlLabel,
  Grid,
  Paper,
  Switch,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import React, { ChangeEvent, useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import useReactRouter from 'use-react-router';
import { API_END_POINT } from '../../../api/api';
import useWorkspaceSetting from '../../../api/use-workspace-setting';
import {
  BreadcrumbLinkItem,
  BreadcrumbTextItem,
  BreadcrumbWorkspaceItem,
} from '../../../components/breadcrumb/BreadcrumbItem';
import { commonCss } from '../../../components/common-css';
import { WorkspacePageLayout } from '../../../components/layouts/WorkspacePageLayout';
import { PageTitleAndDescription } from '../../../components/PageTitleAndDescription';
import {
  FullscreenLoading,
  Head,
  Main,
  Root,
} from '../../../components/Shared';
import Spinner from '../../../components/Spinner';
import { useStyles } from '../../../components/Styles';
import { Store } from '../../../context/GlobalStore';
import useCurrentWorkspace from '../../../context/use-current-workspace';
import { globalColors } from '../../../styles/globalColors';
import { helps } from '../../../utils/helps';

interface PageParams {
  workspaceUid: string;
}

type Form = {
  enableCrm: boolean;
};

export default function WorkspaceCrmSettingPage(props: any) {
  const { globalState, setGlobalState } = useContext(Store);
  const { match } = useReactRouter<PageParams>();
  const { workspaceUid } = match.params;
  const validationContext = useForm();
  const { handleSubmit } = validationContext;
  const styles = useStyles();
  const workspace = useCurrentWorkspace(workspaceUid);
  const {
    workspaceSetting,
    isLoadingWorkspaceSetting,
    reloadWorkspaceSetting,
  } = useWorkspaceSetting(workspaceUid);

  const [form, setForm] = useState<Form>({
    enableCrm: false,
  });
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (!workspaceSetting) {
      return;
    }
    setForm({
      enableCrm: Boolean(workspaceSetting.enableCrm),
    });
  }, [workspaceSetting]);

  const handleChangeEnableCrm = (e: ChangeEvent<HTMLInputElement>) => {
    setForm({ ...form, enableCrm: e.target.checked });
  };

  const handleSubmitForm = async (e: React.FormEvent) => {
    const json = {
      enableCrm: form.enableCrm,
    };

    const headers: any = {
      Authorization: globalState.session?.idToken,
    };

    setOpenBackdrop(true);
    axios
      .post(`${API_END_POINT}/app/ws/${workspaceUid}/setting/crm`, json, {
        headers,
      })
      .then(() => {
        enqueueSnackbar('設定を保存しました。', { variant: 'success' });
        reloadWorkspaceSetting();
      })
      .catch((e) => {
        enqueueSnackbar('設定が保存できませんでした。', { variant: 'error' });
      })
      .finally(() => {
        setOpenBackdrop(false);
      });
  };

  const buildRightNavigationButtons = () => {
    return (
      <>
        <Button
          css={commonCss.button.right}
          type="submit"
          variant="contained"
          color="primary"
        >
          &nbsp;保存&nbsp;
        </Button>
      </>
    );
  };

  const buildContents = () => {
    if (isLoadingWorkspaceSetting || !workspaceSetting) {
      return <Spinner loading={true} />;
    }
    return (
      <form onSubmit={handleSubmit(handleSubmitForm)}>
        <Grid container style={{ marginTop: '20px' }}>
          <Grid item xs={6}>
            <h3>顧客管理</h3>
          </Grid>
          <Grid
            item
            container
            xs={6}
            alignItems="center"
            justifyContent="flex-end"
          ></Grid>
        </Grid>
        <Alert severity="info">
          顧客管理機能を有効にすることで次のことが可能になります。
          <ul>
            <li>顧客の登録、更新、削除</li>
            <li>顧客一覧から顧客の検索して詳細情報の閲覧</li>
            <li>予約登録/更新時に顧客を紐づけ</li>
            <li>顧客ごとの予約履歴の確認</li>
          </ul>
        </Alert>
        <Paper className={styles.paper}>
          <FormControlLabel
            style={{ width: '100%' }}
            control={
              <Switch
                checked={form.enableCrm}
                onChange={handleChangeEnableCrm}
              />
            }
            label="顧客管理機能を利用する"
          />
        </Paper>
        <Grid
          container
          css={css`
            margin-top: 20px;
          `}
        >
          <Grid item xs={6}></Grid>
          <Grid
            item
            container
            xs={6}
            alignItems="center"
            justifyContent="flex-end"
          >
            {buildRightNavigationButtons()}
          </Grid>
        </Grid>
      </form>
    );
  };

  const pageTitle = '顧客管理設定';

  return (
    <Root>
      <Head title={`ワークスペース設定 - ${workspace?.name || ''}`} />
      <Main>
        <WorkspacePageLayout
          workspaceUid={workspaceUid}
          current="crm"
          breadcrumbs={
            <Breadcrumbs aria-label="breadcrumb">
              <BreadcrumbLinkItem to={`/`}>ホーム</BreadcrumbLinkItem>
              <BreadcrumbWorkspaceItem workspaceUid={workspaceUid} />
              <BreadcrumbTextItem>{pageTitle}</BreadcrumbTextItem>
            </Breadcrumbs>
          }
          helpId={helps.workspace.setting.crm}
        >
          <PageTitleAndDescription
            title="ワークスペース"
            subTitle={pageTitle}
            description="顧客管理機能を使用するか否かの設定を行います。"
            themeColor={globalColors.workspace}
          />
          {buildContents()}
        </WorkspacePageLayout>
      </Main>
      <FullscreenLoading open={openBackdrop} />
    </Root>
  );
}
