import { MenuItem, TextField } from '@material-ui/core';
import {
  AssignmentRule,
  assignmentRules,
} from '../../../../core/types/reservation-resource-types';

type AssignmentRuleOption = {
  label: string;
};

const assignmentRuleOptions: {
  [assignmentRule in AssignmentRule]: AssignmentRuleOption;
} = {
  autoRandom: {
    label: '自動割当(ランダム)',
  },
  autoOrdered: {
    label: '自動割当(リソースの並び順)',
  },
  select: {
    label: '利用者が指定',
  },
};

type SelectAssignmentRuleProps = {
  target: {
    assignmentRule: AssignmentRule;
  };
  onUpdate: () => void;
};

export const SelectAssignmentRule: React.VFC<SelectAssignmentRuleProps> = (
  props
) => {
  const { target, onUpdate } = props;

  const handleChangeAssignmentRule = (
    event: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    const assignmentRule = event.target.value as AssignmentRule;
    target.assignmentRule = assignmentRule;
    onUpdate();
  };

  return (
    <TextField
      select
      label="リソースの割当方法"
      value={target.assignmentRule}
      onChange={handleChangeAssignmentRule}
      style={{ minWidth: '200px' }}
      required
    >
      {assignmentRules.map((assignmentRule) => (
        <MenuItem key={assignmentRule} value={assignmentRule}>
          {assignmentRuleOptions[assignmentRule].label}
        </MenuItem>
      ))}
    </TextField>
  );
};
