import { AxiosResponse } from 'axios';
import { NewResourceGroupRequestData } from '../../../@interfaces/resource/apis';
import { API_END_POINT } from '../../../api/api';
import { useMutation } from '../../common/api/use-mutation';

export const useCreateResourceGroups = (): ((
  shopId: string,
  params: NewResourceGroupRequestData
) => Promise<AxiosResponse<''>>) => {
  const { request } = useMutation<'', NewResourceGroupRequestData>();
  return (shopId, params) => {
    return request(
      'post',
      `${API_END_POINT}/app/shops/${shopId}/resource-groups`,
      {},
      params
    );
  };
};
