import axios, { AxiosResponse } from 'axios';
import { useContext, useEffect, useState } from 'react';
import { ResponseBody } from '../../../frontend-api/src/handlers/slot-setting/slot-setting-rule-handler';
import { SlotSettingWithCourses } from '../@interfaces/slot-setting';
import { SlotSettingRule } from '../@interfaces/slot-setting-rule';
import { Store } from '../context/GlobalStore';
import { API_END_POINT } from './api';

export default function useSlotSettingRule(
  shopId: string,
  slotSettingId: string,
  ruleId: string | undefined
) {
  const { globalState, setGlobalState } = useContext(Store);
  const [slotSetting, setSlotSetting] = useState<SlotSettingWithCourses>();
  const [slotSettingRule, setSlotSettingRule] = useState<SlotSettingRule>();
  const [loading, setLoading] = useState(false);
  const reload = () => {
    if (!globalState.session?.idToken || !ruleId) {
      return;
    }
    setLoading(true);
    axios
      .get(
        `${API_END_POINT}/app/shops/${shopId}/slot-settings/${slotSettingId}/rules/${ruleId}`,
        {
          headers: {
            Authorization: globalState.session?.idToken,
          },
        }
      )
      .then((response: AxiosResponse<ResponseBody>) => {
        setSlotSetting(response.data.slotSetting);
        setSlotSettingRule(response.data.rule);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    reload();
  }, [shopId, slotSettingId, globalState.session?.idToken]);
  return {
    slotSetting,
    slotSettingRule,
    isLoadingSlotSttingRule: loading,
  };
}
