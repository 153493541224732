import axios, { AxiosResponse } from 'axios';
import { useContext } from 'react';
import useSWR from 'swr';
import { GetShopCustomerLineMessagingChannels } from '../../../@interfaces/crm/api';
import { API_END_POINT } from '../../../api/api';
import { Store } from '../../../context/GlobalStore';

const fetcher = async (token: string, shopId: number, customerId: number) =>
  axios
    .get(
      `${API_END_POINT}/app/shops/${shopId}/customers/${customerId}/line-messaging-channels`,
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((r: AxiosResponse<GetShopCustomerLineMessagingChannels>) => r.data);

export const useShopCustomerLineMessagingChannels = (
  shopId: number,
  customerId: number | undefined
) => {
  const { globalState } = useContext(Store);
  const { data, isLoading } = useSWR(
    globalState.session?.idToken && shopId && customerId
      ? [globalState.session.idToken, shopId, customerId]
      : null,
    async ([token, shopId, customerId]) => fetcher(token, shopId, customerId)
  );
  return {
    data,
    isLoading,
  };
};
