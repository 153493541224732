import axios from 'axios';
import { useContext, useState } from 'react';
import { SendTestReportRequest } from '../../../@interfaces/insight/api';
import { API_END_POINT } from '../../../api/api';
import { Store } from '../../../context/GlobalStore';

export default function useInsightReportTest(shopId: string) {
  const { globalState } = useContext(Store);
  const [isLoading, setIsLoading] = useState(false);

  const sendTestReport = async (year: string, month: string) => {
    const url = `${API_END_POINT}/app/shops/${shopId}/insight/report-test`;
    const token = globalState.session?.idToken;

    try {
      setIsLoading(true);
      const data = {
        year,
        month,
      } as SendTestReportRequest;
      const response = await axios.post(url, data, {
        headers: {
          Authorization: token,
        },
      });
      console.log(response.data);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    sendTestReport,
    isLoading: isLoading,
  };
}
