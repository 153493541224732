import { CustomerActivitiesResponse } from '../../../@interfaces/crm/api';
import { API_END_POINT } from '../../../api/api';
import { useQuery } from '../../common/api/use-query';

export const useCustomerActivities = (
  shopId: string,
  customerId: string
): {
  data: CustomerActivitiesResponse | undefined;
  isLoading: boolean;
  reload: () => void;
} => {
  const { response, isLoading, reload } = useQuery<CustomerActivitiesResponse>(
    `${API_END_POINT}/app/shops/${shopId}/customers/${customerId}/activities`
  );
  return {
    data: response,
    isLoading: isLoading,
    reload: reload,
  };
};
