import axios from 'axios';
import { useContext, useState } from 'react';
import { Store } from '../../context/GlobalStore';
import { API_END_POINT } from '../api';

export default function useCreateGoogleCalendarIntegration() {
  const { globalState } = useContext(Store);
  const [loading, setLoading] = useState(false);

  const googleCalendarIntegrationApi = (
    shopId: string,
    googleAccountUid: string,
    accountId: number,
    calendarId: string,
    calendarSummary: string
  ) => {
    setLoading(true);
    axios
      .post(
        `${API_END_POINT}/app/shops/${shopId}/google/calendar/integration`,
        {
          accountId: accountId,
          googleAccountUid: googleAccountUid,
          calendarId: calendarId,
          summary: calendarSummary,
        },
        {
          headers: {
            Authorization: globalState.session?.idToken,
          },
        }
      )
      .finally(() => {
        setLoading(false);
      });
  };

  return {
    googleCalendarIntegrationApi,
    isLoadingIntegration: loading,
  };
}
