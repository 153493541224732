/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import {
  Box,
  Button,
  FormControlLabel,
  Grid,
  IconButton,
  MenuItem,
  Popover,
  PopoverProps,
  Switch,
  Tab,
  Tabs,
  TextareaAutosize,
  TextField,
  useTheme,
} from '@material-ui/core';
import AssignmentIcon from '@material-ui/icons/Assignment';
import CloseIcon from '@material-ui/icons/Close';
import GetAppIcon from '@material-ui/icons/GetApp';
import Share from '@material-ui/icons/Share';
import { Alert } from '@material-ui/lab';
import dayjs from 'dayjs';
import { useQRCode } from 'next-qrcode';
import { useSnackbar } from 'notistack';
import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { InflowSource } from '../@interfaces/inflow-source';
import { LineMessagingChannelOverview } from '../@interfaces/line-messaging-channel';
import { Shop } from '../@interfaces/shop';
import useShopCourses from '../api/use-shop-courses';
import useWorkspaceCourses from '../api/use-workspace-courses';
import { useCustomDomainAttachedShop } from '../features/custom-domain/api/useCustomDomainAttachedShop';
import { SM_BREAKPOINT } from '../hooks/use-size-type';
import {
  buildPublicLineUrl,
  buildPublicShareUrl,
  PUBLIC_BASE_URL,
} from '../utils/urls';
import { commonCss } from './common-css';
import LinkButton from './LinkButton';

const styles = {
  container: css({
    width: '600px',
    maxWidth: '600px',
    padding: '20px',
  }),
  header: (primaryColor: string) =>
    css({
      padding: '5px 0px',
      backgroundColor: primaryColor,
      borderBottom: 'solid 1px #ddd',
    }),
  headerLabel: css({
    marginLeft: '15px',
    color: '#fff',
  }),
  tab: css({
    marginLeft: '50px',
  }),
  row: css`
    padding: 1px;
    margin-top: 10px;
    margin-bottom: 5px;
  `,
  inflowSourceName: css`
    font-size: 14px;

    @media screen and (max-width: ${SM_BREAKPOINT}px) {
      margin-top: 8px;
    }
  `,
  input: css`
    color: #777;
    font-size: 14px;
    padding: 7px;
    width: 400px;
    border: solid 1px #aaa;
    border-radius: 5px 5px;

    @media screen and (max-width: ${SM_BREAKPOINT}px) {
      width: 100%;
    }
  `,
  inputTextAreaAlert: css`
    clear: both;
  `,
  inputTextArea: css`
    width: 400px;
    margin-top: 5px;
    margin-right: 5px;
    margin-bottom: 5px;
    float: left;
    @media screen and (max-width: ${SM_BREAKPOINT}px) {
      width: 100%;
    }
  `,
  copyButton: css`
    margin-left: 3px;

    @media screen and (max-width: ${SM_BREAKPOINT}px) {
      margin-top: 8px;
      margin-left: 0;
    }
  `,
  copyTextAreaButton: css`
    margin-left: 3px;
    float: left;
  `,
  openButton: css`
    margin-left: 3px;

    @media screen and (max-width: ${SM_BREAKPOINT}px) {
      margin-top: 8px;
    }
  `,
  popoverCloseButton: css`
    position: absolute;
    top: 4px;
    right: 4px;
    background: #ffffff;
    z-index: 10000;
  `,
  closeButton: css`
    position: fixed;
    left: 0;
    bottom: 0;
    background: red;
    width: 100%;
  `,
  lineButton: css`
    margin-bottom: 10px;
  `,
  textField: css`
    margin-top: 20px;
    margin-right: 20px;
    margin-left: 5px;
    margin-bottom: 10px;
    width: 300px;
  `,
  qrCodeWrapper: css`
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
  `,
};

const DEFAULT_COURSE_ID = -1;
const DEFAULT_INFLOW_ID = -1;
const DEFAULT_SHOP_ID = -1;

type ShareParams = {
  workspaceUid: string;
  shopId?: string;
  channelKey?: string;
  inflowSources: InflowSource[];
  shops: Shop[];
  lineMessagingChannels: LineMessagingChannelOverview[];
};

export const ShareButton = (props: ShareParams) => {
  const { channelKey } = props;
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const handleClickOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        type="button"
        variant="text"
        size="small"
        startIcon={channelKey ? <AssignmentIcon /> : <Share />}
        color="primary"
        onClick={handleClickOpen}
      >
        {channelKey ? (
          <>
            <span css={commonCss.forPC}>LINE用の予約URLをコピー</span>
            <span css={commonCss.forSP}>LINE用の予約URLをコピー</span>
          </>
        ) : (
          <>
            <span css={commonCss.forPC}>共有</span>
            <span css={commonCss.forSP}>共有</span>
          </>
        )}
      </Button>
      {anchorEl && (
        <SharePopover
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          {...props}
        />
      )}
    </>
  );
};

const SharePopover = (props: ShareParams & PopoverProps) => {
  const {
    workspaceUid,
    shopId,
    channelKey,
    inflowSources,
    shops,
    lineMessagingChannels,
    ...popoverProps
  } = props;
  const [tab, setTab] = useState(channelKey ? 1 : 0);
  const theme = useTheme();
  const handleChangeTab = (event: React.ChangeEvent<any>, newValue: number) => {
    setTab(newValue);
  };
  const { enqueueSnackbar } = useSnackbar();
  const { Canvas } = useQRCode();
  const { courses } = useWorkspaceCourses(workspaceUid);
  const [startDate, setStartDate] = useState(
    dayjs().startOf('month').format('YYYY-MM-DD')
  );
  const [endDate, setEndDate] = useState(
    dayjs().endOf('month').format('YYYY-MM-DD')
  );
  const [selectedRange, setSelectedRange] = useState(false);
  const handleChangeSelectedType = (e: ChangeEvent<HTMLInputElement>) => {
    const type = selectedRange ? 'default' : 'range';
    if (type === 'range' && !selectedCourse) {
      enqueueSnackbar(
        `日付指定はコースを指定する必要があります。コースを選択してください。`,
        { variant: 'error' }
      );
      return;
    }
    setSelectedRange(type === 'range');
  };
  const [selectedLineChannelKey, setselectedLineChannelKey] =
    React.useState<string>(
      channelKey ? channelKey : lineMessagingChannels[0]?.channelKey
    );
  useEffect(() => {
    if (!channelKey)
      setselectedLineChannelKey(lineMessagingChannels[0]?.channelKey);
  }, [channelKey, lineMessagingChannels, setselectedLineChannelKey]);
  const handleChangeSelectedLineChannelKey = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = e.target.value;
    setselectedLineChannelKey(value);
  };
  const [selectedInflowId, setSelectedInflowId] =
    React.useState<number>(DEFAULT_INFLOW_ID);
  const handleChangeSelectedInflowId = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = e.target.value;
    setSelectedInflowId(parseInt(value));
  };
  const selectedInflow = useMemo(() => {
    return inflowSources.find(
      (inflowSource) => inflowSource.id === selectedInflowId
    );
  }, [selectedInflowId, inflowSources]);
  const [selectedShopId, setSelectedShopId] = React.useState<number>(
    shopId ? parseInt(shopId) : DEFAULT_SHOP_ID
  );
  const handleChangeSelectedShopId = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = e.target.value;
    setSelectedShopId(parseInt(value));
    setSelectedCourseId(DEFAULT_COURSE_ID);
  };
  const selectedShop = useMemo(() => {
    return shops.find((shop) => shop.id === selectedShopId);
  }, [selectedShopId, shops]);
  const handleChangeSelectedCourseId = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = parseInt(e.target.value);
    setSelectedCourseId(value);
    if (value === DEFAULT_COURSE_ID) {
      setSelectedRange(false);
    }
  };
  const [selectedCourseId, setSelectedCourseId] =
    React.useState<number>(DEFAULT_COURSE_ID);
  const { shopCourses } = useShopCourses(
    String(selectedShopId >= 0 ? selectedShopId : shops[0]?.id)
  );
  const selectedCourse = useMemo(() => {
    return shopCourses.find((course) => course.id === selectedCourseId);
  }, [selectedCourseId, shopCourses]);

  const { data: customDomain } = useCustomDomainAttachedShop(selectedShop?.id);
  const url = buildPublicShareUrl(
    workspaceUid,
    selectedShop ? selectedShop?.uid : '',
    selectedCourse ? selectedCourse?.uid : '',
    selectedInflow ? selectedInflow.uid : '',
    selectedRange && selectedCourse ? startDate : '',
    selectedRange && selectedCourse ? endDate : '',
    customDomain
  );
  const lineUrl = buildPublicLineUrl(
    workspaceUid,
    selectedLineChannelKey,
    selectedShopId !== DEFAULT_SHOP_ID ? selectedShopId : undefined,
    selectedCourseId !== DEFAULT_COURSE_ID ? selectedCourseId : undefined
  );

  const buildQr = (url: string) => {
    const downloadQR = () => {
      const canvas = document.querySelector('#qrcode canvas');
      if (!canvas) {
        return;
      }
      const pngUrl = (canvas as any)
        .toDataURL('image/png')
        .replace('image/png', 'image/octet-stream');
      const downloadLink = document.createElement('a');
      downloadLink.href = pngUrl;
      const filename =
        [
          workspaceUid,
          ...(selectedShop ? [selectedShop?.name] : []),
          ...(selectedCourse ? [selectedCourse?.name] : []),
          ...(selectedInflow ? [selectedInflow.name] : []),
          ...(selectedRange && selectedCourse ? [startDate] : []),
          ...(selectedRange && selectedCourse ? [endDate] : []),
        ].join('_') + '.png';
      downloadLink.download = filename;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
      enqueueSnackbar(`QRコードを保存しました。(ファイル名: ${filename})`, {
        variant: 'success',
      });
    };

    return (
      <div>
        <div id="qrcode" css={styles.qrCodeWrapper}>
          <Canvas
            text={url}
            options={{
              errorCorrectionLevel: 'M',
              margin: 1,
              scale: 4,
              width: 120,
              color: {
                dark: '#000',
              },
            }}
          />
        </div>
        <Button
          css={styles.copyButton}
          onClick={downloadQR}
          variant="outlined"
          startIcon={<GetAppIcon />}
        >
          QRコードを保存
        </Button>
      </div>
    );
  };
  const lineChannelList = () => {
    return (
      <>
        {lineMessagingChannels.length > 0 && (
          <TextField
            select
            label="公式アカウント"
            css={styles.textField}
            onChange={handleChangeSelectedLineChannelKey}
            value={selectedLineChannelKey}
          >
            {lineMessagingChannels.map((channel) => {
              return (
                <MenuItem key={channel.channelKey} value={channel.channelKey}>
                  {channel.channelName}
                </MenuItem>
              );
            })}
          </TextField>
        )}
      </>
    );
  };
  const shopList = () => {
    return (
      <>
        {shops.length > 0 && (
          <TextField
            select
            label="店舗"
            css={styles.textField}
            onChange={handleChangeSelectedShopId}
            value={selectedShopId}
          >
            <MenuItem key={DEFAULT_SHOP_ID} value={DEFAULT_SHOP_ID}>
              店舗未指定
            </MenuItem>
            {shops.map((shop) => {
              return (
                <MenuItem key={shop.id} value={shop.id}>
                  {shop.name} {shop.deletedAt && '(削除済み)'}
                </MenuItem>
              );
            })}
          </TextField>
        )}
      </>
    );
  };
  const courseList = () => {
    return (
      <>
        {(shopCourses && selectedShopId >= 0 ? shopCourses : courses).length >
          0 && (
          <TextField
            select
            label="コース"
            css={styles.textField}
            onChange={handleChangeSelectedCourseId}
            value={selectedCourseId}
          >
            <MenuItem key={DEFAULT_COURSE_ID} value={DEFAULT_COURSE_ID}>
              コース未指定
            </MenuItem>
            {(shopCourses && selectedShopId >= 0 ? shopCourses : courses).map(
              (course) => {
                return (
                  <MenuItem key={course.id} value={course.id}>
                    {course.name} {course.deletedAt && '(削除済み)'}
                  </MenuItem>
                );
              }
            )}
          </TextField>
        )}
      </>
    );
  };
  const inflowSourceList = () => {
    return (
      <>
        {inflowSources.length > 0 && (
          <TextField
            select
            label="流入元"
            css={styles.textField}
            onChange={handleChangeSelectedInflowId}
            value={selectedInflowId}
          >
            <MenuItem key={DEFAULT_INFLOW_ID} value={DEFAULT_INFLOW_ID}>
              流入元未指定
            </MenuItem>
            {inflowSources.map((inflow) => {
              return (
                <MenuItem key={inflow.id} value={inflow.id}>
                  {inflow.name}
                </MenuItem>
              );
            })}
          </TextField>
        )}
      </>
    );
  };
  const buildRange = () => {
    return (
      <div>
        <FormControlLabel
          control={
            <Switch
              checked={selectedRange}
              onChange={handleChangeSelectedType}
            />
          }
          label="日付を指定"
        />
        {selectedRange && selectedCourse && (
          <div>
            期間:
            <input
              type="date"
              value={startDate}
              onChange={(event) => {
                if (dayjs(endDate).diff(event.target.value, 'days') > 100) {
                  enqueueSnackbar('期間の指定は最大100日間です', {
                    variant: 'error',
                  });
                  return;
                }
                setStartDate(event.target.value);
              }}
              style={{ width: '140px', marginLeft: '5px', marginRight: '5px' }}
              max="9999-12-31"
            />
            〜
            <input
              type="date"
              value={endDate}
              onChange={(event) => {
                if (dayjs(event.target.value).diff(startDate, 'days') > 100) {
                  enqueueSnackbar('期間の指定は最大100日間です', {
                    variant: 'error',
                  });
                  return;
                }
                setEndDate(event.target.value);
              }}
              style={{ width: '140px', marginLeft: '5px' }}
              max="9999-12-31"
            />
          </div>
        )}
      </div>
    );
  };

  const buildDefaultShare = () => {
    return (
      <div>
        {shopList()}
        {courseList()}
        <div style={{ float: 'right', marginRight: '60px' }}>
          {buildQr(url)}
        </div>
        {inflowSourceList()}
        {buildRange()}
        {urlTextCopyArea(url)}
        <Alert severity="info">
          生成されたURLはHPやSNSからのリンクURLとして利用できます。
        </Alert>
      </div>
    );
  };

  const buildLineShare = () => {
    return (
      <div>
        {lineMessagingChannels.length ? (
          <>
            {shopList()}
            {courseList()}
            {lineChannelList()}
            {urlTextCopyArea(lineUrl)}
            <Alert severity="info">
              生成されたURLをLINEのリッチパネルなどに設定してください。
            </Alert>
          </>
        ) : (
          <Grid item container xs={8} alignItems="center">
            <Alert severity="error" css={styles.lineButton}>
              LINE連携が設定されていません。
            </Alert>
            <LinkButton
              to={`/a/${workspaceUid}/settings/line`}
              label="LINE連携を設定"
            />
          </Grid>
        )}
      </div>
    );
  };

  const buildCodeShare = () => {
    return (
      <div>
        {shopList()}
        {courseList()}
        {inflowSourceList()}
        {buildRange()}
        {codeTextCopyArea(url)}
        <div css={styles.inputTextAreaAlert}>
          <Alert severity="info">
            生成された埋め込みコードをコピーして埋め込みたいサイトに貼り付けてください。
          </Alert>
        </div>
      </div>
    );
  };

  function codeTextCopyArea(url: string) {
    const widgetUrl = new URL(url);
    widgetUrl.searchParams.set('widget', '1');
    const scriptPath = `${PUBLIC_BASE_URL}/script/widget.js`;
    const code = `<div id="locaop-reservation-widget" data-reservation-link="${widgetUrl.toString()}"></div><script async src="${scriptPath}"></script>`;
    const handleClickCopy = async () => {
      await navigator.clipboard.writeText(code);
      enqueueSnackbar(`埋め込みコードをクリップボードにコピーしました。`, {
        variant: 'success',
      });
    };

    return (
      <div css={styles.row} key={url}>
        <div css={styles.inflowSourceName}>生成された埋め込みコード</div>
        <TextareaAutosize
          value={code}
          css={styles.inputTextArea}
          disabled={true}
        />
        <Button
          css={styles.copyTextAreaButton}
          onClick={handleClickCopy}
          variant="outlined"
        >
          コピー
        </Button>
      </div>
    );
  }

  function urlTextCopyArea(url: string) {
    const handleClickCopy = async () => {
      await navigator.clipboard.writeText(url);
      enqueueSnackbar(`URLをクリップボードにコピーしました。`, {
        variant: 'success',
      });
    };

    return (
      <div css={styles.row} key={url}>
        <div css={styles.inflowSourceName}>生成されたURL</div>
        <input css={styles.input} type="text" value={url} readOnly />
        <Button
          css={styles.copyButton}
          onClick={handleClickCopy}
          variant="outlined"
        >
          コピー
        </Button>
        <a
          href={url}
          target="_blank"
          rel="noreferrer"
          css={css`
            text-decoration: none;
          `}
        >
          <Button css={styles.openButton} variant="outlined">
            開く
          </Button>
        </a>
      </div>
    );
  }
  return (
    <Popover {...popoverProps}>
      <Grid
        container
        alignItems="center"
        css={styles.header(theme.palette.primary.main)}
      >
        <Grid container item xs={10} alignItems="center">
          <div css={styles.headerLabel}>Web予約画面の共有</div>
        </Grid>
        <Grid container item xs={2} alignItems="center">
          <IconButton
            size="small"
            color="primary"
            component="span"
            onClick={() => props.onClose}
          >
            <CloseIcon />
          </IconButton>
        </Grid>
      </Grid>
      <Box css={styles.tab}>
        <Tabs value={tab} onChange={handleChangeTab} indicatorColor="primary">
          <Tab label="URL" />
          <Tab label="LINE連携" />
          <Tab label="埋め込みコード" />
        </Tabs>
      </Box>
      <Grid container alignItems="center" css={styles.container}>
        {tab === 0 && buildDefaultShare()}
        {tab === 1 && buildLineShare()}
        {tab === 2 && buildCodeShare()}
      </Grid>
    </Popover>
  );
};
