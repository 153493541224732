import { ReservationActivityResponse } from '../../../@interfaces/reservation-activity/apis';
import { useQuery } from '../../common/api/use-query';
import { API_END_POINT } from '../../../api/api';

type ReturnType = {
  response: ReservationActivityResponse | undefined;
  isLoading: boolean;
};

export function useReservationActivities(
  shopId: string,
  reservationId: string
): ReturnType {
  const { response, isLoading } = useQuery<ReservationActivityResponse>(
    `${API_END_POINT}/app/shops/${shopId}/reservations/${reservationId}/activities`
  );
  return { response, isLoading };
}
