/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { Box } from '@material-ui/core';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import React from 'react';
import { ShopImage } from '../@interfaces/shop-setting';
import Description from './Description';
import { PreviewCourse } from './PreviewCourse';
import { commonCss } from './common-css';

const styles = {
  previewWrapper: css`
    background: #f2f2f2;
    border: 1px solid #333333;
  `,
  imageWrapper: css`
    position: relative;
    height: 160px;
    width: 100%;
    overflow: hidden;
    background: #ffffff;

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
    }
  `,
  shopName: css`
    font-size: 24px;
    font-weight: bold;
    margin: 32px auto 8px;
    text-align: center;
  `,
  headline: css`
    font-weight: normal;
    font-size: 14px;
    margin-top: 8px;
  `,
  previewDescription: css`
    p {
      margin: 0 auto;
    }
  `,
};

interface Props {
  color: string;
  shopName: string;
  imageSrc?: string;
  headline1?: string;
  headline2?: string;
  images?: ShopImage[];
  bannerImageSrc?: string;
}

export const PreviewSelectCoursePage: React.VFC<Props> = ({
  color,
  imageSrc,
  shopName,
  headline1,
  headline2,
  images,
  bannerImageSrc,
}) => {
  return (
    <Box css={[styles.previewWrapper, commonCss.previewWrapper]}>
      {imageSrc && (
        <Box css={styles.imageWrapper}>
          <img src={imageSrc} alt="" />
        </Box>
      )}
      {!imageSrc && images && images.length > 0 && (
        <Splide
          options={{
            type: 'loop',
            arrows: images.length > 1,
            pagination: images.length > 1,
            drag: images.length > 1,
          }}
        >
          {images.map((image, index) => (
            <SplideSlide key={`slide-${index}`}>
              <img
                src={image.url}
                css={css`
                  width: 100%;
                `}
                alt="shop slide"
              />
            </SplideSlide>
          ))}
        </Splide>
      )}
      <Box>
        <h1 css={styles.shopName}>{shopName}</h1>
        <Box css={styles.previewDescription}>
          <Description
            description={`${headline1}
  ${headline2}`}
            enableShowButton={false}
          />
        </Box>
      </Box>
      <div
        css={css`
          margin: 10px 32px;
        `}
      >
        <PreviewCourse primaryColor={color} />
      </div>
      <Box
        css={css`
          padding: 0px 30px;
        `}
      >
        <img src={bannerImageSrc} alt="" width="100%" />
      </Box>
    </Box>
  );
};
