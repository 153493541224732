import axios, { AxiosResponse } from 'axios';
import { useContext, useEffect, useState } from 'react';
import { ResponseBody } from '../../../frontend-api/src/handlers/form-setting/form-setting-fields-handler';
import { Store } from '../context/GlobalStore';
import { AnyField } from '../core/types/reservation-form-types';
import { API_END_POINT } from './api';

export default function useFormSettingFields(workspaceUid: string) {
  const { globalState, setGlobalState } = useContext(Store);
  const [formSettingFields, setFormSettingFields] = useState([] as AnyField[]);
  const [loading, setLoading] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  useEffect(() => {
    if (!globalState.session?.idToken) {
      return;
    }
    axios
      .get(
        `${API_END_POINT}/app/ws/${workspaceUid}/courses/form-setting-fields`,
        {
          headers: {
            Authorization: globalState.session?.idToken,
          },
        }
      )
      .then((response: AxiosResponse<ResponseBody>) => {
        setFormSettingFields(response.data.fields);
      })
      .finally(() => {
        setLoading(false);
        setIsLoaded(true);
      });
  }, [workspaceUid, globalState.session?.idToken]);
  return {
    formSettingFields,
    isLoadingFormSettingFields: loading,
    isLoadedFormSettingFields: isLoaded,
  };
}
