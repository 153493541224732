import { ResourceGroupListResponse } from '../../../@interfaces/resource/apis';
import { API_END_POINT } from '../../../api/api';
import { useQuery } from '../../common/api/use-query';

export const useResourceGroups = (shopId: string) => {
  const { response, isLoading, reload } = useQuery<ResourceGroupListResponse>(
    `${API_END_POINT}/app/shops/${shopId}/resource-groups`
  );
  return {
    data: response?.resourceGroups || [],
    isLoading: isLoading,
    reload: reload,
  };
};
