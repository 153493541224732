import { useEffect, useState } from 'react';

/**
 * Reactの状態変更が行われてもリセットされないsetIntervalを実現するためのタイマー用のフック。
 * 1秒おきにしかチェックを実行しないため、1秒未満の精度は保証されません。
 *
 * @param callback 一定間隔ごとに実行する関数、状態に応じて関数のインスタンスは変更されることを想定
 * @param interval 関数の実行間隔(ms)
 * @param enabled タイマーの有効/無効。無効 -> 有効に切り替えた場合、タイマーが0にリセットされます
 */
export const useInterval = (
  callback: (...args: any[]) => void,
  interval: number,
  enabled: boolean
): void => {
  // 最終実行時間
  const [lastExecutedTime, setLastExecutedTime] = useState(
    new Date().getTime()
  );
  // １つ前のタイマーの有効状態
  const [previousEnabledState, setPreviousEnabledState] = useState(enabled);
  useEffect(() => {
    if (!enabled) {
      setPreviousEnabledState(enabled);
      return;
    }
    // 有効に切り替わったタイミングで最終実行時間をリセット
    if (!previousEnabledState) {
      setLastExecutedTime(new Date().getTime());
    }
    setPreviousEnabledState(enabled);
    // 1秒ごとに監視して、しきい値の秒数を超えたタイミングで実行する
    const timer = () => {
      const currentTime = new Date().getTime();
      const remainingTime = interval - (currentTime - lastExecutedTime);
      if (remainingTime < 0) {
        setLastExecutedTime(currentTime);
        callback();
      }
    };
    const intervalId = setInterval(timer, 1000);
    return () => {
      clearInterval(intervalId);
    };
  }, [callback, interval, enabled, lastExecutedTime, previousEnabledState]);
};
