import { useState } from 'react';
import { Checkbox, FormControlLabel } from '@material-ui/core';

export const useComparisonCheckbox = () => {
  const [showComparison, setShowComparison] = useState(false);

  const comparisonCheckbox = (
    <FormControlLabel
      control={
        <Checkbox
          name="comparisonDisplay"
          onChange={(_event, checked) => {
            setShowComparison(checked);
          }}
          value={showComparison}
        />
      }
      label="比較を表示する"
    />
  );

  return {
    comparisonCheckbox,
    showComparison,
  };
};
