import axios from 'axios';
import { useCallback, useContext, useState } from 'react';
import { SendLineMessageBody } from '../@interfaces/reservation-send-line-message';
import { Store } from '../context/GlobalStore';
import { API_END_POINT } from './api';

export default function useSendLineMessage() {
  const { globalState } = useContext(Store);
  const [loading, setLoading] = useState(false);

  const sendLineMessage = useCallback(
    async (
      shopId: number,
      reservationId: number,
      message: SendLineMessageBody
    ) => {
      if (!globalState.session?.idToken) {
        return;
      }
      setLoading(true);
      const response = await axios.post(
        `${API_END_POINT}/app/shops/${shopId}/reservations/${reservationId}/send-line-message`,
        message,
        {
          headers: {
            Authorization: globalState.session?.idToken,
            'Content-Type': 'application/json',
          },
        }
      );
      setLoading(false);
      if (response.status >= 400) {
        throw new Error(response.data?.message);
      }
    },
    [globalState.session?.idToken]
  );

  return {
    sendLineMessage,
    loading,
  };
}
