import axios, { AxiosResponse } from 'axios';
import { useCallback, useContext, useEffect, useState } from 'react';
import { ResponseBody } from '../../../frontend-api/src/handlers/line/line-login-channel-handler';
import { LineLoginChannel } from '../@interfaces/line-login-channel';
import { Store } from '../context/GlobalStore';
import { API_END_POINT } from './api';

export default function useLineLoginChannel(workspaceUid: string) {
  const { globalState } = useContext(Store);
  const [lineLoginChannel, setLoginChannel] = useState<LineLoginChannel>();
  const [loading, setLoading] = useState(false);
  const reload = useCallback(() => {
    if (!globalState.session?.idToken) {
      return;
    }
    setLoading(true);
    axios
      .get(`${API_END_POINT}/app/ws/${workspaceUid}/line/login-channel`, {
        headers: {
          Authorization: globalState.session?.idToken,
        },
      })
      .then((response: AxiosResponse<ResponseBody>) => {
        setLoginChannel(response.data.lineLoginChannel);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [workspaceUid, globalState.session?.idToken]);

  useEffect(() => {
    reload();
  }, [reload]);
  return {
    lineLoginChannel,
    isLoadingLineLoginChannel: loading,
  };
}
