/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from '@material-ui/core';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import dayjs from 'dayjs';
import { ChangeEvent, useState } from 'react';
import useShopReservations from '../api/use-shop-reservations';
import { DateRange, toDate } from '../core/types/reservation-types';
import { writeShopReservationXlsx } from '../models/reservation';

const styles = {
  select: css({
    border: '2px solid #cccccc',
    borderRadius: 3,
    '.MuiInputBase-root': {
      fontSize: 16,
    },
    '.MuiSelect-root': {
      paddingTop: 3,
      paddingBottom: 3,
      paddingLeft: 5,
    },
  }),
  marginLeft: css({
    marginLeft: 5,
  }),
};

export function ReservationDataDownloadButton({
  shopId,
  shopName,
}: {
  shopId: string;
  shopName: string;
}) {
  const [open, setOpen] = useState(false);

  return (
    <div>
      <Button
        type="button"
        variant="text"
        size="small"
        startIcon={<CloudDownloadIcon />}
        color="primary"
        onClick={() => {
          setOpen(true);
        }}
      >
        予約一覧出力
      </Button>

      {open && (
        <DownloadReservationDialog
          key={shopId}
          shopId={shopId}
          shopName={shopName}
          open={open}
          setOpen={setOpen}
        />
      )}
    </div>
  );
}

function DownloadReservationDialog({
  shopId,
  shopName,
  open,
  setOpen,
}: {
  shopId: string;
  shopName: string;
  open: boolean;
  setOpen: (open: boolean) => void;
}) {
  const [startDate, setStartDate] = useState(
    dayjs().startOf('month').format('YYYY-MM-DD')
  );
  const [endDate, setEndDate] = useState(
    dayjs().endOf('month').format('YYYY-MM-DD')
  );
  const [startTime, setStartTime] = useState(0);
  const [endTime, setEndTime] = useState(24);
  const lastMonth = dayjs().add(0, 'month');
  const [startMonthlyDate, setStartMonthlyDate] = useState(
    lastMonth.startOf('month').format('YYYY-MM-DD')
  );
  const [endMonthlyDate, setEndMonthlyDate] = useState(
    lastMonth.endOf('month').format('YYYY-MM-DD')
  );
  const [selectedMonthly, setSelectedMonthly] = useState(true);
  const [showTimeCondition, setShowTimeCondition] = useState(false);

  // Excel生成に必要な情報を取得
  const {
    reservations,
    inflowSources,
    labels,
    settingJsons,
    resources,
    enableCrm,
    isLoadingReservations,
  } = useShopReservations(
    shopId,
    selectedMonthly ? startMonthlyDate : startDate,
    selectedMonthly ? endMonthlyDate : endDate,
    selectedMonthly === false && showTimeCondition ? startTime : null,
    selectedMonthly === false && showTimeCondition ? endTime : null
  );

  /**
   * x件のXLSXを出力ボタン押下時の処理
   */
  const handleClickXlsxDownload = () => {
    const dateRange: DateRange = {
      start: toDate(dayjs(startDate)),
      end: toDate(dayjs(endDate)),
    };

    // エクセル書き出し
    writeShopReservationXlsx(
      reservations,
      inflowSources,
      labels,
      settingJsons,
      resources,
      dateRange,
      shopName,
      enableCrm
    );

    setOpen(false);
  };

  const buttonValues = [
    {
      name: '先月',
      func: () => {
        /** 先月ボタンが押下された際の処理 */
        const lastMonth = dayjs().add(-1, 'month');
        setStartDate(lastMonth.startOf('month').format('YYYY-MM-DD'));
        setEndDate(lastMonth.endOf('month').format('YYYY-MM-DD'));
      },
    },
    {
      name: '今月',
      func: () => {
        /** 今月ボタンが押下された場合の処理 */
        const thisMonth = dayjs();
        setStartDate(thisMonth.startOf('month').format('YYYY-MM-DD'));
        setEndDate(thisMonth.endOf('month').format('YYYY-MM-DD'));
      },
    },
    {
      name: '今日',
      func: () => {
        /** 今日ボタンが押下された際の処理 */
        const today = dayjs();
        setStartDate(today.format('YYYY-MM-DD'));
        setEndDate(today.format('YYYY-MM-DD'));
      },
    },
  ];
  /**
   * ボタンの情報に応じてボタンを生成する処理
   * @param buttonValues 生成するボタンの情報
   * @returns 生成したボタンを返却する
   */
  const renderButtons = (
    buttonValues: { name: string; func: () => void }[]
  ) => {
    return buttonValues.map(
      (buttonValue: { name: string; func: () => void }) => (
        <Button
          key={buttonValue.name}
          variant="contained"
          color="default"
          size="small"
          style={{ marginLeft: '5px' }}
          onClick={buttonValue.func}
          disabled={selectedMonthly}
        >
          {buttonValue.name}
        </Button>
      )
    );
  };

  const handleChangeSelectedType = (e: ChangeEvent<HTMLInputElement>) => {
    const type = e.target.value as 'range' | 'monthly';
    setSelectedMonthly(type === 'monthly');
  };

  const [showReportMonth, setShowReportMonth] = useState(
    dayjs().add(0, 'M').format('YYYY-MM')
  );

  const changeReportMonth = (month: string) => {
    setShowReportMonth(month);
    setStartMonthlyDate(dayjs(month).startOf('month').format('YYYY-MM-DD'));
    setEndMonthlyDate(dayjs(month).endOf('month').format('YYYY-MM-DD'));
  };

  const handleUpdateShowReportMonth = (
    event: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    changeReportMonth(event.target.value as string);
  };

  const monthSelector = () => {
    return (
      <TextField
        variant="outlined"
        css={[styles.select, styles.marginLeft]}
        select
        value={showReportMonth}
        onChange={(e) => {
          handleUpdateShowReportMonth(e);
        }}
        disabled={!selectedMonthly}
      >
        {[...Array(24)].map((_, index) => {
          const ym = dayjs().add(-1 * (index - 1), 'M');
          return (
            <MenuItem value={ym.format('YYYY-MM')} key={index}>
              {ym.format('YYYY年MM月')}
            </MenuItem>
          );
        })}
      </TextField>
    );
  };

  const buildTimes = () => {
    return Array.from({ length: 30 }, (_, i) => {
      const time = `${i.toString().padStart(2, '0')}:00`;
      return (
        <MenuItem value={i} key={i}>
          {time}
        </MenuItem>
      );
    });
  };

  return (
    <Dialog
      open={open}
      onClose={() => {
        setOpen(false);
      }}
      maxWidth="md"
    >
      <DialogTitle>予約一覧出力</DialogTitle>
      <DialogContent>
        <DialogContentText>
          予約一覧をXLSX形式でダウンロードします。
        </DialogContentText>
        <RadioGroup
          name="reminderType"
          value={selectedMonthly ? 'monthly' : 'range'}
          onChange={handleChangeSelectedType}
        >
          <FormControlLabel
            value="monthly"
            control={<Radio />}
            label="月単位でダウンロードする"
          />
          <Grid container alignItems="center">
            対象月:
            {monthSelector()}
          </Grid>
          <FormControlLabel
            value="range"
            control={<Radio />}
            label="期間選択してダウンロードする"
          />
        </RadioGroup>
        <Grid container>
          <Grid container item xs={12}>
            <Grid item>
              期間:
              <input
                type="date"
                value={startDate}
                onChange={(event) => {
                  setStartDate(event.target.value);
                }}
                style={{
                  width: '140px',
                  marginLeft: '5px',
                  marginRight: '5px',
                }}
                max="9999-12-31"
                disabled={selectedMonthly}
              />
              {showTimeCondition && (
                <Select
                  value={startTime}
                  onChange={(event) => {
                    setStartTime(event.target.value as number);
                  }}
                  disabled={selectedMonthly}
                >
                  {buildTimes()}
                </Select>
              )}
              〜
              <input
                type="date"
                value={endDate}
                onChange={(event) => {
                  setEndDate(event.target.value);
                }}
                style={{ width: '140px', marginLeft: '5px' }}
                max="9999-12-31"
                disabled={selectedMonthly}
              />
              {showTimeCondition && (
                <Select
                  value={endTime}
                  onChange={(event) => {
                    setEndTime(event.target.value as number);
                  }}
                  disabled={selectedMonthly}
                >
                  {buildTimes()}
                </Select>
              )}
              <div
                css={css`
                  margin-left: 30px;
                `}
              >
                <Button
                  variant="text"
                  color="primary"
                  size="small"
                  onClick={() => {
                    setShowTimeCondition(!showTimeCondition);
                  }}
                  disabled={selectedMonthly}
                >
                  {showTimeCondition ? '時間を指定しない' : '時間まで指定'}
                </Button>
              </div>
            </Grid>
            <Grid item>{renderButtons(buttonValues)}</Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setOpen(false);
          }}
          color="primary"
        >
          キャンセル
        </Button>
        <Button
          onClick={handleClickXlsxDownload}
          color="primary"
          disabled={isLoadingReservations}
        >
          {isLoadingReservations
            ? '読込中...'
            : `${reservations.length}件のXLSXを出力`}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
