/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import {
  CircularProgress,
  Grid,
  Link,
  Paper,
  SvgIconProps,
} from '@material-ui/core';
import Container from '@material-ui/core/Container';
import AnnouncementIcon from '@material-ui/icons/Announcement';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import BookmarkBorder from '@material-ui/icons/BookmarkBorder';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import React, { useContext, useMemo } from 'react';
import { BaseLayout } from '../components/layouts/BaseLayout';
import LinkRouter from '../components/LinkRouter';
import { Head, Main, Root, TopAppBar } from '../components/Shared';
import { useStyles } from '../components/Styles';
import { Store } from '../context/GlobalStore';
import Informations from '../features/information/components/Informations';
import { useLocaopMediaItems } from '../features/locaop-media/api/get-locaop-media-items';
import { MainMenu } from '../features/main-menu/components/MainMenu';
import { SideMenuItem } from '../features/side-menu/components/SideMenuItem';
import { sideMenuStyles } from '../features/side-menu/styles';
import useBookmarkedWorkspaces from '../hooks/use-bookmarked-workspaces';
import { globalColors } from '../styles/globalColors';

const styles = {
  bookmarked: css({
    marginRight: '6px',
    cursor: 'pointer',
  }),
  unbookmarked: css({
    marginRight: '6px',
    color: '#ccc',
    cursor: 'pointer',
  }),
};

export default function HomePage() {
  const buildContents = () => (
    <Container maxWidth="lg">
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <Workspaces />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Announcements />
          <Medias />
          <SystemMenu />
        </Grid>
      </Grid>
    </Container>
  );

  return (
    <Root>
      <Head title="ダッシュボード" />
      <Main>
        <BaseLayout
          header={
            <TopAppBar
              sideMenuContents={
                <div css={sideMenuStyles.sideMenu}>
                  <SideMenuItem
                    label="ホーム"
                    href={`/`}
                    isCurrent={true}
                    sideColor={globalColors.main}
                  />
                </div>
              }
            />
          }
          mainMenu={<MainMenu current="home" />}
        >
          {buildContents()}
        </BaseLayout>
      </Main>
    </Root>
  );
}

const Workspaces = (): JSX.Element => {
  const { globalState } = useContext(Store);
  const classes = useStyles();
  const { bookmarkedWorkspaceIds, toggleBookmarkedWorkspace } =
    useBookmarkedWorkspaces();
  const sortedWorkspaces = useMemo(() => {
    const bookmarked =
      globalState.signedUser?.workspaces.filter((w) =>
        bookmarkedWorkspaceIds.includes(w.id)
      ) || [];
    const unbookmarked =
      globalState.signedUser?.workspaces.filter(
        (w) => !bookmarkedWorkspaceIds.includes(w.id)
      ) || [];
    return [...bookmarked, ...unbookmarked];
  }, [globalState.signedUser?.workspaces, bookmarkedWorkspaceIds]);

  return (
    <Paper className={classes.paper}>
      <Grid
        container
        spacing={3}
        alignItems="center"
        style={{ padding: '20px' }}
      >
        <Grid container direction="row">
          <h3 className={classes.title}>所属しているワークスペース一覧</h3>
        </Grid>

        {sortedWorkspaces.map((ws) => {
          return (
            <Grid
              item
              container
              xs={12}
              alignItems="center"
              key={ws.uid}
              style={{ padding: '12px' }}
            >
              <Grid container alignItems="center">
                <Bookmark
                  isActive={bookmarkedWorkspaceIds.includes(ws.id)}
                  onClick={() => {
                    toggleBookmarkedWorkspace(ws.id);
                  }}
                />
                <LinkRouter
                  to={`/a/${ws.uid}/`}
                  style={{ fontSize: '14px', fontWeight: 'bold' }}
                >
                  {ws.name}
                </LinkRouter>
              </Grid>
            </Grid>
          );
        })}
      </Grid>
    </Paper>
  );
};

const Bookmark = ({
  isActive,
  ...props
}: { isActive: boolean } & SvgIconProps): JSX.Element => {
  return isActive ? (
    <BookmarkIcon css={styles.bookmarked} color="primary" {...props} />
  ) : (
    <BookmarkBorder css={styles.unbookmarked} {...props} />
  );
};

const SystemMenu = (): JSX.Element | null => {
  const { globalState } = useContext(Store);
  const classes = useStyles();

  const isLocaopsAdmin =
    globalState.signedUser?.workspaces.find((w) => {
      return w.uid === 'adlive' && w.role === 0;
    }) != undefined;

  if (!isLocaopsAdmin) {
    return null;
  }
  return (
    <Paper className={classes.paper}>
      <Grid
        container
        spacing={3}
        alignItems="center"
        style={{ padding: '20px' }}
      >
        <Grid item container xs={10} alignItems="center">
          <h3 className={classes.title}>
            システム管理(ロカオプワークスペースの管理者に表示)
          </h3>
        </Grid>
        <Grid item container xs={12} alignItems="center">
          <Grid
            item
            container
            xs={12}
            alignItems="center"
            style={{ padding: '12px' }}
          >
            <LinkRouter
              to="/workspaces/new"
              style={{ fontSize: '14px', fontWeight: 'bold' }}
            >
              ワークスペースの新規作成
            </LinkRouter>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

const Announcements = (): JSX.Element | null => {
  const classes = useStyles();
  return (
    <Paper className={classes.paper}>
      <Grid container direction="row">
        <AnnouncementIcon />
        <h3 className={classes.title}>お知らせ</h3>
      </Grid>
      <Informations />
    </Paper>
  );
};

const Medias = (): JSX.Element | null => {
  const classes = useStyles();
  const locaopMediaItemsQuery = useLocaopMediaItems();

  return (
    <Paper className={classes.paper}>
      <Grid
        container
        spacing={3}
        alignItems="center"
        style={{ padding: '20px' }}
      >
        <Grid container direction="row">
          <MenuBookIcon />
          <h3 className={classes.title}>お役立ち記事</h3>
        </Grid>
        {locaopMediaItemsQuery.isLoading ? (
          <CircularProgress />
        ) : (
          locaopMediaItemsQuery.data.slice(0, 5).map((item: any) => {
            return (
              <Grid
                item
                container
                xs={12}
                alignItems="center"
                key={item.id}
                style={{ padding: '12px' }}
              >
                <Link
                  href={`${item.url}?utm_source=locaop_meo_app`}
                  rel="noreferrer noopener"
                  target="_blank"
                  style={{ fontSize: '14px', fontWeight: 'bold' }}
                >
                  {item.title}
                  <OpenInNewIcon style={{ fontSize: '14px' }} />
                </Link>
              </Grid>
            );
          })
        )}
      </Grid>
    </Paper>
  );
};
