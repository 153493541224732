/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import {
  Button,
  Dialog,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import SettingsIcon from '@material-ui/icons/Settings';
import dayjs from 'dayjs';
import moment from 'moment';
import React, { useState } from 'react';
import { WorkspaceAccount } from '../api/use-workspace-accounts';
import { WorkspaceInvitation } from '../api/use-workspace-invitations';
import { useSizeType } from '../hooks/use-size-type';

const styles = {
  tableWrapper: css`
    margin: 16px auto;
  `,
  buttonWrapper: css`
    text-align: center;
    padding: 0 16px;
  `,
  labelCell: css`
    width: 110px;
    padding: 16px 8px 16px 16px;
  `,
  valueCell: css`
    padding: 16px 8px;
  `,
};

interface Props {
  workspaceUid: string;
  invitations: WorkspaceInvitation[];
  accounts: WorkspaceAccount[];
  onRemoveInvitation: (invitation: WorkspaceInvitation) => void;
  onResendInvitation: (invitation: WorkspaceInvitation) => void;
  onRemoveAccount: (account: WorkspaceAccount) => void;
  onChangeAccountRole: (account: WorkspaceAccount, role: number) => void;
  isSpSize: boolean;
}

const ROLE_NAMES = ['管理者', '一般ユーザー'];

export default function AccountsTable(props: Props) {
  const { accounts, invitations, isSpSize } = props;
  const [openChangeRoleDialog, setOpenChangeRoleDialog] = useState(false);
  const [selectedWorkspaceAccount, setSelectedWorkspaceAccount] = useState<
    WorkspaceAccount | undefined
  >(undefined);

  const handleChangeRole = (account: WorkspaceAccount) => {
    setSelectedWorkspaceAccount(account);
    setOpenChangeRoleDialog(true);
  };

  const handleCloseChangeRoleDialog = () => {
    setSelectedWorkspaceAccount(undefined);
    setOpenChangeRoleDialog(false);
  };

  const changeRoleDialog = () => {
    if (!selectedWorkspaceAccount) {
      return null;
    }
    return (
      <Dialog onClose={handleCloseChangeRoleDialog} open={openChangeRoleDialog}>
        <DialogTitle>
          {selectedWorkspaceAccount?.name}の新しい権限を選択してください
        </DialogTitle>
        <List>
          <ListItem
            button
            onClick={() => {
              props.onChangeAccountRole(selectedWorkspaceAccount, 0);
              setOpenChangeRoleDialog(false);
            }}
          >
            <ListItemText
              primary={`管理者${
                selectedWorkspaceAccount.role == 0 ? '(現在の権限)' : ''
              }`}
              secondary="クライアントの追加・編集、メンバーの招待・削除などが実行できます。ワークスペース内のすべてのクライアントが利用できます。"
            />
          </ListItem>
          <ListItem
            button
            onClick={() => {
              props.onChangeAccountRole(selectedWorkspaceAccount, 1);
              setOpenChangeRoleDialog(false);
            }}
          >
            <ListItemText
              primary={`一般ユーザー${
                selectedWorkspaceAccount.role == 1 ? '(現在の権限)' : ''
              }`}
              secondary="招待されたクライアントのみ閲覧、編集ができます。"
            />
          </ListItem>
        </List>
      </Dialog>
    );
  };

  return (
    <>
      {changeRoleDialog()}
      {isSpSize ? (
        <>
          {invitations.map((invitation, index) => {
            const diff = moment(invitation.expiredAt).diff(moment(), 'days');
            return (
              <TableContainer
                component={Paper}
                key={`invitation${index}`}
                css={styles.tableWrapper}
              >
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell css={styles.labelCell}>招待中</TableCell>
                      <TableCell css={styles.valueCell}>
                        {invitation.email}
                        <br />(
                        {diff > 0 ? `有効期限: 残り${diff}日` : '期限切れ'})
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell colSpan={2} css={styles.buttonWrapper}>
                        <Button
                          color="primary"
                          startIcon={<DeleteIcon />}
                          onClick={() => {
                            props.onResendInvitation(invitation);
                          }}
                        >
                          招待メールを再送
                        </Button>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell colSpan={2} css={styles.buttonWrapper}>
                        <Button
                          color="primary"
                          startIcon={<DeleteIcon />}
                          onClick={() => {
                            props.onRemoveInvitation(invitation);
                          }}
                        >
                          招待をキャンセル
                        </Button>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            );
          })}
          {accounts.map((account, index) => (
            <TableContainer
              component={Paper}
              key={`account${index}`}
              css={styles.tableWrapper}
            >
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell css={styles.labelCell}>
                      {ROLE_NAMES[account.role]}
                    </TableCell>
                    <TableCell css={styles.valueCell}>
                      {account.name}
                      <br />({account.email})
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={2} css={styles.buttonWrapper}>
                      <Button
                        color="primary"
                        startIcon={<SettingsIcon />}
                        onClick={() => {
                          handleChangeRole(account);
                        }}
                        css={css`
                          margin-right: 8px;
                        `}
                      >
                        権限を変更
                      </Button>
                      <Button
                        color="primary"
                        startIcon={<DeleteIcon />}
                        onClick={() => {
                          props.onRemoveAccount(account);
                        }}
                        css={css`
                          margin-left: 8px;
                        `}
                      >
                        削除
                      </Button>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          ))}
        </>
      ) : (
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>メンバー</TableCell>
                <TableCell>メールアドレス</TableCell>
                <TableCell>権限</TableCell>
                <TableCell>最終ログイン</TableCell>
                <TableCell>アクション</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {invitations.map((invitation, index) => {
                const diff = moment(invitation.expiredAt).diff(
                  moment(),
                  'days'
                );
                return (
                  <TableRow key={invitation.id}>
                    <TableCell scope="row">
                      {invitation.email}(招待中、
                      {diff > 0 ? `有効期限: 残り${diff}日` : '期限切れ'})
                    </TableCell>
                    <TableCell scope="row"></TableCell>
                    <TableCell scope="row">
                      {ROLE_NAMES[invitation.role]}
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell scope="row">
                      <Button
                        color="primary"
                        startIcon={<DeleteIcon />}
                        onClick={() => {
                          props.onRemoveInvitation(invitation);
                        }}
                      >
                        招待をキャンセル
                      </Button>
                      <Button
                        color="primary"
                        startIcon={<DeleteIcon />}
                        onClick={() => {
                          props.onResendInvitation(invitation);
                        }}
                      >
                        招待メールを再送
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })}
              {accounts.map((account, index) => (
                <TableRow key={account.id}>
                  <TableCell scope="row">{account.name}</TableCell>
                  <TableCell scope="row">{account.email}</TableCell>
                  <TableCell scope="row">{ROLE_NAMES[account.role]}</TableCell>
                  <TableCell scope="row">
                    {account.lastLoginAt &&
                      dayjs(account.lastLoginAt).format('YYYY/MM/DD HH:mm:ss')}
                  </TableCell>
                  <TableCell scope="row">
                    <Button
                      color="primary"
                      startIcon={<DeleteIcon />}
                      onClick={() => {
                        props.onRemoveAccount(account);
                      }}
                    >
                      削除
                    </Button>
                    <Button
                      color="primary"
                      startIcon={<SettingsIcon />}
                      onClick={() => {
                        handleChangeRole(account);
                      }}
                    >
                      権限を変更
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
}
