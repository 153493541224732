import axios, { AxiosResponse } from 'axios';
import { useContext, useEffect, useState } from 'react';
import { ResponseBody } from '../../../frontend-api/src/handlers/form-setting/form-setting-handler';
import { Store } from '../context/GlobalStore';
import { FormSetting } from '../core/types/reservation-form-types';
import { API_END_POINT } from './api';

type CacheKey = `${string}/${string}`;

const createCacheKey = (
  workspaceUid: string,
  courseId: string | undefined
): CacheKey => {
  return `${workspaceUid}/${courseId}` as CacheKey;
};
const cache: { [cacheKey: string]: FormSetting } = {};

export default function useFormSetting(
  workspaceUid: string,
  courseId: string | undefined
) {
  const { globalState, setGlobalState } = useContext(Store);
  const cacheKey = createCacheKey(workspaceUid, courseId);
  const [formSetting, setFormSetting] = useState(
    cache[cacheKey] || ([] as FormSetting[])
  );
  const [loading, setLoading] = useState(cache[cacheKey] == undefined);
  useEffect(() => {
    if (!globalState.session?.idToken || !courseId) {
      return;
    }
    axios
      .get(
        `${API_END_POINT}/app/ws/${workspaceUid}/courses/${courseId}/form-setting`,
        {
          headers: {
            Authorization: globalState.session?.idToken,
          },
        }
      )
      .then((response: AxiosResponse<ResponseBody>) => {
        setFormSetting(response.data.formSetting);
        cache[cacheKey] = response.data.formSetting;
      })
      .finally(() => {
        setLoading(false);
      });
  }, [workspaceUid, courseId, globalState.session?.idToken]);
  return {
    formSetting,
    isLoadingFormSetting: loading,
  };
}
