/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { FaLongArrowAltDown, FaLongArrowAltUp } from 'react-icons/fa';
import { calcGrowthPercent, styles } from '../utils/insight-utils';

export function ComparisonItemValueWithArrow({
  primaryValue,
  comparisonValue,
  isColorReversed = false,
}: {
  primaryValue: number;
  comparisonValue: number;
  isColorReversed?: boolean;
}): JSX.Element {
  const { value, displayValue } = calcGrowthPercent(
    primaryValue,
    comparisonValue
  );

  const buildArrow = () => {
    if (!isFinite(value)) {
      return <p css={styles.rateArrowContainer}>-</p>;
    }

    if (value > 0) {
      return (
        <p css={styles.rateArrowContainer}>
          <FaLongArrowAltUp
            css={css`
              color: ${isColorReversed ? 'red' : 'green'};
            `}
          />
          {displayValue}
          <span css={styles.tableItemPercent} />
        </p>
      );
    }

    if (value < 0) {
      return (
        <p css={styles.rateArrowContainer}>
          <FaLongArrowAltDown
            css={css`
              color: ${isColorReversed ? 'green' : 'red'};
            `}
          />
          {displayValue}
          <span css={styles.tableItemPercent} />
        </p>
      );
    }

    return <p css={styles.rateArrowContainer}>-</p>;
  };

  return (
    <div css={styles.tableNumberCell}>
      <p
        css={css`
          margin: 0;
        `}
      >
        <span css={styles.tableItemValue}>
          {comparisonValue.toLocaleString()}
        </span>
        <span css={styles.tableItemPercent}>(100.0%)</span>
      </p>
      {buildArrow()}
    </div>
  );
}
