import { AxiosResponse } from 'axios';
import { NewResourceSchedulePatternRequestData } from '../../../@interfaces/resource/apis';
import { API_END_POINT } from '../../../api/api';
import { useMutation } from '../../common/api/use-mutation';

export const useCreateResourceSchedulePatterns = (): ((
  shopId: string,
  params: NewResourceSchedulePatternRequestData
) => Promise<AxiosResponse<''>>) => {
  const { request } = useMutation<'', NewResourceSchedulePatternRequestData>();
  return (shopId, params) => {
    return request(
      'post',
      `${API_END_POINT}/app/shops/${shopId}/resource-schedule-patterns`,
      {},
      params
    );
  };
};
