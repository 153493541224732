/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { Box, Button, Checkbox, Grid, TextField } from '@material-ui/core';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import { Alert, Autocomplete } from '@material-ui/lab';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import React, { useContext, useMemo, useState } from 'react';
import useReactRouter from 'use-react-router';
import { ShopAccount } from '../../../@interfaces/shop-account';
import { ShopInvitationWithEmail } from '../../../@interfaces/shop-invitation';
import { WorkspaceAccountWithAccount } from '../../../@interfaces/workspace-account';
import { API_END_POINT } from '../../../api/api';
import useShop from '../../../api/use-shop';
import useShopAccounts from '../../../api/use-shop-accounts';
import useShopInvitations from '../../../api/use-shop-invitations';
import useWorkspaceAccounts from '../../../api/use-workspace-accounts';
import useWorkspaceInvitations, {
  WorkspaceInvitation,
} from '../../../api/use-workspace-invitations';
import {
  BreadcrumbLinkItem,
  BreadcrumbShopItem,
  BreadcrumbTextItem,
  BreadcrumbWorkspaceItem,
} from '../../../components/breadcrumb/BreadcrumbItem';
import { ShopPageLayout } from '../../../components/layouts/ShopPageLayout';
import { PageTitleAndDescription } from '../../../components/PageTitleAndDescription';
import {
  FullscreenLoading,
  Head,
  Main,
  Root,
} from '../../../components/Shared';
import Spinner from '../../../components/Spinner';
import { Store } from '../../../context/GlobalStore';
import { globalColors } from '../../../styles/globalColors';
import ShopAccountsTable from '../../../tables/ShopAccountsTable';
import ShopInvitationsTable from '../../../tables/ShopInvitationsTable';
import { helps } from '../../../utils/helps';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

interface PageParams {
  workspaceUid: string;
  shopId: string;
}

type WorkspaceAccountWithType = WorkspaceAccountWithAccount & {
  type: 'account';
};

type WorkspaceInvitationWithType = WorkspaceInvitation & {
  type: 'invitation';
};

type Option = WorkspaceAccountWithType | WorkspaceInvitationWithType;

export default function ShopMemberSettingPage(props: any) {
  const { globalState } = useContext(Store);
  const { match } = useReactRouter<PageParams>();
  const { workspaceUid, shopId } = match.params;
  const { shop } = useShop(shopId);
  const { shopAccounts, isLoadingShopAccounts, reloadShopAccounts } =
    useShopAccounts(shopId);
  const { shopInvitations, isLoadingShopInvitations, reloadShopInvitations } =
    useShopInvitations(shopId);
  const { workspaceAccounts } = useWorkspaceAccounts(workspaceUid);
  const { invitations } = useWorkspaceInvitations(workspaceUid);
  const [selectedOptions, setSelectedOptions] = useState([] as Option[]);
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const snackbar = useSnackbar();

  const options: Option[] = useMemo(() => {
    const outsiderAccounts: WorkspaceAccountWithType[] = workspaceAccounts
      .filter((workspaceAccount) => {
        return (
          shopAccounts.find(
            (shopAccount) =>
              shopAccount.workspaceAccountId === workspaceAccount.id
          ) === undefined
        );
      })
      .map((it) => {
        return { ...it, type: 'account' };
      });
    const outsiderInvitations: WorkspaceInvitationWithType[] = invitations
      .filter((workspaceInvitation) => {
        return (
          shopInvitations.find(
            (shopInvitation) =>
              shopInvitation.email === workspaceInvitation.email
          ) === undefined
        );
      })
      .map((it) => {
        return { ...it, type: 'invitation' };
      });
    return [...outsiderInvitations, ...outsiderAccounts];
  }, [workspaceAccounts, shopAccounts, invitations, shopInvitations]);
  const handleChange = (event: React.ChangeEvent<{}>, newValue: Option[]) => {
    setSelectedOptions(newValue);
  };

  const handleAdd = () => {
    if (selectedOptions.length === 0) {
      return;
    }
    setOpenBackdrop(true);
    Promise.all([addAccounts(), addInvitations()])
      .then(() => {
        snackbar.enqueueSnackbar(
          `${selectedOptions.length}件の店舗メンバーを追加しました。`,
          {
            variant: 'success',
          }
        );
      })
      .finally(() => {
        setSelectedOptions([]);
        setOpenBackdrop(false);
      });
  };

  const addAccounts = async () => {
    const selectedAccounts = selectedOptions.filter(
      (it) => it.type === 'account'
    );
    if (selectedAccounts.length === 0) {
      return new Promise((resolve) => {
        resolve(true);
      });
    }
    const json = {
      workspaceAccountIds: selectedAccounts.map((it) => it.id),
    };
    return axios
      .post(`${API_END_POINT}/app/shops/${shopId}/accounts`, json, {
        headers: {
          Authorization: globalState.session?.idToken,
        },
      })
      .then(() => {
        reloadShopAccounts();
      });
  };

  const addInvitations = async () => {
    const selectedInvitations = selectedOptions.filter(
      (it) => it.type === 'invitation'
    );
    if (selectedInvitations.length === 0) {
      return new Promise((resolve) => {
        resolve(true);
      });
    }
    const json = {
      workspaceInvitationIds: selectedInvitations.map((it) => it.id),
    };
    return axios
      .post(`${API_END_POINT}/app/shops/${shopId}/invitations`, json, {
        headers: {
          Authorization: globalState.session?.idToken,
        },
      })
      .then(() => {
        reloadShopInvitations();
      });
  };

  const handleRemoveAccount = (shopAccount: ShopAccount) => {
    setOpenBackdrop(true);
    axios
      .delete(
        `${API_END_POINT}/app/shops/${shopId}/accounts/${shopAccount.id}`,
        {
          headers: {
            Authorization: globalState.session?.idToken,
          },
        }
      )
      .then(() => {
        snackbar.enqueueSnackbar('店舗メンバーから削除しました。', {
          variant: 'success',
        });
        reloadShopAccounts();
      })
      .finally(() => {
        setOpenBackdrop(false);
      });
  };

  const handleRemoveInvitation = (shopInvitation: ShopInvitationWithEmail) => {
    setOpenBackdrop(true);
    axios
      .delete(
        `${API_END_POINT}/app/shops/${shopId}/invitations/${shopInvitation.workspaceInvitationId}`,
        {
          headers: {
            Authorization: globalState.session?.idToken,
          },
        }
      )
      .then(() => {
        snackbar.enqueueSnackbar('店舗メンバーから削除しました。', {
          variant: 'success',
        });
        reloadShopInvitations();
      })
      .finally(() => {
        setOpenBackdrop(false);
      });
  };

  const toLabel = (option: Option) => {
    if (option.type === 'account') {
      return `${option.name}(${option.email})`;
    } else if (option.type === 'invitation') {
      return `${option.email}(ワークスペースに招待中)`;
    } else {
      return 'Unknown';
    }
  };

  const buildContents = () => (
    <>
      <h3>メンバー</h3>
      <Alert severity="info">
        ワークスペースのメンバーを店舗メンバーとして招待します。
      </Alert>
      <Grid
        container
        spacing={3}
        alignItems="center"
        css={css`
          margin-top: 8px;
        `}
      >
        <Grid item xs={12} md={8}>
          <Autocomplete
            multiple
            id="tags-standard"
            fullWidth={true}
            onChange={handleChange}
            options={options}
            disableCloseOnSelect
            getOptionLabel={(option) => toLabel(option)}
            value={selectedOptions}
            renderOption={(option, { selected }) => (
              <React.Fragment>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {toLabel(option)}
              </React.Fragment>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                label="追加するメンバーを選択"
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Button
            type="button"
            variant="contained"
            color="primary"
            onClick={handleAdd}
          >
            メンバーを追加
          </Button>
        </Grid>
      </Grid>
      <Box mt={3}>
        {isLoadingShopAccounts || isLoadingShopInvitations ? (
          <Spinner loading={true} />
        ) : (
          <>
            {shopInvitations.length === 0 ? null : (
              <>
                <ShopInvitationsTable
                  shopInvitations={shopInvitations}
                  onRemove={handleRemoveInvitation}
                />
                <div style={{ height: '20px' }}>&nbsp;</div>
              </>
            )}
            <ShopAccountsTable
              shopAccounts={shopAccounts}
              onRemove={handleRemoveAccount}
            />
          </>
        )}
      </Box>
    </>
  );

  const pageTitle = 'メンバー設定';

  return (
    <Root>
      <Head title={`店舗メンバー - ${shop?.name || ''}`} />
      <Main>
        <ShopPageLayout
          workspaceUid={workspaceUid}
          shopId={shopId}
          current="member"
          helpId={helps.shop.setting.member}
          breadcrumbs={
            <Breadcrumbs aria-label="breadcrumb">
              <BreadcrumbLinkItem to={`/`}>ホーム</BreadcrumbLinkItem>
              <BreadcrumbWorkspaceItem workspaceUid={workspaceUid} />
              <BreadcrumbShopItem workspaceUid={workspaceUid} shop={shop} />
              <BreadcrumbTextItem>{pageTitle}</BreadcrumbTextItem>
            </Breadcrumbs>
          }
        >
          <PageTitleAndDescription
            title="店舗"
            subTitle={pageTitle}
            description="予約を管理する店舗スタッフの設定を行います。"
            themeColor={globalColors.shop}
          />
          {buildContents()}
        </ShopPageLayout>
      </Main>
      <FullscreenLoading open={openBackdrop} />
    </Root>
  );
}
