export const ROLE_ADMIN = 0;
export const ROLE_NORMAL = 1;
export const ROLES = [ROLE_ADMIN, ROLE_NORMAL];

export interface WorkspaceAccount {
  id: number;
  workspaceId: number;
  accountId: number;
  role: number;
}

export interface WorkspaceAccountWithAccount extends WorkspaceAccount {
  name: string;
  email: string;
  lastLoginAt: string | null;
}
