/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { Payload } from 'recharts/types/component/DefaultLegendContent';

export const LegendWithNoComparison = (props: { payload?: Payload[] }) => {
  const { payload } = props;
  if (payload === undefined) {
    return null;
  }

  const filteredPayload = payload.filter((entry) => {
    if (entry.inactive === true) {
      return false;
    }

    const dataKey = entry.dataKey;
    if (dataKey === undefined || typeof dataKey !== 'string') {
      return true;
    }

    return !dataKey.startsWith('comparison_');
  });

  return (
    <div
      css={css`
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        column-gap: 8px;
      `}
    >
      {filteredPayload.map((entry, index) => (
        <div
          key={`item-${index}`}
          css={css`
            color: ${entry.color};
          `}
        >
          <span
            css={css`
              flex: 0 0 8px;
              display: inline-block;
              background-color: ${entry.color};
              width: 8px;
              height: 8px;
              margin-right: 4px;
            `}
          />
          {entry.value}
        </div>
      ))}
    </div>
  );
};
