import { NewCourseFormSetting } from '../@interfaces/course-form-setting';
import { Label } from '../@interfaces/label';
import {
  InflowSourceLabels,
  RichReservation,
} from '../@interfaces/reservation';
import {
  AnyField,
  FieldResponseValue,
  getSpecialFieldValue,
  isSpecialFieldType,
  SpecialFieldType,
} from '../core/types/reservation-form-types';
import {
  DateRange,
  Time,
  toDateStringByDate,
  toTimeStringByTime,
  zeroPadding,
} from '../core/types/reservation-types';
import { writeXlsx } from '../utils/xlsx';

export const findSpecialFieldValue = (
  res: RichReservation,
  type: SpecialFieldType,
  formSettings: NewCourseFormSetting[]
) => {
  const { customer } = res;
  const formSetting = formSettings.find((s) => s.courseId === res.courseId);
  if (!formSetting) {
    return '';
  }
  // 優先度：予約情報の値 -> 顧客情報の値
  const formFieldValue =
    getSpecialFieldValue(formSetting.settingJson, res.formResponse, type) || '';
  if (formFieldValue !== '') {
    return formFieldValue;
  } else if (
    formFieldValue === '' &&
    customer?.[type] !== null &&
    customer?.[type] !== undefined &&
    customer?.[type] != ''
  ) {
    return customer[type];
  } else {
    return formFieldValue;
  }
};

export const buildValueString = (
  field: AnyField,
  values: FieldResponseValue[] | undefined
) => {
  const valuesString =
    values
      ?.map((value) => {
        if (field.type === 'number') {
          return value;
        } else if (typeof value === 'string') {
          return value;
        } else if (field.type === 'checkbox' || field.type === 'radio') {
          return (
            field.options.find((option) => option.uid === value.uid)?.text ||
            value
          );
        } else {
          return value;
        }
      })
      .join(', ') || '';

  return `${field.name}: ${valuesString}`;
};

export const writeReservationXlsx = (
  reservations: RichReservation[],
  shopName: string,
  range: DateRange,
  formSettings: NewCourseFormSetting[],
  labels: Label[]
) => {
  const baseHeaders = [
    'ID',
    '日付',
    '時間',
    'コース',
    '名前',
    'メールアドレス',
    '電話番号',
    'その他項目',
    '流入元',
    'メモ',
    '登録日時',
    '最終更新日時',
  ];
  const labelHeaders = labels.map((l) => l.name);
  const headers = [...baseHeaders, ...labelHeaders];
  const rows = reservations.map((r) => {
    const formSetting = formSettings.find((s) => s.courseId === r.courseId);
    const otherFieldValues = formSetting?.settingJson.fields
      ?.filter((f) => !isSpecialFieldType(f.type))
      .map((field) => {
        const values = r.formResponse.fields.find(
          (f) => f.uid === field.uid
        )?.values;
        const valueString = buildValueString(field, values);
        return valueString;
      })
      .filter((v) => v !== '');
    const baseColumns = [
      r.id,
      r.reservationDate,
      toTimeStringByTime({
        hour: r.reservationHour,
        minute: r.reservationMinute,
      } as Time),
      r.courseName,
      findSpecialFieldValue(r, 'name', formSettings),
      findSpecialFieldValue(r, 'email', formSettings),
      findSpecialFieldValue(r, 'tel', formSettings),
      otherFieldValues?.join('\n') || '',
      r.inflowSourceLabel,
      r.note,
      r.createdAt,
      r.updatedAt,
    ];
    const labelColumns = labels.map((l) =>
      r.selectedLabelIds.find((labelId) => l.id === labelId) !== undefined
        ? '○'
        : ''
    );
    return [...baseColumns, ...labelColumns];
  });
  // Excelファイル生成
  const startDate = toDateStringByDate(range.start);
  const endDate = toDateStringByDate(range.end);
  const cells = [headers, ...rows];
  const filename = `${shopName}予約一覧_${startDate}_${endDate}.xlsx`;
  const colsWidths = [
    10, 10, 10, 10, 15, 20, 15, 40, 10, 10, 20, 20, 10, 10, 10, 10, 10, 10, 10,
  ];
  writeXlsx(cells, filename, colsWidths);
};

export const writeWorkspaceReservationXlsx = (
  reservations: any[],
  inflowSources: any[],
  labels: any[],
  settingJsons: any[],
  resources: any[],
  range: DateRange,
  enableCrm: boolean
) => {
  // ----------
  // 定数
  // ----------
  // ベースヘッダ
  const BASE_HEADER = [
    'ID',
    '日付',
    '時間',
    '店鋪名',
    'コース',
    '名前',
    'メールアドレス',
    '電話番号',
    '流入元',
    'メモ',
    '登録日時',
    '最終更新日時',
    ...labels.map((label) => label.name),
    ...(resources.length > 0 ? ['リソース'] : []),
    ...(enableCrm
      ? [
          '顧客名前',
          '顧客よみがな',
          '顧客電話番号',
          '顧客メールアドレス',
          '顧客郵便番号',
          '顧客住所',
          '顧客メモ',
        ]
      : []),
    'LINEユーザー名',
  ];
  // フィールドタイプの定数
  const FIELDS_TYPE = {
    NAME: 'name',
    EMAIL: 'email',
    TEL: 'tel',
  };

  // ----------
  // 変数
  // ----------
  // ヘッダーの配列
  const headerRows: Array<string>[] = [];
  // ヘッダー以外の中身の配列
  const contentRows: Array<string>[] = [];
  // フォームの回答をまとめた配列
  const otherFormResponsesArray: Array<string>[] = [];
  // フォームの項目一覧
  const otherSettingJsons: any[] = [];

  // ----------
  // 予約の数で回す 開始
  // ----------
  for (const reservation of reservations) {
    // ----------
    // 変数
    // ----------
    let name,
      nameUid,
      email,
      emailUid,
      tel,
      telUid,
      inflowSource = '';
    // ラベルの中身
    const reservationLabelContents = [];
    // reservationsから値を取得
    const {
      id,
      reservationDate,
      reservationHour,
      reservationMinute,
      shopName,
      courseId,
      courseName,
      formResponse,
      inflowSource: reservationInflowSource, //rename
      note,
      createdAt,
      updatedAt,
      reservationLabelIds,
      reservationResourceIds,
      customerName,
      customerKana,
      customerTel,
      customerEmail,
      customerPostalCode,
      customerAddress,
      customerNote,
      lineDisplayName,
    } = reservation;
    // 時間生成
    const reservationTime = `${reservationHour}:${zeroPadding(
      reservationMinute.toString(),
      2
    )}`;
    // コースIDに紐づくsettingJsonFieldsを取得
    const {
      settingJson: {
        fields: settingJsonFields,
        deletedFields: settingJsonDeleteFields,
      }, // rename
    } = settingJsons.find((settingJson) => settingJson.courseId === courseId);
    // formResponseFieldsを取得
    const {
      fields: formResponseFields, // rename
    } = formResponse;
    const settingJsonAllFields = settingJsonDeleteFields
      ? [...settingJsonFields, ...settingJsonDeleteFields]
      : settingJsonFields;

    // ----------
    // settingJsonFieldsを回す
    // ----------
    for (const settingJsonField of settingJsonAllFields) {
      // settingJsonのtypeとuidを取得
      const { type, uid } = settingJsonField;

      // お名前の場合
      if (type === FIELDS_TYPE.NAME) nameUid = uid;
      // メールアドレスの場合
      else if (type === FIELDS_TYPE.EMAIL) emailUid = uid;
      // 電話番号の場合
      else if (type === FIELDS_TYPE.TEL) telUid = uid;
      // それ以外の処理
      else {
        // フォームの項目一覧 (otherSettingJsons) に、settingJsonがなければ追加し、1つでも存在すれば追加しない
        if (
          !otherSettingJsons.some(
            (otherSettingJson) => otherSettingJson.uid === uid
          )
        ) {
          otherSettingJsons.push(settingJsonField);
        }
      }
    }

    // ----------
    // formResponseFieldsを回す
    // ----------
    // フォームの項目一覧 (otherSettingJsons) の数だけ、回答 (otherFormResponses) の列を作成する
    const otherFormResponses: any[] = new Array(otherSettingJsons.length);

    for (const formResponseField of formResponseFields) {
      const { uid: formResponseFieldUid, values: formResponseFieldValues } =
        formResponseField;

      // お名前
      if (formResponseFieldUid === nameUid)
        name = formResponseFieldValues && formResponseFieldValues[0];
      // メールアドレス
      else if (formResponseFieldUid === emailUid)
        email = formResponseFieldValues && formResponseFieldValues[0];
      // 電話番号
      else if (formResponseFieldUid === telUid)
        tel = formResponseFieldValues && formResponseFieldValues[0];
      // それ以外の処理
      else {
        // フォームの項目一覧に、formResponseFieldと同じものがあるか確認
        // あればインデックスを取得する
        const index = otherSettingJsons.findIndex((otherSettingJson) => {
          return otherSettingJson.uid === formResponseFieldUid;
        });
        const settingJsonField = settingJsonAllFields.find(
          (field: any) => field.uid == formResponseFieldUid
        );
        if (index !== -1 && settingJsonField) {
          // インデックスが存在する場合
          const { type } = otherSettingJsons[index];

          // settingJsonのtypeを確認
          if (type === 'radio' || type === 'checkbox') {
            const df = settingJsonDeleteFields?.find(
              (d: AnyField) => d.uid === formResponseFieldUid
            );
            const newSettingJsonField = {
              ...settingJsonField,
              options: df
                ? [...df.options, ...settingJsonField.options]
                : [...settingJsonField.options],
            };
            const { options = [] } = newSettingJsonField;

            // 選択肢単一選択、または選択肢複数選択の場合
            let filteredOptionsText = '';

            // formResponseFieldValuesを回す
            for (const value of formResponseFieldValues) {
              // optionsの中に、valueのuidがあれば取り出す (複数)
              const filteredOptions = options.filter(
                (option: { uid: string; text: string }) => {
                  return option.uid === value.uid;
                }
              );

              if (filteredOptions.length === 1) {
                filteredOptionsText += `${filteredOptions[0]?.text}\n`;
              }
            }

            // 回答に追加する
            otherFormResponses[index] = filteredOptionsText;
          } else {
            // 回答に追加する
            otherFormResponses[index] = formResponseFieldValues[0];
          }
        } else {
          // インデックスが存在しなければ、何かの処理を行う
          // TODO: 削除された質問がある場合の処理
        }
      }
    }
    // 回答の配列に回答を入れる
    otherFormResponsesArray.push(otherFormResponses);

    // ----------
    // 流入元
    // ----------
    // reservationInflowSource (stringのuid) と合致するものがinflowSourcesにあれば取得、
    const inflowSourceName = inflowSources.find(
      (inflowSource) => inflowSource.uid === reservationInflowSource
    );
    // 合致するものがなければ'不明'をセットする
    inflowSource =
      inflowSourceName?.name ||
      InflowSourceLabels[reservationInflowSource] ||
      reservationInflowSource ||
      '不明';

    // ----------
    // ラベルで回す
    // ----------
    for (const label of labels) {
      // ラベルのIDを取得
      const { id } = label;
      // ラベルの中身を入れる
      reservationLabelContents.push(
        reservationLabelIds.includes(id) ? '○' : ''
      );
    }

    // ----------
    // 選択されたの一覧を作成
    // ----------
    const reservationResourceContents = reservationResourceIds.map(
      (resourceId: any) =>
        resources.find((r) => r.id === resourceId)?.name || '不明なリソース'
    );
    // ----------
    // ヘッダー以外の中身の配列に中身を入れる
    // ----------
    contentRows.push([
      // ID
      id,
      // 日付
      reservationDate,
      // 時間
      reservationTime,
      // 店鋪名
      shopName,
      // コース
      courseName,
      // 名前
      name,
      // メールアドレス
      email,
      // 電話番号
      tel,
      // 流入元
      inflowSource,
      // メモ
      note,
      // 登録日時
      createdAt,
      // 最終更新日時
      updatedAt,
      // ラベル
      ...reservationLabelContents,
      // リソース
      ...(resources.length > 0 ? [reservationResourceContents.join('\n')] : []),
      // 顧客情報
      ...(enableCrm
        ? [
            customerName,
            customerKana,
            customerTel,
            customerEmail,
            customerPostalCode,
            customerAddress,
            customerNote,
          ]
        : []),
      lineDisplayName,
    ]);
  }
  // ----------
  // 予約の数で回す 終了
  // ----------

  // ----------
  // ヘッダーの調整
  // ----------
  // ヘッダーにベースヘッダーを追加する
  headerRows.push([...BASE_HEADER]);
  // 予約を回し終わったら、最後にヘッダにフォームの項目一覧を追加する (電話番号の直後)
  const otherSettingJsonNames: string[] = otherSettingJsons.map(
    (otherSettingJson) => otherSettingJson.name
  );
  headerRows[0].splice(8, 0, ...otherSettingJsonNames);

  // ----------
  // フォーム回答の調整
  // ----------
  // 回答の中身の配列を回す
  for (let i = 0; i < otherFormResponsesArray.length; i++) {
    // 1行分の回答配列の変数
    const otherFormResponse = [];

    // フォームの最大数だけ回す
    for (let j = 0; j < otherSettingJsons.length; j++) {
      // 現在の回答配列の位置 i x 現在のフォーム配列の位置 j に存在する回答の中身があれば、
      // 1行分の回答配列に入れるが、中身がなければnullを入れる (セルの空白調整のため)
      otherFormResponse[j] = otherFormResponsesArray[i][j] ?? null;
    }

    // フォームの最大数 (1行分) を回し終わったら、中身をその1行の電話番号の直後に挿入する
    contentRows[i] && contentRows[i].splice(8, 0, ...otherFormResponse);
  }

  // ----------
  // セルの調整
  // ----------
  // エクセル生成のメソッドに渡すセルデータを作成する
  const cells = [...headerRows, ...contentRows];

  // ----------
  // Excelファイル生成
  // ----------
  const startDate = toDateStringByDate(range.start);
  const endDate = toDateStringByDate(range.end);
  const filename = `予約一覧_${startDate}_${endDate}.xlsx`;

  writeXlsx(cells, filename);
};

export const writeShopReservationXlsx = (
  reservations: any[],
  inflowSources: any[],
  labels: any[],
  settingJsons: any[],
  resources: any[],
  range: DateRange,
  shopName: string,
  enableCrm: boolean
) => {
  // ----------
  // 定数
  // ----------
  // ベースヘッダ
  const BASE_HEADER = [
    'ID',
    '日付',
    '時間',
    'コース',
    '名前',
    'メールアドレス',
    '電話番号',
    '流入元',
    'メモ',
    '登録日時',
    '最終更新日時',
    ...labels.map((label) => label.name),
    ...(resources.length > 0 ? ['リソース'] : []),
    ...(enableCrm
      ? [
          '顧客名前',
          '顧客よみがな',
          '顧客電話番号',
          '顧客メールアドレス',
          '顧客郵便番号',
          '顧客住所',
          '顧客メモ',
        ]
      : []),
    'LINEユーザー名',
  ];
  // フィールドタイプの定数
  const FIELDS_TYPE = {
    NAME: 'name',
    EMAIL: 'email',
    TEL: 'tel',
  };

  // ----------
  // 変数
  // ----------
  // ヘッダーの配列
  const headerRows: Array<string>[] = [];
  // ヘッダー以外の中身の配列
  const contentRows: Array<string>[] = [];
  // フォームの回答をまとめた配列
  const otherFormResponsesArray: Array<string>[] = [];
  // フォームの項目一覧
  const otherSettingJsons: any[] = [];

  // ----------
  // 予約の数で回す 開始
  // ----------
  for (const reservation of reservations) {
    // ----------
    // 変数
    // ----------
    let name,
      nameUid,
      email,
      emailUid,
      tel,
      telUid,
      inflowSource = '';
    // ラベルの中身
    const reservationLabelContents = [];
    // reservationsから値を取得
    const {
      id,
      reservationDate,
      reservationHour,
      reservationMinute,
      courseId,
      courseName,
      formResponse,
      inflowSource: reservationInflowSource, //rename
      note,
      createdAt,
      updatedAt,
      reservationLabelIds,
      reservationResourceIds,
      customerName,
      customerKana,
      customerTel,
      customerEmail,
      customerPostalCode,
      customerAddress,
      customerNote,
      lineDisplayName,
    } = reservation;
    // 時間生成
    const reservationTime = `${reservationHour}:${zeroPadding(
      reservationMinute.toString(),
      2
    )}`;
    // コースIDに紐づくsettingJsonFieldsを取得
    const {
      settingJson: {
        fields: settingJsonFields,
        deletedFields: settingJsonDeleteFields,
      }, // rename
    } = settingJsons.find((settingJson) => settingJson.courseId === courseId);
    // formResponseFieldsを取得
    const {
      fields: formResponseFields, // rename
    } = formResponse;
    const settingJsonAllFields = settingJsonDeleteFields
      ? [...settingJsonFields, ...settingJsonDeleteFields]
      : settingJsonFields;
    // ----------
    // settingJsonFieldsを回す
    // ----------
    for (const settingJsonField of settingJsonAllFields) {
      // settingJsonのtypeとuidを取得
      const { type, uid } = settingJsonField;

      // お名前の場合
      if (type === FIELDS_TYPE.NAME) nameUid = uid;
      // メールアドレスの場合
      else if (type === FIELDS_TYPE.EMAIL) emailUid = uid;
      // 電話番号の場合
      else if (type === FIELDS_TYPE.TEL) telUid = uid;
      // それ以外の処理
      else {
        // フォームの項目一覧 (otherSettingJsons) に、settingJsonがなければ追加し、1つでも存在すれば追加しない
        if (
          !otherSettingJsons.some(
            (otherSettingJson) => otherSettingJson.uid === uid
          )
        ) {
          otherSettingJsons.push(settingJsonField);
        }
      }
    }
    // ----------
    // formResponseFieldsを回す
    // ----------
    // フォームの項目一覧 (otherSettingJsons) の数だけ、回答 (otherFormResponses) の列を作成する
    const otherFormResponses: any[] = new Array(otherSettingJsons.length);

    for (const formResponseField of formResponseFields) {
      const { uid: formResponseFieldUid, values: formResponseFieldValues } =
        formResponseField;

      // お名前
      if (formResponseFieldUid === nameUid)
        name = formResponseFieldValues && formResponseFieldValues[0];
      // メールアドレス
      else if (formResponseFieldUid === emailUid)
        email = formResponseFieldValues && formResponseFieldValues[0];
      // 電話番号
      else if (formResponseFieldUid === telUid)
        tel = formResponseFieldValues && formResponseFieldValues[0];
      // それ以外の処理
      else {
        // フォームの項目一覧に、formResponseFieldと同じものがあるか確認
        // あればインデックスを取得する
        const index = otherSettingJsons.findIndex((otherSettingJson) => {
          return otherSettingJson.uid === formResponseFieldUid;
        });
        const settingJsonField = settingJsonAllFields.find(
          (field: any) => field.uid == formResponseFieldUid
        );
        if (index !== -1 && settingJsonField) {
          // インデックスが存在する場合
          const { type } = otherSettingJsons[index];

          // settingJsonのtypeを確認
          if (type === 'radio' || type === 'checkbox') {
            const df = settingJsonDeleteFields?.find(
              (d: AnyField) => d.uid === formResponseFieldUid
            );
            const newSettingJsonField = {
              ...settingJsonField,
              options: df
                ? [...df.options, ...settingJsonField.options]
                : [...settingJsonField.options],
            };
            const { options = [] } = newSettingJsonField;

            // 選択肢単一選択、または選択肢複数選択の場合
            let filteredOptionsText = '';

            // formResponseFieldValuesを回す
            for (const value of formResponseFieldValues) {
              // optionsの中に、valueのuidがあれば取り出す (複数)
              const filteredOptions = options.filter(
                (option: { uid: string; text: string }) => {
                  return option.uid === value.uid;
                }
              );

              if (filteredOptions.length === 1) {
                filteredOptionsText += `${filteredOptions[0]?.text}\n`;
              }
            }

            // 回答に追加する
            otherFormResponses[index] = filteredOptionsText;
          } else {
            // 回答に追加する
            otherFormResponses[index] = formResponseFieldValues[0];
          }
        } else {
          // インデックスが存在しなければ、何かの処理を行う
          // TODO: 削除された質問がある場合の処理
        }
      }
    }
    // 回答の配列に回答を入れる
    otherFormResponsesArray.push(otherFormResponses);

    // ----------
    // 流入元
    // ----------
    // reservationInflowSource (stringのuid) と合致するものがinflowSourcesにあれば取得、
    const inflowSourceName = inflowSources.find(
      (inflowSource) => inflowSource.uid === reservationInflowSource
    );
    // 合致するものがなければ'不明'をセットする
    inflowSource =
      inflowSourceName?.name ||
      InflowSourceLabels[reservationInflowSource] ||
      reservationInflowSource ||
      '不明';

    // ----------
    // ラベルで回す
    // ----------
    for (const label of labels) {
      // ラベルのIDを取得
      const { id } = label;
      // ラベルの中身を入れる
      reservationLabelContents.push(
        reservationLabelIds.includes(id) ? '○' : ''
      );
    }

    // ----------
    // 選択されたリソースの一覧を作成
    // ----------
    const reservationResourceContents = reservationResourceIds.map(
      (resourceId: any) =>
        resources.find((r) => r.id === resourceId)?.name || '不明なリソース'
    );

    // ----------
    // ヘッダー以外の中身の配列に中身を入れる
    // ----------
    contentRows.push([
      // ID
      id,
      // 日付
      reservationDate,
      // 時間
      reservationTime,
      // コース
      courseName,
      // 名前
      name,
      // メールアドレス
      email,
      // 電話番号
      tel,
      // 流入元
      inflowSource,
      // メモ
      note,
      // 登録日時
      createdAt,
      // 最終更新日時
      updatedAt,
      // ラベル
      ...reservationLabelContents,
      // リソース
      ...(resources.length > 0 ? [reservationResourceContents.join('\n')] : []),
      // 顧客情報
      ...(enableCrm
        ? [
            customerName,
            customerKana,
            customerTel,
            customerEmail,
            customerPostalCode,
            customerAddress,
            customerNote,
          ]
        : []),
      lineDisplayName,
    ]);
  }
  // ----------
  // 予約の数で回す 終了
  // ----------

  // ----------
  // ヘッダーの調整
  // ----------
  // ヘッダーにベースヘッダーを追加する
  headerRows.push([...BASE_HEADER]);
  // 予約を回し終わったら、最後にヘッダにフォームの項目一覧を追加する (電話番号の直後)
  const otherSettingJsonNames: string[] = otherSettingJsons.map(
    (otherSettingJson) => otherSettingJson.name
  );
  headerRows[0].splice(7, 0, ...otherSettingJsonNames);

  // ----------
  // フォーム回答の調整
  // ----------
  // 回答の中身の配列を回す
  for (let i = 0; i < otherFormResponsesArray.length; i++) {
    // 1行分の回答配列の変数
    const otherFormResponse = [];

    // フォームの最大数だけ回す
    for (let j = 0; j < otherSettingJsons.length; j++) {
      // 現在の回答配列の位置 i x 現在のフォーム配列の位置 j に存在する回答の中身があれば、
      // 1行分の回答配列に入れるが、中身がなければnullを入れる (セルの空白調整のため)
      otherFormResponse[j] = otherFormResponsesArray[i][j] ?? null;
    }

    // フォームの最大数 (1行分) を回し終わったら、中身をその1行の電話番号の直後に挿入する
    contentRows[i] && contentRows[i].splice(7, 0, ...otherFormResponse);
  }

  // ----------
  // セルの調整
  // ----------
  // エクセル生成のメソッドに渡すセルデータを作成する
  const cells = [...headerRows, ...contentRows];

  // ----------
  // Excelファイル生成
  // ----------
  const startDate = toDateStringByDate(range.start);
  const endDate = toDateStringByDate(range.end);
  const filename = `${shopName}予約一覧_${startDate}_${endDate}.xlsx`;

  writeXlsx(cells, filename);
};
