/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { Button } from '@material-ui/core';
import { useMemo } from 'react';
import { LuArrowLeftToLine, LuArrowRightToLine } from 'react-icons/lu';
import { atom, useRecoilState } from 'recoil';
import { recoilPersist } from 'recoil-persist';
import { sideMenuStyles } from '../styles';

const { persistAtom } = recoilPersist({
  key: 'recoil-persist',
  storage: localStorage,
});

const state = atom<boolean>({
  key: 'sideMenuExpanded',
  default: true,
  effects_UNSTABLE: [persistAtom],
});

export const useExpandedMenu = () => {
  const [isExpandedSidebar, setIsExpandedSidebar] = useRecoilState(state);

  const expandedSidebarButton = useMemo(() => {
    if (isExpandedSidebar) {
      return (
        <Button
          css={sideMenuStyles.toggleButton}
          startIcon={<LuArrowLeftToLine />}
          onClick={() => {
            setIsExpandedSidebar(!isExpandedSidebar);
          }}
        >
          メニューを隠す
        </Button>
      );
    } else {
      return (
        <>
          <Button
            css={sideMenuStyles.toggleButton}
            onClick={() => {
              setIsExpandedSidebar(!isExpandedSidebar);
            }}
          >
            <div
              css={css`
                display: flex;
                flex-direction: column;
                font-size: 9px;
                align-items: center;
              `}
            >
              <LuArrowRightToLine size={20} />
              <span>メニューを</span>
              <span>表示</span>
            </div>
          </Button>
        </>
      );
    }
  }, [isExpandedSidebar, setIsExpandedSidebar]);

  return {
    isExpandedSidebar,
    expandedSidebarButton,
  };
};
