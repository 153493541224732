/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import {
  Box,
  Breadcrumbs,
  Button,
  FormControlLabel,
  Grid,
  Switch,
  Tab,
  Tabs,
} from '@material-ui/core';
import ListIcon from '@material-ui/icons/List';
import PeopleIcon from '@material-ui/icons/People';
import PrintIcon from '@material-ui/icons/Print';
import { Alert } from '@material-ui/lab';
import dayjs, { Dayjs } from 'dayjs';
import { useSnackbar } from 'notistack';
import { useContext, useEffect, useState } from 'react';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { Link } from 'react-router-dom';
import useReactRouter from 'use-react-router';
import { Course } from '../../@interfaces/course';
import { ReservationAPI } from '../../api/reservations';
import useCountSlotSettingRules from '../../api/use-count-slot-setting-rules';
import useInflowSources from '../../api/use-inflow-sources';
import useLabels from '../../api/use-labels';
import useLineMessagingChannelOverviews from '../../api/use-line-messaging-channel-overviews';
import useReservationTimeTable, {
  ReservationTimeTable,
} from '../../api/use-reservation-time-table';
import useReservations from '../../api/use-reservations';
import useShop from '../../api/use-shop';
import useShopCourses from '../../api/use-shop-courses';
import useShopNotifyDestinations from '../../api/use-shop-notify-destinations';
import useShopSetting from '../../api/use-shop-setting';
import useShops from '../../api/use-shops';
import useWorkspaceSetting from '../../api/use-workspace-setting';
import {
  BreadcrumbLinkItem,
  BreadcrumbShopItem,
  BreadcrumbWorkspaceItem,
} from '../../components/breadcrumb/BreadcrumbItem';
import { ReservationCalendar } from '../../components/calendar/ReservationCalendar';
import { commonCss } from '../../components/common-css';
import { ShopPageLayout } from '../../components/layouts/ShopPageLayout';
import { PageTitleAndDescription } from '../../components/PageTitleAndDescription';
import { ReservationDataDownloadButton } from '../../components/ReservationDataDownloadButton';
import { ShareButton } from '../../components/ShareButton';
import { Head, Main, Root } from '../../components/Shared';
import ShareFormUrlButton from '../../components/ShareFormUrlButton';
import Spinner from '../../components/Spinner';
import { Store } from '../../context/GlobalStore';
import {
  createDate,
  DateFormat,
  IDate,
  Time,
  toDateStringByDayjs,
  toDayjs,
  toTimeStringByTime,
} from '../../core/types/reservation-types';
import { ResourceTimeline } from '../../features/resource/components/ResourceTimeline';
import { useExpandedMenu } from '../../features/side-menu/hooks/use-expanded-menu';
import {
  AUTO_RELOAD_RESERVATIONS_INTERVAL,
  useAutoReloadReservations,
} from '../../hooks/use-auto-reload-reservations';
import { useInterval } from '../../hooks/use-interval';
import {
  MD_BREAKPOINT,
  SM_BREAKPOINT,
  useSizeType,
} from '../../hooks/use-size-type';
import useStorage from '../../hooks/use-storage';
import { localStorageKeys } from '../../models/storage-keys';
import { globalColors } from '../../styles/globalColors';
import ReservationsTable from '../../tables/ReservationsTable';
import ReservationTimeTableView from '../../tables/ReservationTimeTableView';
import { helps } from '../../utils/helps';
import { DisplaySettingButton } from './ShopPageDisplySetting';

const styles = {
  gridWrapper: css`
    margin-top: 20px;
    @media screen and (max-width: ${MD_BREAKPOINT}px) {
      margin-top: 0;
    }
  `,
  sidebarWrapper: css`
    max-width: 400px;
    @media screen and (max-width: ${MD_BREAKPOINT}px) {
      max-width: none;
    }
  `,
  tableWrapper: css`
    @media screen and (max-width: ${SM_BREAKPOINT}px) {
      max-width: calc(100vw - 38px);
      overflow: scroll;
    }
  `,
  buttonsWrapper: css`
    @media screen and (max-width: ${SM_BREAKPOINT}px) {
      margin-top: 16px;
    }
  `,
  title: css`
    display: inline-block;
    @media screen and (max-width: ${SM_BREAKPOINT}px) {
      font-size: 18px;
    }
  `,
  shareUrlButtonWrapper: css`
    @media screen and (max-width: ${SM_BREAKPOINT}px) {
      width: 150px;
    }
  `,
};

function calcReservationListWrapperWidth(
  isExpandedSidebar: boolean,
  isPcSize: boolean
) {
  const sideWidth = isExpandedSidebar ? 300 : 100;

  return css`
    max-width: calc(100vw - 54px - ${isPcSize ? sideWidth : 0}px);
    @media print {
      max-width: 100%;
    }
  `;
}

function calcTableWrapperWidth(isPcSize: boolean) {
  return css`
    max-width: calc(100vw - 54px - ${isPcSize ? 300 : 0}px);
  `;
}

type PageParams = {
  workspaceUid: string;
  shopId: string;
};

export default function ShopPage(props: any) {
  const { history, match } = useReactRouter<PageParams>();
  const { workspaceUid, shopId } = match.params;
  const { inflowSources } = useInflowSources(workspaceUid);
  const { shops } = useShops(workspaceUid);
  const { lineMessagingChannels } =
    useLineMessagingChannelOverviews(workspaceUid);
  const { workspaceSetting } = useWorkspaceSetting(workspaceUid);

  const searchParams = new URL(window.location.href).searchParams;
  const date = searchParams.get('date');
  const [selectedDate, setSelectedDate] = useState(
    date ? toDayjs(createDate(date as DateFormat)) : dayjs()
  );
  const { shop, isLoadingShop } = useShop(shopId);
  const [calendarTimeTable, setCalendarTimeTable] = useState<
    ReservationTimeTable | undefined
  >();
  const { isExpandedSidebar } = useExpandedMenu();
  const { isPcSize } = useSizeType();

  // history.back時にパラメーターから日付を復元
  useEffect(() => {
    if (date) {
      setSelectedDate(toDayjs(createDate(date as DateFormat)));
    } else {
      setSelectedDate(dayjs());
    }
  }, [date]);

  const handleClickTimeTableCell = (course: Course, time: Time) => {
    const params = new URLSearchParams({
      courseId: String(course.id),
      date: toDateStringByDayjs(selectedDate),
      time: toTimeStringByTime(time),
    });
    const url = `/a/${workspaceUid}/shops/${shopId}/reservations/new?${params}`;
    history.push(url);
  };

  const handleChangeSelectedDate = (selectedDate: Dayjs) => {
    setSelectedDate(selectedDate);
    const params = new URLSearchParams({
      date: toDateStringByDayjs(selectedDate),
    });
    const url = `/a/${workspaceUid}/shops/${shopId}?${params}`;
    history.push(url);
  };

  const buildContents = () => {
    if (isLoadingShop) {
      return <Spinner loading={true} />;
    }
    if (!shop) {
      return <div>店舗情報が取得できませんでした。</div>;
    }
    return (
      <Grid container spacing={3} css={styles.gridWrapper}>
        <Grid
          item
          xs={12}
          md={4}
          css={[styles.sidebarWrapper, commonCss.noPrint]}
        >
          <Sidebar
            css={[commonCss.noPrint]}
            shopId={shopId}
            selectedDate={selectedDate}
            onChangeSelectedDate={handleChangeSelectedDate}
            onChangeCalendarTimeTable={setCalendarTimeTable}
          />
        </Grid>
        <Grid item xs={12} md={8} css={[commonCss.noPrint]}>
          <TimeTable
            shopId={shopId}
            selectedDate={selectedDate}
            onClickTimeTableCell={handleClickTimeTableCell}
          />
        </Grid>
        <Grid item xs={12}>
          <div
            id="hoge"
            css={[
              calcReservationListWrapperWidth(isExpandedSidebar, isPcSize),
              styles.tableWrapper,
            ]}
          >
            <ReservationList
              workspaceUid={workspaceUid}
              shopId={shopId}
              selectedDate={selectedDate}
            />
          </div>
        </Grid>
      </Grid>
    );
  };

  const buildActionButtons = () => (
    <>
      <Button
        type="button"
        variant="text"
        size="small"
        startIcon={<PrintIcon />}
        color="primary"
        onClick={(e) => {
          window.print();
          e.preventDefault();
        }}
      >
        印刷
      </Button>
      <ReservationDataDownloadButton
        shopId={shopId}
        shopName={shop?.name || ''}
      />
      <Box css={styles.shareUrlButtonWrapper}>
        <ShareButton
          workspaceUid={workspaceUid}
          shopId={shopId}
          inflowSources={inflowSources}
          shops={shops}
          lineMessagingChannels={lineMessagingChannels}
        />
      </Box>
      <DisplaySettingButton shopId={shopId} />
    </>
  );

  const mainContentsWithOnboardingNavigation = () => (
    <>
      <OnboardingNavigation
        workspaceUid={workspaceUid}
        shopId={shopId}
        shopUid={shop?.uid}
      />
      {buildContents()}
    </>
  );

  return (
    <Root>
      <Head title={`${shop?.name || ''}`} />
      <Main>
        <ShopPageLayout
          workspaceUid={workspaceUid}
          shopId={shopId}
          current="shop-top"
          helpId={helps.shop.top}
          breadcrumbs={
            <Breadcrumbs aria-label="breadcrumb">
              <BreadcrumbLinkItem to={`/`}>ホーム</BreadcrumbLinkItem>
              <BreadcrumbWorkspaceItem workspaceUid={workspaceUid} />
              <BreadcrumbShopItem
                workspaceUid={workspaceUid}
                shop={shop}
                noLink
              />
            </Breadcrumbs>
          }
        >
          <PageTitleAndDescription
            title="店舗"
            subTitle="店舗TOP（カレンダー）"
            description="予約の確認やWeb以外の予約の登録、キャンセル等を行います。"
            themeColor={globalColors.shop}
          />
          <div
            css={[
              css`
                display: flex;
                justify-content: flex-end;
              `,
              commonCss.noPrint,
            ]}
          >
            {buildActionButtons()}
          </div>
          {mainContentsWithOnboardingNavigation()}
        </ShopPageLayout>
      </Main>
    </Root>
  );
}

type SidebarProps = {
  shopId: string;
  selectedDate: Dayjs;
  onChangeSelectedDate: (selectedDate: Dayjs) => void;
  onChangeCalendarTimeTable: (table: ReservationTimeTable | undefined) => void;
};

function Sidebar(props: SidebarProps) {
  const {
    shopId,
    selectedDate,
    onChangeSelectedDate,
    onChangeCalendarTimeTable,
  } = props;

  const handleChangeCurrentDate = (date: IDate) => {
    onChangeSelectedDate(toDayjs(date));
  };

  return (
    <div>
      <ReservationCalendar
        shopId={shopId}
        floating={false}
        currentDate={{
          year: selectedDate.year(),
          month: selectedDate.month() + 1,
          date: selectedDate.date(),
        }}
        onChangeCurrentDate={handleChangeCurrentDate}
        onChangeTimeTable={onChangeCalendarTimeTable}
      />
    </div>
  );
}

type TimeTableProps = {
  shopId: string;
  selectedDate: Dayjs;
  onClickTimeTableCell: (course: Course, time: Time) => void;
};

function TimeTable(props: TimeTableProps) {
  const { shopId, selectedDate, onClickTimeTableCell } = props;
  const {
    reservationTimeTable,
    isLoadingReservationTimeTable,
    reloadReservationTimeTable,
  } = useReservationTimeTable(
    shopId,
    toDateStringByDayjs(selectedDate),
    toDateStringByDayjs(selectedDate)
  );

  const { autoReloadReservations } = useAutoReloadReservations();
  const { isPcSize } = useSizeType();

  useInterval(
    reloadReservationTimeTable,
    AUTO_RELOAD_RESERVATIONS_INTERVAL,
    autoReloadReservations.autoReload
  );

  if (isLoadingReservationTimeTable) {
    return <Spinner loading={true} />;
  }
  if (!reservationTimeTable) {
    return <div>予約枠が取得できませんでした。</div>;
  }
  if (reservationTimeTable.tables.length === 0) {
    return <div>予約枠はありません</div>;
  }
  return (
    <Box css={[calcTableWrapperWidth(isPcSize), styles.tableWrapper]}>
      <ReservationTimeTableView
        reservationTimeTable={reservationTimeTable}
        onClickCell={onClickTimeTableCell}
        shopId={shopId}
      />
    </Box>
  );
}

type ReservationListProps = {
  workspaceUid: string;
  shopId: string;
  selectedDate: Dayjs;
};

function ReservationList(props: ReservationListProps) {
  const { workspaceUid, shopId, selectedDate } = props;
  const { globalState } = useContext(Store);
  const { enqueueSnackbar } = useSnackbar();
  const [includingDeleted, setIncludingDeleted] = useStorage(
    localStorageKeys.includingDeletedReservations,
    false
  );
  const {
    reservations,
    formSettings,
    isLoadingReservations,
    reloadReservations,
  } = useReservations(
    shopId,
    toDateStringByDayjs(selectedDate),
    toDateStringByDayjs(selectedDate),
    includingDeleted
  );
  const { labels } = useLabels(workspaceUid);
  const [tab, setTab] = useStorage('shop_reservation_list_tab', 0, 'session');
  const { shopSetting } = useShopSetting(shopId);

  const { autoReloadReservations, setAutoReloadReservations } =
    useAutoReloadReservations();

  useInterval(
    reloadReservations,
    AUTO_RELOAD_RESERVATIONS_INTERVAL,
    autoReloadReservations.autoReload
  );

  const handleChangeAutoReloadReservations = () => {
    setAutoReloadReservations({
      autoReload: !autoReloadReservations.autoReload,
    });
  };

  const handleChangeIncludingDeleted = () => {
    setIncludingDeleted(!includingDeleted);
  };

  const handleChangeSelectedLabelIds = (
    reservationId: number,
    newSelectedLabelIds: number[]
  ) => {
    if (!globalState.session?.idToken) {
      return;
    }
    ReservationAPI.updateLabels(
      globalState.session.idToken,
      parseInt(shopId),
      newSelectedLabelIds,
      reservationId
    )
      .then(() => {
        reloadReservations();
      })
      .catch((e) => {})
      .finally(() => {});
  };

  const handleChangeNote = (reservationId: number, newNote: string) => {
    if (!globalState.session?.idToken) {
      return;
    }
    ReservationAPI.updateNote(
      globalState.session.idToken,
      parseInt(shopId),
      newNote,
      reservationId
    )
      .then(() => {
        enqueueSnackbar(`メモを保存しました。`);
        reloadReservations();
      })
      .catch((e) => {})
      .finally(() => {});
  };

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTab(newValue);
  };

  const buildReservationTable = () => {
    if (isLoadingReservations) {
      return <Spinner loading={true} />;
    }
    if (reservations.length === 0) {
      return <div>予約はありません</div>;
    }
    return (
      <ReservationsTable
        workspaceUid={workspaceUid}
        shopId={shopId}
        reservations={reservations || []}
        formSettings={formSettings}
        labels={labels}
        onChangeSelectedLabelIds={handleChangeSelectedLabelIds}
        onChangeNote={handleChangeNote}
        reloadReservations={reloadReservations}
      />
    );
  };

  const buildResourceTimeTable = () => {
    if (isLoadingReservations) {
      return <Spinner loading={true} />;
    }
    return (
      <ResourceTimeline
        shopId={shopId}
        startDate={toDateStringByDayjs(selectedDate)}
        endDate={toDateStringByDayjs(selectedDate)}
        reservations={reservations}
        formSettings={formSettings}
        workspaceUid={workspaceUid}
      />
    );
  };

  return (
    <>
      <Grid container alignItems="center" justifyContent="space-between">
        <Grid item>
          <h2 css={styles.title}>
            {selectedDate.format('YYYY年M月D日')}の予約 ({reservations.length}
            件)
          </h2>
          <Link
            to={`/a/${workspaceUid}/shops/${shopId}/settings/special-rule?date=${dayjs(
              selectedDate
            ).format('YYYY-MM-DD')}`}
            css={css`
              margin-left: 10px;
              text-decoration: none;
            `}
          >
            <Button variant="outlined" color="primary" size="small">
              この日の予約枠の変更
            </Button>
          </Link>
        </Grid>
        <Grid
          item
          xs
          container
          alignItems="center"
          justifyContent="flex-end"
          css={[
            commonCss.noPrint,
            css`
              text-align: right;
            `,
          ]}
        >
          <FormControlLabel
            control={
              <Switch
                checked={autoReloadReservations.autoReload}
                onChange={handleChangeAutoReloadReservations}
              />
            }
            label="自動更新(5分ごと)"
          />
          <FormControlLabel
            control={
              <Switch
                checked={includingDeleted}
                onChange={handleChangeIncludingDeleted}
              />
            }
            label="キャンセル済みも表示"
          />
          <Grid item>
            <Link
              to={`/a/${workspaceUid}/shops/${shopId}/reservations/new?date=${dayjs(
                selectedDate
              ).format('YYYY-MM-DD')}`}
              css={css`
                text-decoration: none;
              `}
            >
              <Button variant="contained" color="primary">
                予約の登録
              </Button>
            </Link>
          </Grid>
        </Grid>
      </Grid>
      {shopSetting?.enableResource ? (
        <>
          <Tabs value={tab} onChange={handleChange}>
            <Tab
              label={
                <div style={{ display: 'flex' }}>
                  <ListIcon />
                  一覧
                </div>
              }
            />
            <Tab
              label={
                <div style={{ display: 'flex' }}>
                  <PeopleIcon />
                  リソース
                </div>
              }
            />
          </Tabs>
          <Grid container>
            {tab === 0 && buildReservationTable()}
            {tab === 1 && buildResourceTimeTable()}
          </Grid>
        </>
      ) : (
        <Grid container>{buildReservationTable()}</Grid>
      )}
    </>
  );
}

const useOnboadingStart = (shopId: string) => {
  return useStorage(`shop_onboarding_start:${shopId}`, false);
};

const useOnboardingNotify = (shopId: string) => {
  return useStorage(`shop_onboarding_invite:${shopId}`, true);
};

const OnboardingNavigation = ({
  workspaceUid,
  shopId,
  shopUid,
}: {
  workspaceUid: string;
  shopId: string;
  shopUid: string | undefined;
}): JSX.Element | null => {
  const { history } = useReactRouter<PageParams>();
  const [onboardingStart, setOnboardingStart] = useOnboadingStart(shopId);
  const [notify, setNotify] = useOnboardingNotify(shopId);
  const { shopCourses, isLoadingShopCourses } = useShopCourses(shopId);
  const { shopNotifyDestinations, isLoadingShopNotifyDestinations } =
    useShopNotifyDestinations(shopId);
  const {
    count: slotSettingRuleCount,
    isLoading: isLoadingSlotSettingRuleCount,
  } = useCountSlotSettingRules(shopId);

  const handleClickChooseCourse = () => {
    setOnboardingStart(true);
    history.push(`/a/${workspaceUid}/shops/${shopId}/settings/courses/chooser`);
  };

  const handleClickAddSlotSetting = () => {
    setOnboardingStart(true);
    history.push(`/a/${workspaceUid}/shops/${shopId}/settings`);
  };

  const handleClickAddNotify = () => {
    setOnboardingStart(true);
    history.push(`/a/${workspaceUid}/shops/${shopId}/settings/destinations`);
  };

  const handleClickCancelNotify = () => {
    setNotify(false);
  };

  const handleClickClose = () => {
    setOnboardingStart(false);
  };

  if (
    isLoadingShopCourses ||
    isLoadingShopNotifyDestinations ||
    isLoadingSlotSettingRuleCount ||
    (shopCourses.length !== 0 && !onboardingStart)
  ) {
    return null;
  } else if (shopCourses.length === 0) {
    return (
      <div
        css={css`
          margin-bottom: 20px;
        `}
      >
        <Alert severity="info">
          <strong>Step 1. まずは使用するコースを選択しましょう。</strong>
          <div>
            この店舗で使用するコースを選択します。
            <div
              css={css`
                margin-top: 20px;
              `}
            >
              <Button
                variant="contained"
                color="primary"
                size="medium"
                onClick={handleClickChooseCourse}
              >
                コースを選択する
              </Button>
            </div>
          </div>
        </Alert>
        <Alert
          severity="info"
          css={css`
            background-color: #ddd;
          `}
        >
          Step 2. 次に予約枠を設定しましょう。
        </Alert>
        <Alert
          severity="info"
          css={css`
            background-color: #ddd;
          `}
        >
          Step 3. 予約の通知先メールアドレスを設定します。
        </Alert>
      </div>
    );
  } else if (slotSettingRuleCount === 0) {
    return (
      <div
        css={css`
          margin-bottom: 20px;
        `}
      >
        <Alert severity="success">
          Step 1. まずは使用するコースを選択しましょう。
        </Alert>
        <Alert severity="info">
          <strong>Step 2. 次に予約枠を設定しましょう。</strong>
          <div>
            コースごとの予約枠を設定します。
            <div
              css={css`
                margin-top: 20px;
              `}
            >
              <Button
                variant="contained"
                color="primary"
                size="medium"
                onClick={handleClickAddSlotSetting}
              >
                予約枠を設定する
              </Button>
            </div>
          </div>
        </Alert>
        <Alert
          severity="info"
          css={css`
            background-color: #ddd;
          `}
        >
          Step 3. 予約の通知先メールアドレスを設定します。
        </Alert>
      </div>
    );
  } else if (shopNotifyDestinations.length === 0 && notify) {
    return (
      <div
        css={css`
          margin-bottom: 20px;
        `}
      >
        <Alert severity="success">
          Step 1. まずは使用するコースを選択しましょう。
        </Alert>
        <Alert severity="success">Step 2. 次に予約枠を設定しましょう。</Alert>
        <Alert severity="info">
          <strong>Step 3. 予約の通知先メールアドレスを設定します。</strong>
          <div>
            Webから予約が入った際に通知するメールアドレスを設定します。
            <div
              css={css`
                margin-top: 20px;
              `}
            >
              <Button
                variant="contained"
                color="primary"
                size="medium"
                onClick={handleClickAddNotify}
              >
                通知先メールアドレスを設定する
              </Button>
              <Button
                variant="text"
                color="primary"
                size="medium"
                onClick={handleClickCancelNotify}
              >
                今は通知先を設定しない
              </Button>
            </div>
          </div>
        </Alert>
      </div>
    );
  } else {
    return (
      <div
        css={css`
          margin-bottom: 20px;
        `}
      >
        <Alert severity="success">
          Step 1. まずは使用するコースを選択しましょう。
        </Alert>
        <Alert severity="success">Step 2. 次に予約枠を設定しましょう。</Alert>
        <Alert severity="success">
          Step 3. 予約の通知先メールアドレスを設定します。
        </Alert>
        <Alert severity="info">
          店舗の設定は完了です。予約フォームのURLをWebサイトなどからリンクしてWeb予約の受け付けを開始しましょう。
          <div>
            <ShareFormUrlButton
              workspaceUid={workspaceUid}
              shopUid={shopUid || ''}
              shopId={shopId}
            />
            <Button
              variant="text"
              color="primary"
              size="medium"
              onClick={handleClickClose}
            >
              この説明を閉じる
            </Button>
          </div>
        </Alert>
      </div>
    );
  }
};
