/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogProps,
  DialogTitle,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { useSnackbar } from 'notistack';
import React, { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import {
  ICourseFormSetting,
  NewCourseFormSetting,
} from '../@interfaces/course-form-setting';
import { Label } from '../@interfaces/label';
import { Reservation, RichReservation } from '../@interfaces/reservation';
import { SendLineMessageBody } from '../@interfaces/reservation-send-line-message';
import useSendLineMessage from '../api/use-send-line-message';
import useSendMail from '../api/use-send-mail';
import useWorkspaceSetting from '../api/use-workspace-setting';
import { commonCss } from '../components/common-css';
import LabelChooser from '../components/LabelChooser';
import LinkRouter from '../components/LinkRouter';
import NoteEditor from '../components/NoteEditor';
import {
  AnyField,
  FieldResponseValue,
  isSpecialFieldType,
} from '../core/types/reservation-form-types';
import { Time, toTimeStringByTime } from '../core/types/reservation-types';
import { ReservationCustomerInfoButton } from '../features/crm/components/ReservationCustomerInfoButton';
import { useShopCustomerLineMessagingChannels } from '../features/line/api/useShopCustomerLineMessagingChannels';
import { useSizeType } from '../hooks/use-size-type';
import { findSpecialFieldValue } from '../models/reservation';
import { lineBreakToBr } from '../utils/browsers';
import { truncate } from '../utils/string';
type Props = {
  workspaceUid: string;
  shopId: string;
  reservations: RichReservation[];
  formSettings: ICourseFormSetting[];
  labels: Label[];
  onChangeSelectedLabelIds: (
    reservationId: number,
    newSelectedLabelIds: number[]
  ) => void;
  onChangeNote: (reservationId: number, newNote: string) => void;
  reloadReservations: () => void;
};

const styles = {
  inflowSource: css`
    max-width: 200px;
    word-wrap: break-word;
  `,
};

export default function ReservationsTable(props: Props) {
  const {
    workspaceUid,
    shopId,
    reservations,
    formSettings,
    labels,
    onChangeSelectedLabelIds,
    onChangeNote,
    reloadReservations,
  } = props;

  const { isSpSize } = useSizeType();
  const { workspaceSetting } = useWorkspaceSetting(workspaceUid);

  return (
    <>
      {!isSpSize ? (
        <TableContainer component={Paper}>
          <Table aria-label="simple table" stickyHeader={true}>
            <TableHead>
              <TableRow>
                <TableCell css={[commonCss.noWrap, commonCss.noPrint]}>
                  日付
                </TableCell>
                <TableCell css={commonCss.noWrap}>時間</TableCell>
                <TableCell css={commonCss.noWrap}>コース</TableCell>
                <TableCell css={commonCss.noWrap}>名前/連絡先</TableCell>
                <TableCell css={[commonCss.noWrap, commonCss.noPrint]}>
                  流入元
                </TableCell>
                <TableCell css={commonCss.noWrap}>ラベル</TableCell>
                <TableCell css={commonCss.noWrap}>メモ</TableCell>
                <TableCell css={[commonCss.noWrap, commonCss.noPrint]}>
                  アクション
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {reservations.map((res) => {
                return (
                  <Row
                    key={res.id}
                    workspaceUid={workspaceUid}
                    shopId={shopId}
                    formSettings={formSettings}
                    labels={labels}
                    res={res}
                    onChangeSelectedLabelIds={onChangeSelectedLabelIds}
                    onChangeNote={onChangeNote}
                    reloadReservations={reloadReservations}
                    enableCrm={workspaceSetting?.enableCrm ?? false}
                  />
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <>
          {reservations.map((res, index) => (
            <Box
              key={index}
              mt={2}
              css={css`
                width: 100%;
              `}
            >
              <Table2Parallel
                workspaceUid={workspaceUid}
                shopId={shopId}
                formSettings={formSettings}
                labels={labels}
                res={res}
                onChangeSelectedLabelIds={onChangeSelectedLabelIds}
                onChangeNote={onChangeNote}
                reloadReservations={reloadReservations}
                enableCrm={workspaceSetting?.enableCrm ?? false}
              />
            </Box>
          ))}
        </>
      )}
    </>
  );
}

function Row(props: {
  workspaceUid: string;
  shopId: string;
  formSettings: NewCourseFormSetting[];
  labels: Label[];
  res: RichReservation;
  onChangeSelectedLabelIds: (
    reservationId: number,
    newSelectedLabelIds: number[]
  ) => void;
  onChangeNote: (reservationId: number, newNote: string) => void;
  reloadReservations: () => void;
  enableCrm: boolean;
}) {
  const {
    workspaceUid,
    shopId,
    formSettings,
    labels,
    res,
    onChangeSelectedLabelIds,
    onChangeNote,
    reloadReservations,
    enableCrm,
  } = props;

  const history = useHistory();
  const [moreMenuAnchorEl, setMoreMenuAnchorEl] =
    React.useState<null | HTMLElement>(null);
  const [openCreateMailDialog, setOpenCreateMailDialog] = useState(false);
  const [openCreateLineMessageDialog, setOpenCreateLineMessageDialog] =
    useState(false);

  const formSetting = useMemo(() => {
    return formSettings.find((s) => s.courseId === res.courseId);
  }, [formSettings, res.courseId]);

  const handleClickMoreMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMoreMenuAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setMoreMenuAnchorEl(null);
  };

  const handleClickCopy = () => {
    history.push(
      `/a/${workspaceUid}/shops/${shopId}/reservations/new?fromId=${res.id}`
    );
  };

  const buildOtherFields = (res: RichReservation) => {
    if (!formSetting) {
      return '';
    }
    const otherFieldValues = formSetting.settingJson.fields
      ?.filter((f) => !isSpecialFieldType(f.type))
      .map((field) => {
        const values = res.formResponse.fields.find(
          (f) => f.uid === field.uid
        )?.values;
        const valueString = buildValueString(field, values);
        return valueString;
      })
      .filter((v) => v !== '');
    const otherDeletedFieldValues = formSetting.settingJson?.deletedFields
      ?.filter((f) => !isSpecialFieldType(f.type))
      .map((field) => {
        const df = formSetting.settingJson.fields.find(
          (d) => d.uid === field.uid
        );
        if (df) {
          const values = res.formResponse.fields.find(
            (f) => f.uid === field.uid
          )?.values;
          const valueString = buildValueString(field, values);
          return valueString;
        } else {
          return null;
        }
      })
      .filter(Boolean);
    const resourecesValues = res.selectedResources.map((r) => r.resourceName);
    return (
      <div
        css={css`
          font-size: 12px;
          color: #555;
        `}
      >
        {(otherDeletedFieldValues
          ? [
              ...otherFieldValues,
              ...otherDeletedFieldValues,
              ...resourecesValues,
            ]
          : [...otherFieldValues, ...resourecesValues]
        ).join(' / ')}
      </div>
    );
  };

  const handleClickCreateMail = () => {
    setOpenCreateMailDialog(true);
    handleClose();
  };

  const handleCloseCreateMailDialog = () => {
    setOpenCreateMailDialog(false);
  };

  const handleClickCreateLineMessage = () => {
    setOpenCreateLineMessageDialog(true);
    handleClose();
  };

  const handleCloseCreateLineMessageDialog = () => {
    setOpenCreateLineMessageDialog(false);
  };

  return (
    <>
      <TableRow key={res.id} css={res.deletedAt ? commonCss.deletedRow : null}>
        <TableCell scope="row" css={commonCss.noPrint}>
          {res.reservationDate}
          {res.deletedAt && (
            <p
              css={css`
                font-size: 11px;
                margin: 0;
              `}
            >
              (キャンセル済み)
            </p>
          )}
        </TableCell>
        <TableCell scope="row">
          {toTimeStringByTime({
            hour: res.reservationHour,
            minute: res.reservationMinute,
          } as Time)}
        </TableCell>
        <TableCell scope="row">
          {res.courseName}
          {res.minutesRequired ? <div>({res.minutesRequired}分)</div> : null}
          {buildOtherFields(res)}
        </TableCell>
        <TableCell scope="row">
          {res.customer?.nameKana && (
            <div
              css={css`
                font-size: 10px;
              `}
            >
              {res.customer?.nameKana}
            </div>
          )}
          {findSpecialFieldValue(res, 'name', formSettings)}
          {res.customer?.lineUserId ? (
            <>
              <img
                src="/img/line.svg"
                css={css`
                  width: 16px;
                  margin-left: 5px;
                  vertical-align: middle;
                `}
                alt=""
              />
              <span
                css={css`
                  color: #777;
                  margin-left: 3px;
                `}
              >
                {res.customer.lineDisplayName}
              </span>
            </>
          ) : null}
          <br />
          {findSpecialFieldValue(res, 'email', formSettings)}
          <br />
          {findSpecialFieldValue(res, 'tel', formSettings)}
          {enableCrm ? (
            <ReservationCustomerInfoButton
              workspaceUid={workspaceUid}
              reservation={res}
              reloadReservations={reloadReservations}
              formSettingFields={formSetting?.settingJson.fields}
            />
          ) : null}
          <div css={commonCss.print}>({res.inflowSourceLabel})</div>
        </TableCell>
        <TableCell scope="row" css={[styles.inflowSource, commonCss.noPrint]}>
          {res.inflowSourceLabel}
        </TableCell>
        <TableCell scope="row">
          <LabelChooser
            workspaceUid={workspaceUid}
            selectedLabelIds={res.selectedLabelIds}
            labels={labels}
            onChangeSelectedLabelIds={(newSelectedLabelIds) => {
              onChangeSelectedLabelIds(res.id, newSelectedLabelIds);
            }}
            onClose={() => {}}
            button={<EditIcon fontSize="small" />}
            vertical={false}
          />
        </TableCell>
        <TableCell scope="row">
          {res.note ? (
            <>
              <div
                css={[
                  commonCss.noPrint,
                  css`
                    max-width: 150px;
                    max-height: 100px;
                    overflow-y: auto;
                  `,
                ]}
              >
                {lineBreakToBr(res.note)}
              </div>
              <div
                css={[
                  commonCss.print,
                  css`
                    font-size: 12px;
                  `,
                ]}
              >
                {lineBreakToBr(res.note)}
              </div>
            </>
          ) : null}
          <NoteEditor
            note={res.note}
            onChangeNote={(newNote) => {
              onChangeNote(res.id, newNote);
            }}
            onClose={() => {}}
            button={<EditIcon fontSize="small" />}
          />
        </TableCell>
        <TableCell scope="row" css={[commonCss.noPrint]}>
          <LinkRouter
            to={`/a/${workspaceUid}/shops/${shopId}/reservations/${res.id}`}
            css={css`
              vertical-align: middle;
            `}
          >
            編集
          </LinkRouter>
          <div
            css={css`
              width: 10px;
              display: inline-block;
            `}
          />
          <IconButton onClick={handleClickMoreMenu}>
            <MoreVertIcon
              fontSize="small"
              css={css`
                vertical-align: middle;
              `}
            />
          </IconButton>
          {moreMenuAnchorEl ? (
            <Menu
              id="simple-menu"
              anchorEl={moreMenuAnchorEl}
              open={Boolean(moreMenuAnchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={handleClickCopy}>
                コピーして新しい予約を作成
              </MenuItem>
              {findSpecialFieldValue(res, 'email', formSettings) && (
                <MenuItem onClick={handleClickCreateMail}>
                  メールを送信
                </MenuItem>
              )}
              {res.customer && (
                <MenuItem onClick={handleClickCreateLineMessage}>
                  LINEメッセージを送信
                </MenuItem>
              )}
            </Menu>
          ) : null}
          {openCreateMailDialog && (
            <MailCreateDialog
              open={openCreateMailDialog}
              fullWidth
              onClose={handleCloseCreateMailDialog}
              reservation={res}
            />
          )}
          {openCreateLineMessageDialog && (
            <LineMessageCreateDialog
              open={openCreateLineMessageDialog}
              fullWidth
              onClose={handleCloseCreateLineMessageDialog}
              reservation={res}
            />
          )}
        </TableCell>
      </TableRow>
    </>
  );
}

function Table2Parallel(props: {
  workspaceUid: string;
  shopId: string;
  formSettings: NewCourseFormSetting[];
  labels: Label[];
  res: RichReservation;
  onChangeSelectedLabelIds: (
    reservationId: number,
    newSelectedLabelIds: number[]
  ) => void;
  onChangeNote: (reservationId: number, newNote: string) => void;
  reloadReservations: () => void;
  enableCrm: boolean;
}) {
  const {
    workspaceUid,
    shopId,
    formSettings,
    labels,
    res,
    onChangeSelectedLabelIds,
    onChangeNote,
    reloadReservations,
    enableCrm,
  } = props;

  const history = useHistory();
  const [moreMenuAnchorEl, setMoreMenuAnchorEl] =
    React.useState<null | HTMLElement>(null);
  const [openCreateMailDialog, setOpenCreateMailDialog] = useState(false);
  const [openCreateLineMessageDialog, setOpenCreateLineMessageDialog] =
    useState(false);

  const formSetting = useMemo(() => {
    return formSettings.find((s) => s.courseId === res.courseId);
  }, [formSettings, res.courseId]);

  const handleClickMoreMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMoreMenuAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setMoreMenuAnchorEl(null);
  };

  const handleClickCopy = () => {
    history.push(
      `/a/${workspaceUid}/shops/${shopId}/reservations/new?fromId=${res.id}`
    );
  };

  const buildOtherFields = (res: RichReservation) => {
    if (!formSetting) {
      return '';
    }
    const otherFieldValues = formSetting.settingJson?.fields
      ?.filter((f) => !isSpecialFieldType(f.type))
      .map((field) => {
        const values = res.formResponse.fields.find(
          (f) => f.uid === field.uid
        )?.values;
        const valueString = buildValueString(field, values);
        return valueString;
      })
      .filter((v) => v !== '');
    const otherDeletedFieldValues = formSetting.settingJson.deletedFields
      ?.filter((f) => !isSpecialFieldType(f.type))
      .map((field) => {
        const df = formSetting.settingJson.fields.find(
          (d) => d.uid === field.uid
        );
        if (df) {
          const values = res.formResponse.fields.find(
            (f) => f.uid === field.uid
          )?.values;
          const valueString = buildValueString(field, values);
          return valueString;
        } else {
          return null;
        }
      })
      .filter(Boolean);
    const resourecesValues = res.selectedResources.map((r) => r.resourceName);
    return (
      <div
        css={css`
          font-size: 12px;
          color: #555;
        `}
      >
        {(otherDeletedFieldValues
          ? [
              ...otherFieldValues,
              ...otherDeletedFieldValues,
              ...resourecesValues,
            ]
          : [...otherFieldValues, ...resourecesValues]
        ).join(' / ')}
      </div>
    );
  };

  const handleClickCreateMail = () => {
    setOpenCreateMailDialog(true);
    handleClose();
  };

  const handleCloseCreateMailDialog = () => {
    setOpenCreateMailDialog(false);
  };

  const handleClickCreateLineMessage = () => {
    setOpenCreateLineMessageDialog(true);
    handleClose();
  };

  const handleCloseCreateLineMessageDialog = () => {
    setOpenCreateLineMessageDialog(false);
  };

  return (
    <>
      <TableContainer
        component={Paper}
        css={res.deletedAt ? commonCss.deletedRow : null}
      >
        <Table aria-label="simple table">
          <TableBody>
            <TableRow>
              <TableCell css={[commonCss.noWrap, commonCss.noPrint]}>
                日付
              </TableCell>
              <TableCell scope="row" css={commonCss.noPrint}>
                {res.reservationDate}
                {res.deletedAt && '(キャンセル済み)'}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell css={commonCss.noWrap}>時間</TableCell>
              <TableCell scope="row">
                {toTimeStringByTime({
                  hour: res.reservationHour,
                  minute: res.reservationMinute,
                } as Time)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell css={commonCss.noWrap}>コース</TableCell>
              <TableCell scope="row">
                {res.courseName}
                {res.minutesRequired ? (
                  <div>({res.minutesRequired}分)</div>
                ) : null}
                {buildOtherFields(res)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell css={commonCss.noWrap}>名前/連絡先</TableCell>
              <TableCell scope="row">
                {findSpecialFieldValue(res, 'name', formSettings)}
                {res.customer?.lineUserId ? (
                  <>
                    <img
                      src="/img/line.svg"
                      css={css`
                        width: 16px;
                        margin-left: 5px;
                        vertical-align: middle;
                      `}
                      alt=""
                    />
                    <span
                      css={css`
                        color: #777;
                        margin-left: 3px;
                      `}
                    >
                      {res.customer.lineDisplayName}
                    </span>
                  </>
                ) : null}
                <br />
                {findSpecialFieldValue(res, 'email', formSettings)}
                <br />
                {findSpecialFieldValue(res, 'tel', formSettings)}
                {enableCrm ? (
                  <ReservationCustomerInfoButton
                    workspaceUid={workspaceUid}
                    reservation={res}
                    reloadReservations={reloadReservations}
                    formSettingFields={formSetting?.settingJson.fields}
                  />
                ) : null}
                <div css={commonCss.print}>({res.inflowSourceLabel})</div>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell css={[commonCss.noWrap, commonCss.noPrint]}>
                流入元
              </TableCell>
              <TableCell
                scope="row"
                css={[styles.inflowSource, commonCss.noPrint]}
              >
                {res.inflowSourceLabel}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell css={commonCss.noWrap}>ラベル</TableCell>
              <TableCell scope="row">
                <LabelChooser
                  workspaceUid={workspaceUid}
                  selectedLabelIds={res.selectedLabelIds}
                  labels={labels}
                  onChangeSelectedLabelIds={(newSelectedLabelIds) => {
                    onChangeSelectedLabelIds(res.id, newSelectedLabelIds);
                  }}
                  onClose={() => {}}
                  button={<EditIcon fontSize="small" />}
                  vertical={false}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell css={commonCss.noWrap}>メモ</TableCell>
              <TableCell scope="row">
                <Grid container>
                  {res.note && (
                    <Grid item xs>
                      <span
                        css={css`
                          max-width: 150px;
                          max-height: 100px;
                          overflow-y: auto;
                        `}
                      >
                        {lineBreakToBr(res.note)}
                      </span>
                    </Grid>
                  )}
                  <Grid item>
                    <NoteEditor
                      note={res.note}
                      onChangeNote={(newNote) => {
                        onChangeNote(res.id, newNote);
                      }}
                      onClose={() => {}}
                      button={<EditIcon fontSize="small" />}
                    />
                  </Grid>
                </Grid>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell css={[commonCss.noWrap, commonCss.noPrint]}>
                アクション
              </TableCell>
              <TableCell scope="row" css={[commonCss.noPrint]}>
                <LinkRouter
                  to={`/a/${workspaceUid}/shops/${shopId}/reservations/${res.id}`}
                  css={css`
                    vertical-align: middle;
                  `}
                >
                  編集
                </LinkRouter>
                <div
                  css={css`
                    width: 10px;
                    display: inline-block;
                  `}
                />
                <IconButton onClick={handleClickMoreMenu}>
                  <MoreVertIcon
                    fontSize="small"
                    css={css`
                      vertical-align: middle;
                    `}
                  />
                </IconButton>
                {moreMenuAnchorEl ? (
                  <Menu
                    id="simple-menu"
                    anchorEl={moreMenuAnchorEl}
                    open={Boolean(moreMenuAnchorEl)}
                    onClose={handleClose}
                  >
                    <MenuItem onClick={handleClickCopy}>
                      コピーして新しい予約を作成
                    </MenuItem>
                    {findSpecialFieldValue(res, 'email', formSettings) && (
                      <MenuItem onClick={handleClickCreateMail}>
                        メールを送信
                      </MenuItem>
                    )}
                    {res.customer && (
                      <MenuItem onClick={handleClickCreateLineMessage}>
                        LINEメッセージを送信
                      </MenuItem>
                    )}
                  </Menu>
                ) : null}
                {openCreateMailDialog && (
                  <MailCreateDialog
                    open={openCreateMailDialog}
                    fullWidth
                    onClose={handleCloseCreateMailDialog}
                    reservation={res}
                  />
                )}
                {openCreateLineMessageDialog && (
                  <LineMessageCreateDialog
                    open={openCreateLineMessageDialog}
                    fullWidth
                    onClose={handleCloseCreateLineMessageDialog}
                    reservation={res}
                  />
                )}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

const buildValueString = (
  field: AnyField,
  values: FieldResponseValue[] | undefined
) => {
  return (
    values
      ?.map((value) => {
        if (field.type === 'number') {
          return `${field.name}: ${value}`;
        } else if (typeof value === 'string') {
          return truncate(value, 100, '...');
        } else if (field.type === 'checkbox' || field.type === 'radio') {
          return field.options.find((option) => option.uid === value.uid)?.text;
        } else {
          return value;
        }
      })
      .filter(Boolean)
      .join(', ') || ''
  );
};

const MailCreateDialog = ({
  reservation,
  ...dialogProps
}: DialogProps & { reservation: Reservation }): JSX.Element => {
  const handleClose = () => {
    setMailSubject('');
    setMailBody('');
    dialogProps.onClose && dialogProps.onClose({}, 'backdropClick');
  };
  const [subject, setMailSubject] = useState('');
  const [body, setMailBody] = useState('');
  const { loading, sendMail } = useSendMail();
  const { enqueueSnackbar } = useSnackbar();
  const handleSubmit = () => {
    sendMail(reservation.shopId, reservation.id, {
      subject,
      body,
    })
      .then(() => {
        enqueueSnackbar('メールを送信しました', { variant: 'success' });
      })
      .catch((e) => {
        enqueueSnackbar('メールを送信に失敗しました。', { variant: 'error' });
        console.error(e);
      })
      .finally(() => {
        handleClose();
      });
  };
  return (
    <Dialog
      {...dialogProps}
      onClose={handleClose}
      onKeyDown={(event) => {
        stopPropagationForTab(event);
        dialogProps.onKeyDown && dialogProps.onKeyDown(event);
      }}
    >
      <DialogTitle>メールを送信</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          label="件名"
          fullWidth
          value={subject}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setMailSubject(event.target.value);
          }}
        />
        <TextField
          label="本文"
          fullWidth
          value={body}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setMailBody(event.target.value);
          }}
          multiline
        />
      </DialogContent>
      <DialogActions>
        <Button color="inherit" onClick={handleClose}>
          閉じる
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={handleSubmit}
          disabled={loading || !subject.trim() || !body.trim()}
        >
          メールを送信する
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const LineMessageCreateDialog = ({
  reservation,
  ...dialogProps
}: DialogProps & { reservation: RichReservation }): JSX.Element => {
  const handleClose = () => {
    setLineMessageBody('');
    dialogProps.onClose && dialogProps.onClose({}, 'backdropClick');
  };
  const [body, setLineMessageBody] = useState('');
  const { loading, sendLineMessage } = useSendLineMessage();
  const { data: channels, isLoading: isLoadingChannels } =
    useShopCustomerLineMessagingChannels(
      reservation.shopId,
      reservation.customer?.id
    );
  const [selectedChannelId, setSelectedChannelId] = useState(
    reservation.lineMessagingChannelId
  );
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (selectedChannelId) {
      return;
    }
    if (channels && channels.channels.length > 0) {
      setSelectedChannelId(channels.channels[0].id);
    }
  }, [selectedChannelId, channels]);

  const handleChangeChannel = (
    e: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    setSelectedChannelId(e.target.value as number);
  };

  const handleSubmit = () => {
    if (!selectedChannelId) {
      enqueueSnackbar('LINE公式アカウントが設定されていません', {
        variant: 'error',
      });
      return;
    }
    const json: SendLineMessageBody = {
      lineMessagingChannelId: selectedChannelId,
      body,
    };
    sendLineMessage(reservation.shopId, reservation.id, json)
      .then(() => {
        enqueueSnackbar('LINEメッセージを送信しました', { variant: 'success' });
      })
      .catch((e) => {
        enqueueSnackbar('LINEメッセージを送信に失敗しました。', {
          variant: 'error',
        });
        console.error(e);
      })
      .finally(() => {
        handleClose();
      });
  };
  return (
    <Dialog
      {...dialogProps}
      onClose={handleClose}
      onKeyDown={(event) => {
        stopPropagationForTab(event);
        dialogProps.onKeyDown && dialogProps.onKeyDown(event);
      }}
    >
      <DialogTitle>LINEメッセージを送信</DialogTitle>
      <DialogContent>
        <DialogContentText>
          LINE公式アカウントがブロックされていたり、友だちでなくなっている場合、メッセージが届かない場合があります。
        </DialogContentText>
        <TextField
          autoFocus
          label="本文"
          fullWidth
          value={body}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setLineMessageBody(event.target.value);
          }}
          multiline
        />
        <div>
          公式アカウント
          <Select value={selectedChannelId} onChange={handleChangeChannel}>
            {channels?.channels.map((channel) => (
              <MenuItem key={channel.id} value={channel.id}>
                {channel.channelName}
              </MenuItem>
            ))}
          </Select>
          から送信
        </div>
      </DialogContent>
      <DialogActions>
        <Button color="inherit" onClick={handleClose}>
          閉じる
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={handleSubmit}
          disabled={loading || !body.trim() || !selectedChannelId}
        >
          LINEメッセージを送信する
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const stopPropagationForTab = (event: React.KeyboardEvent) => {
  if (event.key === 'Tab') {
    event.stopPropagation();
  }
};
