/** @jsxImportSource @emotion/react */

import {
  Breadcrumbs,
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  Paper,
  Switch,
  TextField,
} from '@material-ui/core';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import React, { ChangeEvent, useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import useReactRouter from 'use-react-router';
import { API_END_POINT } from '../../../api/api';
import useLineLoginChannel from '../../../api/use-line-login-channel';
import {
  BreadcrumbLinkItem,
  BreadcrumbTextItem,
  BreadcrumbWorkspaceItem,
} from '../../../components/breadcrumb/BreadcrumbItem';
import { commonCss } from '../../../components/common-css';
import { WorkspacePageLayout } from '../../../components/layouts/WorkspacePageLayout';
import { PageTitleAndDescription } from '../../../components/PageTitleAndDescription';
import {
  FullscreenLoading,
  Head,
  Main,
  Root,
} from '../../../components/Shared';
import Spinner from '../../../components/Spinner';
import { Store } from '../../../context/GlobalStore';
import { globalColors } from '../../../styles/globalColors';
import { helps } from '../../../utils/helps';

interface PageParams {
  workspaceUid: string;
}

type Form = {
  channelId: string;
  channelSecret: string;
  enabled: boolean;
};

export default function WorkspaceLineLoginChannelSettingEditPage(props: any) {
  const { globalState } = useContext(Store);
  const { history, match } = useReactRouter<PageParams>();
  const { workspaceUid } = match.params;
  const validationContext = useForm();
  const { handleSubmit } = validationContext;
  const { lineLoginChannel, isLoadingLineLoginChannel } =
    useLineLoginChannel(workspaceUid);

  const [form, setForm] = useState<Form>({
    channelId: '',
    channelSecret: '',
    enabled: true,
  });
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (!lineLoginChannel) {
      return;
    }
    setForm({
      channelId: lineLoginChannel.channelId,
      channelSecret: lineLoginChannel.channelSecret,
      enabled: lineLoginChannel.enabled,
    });
  }, [lineLoginChannel]);

  const handleChangeChannelId = (e: ChangeEvent<HTMLInputElement>) => {
    const channelId = e.target.value;
    setForm({ ...form, channelId });
  };

  const handleChangeChannelSecret = (e: ChangeEvent<HTMLInputElement>) => {
    const channelSecret = e.target.value;
    setForm({ ...form, channelSecret });
  };

  const handleChangeEnabled = (e: React.ChangeEvent<HTMLInputElement>) => {
    const enabled = !form.enabled;
    setForm({ ...form, enabled });
  };

  const handleClickDelete = () => {
    if (!globalState.session?.idToken || !lineLoginChannel) {
      return;
    }
    if (
      prompt(
        'このLINEログイン設定を削除するには「削除」と入力してください。'
      ) === '削除'
    ) {
      setOpenBackdrop(true);
      axios
        .delete(`${API_END_POINT}/app/ws/${workspaceUid}/line/login-channel`, {
          headers: {
            Authorization: globalState.session?.idToken,
          },
        })
        .then(() => {
          enqueueSnackbar('LINEログイン設定を削除しました。', {
            variant: 'success',
          });
          history.push(`/a/${workspaceUid}/settings/line`);
        })
        .catch((e) => {
          alert('削除に失敗しました。');
        })
        .finally(() => {
          setOpenBackdrop(false);
        });
    }
  };

  const handleClickCancel = () => {
    history.push(`/a/${workspaceUid}/settings/line`);
  };

  const update = () => {
    const json = {
      channelId: form.channelId,
      channelSecret: form.channelSecret,
      enabled: form.enabled,
    };
    setOpenBackdrop(true);
    axios
      .put(`${API_END_POINT}/app/ws/${workspaceUid}/line/login-channel`, json, {
        headers: {
          Authorization: globalState.session?.idToken,
        },
      })
      .then(() => {
        enqueueSnackbar('LINEログイン設定を保存しました。', {
          variant: 'success',
        });
        history.push(`/a/${workspaceUid}/settings/line`);
      })
      .catch((e) => {
        enqueueSnackbar('LINEログイン設定が保存できませんでした。', {
          variant: 'error',
        });
      })
      .finally(() => {
        setOpenBackdrop(false);
      });
  };

  const insert = () => {
    const json = {
      channelId: form.channelId,
      channelSecret: form.channelSecret,
      enabled: form.enabled,
    };
    setOpenBackdrop(true);
    axios
      .post(
        `${API_END_POINT}/app/ws/${workspaceUid}/line/login-channel`,
        json,
        {
          headers: {
            Authorization: globalState.session?.idToken,
          },
        }
      )
      .then(() => {
        enqueueSnackbar('LINEログイン設定を追加しました。', {
          variant: 'success',
        });
        history.push(`/a/${workspaceUid}/settings/line`);
      })
      .catch((e) => {
        enqueueSnackbar('LINEログイン設定が追加できませんでした。', {
          variant: 'error',
        });
      })
      .finally(() => {
        setOpenBackdrop(false);
      });
  };

  const handleSubmitForm = (e: React.FormEvent) => {
    if (lineLoginChannel?.id) {
      update();
    } else {
      insert();
    }
  };

  const buildRightNavigationButtons = () => {
    return (
      <>
        <Button
          css={commonCss.button.right}
          type="button"
          variant="text"
          color="primary"
          onClick={handleClickCancel}
        >
          キャンセル
        </Button>
        <Button
          css={commonCss.button.right}
          type="submit"
          variant="contained"
          color="primary"
        >
          &nbsp;保存&nbsp;
        </Button>
      </>
    );
  };

  const buildContents = () => {
    if (isLoadingLineLoginChannel) {
      return <Spinner loading={true} />;
    }
    return (
      <form onSubmit={handleSubmit(handleSubmitForm)}>
        <Grid container style={{ marginTop: '20px' }}>
          <Grid item xs={6}>
            <h3>
              {lineLoginChannel
                ? `LINEログイン設定の編集`
                : 'LINEログイン設定の追加'}
            </h3>
          </Grid>
          <Grid item container xs={6} alignItems="center" justify="flex-end">
            {buildRightNavigationButtons()}
          </Grid>
        </Grid>
        <Paper css={commonCss.paper}>
          <FormControl component="fieldset">
            <FormControlLabel
              control={
                <Switch
                  checked={form.enabled}
                  onChange={handleChangeEnabled}
                  color="primary"
                />
              }
              label="このチャネルを有効にする"
            />
            <FormHelperText>
              無効にするとこの公式アカウントとの連携は一時的にオフになります。
            </FormHelperText>
          </FormControl>
          <TextField
            label="チャネルID"
            helperText="LINE Developerコンソール > 対象のLINEログインチャネル > チャネル基本設定 > 表示されるチャネルIDの値を設定します。例: 1234567890"
            value={form.channelId}
            fullWidth
            required
            onChange={handleChangeChannelId}
          />
          <TextField
            label="チャネルシークレット"
            helperText="LINE Developerコンソール > 対象の公式アカウント > チャネルシークレット基本設定 > 表示されるチャネルアクセストークン（長期） の値を設定します。"
            value={form.channelSecret}
            fullWidth
            required
            multiline
            onChange={handleChangeChannelSecret}
          />
        </Paper>
        <Grid container style={{ marginTop: '20px' }}>
          <Grid item xs={6}>
            {lineLoginChannel?.id ? (
              <Button
                type="button"
                variant="contained"
                color="default"
                onClick={handleClickDelete}
              >
                削除する
              </Button>
            ) : null}
          </Grid>
          <Grid item container xs={6} alignItems="center" justify="flex-end">
            {buildRightNavigationButtons()}
          </Grid>
        </Grid>
      </form>
    );
  };

  const pageTitle =
    lineLoginChannel === undefined
      ? 'LINEログイン設定の追加'
      : 'LINEログイン設定の編集';

  return (
    <Root>
      <Head title={`ワークスペース設定 - LINEログイン設定の編集`} />
      <Main>
        <WorkspacePageLayout
          workspaceUid={workspaceUid}
          current="line"
          breadcrumbs={
            <Breadcrumbs aria-label="breadcrumb">
              <BreadcrumbLinkItem to={`/`}>ホーム</BreadcrumbLinkItem>
              <BreadcrumbWorkspaceItem workspaceUid={workspaceUid} />
              <BreadcrumbLinkItem to={`/a/${workspaceUid}/settings/line`}>
                LINE連携
              </BreadcrumbLinkItem>
              <BreadcrumbTextItem>{pageTitle}</BreadcrumbTextItem>
            </Breadcrumbs>
          }
          helpId={helps.workspace.setting.line}
        >
          <PageTitleAndDescription
            title="ワークスペース"
            subTitle={pageTitle}
            description="LINE連携に必要なLINEログインの設定を行います。"
            themeColor={globalColors.workspace}
          />
          {buildContents()}
        </WorkspacePageLayout>
      </Main>
      <FullscreenLoading open={openBackdrop} />
    </Root>
  );
}
