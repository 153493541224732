/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { Button, Grid, IconButton, Popover, Slider } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import SettingsOverscanIcon from '@material-ui/icons/SettingsOverscan';
import React, { useMemo } from 'react';
import { atom, useRecoilState } from 'recoil';
import { recoilPersist } from 'recoil-persist';
import useShopSetting from '../../api/use-shop-setting';
import { deepMerge } from '../../utils/deep-merge';

const DEFAULT_TIMETABLE_HEIGHT = 230;
const DEFAULT_TIMETABLE_CELL_WIDTH = 120;
export const RESOURCE_HEADER_HEIGHT = 100;
export const DEFAULT_RESOURCE_CELL_HEIGHT = 60;
export const DEFAULT_RESOURCE_CELL_WIDTH = 150;
export const DEFAULT_RESOURCE_FONT_SIZE = 10;

const styles = {
  container: css({
    width: '550px',
    maxWidth: '550px',
    padding: '20px',
  }),
  header: css({
    padding: '5px 0px',
    backgroundColor: '#eee',
    borderBottom: 'solid 1px #ddd',
  }),
  headerLabel: css({
    marginLeft: '15px',
  }),
  description: css({
    marginTop: '10px',
    marginBottom: '10px',
    color: '#555',
  }),
};

export type ShopPageDisplaySetting = {
  reservationTimeTable: {
    height: number;
    cellWidth: number;
  };
  resourceTimeLine: {
    cellHeight: number;
    cellWidth: number;
    fontSize: number;
  };
};

const defaultDisplaySetting: ShopPageDisplaySetting = {
  reservationTimeTable: {
    height: DEFAULT_TIMETABLE_HEIGHT,
    cellWidth: DEFAULT_TIMETABLE_CELL_WIDTH,
  },
  resourceTimeLine: {
    cellHeight: DEFAULT_RESOURCE_CELL_HEIGHT,
    cellWidth: DEFAULT_RESOURCE_CELL_WIDTH,
    fontSize: DEFAULT_RESOURCE_FONT_SIZE,
  },
};

const { persistAtom } = recoilPersist();

// 同じキーの atom を複数生成すると以下のコンソールログが大量に出力されるため shopId をキーにしてキャッシュする。
// Duplicate atom key "ShopPageDisplaySetting_N". This is a FATAL ERROR in production.
const atomsCache: {
  [key: string]: ReturnType<typeof atom<ShopPageDisplaySetting>>;
} = {};
const getAtomFromCacheOrCreate = (shopId: string) => {
  if (atomsCache[shopId] === undefined) {
    atomsCache[shopId] = atom<ShopPageDisplaySetting>({
      key: `ShopPageDisplaySetting_${shopId}`,
      default: defaultDisplaySetting,
      effects_UNSTABLE: [persistAtom],
    });
  }

  return atomsCache[shopId];
};

export const useShopPageDisplaySetting = (shopId: string) => {
  const [storedDisplaySetting, setDisplaySetting] = useRecoilState(
    getAtomFromCacheOrCreate(shopId)
  );
  const displaySetting = useMemo(() => {
    return deepMerge(storedDisplaySetting, defaultDisplaySetting);
  }, [storedDisplaySetting]);
  return [displaySetting, setDisplaySetting] as const;
};

export const DisplaySettingButton = (props: { shopId: string }) => {
  const { shopId } = props;
  const [displaySetting, setDisplaySetting] = useShopPageDisplaySetting(shopId);
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const { shopSetting } = useShopSetting(shopId);

  const handleClickOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    // onClose();
  };

  const handleChangeTimeTableHeight = (
    event: React.ChangeEvent<any>,
    value: number | number[]
  ) => {
    if (Array.isArray(value)) {
      return;
    }
    setDisplaySetting({
      ...displaySetting,
      reservationTimeTable: {
        ...displaySetting.reservationTimeTable,
        height: value,
      },
    });
  };

  const handleChangeTimeTableCellWidth = (
    event: React.ChangeEvent<any>,
    value: number | number[]
  ) => {
    if (Array.isArray(value)) {
      return;
    }
    setDisplaySetting({
      ...displaySetting,
      reservationTimeTable: {
        ...displaySetting.reservationTimeTable,
        cellWidth: value,
      },
    });
  };

  const handleChangeResourceTimeLineCellHeight = (
    event: React.ChangeEvent<any>,
    value: number | number[]
  ) => {
    if (Array.isArray(value)) {
      return;
    }
    setDisplaySetting({
      ...displaySetting,
      resourceTimeLine: {
        ...displaySetting.resourceTimeLine,
        cellHeight: value,
      },
    });
  };

  const handleChangeResourceTimeLineCellWidth = (
    event: React.ChangeEvent<any>,
    value: number | number[]
  ) => {
    if (Array.isArray(value)) {
      return;
    }
    setDisplaySetting({
      ...displaySetting,
      resourceTimeLine: {
        ...displaySetting.resourceTimeLine,
        cellWidth: value,
      },
    });
  };

  const handleChangeResourceTimeLineFontSize = (
    event: React.ChangeEvent<any>,
    value: number | number[]
  ) => {
    if (Array.isArray(value)) {
      return;
    }
    setDisplaySetting({
      ...displaySetting,
      resourceTimeLine: {
        ...displaySetting.resourceTimeLine,
        fontSize: value,
      },
    });
  };

  const handleClickReset = () => {
    setDisplaySetting(defaultDisplaySetting);
  };

  return (
    <>
      <Button
        type="button"
        variant="text"
        size="small"
        startIcon={<SettingsOverscanIcon />}
        color="primary"
        onClick={handleClickOpen}
      >
        表示設定
      </Button>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Grid
          container
          alignItems="center"
          justifyContent="flex-end"
          css={styles.header}
        >
          <Grid
            container
            item
            xs={10}
            alignItems="center"
            justifyContent="flex-start"
          >
            <div css={styles.headerLabel}>表示設定</div>
          </Grid>
          <Grid
            container
            item
            xs={2}
            alignItems="center"
            justifyContent="flex-end"
          >
            <IconButton
              size="small"
              color="primary"
              component="span"
              onClick={handleClose}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
        <Grid
          container
          alignItems="center"
          justifyContent="flex-end"
          css={styles.container}
        >
          <Grid
            item
            container
            xs={12}
            alignItems="center"
            justifyContent="flex-start"
          >
            <div>予約状況テーブルの高さ</div>
            <Slider
              value={displaySetting.reservationTimeTable.height}
              valueLabelDisplay="auto"
              step={10}
              marks
              min={DEFAULT_TIMETABLE_HEIGHT}
              max={1000}
              onChange={handleChangeTimeTableHeight}
            />
          </Grid>
          <Grid
            item
            container
            xs={12}
            alignItems="center"
            justifyContent="flex-start"
          >
            <div>予約状況テーブルの列幅</div>
            <Slider
              value={displaySetting.reservationTimeTable.cellWidth}
              valueLabelDisplay="auto"
              step={10}
              marks
              min={DEFAULT_TIMETABLE_CELL_WIDTH}
              max={500}
              onChange={handleChangeTimeTableCellWidth}
            />
          </Grid>
          {shopSetting?.enableResource ? (
            <>
              <Grid
                item
                container
                xs={12}
                alignItems="center"
                justifyContent="flex-start"
              >
                <div>リソース高さ(30分幅)</div>
                <Slider
                  value={displaySetting.resourceTimeLine.cellHeight}
                  valueLabelDisplay="auto"
                  step={10}
                  marks
                  min={30}
                  max={500}
                  onChange={handleChangeResourceTimeLineCellHeight}
                />
              </Grid>
              <Grid
                item
                container
                xs={12}
                alignItems="center"
                justifyContent="flex-start"
              >
                <div>リソース列幅</div>
                <Slider
                  value={displaySetting.resourceTimeLine.cellWidth}
                  valueLabelDisplay="auto"
                  step={10}
                  marks
                  min={DEFAULT_RESOURCE_CELL_WIDTH}
                  max={500}
                  onChange={handleChangeResourceTimeLineCellWidth}
                />
              </Grid>
              <Grid
                item
                container
                xs={12}
                alignItems="center"
                justifyContent="flex-start"
              >
                <div>リソース文字サイズ</div>
                <Slider
                  value={displaySetting.resourceTimeLine.fontSize}
                  valueLabelDisplay="auto"
                  step={1}
                  marks
                  min={DEFAULT_RESOURCE_FONT_SIZE}
                  max={20}
                  onChange={handleChangeResourceTimeLineFontSize}
                />
              </Grid>
            </>
          ) : null}
          <Grid
            item
            container
            xs={12}
            alignItems="center"
            justifyContent="flex-start"
          >
            <div css={styles.description}>
              ここで設定した内容はブラウザに保存されます。次回表示時も有効です。
            </div>
          </Grid>
          <Grid
            item
            container
            xs={12}
            alignItems="center"
            justifyContent="flex-end"
          >
            <Button variant="contained" size="small" onClick={handleClickReset}>
              リセット
            </Button>
          </Grid>
        </Grid>
      </Popover>
    </>
  );
};
