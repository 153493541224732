/** @jsxImportSource @emotion/react */

import {
  Breadcrumbs,
  Button,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  Paper,
  TextField,
} from '@material-ui/core';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import React, { ChangeEvent, useContext, useEffect, useState } from 'react';
import { ColorResult, CompactPicker } from 'react-color';
import { useForm } from 'react-hook-form';
import useReactRouter from 'use-react-router';
import { API_END_POINT } from '../../../api/api';
import useLabel from '../../../api/use-label';
import {
  BreadcrumbLinkItem,
  BreadcrumbTextItem,
  BreadcrumbWorkspaceItem,
} from '../../../components/breadcrumb/BreadcrumbItem';
import { commonCss } from '../../../components/common-css';
import { RawLabelPreview } from '../../../components/LabelChooser';
import { WorkspacePageLayout } from '../../../components/layouts/WorkspacePageLayout';
import { PageTitleAndDescription } from '../../../components/PageTitleAndDescription';
import {
  FullscreenLoading,
  Head,
  Main,
  Root,
} from '../../../components/Shared';
import { Store } from '../../../context/GlobalStore';
import { DEFAULT_THEME_COLOR, THEME_COLORS } from '../../../models/theme';
import { globalColors } from '../../../styles/globalColors';
import { helps } from '../../../utils/helps';

interface PageParams {
  workspaceUid: string;
  labelId: string | undefined;
}

type Form = {
  name: string;
  primaryColor: string;
};

export default function WorkspaceLabelEditPage(props: any) {
  const { globalState } = useContext(Store);
  const { history, match } = useReactRouter<PageParams>();
  const { workspaceUid, labelId } = match.params;
  const validationContext = useForm();
  const { handleSubmit } = validationContext;
  const { label } = useLabel(workspaceUid, labelId);

  const [form, setForm] = useState<Form>({
    name: '',
    primaryColor: DEFAULT_THEME_COLOR,
  });
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (!label) {
      return;
    }
    setForm({
      name: label.name,
      primaryColor: label.primaryColor,
    });
  }, [JSON.stringify(label)]);

  const handleChangeName = (e: ChangeEvent<HTMLInputElement>) => {
    const name = e.target.value;
    setForm({ ...form, name });
  };

  const handleChangePrimaryColor = (
    color: ColorResult,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const primaryColor = color.hex;
    setForm({ ...form, primaryColor });
  };

  const handleClickDelete = () => {
    if (!globalState.session?.idToken) {
      return;
    }
    if (
      prompt('このラベルを削除するには「削除」と入力してください。') === '削除'
    ) {
      setOpenBackdrop(true);
      axios
        .delete(`${API_END_POINT}/app/ws/${workspaceUid}/labels/${label.id}`, {
          headers: {
            Authorization: globalState.session?.idToken,
          },
        })
        .then(() => {
          enqueueSnackbar('ラベルを削除しました。', { variant: 'success' });
          history.push(`/a/${workspaceUid}/settings/labels`);
        })
        .catch((e) => {
          alert('削除に失敗しました。');
        })
        .finally(() => {
          setOpenBackdrop(false);
        });
    }
  };

  const handleClickCancel = () => {
    history.push(`/a/${workspaceUid}/settings/labels`);
  };

  const update = () => {
    const json = {
      name: form.name,
      primaryColor: form.primaryColor,
    };
    setOpenBackdrop(true);
    axios
      .put(`${API_END_POINT}/app/ws/${workspaceUid}/labels/${labelId}`, json, {
        headers: {
          Authorization: globalState.session?.idToken,
        },
      })
      .then(() => {
        enqueueSnackbar('ラベルを保存しました。', { variant: 'success' });
        history.push(`/a/${workspaceUid}/settings/labels`);
      })
      .catch((e) => {
        enqueueSnackbar('ラベルが保存できませんでした。', { variant: 'error' });
      })
      .finally(() => {
        setOpenBackdrop(false);
      });
  };

  const insert = () => {
    const json = {
      name: form.name,
      primaryColor: form.primaryColor,
    };
    setOpenBackdrop(true);
    axios
      .post(`${API_END_POINT}/app/ws/${workspaceUid}/labels`, json, {
        headers: {
          Authorization: globalState.session?.idToken,
        },
      })
      .then(() => {
        enqueueSnackbar('ラベルを追加しました。', { variant: 'success' });
        history.push(`/a/${workspaceUid}/settings/labels`);
      })
      .catch((e) => {
        enqueueSnackbar('ラベルが追加できませんでした。', { variant: 'error' });
      })
      .finally(() => {
        setOpenBackdrop(false);
      });
  };

  const handleSubmitForm = (e: React.FormEvent) => {
    if (labelId) {
      update();
    } else {
      insert();
    }
  };

  const buildRightNavigationButtons = () => {
    return (
      <>
        <Button
          css={commonCss.button.right}
          type="button"
          variant="text"
          color="primary"
          onClick={handleClickCancel}
        >
          キャンセル
        </Button>
        <Button
          css={commonCss.button.right}
          type="submit"
          variant="contained"
          color="primary"
        >
          &nbsp;保存&nbsp;
        </Button>
      </>
    );
  };

  const buildContents = () => {
    return (
      <form onSubmit={handleSubmit(handleSubmitForm)}>
        <Grid container style={{ marginTop: '20px' }}>
          <Grid item xs={6}>
            <h3>{label ? `${label.name}の編集` : 'ラベルの追加'}</h3>
          </Grid>
          <Grid
            item
            container
            xs={6}
            alignItems="center"
            justifyContent="flex-end"
          >
            {buildRightNavigationButtons()}
          </Grid>
        </Grid>
        <Paper css={commonCss.paper}>
          <TextField
            label="ラベル"
            helperText="ラベルを設定します。"
            value={form.name}
            fullWidth
            onChange={handleChangeName}
          />
          <FormControl required fullWidth component="fieldset">
            <FormGroup>
              <FormControlLabel
                control={
                  <CompactPicker
                    colors={THEME_COLORS}
                    color={form.primaryColor || DEFAULT_THEME_COLOR}
                    onChangeComplete={handleChangePrimaryColor}
                  />
                }
                labelPlacement="top"
                label={`ラベルの色`}
              />
              <FormHelperText>プレビュー</FormHelperText>
            </FormGroup>
          </FormControl>
          <RawLabelPreview text={form.name} primaryColor={form.primaryColor} />
        </Paper>
        <Grid container style={{ marginTop: '20px' }}>
          <Grid item xs={6}>
            {labelId ? (
              <Button
                type="button"
                variant="contained"
                color="default"
                onClick={handleClickDelete}
              >
                削除する
              </Button>
            ) : null}
          </Grid>
          <Grid
            item
            container
            xs={6}
            alignItems="center"
            justifyContent="flex-end"
          >
            {buildRightNavigationButtons()}
          </Grid>
        </Grid>
      </form>
    );
  };

  const addOrEdit = labelId === undefined ? '追加' : '編集';
  const pageTitle = `ラベルの${addOrEdit}`;

  return (
    <Root>
      <Head title={`ワークスペース設定 - ${label?.name || 'ラベル'}の編集`} />
      <Main>
        <WorkspacePageLayout
          workspaceUid={workspaceUid}
          current="label"
          breadcrumbs={
            <Breadcrumbs aria-label="breadcrumb">
              <BreadcrumbLinkItem to={`/`}>ホーム</BreadcrumbLinkItem>
              <BreadcrumbWorkspaceItem workspaceUid={workspaceUid} />
              <BreadcrumbLinkItem to={`/a/${workspaceUid}/settings/labels`}>
                ラベル設定
              </BreadcrumbLinkItem>
              <BreadcrumbTextItem>{pageTitle}</BreadcrumbTextItem>
            </Breadcrumbs>
          }
          helpId={helps.workspace.setting.label}
        >
          <PageTitleAndDescription
            title="ワークスペース"
            subTitle={pageTitle}
            description={`新規、既存等ユーザーを識別するためのラベルの${addOrEdit}を行います。`}
            themeColor={globalColors.workspace}
          />
          {buildContents()}
        </WorkspacePageLayout>
      </Main>
      <FullscreenLoading open={openBackdrop} />
    </Root>
  );
}
