import axios, { AxiosResponse } from 'axios';
import { useContext, useEffect, useState } from 'react';
import { Store } from '../context/GlobalStore';
import { API_END_POINT } from './api';

export default function useCountSlotSettingRules(shopId: string) {
  const { globalState } = useContext(Store);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (!globalState.session?.idToken) {
      return;
    }
    setLoading(true);
    axios
      .get(`${API_END_POINT}/app/shops/${shopId}/count-slot-setting-rules`, {
        headers: {
          Authorization: globalState.session?.idToken,
        },
      })
      .then((response: AxiosResponse<{ count: number }>) => {
        setCount(response.data.count);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [shopId, globalState.session?.idToken]);
  return {
    count,
    isLoading: loading,
  };
}
