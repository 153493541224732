export const concurrentPromise = async <T>(
  promises: (() => Promise<T>)[],
  concurrency: number
): Promise<T[]> => {
  const promisesCopy = [...promises];

  return (
    await Promise.all<T[]>(
      [...Array(concurrency)].map(
        () =>
          // eslint-disable-next-line no-async-promise-executor
          new Promise(async (resolve, reject) => {
            let current: (() => Promise<T>) | undefined;
            const results = [];
            try {
              while ((current = promisesCopy.shift()) !== undefined) {
                results.push(await current());
              }
            } catch (e) {
              reject(e);
            }
            resolve(results);
          })
      ) as Array<Promise<T[]>>
    )
  ).reduce((prev, current) => current.concat(prev), []);
};
