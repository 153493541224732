import axios, { AxiosResponse } from 'axios';
import { useContext, useState } from 'react';
import { ResponseBody } from '../../../../frontend-api/src/handlers/integration/google-token-update-handler';
import { Store } from '../../context/GlobalStore';
import { API_END_POINT } from '../api';

export type StoredGoogleTokenIntegration = ResponseBody;

export default function useGoogleTokenIntegration() {
  const { globalState, setGlobalState } = useContext(Store);
  const [googleTokenIntegration, setGoogleTokenIntegration] =
    useState<StoredGoogleTokenIntegration>();
  const [loading, setLoading] = useState(false);

  const googleTokenIntegrationApi = (
    shopId: string,
    code: string,
    url: string
  ) => {
    setLoading(true);
    axios
      .get(
        `${API_END_POINT}/app/shops/${shopId}/google/token/integration?code=${code}&url=${url}`,
        {
          headers: {
            Authorization: globalState.session?.idToken,
          },
        }
      )
      .then((response: AxiosResponse<ResponseBody>) => {
        setGoogleTokenIntegration(response.data);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return {
    googleTokenIntegration,
    googleTokenIntegrationApi,
    isLoadingIntegration: loading,
  };
}
