/** @jsxImportSource @emotion/react */

import React from 'react';
import {
  AnyField,
  FormSetting,
  TextareaField,
} from '../../../../../core/types/reservation-form-types';
import { FormSettingSingleDefaultValue } from '../FormSettingSingleDefaultValue';

type FormSettingFieldTextareaProps = {
  field: TextareaField;
  setting: FormSetting;
  onChangeSetting: (setting: FormSetting) => void;
  originalField: AnyField | undefined;
};

export const FormSettingFieldTextarea = (
  props: FormSettingFieldTextareaProps
): JSX.Element => {
  const { field, setting, onChangeSetting } = props;

  return (
    <FormSettingSingleDefaultValue
      field={field}
      setting={setting}
      onChangeSetting={onChangeSetting}
      type="textarea"
    />
  );
};
