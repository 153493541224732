import axios, { AxiosResponse } from 'axios';
import { useContext, useEffect, useState } from 'react';
import { ResponseBody } from '../../../frontend-api/src/handlers/shop-invitation/shop-invitations-handler';
import { ShopInvitationWithEmail } from '../@interfaces/shop-invitation';
import { Store } from '../context/GlobalStore';
import { API_END_POINT } from './api';

export default function useShopInvitations(shopId: string) {
  const { globalState, setGlobalState } = useContext(Store);
  const [shopInvitations, setShopInvitations] = useState(
    [] as ShopInvitationWithEmail[]
  );
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    reload();
  }, [shopId, globalState.session?.idToken]);

  const reload = () => {
    if (!globalState.session?.idToken) {
      return;
    }
    axios
      .get(`${API_END_POINT}/app/shops/${shopId}/invitations`, {
        headers: {
          Authorization: globalState.session?.idToken,
        },
      })
      .then((response: AxiosResponse<ResponseBody>) => {
        setShopInvitations(response.data.shopInvitations);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return {
    shopInvitations,
    isLoadingShopInvitations: loading,
    reloadShopInvitations: reload,
  };
}
