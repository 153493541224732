/** @jsxImportSource @emotion/react */

import {
  AnyField,
  EmailField,
  FormSetting,
} from '../../../../../core/types/reservation-form-types';

type FormSettingFieldEmailProps = {
  field: EmailField;
  setting: FormSetting;
  onChangeSetting: (setting: FormSetting) => void;
  originalField: AnyField | undefined;
};

export const FormSettingFieldEmail = (
  props: FormSettingFieldEmailProps
): null => {
  return null;
};
