import { useState, useEffect, useCallback } from 'react';

interface WindowSize {
  width: number;
  height: number;
}
export const useWindowSize = (): WindowSize => {
  const [windowSize, setWindowSize] = useState<WindowSize>({
    width: 0,
    height: 0,
  });

  useEffect(() => {
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight,
    });

    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleResize = useCallback(() => {
    window.addEventListener('resize', () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    });
  }, [windowSize.width, windowSize.height]);

  return windowSize;
};
