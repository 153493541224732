/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import {
  Box,
  Breadcrumbs,
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Paper,
  Switch,
  TextField,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Alert } from '@material-ui/lab';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import React, { ChangeEvent, useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import useReactRouter from 'use-react-router';
import { API_END_POINT } from '../../../api/api';
import useCourseSetting from '../../../api/use-course-setting';
import {
  BreadcrumbLinkItem,
  BreadcrumbTextItem,
  BreadcrumbWorkspaceItem,
} from '../../../components/breadcrumb/BreadcrumbItem';
import { commonCss } from '../../../components/common-css';
import Description from '../../../components/Description';
import { ImageCropperDialog } from '../../../components/ImageCropperDialog';
import { WorkspacePageLayout } from '../../../components/layouts/WorkspacePageLayout';
import { PageTitleAndDescription } from '../../../components/PageTitleAndDescription';
import {
  FullscreenLoading,
  Head,
  Main,
  Root,
} from '../../../components/Shared';
import { Store } from '../../../context/GlobalStore';
import { SM_BREAKPOINT } from '../../../hooks/use-size-type';
import { LOGO_BASE_URL } from '../../../models/theme';
import { globalColors } from '../../../styles/globalColors';
import { convertFileToBase64 } from '../../../utils/file';
import { helps } from '../../../utils/helps';

interface PageParams {
  workspaceUid: string;
  courseId: string | undefined;
}

type Form = {
  uid: string;
  name: string;
  description: string;
  emailHeader: string;
  emailFooter: string;
  thanksTitle: string;
  thanksMessage: string | null;
  isShowContinueReserve: boolean;
  buttonLabel: string | null;
  dateSelectMessage: string | null;
  imagePath: string | null;
};

const styles = {
  previewCourse: css`
    padding-right: 12px;
    @media screen and (max-width: ${SM_BREAKPOINT}px) {
      padding-right: 0;
    }
  `,
  previewTime: css`
    padding-left: 12px;
    @media screen and (max-width: ${SM_BREAKPOINT}px) {
      margin-top: 24px;
      padding-left: 0;
    }
  `,
  previewWrapper: css`
    width: 100%;
    max-width: 375px;
    border: 1px solid #dfdfdf;
    padding: 16px 20px;
    background: #f2f2f2;
  `,
  uploadImage: css`
    position: relative;
    width: fit-content;
    @media screen and (max-width: ${SM_BREAKPOINT}px) {
      width: 100%;
    }
  `,
  image: css`
    width: 200px;
    @media screen and (max-width: ${SM_BREAKPOINT}px) {
      width: 100%;
    }
  `,
  removeIcon: css`
    position: absolute;
    top: -8px;
    right: -8px;
    background: #dddddd;
  `,
  previewLabel: css`
    font-weight: bold;
    margin: 0 auto 8px;
  `,
  closeIcon: css`
    position: absolute;
    top: -16px;
    right: -16px;
    img {
      width: 16px;
    }
  `,
  button: css`
    display: block;
    background: #172b4d;
    color: #ffffff;
    border-radius: 56px;
    padding: 9px 22px;
    border: none;
    font-size: 14px;
    text-align: center;
    font-weight: bold;
  `,
  previewDescription: css`
    p {
      margin: 0 auto;
    }
  `,
};

export default function WorkspaceCourseSettingEditPage(props: any) {
  const { globalState } = useContext(Store);
  const { history, match } = useReactRouter<PageParams>();
  const { workspaceUid, courseId } = match.params;
  const validationContext = useForm();
  const { handleSubmit } = validationContext;
  const [isSettingImage, setIsSettingImage] = useState<boolean>(false);
  const [courseImage, setCourseImage] = useState<File | undefined>(undefined);
  const [isShowIconCropperDialog, setIsShowIconCropperDialog] =
    useState<boolean>(false);
  const { course, setting } = useCourseSetting(workspaceUid, courseId);

  const [form, setForm] = useState<Form>({
    uid: '',
    name: '',
    description: '',
    emailHeader: '',
    emailFooter: '',
    thanksTitle: '',
    thanksMessage: '',
    isShowContinueReserve: false,
    buttonLabel: '',
    dateSelectMessage: '',
    imagePath: null,
  });
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (!course || !setting) {
      return;
    }
    setForm({
      uid: course.uid,
      name: course.name,
      description: course.description,
      emailHeader: setting.emailHeader,
      emailFooter: setting.emailFooter,
      thanksTitle: setting.thanksTitle
        ? setting.thanksTitle
        : '上記の日時で予約完了しました。',
      thanksMessage: setting.thanksMessage ?? '',
      imagePath: setting.imagePath,
      // setting.isShowContinueReserve の実態は 0/1 の number であるため、 boolean にキャストしておく
      isShowContinueReserve: Boolean(setting.isShowContinueReserve),
      buttonLabel: setting.buttonLabel ?? '',
      dateSelectMessage: setting.dateSelectMessage ?? '',
    });
    setIsSettingImage(setting.imagePath !== null && setting.imagePath !== '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(course), JSON.stringify(setting)]);

  const handleChangeUid = (e: ChangeEvent<HTMLInputElement>) => {
    const uid = e.target.value;
    setForm({ ...form, uid });
  };

  const handleChangeName = (e: ChangeEvent<HTMLInputElement>) => {
    const name = e.target.value;
    setForm({ ...form, name });
  };

  const handleChangeDescription = (e: ChangeEvent<HTMLInputElement>) => {
    const description = e.target.value;
    setForm({ ...form, description });
  };

  const handleChangeEmailHeader = (e: ChangeEvent<HTMLInputElement>) => {
    const emailHeader = e.target.value;
    setForm({ ...form, emailHeader });
  };

  const handleChangeEmailFooter = (e: ChangeEvent<HTMLInputElement>) => {
    const emailFooter = e.target.value;
    setForm({ ...form, emailFooter });
  };

  const handleChangeThanksTitle = (e: ChangeEvent<HTMLInputElement>) => {
    const thanksTitle = e.target.value;
    setForm({ ...form, thanksTitle });
  };

  const handleChangeThanksMessage = (e: ChangeEvent<HTMLInputElement>) => {
    const thanksMessage = e.target.value;
    setForm({ ...form, thanksMessage });
  };

  const handleChangeButtonLabel = (e: ChangeEvent<HTMLInputElement>) => {
    const buttonLabel = e.target.value;
    setForm({ ...form, buttonLabel });
  };

  const handleChangeDateSelectMessage = (e: ChangeEvent<HTMLInputElement>) => {
    const dateSelectMessage = e.target.value;
    setForm({ ...form, dateSelectMessage });
  };

  const handleChangeContinueReserve = () => {
    setForm({
      ...form,
      isShowContinueReserve: !form.isShowContinueReserve,
    });
  };

  const validate = () => {
    if (!form.uid) {
      return false;
    }
    if (!form.name) {
      return false;
    }
    if (form.description.length > 1000) {
      return false;
    }
    if ((form.thanksMessage?.length || 0) > 1000) {
      return false;
    }
    if (form.buttonLabel && form.buttonLabel.length > 10) {
      return false;
    }
    if (form.dateSelectMessage && form.dateSelectMessage.length > 200) {
      return false;
    }
    return true;
  };

  const handleClickDelete = () => {
    if (!globalState.session?.idToken || !course) {
      return;
    }
    if (
      prompt(
        `このコースを削除するには「削除」と入力してください。\nコースを削除するとすべての店舗で「${course.name}」が使用できなくなります。`
      ) === '削除'
    ) {
      setOpenBackdrop(true);
      axios
        .delete(`${API_END_POINT}/app/ws/${workspaceUid}/courses/${courseId}`, {
          headers: {
            Authorization: globalState.session?.idToken,
          },
        })
        .then(() => {
          enqueueSnackbar('コースを削除しました。', { variant: 'success' });
          history.push(`/a/${workspaceUid}/settings/courses`);
        })
        .catch((e) => {
          alert('削除に失敗しました。');
        })
        .finally(() => {
          setOpenBackdrop(false);
        });
    }
  };

  const handleClickCancel = () => {
    history.push(`/a/${workspaceUid}/settings/courses`);
  };

  const encodeText = (text: string) => {
    return process.env.REACT_APP_STAGE === 'local'
      ? encodeURIComponent(text)
      : text;
  };

  const update = async () => {
    const params = new FormData();
    const json = {
      uid: form.uid,
      name: encodeText(form.name),
      description: encodeText(form.description),
      emailHeader: encodeText(form.emailHeader),
      emailFooter: encodeText(form.emailFooter),
      thanksTitle: encodeText(form.thanksTitle),
      thanksMessage: form.thanksMessage ? encodeText(form.thanksMessage) : null,
      isShowContinueReserve: form.isShowContinueReserve,
      buttonLabel: form.buttonLabel ? encodeText(form.buttonLabel) : null,
      dateSelectMessage: form.dateSelectMessage
        ? encodeText(form.dateSelectMessage)
        : null,
      imagePath: isSettingImage ? form.imagePath : null,
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const headers: any = {
      Authorization: globalState.session?.idToken,
    };

    if (isSettingImage && courseImage) {
      if (process.env.REACT_APP_STAGE === 'local') {
        const fileBase64 = await convertFileToBase64(courseImage);
        params.append(
          'json',
          JSON.stringify({
            ...json,
            mimetype: courseImage.type,
            fileBase64,
          })
        );
      } else {
        params.append('json', JSON.stringify(json));
        params.append('file', courseImage);
      }
    } else {
      params.append('json', JSON.stringify(json));
    }

    setOpenBackdrop(true);
    axios
      .put(
        `${API_END_POINT}/app/ws/${workspaceUid}/courses/${courseId}`,
        params,
        { headers }
      )
      .then(() => {
        enqueueSnackbar('コースを保存しました。', { variant: 'success' });
        history.push(`/a/${workspaceUid}/settings/courses`);
      })
      .catch((e) => {
        if (e.response && e.response.status === 409) {
          enqueueSnackbar(
            '同一IDのコースが存在するため、保存できませんでした。',
            { variant: 'error' }
          );
        } else {
          enqueueSnackbar('コースが保存できませんでした。', {
            variant: 'error',
          });
        }
      })
      .finally(() => {
        setOpenBackdrop(false);
      });
  };

  const insert = async () => {
    const params = new FormData();
    const json = {
      uid: form.uid,
      name: encodeText(form.name),
      description: encodeText(form.description),
      emailHeader: encodeText(form.emailHeader),
      emailFooter: encodeText(form.emailFooter),
      thanksTitle: encodeText(form.thanksTitle),
      thanksMessage: form.thanksMessage ? encodeText(form.thanksMessage) : null,
      isShowContinueReserve: form.isShowContinueReserve,
      buttonLabel: form.buttonLabel ? encodeText(form.buttonLabel) : null,
      dateSelectMessage: form.dateSelectMessage
        ? encodeText(form.dateSelectMessage)
        : null,
      imagePath: isSettingImage ? form.imagePath : null,
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const headers: any = {
      Authorization: globalState.session?.idToken,
    };

    if (isSettingImage && courseImage) {
      if (process.env.REACT_APP_STAGE === 'local') {
        const fileBase64 = await convertFileToBase64(courseImage);
        params.append(
          'json',
          JSON.stringify({
            ...json,
            fileBase64,
            mimetype: courseImage.type,
          })
        );
      } else {
        params.append('json', JSON.stringify(json));
        params.append('file', courseImage);
      }
    } else {
      params.append('json', JSON.stringify(json));
    }

    setOpenBackdrop(true);
    axios
      .post(`${API_END_POINT}/app/ws/${workspaceUid}/courses`, params, {
        headers,
      })
      .then((res) => {
        const { course } = res.data;
        enqueueSnackbar(
          'コースを追加しました。次にコースのフォームを設定します。',
          { variant: 'success' }
        );
        history.push(
          `/a/${workspaceUid}/settings/courses/${course.id}/form-setting`
        );
      })
      .catch((e) => {
        if (e.response && e.response.status === 409) {
          enqueueSnackbar(
            '同一IDのコースが存在するため、追加できませんでした。',
            { variant: 'error' }
          );
        } else {
          enqueueSnackbar('コースが追加できませんでした。', {
            variant: 'error',
          });
        }
      })
      .finally(() => {
        setOpenBackdrop(false);
      });
  };

  const handleSubmitForm = (e: React.FormEvent) => {
    if (courseId) {
      update();
    } else {
      insert();
    }
  };

  const handleCropIconImage = async (croppedImageSrc: string) => {
    const newFile: File = await fetch(croppedImageSrc)
      .then(async (r) => r.blob())
      .then(
        (blobFile) =>
          new File([blobFile], courseImage!.name, {
            type: courseImage!.type || 'image/jpeg',
          })
      );

    setCourseImage(newFile);
    setIsShowIconCropperDialog(false);
  };

  const handleUpdatedIconImage = function (
    e: React.ChangeEvent<HTMLInputElement>
  ) {
    e.preventDefault();

    if (e.currentTarget.files === null) throw new Error('event is NULL');

    const file = e.currentTarget.files[0];

    if (file && /image.*/.exec(file.type)) {
      setCourseImage(file);
      setIsShowIconCropperDialog(true);
    }
  };

  const buildRightNavigationButtons = () => {
    return (
      <>
        <Button
          css={commonCss.button.right}
          type="button"
          variant="text"
          color="primary"
          onClick={handleClickCancel}
        >
          キャンセル
        </Button>
        <Button
          css={commonCss.button.right}
          type="submit"
          variant="contained"
          color="primary"
          disabled={!validate()}
        >
          &nbsp;保存&nbsp;
        </Button>
      </>
    );
  };

  const removeImage = () => {
    setCourseImage(undefined);
    setForm({
      ...form,
      imagePath: null,
    });
  };

  const buildContents = () => {
    return (
      <form onSubmit={handleSubmit(handleSubmitForm)}>
        <Grid
          container
          css={css`
            margin-top: 20px;
          `}
        >
          <Grid
            item
            container
            xs={6}
            justifyContent="flex-start"
            alignItems="center"
          >
            <h3
              css={css`
                margin: 0;
              `}
            >
              {courseId ? `${course?.name}の基本情報の編集` : 'コースの追加'}
            </h3>
          </Grid>
          <Grid
            item
            container
            xs={6}
            alignItems="center"
            justifyContent="flex-end"
          >
            {buildRightNavigationButtons()}
          </Grid>
        </Grid>
        <Paper css={commonCss.paper}>
          <Box mb={2}>
            <TextField
              label="コースID"
              helperText="コースの一意なIDを設定します(例: 見学予約ならkengaku)。半角英数小文字、ハイフン、アンダーバーが使用できます。Web予約画面のURLに使用されます。"
              value={form.uid}
              fullWidth
              onChange={handleChangeUid}
              required
              inputProps={{ maxLength: 20, pattern: '^[a-z0-9_-]+$' }}
            />
          </Box>
          <Box mb={2}>
            <TextField
              label="コース名"
              helperText="コース名を設定します（例: 見学予約）。"
              value={form.name}
              fullWidth
              onChange={handleChangeName}
              required
              inputProps={{ maxLength: 100 }}
            />
          </Box>
          <Box mb={2}>
            <TextField
              label="コースの説明"
              helperText="コースの説明を設定します（例: レッスン内容を見学することができます。）。Web予約画面にコースの説明として表示されます。HTMLタグはimg, a, span, table, tr, th, tdが使用できます。(上限1000文字)"
              value={form.description}
              fullWidth
              multiline
              onChange={handleChangeDescription}
              error={form.description.length > 1000}
            />
          </Box>
          <Box mb={2}>
            <TextField
              label="予約完了時のメッセージ"
              helperText="予約完了画面のメッセージを設定します。（初期値: 上記の日時で予約完了しました。）。"
              value={form.thanksTitle}
              fullWidth
              multiline
              onChange={handleChangeThanksTitle}
            />
          </Box>
          <Box mb={2}>
            <TextField
              label="予約完了時の補足文"
              helperText="予約完了画面の補足文を設定します。HTMLタグはimg, a, span, table, tr, th, tdが使用できます。(上限1000文字)"
              value={form.thanksMessage}
              fullWidth
              multiline
              onChange={handleChangeThanksMessage}
              error={(form.thanksMessage?.length || 0) > 1000}
            />
          </Box>
          <Box mb={2}>
            <TextField
              label="コース選択ボタンのラベル"
              helperText="コース選択ボタンのラベルを変更します。未設定の場合は「空席確認・予約する」と表示されます。"
              value={form.buttonLabel}
              fullWidth
              inputProps={{ maxLength: 10 }}
              onChange={handleChangeButtonLabel}
            />
          </Box>
          <Box mb={2}>
            <TextField
              label="日時選択の説明文"
              helperText="日時選択の説明文を変更します。未設定の場合は「ご都合のよい時間帯をクリックし、ご予約を確定してください。」と表示されます。"
              value={form.dateSelectMessage}
              fullWidth
              inputProps={{ maxLength: 200 }}
              onChange={handleChangeDateSelectMessage}
            />
          </Box>
          <Box>
            <FormControl component="fieldset">
              <FormControlLabel
                control={
                  <Switch
                    checked={form.isShowContinueReserve}
                    onChange={handleChangeContinueReserve}
                    color="primary"
                  />
                }
                label="「続けて予約する」ボタンを表示する"
              />
            </FormControl>
            <p className="MuiFormHelperText-root">
              予約完了画面で「続けて予約する」ボタンを表示・非表示を設定します。
            </p>
          </Box>
          <Box>
            <Box mt={3}>
              <FormControl component="fieldset">
                <FormControlLabel
                  control={
                    <Switch
                      checked={isSettingImage}
                      onChange={() => {
                        setIsSettingImage(!isSettingImage);
                      }}
                      color="primary"
                    />
                  }
                  label="コースのイメージ画像を設定する"
                />
              </FormControl>
              <Box mt={2}>
                <Box>
                  {isSettingImage && (
                    <Box>
                      {courseImage !== undefined ? (
                        <Box css={styles.uploadImage} mt={1}>
                          <img
                            src={URL.createObjectURL(courseImage)}
                            css={styles.image}
                            alt=""
                          />
                          <IconButton
                            css={styles.removeIcon}
                            onClick={removeImage}
                          >
                            <CloseIcon />
                          </IconButton>
                        </Box>
                      ) : form.imagePath !== null ? (
                        <Box css={styles.uploadImage} mt={1}>
                          <img
                            src={`${LOGO_BASE_URL}/${form.imagePath}`}
                            css={styles.image}
                            alt=""
                          />
                          <IconButton
                            css={styles.removeIcon}
                            onClick={removeImage}
                          >
                            <CloseIcon />
                          </IconButton>
                        </Box>
                      ) : (
                        <>
                          <Typography
                            variant="body2"
                            css={css`
                              font-size: 14px;
                              margin-bottom: 4px;
                            `}
                          >
                            推奨サイズ:750×420px
                          </Typography>
                          <input
                            type="file"
                            accept="image/*"
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              handleUpdatedIconImage(e);
                            }}
                          />
                        </>
                      )}
                    </Box>
                  )}
                </Box>
                <Grid
                  container
                  css={css`
                    margin-top: 16px;
                  `}
                >
                  <Grid item xs={12} sm={6} css={styles.previewCourse}>
                    <p css={styles.previewLabel}>プレビュー(コース選択)</p>
                    <Box css={styles.previewWrapper}>
                      <Box
                        css={css`
                          background: #ffffff;
                          border-radius: 10px;
                          padding: 10px;
                        `}
                      >
                        <Grid container>
                          <Grid item>
                            {isSettingImage && courseImage !== undefined ? (
                              <div
                                css={css`
                                  width: 100px;
                                `}
                              >
                                <img
                                  src={URL.createObjectURL(courseImage)}
                                  alt=""
                                  css={css`
                                    width: 100%;
                                  `}
                                />
                              </div>
                            ) : (
                              isSettingImage &&
                              form.imagePath !== null && (
                                <div
                                  css={css`
                                    width: 100%;
                                  `}
                                >
                                  <img
                                    src={`${LOGO_BASE_URL}/${form.imagePath}`}
                                    alt=""
                                    css={css`
                                      width: 100%;
                                    `}
                                  />
                                </div>
                              )
                            )}
                          </Grid>
                          <Grid
                            item
                            xs
                            css={css`
                              padding-left: 10px;
                            `}
                          >
                            <p
                              css={css`
                                font-size: 18px;
                                font-weight: bold;
                                margin: 0;
                                text-align: left;
                              `}
                            >
                              {form.name || 'コース名'}
                            </p>
                            <Box
                              css={css`
                                width: 100%;
                                margin-top: 20px;
                                display: flex;
                                justify-content: flex-end;
                              `}
                            >
                              <div css={styles.button}>
                                {form.buttonLabel || '空席確認・予約する'}
                              </div>
                            </Box>
                          </Grid>
                        </Grid>
                        <Box mt={2} css={styles.previewDescription}>
                          <Description
                            description={
                              form.description ||
                              'コースの説明はここに表示されます。'
                            }
                          />
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6} css={styles.previewTime}>
                    <p css={styles.previewLabel}>プレビュー(時間選択)</p>
                    <Box css={styles.previewWrapper}>
                      <Box
                        css={css`
                          border: 1px solid #aaaaaa;
                        `}
                      >
                        <Grid
                          container
                          alignItems="center"
                          css={css`
                            padding: 10px 20px;
                          `}
                        >
                          <Grid item>
                            <Box
                              css={css`
                                position: relative;
                                width: 80px;
                                height: 40px;
                                line-height: 40px;
                                background: rgba(103, 58, 183, 0.67);
                              `}
                            >
                              <p
                                css={css`
                                  text-align: center;
                                  margin: 0;
                                  font-weight: bold;
                                `}
                              >
                                ロゴ画像
                              </p>
                            </Box>
                          </Grid>
                          <Grid
                            item
                            css={css`
                              margin-left: 12px;
                            `}
                          >
                            <p
                              css={css`
                                font-size: 16px;
                                font-weight: 600;
                              `}
                            >
                              店舗名
                            </p>
                          </Grid>
                        </Grid>
                        <Box
                          css={css`
                            background: #ffffff;
                          `}
                        >
                          <Box>
                            {isSettingImage && courseImage !== undefined ? (
                              <img
                                src={URL.createObjectURL(courseImage)}
                                css={css`
                                  width: 100%;
                                `}
                                alt="イメージ画像"
                              />
                            ) : (
                              isSettingImage &&
                              form.imagePath !== null && (
                                <img
                                  src={`${LOGO_BASE_URL}/${form.imagePath}`}
                                  css={css`
                                    display: block;
                                    width: 100%;
                                    margin: 0 auto;
                                  `}
                                  alt="イメージ画像"
                                />
                              )
                            )}
                          </Box>
                          <Box p={3}>
                            <p
                              css={css`
                                font-size: 24px;
                                font-weight: bold;
                                margin: 0;
                                text-align: left;
                                color: #172b4d;
                              `}
                            >
                              {form.name || 'コース名'}
                            </p>
                            <Box css={styles.previewDescription}>
                              <Description
                                description={
                                  form.description ||
                                  'コースの説明はここに表示されます。'
                                }
                              />
                            </Box>
                            <p
                              css={css`
                                font-size: 14px;
                                color: #2c3f4c;
                              `}
                            >
                              {form.dateSelectMessage ||
                                'ご都合のよい時間帯をクリックし、ご予約を確定してください。'}
                            </p>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
              {isShowIconCropperDialog && courseImage !== undefined && (
                <ImageCropperDialog
                  file={courseImage}
                  onCropImage={handleCropIconImage}
                  onClose={() => {
                    setIsShowIconCropperDialog(false);
                  }}
                  aspect={375 / 210}
                />
              )}
            </Box>
          </Box>
        </Paper>
        <h3>コースメール設定</h3>
        <Alert severity="info">
          <div>
            コースメールの設定は省略可能です。あとから設定にすることもできます。
          </div>
        </Alert>
        <Paper css={commonCss.paper}>
          <TextField
            label="コースメールヘッダー"
            helperText={
              <>
                このコースが選択された際のメール（予約確認メール、リマインダなど）のヘッダーを設定します。店舗で設定されたメールヘッダーの後に差し込まれます。入力内容に「
                <strong>{'{ご予約者}'}</strong>
                」を入れると、予約者のお名前に置換されます。
              </>
            }
            value={form.emailHeader}
            fullWidth
            multiline
            onChange={handleChangeEmailHeader}
          />
          <TextField
            label="コースメールフッター"
            helperText={
              <>
                このコースが選択された際のメール（予約確認メール、リマインダなど）の共通フッターを設定します。店舗で設定されたメールフッターの前に差し込まれます。入力内容に「
                <strong>{'{ご予約者}'}</strong>
                」を入れると、予約者のお名前に置換されます。
              </>
            }
            value={form.emailFooter}
            fullWidth
            multiline
            onChange={handleChangeEmailFooter}
          />
        </Paper>
        <Grid
          container
          css={css`
            margin-top: 20px;
          `}
        >
          <Grid item xs={6}>
            {courseId && (
              <Button
                type="button"
                variant="contained"
                color="default"
                onClick={handleClickDelete}
              >
                削除する
              </Button>
            )}
          </Grid>
          <Grid
            item
            container
            xs={6}
            alignItems="center"
            justifyContent="flex-end"
          >
            {buildRightNavigationButtons()}
          </Grid>
        </Grid>
      </form>
    );
  };

  const pageTitle = `コースの${courseId === undefined ? '追加' : '編集'}`;
  const description =
    courseId === undefined
      ? 'ワークスペース配下の店舗で使用するコースを追加します。'
      : 'コース名、予約完了時のメッセージ等の設定を行います。';

  return (
    <Root>
      <Head title={`ワークスペース設定 - ${course?.name || ''}`} />
      <Main>
        <WorkspacePageLayout
          workspaceUid={workspaceUid}
          current="course"
          breadcrumbs={
            <Breadcrumbs aria-label="breadcrumb">
              <BreadcrumbLinkItem to={`/`}>ホーム</BreadcrumbLinkItem>
              <BreadcrumbWorkspaceItem workspaceUid={workspaceUid} />
              <BreadcrumbLinkItem to={`/a/${workspaceUid}/settings/courses`}>
                コース設定
              </BreadcrumbLinkItem>
              <BreadcrumbTextItem>{pageTitle}</BreadcrumbTextItem>
            </Breadcrumbs>
          }
          helpId={helps.workspace.setting.course}
        >
          <PageTitleAndDescription
            title="ワークスペース"
            subTitle={pageTitle}
            description={description}
            themeColor={globalColors.workspace}
          />
          {buildContents()}
        </WorkspacePageLayout>
      </Main>
      <FullscreenLoading open={openBackdrop} />
    </Root>
  );
}
