/** @jsxImportSource @emotion/react */

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import React, { useContext } from 'react';
import useReactRouter from 'use-react-router';
import { WorkspaceCourse } from '../../../@interfaces/course';
import { API_END_POINT } from '../../../api/api';
import { Store } from '../../../context/GlobalStore';

interface Props {
  copyBased?: WorkspaceCourse;
  workspaceUid: string;
  onClose: () => void;
}

export default function WorkspaceCoursesCopyConfirmationDialog({
  copyBased,
  workspaceUid,
  onClose,
}: Props) {
  const { globalState } = useContext(Store);
  const { history } = useReactRouter();
  const { enqueueSnackbar } = useSnackbar();

  const copyFunc = () => {
    if (!globalState.session?.idToken || !copyBased) {
      return;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let headers: any = {
      Authorization: globalState.session?.idToken,
    };

    axios
      .post(
        `${API_END_POINT}/app/ws/${workspaceUid}/courses/${copyBased.id}/copy`,
        {},
        { headers }
      )
      .then((res) => {
        enqueueSnackbar('コースを複製しました。', { variant: 'success' });
        history.push(
          `/a/${workspaceUid}/settings/courses/${res.data.course.id}`
        );
      })
      .catch((e) => {
        enqueueSnackbar('コースを複製できませんでした。', {
          variant: 'error',
        });
      })
      .finally(() => {
        onClose();
      });
  };

  return (
    <Dialog open={copyBased !== undefined} onClose={onClose}>
      <DialogTitle>コースの複製</DialogTitle>
      <DialogContent>
        <DialogContentText color="textPrimary">
          コース「{copyBased?.name}」の複製を実行しますか？
          <br />
          基本情報と予約フォームの内容が複製されます。
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>キャンセル</Button>
        <Button onClick={copyFunc} color="primary" variant="contained">
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
}
