import katakanaMapJson from './katakanaMap.json';
import romanToKatakanaMapJson from './romanToKatakanaMap.json';

export const getFirstLine = (s: string) => (s ? s.split('\n')[0] : '');

export function randomString(length = 8): string {
  const chars =
    'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  return Array.from(Array(length))
    .map(() => chars[Math.floor(Math.random() * chars.length)])
    .join('');
}

export const truncate = (
  str: string,
  length: number,
  ellipsis = ''
): string => {
  if (str.length <= length) {
    return str;
  }
  const truncated = str.substring(0, length - ellipsis.length);
  return `${truncated}${ellipsis}`;
};

interface KataKanaMap {
  [key: string]: string;
}
export function kanaHalfToFull(input: string): string {
  const katakanaMap: KataKanaMap = katakanaMapJson;
  const reg = new RegExp('(' + Object.keys(katakanaMap).join('|') + ')', 'g');
  return input
    .replace(reg, function (match: string) {
      return katakanaMap[match];
    })
    .replace(/ﾞ/g, '゛')
    .replace(/ﾟ/g, '゜');
}

interface ITree {
  [key: string]: ITree | string;
}
export function romanToKana(original: string) {
  const tree: ITree = romanToKatakanaMapJson;
  const str = original
    .replace(/[Ａ-Ｚａ-ｚ]/, (s) =>
      String.fromCharCode(s.charCodeAt(0) - 65248)
    )
    .toLowerCase(); // 全角→半角→小文字
  let result = '';
  let tmp = '';
  let index = 0;
  const len = str.length;
  let node = tree;
  const push = (char: string, toRoot = true) => {
    result += char;
    tmp = '';
    node = toRoot ? tree : node;
  };
  while (index < len) {
    const char = str.charAt(index);
    if (/[a-z]/.exec(char)) {
      // 英数字以外は考慮しない
      if (char in node) {
        const next = node[char];
        if (typeof next === 'string') {
          push(next);
        } else {
          tmp += original.charAt(index);
          node = next;
        }
        index++;
        continue;
      }
      const prev = str.charAt(index - 1);
      if (prev && (prev === 'n' || prev === char)) {
        // 促音やnへの対応
        push(prev === 'n' ? 'ン' : 'ッ', false);
      }
      if (node !== tree && char in tree) {
        // 今のノードがルート以外だった場合、仕切り直してチェックする
        push(tmp);
        continue;
      }
    }
    push(tmp + char);
    index++;
  }
  tmp = tmp.replace(/n$/, 'ン'); // 末尾のnは変換する
  push(tmp);
  return result;
}

export function kanaToHiragana(str: string) {
  return str.replace(/[\u30a1-\u30f6]/g, function (match) {
    const chr = match.charCodeAt(0) - 0x60;
    return String.fromCharCode(chr);
  });
}

const KANA_REGEX = /^[\p{scx=Hiragana}]+$/u;
export const isKana = (value: string): boolean => !!KANA_REGEX.exec(value);
