import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
} from '@material-ui/core';
import Link from '@material-ui/core/Link';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import dayjs from 'dayjs';
import { useSnackbar } from 'notistack';
import React, { ChangeEvent, useContext, useState } from 'react';
import { Information } from '../../../@interfaces/information';
import { Store } from '../../../context/GlobalStore';
import useInformations, {
  addInformation,
  deleteInformation,
  updateInformation,
} from '../api/use-informations';
import { LatestInfomationDialog } from './LatestInfomationDialog';

export default function Informations(): JSX.Element | null {
  const { globalState } = useContext(Store);

  const { informations, reloadInformations, isLoadingInformations } =
    useInformations();
  const [edit, setEdit] = useState(false);
  const [selectedInformation, setSelectedInformation] = useState<Information>();

  const handleClickEditStart = () => {
    setEdit(true);
  };

  const handleClickEditCancel = () => {
    setEdit(false);
  };

  const handleClickEdit = (information: Information) => {
    setSelectedInformation({ ...information });
  };

  const handleClickAdd = () => {
    setSelectedInformation({
      id: -1,
      releaseDate: dayjs().format('YYYY-MM-DD'),
      title: '',
      url: '',
    });
  };

  const buildInformation = (information: Information) => {
    return (
      <Grid
        item
        container
        xs={12}
        alignItems="center"
        key={information.title} // RSS から取得した information は id が -1 固定となっているため、title をキーにする
        style={{ padding: '12px' }}
      >
        <Link
          href={`${information.url}?utm_source=locaop_meo_app` || '#'}
          rel="noreferrer noopener"
          target="_blank"
          style={{ fontSize: '14px', fontWeight: 'bold' }}
        >
          {information.releaseDate.substring(0, 10)} {information.title}
          <OpenInNewIcon style={{ fontSize: '14px' }} />
        </Link>
        {edit && information.id !== -1 ? (
          <>
            <Button
              color="primary"
              size="small"
              variant="text"
              onClick={() => {
                handleClickEdit(information);
              }}
            >
              編集
            </Button>
          </>
        ) : null}
      </Grid>
    );
  };

  const buildDialog = () => {
    if (!selectedInformation) {
      return null;
    }

    return (
      <InformationEditDialog
        information={selectedInformation}
        onUpdateInformation={setSelectedInformation}
        reloadInformations={reloadInformations}
      />
    );
  };

  const buildButtons = () => {
    if (!globalState.signedUser?.account.isSuperUser) {
      return null;
    }
    if (edit) {
      return (
        <Grid item container xs={12} justifyContent="flex-end">
          <Button
            color="primary"
            size="small"
            variant="text"
            onClick={handleClickEditCancel}
          >
            キャンセル
          </Button>
          &nbsp;|&nbsp;
          <Button
            color="primary"
            size="small"
            variant="text"
            onClick={handleClickAdd}
          >
            追加
          </Button>
        </Grid>
      );
    } else {
      return (
        <Grid item container xs={12} justifyContent="flex-end">
          <Button
            color="primary"
            size="small"
            variant="text"
            onClick={handleClickEditStart}
          >
            編集
          </Button>
        </Grid>
      );
    }
  };

  if (isLoadingInformations) {
    return <CircularProgress />;
  }

  return (
    <Grid item xs={12}>
      {informations.map(buildInformation)}
      {buildDialog()}
      {buildButtons()}
      <LatestInfomationDialog informations={informations} />
    </Grid>
  );
}

const InformationEditDialog = ({
  information,
  onUpdateInformation,
  reloadInformations,
}: {
  information: Information;
  onUpdateInformation: (information: Information | undefined) => void;
  reloadInformations: (forceLoading: boolean) => void;
}) => {
  const { globalState } = useContext(Store);
  const { enqueueSnackbar } = useSnackbar();

  const handleSave = () => {
    if (!globalState.session) {
      return;
    }
    if (information.id === -1) {
      addInformation(globalState.session.idToken, information).then(() => {
        onUpdateInformation(undefined);
        reloadInformations(true);
        enqueueSnackbar('お知らせを追加しました。');
      });
    } else {
      updateInformation(globalState.session.idToken, information).then(() => {
        onUpdateInformation(undefined);
        reloadInformations(true);
        enqueueSnackbar('お知らせを更新しました。');
      });
    }
  };

  const handleDelete = () => {
    if (!globalState.session) {
      return;
    }
    if (!window.confirm('お知らせを削除してもよろしいですか？')) {
      return;
    }
    deleteInformation(globalState.session.idToken, information.id).then(() => {
      onUpdateInformation(undefined);
      reloadInformations(true);
      enqueueSnackbar('お知らせを削除しました。');
    });
  };

  const handleClose = () => {
    onUpdateInformation(undefined);
  };

  const handleUpdateValue = (
    e: ChangeEvent<HTMLInputElement>,
    key: 'releaseDate' | 'title' | 'url'
  ) => {
    information[key] = e.target.value;
    onUpdateInformation({ ...information });
  };

  return (
    <Dialog open={true} onClose={handleClose}>
      <DialogTitle>お知らせの編集</DialogTitle>
      <DialogContent>
        <Grid container direction="column">
          <TextField
            placeholder="YYYY-MM-DD"
            label="日付"
            type="date"
            value={information.releaseDate}
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              handleUpdateValue(event, 'releaseDate');
            }}
          />
          <TextField
            placeholder="タイトル"
            label="タイトル"
            style={{ width: '500px' }}
            value={information.title}
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              handleUpdateValue(event, 'title');
            }}
          />
          <TextField
            placeholder="URL"
            label="url"
            type="url"
            value={information.url}
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              handleUpdateValue(event, 'url');
            }}
          />
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          キャンセル
        </Button>
        <Button onClick={handleDelete} color="primary">
          削除
        </Button>
        <Button onClick={handleSave} color="primary" autoFocus>
          保存
        </Button>
      </DialogActions>
    </Dialog>
  );
};
