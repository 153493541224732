/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import {
  Checkbox,
  FormControlLabel,
  MenuItem,
  Select,
} from '@material-ui/core';
import EmailIcon from '@material-ui/icons/Email';
import SendIcon from '@material-ui/icons/Send';
import { Alert, AlertTitle } from '@material-ui/lab';
import { useEffect, useMemo } from 'react';
import { SelectedCustomer } from '../../../../@interfaces/customer';
import {
  FormResponse,
  FormSetting,
} from '../../../../core/types/reservation-form-types';
import { useShopCustomerLineMessagingChannels } from '../../../../features/line/api/useShopCustomerLineMessagingChannels';
import { LineInfo } from '../LineInfo';
import { NotificationForm } from './types';

const styles = {
  container: css`
    margin-top: 20px;
  `,
  description: css`
    color: #777;
    font-size: 14px;
  `,
  notificationItem: css`
    display: flex;
    align-items: center;
  `,
  toAddressInfo: css`
    display: flex;
    align-items: center;
    color: #777;
  `,
};

type NotificationInfoProps = {
  shopId: number;
  formSetting: FormSetting;
  formResponse: FormResponse;
  selectedCustomer: SelectedCustomer | undefined;
  notificationForm: NotificationForm;
  onUpdateNotificationForm: (notificationForm: NotificationForm) => void;
};

/**
 * 内部予約時の通知設定の情報を表示/設定するためのコンポーネント
 */
export const NotificationInfo = ({
  shopId,
  formSetting,
  formResponse,
  selectedCustomer,
  notificationForm,
  onUpdateNotificationForm,
}: NotificationInfoProps) => {
  useEffect(() => {
    onUpdateNotificationForm({
      formEmail: false,
      customerEmail: false,
      lineMessagingChannelKey: undefined,
    });
  }, [onUpdateNotificationForm, selectedCustomer]);

  const formEmailAddress = useMemo(() => {
    const emailField = formSetting.fields.find(
      (field) => field.type === 'email'
    );
    return emailField
      ? (formResponse.fields.find((field) => field.uid === emailField.uid)
          ?.values[0] as string)
      : undefined;
  }, [formResponse.fields, formSetting.fields]);

  return (
    <Alert
      color="warning"
      icon={<SendIcon fontSize="inherit" />}
      css={styles.container}
    >
      <AlertTitle>通知設定</AlertTitle>
      <div css={styles.description}>
        予約登録時に予約の内容を顧客に通知するかどうかを選択します
      </div>
      {!formEmailAddress &&
        !selectedCustomer?.email &&
        !selectedCustomer?.lineUserId && (
          <div>選択可能な通知先(メール、LINE)はありません。</div>
        )}
      {formEmailAddress && (
        <FormEmailNotification
          {...{
            formEmailAddress,
            notificationForm,
            onUpdateNotificationForm,
          }}
        />
      )}
      {selectedCustomer?.email && (
        <CustomerEmailNotification
          {...{
            customerEmail: selectedCustomer.email,
            notificationForm,
            onUpdateNotificationForm,
          }}
        />
      )}
      <LineNotification
        {...{
          shopId,
          selectedCustomer,
          notificationForm,
          onUpdateNotificationForm,
        }}
      />
    </Alert>
  );
};

type FormEmailNotificationProps = {
  formEmailAddress: string;
  notificationForm: NotificationForm;
  onUpdateNotificationForm: (notificationForm: NotificationForm) => void;
};

/**
 * フォームに入力されたメールアドレスに対する通知設定
 */
const FormEmailNotification = ({
  formEmailAddress,
  notificationForm,
  onUpdateNotificationForm,
}: FormEmailNotificationProps) => {
  const handleChangeCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
    onUpdateNotificationForm({
      ...notificationForm,
      formEmail: e.target.checked,
    });
  };

  return (
    <div>
      <FormControlLabel
        control={
          <Checkbox
            checked={notificationForm.formEmail}
            onChange={handleChangeCheck}
          />
        }
        label={
          <div css={styles.notificationItem}>
            予約完了メールを送信(
            <span css={styles.toAddressInfo}>
              宛先: <EmailIcon fontSize="small" />
              {formEmailAddress})
            </span>
          </div>
        }
      />
    </div>
  );
};

type CustomerEmailNotificationProps = {
  customerEmail: string;
  notificationForm: NotificationForm;
  onUpdateNotificationForm: (notificationForm: NotificationForm) => void;
};

/**
 * 顧客に設定されたメールアドレスに対する通知設定
 */
const CustomerEmailNotification = ({
  customerEmail,
  notificationForm,
  onUpdateNotificationForm,
}: CustomerEmailNotificationProps) => {
  const handleChangeCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
    onUpdateNotificationForm({
      ...notificationForm,
      customerEmail: e.target.checked,
    });
  };

  return (
    <div>
      <FormControlLabel
        control={
          <Checkbox
            checked={notificationForm.customerEmail}
            onChange={handleChangeCheck}
          />
        }
        label={
          <div css={styles.notificationItem}>
            予約完了をメールで送信(
            <span css={styles.toAddressInfo}>
              <EmailIcon fontSize="small" />
              {customerEmail})
            </span>
          </div>
        }
      />
    </div>
  );
};

type LineNotificationProps = {
  shopId: number;
  selectedCustomer: SelectedCustomer | undefined;
  notificationForm: NotificationForm;
  onUpdateNotificationForm: (notificationForm: NotificationForm) => void;
};

/**
 * LINEに対する通知設定
 */
const LineNotification = ({
  shopId,
  selectedCustomer,
  notificationForm,
  onUpdateNotificationForm,
}: LineNotificationProps) => {
  const { data: channels, isLoading: isLoadingChannels } =
    useShopCustomerLineMessagingChannels(shopId, selectedCustomer?.id);

  const checked = notificationForm.lineMessagingChannelKey !== undefined;

  const handleChangeCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!channels || channels.channels.length === 0) {
      return;
    }
    if (notificationForm.lineMessagingChannelKey) {
      onUpdateNotificationForm({
        ...notificationForm,
        lineMessagingChannelKey: undefined,
      });
    } else {
      onUpdateNotificationForm({
        ...notificationForm,
        lineMessagingChannelKey: channels.channels[0].channelKey,
      });
    }
  };

  const handleChangeChannel = (
    e: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    onUpdateNotificationForm({
      ...notificationForm,
      lineMessagingChannelKey: e.target.value as string,
    });
  };

  if (
    !selectedCustomer?.lineUserId ||
    !channels ||
    channels.channels.length === 0
  ) {
    return null;
  }
  return (
    <div>
      <FormControlLabel
        control={<Checkbox checked={checked} onChange={handleChangeCheck} />}
        label={
          <div css={styles.notificationItem}>
            <span>予約完了をLINEで送信</span>(
            <span css={styles.toAddressInfo}>
              <Select
                value={
                  notificationForm.lineMessagingChannelKey ||
                  channels.channels[0].channelKey
                }
                onChange={handleChangeChannel}
              >
                {channels.channels.map((channel) => (
                  <MenuItem key={channel.channelKey} value={channel.channelKey}>
                    {channel.channelName}
                  </MenuItem>
                ))}
              </Select>
              から
              <LineInfo
                lineUser={{
                  lineUserId: selectedCustomer.lineUserId,
                  lineDisplayName: selectedCustomer.lineDisplayName,
                }}
              />
              宛に送信
            </span>
            )
          </div>
        }
      />
    </div>
  );
};
