/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { Divider } from '@material-ui/core';
import { AiOutlineRight } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import { globalColors } from '../../../styles/globalColors';
import { buttonReset, sideMenuItemStyles } from '../styles';

const calcSideColorStyle = (sideColor: string) => css`
  border-left: 4px solid ${sideColor};
`;

const calcCurrentColorStyle = (currentColor = globalColors.currentPage) => css`
  background-color: ${currentColor};
  font-weight: bold;
  border-left: 4px solid ${currentColor};
  transition: none;

  &:hover {
    background-color: ${currentColor};
    border-color: ${currentColor};
  }
`;

type SideMenuItemProps = {
  label: React.ReactNode;
  href: string;
  isCurrent: boolean;
  sideColor: string;
  currentColor?: string;
  icon?: React.ReactNode;
};

export function SideMenuItem({
  label,
  href,
  isCurrent,
  sideColor,
  currentColor,
  icon,
}: SideMenuItemProps): JSX.Element {
  return (
    <Link
      css={[
        sideMenuItemStyles.sideMenuItem,
        calcSideColorStyle(sideColor),
        isCurrent && calcCurrentColorStyle(currentColor),
      ]}
      to={href}
    >
      <span
        css={css`
          color: rgba(0, 0, 0, 0.54);
          margin-right: 12px;
          margin-top: 5px;
        `}
      >
        {icon}
      </span>
      <span
        css={css`
          flex: 1;
        `}
      >
        {label}
      </span>
      <AiOutlineRight
        css={css`
          color: rgba(0, 0, 0, 0.54);
        `}
      />
    </Link>
  );
}

type SideMenuItemButtonProps = {
  label: string;
  isCurrent: boolean;
  sideColor: string;
  onClick: () => void;
};

export function SideMenuItemButton({
  label,
  isCurrent,
  sideColor,
  onClick,
}: SideMenuItemButtonProps): JSX.Element {
  return (
    <button
      css={[
        buttonReset,
        sideMenuItemStyles.sideMenuItem,
        calcSideColorStyle(sideColor),
        isCurrent && sideMenuItemStyles.sideMenuCurrentItem,
      ]}
      onClick={onClick}
    >
      <span>{label}</span>
      <AiOutlineRight />
    </button>
  );
}

type SideMenuCategoryItemProps = { label: string; themeColor: string };

export function SideMenuCategoryItem({
  label,
  themeColor: color,
}: SideMenuCategoryItemProps): JSX.Element {
  return (
    <div
      css={[
        sideMenuItemStyles.categoryItem,
        css`
          background-color: ${color};
          border-left: 4px solid ${color};
        `,
      ]}
    >
      <span>{label}</span>
    </div>
  );
}

export function SideMenuDivider({
  sideColor,
}: {
  sideColor: string;
}): JSX.Element {
  return (
    <div
      css={[
        css`
          display: flex;
          justify-content: center;
        `,
        calcSideColorStyle(sideColor),
      ]}
    >
      <Divider
        css={css`
          width: 80%;
          margin: 4px 0;
        `}
      />
    </div>
  );
}
