import { css, Global } from '@emotion/react';
import { CssBaseline } from '@material-ui/core';
import { createTheme } from '@material-ui/core/styles';
import { StylesProvider, ThemeProvider } from '@material-ui/styles';
import axios from 'axios';
import { SnackbarProvider } from 'notistack';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import { colors } from './colors';
import Auth from './components/Auth';
import { AutoUpdater } from './components/AutoUpdater';
import ScrollToTop from './components/ScrollToTop';
import { Provider } from './context/GlobalStore';
import CustomerEditPage from './features/crm/routes/CustomerEditPage';
import CustomerListPage from './features/crm/routes/CustomerListPage';
import ConversionPage from './features/insight/routes/ConversionPage';
import CoursePage from './features/insight/routes/CoursePage';
import HourlyPage from './features/insight/routes/HourlyPage';
import InflowSourcePage from './features/insight/routes/InflowSourcePage';
import InsightPage from './features/insight/routes/InsightPage';
import HomePage from './pages/HomePage';
import InvitationPage from './pages/public/InivitationPage';
import InvitationActivationPage from './pages/public/InvitationActivationPage';
import PasswordReminderPage from './pages/public/PasswordReminderPage';
import SignInPage from './pages/public/SignInPage';
import ReservationEditPage from './pages/shop/ReservationEditPage/ReservationEditPage';
import ShopBasicSettingPage from './pages/shop/settings/ShopBasicSettingPage';
import ShopCourseChooserPage from './pages/shop/settings/ShopCourseChooserPage';
import ShopCourseSettingPage from './pages/shop/settings/ShopCourseSettingPage';
import ShopCourseSlotSettingPage from './pages/shop/settings/ShopCourseSlotSettingPage';
import ShopCustomDomainSettingPage from './pages/shop/settings/ShopCustomDomainSettingPage';
import ShopGoogleOauthCallbackPage from './pages/shop/settings/ShopGoogleOauthCallbackPage';
import ShopIntegrationsSettingEditPage from './pages/shop/settings/ShopIntegrationsSettingEditPage';
import ShopIntegrationsSettingPage from './pages/shop/settings/ShopIntegrationsSettingPage';
import ShopMemberSettingPage from './pages/shop/settings/ShopMemberSettingPage';
import ShopNotifyDestinationSettingPage from './pages/shop/settings/ShopNotifyDestinationSettingPage';
import ShopReservationSettingPage from './pages/shop/settings/ShopReservationSettingPage';
import ShopResourceGroupSettingPage from './pages/shop/settings/ShopResourceGroupSettingPage';
import ShopResourceListSettingPage from './pages/shop/settings/ShopResourceListSettingPage';
import ShopResourcePatternSettingPage from './pages/shop/settings/ShopResourcePatternSettingPage';
import ShopResourceTableSettingPage from './pages/shop/settings/ShopResourceTableSettingPage';
import ShopSlotSettingPage from './pages/shop/settings/ShopSlotSettingPage';
import ShopSlotSettingRulePage from './pages/shop/settings/ShopSlotSettingRulePage';
import ShopSlotSettingsPage from './pages/shop/settings/ShopSlotSettingsPage';
import ShopSpecialRuleSettingPage from './pages/shop/settings/ShopSpecialRuleSettingPage';
import ShopThemeSettingPage from './pages/shop/settings/ShopThemeSettingPage';
import ShopPage from './pages/shop/ShopPage';
import ChangeEmailConfirmPage from './pages/user/ChangeEmailConfirmPage';
import UserProfilePage from './pages/user/UserProfilePage';
import WorkspaceBasicSettingPage from './pages/workspace/settings/WorkspaceBasicSettingPage';
import WorkspaceCourseFormSettingPage from './pages/workspace/settings/WorkspaceCourseFormSettingPage/WorkspaceCourseFormSettingPage';
import WorkspaceCourseSettingEditPage from './pages/workspace/settings/WorkspaceCourseSettingEditPage';
import WorkspaceCourseSettingPage from './pages/workspace/settings/WorkspaceCourseSettingPage';
import WorkspaceCrmSettingPage from './pages/workspace/settings/WorkspaceCrmSettingPage';
import WorkspaceCustomDomainSettingPage from './pages/workspace/settings/WorkspaceCustomDomainSettingPage';
import WorkspaceInflowSourceSettingEditPage from './pages/workspace/settings/WorkspaceInflowSourceSettingEditPage';
import WorkspaceInflowSourceSettingPage from './pages/workspace/settings/WorkspaceInflowSourceSettingPage';
import WorkspaceLabelSettingEditPage from './pages/workspace/settings/WorkspaceLabelSettingEditPage';
import WorkspaceLabelSettingPage from './pages/workspace/settings/WorkspaceLabelSettingPage';
import WorkspaceLineLoginChannelSettingEditPage from './pages/workspace/settings/WorkspaceLineLoginChannelSettingEditPage';
import WorkspaceLineMessagingChannelSettingEditPage from './pages/workspace/settings/WorkspaceLineMessagingChannelSettingEditPage';
import WorkspaceLineSettingPage from './pages/workspace/settings/WorkspaceLineSettingPage';
import WorkspaceMemberSettingPage from './pages/workspace/settings/WorkspaceMemberSettingPage';
import WorkspaceThemeSettingPage from './pages/workspace/settings/WorkspaceThemeSettingPage';
import ShopNewPage from './pages/workspace/ShopNewPage';
import WorkspaceDashboardPage from './pages/workspace/WorkspaceDashboardPage';
import WorkspaceShopReportsPage from './pages/workspace/WorkspaceShopReportsPage';
import WorkspacesNewPage from './pages/WorkspacesNew';
import { globalColors } from './styles/globalColors';

const globalStyles = css`
  .MuiAlert-standardInfo {
    background-color: #daf3e8;
    color: #000;
    & a {
      color: #000;
    }
    & .MuiAlert-icon {
      color: #017b6b;
    }
  }
`;

const theme = () =>
  createTheme({
    palette: {
      primary: {
        main: globalColors.button,
        contrastText: '#fff',
      },
      secondary: {
        main: colors.secondary,
      },
      background: {
        default: '#fafafa',
      },
    },
    typography: {
      button: {
        textTransform: 'none',
      },
    },
    overrides: {
      MuiOutlinedInput: {
        root: {
          // 検索バーのnotchedを消す
          '& $notchedOutline': {
            borderColor: 'rgb(0,0,0,0)',
          },
          '&:hover $notchedOutline': {
            borderColor: 'rgb(0,0,0,0)',
          },
          '&$focused $notchedOutline': {
            borderColor: 'rgb(0,0,0,0)',
          },
        },
      },
      MuiButton: {
        root: {
          '@media (max-width:599px)': {
            minHeight: '48px',
            fontSize: '14px',
          },
        },
      },
      MuiIconButton: {
        root: {
          '@media (max-width:599px)': {
            minHeight: '48px',
            fontSize: '14px',
          },
        },
      },
      MuiSwitch: {
        switchBase: {
          '@media (max-width:599px)': {
            top: '-6px',
          },
        },
        colorSecondary: {
          '&$checked': {
            color: globalColors.accent2,
          },
        },
      },
      MuiLink: {
        root: {
          color: globalColors.link,
        },
      },
      MuiPaper: {
        elevation1: {
          boxShadow:
            '1px 1px 1px 0 rgba(130,130,130,0.2), 1px 1px 1px 1px rgba(130,130,130,0.2), -1px -1px 6px 1px rgba(130,130,130,0.1)',
        },
      },
      MuiBreadcrumbs: {
        li: {
          fontSize: '15px',
        },
      },
    },
  });

// Axiosのエラーハンドラを初期化
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (
      error?.response?.status &&
      error.response.status !== 409 &&
      error.response.status >= 400 &&
      error.response.status < 500
    ) {
      alert(error.response.data);
    }
    return Promise.reject(error);
  }
);

function App() {
  const root = (() => {
    return (
      <Router>
        <AutoUpdater />
        <ScrollToTop />
        <Switch>
          <Route path="/signin" component={SignInPage} exact />
          <Route path="/invitation" component={InvitationPage} exact />
          <Route
            path="/invitation/activation"
            component={InvitationActivationPage}
            exact
          />
          <Route path="/profile" component={UserProfilePage} exact />
          <Route
            path="/profile/change-email"
            component={ChangeEmailConfirmPage}
            exact
          />
          <Route path="/reminder" component={PasswordReminderPage} exact />
          <Auth>
            <Route
              path="/google/oauth/callback"
              component={ShopGoogleOauthCallbackPage}
              exact
            />
            <Route path="/" component={HomePage} exact />
            <Route
              path="/a/:workspaceUid/"
              component={WorkspaceDashboardPage}
              exact
            />
            <Route
              path="/a/:workspaceUid/shops/reports"
              component={WorkspaceShopReportsPage}
              exact
            />
            <Route
              path="/a/:workspaceUid/shops/new"
              component={ShopNewPage}
              exact
            />
            <Route
              path="/a/:workspaceUid/shops/:shopId(\d+)"
              component={ShopPage}
              exact
            />
            <Route
              path="/a/:workspaceUid/shops/:shopId(\d+)/reservations/new"
              component={ReservationEditPage}
              exact
            />
            <Route
              path="/a/:workspaceUid/shops/:shopId(\d+)/reservations/:reservationId(\d+)"
              component={ReservationEditPage}
              exact
            />

            <Route
              path="/a/:workspaceUid/shops/:shopId(\d+)/settings"
              component={ShopSlotSettingsPage}
              exact
            />
            <Route
              path="/a/:workspaceUid/shops/:shopId(\d+)/settings/slot-settings"
              component={ShopSlotSettingsPage}
              exact
            />
            <Route
              path="/a/:workspaceUid/shops/:shopId(\d+)/settings/integrations"
              component={ShopIntegrationsSettingPage}
              exact
            />
            <Route
              path="/a/:workspaceUid/shops/:shopId(\d+)/settings/integrations/new"
              component={ShopIntegrationsSettingEditPage}
              exact
            />
            <Route
              path="/a/:workspaceUid/shops/:shopId(\d+)/settings/integrations/:integrationId(\d+)"
              component={ShopIntegrationsSettingEditPage}
              exact
            />
            <Route
              path="/a/:workspaceUid/shops/:shopId(\d+)/settings/slot-settings/:slotSettingId(\d+)"
              component={ShopSlotSettingPage}
              exact
            />
            <Route
              path="/a/:workspaceUid/shops/:shopId(\d+)/settings/slot-settings/:slotSettingId(\d+)/rules/new"
              component={ShopSlotSettingRulePage}
              exact
            />
            <Route
              path="/a/:workspaceUid/shops/:shopId(\d+)/settings/slot-settings/:slotSettingId(\d+)/rules/:ruleId(\d+)"
              component={ShopSlotSettingRulePage}
              exact
            />

            <Route
              path="/a/:workspaceUid/shops/:shopId(\d+)/settings/courses"
              component={ShopCourseSettingPage}
              exact
            />
            <Route
              path="/a/:workspaceUid/shops/:shopId(\d+)/settings/courses/chooser"
              component={ShopCourseChooserPage}
              exact
            />
            <Route
              path="/a/:workspaceUid/shops/:shopId(\d+)/settings/courses/:courseId(\d+)/course-slot-setting"
              component={ShopCourseSlotSettingPage}
              exact
            />
            <Route
              path="/a/:workspaceUid/shops/:shopId(\d+)/settings/special-rule"
              component={ShopSpecialRuleSettingPage}
              exact
            />
            <Route
              path="/a/:workspaceUid/shops/:shopId(\d+)/settings/reservation"
              component={ShopReservationSettingPage}
              exact
            />
            {/* 旧UIでこのページをブックマークしていたユーザーのためにフォールバックとして残しておく */}
            <Route
              path="/a/:workspaceUid/shops/:shopId(\d+)/settings/resources"
              component={ShopResourceTableSettingPage}
              exact
            />
            <Route
              path="/a/:workspaceUid/shops/:shopId(\d+)/settings/resource-table"
              component={ShopResourceTableSettingPage}
              exact
            />
            <Route
              path="/a/:workspaceUid/shops/:shopId(\d+)/settings/resource-group"
              component={ShopResourceGroupSettingPage}
              exact
            />
            <Route
              path="/a/:workspaceUid/shops/:shopId(\d+)/settings/resource-list"
              component={ShopResourceListSettingPage}
              exact
            />
            <Route
              path="/a/:workspaceUid/shops/:shopId(\d+)/settings/resource-pattern"
              component={ShopResourcePatternSettingPage}
              exact
            />
            <Route
              path="/a/:workspaceUid/shops/:shopId(\d+)/settings/destinations"
              component={ShopNotifyDestinationSettingPage}
              exact
            />
            <Route
              path="/a/:workspaceUid/shops/:shopId(\d+)/settings/theme"
              component={ShopThemeSettingPage}
              exact
            />
            <Route
              path="/a/:workspaceUid/shops/:shopId(\d+)/settings/members"
              component={ShopMemberSettingPage}
              exact
            />
            <Route
              path="/a/:workspaceUid/shops/:shopId(\d+)/settings/basic"
              component={ShopBasicSettingPage}
              exact
            />
            <Route
              path="/a/:workspaceUid/shops/:shopId(\d+)/settings/custom-domain"
              component={ShopCustomDomainSettingPage}
              exact
            />
            <Route
              path="/a/:workspaceUid/shops/:shopId(\d+)/customers"
              component={CustomerListPage}
              exact
            />
            <Route
              path="/a/:workspaceUid/shops/:shopId(\d+)/customers/new"
              component={CustomerEditPage}
              exact
            />
            <Route
              path="/a/:workspaceUid/shops/:shopId(\d+)/customers/:customerId(\d+)"
              component={CustomerEditPage}
              exact
            />
            <Route
              path="/a/:workspaceUid/shops/:shopId(\d+)/insight"
              component={InsightPage}
              exact
            />
            <Route
              path="/a/:workspaceUid/shops/:shopId(\d+)/insight/inflow-source"
              component={InflowSourcePage}
              exact
            />
            <Route
              path="/a/:workspaceUid/shops/:shopId(\d+)/insight/course"
              component={CoursePage}
              exact
            />
            <Route
              path="/a/:workspaceUid/shops/:shopId(\d+)/insight/hourly"
              component={HourlyPage}
              exact
            />
            <Route
              path="/a/:workspaceUid/shops/:shopId(\d+)/insight/conversion"
              component={ConversionPage}
              exact
            />
            <Route
              path="/a/:workspaceUid/settings"
              component={WorkspaceCourseSettingPage}
              exact
            />
            <Route
              path="/a/:workspaceUid/settings/courses"
              component={WorkspaceCourseSettingPage}
              exact
            />
            <Route
              path="/a/:workspaceUid/settings/courses/new"
              component={WorkspaceCourseSettingEditPage}
              exact
            />
            <Route
              path="/a/:workspaceUid/settings/courses/:courseId(\d+)"
              component={WorkspaceCourseSettingEditPage}
              exact
            />
            <Route
              path="/a/:workspaceUid/settings/courses/:courseId(\d+)/form-setting"
              component={WorkspaceCourseFormSettingPage}
              exact
            />
            <Route
              path="/a/:workspaceUid/settings/inflow-sources"
              component={WorkspaceInflowSourceSettingPage}
              exact
            />
            <Route
              path="/a/:workspaceUid/settings/inflow-sources/new"
              component={WorkspaceInflowSourceSettingEditPage}
              exact
            />
            <Route
              path="/a/:workspaceUid/settings/inflow-sources/:inflowSourceId(\d+)"
              component={WorkspaceInflowSourceSettingEditPage}
              exact
            />
            <Route
              path="/a/:workspaceUid/settings/labels"
              component={WorkspaceLabelSettingPage}
              exact
            />
            <Route
              path="/a/:workspaceUid/settings/labels/new"
              component={WorkspaceLabelSettingEditPage}
              exact
            />
            <Route
              path="/a/:workspaceUid/settings/labels/:labelId(\d+)"
              component={WorkspaceLabelSettingEditPage}
              exact
            />
            <Route
              path="/a/:workspaceUid/settings/theme"
              component={WorkspaceThemeSettingPage}
              exact
            />
            <Route
              path="/a/:workspaceUid/settings/member"
              component={WorkspaceMemberSettingPage}
              exact
            />
            <Route
              path="/a/:workspaceUid/settings/line"
              component={WorkspaceLineSettingPage}
              exact
            />
            <Route
              path="/a/:workspaceUid/settings/line/messaging-channels/new"
              component={WorkspaceLineMessagingChannelSettingEditPage}
              exact
            />
            <Route
              path="/a/:workspaceUid/settings/line/messaging-channels/:channelId(\d+)"
              component={WorkspaceLineMessagingChannelSettingEditPage}
              exact
            />
            <Route
              path="/a/:workspaceUid/settings/line/login-channel"
              component={WorkspaceLineLoginChannelSettingEditPage}
              exact
            />
            <Route
              path="/a/:workspaceUid/settings/basic"
              component={WorkspaceBasicSettingPage}
              exact
            />
            <Route
              path="/a/:workspaceUid/settings/custom-domain"
              component={WorkspaceCustomDomainSettingPage}
              exact
            />
            <Route
              path="/a/:workspaceUid/settings/crm"
              component={WorkspaceCrmSettingPage}
              exact
            />
            <Route path="/workspaces/new" component={WorkspacesNewPage} exact />
          </Auth>
        </Switch>
      </Router>
    );
  })();

  return (
    <Provider>
      <RecoilRoot>
        <ThemeProvider theme={theme()}>
          <SnackbarProvider maxSnack={3}>
            <CssBaseline />
            <Global styles={globalStyles} />
            <StylesProvider injectFirst>{root}</StylesProvider>
          </SnackbarProvider>
        </ThemeProvider>
      </RecoilRoot>
    </Provider>
  );
}

export default App;
