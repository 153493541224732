import { AxiosResponse } from 'axios';
import { Resource } from '../../../@interfaces/resource/resource';
import { API_END_POINT } from '../../../api/api';
import { useMutation } from '../../common/api/use-mutation';

export const useUpdateResourceOrders = (): ((
  shopId: string,
  params: Resource[]
) => Promise<AxiosResponse<''>>) => {
  const { request } = useMutation<'', Resource[]>();
  return async (shopId, params) => {
    return request(
      'put',
      `${API_END_POINT}/app/shops/${shopId}/resource-orders`,
      {},
      params
    );
  };
};
