import { css } from '@emotion/react';

export const holiday = css({
  color: '#DE0040',
});

export const workday = css({
  color: '#222',
});

export const saturday = css({
  color: '#0063DA',
});

export type DayOfWeek = 0 | 1 | 2 | 3 | 4 | 5 | 6;

export const commonStyles = {
  dayOfWeek: {
    0: holiday,
    1: workday,
    2: workday,
    3: workday,
    4: workday,
    5: workday,
    6: saturday,
  },
};
