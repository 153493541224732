const BASE_URL = 'https://support.locaop.jp/hc/ja';

export const helps = {
  workspace: {
    shopAdd: 'articles/4411669989785',
    setting: {
      toc: 'sections/4410966515225',
      basic: 'articles/4411669962777',
      course: 'articles/4411669842841',
      inflowSource: 'articles/4410966515993',
      member: 'articles/4411661962393',
      themeAndTag: 'articles/4411661933593',
      label: 'articles/4411973163161',
      line: 'articles/4563241442841',
      crm: 'articles/32236950924057',
    },
  },
  shop: {
    top: 'articles/4411848910745',
    reservationEdit: 'articles/4411834174105',
    setting: {
      toc: 'sections/4410977957017',
      basic: 'articles/4411661837465',
      courseChoose: 'articles/4410969236121',
      course: 'articles/4411638874393',
      courseSlot: 'articles/4411653408153',
      member: 'articles/4411678059673',
      destination: 'articles/4411669751449',
      reservation: 'articles/4411678038809',
      specialRule: 'articles/4411669654553',
      themeAndTag: 'articles/4411661829145',
      integrations: 'articles/14938160165913',
    },
  },
  dashboard: {
    shops: 'articles/4411834070809',
    analitics: 'articles/4411850104985',
  },
  reservation: {
    setting: {
      toc: 'sections/4410969212313',
    },
  },
  customer: {
    list: 'articles/32236950924057',
    edit: 'articles/32236950924057',
  },
};

export const openHelpPage = (helpId: string | undefined) => {
  const url = helpId ? encodeURI(`${BASE_URL}/${helpId}`) : BASE_URL;
  window.open(url, '_blank');
};
