/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import dayjs from 'dayjs';
import { useState } from 'react';
import { FaLongArrowAltDown, FaLongArrowAltUp } from 'react-icons/fa';
import { DateAggregation } from '../../../@interfaces/insight/backend';
import {
  calcPercent,
  DisplayData,
  styles,
  UniqueKey,
} from '../utils/insight-utils';
import { ComparisonItemValue } from './ComparisonItemValue';
import { ComparisonItemValueWithArrow } from './ComparisonItemValueWithArrow';
import { ComparisonValue } from './ComparisonValue';
import { ComparisonValueWithArrow } from './ComparisonValueWithArrow';

type RowData = {
  key: string;
  label: string;
  primary: {
    total: number;
    cancelTotal: number;
    cancelRate: number;
  };
  comparison?: {
    total: number;
    cancelTotal: number;
    cancelRate: number;
  };
};

type ItemAggregationTableProps = {
  uniqueKeys: UniqueKey[];
  itemLabel: string;
  primary: {
    displayData: DisplayData[];
    results: DateAggregation[];
  };
  comparison?: {
    displayData: DisplayData[];
    results: DateAggregation[];
  };
  primaryStartDate: dayjs.Dayjs;
  primaryEndDate: dayjs.Dayjs;
  comparisonStartDate: dayjs.Dayjs;
  comparisonEndDate: dayjs.Dayjs;
};

type SortKey = 'label' | keyof RowData['primary'];

export const ItemAggregationTable = ({
  uniqueKeys,
  itemLabel,
  primary,
  comparison,
  primaryStartDate,
  primaryEndDate,
  comparisonStartDate,
  comparisonEndDate,
}: ItemAggregationTableProps): JSX.Element => {
  const { displayData, results } = primary;
  const [sortKey, setSortKey] = useState<SortKey>('total');
  const [ascending, setAscending] = useState(false);

  const rows: RowData[] = uniqueKeys.map((key) => {
    // 予約数
    const reservationTotal = displayData.reduce((prev, current) => {
      return prev + ((current[key.key] as number) || 0);
    }, 0);

    // キャンセル数
    const cancelTotal = results
      .filter((result) => result.aggregationId === key.key)
      .reduce((prev, current) => {
        return prev + current.cancelTotal;
      }, 0);

    // キャンセル率
    const allReservation = reservationTotal + cancelTotal;
    const cancelRate = (cancelTotal / allReservation) * 100;

    if (comparison === undefined) {
      return {
        key: key.key,
        label: key.label,
        primary: {
          total: reservationTotal,
          cancelTotal: cancelTotal,
          cancelRate: cancelRate,
        },
      };
    }

    // 比較対象の予約数
    const comparisonReservationTotal = comparison.displayData.reduce(
      (prev, current) => {
        return prev + (current[key.key] as number);
      },
      0
    );

    // 比較対象のキャンセル数
    const comparisonCancelTotal = comparison.results
      .filter((result) => result.aggregationId === key.key)
      .reduce((prev, current) => {
        return prev + current.cancelTotal;
      }, 0);

    // 比較対象のキャンセル率
    const allComparisonReservation =
      comparisonReservationTotal + comparisonCancelTotal;
    const comparisonCancelRate =
      (comparisonCancelTotal / allComparisonReservation) * 100;

    return {
      key: key.key,
      label: key.label,
      primary: {
        total: reservationTotal,
        cancelTotal: cancelTotal,
        cancelRate: cancelRate,
      },
      comparison: {
        total: comparisonReservationTotal,
        cancelTotal: comparisonCancelTotal,
        cancelRate: comparisonCancelRate,
      },
    };
  });

  const sortedRows = [...rows].sort((a: any, b: any) => {
    if (sortKey === 'label') {
      return ascending
        ? a.label.localeCompare(b.label)
        : b.label.localeCompare(a.label);
    } else {
      return ascending
        ? a.primary[sortKey] - b.primary[sortKey]
        : b.primary[sortKey] - a.primary[sortKey];
    }
  });

  // 予約数合計
  const allTotal = rows.reduce((prev, current) => {
    return prev + current.primary.total;
  }, 0);

  //比較対象の予約数合計
  const allComparisonTotal =
    comparison === undefined
      ? undefined
      : rows.reduce((prev, current) => {
          return prev + (current.comparison?.total ?? 0);
        }, 0);

  // キャンセル数合計
  const allCancelTotal = rows.reduce((prev, current) => {
    return prev + current.primary.cancelTotal;
  }, 0);

  // 比較対象のキャンセル数合計
  const allComparisonCancelTotal =
    comparison === undefined
      ? undefined
      : rows.reduce((prev, current) => {
          return prev + (current.comparison?.cancelTotal ?? 0);
        }, 0);

  // キャンセル率合計
  const allCancelRate = (allCancelTotal / allTotal) * 100;

  // 比較対象のキャンセル率合計
  const allComparisonCancelRate =
    allComparisonCancelTotal === undefined || allComparisonTotal === undefined
      ? undefined
      : (allComparisonCancelTotal / allComparisonTotal) * 100;

  const buildTotalRow = () => {
    // キャンセル率の矢印と値を表示
    const buildArrow = () => {
      if (allComparisonCancelRate === undefined) {
        return null;
      }
      const diffCancelRate = allCancelRate - allComparisonCancelRate;
      const arrowIcon = (() => {
        if (diffCancelRate > 0) {
          return (
            <FaLongArrowAltUp
              css={css`
                color: red;
              `}
            />
          );
        } else if (diffCancelRate < 0) {
          return (
            <FaLongArrowAltDown
              css={css`
                color: green;
              `}
            />
          );
        } else {
          return '-';
        }
      })();

      return (
        <div css={styles.rateArrowContainer}>
          {Number.isNaN(allCancelRate) ||
          Number.isNaN(allComparisonCancelRate) ? (
            `-`
          ) : (
            <>
              {arrowIcon}
              {diffCancelRate.toFixed(1).toLocaleString()}%
            </>
          )}
        </div>
      );
    };
    return (
      <TableRow
        css={css`
          background-color: #f5f5f5;
        `}
      >
        <TableCell
          scope="row"
          css={css`
            font-weight: bold;
          `}
        >
          <div>合計</div>
          {comparison !== undefined && (
            <>
              <div>{`${primaryStartDate.format(
                'YYYY/MM/DD'
              )}〜${primaryEndDate.format('YYYY/MM/DD')}`}</div>
              <div>{`${comparisonStartDate.format(
                'YYYY/MM/DD'
              )}〜${comparisonEndDate.format('YYYY/MM/DD')}`}</div>
              <div>推移(%)</div>
            </>
          )}
        </TableCell>
        <TableCell
          scope="row"
          align="center"
          css={css`
            font-weight: bold;
          `}
        >
          {comparison !== undefined && <br />}
          <div css={styles.tableNumberCell}>
            <span css={styles.tableItemValue}>{allTotal.toLocaleString()}</span>
            <span css={styles.tableItemPercent}>(100.0%)</span>
          </div>
          {allComparisonTotal !== undefined && (
            <ComparisonItemValueWithArrow
              primaryValue={allTotal}
              comparisonValue={allComparisonTotal}
            />
          )}
        </TableCell>
        <TableCell
          scope="row"
          align="center"
          css={css`
            font-weight: bold;
          `}
        >
          {comparison !== undefined && <br />}
          <div css={styles.tableNumberCell}>
            <span css={styles.tableItemValue}>
              {allCancelTotal.toLocaleString()}
            </span>
            <span css={styles.tableItemPercent}>(100.0%)</span>
          </div>
          {allComparisonCancelTotal !== undefined && (
            <ComparisonItemValueWithArrow
              primaryValue={allCancelTotal}
              comparisonValue={allComparisonCancelTotal}
              isColorReversed
            />
          )}
        </TableCell>
        <TableCell
          scope="row"
          align="center"
          css={css`
            font-weight: bold;
          `}
        >
          {comparison !== undefined && <br />}
          <div css={styles.tableNumberCell}>
            {Number.isNaN(allCancelRate)
              ? `-`
              : `${allCancelRate.toFixed(1).toLocaleString()}%`}
          </div>
          {allComparisonCancelRate !== undefined && (
            <>
              <div css={styles.tableNumberCell}>
                {Number.isNaN(allCancelRate) ||
                Number.isNaN(allComparisonCancelRate)
                  ? `-`
                  : `${allComparisonCancelRate.toFixed(1).toLocaleString()}%`}
              </div>
              <div css={styles.tableNumberCell}>{buildArrow()}</div>
            </>
          )}
        </TableCell>
      </TableRow>
    );
  };

  const buildTableRow = (row: RowData) => {
    return (
      <TableRow key={row.key}>
        <TableCell scope="row">{row.label}</TableCell>
        <TableCell scope="row" align="center">
          <div css={styles.tableNumberCell}>
            <span css={styles.tableItemValue}>
              {row.primary.total.toLocaleString()}
            </span>
            <span css={styles.tableItemPercent}>
              ({calcPercent(row.primary.total, allTotal).displayValue})
            </span>
          </div>
          {row.comparison !== undefined && (
            <ComparisonItemValue
              primaryValue={row.primary.total}
              comparisonValue={row.comparison.total}
              comparisonTotal={allComparisonTotal}
            />
          )}
        </TableCell>
        <TableCell scope="row" align="center">
          <div css={styles.tableNumberCell}>
            <span css={styles.tableItemValue}>
              {row.primary.cancelTotal.toLocaleString()}
            </span>
            <span css={styles.tableItemPercent}>
              (
              {
                calcPercent(row.primary.cancelTotal, allCancelTotal)
                  .displayValue
              }
              )
            </span>
          </div>
          {row.comparison !== undefined && (
            <ComparisonItemValue
              primaryValue={row.primary.cancelTotal}
              comparisonValue={row.comparison.cancelTotal}
              comparisonTotal={allComparisonCancelTotal}
            />
          )}
        </TableCell>
        <TableCell scope="row" align="center">
          <div css={styles.tableNumberCell}>
            {Number.isNaN(row.primary.cancelRate)
              ? `-`
              : `${row.primary.cancelRate.toFixed(1).toLocaleString()}%`}
          </div>
          {row.comparison !== undefined && (
            <>
              <div css={styles.tableNumberCell}>
                {Number.isNaN(row.comparison.cancelRate)
                  ? `-`
                  : `${row.comparison.cancelRate.toFixed(1).toLocaleString()}%`}
              </div>
              <div css={styles.tableNumberCell}>
                {Number.isNaN(row.primary.cancelRate) ||
                Number.isNaN(row.comparison.cancelRate)
                  ? `-`
                  : `${(row.primary.cancelRate - row.comparison.cancelRate)
                      .toFixed(1)
                      .toLocaleString()}%`}
              </div>
            </>
          )}
        </TableCell>
      </TableRow>
    );
  };

  const sortableTableHeaderCell = (
    key: SortKey,
    label: string
  ): JSX.Element => {
    const buildSortIcon = () => {
      if (sortKey === key && ascending) {
        return (
          <FaLongArrowAltUp
            css={css`
              vertical-align: middle;
            `}
          />
        );
      } else if (sortKey === key && !ascending) {
        return (
          <FaLongArrowAltDown
            css={css`
              vertical-align: middle;
            `}
          />
        );
      } else {
        return null;
      }
    };
    return (
      <TableCell
        css={[
          styles.tableHeader(4),
          styles.clickableTableHeader,
          sortKey === key
            ? css`
                font-weight: bold;
              `
            : null,
        ]}
        align="center"
        onClick={() => {
          setSortKey(key);
          setAscending(sortKey === key ? !ascending : false);
        }}
      >
        {label}
        {buildSortIcon()}
      </TableCell>
    );
  };

  return (
    <TableContainer
      css={css`
        margin-top: 3em;
      `}
      component={Paper}
    >
      <Table size="small" aria-label="a dense table">
        <TableHead
          css={css`
            background-color: black;
          `}
        >
          <TableRow>
            {sortableTableHeaderCell('label', itemLabel)}
            {sortableTableHeaderCell('total', '予約数')}
            {sortableTableHeaderCell('cancelTotal', 'キャンセル数')}
            {sortableTableHeaderCell('cancelRate', 'キャンセル率')}
          </TableRow>
        </TableHead>
        <TableBody>
          {buildTotalRow()}
          {sortedRows.map((row) => buildTableRow(row))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
