export const DEFAULT_THEME_COLOR = '#23537e';
export const THEME_COLORS = [
  '#23537e',
  '#f2446d',
  '#ffd25c',
  '#98ce00',
  '#5f52a0',
  '#54b9c7',
  '#f28300',
  '#7e4514',
  '#ce0000',
  '#ffffff',
  '#4D4D4D',
  '#999999',
  '#F44E3B',
  '#FE9200',
  '#FCDC00',
  '#DBDF00',
  '#A4DD00',
  '#68CCCA',
  '#73D8FF',
  '#AEA1FF',
  '#FDA1FF',
  '#333333',
  '#808080',
  '#cccccc',
  '#D33115',
  '#E27300',
  '#FCC400',
  '#B0BC00',
  '#68BC00',
  '#16A5A5',
  '#009CE0',
  '#7B64FF',
  '#FA28FF',
  '#000000',
  '#666666',
  '#B3B3B3',
  '#9F0500',
  '#C45100',
  '#FB9E00',
  '#808900',
  '#194D33',
  '#0C797D',
  '#0062B1',
  '#653294',
  '#AB149E',
];

export const LIGHT_COLORS = [
  '#ffebee',
  '#fce4ec',
  '#f3e5f5',
  '#e8eaf6',
  '#e3f2fd',
  '#e8f5e9',
  '#f9fbe7',
  '#fffde7',
  '#fff3e0',
  '#efebe9',
  '#eceff1',
  '#fafafa',

  '#ffcdd2',
  '#f8bbd0',
  '#e1bee7',
  '#c5cae9',
  '#bbdefb',
  '#c8e6c9',
  '#f0f4c3',
  '#fff9c4',
  '#ffe0b2',
  '#d7ccc8',
  '#cfd8dc',
  '#f5f5f5',

  '#ef9a9a',
  '#f48fb1',
  '#ce93d8',
  '#9fa8da',
  '#90caf9',
  '#a5d6a7',
  '#e6ee9c',
  '#fff59d',
  '#ffcc80',
  '#bcaaa4',
  '#b0bec5',
  '#eeeeee',

  '#e57373',
  '#f06292',
  '#ba68c8',
  '#7986cb',
  '#64b5f6',
  '#81c784',
  '#dce775',
  '#fff176',
  '#ffb74d',
  '#a1887f',
  '#90a4ae',
  '#e0e0e0',
];
export const LOGO_BASE_URL = process.env.REACT_APP_UPLOAD_IMAGE_URL_BASE ?? '';
