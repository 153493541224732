/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import dayjs from 'dayjs';

export const DateRangePreview = ({
  primaryStartDate,
  primaryEndDate,
  comparisonStartDate,
  comparisonEndDate,
  showComparison,
}: {
  primaryStartDate: dayjs.Dayjs;
  primaryEndDate: dayjs.Dayjs;
  comparisonStartDate: dayjs.Dayjs;
  comparisonEndDate: dayjs.Dayjs;
  showComparison: boolean;
}): JSX.Element => {
  return (
    <div
      css={css`
        display: inline-block;
        padding: 1em;
        background-color: #fff;
        line-height: 1;
        text-align: right;
        height: 64px;
      `}
    >
      <p
        css={css`
          font-weight: 700;
          font-size: 1.1em;
          margin: 0;
        `}
      >
        選択中の期間: {primaryStartDate.format('YYYY-MM-DD')}〜
        {primaryEndDate.format('YYYY-MM-DD')}
      </p>
      {showComparison && (
        <p
          css={css`
            margin: 0.5em 0 0;
          `}
        >
          前の期間: {comparisonStartDate.format('YYYY-MM-DD')}〜
          {comparisonEndDate.format('YYYY-MM-DD')}
        </p>
      )}
    </div>
  );
};
