import axios from 'axios';
import { useContext } from 'react';
import { Store } from '../context/GlobalStore';
import { API_END_POINT } from './api';
import useSWR from 'swr';

const buildUrl = (shopId: string) => {
  return `${API_END_POINT}/app/shops/${shopId}/setting`;
};

const fetcher = async (token: string, shopId: string) => {
  const response = await axios.get(buildUrl(shopId), {
    headers: {
      Authorization: token,
    },
  });
  return response.data;
};

export default function useShopSetting(shopId: string) {
  const { globalState } = useContext(Store);
  const { data, isLoading, mutate } = useSWR(
    globalState.session?.idToken ? [globalState.session.idToken, shopId] : null,
    async ([token, shopId]) => fetcher(token, shopId)
  );

  const reload = () => {
    void mutate([buildUrl(shopId), globalState.session?.idToken]);
  };

  return {
    shopSetting: data?.shopSetting,
    isLoadingShopSetting: isLoading,
    reloadShopSetting: reload,
  };
}
