import { useMemo, useState } from 'react';
import { SelectedCustomer } from '../../../../@interfaces/customer';
import {
  FormResponse,
  FormSetting,
} from '../../../../core/types/reservation-form-types';
import { NotificationInfo } from './NotificationInfo';
import { NotificationForm, NotificationSetting } from './types';

/**
 * 通知設定の状態管理とコンポーネントの生成を行うカスタムフック
 */
export const useNotificationInfo = (
  shopId: number,
  formSetting: FormSetting,
  formResponse: FormResponse,
  selectedCustomer: SelectedCustomer | undefined
) => {
  const [notificationForm, setNotificationForm] = useState<NotificationForm>({
    formEmail: false,
    customerEmail: false,
    lineMessagingChannelKey: undefined,
  });

  const notificationInfo = useMemo(() => {
    return (
      <NotificationInfo
        shopId={shopId}
        formSetting={formSetting}
        formResponse={formResponse}
        selectedCustomer={selectedCustomer}
        notificationForm={notificationForm}
        onUpdateNotificationForm={setNotificationForm}
      />
    );
  }, [formResponse, formSetting, notificationForm, selectedCustomer, shopId]);

  // 通知のフォームから通知設定を生成
  const notificationSetting = useMemo(() => {
    const emailAddresses = [];
    if (notificationForm.formEmail) {
      const emailField = formSetting.fields.find(
        (field) => field.type === 'email'
      );
      const formEmailAddress = emailField
        ? (formResponse.fields.find((field) => field.uid === emailField.uid)
            ?.values[0] as string)
        : undefined;
      if (formEmailAddress) {
        emailAddresses.push(formEmailAddress);
      }
    }
    if (notificationForm.customerEmail && selectedCustomer?.email) {
      emailAddresses.push(selectedCustomer.email);
    }
    const notificationSetting: NotificationSetting = {
      emailAddresses,
      lineMessagingChannelKey: notificationForm.lineMessagingChannelKey,
    };
    return notificationSetting;
  }, [formResponse, formSetting, selectedCustomer, notificationForm]);

  return {
    notificationSetting,
    notificationInfo,
  };
};
