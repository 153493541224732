/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import {
  Box,
  Breadcrumbs,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Select,
  Tab,
  Tabs,
  TextField,
  useTheme,
} from '@material-ui/core';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import DeleteIcon from '@material-ui/icons/Delete';
import { Alert } from '@material-ui/lab';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import React, { ChangeEvent, useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import useReactRouter from 'use-react-router';
import { API_END_POINT } from '../../../api/api';
import useShop from '../../../api/use-shop';
import useSlotSetting from '../../../api/use-slot-setting';
import useSlotSettingRule from '../../../api/use-slot-setting-rule';
import {
  BreadcrumbLinkItem,
  BreadcrumbShopItem,
  BreadcrumbTextItem,
  BreadcrumbWorkspaceItem,
} from '../../../components/breadcrumb/BreadcrumbItem';
import { commonCss } from '../../../components/common-css';
import { ShopPageLayout } from '../../../components/layouts/ShopPageLayout';
import { PageTitleAndDescription } from '../../../components/PageTitleAndDescription';
import {
  FullscreenLoading,
  Head,
  Main,
  Root,
} from '../../../components/Shared';
import Spinner from '../../../components/Spinner';
import { useStyles } from '../../../components/Styles';
import { a11yProps, TabPanel } from '../../../components/TabPanel';
import TimeTextField, {
  TimeNumberField,
} from '../../../components/TimeTextField';
import { Store } from '../../../context/GlobalStore';
import { getSlotsByTimeRanges } from '../../../core/services/reservation-table-service';
import {
  createDate,
  createTime,
  CustomDayOfWeek,
  CustomWeeklyCondition,
  DateFormat,
  DayOfWeek,
  isTimeFormat,
  MonthlyCondition,
  normalizeRule,
  RegularRule,
  Schedule,
  ScheduleCondition,
  SlotCapacity,
  SlotRule,
  Time,
  TimeFormat,
  TimeRange,
  TimeRangeCapacity,
  toDateStringByDate,
  toDayOfWeeksLabelDayOfWeek,
  toTimeByTimeValue,
  toTimeStringByTime,
  toTimeValue,
  WeeklyCondition,
  zeroPadding,
} from '../../../core/types/reservation-types';
import {
  validateDateRange,
  validateRule,
  ValidationError,
} from '../../../core/types/reservation-types-validation';
import { useReservationTablePreview } from '../../../features/preview/hooks/useReservationTablePreview';
import { useQuery } from '../../../hooks/use-query';
import { SM_BREAKPOINT } from '../../../hooks/use-size-type';
import { globalColors } from '../../../styles/globalColors';
import { groupBy } from '../../../utils/arrays';
import { helps } from '../../../utils/helps';
import { range } from '../../../utils/numbers';
import {
  isAfterTimeRange,
  isBeforeTimeRange,
  isBetweenTimeRange,
  isSameTimeRange,
} from '../../../utils/time';

const styles = {
  error: css`
    color: red;
  `,
  reserveSettingWrapper: css`
    display: flex;
    @media screen and (max-width: ${SM_BREAKPOINT}px) {
      display: block;
      margin-top: 16px;
      &:first-of-type {
        margin-top: 0;
      }
    }
  `,
  reserveSetting: css`
    display: flex;
    justify-content: flex-start;
    align-items: center;
  `,
  reserveDayOfWeek: css`
    @media screen and (max-width: 500px) {
      padding: 4px;
    }
  `,
  monthlyTableWrapper: css`
    width: calc(100vw - 70px);
    overflow: scroll;
  `,
  dateLabel: css`
    margin-right: 8px;
  `,
  timeContainer: css`
    margin-top: 16px;
    font-size: 12px;
    font-weight: bold;
  `,
  timesTableTimeRange: css`
    font-size: 16px;
    line-height: 16px;
  `,
  capacityZeroContainer: css`
    color: #b0b0b0;
    font-weight: normal;
    input {
      color: #b0b0b0;
    }
  `,
};

export const UNITS = [
  -1, 5, 10, 15, 20, 30, 40, 45, 50, 60, 90, 120, 150, 180, 210, 240, 270, 300,
  330, 360, 390, 420, 450, 480,
];
const DAY_OF_WEEKS: DayOfWeek[] = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
];
const DAY_OF_WEEK_LABELS: string[] = DAY_OF_WEEKS.map(
  toDayOfWeeksLabelDayOfWeek
);
const DATES = [range(1, 10), range(11, 20), range(21, 30), range(31, 31)];

const addTimeValue = (time: Time, timeValue: number) => {
  const baseValue = toTimeValue(time);
  const newValue = baseValue + timeValue;
  return toTimeByTimeValue(newValue);
};

const createNewTimeRange = (
  baseUnit: number,
  baseSchedule?: Schedule
): TimeRangeCapacity => {
  const baseTimeRange =
    baseSchedule?.timeRanges[baseSchedule.timeRanges.length - 1];
  // ベースになる時間帯があれば1枠分間隔を空けた時間帯を新しい時間帯の開始時間とする
  const start: Time = baseTimeRange
    ? addTimeValue(baseTimeRange.end, baseUnit)
    : {
        hour: 12,
        minute: 0,
      };
  const end: Time = addTimeValue(start, baseUnit);
  const capacity = baseTimeRange?.capacity || {
    total: 1,
  };
  const newTimeRange: TimeRangeCapacity = {
    start,
    end,
    capacity,
  };
  return newTimeRange;
};

const createNewSchedule = (baseUnit: number, baseSchedule?: Schedule) => {
  const condition: ScheduleCondition = baseSchedule
    ? { ...baseSchedule.condition }
    : ({ type: 'weekly', dayOfWeeks: [] } as WeeklyCondition);
  const newSchedule: Schedule = {
    condition,
    capacity: { total: 1 },
    timeRanges: [createNewTimeRange(baseUnit, baseSchedule)],
  };
  return newSchedule;
};

interface PageParams {
  workspaceUid: string;
  shopId: string;
  slotSettingId: string;
  ruleId?: string;
}

const defaultRule: RegularRule = {
  type: 'regular',
  dateRange: {
    start: undefined,
    end: undefined,
  },
  unit: 60,
  schedules: [createNewSchedule(60)],
};

type Form = {
  rule: SlotRule;
};

export default function ShopSlotSettingRulePage(props: any) {
  const { globalState, setGlobalState } = useContext(Store);
  const { history, match } = useReactRouter<PageParams>();
  const { workspaceUid, shopId, slotSettingId, ruleId } = match.params;
  const query = useQuery();
  const fromId = query.get('fromId');
  const validationContext = useForm();
  const { handleSubmit } = validationContext;
  const { preview, previewOpen, updatePreview } = useReservationTablePreview();

  const { shop, isLoadingShop } = useShop(shopId);
  const { slotSettingRule, isLoadingSlotSttingRule } = useSlotSettingRule(
    shopId,
    slotSettingId,
    ruleId || fromId || undefined
  );

  const {
    slotSetting,
    slotSettingRules: otherSlotSettingRules,
    isLoadingSlotSetting,
  } = useSlotSetting(shopId, slotSettingId);
  const isNewWithDefault = !ruleId && !fromId;
  const isNewWithCopy = !ruleId && fromId;
  const isEdit = ruleId != undefined;

  const [form, setForm] = useState<Form>({
    rule: defaultRule,
  });
  const [errors, setErrors] = useState<ValidationError[]>([]);

  useEffect(() => {
    const errors = validateRule(form.rule, 0);
    const dateRangeErrors = validateDateRange(
      form.rule.dateRange,
      otherSlotSettingRules
        .filter((os) => os.id !== parseInt(ruleId || '-1'))
        .map((r) => ({
          start: r.startDate && createDate(r.startDate),
          end: r.endDate && createDate(r.endDate),
        }))
    );
    setErrors([...errors, ...dateRangeErrors]);
    updatePreview([form.rule]);
  }, [JSON.stringify(form.rule), updatePreview]);

  const [openBackdrop, setOpenBackdrop] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (isNewWithDefault) {
      // already set default value
    } else if (isNewWithCopy && slotSettingRule) {
      setForm({ ...form, rule: slotSettingRule.rule });
    } else if (isEdit && slotSettingRule) {
      setForm({ ...form, rule: slotSettingRule.rule });
    }
  }, [JSON.stringify(slotSettingRule)]);

  const update = () => {
    const rule = form.rule;
    normalizeRule(rule);
    const json = {
      rule,
    };
    setOpenBackdrop(true);
    axios
      .put(
        `${API_END_POINT}/app/shops/${shopId}/slot-settings/${slotSettingId}/rules/${ruleId}`,
        json,
        {
          headers: {
            Authorization: globalState.session?.idToken,
          },
        }
      )
      .then(() => {
        enqueueSnackbar('設定を保存しました。', { variant: 'success' });
        history.push(
          `/a/${workspaceUid}/shops/${shopId}/settings/slot-settings/${slotSettingId}`
        );
      })
      .catch((e) => {
        enqueueSnackbar('設定が保存できませんでした。', { variant: 'error' });
      })
      .finally(() => {
        setOpenBackdrop(false);
      });
  };

  const create = () => {
    const json = {
      rule: form.rule,
    };
    setOpenBackdrop(true);
    axios
      .post(
        `${API_END_POINT}/app/shops/${shopId}/slot-settings/${slotSettingId}/rules`,
        json,
        {
          headers: {
            Authorization: globalState.session?.idToken,
          },
        }
      )
      .then(() => {
        enqueueSnackbar('設定を保存しました。', { variant: 'success' });
        history.push(
          `/a/${workspaceUid}/shops/${shopId}/settings/slot-settings/${slotSettingId}`
        );
      })
      .catch((e) => {
        enqueueSnackbar('設定が保存できませんでした。', { variant: 'error' });
      })
      .finally(() => {
        setOpenBackdrop(false);
      });
  };

  const remove = () => {
    setOpenBackdrop(true);
    axios
      .delete(
        `${API_END_POINT}/app/shops/${shopId}/slot-settings/${slotSettingId}/rules/${ruleId}`,
        {
          headers: {
            Authorization: globalState.session?.idToken,
          },
        }
      )
      .then(() => {
        enqueueSnackbar('設定を削除しました。', { variant: 'success' });
        history.push(
          `/a/${workspaceUid}/shops/${shopId}/settings/slot-settings/${slotSettingId}`
        );
      })
      .catch((e) => {
        enqueueSnackbar('設定が削除できませんでした。', { variant: 'error' });
      })
      .finally(() => {
        setOpenBackdrop(false);
      });
  };

  const validate = () => {
    const { rule } = form;
    if (rule.type == 'regular') {
      const conditions = rule.schedules.map((s) => s.condition);
      const invalidDayOfWeeks = groupBy(
        conditions.flatMap((c) => {
          if (c.type == 'weekly') {
            return c.dayOfWeeks;
          } else {
            return [];
          }
        }),
        (d) => d
      )
        .filter(([dayOfWeek, dayOfWeeks]) => dayOfWeeks.length > 1)
        .map(([dayOfWeek, dayOfWeeks]) => dayOfWeek);
      if (invalidDayOfWeeks.length > 0) {
        alert(
          `曜日の設定が重複しています(${invalidDayOfWeeks
            .map(toDayOfWeeksLabelDayOfWeek)
            .join(',')})`
        );
        return false;
      }

      const invalidDates = groupBy(
        conditions.flatMap((c) => {
          if (c.type == 'monthly') {
            return c.dates;
          } else {
            return [];
          }
        }),
        (d) => d
      )
        .filter(([date, dates]) => dates.length > 1)
        .map(([date, dates]) => date);
      if (invalidDates.length > 0) {
        alert(`月の日付の設定が重複しています(${invalidDates.join(',')})`);
        return false;
      }

      for (const schedule of rule.schedules) {
        const slots = schedule.timeRanges.flatMap((t) =>
          getSlotsByTimeRanges([t], rule.unit, schedule.capacity)
        );
        const invalidStartTimes = groupBy(slots, (slot) =>
          toTimeStringByTime(slot.slot.timeRange.start)
        )
          .filter(([startTime, slots]) => slots.length > 1)
          .map(([startTime, slots]) => startTime);
        if (invalidStartTimes.length > 0) {
          alert(`時間の設定が重複しています(${invalidStartTimes.join(',')})`);
          return false;
        }
      }
    }
    return true;
  };

  const handleSubmitForm = (e: React.FormEvent) => {
    if (!validate()) {
      return;
    }
    if (isEdit) {
      update();
    } else {
      create();
    }
  };

  const handleClickDelete = () => {
    if (
      prompt('この設定を削除するには「削除」と入力してください。') === '削除'
    ) {
      remove();
    }
  };

  const handleClickCancel = () => {
    history.push(
      `/a/${workspaceUid}/shops/${shopId}/settings/slot-settings/${slotSettingId}`
    );
  };

  const handleChangeRule = (rule: SlotRule) => {
    setForm({ ...form, rule });
  };

  const buildEditor = () => {
    if (!slotSettingRule && (isEdit || isNewWithCopy)) {
      return <div>設定が見つかりませんでした。</div>;
    }
    if (form.rule.type == 'regular') {
      return (
        <RegularRuleEditor
          shopId={shop.id}
          rule={form.rule}
          errors={errors}
          workspaceUid={workspaceUid}
          onChangeRule={handleChangeRule}
        />
      );
    } else {
      return null;
    }
  };

  const buildRightNavigationButtons = () => {
    return (
      <>
        <Button
          css={commonCss.button.right}
          type="button"
          variant="text"
          color="primary"
          onClick={handleClickCancel}
        >
          キャンセル
        </Button>
        <Button
          css={commonCss.button.right}
          type="submit"
          variant="contained"
          color="primary"
          disabled={errors.length > 0}
        >
          {isEdit ? '保存' : '追加'}
        </Button>
      </>
    );
  };

  const buildContents = () => {
    if (isLoadingShop || isLoadingSlotSetting || isLoadingSlotSttingRule) {
      return <Spinner loading={true} />;
    }
    return (
      <>
        <form
          onSubmit={handleSubmit(handleSubmitForm)}
          css={
            previewOpen
              ? css`
                  margin-right: 480px;
                `
              : null
          }
        >
          <Grid container>
            <Grid item xs={12} sm={6}>
              <h3>
                {slotSetting?.courses.map((c) => c.name).join('/')}
                の予約枠設定の
                {isEdit ? '更新' : '新規追加'}
              </h3>
            </Grid>
            <Grid
              item
              container
              xs={12}
              sm={6}
              alignItems="center"
              justifyContent="flex-end"
            >
              {buildRightNavigationButtons()}
            </Grid>
          </Grid>
          {buildEditor()}
          <Grid
            container
            css={css`
              margin-top: 20px;
            `}
            justifyContent="space-between"
            alignItems="center"
          >
            <div>
              {isEdit ? (
                <Button
                  type="button"
                  variant="contained"
                  color="default"
                  onClick={handleClickDelete}
                >
                  &nbsp;削除&nbsp;
                </Button>
              ) : null}
            </div>
            <div>{buildRightNavigationButtons()}</div>
          </Grid>
          {/* <h3>Debug</h3>
        <Paper>
          <pre>
            {JSON.stringify(form.rule, null, 2)}
          </pre>
        </Paper> */}
          {preview}
        </form>
      </>
    );
  };

  const pageTitle = isEdit ? '予約枠設定の編集' : '予約枠設定の追加';
  const description = `コースごとの予約受付可能枠の設定の${
    isEdit ? '編集' : '追加'
  }を行います。`;

  return (
    <Root>
      <Head
        title={`店舗設定 - ${shop?.name || ''} ${slotSetting?.courses
          .map((c) => c.name)
          .join('/')}`}
      />
      <Main>
        <ShopPageLayout
          workspaceUid={workspaceUid}
          shopId={shopId}
          current="slot-basic"
          helpId={helps.shop.setting.courseSlot}
          breadcrumbs={
            <Breadcrumbs aria-label="breadcrumb">
              <BreadcrumbLinkItem to={`/`}>ホーム</BreadcrumbLinkItem>
              <BreadcrumbWorkspaceItem workspaceUid={workspaceUid} />
              <BreadcrumbShopItem workspaceUid={workspaceUid} shop={shop} />
              <BreadcrumbLinkItem
                to={`/a/${workspaceUid}/shops/${shopId}/settings/slot-settings`}
              >
                予約枠基本設定
              </BreadcrumbLinkItem>
              <BreadcrumbLinkItem
                to={`/a/${workspaceUid}/shops/${shopId}/settings/slot-settings/${slotSettingId}`}
              >
                コースの予約枠
              </BreadcrumbLinkItem>
              <BreadcrumbTextItem>{pageTitle}</BreadcrumbTextItem>
            </Breadcrumbs>
          }
        >
          <PageTitleAndDescription
            title="店舗"
            subTitle={pageTitle}
            description={description}
            themeColor={globalColors.shop}
          />
          {buildContents()}
        </ShopPageLayout>
      </Main>
      <FullscreenLoading open={openBackdrop} />
    </Root>
  );
}

function RegularRuleEditor(props: {
  shopId: number;
  rule: RegularRule;
  errors: ValidationError[];
  workspaceUid: string;
  onChangeRule: (rule: SlotRule) => void;
}) {
  const { shopId, rule, errors, workspaceUid, onChangeRule } = props;
  const styles = useStyles();
  const [customUnit, setCustomUnit] = useState(
    UNITS.includes(rule.unit) === false
  );

  const handleChangeDateRangeStart = (e: ChangeEvent<HTMLInputElement>) => {
    const date = e.target.value;
    const start = date ? createDate(date as DateFormat) : undefined;
    const dateRange = { ...rule.dateRange, start };
    onChangeRule({ ...rule, dateRange });
  };

  const handleChangeDateRangeEnd = (e: ChangeEvent<HTMLInputElement>) => {
    const date = e.target.value;
    const end = date ? createDate(date as DateFormat) : undefined;
    const dateRange = { ...rule.dateRange, end };
    onChangeRule({ ...rule, dateRange });
  };

  const handleChangeUnit = (e: React.ChangeEvent<{ value: unknown }>) => {
    const value = e.target.value;
    if (value === -1) {
      setCustomUnit(true);
      return;
    }
    const unit =
      typeof value === 'string' ? parseInt(value) : (value as number);
    onChangeRule({ ...rule, unit });
  };

  const handleChangeCustomUnit = (value: string) => {
    if (value === '' || parseInt(value) <= 0) {
      onChangeRule({ ...rule, unit: 1 });
      return;
    }
    const unit = parseInt(value);
    onChangeRule({ ...rule, unit });
  };

  const handleClickAddSchedule = () => {
    const baseSchedule = rule.schedules[rule.schedules.length - 1];
    const newSchedule = createNewSchedule(rule.unit, baseSchedule);
    const schedules = [...rule.schedules, newSchedule];
    onChangeRule({ ...rule, schedules });
  };

  return (
    <>
      <Paper className={styles.paper}>
        <div>
          設定の対象期間:
          <br css={commonCss.forSP} />
          <input
            type="date"
            defaultValue={
              rule.dateRange.start
                ? toDateStringByDate(rule.dateRange.start)
                : ''
            }
            onChange={handleChangeDateRangeStart}
          />
          〜
          <input
            type="date"
            defaultValue={
              rule.dateRange.end ? toDateStringByDate(rule.dateRange.end) : ''
            }
            onChange={handleChangeDateRangeEnd}
          />
        </div>
        <ErrorMessage errors={errors} path="rules/0/dateRange" />
        <div>
          <div
            css={css`
              display: flex;
              align-items: center;
            `}
          >
            {customUnit ? (
              <TimeNumberField
                value={String(rule.unit)}
                min={0}
                max={1000}
                disabled={false}
                onChange={handleChangeCustomUnit}
              />
            ) : (
              <Select value={rule.unit} onChange={handleChangeUnit}>
                {UNITS.map((unit) => (
                  <MenuItem key={unit} value={unit}>
                    {unit !== -1 ? `${unit}` : 'カスタム設定...'}
                  </MenuItem>
                ))}
              </Select>
            )}
            <div>分ごとに予約枠を設定する</div>
          </div>
          <div>
            (コースごとの所要時間の設定は
            <Link to={`/a/${workspaceUid}/shops/${shopId}/settings/courses`}>
              コース設定
            </Link>
            からおこなえます)
          </div>
        </div>
      </Paper>
      <div>
        <Alert severity="info">
          時間設定を複数設定した場合、後の設定が優先度が高くなります。
          <br />
          例: 時間設定1が毎週日曜日に1枠、時間設定2が毎月5, 15,
          15日に2枠を設定した場合、日曜日かつ5の付く日は後の設定の2枠が適用されます。
        </Alert>
        <ErrorMessage errors={errors} path="rules/0/schedules" />
        {rule.schedules.map((schedule, index) => {
          return (
            <SchduleEditor
              key={index}
              errors={errors}
              rule={rule}
              index={index}
              schedule={schedule}
              onChangeRule={onChangeRule}
            />
          );
        })}
        <div>
          <Button
            variant="contained"
            color="default"
            onClick={handleClickAddSchedule}
          >
            時間設定を追加
          </Button>
        </div>
      </div>
    </>
  );
}

function SchduleEditor(props: {
  rule: RegularRule;
  errors: ValidationError[];
  schedule: Schedule;
  index: number;
  onChangeRule: (rule: SlotRule) => void;
}) {
  const { rule, errors, schedule, index, onChangeRule } = props;
  const { capacity, condition, timeRanges } = schedule;
  const styles = useStyles();

  const setSchedules = (newSchedules: Schedule[]) => {
    onChangeRule({ ...rule, schedules: newSchedules });
  };

  const setSchedule = (newSchedule: Schedule) => {
    const newSchedules = [...rule.schedules];
    newSchedules[index] = newSchedule;
    setSchedules(newSchedules);
  };

  const handleChangeCapacityTotal = (e: ChangeEvent<HTMLInputElement>) => {
    let value;
    try {
      value = parseInt(e.target.value);
    } catch (e) {
      return;
    }
    if (value < 0) {
      return;
    }
    const newCapacity = {
      ...capacity,
      total: value,
    };
    setSchedule({
      ...schedule,
      capacity: newCapacity,
    });
  };

  const handleChangeScheduleType = (
    e: React.ChangeEvent<{ value: unknown }>
  ) => {
    const type = e.target.value as 'weekly' | 'monthly' | 'custom-weekly';
    if (type == 'weekly') {
      const newCondition: WeeklyCondition = {
        type: 'weekly',
        dayOfWeeks: [],
      };
      setSchedule({
        ...schedule,
        condition: newCondition,
      });
    } else if (type == 'monthly') {
      const newCondition: MonthlyCondition = {
        type: 'monthly',
        dates: [],
      };
      setSchedule({
        ...schedule,
        condition: newCondition,
      });
    } else if (type == 'custom-weekly') {
      const newCondition: CustomWeeklyCondition = {
        type: 'custom-weekly',
        dayOfWeeks: [{ dayOfWeek: 'Monday', target: 1 }],
      };
      setSchedule({
        ...schedule,
        condition: newCondition,
      });
    }
  };

  const buildScheduleCondition = () => {
    if (condition.type == 'weekly') {
      return (
        <WeeklyScheduleConditionEditor
          errors={errors}
          schedule={schedule}
          rule={rule}
          condition={condition}
          onChangeSchedule={setSchedule}
        />
      );
    } else if (condition.type == 'monthly') {
      return (
        <MonthlyScheduleConditionEditor
          errors={errors}
          schedule={schedule}
          rule={rule}
          condition={condition}
          onChangeSchedule={setSchedule}
        />
      );
    } else if (condition.type == 'custom-weekly') {
      return (
        <CustomWeeklyScheduleConditionEditors
          errors={errors}
          schedule={schedule}
          condition={condition}
          onChangeSchedule={setSchedule}
        />
      );
    } else {
      return <div>未知の条件種別です。</div>;
    }
  };

  const handleClickDelete = () => {
    const newSchedules = rule.schedules.filter((s, i) => i != index);
    setSchedules(newSchedules);
  };

  const handleClickUp = () => {
    if (index < 1) {
      return;
    }
    const newSchedules = [...rule.schedules];
    newSchedules[index] = newSchedules[index - 1];
    newSchedules[index - 1] = schedule;
    setSchedules(newSchedules);
  };

  const handleClickDown = () => {
    if (index > rule.schedules.length - 2) {
      return;
    }
    const newSchedules = [...rule.schedules];
    newSchedules[index] = newSchedules[index + 1];
    newSchedules[index + 1] = schedule;
    setSchedules(newSchedules);
  };

  return (
    <>
      <Grid container>
        <Grid item container xs={6}>
          <h4>時間設定{index + 1}</h4>
        </Grid>
        <Grid item container xs={6} justifyContent="flex-end">
          <IconButton onClick={handleClickUp} disabled={index == 0}>
            <ArrowUpwardIcon fontSize="small" />
          </IconButton>
          <IconButton
            onClick={handleClickDown}
            disabled={index == rule.schedules.length - 1}
          >
            <ArrowDownwardIcon fontSize="small" />
          </IconButton>
          <IconButton onClick={handleClickDelete}>
            <DeleteIcon fontSize="small" />
          </IconButton>
        </Grid>
      </Grid>
      <ErrorMessage errors={errors} path={`rules/0/schedules/${index}`} />
      <Paper className={styles.paper}>
        {/* <TextField
          type="number"
          label="予約上限"
          helperText="1枠ごとの予約上限を設定します。"
          value={capacity.total}
          onChange={handleChangeCapacityTotal}
        /> */}
        <Box mb={1}>
          <Select value={condition.type} onChange={handleChangeScheduleType}>
            <MenuItem value="weekly">毎週の曜日ごとに指定</MenuItem>)
            <MenuItem value="monthly">毎月の日付ごとに指定</MenuItem>)
            <MenuItem value="custom-weekly">
              特定の週の指定曜日ごとに指定
            </MenuItem>
            )
          </Select>
        </Box>
        <div>{buildScheduleCondition()}</div>
        <TimeRangesEditor
          errors={errors}
          scheduleIndex={index}
          unit={rule.unit}
          schedule={schedule}
          setSchedule={setSchedule}
        />
      </Paper>
    </>
  );
}

function WeeklyScheduleConditionEditor(props: {
  errors: ValidationError[];
  schedule: Schedule;
  rule: RegularRule;
  condition: WeeklyCondition;
  onChangeSchedule: (schedule: Schedule) => void;
}) {
  const { errors, schedule, rule, condition, onChangeSchedule } = props;
  const { dayOfWeeks } = condition;

  const isChecked = (dayOfWeek: DayOfWeek) => {
    return dayOfWeeks.includes(dayOfWeek);
  };

  const handleChangeChecked = (dayOfWeek: DayOfWeek) => {
    if (isChecked(dayOfWeek)) {
      const newDayOfWeeks = dayOfWeeks.filter((d) => d != dayOfWeek);
      const newCondition: WeeklyCondition = {
        ...condition,
        dayOfWeeks: newDayOfWeeks,
      };
      onChangeSchedule({ ...schedule, condition: newCondition });
    } else {
      const newDayOfWeeks = [...dayOfWeeks, dayOfWeek];
      const newCondition: WeeklyCondition = {
        ...condition,
        dayOfWeeks: newDayOfWeeks,
      };
      onChangeSchedule({ ...schedule, condition: newCondition });
    }
  };

  const handleChangeCheckedHoliday = () => {
    const holiday = condition.holiday == true;
    const newCondition: WeeklyCondition = { ...condition, holiday: !holiday };
    onChangeSchedule({ ...schedule, condition: newCondition });
  };

  return (
    <>
      <table>
        <thead>
          <tr>
            {DAY_OF_WEEK_LABELS.map((dayOfWeek) => {
              return <th key={dayOfWeek}>{dayOfWeek}</th>;
            })}
            <th
              css={css`
                border-left: solid 1px #aaa;
              `}
            >
              祝日
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            {DAY_OF_WEEKS.map((dayOfWeek) => {
              return (
                <td key={dayOfWeek}>
                  <Checkbox
                    checked={isChecked(dayOfWeek)}
                    onChange={() => {
                      handleChangeChecked(dayOfWeek);
                    }}
                    css={styles.reserveDayOfWeek}
                  />
                </td>
              );
            })}
            <td>
              <Checkbox
                checked={Boolean(condition.holiday)}
                onChange={() => {
                  handleChangeCheckedHoliday();
                }}
              />
            </td>
          </tr>
        </tbody>
      </table>
      <ErrorMessage errors={errors} path={`rules/0/schedules/weekly`} />
    </>
  );
}

function MonthlyScheduleConditionEditor(props: {
  errors: ValidationError[];
  schedule: Schedule;
  rule: RegularRule;
  condition: MonthlyCondition;
  onChangeSchedule: (schedule: Schedule) => void;
}) {
  const { errors, schedule, rule, condition, onChangeSchedule } = props;
  const { dates } = condition;

  const isChecked = (date: number) => {
    return dates.includes(date);
  };

  const handleChangeChecked = (date: number) => {
    if (isChecked(date)) {
      const newDates = dates.filter((d) => d != date);
      const newCondition: MonthlyCondition = { ...condition, dates: newDates };
      onChangeSchedule({ ...schedule, condition: newCondition });
    } else {
      const newDates = [...dates, date].sort((a, b) => (a < b ? -1 : 1));
      const newCondition: MonthlyCondition = { ...condition, dates: newDates };
      onChangeSchedule({ ...schedule, condition: newCondition });
    }
  };

  return (
    <Box css={styles.monthlyTableWrapper}>
      <table>
        <tbody>
          {DATES.map((row, index) => {
            return (
              <tr key={index}>
                {row.map((date) => {
                  return (
                    <td key={date}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={isChecked(date)}
                            onChange={() => {
                              handleChangeChecked(date);
                            }}
                          />
                        }
                        label={date}
                        css={styles.dateLabel}
                      />
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      <ErrorMessage errors={errors} path={`rules/0/schedules/monthly`} />
    </Box>
  );
}

function timeString(time: Time): TimeFormat {
  return `${zeroPadding(time.hour?.toString() || '0', 2)}:${zeroPadding(
    time.minute?.toString() || '0',
    2
  )}` as TimeFormat;
}

export function TimeRangesEditor(props: {
  errors: ValidationError[];
  scheduleIndex: number;
  unit: number;
  schedule: Schedule;
  setSchedule: (schedule: Schedule) => void;
}) {
  const { errors, scheduleIndex, unit, schedule, setSchedule } = props;
  const [showIndex, setShowIndex] = useState<number>(0);

  const [beforeEditFirstTimeRange, setBeforeEditFirstTimeRange] =
    useState<TimeRangeCapacity>();
  const [beforeEditLastTimeRange, setBeforeEditLastTimeRange] =
    useState<TimeRangeCapacity>();
  const [deletedSlots, setDeletedSlots] = useState<SlotCapacity[]>([]);
  const theme = useTheme();

  const beforeFirstTimeRangeSlots = () => {
    if (
      schedule.timeRanges.length > 0 &&
      beforeEditFirstTimeRange &&
      isBeforeTimeRange(
        beforeEditFirstTimeRange.start,
        schedule.timeRanges[0].start
      )
    ) {
      const slots = getSlotsByTimeRanges(
        [
          {
            start: beforeEditFirstTimeRange.start,
            end: schedule.timeRanges[0].start,
          },
        ],
        unit,
        undefined
      );
      return slots;
    }
    return [];
  };

  const afterLastTimeRangeSlots = () => {
    if (
      beforeEditLastTimeRange &&
      schedule.timeRanges.length > 0 &&
      isAfterTimeRange(
        beforeEditLastTimeRange.end,
        schedule.timeRanges[schedule.timeRanges.length - 1].end
      )
    ) {
      const slots = getSlotsByTimeRanges(
        [
          {
            start: schedule.timeRanges[schedule.timeRanges.length - 1].end,
            end: beforeEditLastTimeRange.end,
          },
        ],
        unit,
        undefined
      );
      return slots;
    }
    return [];
  };

  const handleClickAdd = () => {
    const newTimeRange: TimeRange = createNewTimeRange(unit, schedule);
    const timeRanges = [...schedule.timeRanges, newTimeRange];
    setSchedule({ ...schedule, timeRanges });
  };

  const handleChangeCapacityTotal = (
    e: ChangeEvent<HTMLInputElement> | number,
    slot: SlotCapacity,
    index: number
  ) => {
    const total = typeof e === 'number' ? e : parseInt(e.target.value);
    if (isNaN(total) || total < 0) return;
    const newCapacity = { ...slot.capacity, total };
    const newSlot = { ...slot, capacity: newCapacity };
    const timeRangesByTimes: TimeRangeCapacity[] = [
      {
        start: newSlot.slot.timeRange.start,
        end: newSlot.slot.timeRange.end,
        capacity: newSlot.capacity,
      },
    ];

    const scheduleData = schedule.timeRanges.slice();
    const timeRanges =
      index === 0
        ? timeRangesByTimes.concat(scheduleData).slice()
        : scheduleData.concat(timeRangesByTimes).slice();

    setSchedule({
      ...schedule,
      timeRanges,
    });
  };

  const handleToggleStartNotAllowed = (slot: SlotCapacity, index: number) => {
    const newCapacity = {
      ...slot.capacity,
      startNotAllowed: !slot.capacity.startNotAllowed,
    };
    const newSlot = { ...slot, capacity: newCapacity };
    const timeRangesByTimes: TimeRangeCapacity[] = [
      {
        start: newSlot.slot.timeRange.start,
        end: newSlot.slot.timeRange.end,
        capacity: newSlot.capacity,
      },
    ];

    const scheduleData = schedule.timeRanges.slice();
    const timeRanges =
      index === 0
        ? timeRangesByTimes.concat(scheduleData).slice()
        : scheduleData.concat(timeRangesByTimes).slice();

    setSchedule({
      ...schedule,
      timeRanges,
    });
  };

  const addDeletedSlot = (newValue: SlotCapacity) => {
    const basedValues = deletedSlots.slice();
    basedValues.push(newValue);
    setDeletedSlots(
      basedValues.sort(
        (a, b) => a.slot.timeRange.start.hour - b.slot.timeRange.start.hour
      )
    );
  };

  const removeDeletedSlot = (removeValue: SlotCapacity) => {
    const basedValues = deletedSlots.slice().filter((value) => {
      return (
        JSON.stringify(value.slot.timeRange) !==
        JSON.stringify(removeValue.slot.timeRange)
      );
    });
    setDeletedSlots(
      basedValues.sort(
        (a, b) => a.slot.timeRange.start.hour - b.slot.timeRange.start.hour
      )
    );
  };

  useEffect(() => {
    setDeletedSlots([]);
  }, [unit]);

  useEffect(() => {
    if (schedule.timeRanges.length > 0) {
      if (
        !beforeEditFirstTimeRange ||
        isBeforeTimeRange(
          schedule.timeRanges[0].start,
          beforeEditFirstTimeRange.start
        )
      ) {
        setBeforeEditFirstTimeRange(schedule.timeRanges[0]);
      }

      if (
        !beforeEditLastTimeRange ||
        isAfterTimeRange(
          schedule.timeRanges[schedule.timeRanges.length - 1].end,
          beforeEditLastTimeRange.end
        )
      ) {
        setBeforeEditLastTimeRange(
          schedule.timeRanges[schedule.timeRanges.length - 1]
        );
      }
    }
  }, [JSON.stringify(schedule)]);

  return (
    <>
      <div>
        <ErrorMessage
          errors={errors}
          path={`rules/0/schedules/${scheduleIndex}/timeRanges`}
        />
        <Box style={{ marginTop: '24px' }}>
          <Tabs
            value={showIndex}
            onChange={(e, value) => {
              setShowIndex(value);
            }}
            TabIndicatorProps={{
              style: {
                backgroundColor: theme.palette.primary.main,
              },
            }}
          >
            <Tab
              label="ルールで表示"
              {...a11yProps(0)}
              style={{
                background: '#FFFFFF',
                borderRadius: '4px 4px 0 0 ',
              }}
            />
            <Tab
              label="時間で表示"
              {...a11yProps(1)}
              style={{
                background: '#FFFFFF',
                borderRadius: '4px 4px 0 0 ',
              }}
            />
          </Tabs>
          <TabPanel value={showIndex} index={0}>
            <Box>
              {schedule.timeRanges.map((timeRange, index) => {
                return (
                  <TimeRangeEditor
                    errors={errors}
                    unit={unit}
                    schedule={schedule}
                    scheduleIndex={scheduleIndex}
                    index={index}
                    timeRange={timeRange}
                    onChangeSchedule={setSchedule}
                    key={index}
                  />
                );
              })}
            </Box>
            <div>
              <Button variant="text" color="primary" onClick={handleClickAdd}>
                時間帯を追加
              </Button>
            </div>
          </TabPanel>
          <TabPanel value={showIndex} index={1}>
            <Box>
              {schedule.timeRanges.map((timeRange, index) => (
                <Box mt="6px" mb="12px" key={index}>
                  <TimeRangeEditor
                    errors={errors}
                    unit={unit}
                    schedule={schedule}
                    scheduleIndex={scheduleIndex}
                    index={-1}
                    timeRange={timeRange}
                    onChangeSchedule={setSchedule}
                    key={index}
                  />
                </Box>
              ))}
              <Box style={{ marginTop: '24px' }}>
                {beforeFirstTimeRangeSlots().map((slot, index) => {
                  const capacityTotal = getTotal(schedule, {
                    start: slot.slot.timeRange.start,
                    end: slot.slot.timeRange.end,
                    capacity: slot.capacity,
                  });
                  return (
                    <TimeRangeEditorBySlot
                      slot={slot}
                      index={0}
                      capacityTotal={capacityTotal}
                      startNotAllowed={slot.capacity.startNotAllowed}
                      handleChangeCapacityTotal={handleChangeCapacityTotal}
                      handleToggleStartNotAllowed={handleToggleStartNotAllowed}
                      key={index}
                    />
                  );
                })}
                {schedule.timeRanges.map((_, index) => (
                  <ShowTimeRangeByEverySlots
                    schedule={schedule}
                    setSchedule={setSchedule}
                    timeRangeIndex={index}
                    unit={unit}
                    deletedSlots={deletedSlots}
                    addDeletedSlot={addDeletedSlot}
                    removeDeletedSlot={removeDeletedSlot}
                    key={index}
                  />
                ))}
                {afterLastTimeRangeSlots().map((slot, index) => {
                  const capacityTotal = getTotal(schedule, {
                    start: slot.slot.timeRange.start,
                    end: slot.slot.timeRange.end,
                    capacity: slot.capacity,
                  });
                  return (
                    <TimeRangeEditorBySlot
                      slot={slot}
                      index={-1}
                      capacityTotal={capacityTotal}
                      startNotAllowed={slot.capacity.startNotAllowed}
                      handleChangeCapacityTotal={handleChangeCapacityTotal}
                      handleToggleStartNotAllowed={handleToggleStartNotAllowed}
                      key={index}
                    />
                  );
                })}
              </Box>
            </Box>
          </TabPanel>
        </Box>
      </div>
    </>
  );
}

const getTotal = (schedule: Schedule, timeRange: TimeRangeCapacity) => {
  if (
    timeRange.capacity?.total !== undefined &&
    timeRange.capacity?.total !== null
  ) {
    return timeRange.capacity.total;
  } else if (
    schedule.capacity?.total !== undefined &&
    schedule.capacity?.total !== null
  ) {
    return schedule.capacity.total;
  } else {
    return 0;
  }
};

function CustomWeeklyScheduleConditionEditor(props: {
  errors: ValidationError[];
  schedule: Schedule;
  condition: CustomWeeklyCondition;
  onChangeSchedule: (schedule: Schedule) => void;
  index: number;
}) {
  const { errors, schedule, onChangeSchedule, condition, index } = props;
  const handleClickDelete = () => {
    const newCondition: CustomWeeklyCondition = {
      ...condition,
      dayOfWeeks: condition.dayOfWeeks.filter((t, i) => i != index),
    };
    onChangeSchedule({ ...schedule, condition: newCondition });
  };

  const handleChangeWeeklyNumber = (
    e: React.ChangeEvent<{ value: unknown }>
  ) => {
    const newDayOfWeeks = condition.dayOfWeeks.map((data, i) => {
      if (i === index) {
        return { ...data, target: e.target.value } as CustomDayOfWeek;
      } else {
        return { ...data } as CustomDayOfWeek;
      }
    });
    const newCondition: CustomWeeklyCondition = {
      ...condition,
      dayOfWeeks: newDayOfWeeks,
    };
    onChangeSchedule({ ...schedule, condition: newCondition });
  };

  const handleChangeDayOfWeek = (e: React.ChangeEvent<{ value: unknown }>) => {
    const newDayOfWeeks = condition.dayOfWeeks.map((data, i) => {
      if (i === index) {
        return { ...data, dayOfWeek: e.target.value } as CustomDayOfWeek;
      } else {
        return { ...data } as CustomDayOfWeek;
      }
    });
    const newCondition: CustomWeeklyCondition = {
      ...condition,
      dayOfWeeks: newDayOfWeeks,
    };
    onChangeSchedule({ ...schedule, condition: newCondition });
  };

  return (
    <>
      <Box css={styles.reserveSettingWrapper}>
        <Box css={styles.reserveSetting}>
          <Box mb={1}>
            <Select
              defaultValue={1}
              value={condition.dayOfWeeks[index].target}
              onChange={handleChangeWeeklyNumber}
            >
              {[...Array(5)]
                .map((_, i) => i + 1)
                .map((data) => {
                  return (
                    <MenuItem key={data} value={data}>
                      毎月第{data}
                    </MenuItem>
                  );
                })}
            </Select>
          </Box>
          <Box mb={1}>
            <Select
              defaultValue={'Monday'}
              value={condition.dayOfWeeks[index].dayOfWeek}
              onChange={handleChangeDayOfWeek}
            >
              {DAY_OF_WEEKS.map((data, index) => {
                return (
                  <MenuItem key={data} value={data}>
                    {DAY_OF_WEEK_LABELS[index]}曜日
                  </MenuItem>
                );
              })}
            </Select>
          </Box>
          {condition.dayOfWeeks.length > 1 && (
            <IconButton onClick={handleClickDelete}>
              <DeleteIcon fontSize="small" />
            </IconButton>
          )}
        </Box>
      </Box>
      <ErrorMessage
        errors={errors}
        path={`rules/0/schedules/custom-weekly/${index}`}
      />
    </>
  );
}

function CustomWeeklyScheduleConditionEditors(props: {
  errors: ValidationError[];
  schedule: Schedule;
  condition: CustomWeeklyCondition;
  onChangeSchedule: (schedule: Schedule) => void;
}) {
  const { errors, schedule, onChangeSchedule, condition } = props;

  const handleClickAdd = () => {
    const newDayOfWeeks: CustomDayOfWeek = { dayOfWeek: 'Monday', target: 1 };
    const newCondition: CustomWeeklyCondition = {
      ...condition,
      dayOfWeeks: [...condition.dayOfWeeks, newDayOfWeeks],
    };
    onChangeSchedule({ ...schedule, condition: newCondition });
  };

  return (
    <>
      <Box>
        {condition.dayOfWeeks.map((data, index) => {
          return (
            <CustomWeeklyScheduleConditionEditor
              key={index}
              errors={errors}
              schedule={schedule}
              condition={condition}
              onChangeSchedule={onChangeSchedule}
              index={index}
            />
          );
        })}
      </Box>
      <div>
        <Button variant="text" color="primary" onClick={handleClickAdd}>
          対象の週と曜日を追加
        </Button>
      </div>
    </>
  );
}

function TimeRangeEditor(props: {
  errors: ValidationError[];
  unit: number;
  schedule: Schedule;
  timeRange: TimeRangeCapacity;
  scheduleIndex: number;
  index: number;
  onChangeSchedule: (schedule: Schedule) => void;
}) {
  const {
    errors,
    unit,
    schedule,
    timeRange,
    scheduleIndex,
    index,
    onChangeSchedule,
  } = props;

  const setTimeRange = (newTimeRange: TimeRange, updateIndex: number) => {
    const timeRanges = [...schedule.timeRanges];
    timeRanges[updateIndex] = newTimeRange;
    onChangeSchedule({ ...schedule, timeRanges });
  };

  const handleClickDelete = () => {
    const timeRanges = schedule.timeRanges.filter((t, i) => i != index);
    onChangeSchedule({ ...schedule, timeRanges });
  };

  const handleChangeStart = (time: string) => {
    if (!isTimeFormat(time)) {
      return;
    }
    const start = createTime(time);
    if (index >= 0) {
      const newTimeRange = { ...timeRange, start };
      setTimeRange(newTimeRange, index);
    } else {
      const newTimeRange = {
        ...timeRange,
        start,
        end: schedule.timeRanges[0].end,
        capacity: schedule.timeRanges[0].capacity,
      };
      const updateIndex = 0;
      setTimeRange(newTimeRange, updateIndex);
    }
  };

  const handleChangeEnd = (time: string) => {
    if (!isTimeFormat(time)) {
      return;
    }
    const end = createTime(time);
    if (index >= 0) {
      const newTimeRange = { ...timeRange, end };
      setTimeRange(newTimeRange, index);
    } else {
      const newTimeRange = {
        ...timeRange,
        end,
        start: schedule.timeRanges[schedule.timeRanges.length - 1].start,
        capacity: schedule.timeRanges[schedule.timeRanges.length - 1].capacity,
      };
      const updateIndex = schedule.timeRanges.length - 1;
      setTimeRange(newTimeRange, updateIndex);
    }
  };

  const handleChangeCapacityTotal = (e: ChangeEvent<HTMLInputElement>) => {
    const total = parseInt(e.target.value);
    const newCapacity = {
      ...timeRange.capacity,
      total,
    };
    const newTimeRange = { ...timeRange, capacity: newCapacity };
    setTimeRange(newTimeRange, index);
  };

  const handleToggleStartNotAllowed = () => {
    if (timeRange.capacity === undefined) {
      return;
    }
    const newCapacity = {
      ...timeRange.capacity,
      startNotAllowed: !(timeRange.capacity.startNotAllowed === true),
    };
    const newTimeRange = { ...timeRange, capacity: newCapacity };
    setTimeRange(newTimeRange, index);
  };

  const slots = getSlotsByTimeRanges([timeRange], unit, schedule.capacity);

  return (
    <>
      <Box css={styles.reserveSettingWrapper}>
        <Box css={styles.reserveSetting}>
          <TimeTextField
            value={timeString(timeRange.start)}
            onChange={handleChangeStart}
            disabled={index < 0}
          />
          〜
          <TimeTextField
            value={timeString(timeRange.end)}
            onChange={handleChangeEnd}
            disabled={index < 0}
          />
          の{unit}分ごとに
        </Box>
        {index >= 0 ? (
          <Box css={styles.reserveSetting}>
            <TextField
              type="number"
              value={getTotal(schedule, timeRange)}
              css={css`
                width: 40px;
              `}
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                inputProps: { min: 0, max: 99, style: { textAlign: 'center' } },
              }}
              onChange={handleChangeCapacityTotal}
            />
            件づつ
            <Select
              value={timeRange.capacity?.startNotAllowed === true}
              onChange={handleToggleStartNotAllowed}
            >
              <MenuItem value="false">予約可能</MenuItem>
              <MenuItem value="true">
                <span style={{ color: 'red' }}>
                  予約可能(この時間から予約開始は不可)
                </span>
              </MenuItem>
            </Select>
            <IconButton onClick={handleClickDelete}>
              <DeleteIcon fontSize="small" />
            </IconButton>
            <span>(全{slots.length}枠)</span>
          </Box>
        ) : (
          <Box css={styles.reserveSetting}>
            予約可能
            {timeRange.capacity?.startNotAllowed === true && (
              <span style={{ color: 'red' }}>(この時間から予約開始は不可)</span>
            )}
          </Box>
        )}
      </Box>
      <ErrorMessage
        errors={errors}
        path={`rules/0/schedules/${scheduleIndex}/timeRanges/${index}`}
      />
    </>
  );
}

function ShowTimeRangeByEverySlots(props: {
  schedule: Schedule;
  setSchedule: (schedule: Schedule) => void;
  timeRangeIndex: number;
  unit: number;
  deletedSlots: SlotCapacity[];
  addDeletedSlot: (newValue: SlotCapacity) => void;
  removeDeletedSlot: (newValue: SlotCapacity) => void;
}) {
  const {
    schedule,
    setSchedule,
    timeRangeIndex,
    unit,
    deletedSlots,
    addDeletedSlot,
    removeDeletedSlot,
  } = props;
  const [times, setTimes] = useState<SlotCapacity[]>([]);

  const deletedFirstContent = () => {
    if (timeRangeIndex === 0) return [];

    return deletedSlots.filter((slot) => {
      return (
        isBetweenTimeRange(
          slot.slot.timeRange.start,
          schedule.timeRanges[timeRangeIndex - 1].end,
          schedule.timeRanges[timeRangeIndex].start
        ) &&
        slot.slot.timeRange.start.minute ===
          schedule.timeRanges[timeRangeIndex].start.minute
      );
    });
  };

  const deletedLastContent = () => {
    if (timeRangeIndex === schedule.timeRanges.length - 1) return [];

    return deletedSlots.filter((slot) => {
      return (
        isBetweenTimeRange(
          slot.slot.timeRange.start,
          schedule.timeRanges[timeRangeIndex].end,
          schedule.timeRanges[timeRangeIndex + 1].start
        ) &&
        slot.slot.timeRange.start.minute ===
          schedule.timeRanges[timeRangeIndex].start.minute
      );
    });
  };

  const isFirstIndex = (): boolean => timeRangeIndex === 0;
  const isLastIndex = (): boolean =>
    timeRangeIndex === schedule.timeRanges.length - 1;

  // trueの場合、対象のtimeRangeの次の開始時刻までの枠も範囲に入れる
  const isIncludeAfterData = (): boolean => {
    const isSameMinute = (): boolean =>
      schedule.timeRanges[timeRangeIndex].end.minute ===
      schedule.timeRanges[timeRangeIndex + 1].start.minute;
    return !isLastIndex() && isSameMinute();
  };

  const isSettingFirstContent = (): boolean => {
    return deletedFirstContent().length > 0 && !isFirstIndex();
  };

  const isSettingLastContent = (): boolean => {
    return deletedLastContent().length > 0 && !isLastIndex();
  };

  const isSaveFirstContent = (total: number, index: number): boolean => {
    const isSameMinute = (): boolean =>
      schedule.timeRanges[timeRangeIndex - 1].end.minute ===
      schedule.timeRanges[timeRangeIndex].start.minute;
    return total === 0 && index === 0 && !isFirstIndex() && !isSameMinute();
  };

  const isSaveLastContent = (total: number, index: number): boolean => {
    const isSameMinute = (): boolean =>
      schedule.timeRanges[timeRangeIndex].end.minute ===
      schedule.timeRanges[timeRangeIndex + 1].start.minute;
    return (
      total === 0 &&
      index === times.length - 1 &&
      !isLastIndex() &&
      !isSameMinute()
    );
  };

  const timeRangeStart = isSettingFirstContent()
    ? deletedFirstContent()[0].slot.timeRange.start
    : schedule.timeRanges[timeRangeIndex].start;
  const timeRangeEnd = isIncludeAfterData()
    ? schedule.timeRanges[timeRangeIndex + 1].start
    : isSettingLastContent()
    ? deletedLastContent()[deletedLastContent().length - 1].slot.timeRange.end
    : schedule.timeRanges[timeRangeIndex].end;

  const slots =
    schedule.timeRanges.length > 0
      ? getSlotsByTimeRanges(
          [schedule.timeRanges[timeRangeIndex]],
          unit,
          schedule.capacity
        )
      : [];

  const totalTimeRange: SlotCapacity[] =
    slots.length > 0
      ? getSlotsByTimeRanges(
          [
            {
              start: timeRangeStart,
              end: timeRangeEnd,
            },
          ],
          unit,
          undefined
        )
      : [];

  const noDataTimes = totalTimeRange.filter((time) => {
    return !slots.some(
      (slot) =>
        slot.slot.timeRange.start.hour === time.slot.timeRange.start.hour &&
        slot.slot.timeRange.start.minute === time.slot.timeRange.start.minute
    );
  });

  useEffect(() => {
    setTimes(
      [...slots, ...noDataTimes].sort(
        (a, b) => a.slot.timeRange.start.hour - b.slot.timeRange.start.hour
      )
    );
  }, [unit]);

  useEffect(() => {
    const newTimes = [...slots, ...noDataTimes].sort(
      (a, b) => a.slot.timeRange.start.hour - b.slot.timeRange.start.hour
    );

    setTimes(newTimes);
  }, [JSON.stringify(schedule)]);

  const handleChangeCapacityTotalShowByEveryHour = (
    e: ChangeEvent<HTMLInputElement> | number,
    slot: SlotCapacity,
    index: number
  ) => {
    const total = typeof e === 'number' ? e : parseInt(e.target.value);
    if (isNaN(total) || total < 0) return;
    const newCapacity = { ...slot.capacity, total };
    const newSlot = { ...slot, capacity: newCapacity };
    const baseTimes = [...times];
    baseTimes[index] = newSlot;

    if (isSaveFirstContent(total, index) || isSaveLastContent(total, index)) {
      addDeletedSlot({
        ...slot,
        capacity: {
          total: 0,
        },
      });
    } else if (total > 0) {
      removeDeletedSlot({ ...slot });
    }
    createTimeRanges(baseTimes, index);
  };

  const handleToggleStartNotAllowed = (slot: SlotCapacity, index: number) => {
    const newCapacity = {
      ...slot.capacity,
      startNotAllowed: !slot.capacity.startNotAllowed,
    };
    const newSlot = { ...slot, capacity: newCapacity };
    const baseTimes = [...times];
    baseTimes[index] = newSlot;
    createTimeRanges(baseTimes, index);
  };

  const createTimeRanges = (newTimes: SlotCapacity[], index: number) => {
    let timeRangesByTimes: TimeRangeCapacity[] = [];
    let pushData: TimeRangeCapacity = {
      start: newTimes[0].slot.timeRange.start,
      end: newTimes[0].slot.timeRange.end,
      capacity: newTimes[0].capacity,
    };
    newTimes.forEach((time) => {
      if (
        pushData.capacity?.total !== time.capacity.total ||
        pushData.capacity?.startNotAllowed != time.capacity.startNotAllowed
      ) {
        if (pushData.capacity?.total) {
          timeRangesByTimes.push(pushData);
        }
        pushData = {
          start: time.slot.timeRange.start,
          end: time.slot.timeRange.end,
          capacity: time.capacity,
        };
      } else {
        pushData.end = time.slot.timeRange.end;
      }
    });

    // forEachの最後の要素を追加
    if (pushData.capacity?.total) {
      timeRangesByTimes.push(pushData);
    }

    const beforeScheduleData = schedule.timeRanges.slice(0, timeRangeIndex);
    const afterScheduleData = schedule.timeRanges.slice(timeRangeIndex + 1);

    if (
      timeRangesByTimes.length > 0 &&
      index === 0 &&
      beforeScheduleData.length > 0 &&
      isSameTimeRange(
        beforeScheduleData[beforeScheduleData.length - 1].end,
        timeRangesByTimes[0].start
      ) &&
      beforeScheduleData[beforeScheduleData.length - 1].capacity?.total ===
        timeRangesByTimes[0].capacity?.total
    ) {
      beforeScheduleData[beforeScheduleData.length - 1] = {
        ...beforeScheduleData[beforeScheduleData.length - 1],
        end: timeRangesByTimes[0].end,
      };
      timeRangesByTimes = timeRangesByTimes.slice(1);
    }

    if (
      timeRangesByTimes.length > 0 &&
      index === newTimes.length - 1 &&
      afterScheduleData.length > 0 &&
      isSameTimeRange(
        timeRangesByTimes[timeRangesByTimes.length - 1].end,
        afterScheduleData[0].start
      ) &&
      timeRangesByTimes[timeRangesByTimes.length - 1].capacity?.total ===
        afterScheduleData[0].capacity?.total
    ) {
      afterScheduleData[0] = {
        ...afterScheduleData[0],
        start: timeRangesByTimes[timeRangesByTimes.length - 1].start,
      };
      timeRangesByTimes = timeRangesByTimes.slice(0, -1);
    }

    const timeRanges = beforeScheduleData.concat(
      timeRangesByTimes,
      afterScheduleData
    );

    setSchedule({
      ...schedule,
      timeRanges,
    });
  };

  return (
    <Box>
      {times.map((slot, index) => {
        const capacityTotal = getTotal(schedule, {
          start: slot.slot.timeRange.start,
          end: slot.slot.timeRange.end,
          capacity: slot.capacity,
        });
        return (
          <TimeRangeEditorBySlot
            slot={slot}
            index={index}
            capacityTotal={capacityTotal}
            startNotAllowed={slot.capacity.startNotAllowed}
            handleChangeCapacityTotal={handleChangeCapacityTotalShowByEveryHour}
            handleToggleStartNotAllowed={handleToggleStartNotAllowed}
            key={index}
          />
        );
      })}
      {!isLastIndex() && !isIncludeAfterData() && (
        <Box
          style={{
            padding: '16px 0',
            marginTop: '16px',
            marginLeft: '-16px',
            backgroundImage: 'url("/img/wave.png")',
            backgroundPosition: 'center',
            backgroundRepeat: 'repeat no-repeat',
            maxWidth: '420px',
          }}
        />
      )}
    </Box>
  );
}

function TimeRangeEditorBySlot(props: {
  slot: SlotCapacity;
  index: number;
  capacityTotal: number;
  startNotAllowed: boolean | undefined;
  handleChangeCapacityTotal: (
    e: ChangeEvent<HTMLInputElement> | number,
    slot: SlotCapacity,
    index: number
  ) => void;
  handleToggleStartNotAllowed: (slot: SlotCapacity, index: number) => void;
}) {
  const {
    slot,
    index,
    capacityTotal,
    startNotAllowed,
    handleChangeCapacityTotal,
    handleToggleStartNotAllowed,
  } = props;

  return (
    <Grid
      container
      justifyContent="flex-start"
      alignItems="center"
      css={[
        styles.timeContainer,
        capacityTotal === 0 ? styles.capacityZeroContainer : css``,
      ]}
      key={index}
    >
      <Grid item css={styles.timesTableTimeRange}>
        {timeString(slot.slot.timeRange.start)}〜
        {timeString(slot.slot.timeRange.end)}
      </Grid>
      <Grid
        item
        css={css`
          margin-left: 8px;
          line-height: 12px;
        `}
      >
        に
      </Grid>
      <Grid item style={{ marginLeft: '8px', marginRight: '8px' }}>
        <TextField
          type="number"
          value={capacityTotal}
          css={css`
            width: 60px;
            text-align: center;
          `}
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            inputProps: {
              min: 0,
              max: 99,
              style: { textAlign: 'center' },
            },
          }}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            handleChangeCapacityTotal(e, slot, index);
          }}
        />
      </Grid>
      <Grid
        item
        css={css`
          line-height: 12px;
        `}
      >
        件
        <Select
          value={startNotAllowed === true}
          onChange={() => {
            handleToggleStartNotAllowed(slot, index);
          }}
        >
          <MenuItem value="false">予約可能</MenuItem>
          <MenuItem value="true">
            <span style={{ color: 'red' }}>
              予約可能(この時間から予約開始は不可)
            </span>
          </MenuItem>
        </Select>
      </Grid>
      <Grid
        item
        css={css`
          margin-left: 16px;
        `}
      >
        <Button
          onClick={() => {
            handleChangeCapacityTotal(0, slot, index);
          }}
          disabled={capacityTotal === 0}
          variant="outlined"
          color="primary"
          css={css`
            font-size: 12px;
          `}
        >
          0件を設定
        </Button>
      </Grid>
    </Grid>
  );
}

function ErrorMessage(props: { errors: ValidationError[]; path: string }) {
  const { errors, path } = props;

  const error = errors.find((e) => e.path == path);
  if (!error) {
    return null;
  }

  return <div css={styles.error}>{error.message}</div>;
}
