/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import {
  Box,
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import { useState } from 'react';
import { ResourceSchedulePattern } from '../../../@interfaces/resource/resource-schedule-pattern';
import { DraggableDialog } from '../../../components/DraggableDialog';
import Spinner from '../../../components/Spinner';
import { toTimeStringByTime } from '../../../core/types/reservation-types';
import { SchedulePatternCreateOrUpdate } from './SchedulePatternCreateOrUpdate';

const styles = {
  preview: (color: string) =>
    css`
      display: inline-block;
      padding: 2px 12px;
      background-color: ${color === '' ? '#FFFFFF' : color};
    `,
};

type ResourceSchedulePatternListProps = {
  shopId: string;
  resourceSchedulePatternQuery: {
    data: ResourceSchedulePattern[];
    isLoading: boolean;
    reload: () => void;
  };
};

export const ResourceSchedulePatternList: React.VFC<
  ResourceSchedulePatternListProps
> = (props) => {
  const { shopId, resourceSchedulePatternQuery } = props;
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [editData, setEditData] = useState<ResourceSchedulePattern>();

  if (resourceSchedulePatternQuery.isLoading) {
    return <Spinner loading={resourceSchedulePatternQuery.isLoading} />;
  }

  const handleAdd = () => {
    setEditData(undefined);
    setDialogOpen(true);
  };

  const handleEdit = (pattern: ResourceSchedulePattern) => {
    setEditData(pattern);
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const reloadData = () => {
    resourceSchedulePatternQuery.reload();
  };

  return (
    <div
      css={css`
        width: 100%;
      `}
    >
      <Grid
        container
        spacing={3}
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item>
          <h3>稼働パターン({resourceSchedulePatternQuery.data.length})</h3>
        </Grid>
        <Grid item>
          <Button onClick={handleAdd} color="primary" variant="contained">
            追加
          </Button>
        </Grid>
      </Grid>
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>パターン名</TableCell>
              <TableCell>時間</TableCell>
              <TableCell>アクション</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {resourceSchedulePatternQuery.data.map((pattern, index) => (
              <TableRow key={pattern.id}>
                <TableCell scope="row">
                  <Box css={styles.preview(pattern.color)}>{pattern.name}</Box>
                </TableCell>
                <TableCell scope="row">
                  {pattern.schedule.timeRanges.map((range, index) => {
                    return (
                      <div key={index}>
                        {toTimeStringByTime(range.start)}〜
                        {toTimeStringByTime(range.end)}
                      </div>
                    );
                  })}
                </TableCell>
                <TableCell scope="row">
                  <Button
                    color="primary"
                    startIcon={<EditIcon />}
                    onClick={() => {
                      handleEdit(pattern);
                    }}
                  >
                    編集
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <DraggableDialog
        open={dialogOpen}
        closeFunc={() => {
          reloadData();
          handleDialogClose();
        }}
        title={editData ? '稼働パターンの編集' : '稼働パターンの作成'}
      >
        <SchedulePatternCreateOrUpdate
          editData={editData}
          dialogClose={handleDialogClose}
          reloadData={reloadData}
        />
      </DraggableDialog>
    </div>
  );
};
