/**
 * objBをデフォルト値として、objAをマージする
 * @param objA
 * @param objB デフォルト値
 * @returns マージ後のオブジェクト
 */
export function deepMerge<T, U>(objA: T, objB: U): T & U {
  const result = { ...objB } as T & U;

  for (const key in objA) {
    if (Object.prototype.hasOwnProperty.call(objA, key)) {
      const propKey = key as keyof T;

      if (
        typeof objA[propKey] === 'object' &&
        objA[propKey] !== null &&
        !Array.isArray(objA[propKey])
      ) {
        // objA[propKey]がオブジェクトの場合、再帰的にマージ
        result[propKey] = deepMerge(
          objA[propKey],
          result[propKey] || {}
        ) as any;
      } else {
        // そうでない場合、objAの値を採用
        result[propKey] = objA[propKey] as any; // 型アサーションを追加
      }
    }
  }
  return result;
}
