/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { Box, Button } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useResourceGroups } from '../api/getResourceGroups';
import { useResources } from '../api/getResources';
import { useResourceSchedulePatterns } from '../api/getResourceSchedulePatterns';

const styles = {
  activeAlert: css``,
  inactiveAlert: css`
    background-color: #ddd;
  `,
};

type ResourceTutorialProps = {
  workspaceUid: string;
  shopId: string;
  resourceGroupsQuery: ReturnType<typeof useResourceGroups>;
  resourcesQuery: ReturnType<typeof useResources>;
  resourceSchedulePatternQuery: ReturnType<typeof useResourceSchedulePatterns>;
};

export const ResourceTutorial = ({
  workspaceUid,
  shopId,
  resourceGroupsQuery,
  resourcesQuery,
  resourceSchedulePatternQuery,
}: ResourceTutorialProps): JSX.Element => {
  const history = useHistory();
  const [step, setStep] = useState<number>(-1);

  const stepInfos = [
    {
      title: 'Step 1. リソースグループを作成しよう',
      buttonText: 'リソースグループを作成する',
      clickEvent: () => {
        history.push(
          `/a/${workspaceUid}/shops/${shopId}/settings/resource-group`
        );
      },
    },
    {
      title: 'Step 2. リソースを作成しよう',
      buttonText: 'リソースを作成する',
      clickEvent: () => {
        history.push(
          `/a/${workspaceUid}/shops/${shopId}/settings/resource-list`
        );
      },
    },
    {
      title:
        'Step 3. 稼働パターンを作成しよう。作成後に稼働表の入力が行えるようになります。',
      buttonText: '稼働パターンを作成する',
      clickEvent: () => {
        history.push(
          `/a/${workspaceUid}/shops/${shopId}/settings/resource-pattern`
        );
      },
    },
  ];

  useEffect(() => {
    if (resourceGroupsQuery.data.length === 0) {
      setStep(0);
    } else if (resourcesQuery.data.length === 0) {
      setStep(1);
    } else if (resourceSchedulePatternQuery.data.length === 0) {
      setStep(2);
    } else {
      setStep(-1);
    }
  }, [
    resourceGroupsQuery.data,
    resourcesQuery.data,
    resourceSchedulePatternQuery.data,
  ]);

  return (
    <>
      {step >= 0 &&
        !resourceGroupsQuery.isLoading &&
        !resourcesQuery.isLoading &&
        !resourceSchedulePatternQuery.isLoading && (
          <Box style={{ marginBottom: '24px' }}>
            {stepInfos.map((stepInfo, index) => (
              <Alert
                severity="info"
                css={step === index ? styles.activeAlert : styles.inactiveAlert}
              >
                {step === index ? (
                  <strong>{stepInfo.title}</strong>
                ) : (
                  <span>{stepInfo.title}</span>
                )}
                {step === index && (
                  <div
                    css={css`
                      margin-top: 16px;
                    `}
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={stepInfo.clickEvent}
                    >
                      {stepInfo.buttonText}
                    </Button>
                  </div>
                )}
              </Alert>
            ))}
          </Box>
        )}
    </>
  );
};
