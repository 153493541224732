import { AxiosResponse } from 'axios';
import { API_END_POINT } from '../../../api/api';
import { useMutation } from '../../common/api/use-mutation';

export const useDeleteCustomer = (): ((
  workspaceUid: string,
  customerId: number
) => Promise<AxiosResponse<''>>) => {
  const { request } = useMutation<'', undefined>();
  return async (workspaceUid, customerId) => {
    return request(
      'DELETE',
      `${API_END_POINT}/app/ws/${workspaceUid}/customers/${customerId}`,
      {}
    );
  };
};
