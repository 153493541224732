import { ResourceSchedulePattern } from '../../../@interfaces/resource/resource-schedule-pattern';
import { toTimeStringByTime } from '../../../core/types/reservation-types';

export const toPatternRangeString = (
  pattern: ResourceSchedulePattern
): string => {
  return pattern.schedule.timeRanges
    .map((range) => {
      return `${toTimeStringByTime(range.start)} ~ ${toTimeStringByTime(
        range.end
      )}`;
    })
    .join(', ');
};
