/** @jsxImportSource @emotion/react */

import React from 'react';
import { MainMenu } from '../../features/main-menu/components/MainMenu';
import {
  ShopSideMenu,
  ShopSideMenuItemId,
} from '../../features/side-menu/components/ShopSideMenu';
import { helps } from '../../utils/helps';
import { TopAppBar } from '../Shared';
import { BaseLayout } from './BaseLayout';

type ShopPageLayoutProps = {
  workspaceUid: string;
  shopId: string;
  current: ShopSideMenuItemId;
  helpId?: string;
  breadcrumbs: React.ReactNode;
  children: React.ReactNode;
};

export function ShopPageLayout({
  workspaceUid,
  shopId,
  current,
  helpId = helps.shop.top,
  breadcrumbs,
  children,
}: ShopPageLayoutProps): JSX.Element {
  return (
    <BaseLayout
      header={
        <TopAppBar
          helpId={helpId}
          sideMenuContents={
            <ShopSideMenu current={current} showMainMenuItems />
          }
        />
      }
      mainTop={breadcrumbs}
      mainMenu={
        <MainMenu current="shop" workspaceUid={workspaceUid} shopId={shopId} />
      }
      side={<ShopSideMenu current={current} />}
    >
      {children}
    </BaseLayout>
  );
}
