import { Button, Grid, Paper, TextField } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import HomeRoudedIcon from '@material-ui/icons/HomeRounded';
import React, { ChangeEvent, FormEvent, useState } from 'react';
import useReactRouter from 'use-react-router';
import { activate } from '../../api/auth/cognito';
import {
  FullscreenLoading,
  Head,
  Main,
  Root,
  TopAppBar,
  TopAppBarSpacer,
} from '../../components/Shared';
import { useStyles } from '../../components/Styles';

interface Form {
  email: string;
  code: string;
}

export default function InvitationActivationPage() {
  const { history, location, match } = useReactRouter();
  const url = new URL(document.URL);
  const email = url.searchParams.get('email');
  const token = url.searchParams.get('token');
  const [openFullscreenLoading, setOpenFullscreenLoading] = useState(false);

  const [form, setForm] = useState<Form>({
    email: email || '',
    code: '',
  });
  const classes = useStyles();

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    setOpenFullscreenLoading(true);
    if (form.email && form.code) {
      activate(form.email, form.code, (err, result) => {
        setOpenFullscreenLoading(false);
        if (err) {
          if (err.name == 'CodeMismatchException') {
            alert(
              '検証コードが一致しません。\n検証コードを確認してもう１度実行してください。'
            );
          } else {
            alert(err.message);
          }
          return;
        }
        // アクティベーション成功の場合、
        // ユーザー登録APIを叩いてDBにユーザー情報をコピーする TODO
        alert(
          'メールアドレスの検証が完了しました。\n登録したメールアドレスとパスワードでログインしてください。'
        );
        history.push(`/signin`);
      });
    }
  };

  const handleChangeEmail = (e: ChangeEvent<HTMLInputElement>) => {
    form.email = e.target.value;
    setForm(Object.assign({}, form));
  };

  const handleChangeCode = (e: ChangeEvent<HTMLInputElement>) => {
    form.code = e.target.value;
    setForm(Object.assign({}, form));
  };

  return (
    <Root>
      <Head title="メールアドレスの検証" />
      <TopAppBar />
      <Main>
        <TopAppBarSpacer />
        <Container maxWidth="lg">
          <Grid container spacing={3} alignItems="center">
            <Grid item container xs={10} alignItems="center">
              <HomeRoudedIcon />
              <h2>メールアドレスの検証</h2>
            </Grid>
          </Grid>
          <Paper className={classes.paper}>
            <div>
              {email}{' '}
              宛に「Locaop(ロカオプ)予約検証コード」という件名のメールで検証コードを送信済みです。検証コードを入力してください。
              <br />
              メールが見つからない場合、メールソフトの迷惑メールフォルダも念の為ご確認ください。
            </div>
            <form onSubmit={handleSubmit}>
              <TextField
                label="検証コード"
                placeholder="メールで届いた6桁の検証コードを入力してください。"
                type="number"
                name="code"
                value={form.code}
                onChange={handleChangeCode}
                margin="normal"
                fullWidth
                required
              />
              <Grid container spacing={3} alignItems="center">
                <Grid item container xs={6} alignItems="center"></Grid>
                <Grid
                  item
                  container
                  xs={6}
                  alignItems="center"
                  justify="flex-end"
                >
                  <Button type="submit" variant="contained" color="primary">
                    &nbsp;検証&nbsp;
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Paper>
          <FullscreenLoading open={openFullscreenLoading} />
        </Container>
      </Main>
    </Root>
  );
}
