/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { Container, Grid } from '@material-ui/core';
import AccountCircle from '@material-ui/icons/AccountCircle';
import useReactRouter from 'use-react-router';
import useConfirmEmail from '../../api/use-confirm-email';
import LinkRouter from '../../components/LinkRouter';
import {
  Head,
  Main,
  Root,
  TopAppBar,
  TopAppBarSpacer,
} from '../../components/Shared';

const styles = {
  tab: css({
    textAlign: 'left',
    background: 'white',
    borderBottom: `1px solid rgba(0, 0, 0, 0.12)`,
  }),
  paper: css({
    padding: 16,
  }),
  passwordInput: css({
    marginTop: 16,
    marginBottom: 8,
  }),
  errorText: css({
    color: '#f44336',
    margin: 0,
    marginTop: 3,
    fontSize: '0.75rem',
    textAlign: 'left',
    fontWeight: 400,
    lineHeight: 1.66,
    letterSpacing: '0.0333em',
  }),
  mailAddress: css({
    fontWeight: 'bold',
    marginLeft: 10,
  }),
};

export default function ChangeEmailConfirmPage(): JSX.Element {
  const token = new URLSearchParams(window.location.search).get('token');
  const { newEmailAddress, errorMessage, loading } = useConfirmEmail(
    token ?? ''
  );
  const { history } = useReactRouter();
  if (!token) {
    history.push('/profile');
  }

  return (
    <Root>
      <Head title="メール変更確認" />
      <TopAppBar />
      <Main>
        <TopAppBarSpacer />
        <Container maxWidth="lg">
          <Grid container spacing={3} alignItems="center">
            <Grid item container xs={6} alignItems="center">
              <AccountCircle />
              <h2>メールアドレス変更</h2>
            </Grid>
          </Grid>
          {!loading && (newEmailAddress || errorMessage) && (
            <>
              {errorMessage ? (
                <p>{errorMessage}</p>
              ) : (
                <>
                  <p>メールアドレスの変更が完了しました。</p>
                  <p>
                    新しいメールアドレス:
                    <span css={styles.mailAddress}>{newEmailAddress}</span>
                  </p>
                </>
              )}
              <LinkRouter to="/profile">プロフィール編集画面へ</LinkRouter>
            </>
          )}
        </Container>
      </Main>
    </Root>
  );
}
