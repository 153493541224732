/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { Menu, MenuItem } from '@material-ui/core';
import { MouseEvent, ReactNode, useState } from 'react';
import { TiArrowSortedDown } from 'react-icons/ti';
import { Link } from 'react-router-dom';
import useReactRouter from 'use-react-router';
import { Shop } from '../../@interfaces/shop';
import useShops from '../../api/use-shops';
import useCurrentWorkspace from '../../context/use-current-workspace';
import { buttonReset } from '../../features/side-menu/styles';
import { globalColors } from '../../styles/globalColors';

const styles = {
  link: css`
    color: #000;
    white-space: normal;
  `,
  selectButton: css`
    color: ${globalColors.accent2};
    display: flex;
    align-items: center;
  `,
};

export const breadcrumbShopNameWrapper = css`
  display: flex;
  align-items: center;
  gap: 2px;
`;

type BreadcrumbLinkItemProps = {
  to: string;
  children: ReactNode;
};

export function BreadcrumbLinkItem({
  to,
  children,
}: BreadcrumbLinkItemProps): JSX.Element {
  return (
    <Link css={styles.link} to={to}>
      {children}
    </Link>
  );
}

type BreadcrumbTextItemProps = {
  children: ReactNode;
};

export function BreadcrumbTextItem({
  children,
}: BreadcrumbTextItemProps): JSX.Element {
  return <span css={styles.link}>{children}</span>;
}

type BreadCrumbWorkspaceItemProps = {
  workspaceUid: string;
};

export function BreadcrumbWorkspaceItem({
  workspaceUid,
}: BreadCrumbWorkspaceItemProps): JSX.Element {
  const workspace = useCurrentWorkspace(workspaceUid);

  return (
    <BreadcrumbLinkItem to={`/a/${workspaceUid}/`}>
      {workspace?.name}
    </BreadcrumbLinkItem>
  );
}

type BreadCrumbShopItemProps = {
  workspaceUid: string;
  shop: Shop | undefined;
  noLink?: boolean;
};

export function BreadcrumbShopItem({
  workspaceUid,
  shop,
  noLink = false,
}: BreadCrumbShopItemProps): JSX.Element | null {
  if (shop === undefined) {
    return null;
  }

  return (
    <div css={breadcrumbShopNameWrapper}>
      {noLink ? (
        <BreadcrumbTextItem>{shop?.name}</BreadcrumbTextItem>
      ) : (
        <BreadcrumbLinkItem to={`/a/${workspaceUid}/shops/${shop.id}`}>
          {shop.name}
        </BreadcrumbLinkItem>
      )}
      <ShopSelectMenuButton workspaceUid={workspaceUid} />
    </div>
  );
}

type ShopSelectMenuButtonProps = {
  workspaceUid: string;
};

function ShopSelectMenuButton({
  workspaceUid,
}: ShopSelectMenuButtonProps): JSX.Element {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <button css={[buttonReset, styles.selectButton]} onClick={handleClick}>
        <TiArrowSortedDown size={24} />
      </button>
      {anchorEl !== null && (
        <ShopSelectMenu
          workspaceUid={workspaceUid}
          handleClose={handleClose}
          anchorEl={anchorEl}
        />
      )}
    </>
  );
}

type ShopSelectMenuProps = {
  workspaceUid: string;
  handleClose: () => void;
  anchorEl: HTMLElement | null;
};

function ShopSelectMenu({
  workspaceUid,
  handleClose,
  anchorEl,
}: ShopSelectMenuProps): JSX.Element {
  const { shops, isLoadingShops } = useShops(workspaceUid);
  const { history } = useReactRouter();

  return (
    <Menu
      id="simple-menu"
      anchorEl={anchorEl}
      keepMounted
      open
      onClose={handleClose}
    >
      {isLoadingShops ? (
        <div>読み込み中...</div>
      ) : (
        shops.map((shop) => {
          const path = `/a/${workspaceUid}/shops/${shop.id}`;
          return (
            <MenuItem
              key={shop.id}
              onClick={() => {
                history.push(path);
                handleClose();
              }}
            >
              <BreadcrumbTextItem>{shop.name}</BreadcrumbTextItem>
            </MenuItem>
          );
        })
      )}
    </Menu>
  );
}
