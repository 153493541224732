/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@material-ui/core';
import React from 'react';

type FormAlertDialogProps = {
  isShowAlert: boolean;
  setIsShowAlert: (isShowAlert: boolean) => void;
  addItems: string[];
  isExistsField: boolean;
};

export const FormAlertDialog = ({
  isShowAlert,
  setIsShowAlert,
  addItems,
  isExistsField,
}: FormAlertDialogProps): JSX.Element => {
  return (
    <Dialog open={isShowAlert}>
      <DialogTitle
        css={css`
          font-weight: bold;
        `}
      >
        お知らせ
      </DialogTitle>
      {
        <DialogContent>
          {addItems.length > 0 && (
            <Typography>
              {addItems.map((item) => '【' + item + '】')}
              の項目を自動追加しました。
              <br />
              これらの項目は保存されるまで反映されません。
            </Typography>
          )}
          {isExistsField && (
            <Typography
              css={css`
                margin-top: ${addItems.length > 0 ? '16px' : '0'};
              `}
            >
              画面下部にある「項目を追加...」ボタンから
              <br />
              他のコースの項目も選択できます。
            </Typography>
          )}
        </DialogContent>
      }
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            setIsShowAlert(false);
          }}
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};
