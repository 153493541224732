/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { Button, Paper } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import chroma from 'chroma-js';
import { useSnackbar } from 'notistack';
import { Fragment, useMemo, useState } from 'react';
import useReactRouter from 'use-react-router';
import { ICourseFormSetting } from '../../../@interfaces/course-form-setting';
import { RichReservation } from '../../../@interfaces/reservation';
import { ResourceSchedules } from '../../../@interfaces/resource/apis';
import { assignedTypeLabels } from '../../../@interfaces/resource/reservation-resource';
import { Resource } from '../../../@interfaces/resource/resource';
import useReservationTimeTable from '../../../api/use-reservation-time-table';
import useShopCourses from '../../../api/use-shop-courses';
import LinkRouter from '../../../components/LinkRouter';
import {
  FieldResponse,
  isSpecialFieldType,
  SpecialFieldType,
} from '../../../core/types/reservation-form-types';
import {
  TimeRange,
  toTimeByTimeValue,
  toTimeStringByTime,
  toTimeValue,
} from '../../../core/types/reservation-types';
import {
  AUTO_RELOAD_RESERVATIONS_INTERVAL,
  useAutoReloadReservations,
} from '../../../hooks/use-auto-reload-reservations';
import { useInterval } from '../../../hooks/use-interval';
import { findSpecialFieldValue } from '../../../models/reservation';
import { THEME_COLORS } from '../../../models/theme';
import {
  DEFAULT_RESOURCE_CELL_HEIGHT,
  RESOURCE_HEADER_HEIGHT,
  useShopPageDisplaySetting,
} from '../../../pages/shop/ShopPageDisplySetting';
import { range } from '../../../utils/numbers';
import { truncate } from '../../../utils/string';
import { useResourceSchedules } from '../api/getResourceSchedules';
import { useUpdateResourceSchedules } from '../api/updateResourceSchedules.ts';
import {
  buildTimelineRange,
  buildTimelines,
} from '../models/resource-timeline';
import { ResourceScheduleEditDialog } from './ResourceScheduleList';

type PageParams = {
  workspaceUid: string;
  shopId: string;
};

const eventBorderColors = THEME_COLORS;
const eventBackgroundColors = eventBorderColors.map((c) =>
  chroma(c).brighten(2).hex()
);

type ResourceTimelineProps = {
  shopId: string;
  startDate: string;
  endDate: string;
  reservations: RichReservation[];
  formSettings: ICourseFormSetting[];
  workspaceUid: string;
};

export const ResourceTimeline: React.VFC<ResourceTimelineProps> = (props) => {
  const {
    shopId,
    startDate,
    endDate,
    reservations,
    formSettings,
    workspaceUid,
  } = props;

  const { shopCourses } = useShopCourses(shopId);
  const [displaySetting] = useShopPageDisplaySetting(shopId);

  const timeWidth = 80;
  const cellWidth = displaySetting.resourceTimeLine.cellWidth;
  const cellHeight = displaySetting.resourceTimeLine.cellHeight;
  const fontSize = displaySetting.resourceTimeLine.fontSize;

  const heightRatio = cellHeight / DEFAULT_RESOURCE_CELL_HEIGHT;

  const styles = {
    container: css({
      position: 'relative',
    }),
    table: css({
      borderCollapse: 'separate',
      borderSpacing: 0,
    }),
    thFirst: css({
      height: `${RESOURCE_HEADER_HEIGHT}px`,
      position: 'sticky',
      top: 76,
      zIndex: 1,
      backgroundColor: '#fff',
      border: 'solid 1px #ccc',
    }),
    th: css({
      height: `${RESOURCE_HEADER_HEIGHT}px`,
      minWidth: `${cellWidth}px`,
      position: 'sticky',
      top: 76,
      zIndex: 1,
      backgroundColor: '#fff',
      border: 'solid 1px #ccc',
    }),
    timeHeader: css({
      height: `${cellHeight}px`,
      width: `${timeWidth}px`,
      minWidth: `${timeWidth}px`,
      verticalAlign: 'top',
      border: 'solid 1px #ccc',
    }),
    td: css({
      height: `${cellHeight}px`,
      width: `${cellWidth}px`,
      minWidth: `${cellWidth}px`,
      border: 'solid 1px #ccc',
    }),
    availableTime: css({
      position: 'absolute',
      border: 'solid 1px #aaa',
      height: '28px',
      width: `${cellWidth}px`,
      minWidth: `${cellWidth}px`,
      fontSize: '9px',
      borderRadius: '3px 3px',
    }),
    event: css({
      color: '#000',
      position: 'absolute',
      border: 'solid 2px #aaa',
      height: '28px',
      overflowWrap: 'break-word',
      width: `${cellWidth - 4}px`,
      minWidth: `${cellWidth - 4}px`,
      fontSize: `${fontSize}px`,
      borderRadius: '3px 3px',
      opacity: 0.75,
    }),
    alert: css({
      color: '#fff',
      backgroundColor: 'red',
      padding: '2px 5px',
    }),
  };
  const { data: resourceSchedulesData, reload: reloadResourceSchedules } =
    useResourceSchedules(shopId, startDate, endDate);

  const { autoReloadReservations } = useAutoReloadReservations();

  useInterval(
    reloadResourceSchedules,
    AUTO_RELOAD_RESERVATIONS_INTERVAL,
    autoReloadReservations.autoReload
  );
  const { history, location, match } = useReactRouter<PageParams>();

  const { reservationTimeTable, isLoadingReservationTimeTable } =
    useReservationTimeTable(shopId, startDate, endDate);

  const tableData = reservationTimeTable?.tables.flatMap((d) =>
    d.dates.flatMap((t) =>
      t.slots.map(
        (s) =>
          toTimeValue(s.slot.timeRange.end) -
          toTimeValue(s.slot.timeRange.start)
      )
    )
  );
  const minutesRequired = tableData?.length ? Math.min(...tableData) : 60;

  const [startHour, endHour] = useMemo(() => {
    return buildTimelineRange(
      resourceSchedulesData?.schedules || [],
      resourceSchedulesData?.patterns || []
    );
  }, [resourceSchedulesData]);

  const timelines = useMemo(() => {
    const resources = resourceSchedulesData?.schedules || [];
    const reservedResources = resourceSchedulesData?.reservedResources || [];
    return buildTimelines(resources, reservedResources);
  }, [resourceSchedulesData]);

  const handleMouseLeave = () => {
    setReservableDisplay(false);
  };

  const handleMouseOver = () => {
    if (reservableDisplay) setReservableDisplay(false);
  };

  const { enqueueSnackbar } = useSnackbar();
  const [selectedResource, setSelectedResource] = useState<Resource>();
  const [open, setOpen] = useState(false);
  const updateResourceSchedule = useUpdateResourceSchedules();
  const handleUpdateResourceSchedule = (
    timeRanges: TimeRange[] | null,
    patternId: number | null
  ) => {
    if (!resourceSchedulesData) return;

    const schedules = resourceSchedulesData.schedules.map((schedule) => {
      if (schedule.resource.id === selectedResource?.id) {
        const basedData = {
          ...schedule.dates[0],
          date: `${resourceSchedulesData.dateRange.start.year}-${resourceSchedulesData.dateRange.start.month}-${resourceSchedulesData.dateRange.start.date}`,
          resourceId: selectedResource.id,
          schedule: patternId
            ? undefined
            : {
                timeRanges: timeRanges,
              },
          resourceSchedulePatternId: patternId ? patternId : undefined,
        };

        return {
          ...schedule,
          dates: [basedData],
        };
      } else {
        return { ...schedule };
      }
    });

    updateResourceSchedule(
      shopId,
      resourceSchedulesData.dateRange,
      schedules as ResourceSchedules[]
    )
      .then(() => {
        reloadResourceSchedules();
        enqueueSnackbar(`稼働表を保存しました。`, { variant: 'success' });
      })
      .catch((e) => {
        enqueueSnackbar('稼働表の保存に失敗しました。', { variant: 'error' });
        console.error(e);
      });
  };

  const initialTimeRanges = () => {
    const resourceSchedule = timelines.timelines.find((timeline) => {
      return timeline.schedules.resource.id === selectedResource?.id;
    });
    const date = resourceSchedule?.schedules.dates;
    return date && date.length > 0
      ? date[0].schedule?.timeRanges || undefined
      : undefined;
  };

  const initialSelectedPatternId = () => {
    const resourceSchedule = timelines.timelines.find((timeline) => {
      return timeline.schedules.resource.id === selectedResource?.id;
    });
    const date = resourceSchedule?.schedules.dates;
    return date && date.length > 0
      ? date[0].resourceSchedulePatternId || undefined
      : undefined;
  };

  const buildThead = () => {
    return (
      <thead>
        <tr>
          <th css={styles.thFirst}></th>
          {timelines.timelines.map((timeline) => (
            <th
              onMouseOver={() => {
                handleMouseOver(); // reservableエリアと重なっているため
              }}
              key={timeline.schedules.resource.id}
              css={styles.th}
              colSpan={timeline.columns.length}
            >
              <div
                css={css`
                  font-size: 13px;
                `}
              >
                {timeline.schedules.resource.name}
              </div>
              {timeline.columns.length >= 2 && (
                <span css={styles.alert}>重複あり</span>
              )}
              <Button
                color="primary"
                variant="text"
                css={css`
                  font-size: 10px;
                  padding: 4px;
                  // YOYAKU-839
                  @media (max-width: 599px) {
                    min-height: inherit;
                  } ;
                `}
                onClick={() => {
                  setOpen(true);
                  setSelectedResource(timeline.schedules.resource);
                }}
              >
                <EditIcon style={{ fontSize: '10px' }} />
                <span>稼働変更</span>
              </Button>
            </th>
          ))}
          <th css={styles.thFirst}></th>
        </tr>
      </thead>
    );
  };

  const buildTbody = () => {
    const hours = range(startHour, endHour);
    return (
      <tbody>
        {hours.map((hour) => (
          <Fragment key={hour}>
            <tr>
              <th css={styles.timeHeader}>{hour}:00</th>
              {timelines.allColumns.map((_, i) => (
                <td key={i} css={styles.td}></td>
              ))}
              <th css={styles.timeHeader}>{hour}:00</th>
            </tr>
            <tr>
              <th css={styles.timeHeader}></th>
              {timelines.allColumns.map((_, i) => (
                <td key={i} css={styles.td}></td>
              ))}
              <th css={styles.timeHeader}></th>
            </tr>
          </Fragment>
        ))}
      </tbody>
    );
  };

  const [roomId, setRoomId] = useState(1);
  const [reservableY, setReservableY] = useState(0);
  const [reservableDisplay, setReservableDisplay] = useState(false);
  const [reservableTimeValue, setReservableTimeValue] = useState(0);

  const handleMouseMove = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    setReservableDisplay(true);
    const hours = range(startHour, endHour);
    // 予約枠要素ののtop位置を取得
    // e.currentTarget.style.top はテーブルの上端からの距離のため、RESOURCE_HEADER_HEIGHTを引く
    const targetSlotTop =
      parseInt(e.currentTarget.style.top.slice(0, -2)) - RESOURCE_HEADER_HEIGHT;
    // イベントが発生したタイムライン上のy座標を取得
    // e.nativeEvent.offsetYは対象要素内の上端からの距離のため、対象要素のtopを足す
    const y = targetSlotTop + e.nativeEvent.offsetY;
    // Y座標をタイムライン上の開始時間からの合計分に変換
    // 1分あたりの高さを2pxとしているため、2で割る
    // また、heightRatioでZoomレベルの変換を行う
    const minutesFromStart = Math.floor(y / 2) / heightRatio;
    // 分を時間のindex値に変換
    const hourIndex = Math.floor(minutesFromStart / 60);
    // indexを時間に変換
    const targetHour = hours[hourIndex];
    // タイムライン上の開始時間からの合計分を分に変換
    const targetMin =
      minutesRequired * Math.floor((minutesFromStart % 60) / minutesRequired);
    // 0時からの経過時間を分に変換
    setReservableTimeValue(targetHour * 60 + targetMin);
    // 予約可能枠のtop位置を設定
    setReservableY(
      Math.floor(y / (minutesRequired * 2 * heightRatio)) *
        (minutesRequired * 2 * heightRatio)
    );
    if (roomId != Number(e.currentTarget.id))
      setRoomId(Number(e.currentTarget.id));
  };

  const handleClickReservableCell = () => {
    const resourceId = timelines.timelines[roomId - 1].schedules.resource.id;
    const params = new URLSearchParams({
      resourceId: String(resourceId),
      date: startDate,
      time: toTimeStringByTime(toTimeByTimeValue(reservableTimeValue)),
    });
    const url = `/a/${workspaceUid}/shops/${shopId}/reservations/new?${params.toString()}`;
    history.push(url);
  };

  const reservableResource = () => {
    const addWidth = (roomId - 1) * cellWidth;
    const display = reservableDisplay ? 'block' : 'none';
    return (
      <div
        onMouseLeave={() => {
          handleMouseLeave();
        }}
        onClick={() => {
          handleClickReservableCell();
        }}
        css={styles.event}
        style={{
          display: display,
          top: reservableY + RESOURCE_HEADER_HEIGHT + 2, // この値を設定して表示位置決める
          left: timeWidth + addWidth + 3,
          height: minutesRequired * 2 * heightRatio - 2,
          cursor: 'pointer',
          borderColor: eventBorderColors[5],
          backgroundColor: eventBackgroundColors[5],
        }}
      ></div>
    );
  };

  const buildEvents = () => {
    let columnIndex = 0;
    const resourceSchedules = timelines.timelines.flatMap(
      (timeline, timelineIndex) => {
        const date = timeline.schedules.dates[0];
        if (!date || (!date.schedule && !date.resourceSchedulePatternId)) {
          // resource.resourceSchedulePatternIdが設定されている場合は、patternのtimeRangesを使用するためreturnしない
          if (!timeline.schedules.resource.resourceSchedulePatternId) {
            columnIndex += timeline.columns.length; // 次の開始位置を現在の列数分足し込み
            return [];
          }
        }
        const timeRanges: TimeRange[] =
          date?.schedule?.timeRanges ||
          resourceSchedulesData?.patterns.find(
            (pattern) => pattern.id === date?.resourceSchedulePatternId
          )?.schedule.timeRanges ||
          resourceSchedulesData?.patterns.find(
            (pattern) =>
              pattern.id ===
              timeline.schedules.resource.resourceSchedulePatternId
          )?.schedule.timeRanges ||
          [];
        const left = timeWidth + cellWidth * columnIndex + 1;
        const width = cellWidth * timeline.columns.length;
        columnIndex += timeline.columns.length; // 次の開始位置を現在の列数分足し込み
        return timeRanges.map((timeRange, rangeIndex) => {
          const top =
            (toTimeValue(timeRange.start) - startHour * 60) * 2 * heightRatio +
            RESOURCE_HEADER_HEIGHT;
          const height =
            (toTimeValue(timeRange.end) - toTimeValue(timeRange.start)) *
            2 *
            heightRatio;
          return (
            <div
              key={`${timelineIndex}_${rangeIndex}`}
              onMouseMove={(e) => {
                handleMouseMove(e);
              }}
              id={String(columnIndex)}
              css={styles.availableTime}
              style={{
                top,
                left,
                width,
                height,
                backgroundColor: '#eef',
                border: 'solid 2px #673ab7',
                opacity: 0.8,
              }}
            ></div>
          );
        });
      }
    );

    const reservedResources = timelines.allColumns.flatMap(
      (column, columnIndex) => {
        return column.events.map((event, eventIndex) => {
          const reservedResource = event.reservedResource;
          const index = reservations.findIndex(
            (r) => r.id === reservedResource.reservationId
          );
          if (index === -1) return undefined;
          const reservation = reservations[index];

          const couseIndex = shopCourses.findIndex(
            (c) => c.id === reservation.courseId
          );
          const formSetting = formSettings.find(
            (fs) => fs.courseId === reservation.courseId
          );
          const collectFormResponseValues = () => {
            if (!reservation || !formSetting) {
              return [];
            }
            return formSetting.settingJson.fields.flatMap((field) => {
              // 名前、電話番号、メアドの場合は、customerの値を優先
              if (isSpecialFieldType(field.type)) {
                const value = findSpecialFieldValue(
                  reservation,
                  field.type as SpecialFieldType,
                  [formSetting]
                );
                return value ? [value] : [];
              } else {
                const formResponseField = reservation.formResponse.fields.find(
                  (fr) => fr.uid === field.uid
                );
                if (!formResponseField) {
                  return [];
                }
                const value = buildValueString(formResponseField, formSetting);
                return value ? [value] : [];
              }
            });
          };
          const desc = `${
            reservation?.courseName
          } / ${collectFormResponseValues().join(' / ')}`;
          const resourceNames = reservation?.selectedResources
            .map((sr) => {
              const assignedTypeLabel =
                sr.initialAssignedType === sr.assignedType
                  ? assignedTypeLabels[sr.initialAssignedType].text
                  : `${assignedTypeLabels[sr.assignedType].text}, 予約当初は${
                      assignedTypeLabels[sr.initialAssignedType].text
                    }`;
              return `${sr.resourceName}(${assignedTypeLabel})`;
            })
            .join('/');
          return (
            <LinkRouter
              key={`${columnIndex}_${eventIndex}`}
              to={`/a/${workspaceUid}/shops/${shopId}/reservations/${reservation?.id}`}
              css={css`
                vertical-align: middle;
              `}
            >
              <div
                onMouseOver={() => {
                  handleMouseOver();
                }}
                css={styles.event}
                style={{
                  top:
                    (toTimeValue(reservedResource.time) - startHour * 60) *
                      2 *
                      heightRatio +
                    RESOURCE_HEADER_HEIGHT +
                    2,
                  left: timeWidth + cellWidth * columnIndex + 1 + 2,
                  height:
                    reservedResource.minutesRequired * 2 * heightRatio - 2,
                  cursor: 'pointer',
                  borderColor:
                    eventBorderColors[couseIndex % eventBorderColors.length],
                  backgroundColor:
                    eventBackgroundColors[
                      couseIndex % eventBackgroundColors.length
                    ],
                }}
              >
                <div>{desc}</div>
                <div>{resourceNames}</div>
              </div>
            </LinkRouter>
          );
        });
      }
    );

    return (
      <>
        {resourceSchedules}
        {reservedResources}
      </>
    );
  };

  return (
    <>
      <Paper>
        <div css={styles.container}>
          <table css={styles.table}>
            {buildThead()}
            {buildTbody()}
          </table>
          {buildEvents()}
          {reservableResource()}
        </div>
      </Paper>
      {resourceSchedulesData && selectedResource && open && (
        <ResourceScheduleEditDialog
          open={open}
          patterns={resourceSchedulesData.patterns}
          initialTimeRanges={initialTimeRanges()}
          title={`${selectedResource.name}の稼働変更`}
          closeFunc={() => {
            setOpen(false);
          }}
          onUpdateResourceSchedule={handleUpdateResourceSchedule}
          initialSelectedPatternId={initialSelectedPatternId()}
        />
      )}
    </>
  );
};

const buildValueString = (
  fieldResponse: FieldResponse,
  formSetting?: ICourseFormSetting
) => {
  const field = formSetting?.settingJson.fields.find(
    (f) => f.uid === fieldResponse.uid
  );
  if (!field) {
    return fieldResponse.values.join(', ');
  }
  return (
    fieldResponse.values
      ?.map((value) => {
        if (field.type === 'number') {
          return `${field.name}: ${(value as string)?.toString()}`;
        } else if (typeof value === 'string') {
          return truncate(value, 100, '...');
        } else if (field.type === 'checkbox' || field.type === 'radio') {
          return (
            field.options.find((option) => option.uid === value.uid)?.text ||
            value
          );
        } else {
          return value;
        }
      })
      .join(', ') || ''
  );
};
