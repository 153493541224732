/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { Divider } from '@material-ui/core';
import AssignmentIcon from '@material-ui/icons/Assignment';
import BusinessIcon from '@material-ui/icons/Business';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import FlagIcon from '@material-ui/icons/Flag';
import HomeIcon from '@material-ui/icons/Home';
import LabelIcon from '@material-ui/icons/Label';
import LanguageIcon from '@material-ui/icons/Language';
import PaletteIcon from '@material-ui/icons/Palette';
import PeopleIcon from '@material-ui/icons/People';
import RecentActorsIcon from '@material-ui/icons/RecentActors';
import SettingsIcon from '@material-ui/icons/Settings';
import StorefrontIcon from '@material-ui/icons/Storefront';
import { FaLine } from 'react-icons/fa';
import useReactRouter from 'use-react-router';
import { ROLE_ADMIN } from '../../../context/GlobalStore';
import useCurrentWorkspace from '../../../context/use-current-workspace';
import { globalColors } from '../../../styles/globalColors';
import { useExpandedMenu } from '../hooks/use-expanded-menu';
import { sideMenuStyles } from '../styles';
import { SideMenuCategoryItem, SideMenuItem } from './SideMenuItem';
export type WorkspaceSideMenuItemId =
  | 'shops'
  | 'reports'
  | 'course'
  | 'theme'
  | 'member'
  | 'basic'
  | 'inflow-source'
  | 'label'
  | 'line'
  | 'crm'
  | 'custom-domain';

type WorkspaceSideMenuProps = {
  current: WorkspaceSideMenuItemId;
  showMainMenuItems?: boolean;
};

export function WorkspaceSideMenu({
  current,
  showMainMenuItems,
}: WorkspaceSideMenuProps): JSX.Element {
  const { match } = useReactRouter<{ workspaceUid: string }>();
  const { workspaceUid } = match.params;
  const workspace = useCurrentWorkspace(workspaceUid);
  const { isExpandedSidebar, expandedSidebarButton } = useExpandedMenu();
  const isAdmin = workspace?.role === ROLE_ADMIN;

  return (
    <>
      {expandedSidebarButton}

      <div
        css={[
          sideMenuStyles.sideMenu,
          !isExpandedSidebar && sideMenuStyles.hideSidebar,
        ]}
      >
        {showMainMenuItems && (
          <>
            <SideMenuItem
              label="ホーム"
              href={`/`}
              isCurrent={false}
              sideColor={globalColors.main}
              icon={<HomeIcon />}
            />
            <SideMenuItem
              label="ワークスペース"
              href={`/a/${workspaceUid}`}
              isCurrent={true}
              sideColor={globalColors.main}
              currentColor={globalColors.workspace}
              icon={<BusinessIcon />}
            />
            <Divider
              css={css`
                margin: 4px 0;
              `}
            />
          </>
        )}
        <SideMenuItem
          label="店舗一覧"
          href={`/a/${workspaceUid}/`}
          isCurrent={current === 'shops'}
          sideColor={globalColors.workspace}
          icon={<StorefrontIcon />}
        />
        <SideMenuItem
          label="集計"
          href={`/a/${workspaceUid}/shops/reports`}
          isCurrent={current === 'reports'}
          sideColor={globalColors.workspace}
          icon={<AssignmentIcon />}
        />
        {isAdmin && (
          <>
            <SideMenuItem
              label="コース設定"
              href={`/a/${workspaceUid}/settings/courses`}
              isCurrent={current === 'course'}
              sideColor={globalColors.workspace}
              icon={<FlagIcon />}
            />
            <SideMenuItem
              label={
                <>
                  デザインと
                  <br />
                  計測タグ設定
                </>
              }
              href={`/a/${workspaceUid}/settings/theme`}
              isCurrent={current === 'theme'}
              sideColor={globalColors.workspace}
              icon={<PaletteIcon />}
            />
            <SideMenuItem
              label="メンバー設定"
              href={`/a/${workspaceUid}/settings/member`}
              isCurrent={current === 'member'}
              sideColor={globalColors.workspace}
              icon={<PeopleIcon />}
            />
            <SideMenuItem
              label="基本設定"
              href={`/a/${workspaceUid}/settings/basic`}
              isCurrent={current === 'basic'}
              sideColor={globalColors.workspace}
              icon={<SettingsIcon />}
            />
            <SideMenuCategoryItem
              label="詳細設定"
              themeColor={globalColors.workspace}
            />
            <SideMenuItem
              label="流入元設定"
              href={`/a/${workspaceUid}/settings/inflow-sources`}
              isCurrent={current === 'inflow-source'}
              sideColor={globalColors.workspace}
              icon={<ExitToAppIcon />}
            />
            <SideMenuItem
              label="ラベル設定"
              href={`/a/${workspaceUid}/settings/labels`}
              isCurrent={current === 'label'}
              sideColor={globalColors.workspace}
              icon={<LabelIcon />}
            />
            <SideMenuItem
              label="LINE連携"
              href={`/a/${workspaceUid}/settings/line`}
              isCurrent={current === 'line'}
              sideColor={globalColors.workspace}
              icon={<FaLine size={24} />}
            />
            <SideMenuItem
              label="顧客管理設定"
              href={`/a/${workspaceUid}/settings/crm`}
              isCurrent={current === 'crm'}
              sideColor={globalColors.workspace}
              icon={<RecentActorsIcon />}
            />
            <SideMenuItem
              label="独自ドメイン"
              href={`/a/${workspaceUid}/settings/custom-domain`}
              isCurrent={current === 'custom-domain'}
              sideColor={globalColors.workspace}
              icon={<LanguageIcon />}
            />
          </>
        )}
      </div>
    </>
  );
}
