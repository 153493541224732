import axios, { AxiosResponse } from 'axios';
import { useContext } from 'react';
import useSWR from 'swr';
import { GetConversionDateResponse } from '../../../@interfaces/insight/api';
import {
  AggregationUnit,
  DateConversionAggregation,
} from '../../../@interfaces/insight/backend';
import { API_END_POINT } from '../../../api/api';
import { Store } from '../../../context/GlobalStore';

const fetcher = async (
  url: string,
  token: string,
  startDate: string,
  endDate: string,
  aggregationUnit: AggregationUnit,
  courseIds: string[],
  inflowSources: string[]
) => {
  return axios
    .get(url, {
      params: {
        startDate,
        endDate,
        aggregationUnit,
        courseIds,
        inflowSources,
      },
      headers: {
        Authorization: token,
      },
    })
    .then(
      (response: AxiosResponse<GetConversionDateResponse>) => response.data
    );
};

export default function useInsightConversionDate(
  shopId: string,
  startDate: string,
  endDate: string,
  aggregationUnit: AggregationUnit,
  courseIds: string[],
  inflowSources: string[],
  shouldFetch = true
): {
  results: DateConversionAggregation[];
  isLoading: boolean;
} {
  const { globalState } = useContext(Store);

  const { data, isLoading } = useSWR(
    globalState.session?.idToken && shouldFetch
      ? [
          `${API_END_POINT}/app/shops/${shopId}/insight/conversion/date`,
          globalState.session.idToken,
          startDate,
          endDate,
          aggregationUnit,
          courseIds,
          inflowSources,
        ]
      : null,
    async ([
      url,
      token,
      startDate,
      endDate,
      aggregationUnit,
      courseIds,
      inflowSources,
    ]) =>
      fetcher(
        url,
        token,
        startDate,
        endDate,
        aggregationUnit,
        courseIds,
        inflowSources
      )
  );

  return {
    results: data?.results ?? [],
    isLoading,
  };
}
