/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { Card, CardActions, CardContent } from '@material-ui/core';
import dayjs from 'dayjs';
import { useMemo } from 'react';
import { FaArrowRight } from 'react-icons/fa';
import {
  AggregationUnit,
  TargetDateType,
} from '../../../@interfaces/insight/backend';
import LinkRouter from '../../../components/LinkRouter';
import useInsightConversionDate from '../api/use-insight-conversion-date';
import {
  calcConversionPercent,
  styles,
  sumConversionAggregation,
} from '../utils/insight-utils';
import { CardSpinner } from './CardSpinner';
import { ConversionFunnelGraph } from './ConversionFunnelGraph';

type ConversionSummaryProps = {
  shopId: string;
  primaryStartDate: dayjs.Dayjs;
  primaryEndDate: dayjs.Dayjs;
  comparisonStartDate: dayjs.Dayjs;
  comparisonEndDate: dayjs.Dayjs;
  aggregationUnit: AggregationUnit;
  targetDateType: TargetDateType;
};

export const ConversionSummary = ({
  shopId,
  primaryStartDate,
  primaryEndDate,
  aggregationUnit,
}: ConversionSummaryProps): JSX.Element => {
  const { results: dateResults, isLoading } = useInsightConversionDate(
    shopId,
    primaryStartDate.format('YYYY-MM-DD'),
    primaryEndDate.format('YYYY-MM-DD'),
    aggregationUnit,
    [],
    []
  );

  const totalAggregation = useMemo(() => {
    return sumConversionAggregation(dateResults);
  }, [dateResults]);

  const buildContent = () => {
    if (isLoading) {
      return <CardSpinner />;
    }

    return (
      <>
        <div
          css={css`
            display: flex;
            align-items: center;
            flex-wrap: wrap;
          `}
        >
          <p
            css={css`
              font-size: 60px;
              line-height: 1;
              margin: 0;
            `}
          >
            {
              calcConversionPercent(
                totalAggregation.reservedTotal,
                totalAggregation.courseSelectViewedTotal
              ).displayValue
            }
          </p>
        </div>
        <div
          css={css`
            padding: 1em 2em 0 2em;
          `}
        >
          <ConversionFunnelGraph totalAggregation={totalAggregation} />
        </div>
      </>
    );
  };

  return (
    <>
      <Card
        css={css`
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        `}
      >
        <CardContent>
          <h3
            css={css`
              font-size: 24px;
              font-weight: normal;
              margin: 0.5em 0;
              padding: 0;
            `}
          >
            予約完了率&nbsp;
            <span
              css={css`
                font-size: 14px;
                display: inline-block;
              `}
            >
              2024年7月12日10時以降の計測データを表示しています
            </span>
          </h3>

          {buildContent()}
        </CardContent>
        <CardActions
          css={[
            css`
              justify-content: flex-end;
              padding: 16px;
            `,
            styles.noPrint,
          ]}
        >
          <LinkRouter
            to={`insight/conversion${window.location.search}`}
            css={css`
              line-height: 1;
              display: flex;
              align-items: center;
              gap: 8px;
            `}
          >
            予約完了率の詳細を表示 <FaArrowRight />
          </LinkRouter>
        </CardActions>
      </Card>
    </>
  );
};
