import axios from 'axios';
import { useContext, useEffect, useRef, useState } from 'react';
import { Store } from '../context/GlobalStore';
import { API_END_POINT } from './api';

export default function useShopReservations(
  shopId: string,
  startDate: string,
  endDate: string,
  startTime: number | null,
  endTime: number | null
) {
  const { globalState } = useContext(Store);
  const [reservations, setReservations] = useState([]);
  const [inflowSources, setInflowSources] = useState([]);
  const [labels, setLabels] = useState([]);
  const [settingJsons, setSettingJsons] = useState([]);
  const [resources, setResources] = useState([]);
  const [enableCrm, setEnableCrm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const lastTimeoutId = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    const url = `${API_END_POINT}/app/shops/${shopId}/reservations-excel`;
    const params = { startDate, endDate, startTime, endTime };
    const headers = { Authorization: globalState.session?.idToken };

    setIsLoading(true);

    if (lastTimeoutId.current) {
      clearTimeout(lastTimeoutId.current);
    }
    lastTimeoutId.current = setTimeout(() => {
      axios
        .get(url, { params, headers })
        .then((response) => {
          const {
            reservations,
            inflowSources,
            labels,
            settingJsons,
            resources,
            enableCrm,
          } = response.data;
          setReservations(reservations);
          setInflowSources(inflowSources);
          setLabels(labels);
          setSettingJsons(settingJsons);
          setResources(resources);
          setEnableCrm(enableCrm);
        })
        .finally(() => {
          setIsLoading(false);
          lastTimeoutId.current = null;
        });
    }, 500);
  }, [
    endDate,
    globalState.session?.idToken,
    shopId,
    startDate,
    startTime,
    endTime,
  ]);

  return {
    reservations,
    inflowSources,
    labels,
    settingJsons,
    resources,
    enableCrm,
    isLoadingReservations: isLoading,
  };
}
