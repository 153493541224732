/** @jsxImportSource @emotion/react */

import {
  Breadcrumbs,
  Button,
  Grid,
  InputAdornment,
  Paper,
  TextField,
} from '@material-ui/core';
import LockIcon from '@material-ui/icons/Lock';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import React, { ChangeEvent, useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import useReactRouter from 'use-react-router';
import { ROLE_ADMIN } from '../../../@interfaces/workspace-account';
import { API_END_POINT } from '../../../api/api';
import useShop from '../../../api/use-shop';
import {
  BreadcrumbLinkItem,
  BreadcrumbShopItem,
  BreadcrumbTextItem,
  BreadcrumbWorkspaceItem,
} from '../../../components/breadcrumb/BreadcrumbItem';
import { commonCss } from '../../../components/common-css';
import { ShopPageLayout } from '../../../components/layouts/ShopPageLayout';
import { PageTitleAndDescription } from '../../../components/PageTitleAndDescription';
import {
  FullscreenLoading,
  Head,
  Main,
  Root,
} from '../../../components/Shared';
import Spinner from '../../../components/Spinner';
import { useStyles } from '../../../components/Styles';
import { Store } from '../../../context/GlobalStore';
import { globalColors } from '../../../styles/globalColors';
import { helps } from '../../../utils/helps';

interface PageParams {
  workspaceUid: string;
  shopId: string;
}

type Form = {
  uid: string;
  name: string;
};

export default function ShopThemeSettingPage(props: any) {
  const { globalState, setGlobalState } = useContext(Store);
  const { history, location, match } = useReactRouter<PageParams>();
  const { workspaceUid, shopId } = match.params;
  const validationContext = useForm();
  const { handleSubmit, register } = validationContext;
  const styles = useStyles();
  const workspace = globalState.signedUser?.workspaces.find(
    (d) => d.uid === workspaceUid
  );
  const { shop, isLoadingShop } = useShop(shopId);

  const [form, setForm] = useState<Form>({
    uid: '',
    name: '',
  });
  const [lockedId, setLockedId] = useState(true);
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (!shop) {
      return;
    }
    setForm({
      uid: shop.uid,
      name: shop.name,
    });
  }, [JSON.stringify(shop)]);

  const handleChangeUid = (e: ChangeEvent<HTMLInputElement>) => {
    const uid = e.target.value;
    setForm({ ...form, uid });
  };

  const handleChangeName = (e: ChangeEvent<HTMLInputElement>) => {
    const name = e.target.value;
    setForm({ ...form, name });
  };

  const handleClickEditId = () => {
    if (
      !window.confirm(
        '店舗IDを変更するとWeb予約画面のURLが変わるためリンク等の書き換えが必要です。店舗IDを変更してもよろしいですか？'
      )
    ) {
      return;
    }
    setLockedId(false);
  };

  const handleClickDelete = () => {
    if (!globalState.session?.idToken) {
      return;
    }
    if (
      prompt('この店舗を削除するには「削除」と入力してください。') === '削除'
    ) {
      setOpenBackdrop(true);
      axios
        .delete(`${API_END_POINT}/app/shops/${shopId}`, {
          headers: {
            Authorization: globalState.session?.idToken,
          },
        })
        .then(() => {
          enqueueSnackbar('店舗を削除しました。', { variant: 'success' });
          history.push(`/a/${workspaceUid}/`);
        })
        .catch((e) => {
          alert('削除に失敗しました。');
        })
        .finally(() => {
          setOpenBackdrop(false);
        });
    }
  };

  const handleSubmitForm = (e: React.FormEvent) => {
    const json = {
      uid: form.uid,
      name: form.name,
    };
    setOpenBackdrop(true);
    axios
      .put(`${API_END_POINT}/app/shops/${shopId}`, json, {
        headers: {
          Authorization: globalState.session?.idToken,
        },
      })
      .then(() => {
        enqueueSnackbar('設定を保存しました。', { variant: 'success' });
      })
      .catch((e) => {
        if (e.response && e.response.status === 409) {
          enqueueSnackbar(
            '同一IDの店舗が存在するため、保存できませんでした。',
            { variant: 'error' }
          );
        } else {
          enqueueSnackbar('設定が保存できませんでした。', { variant: 'error' });
        }
        setOpenBackdrop(false);
      })
      .finally(() => {
        setOpenBackdrop(false);
      });
  };

  const buildRightNavigationButtons = () => {
    return (
      <>
        <Button
          css={commonCss.button.right}
          type="submit"
          variant="contained"
          color="primary"
        >
          &nbsp;保存&nbsp;
        </Button>
      </>
    );
  };

  const buildContents = () => {
    if (isLoadingShop) {
      return <Spinner loading={true} />;
    }
    return (
      <form onSubmit={handleSubmit(handleSubmitForm)}>
        <h3>基本設定</h3>
        <Paper className={styles.paper}>
          <TextField
            label="店舗ID"
            helperText="店舗の一意なIDを設定します(例: 新宿店ならshinjuku)。半角英数小文字、ハイフン、アンダーバーが使用できます。Web予約画面のURLに使用されます。"
            value={form.uid}
            onChange={handleChangeUid}
            InputProps={{
              startAdornment: lockedId ? (
                <InputAdornment position="start">
                  <LockIcon />
                </InputAdornment>
              ) : null,
            }}
            disabled={lockedId}
            required
            inputProps={{ maxLength: 20, pattern: '^[a-z0-9_-]+$' }}
          />
          {lockedId ? (
            <Button
              type="button"
              variant="contained"
              color="default"
              onClick={handleClickEditId}
            >
              店舗IDを編集する
            </Button>
          ) : null}
          <TextField
            label="店舗名"
            helperText="店舗名を設定します（例：新宿店）。"
            value={form.name}
            fullWidth
            onChange={handleChangeName}
          />
        </Paper>
        <Grid container style={{ marginTop: '20px' }}>
          <Grid item xs={6}>
            {workspace?.role === ROLE_ADMIN && (
              <Button
                type="button"
                variant="contained"
                color="default"
                onClick={handleClickDelete}
              >
                削除する
              </Button>
            )}
          </Grid>
          <Grid
            item
            container
            xs={6}
            alignItems="center"
            justifyContent="flex-end"
          >
            {buildRightNavigationButtons()}
          </Grid>
        </Grid>
      </form>
    );
  };

  const pageTitle = '基本設定';

  return (
    <Root>
      <Head title={`店舗設定 - ${shop?.name || ''}`} />
      <Main>
        <ShopPageLayout
          workspaceUid={workspaceUid}
          shopId={shopId}
          current="basic"
          helpId={helps.shop.setting.basic}
          breadcrumbs={
            <Breadcrumbs aria-label="breadcrumb">
              <BreadcrumbLinkItem to={`/`}>ホーム</BreadcrumbLinkItem>
              <BreadcrumbWorkspaceItem workspaceUid={workspaceUid} />
              <BreadcrumbShopItem workspaceUid={workspaceUid} shop={shop} />
              <BreadcrumbTextItem>{pageTitle}</BreadcrumbTextItem>
            </Breadcrumbs>
          }
        >
          <PageTitleAndDescription
            title="店舗"
            subTitle={pageTitle}
            description="店舗ID、店舗名の設定を行います。"
            themeColor={globalColors.shop}
          />
          {buildContents()}
        </ShopPageLayout>
      </Main>
      <FullscreenLoading open={openBackdrop} />
    </Root>
  );
}
