import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
dayjs.extend(duration);

/**
 * 最終予約日を相対的な日付文字列に変換する
 * @param targetDate YYYY-MM-DD形式の日付文字列
 * @returns 1日以内の場合は「今日」、それ以外は「○日前」の文字列を返す
 */
export const toRelativeLastRelativeDate = (
  targetDate: string | undefined
): string | null => {
  if (targetDate === undefined) {
    return null;
  }
  const now = dayjs();
  const duration = dayjs.duration(now.diff(targetDate));

  if (duration.asDays() < 1) {
    return '今日';
  }

  return dayjs(targetDate).fromNow();
};
