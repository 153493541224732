/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import { Alert } from '@material-ui/lab';
import React, { useMemo, useState } from 'react';
import { GroupingDetails, ShopReport } from '../@interfaces/reservation';
import LinkRouter from '../components/LinkRouter';
import useBookmarkedShops from '../hooks/use-bookmarked-shops';
import { SM_BREAKPOINT } from '../hooks/use-size-type';

const styles = {
  publicShopUrl: css`
    font-size: 11px;
    color: #999;
  `,
  openIcon: css`
    height: 12px;
    vertical-align: middle;
  `,
  totalRow: css`
    background-color: #f8f8f8;
    > td {
      font-weight: bold;
    }
  `,
  subRowHeader: css`
    background-color: #f0f0f8;
    > td {
      font-weight: bold;
    }
  `,
  subRow: css`
    background-color: #f0f0f8;
  `,
  iconCell: css`
    @media screen and (max-width: ${SM_BREAKPOINT}px) {
      padding: 16px 8px;
    }
  `,
};

type Props = {
  workspaceUid: string;
  shopReports: ShopReport[];
};

export default function ShopReportsTable(props: Props) {
  const { workspaceUid, shopReports } = props;

  const { bookmarkedShopIds } = useBookmarkedShops();

  const sortedShopReports = useMemo(() => {
    const bookmarked = shopReports.filter(
      (s) => (s.shopId && bookmarkedShopIds.includes(s.shopId)) || !s.shopId
    );
    const unbookmarked = shopReports.filter(
      (s) => s.shopId && !bookmarkedShopIds.includes(s.shopId)
    );
    return [...bookmarked, ...unbookmarked];
  }, [bookmarkedShopIds, shopReports]);

  if (shopReports.length === 0) {
    return (
      <Alert severity="info">
        <strong>表示できる店舗はありません。</strong>
        <div>
          店舗が登録されていないか、店舗への権限がない可能性があります。ワークスペースの管理者に問い合わせてください。
        </div>
      </Alert>
    );
  }

  return (
    <>
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell css={styles.iconCell}></TableCell>
              <TableCell>店舗名</TableCell>
              <TableCell>予約数(Web)</TableCell>
              <TableCell>予約数(内部)</TableCell>
              <TableCell>予約合計</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedShopReports.map((shopReport) => {
              return (
                <Row
                  key={shopReport.shopUid}
                  workspaceUid={workspaceUid}
                  shopReport={shopReport}
                />
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

function Row(props: { workspaceUid: string; shopReport: ShopReport }) {
  const { workspaceUid, shopReport } = props;
  const [open, setOpen] = useState(false);

  const canOpen = shopReport.grouping && shopReport.grouping.details.length > 0;

  const buildInflowSourceDetails = (grouping: GroupingDetails | undefined) => {
    if (!open || !canOpen) {
      return null;
    }

    if (!grouping) {
      return (
        <>
          <TableRow css={styles.subRow}>
            <TableCell colSpan={5} align="center">
              詳細はありません
            </TableCell>
          </TableRow>
        </>
      );
    }

    const header = (
      <TableRow css={styles.subRowHeader}>
        <TableCell />
        <TableCell colSpan={4}>{grouping.name}</TableCell>
      </TableRow>
    );

    return (
      <>
        {header}
        {grouping.details.map((detail) => {
          return (
            <TableRow key={detail.label} css={styles.subRow}>
              <TableCell colSpan={2} align="right">
                {detail.label}
              </TableCell>
              <TableCell align="right">
                {detail.webTotal}
                {buildPercentage(detail.webTotal, shopReport.webTotal)}
              </TableCell>
              <TableCell align="right">
                {detail.internalTotal}
                {buildPercentage(
                  detail.internalTotal,
                  shopReport.internalTotal
                )}
              </TableCell>
              <TableCell align="right">
                {detail.total}
                {buildPercentage(detail.total, shopReport.total)}
              </TableCell>
            </TableRow>
          );
        })}
      </>
    );
  };

  return (
    <>
      <TableRow
        key={shopReport.shopId}
        css={shopReport.shopUid === 'total' ? styles.totalRow : null}
      >
        <TableCell css={styles.iconCell}>
          {canOpen ? (
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => {
                setOpen(!open);
              }}
            >
              {open ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
            </IconButton>
          ) : null}
        </TableCell>
        <TableCell scope="row">
          {shopReport.shopId === undefined ? (
            shopReport.shopName
          ) : (
            <LinkRouter to={`/a/${workspaceUid}/shops/${shopReport.shopId}`}>
              {shopReport.shopName}
            </LinkRouter>
          )}
          <br />
        </TableCell>
        <TableCell scope="row" align="right">
          {shopReport.webTotal}
        </TableCell>
        <TableCell scope="row" align="right">
          {shopReport.internalTotal}
        </TableCell>
        <TableCell scope="row" align="right">
          {shopReport.total}
        </TableCell>
      </TableRow>
      {buildInflowSourceDetails(shopReport.grouping)}
    </>
  );
}

const buildPercentage = (value: number | undefined, total: number) => {
  if (value === undefined) {
    return null;
  }
  const percent = Math.floor((value / total) * 100) || 0;
  return (
    <span
      css={css`
        color: #777;
      `}
    >
      ({percent}%)
    </span>
  );
};
