/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import TextField from '@material-ui/core/TextField';
import React, { ChangeEvent, FocusEvent, useEffect, useState } from 'react';

type Props = {
  value: string;
  onChange: (value: string) => void;
  disabled?: boolean;
};

export default function TimeTextField({ value, onChange, disabled }: Props) {
  const [hour, minute] = value.split(':');

  const handleBlurHour = (hour: string) => {
    onChange(`${hour}:${minute}`);
  };

  const handleBlurMinute = (minute: string) => {
    onChange(`${hour}:${minute}`);
  };

  return (
    <div
      css={css`
        display: flex;
        align-items: center;
      `}
    >
      <TimeNumberField
        value={hour}
        min={0}
        max={30}
        disabled={disabled}
        onChange={handleBlurHour}
      />
      <span>:</span>
      <TimeNumberField
        value={minute}
        min={0}
        max={59}
        disabled={disabled}
        onChange={handleBlurMinute}
      />
    </div>
  );
}

type TimeNumberFieldProps = {
  value: string;
  min: number;
  max: number;
  onChange: (value: string) => void;
  disabled?: boolean;
};

export function TimeNumberField({
  value,
  min,
  max,
  onChange,
  disabled,
}: TimeNumberFieldProps) {
  const [inputValue, setInputValue] = useState<string>('');

  useEffect(() => {
    setInputValue(String(value));
  }, [value]);

  const handleChangeInputValue = (e: ChangeEvent<HTMLInputElement>) => {
    const newInputValue = e.target.value;
    setInputValue(newInputValue);
  };

  const handleBlurInputValue = () => {
    setInputValue(String(value));
    onChange(inputValue);
  };

  const handleFocusInputValue = (
    e: FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    e.target.select();
  };

  return (
    <TextField
      type="number"
      value={inputValue}
      InputLabelProps={{
        shrink: true,
      }}
      InputProps={{ inputProps: { min, max, style: { textAlign: 'center' } } }}
      css={css`
        width: 40px;
        text-align: right;
      `}
      onChange={handleChangeInputValue}
      onFocus={handleFocusInputValue}
      onBlur={handleBlurInputValue}
      placeholder="00"
      disabled={disabled}
    />
  );
}
