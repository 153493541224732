/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slider,
} from '@material-ui/core';
import React, { useEffect } from 'react';
import { useCallback, useState } from 'react';
import Cropper from 'react-easy-crop';
import { getCroppedImg } from '../utils/canvas-utils';

interface ImageCropperProps {
  file: FilePropertyBag;
  onCropImage?: (croppedImageSrc: string) => void;
  onClose: () => void;
  aspect: number;
}

export function ImageCropperDialog(props: ImageCropperProps) {
  const { file, onCropImage, onClose, aspect } = props;
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const imageSrc = URL.createObjectURL(file as File);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const handleCrop = useCallback(async () => {
    try {
      const croppedImageSrc = await getCroppedImg(
        imageSrc,
        file.type,
        croppedAreaPixels
      );
      onCropImage?.(croppedImageSrc);
    } catch (e) {
      console.error(e);
    }
  }, [imageSrc, croppedAreaPixels]);

  const handleClose = useCallback(() => {
    onClose();
  }, []);

  const handleChangeZoom = (
    event: React.ChangeEvent<{}>,
    value: number | number[]
  ) => {
    if (typeof value == 'number') {
      setZoom(value);
    }
  };

  useEffect(() => {
    setCrop({ x: 0, y: 0 });
    setZoom(1);
    setCroppedAreaPixels(null);
  }, [file]);

  return (
    <Dialog
      onClose={handleClose}
      open={true}
      fullScreen
      maxWidth="md"
      css={css`
        z-index: 9999999999;
      `}
    >
      <DialogTitle>画像の切り抜き</DialogTitle>
      <DialogContent>
        <Cropper
          image={imageSrc}
          crop={crop}
          zoom={zoom}
          aspect={aspect}
          onCropChange={setCrop}
          onCropComplete={onCropComplete}
          onZoomChange={setZoom}
        />
      </DialogContent>
      <DialogActions>
        <Box
          css={css`
            width: 70px;
          `}
        >
          拡大/縮小:
        </Box>
        <Slider
          value={zoom}
          min={1}
          max={3}
          step={0.1}
          onChange={handleChangeZoom}
          css={css`
            width: calc(100% - 78px);
            max-width: 300px;
          `}
        />
        <Box>
          <Button onClick={handleClose} variant="contained" color="default">
            キャンセル
          </Button>
          <Button
            onClick={handleCrop}
            variant="contained"
            color="primary"
            css={css`
              margin-left: 12px;
            `}
          >
            切り抜き
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
}
