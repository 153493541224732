import { CustomDomain } from '../@interfaces/custom-domain/api';

export const PUBLIC_BASE_URL = process.env.REACT_APP_PUBLIC_BASE_URL ?? '';

export function buildPublicShopUrl(workspaceUid: string, shopUid: string) {
  return `${PUBLIC_BASE_URL}/a/${workspaceUid}/shops/${shopUid}`;
}

export function buildPublicCourseUrl(
  workspaceUid: string,
  shopUid: string,
  courseUid: string
) {
  return `${PUBLIC_BASE_URL}/a/${workspaceUid}/shops/${shopUid}/courses/${courseUid}`;
}

export function buildPublicLineUrl(
  workspaceUid: string,
  channelKey: string,
  shopId?: number,
  courseId?: number
) {
  return `${PUBLIC_BASE_URL}/a/${workspaceUid}/line?channelKey=${channelKey}${
    shopId ? `&shopId=${shopId}` : ''
  }${courseId ? `&courseId=${courseId}` : ''}`;
}

export function buildLineCallbackUrl(workspaceUid: string) {
  return `${PUBLIC_BASE_URL}/a/${workspaceUid}/line/callback`;
}

export function buildPublicShareUrl(
  workspaceUid: string,
  shopUid: string,
  courseUid: string,
  src: string,
  startDate: string,
  endDate: string,
  customDomain: CustomDomain | null
) {
  const baseUrl = customDomain?.fqdn
    ? `https://${customDomain.fqdn}`
    : PUBLIC_BASE_URL;
  return `${baseUrl}/a/${workspaceUid}${shopUid ? '/shops/' + shopUid : ''}${
    courseUid ? '/courses/' + courseUid : ''
  }${src || startDate ? '?' : ''}${src ? '_src=' + src : ''}${
    src && startDate ? '&' : ''
  }${startDate ? 'startDate=' + startDate + '&endDate=' + endDate : ''}`;
}
