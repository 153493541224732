import axios, { AxiosResponse } from 'axios';
import { useContext } from 'react';
import useSWR from 'swr';
import { GetReservationItemResponse } from '../../../@interfaces/insight/api';
import {
  AggregationGroup,
  TargetDateType,
} from '../../../@interfaces/insight/backend';
import { API_END_POINT } from '../../../api/api';
import { Store } from '../../../context/GlobalStore';

const fetcher = async (
  url: string,
  token: string,
  primaryStartDate: string,
  primaryEndDate: string,
  comparisonStartDate: string,
  comparisonEndDate: string,
  targetDateType: TargetDateType,
  group: AggregationGroup
) => {
  return axios
    .get(url, {
      params: {
        primaryStartDate,
        primaryEndDate,
        comparisonStartDate,
        comparisonEndDate,
        targetDateType,
        group,
      },
      headers: {
        Authorization: token,
      },
    })
    .then(
      (response: AxiosResponse<GetReservationItemResponse>) => response.data
    );
};

export default function useInsightItemReservation(
  shopId: string,
  primaryStartDate: string,
  primaryEndDate: string,
  comparisonStartDate: string,
  comparisonEndDate: string,
  targetDateType: TargetDateType,
  group: AggregationGroup
) {
  const { globalState } = useContext(Store);

  const { data, isLoading } = useSWR(
    globalState.session?.idToken
      ? [
          `${API_END_POINT}/app/shops/${shopId}/insight/reservation/item`,
          globalState.session.idToken,
          primaryStartDate,
          primaryEndDate,
          comparisonStartDate,
          comparisonEndDate,
          targetDateType,
          group,
        ]
      : null,
    async ([
      url,
      token,
      primaryStartDate,
      primaryEndDate,
      comparisonStartDate,
      comparisonEndDate,
      targetDateType,
      group,
    ]) =>
      fetcher(
        url,
        token,
        primaryStartDate,
        primaryEndDate,
        comparisonStartDate,
        comparisonEndDate,
        targetDateType,
        group
      )
  );

  return {
    results: data,
    isLoading: isLoading,
  };
}
