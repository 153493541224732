import CircularProgress from '@material-ui/core/CircularProgress';
import React from 'react';

interface Props {
  loading: boolean;
}

export default function Spinner(props: Props) {
  if (!props.loading) {
    return null;
  }
  return <CircularProgress disableShrink={true} variant="indeterminate" />;
}
