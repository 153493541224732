import axios, { AxiosResponse } from 'axios';
import { useContext, useEffect, useState } from 'react';
import { ResponseBody as SearchResponse } from '../@interfaces/search';
import { Shop } from '../@interfaces/shop';
import { Workspace } from '../@interfaces/workspace';
import { Store } from '../context/GlobalStore';
import { API_END_POINT } from './api';

type ShopResult = Shop & {
  type: 'shop';
};
type WorkspaceResult = Workspace & {
  type: 'workspace';
};

export type SearchResult = ShopResult | WorkspaceResult;

export const useSearch = (
  keyword: string
): {
  data: SearchResult[];
  isLoading: boolean;
  reload: () => void;
} => {
  const { globalState } = useContext(Store);
  const [response, setResponse] = useState<SearchResponse>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    reload();
  }, [keyword, globalState.session?.idToken]);

  const reload = () => {
    if (!globalState.session?.idToken) {
      return;
    }
    if (keyword === '') {
      setResponse({ result: { workspaces: [], shops: [] } });
      return;
    }
    setLoading(true);
    axios
      .get(`${API_END_POINT}/app/search`, {
        params: {
          keyword,
        },
        headers: {
          Authorization: globalState.session?.idToken,
        },
      })
      .then((response: AxiosResponse<SearchResponse>) => {
        setResponse(response.data);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return {
    data: [
      ...(response?.result?.workspaces?.map?.((w) => ({
        ...w,
        type: 'workspace' as const,
      })) ?? []),
      ...(response?.result?.shops?.map?.((s) => ({
        ...s,
        type: 'shop' as const,
      })) ?? []),
    ],
    isLoading: loading,
    reload: reload,
  };
};
