import axios, { AxiosResponse } from 'axios';
import { useContext, useEffect, useState } from 'react';
import { ResponseBody } from '../../../frontend-api/src/handlers/shop/shops-handler';
import { Shop } from '../@interfaces/shop';
import { Store } from '../context/GlobalStore';
import { API_END_POINT } from './api';

const cache: { [workspaceUid: string]: Shop[] } = {};

export default function useShops(workspaceUid: string) {
  const { globalState, setGlobalState } = useContext(Store);
  const [shops, setShops] = useState(cache[workspaceUid] || ([] as Shop[]));
  const [loading, setLoading] = useState(cache[workspaceUid] == undefined);
  useEffect(() => {
    if (!workspaceUid || !globalState.session?.idToken) {
      return;
    }
    axios
      .get(`${API_END_POINT}/app/ws/${workspaceUid}/shops`, {
        headers: {
          Authorization: globalState.session?.idToken,
        },
      })
      .then((response: AxiosResponse<ResponseBody>) => {
        setShops(response.data.shops);
        cache[workspaceUid] = response.data.shops;
      })
      .finally(() => {
        setLoading(false);
      });
  }, [workspaceUid, globalState.session?.idToken]);
  return {
    shops,
    isLoadingShops: loading,
  };
}
