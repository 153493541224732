/** @jsxImportSource @emotion/react */

import { Button, Grid } from '@material-ui/core';
import React from 'react';
import {
  AnyField,
  CheckboxField,
  FormSetting,
  Option,
  RadioField,
} from '../../../../../core/types/reservation-form-types';
import { randomString } from '../../../../../utils/string';
import { FormSettingOption } from './FormSettingOption';

export type SelectedField = CheckboxField | RadioField;

type FormSettingOptionsProps = {
  field: SelectedField;
  setting: FormSetting;
  onChangeSetting: (setting: FormSetting) => void;
  originalField: AnyField | undefined;
};

export const FormSettingOptions = (
  props: FormSettingOptionsProps
): JSX.Element => {
  const { field, setting, onChangeSetting, originalField } = props;

  const originalOptions =
    originalField?.type === 'radio' || originalField?.type === 'checkbox'
      ? originalField.options
      : [];

  const handleClickOptionAdd = (field: SelectedField) => {
    const option = {
      uid: randomString(6),
      text: `選択肢${field.options.length + 1}`,
    } as Option;
    field.options = [...field.options, option];
    onChangeSetting({ ...setting });
  };

  return (
    <>
      <Grid item container xs={1} alignItems="center" justifyContent="center">
        <div>選択肢</div>
      </Grid>
      <Grid item container xs={11}>
        {field.options.map((option, index) => {
          const originalOption = originalOptions.find(
            (o) => o.uid === option.uid
          );
          return (
            <FormSettingOption
              field={field}
              option={option}
              setting={setting}
              onChangeSetting={onChangeSetting}
              originalOption={originalOption}
            />
          );
        })}
        <Grid item xs={12}>
          <Button
            variant="text"
            color="primary"
            onClick={() => {
              handleClickOptionAdd(field);
            }}
          >
            選択肢を追加
          </Button>
        </Grid>
      </Grid>
    </>
  );
};
