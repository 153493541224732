/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import {
  Breadcrumbs,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { useState } from 'react';
import { FaLongArrowAltDown, FaLongArrowAltUp } from 'react-icons/fa';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import useReactRouter from 'use-react-router';
import { HourlyAggregation } from '../../../@interfaces/insight/backend';
import useShop from '../../../api/use-shop';
import {
  BreadcrumbLinkItem,
  BreadcrumbShopItem,
  BreadcrumbTextItem,
  BreadcrumbWorkspaceItem,
} from '../../../components/breadcrumb/BreadcrumbItem';
import { ShopPageLayout } from '../../../components/layouts/ShopPageLayout';
import { PageTitleAndDescription } from '../../../components/PageTitleAndDescription';
import { Head, Main, Root } from '../../../components/Shared';
import { useSizeType } from '../../../hooks/use-size-type';
import { globalColors } from '../../../styles/globalColors';
import { helps } from '../../../utils/helps';
import useInsightReservationHourly from '../api/use-insight-reservation-hourly';
import { ComparisonValue } from '../components/ComparisonValue';
import { DateRangePreview } from '../components/DateRangePreview';
import { useComparisonCheckbox } from '../hooks/useComparison';
import { useDateRange } from '../hooks/useDateRange';
import { useTargetDateType } from '../hooks/useTargetDateType';
import { colors, comparisonColors, styles } from '../utils/insight-utils';

type PageParams = {
  workspaceUid: string;
  shopId: string;
};

type DisplayData = {
  hour: number;
  total: number;
  comparisonTotal?: number;
};

const toDisplayData = (results: HourlyAggregation[]): DisplayData[] => {
  const displayData = Array.from({ length: 24 }, (_, i) => ({
    hour: i,
    total: 0,
  }));

  results.forEach((result) => {
    displayData[result.hour].total = result.total;
  });

  return displayData;
};

const mergeDisplayData = (
  primary: HourlyAggregation[],
  comparison: HourlyAggregation[]
): DisplayData[] => {
  const displayData = Array.from({ length: 24 }, (_, i) => ({
    hour: i,
    total: 0,
    comparisonTotal: 0,
  }));

  primary.forEach((result) => {
    displayData[result.hour].total = result.total;
  });

  comparison.forEach((result) => {
    displayData[result.hour].comparisonTotal = result.total;
  });

  return displayData;
};

export default function HourlyPage() {
  const { match, location } = useReactRouter<PageParams>();
  const { workspaceUid, shopId } = match.params;
  const { shop } = useShop(shopId);
  const {
    startDate,
    endDate,
    comparisonStartDate,
    comparisonEndDate,
    dateRangePicker,
  } = useDateRange();
  const { targetDateType, targetDateTypeSelector } = useTargetDateType();
  const { isSpSize } = useSizeType();
  const { showComparison, comparisonCheckbox } = useComparisonCheckbox();
  const { results } = useInsightReservationHourly(
    shopId,
    startDate.format('YYYY-MM-DD'),
    endDate.format('YYYY-MM-DD'),
    comparisonStartDate.format('YYYY-MM-DD'),
    comparisonEndDate.format('YYYY-MM-DD'),
    targetDateType
  );
  const [sortKey, setSortKey] = useState<keyof HourlyAggregation>('hour');
  const [ascending, setAscending] = useState(true);

  const primaryDisplayData = toDisplayData(results?.primary.results || []);
  const comparisonDisplayData = toDisplayData(
    results?.comparison.results || []
  );
  const displayData = showComparison
    ? mergeDisplayData(primaryDisplayData, comparisonDisplayData)
    : primaryDisplayData;

  const sortedData = [...displayData].sort((a: any, b: any) => {
    return ascending ? a[sortKey] - b[sortKey] : b[sortKey] - a[sortKey];
  });

  const buildSortIcon = (key: keyof HourlyAggregation) => {
    if (sortKey === key && ascending) {
      return (
        <FaLongArrowAltUp
          css={css`
            vertical-align: middle;
          `}
        />
      );
    } else if (sortKey === key && !ascending) {
      return (
        <FaLongArrowAltDown
          css={css`
            vertical-align: middle;
          `}
        />
      );
    } else {
      return null;
    }
  };

  const buildContents = () => {
    return (
      <div>
        <div
          css={css`
            display: flex;
            justify-content: space-between;
            margin-bottom: 1em;
            gap: 2em;
            flex-wrap: wrap;
          `}
        >
          <div
            css={css`
              display: flex;
              gap: 2em;
              flex-wrap: wrap;
            `}
          >
            {dateRangePicker}
            {targetDateTypeSelector}
            {comparisonCheckbox}
          </div>
          <div
            css={css`
              margin-bottom: 1em;
            `}
          >
            <DateRangePreview
              primaryStartDate={startDate}
              primaryEndDate={endDate}
              comparisonStartDate={comparisonStartDate}
              comparisonEndDate={comparisonEndDate}
              showComparison={showComparison}
            />
          </div>
        </div>
        <ResponsiveContainer
          css={css`
            margin-top: 1.5em;
          `}
          width="100%"
          height={isSpSize ? 600 : 400}
        >
          <BarChart
            width={1000}
            data={displayData}
            margin={{
              top: 20,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="hour"
              tickFormatter={(tickItem: string) => `${tickItem}時`}
            />
            <YAxis />
            <Tooltip />
            <Legend />
            {showComparison && (
              <Bar
                dataKey={`comparisonTotal`}
                name={`予約数 (比較)`}
                stackId="comparison"
                fill={comparisonColors[0]}
              />
            )}
            <Bar dataKey="total" name="予約数" fill={colors[0]} />
          </BarChart>
        </ResponsiveContainer>
        <TableContainer
          css={css`
            margin-top: 3em;
          `}
          component={Paper}
        >
          <Table size="small" aria-label="a dense table">
            <TableHead
              css={css`
                background-color: black;
              `}
            >
              <TableRow>
                <TableCell
                  css={[
                    styles.tableHeader(2),
                    styles.clickableTableHeader,
                    sortKey === 'hour'
                      ? css`
                          font-weight: bold;
                        `
                      : null,
                  ]}
                  align="center"
                  onClick={() => {
                    setSortKey('hour');
                    setAscending(sortKey === 'hour' ? !ascending : false);
                  }}
                >
                  時間
                  {buildSortIcon('hour')}
                </TableCell>
                <TableCell
                  css={[
                    styles.tableHeader(2),
                    styles.clickableTableHeader,
                    sortKey === 'total'
                      ? css`
                          font-weight: bold;
                        `
                      : null,
                  ]}
                  align="center"
                  onClick={() => {
                    setSortKey('total');
                    setAscending(sortKey === 'total' ? !ascending : false);
                  }}
                >
                  予約数
                  {buildSortIcon('total')}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedData.map((row) => (
                <TableRow key={row.hour}>
                  <TableCell align="center">{`${row.hour}時`}</TableCell>
                  <TableCell align="center">
                    <div>
                      <div css={styles.tableNumberCell}>
                        {row.total.toLocaleString()}
                      </div>
                      {row.comparisonTotal !== undefined && (
                        <ComparisonValue
                          primaryValue={row.total}
                          comparisonValue={row.comparisonTotal}
                        />
                      )}
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  };

  return (
    <Root>
      <Head title="統計データ（レポート）- 流入元別詳細" />
      <Main>
        <ShopPageLayout
          workspaceUid={workspaceUid}
          shopId={shopId}
          current="insight"
          helpId={helps.customer.list}
          breadcrumbs={
            <Breadcrumbs aria-label="breadcrumb">
              <BreadcrumbLinkItem to={`/`}>ホーム</BreadcrumbLinkItem>
              <BreadcrumbWorkspaceItem workspaceUid={workspaceUid} />
              <BreadcrumbShopItem workspaceUid={workspaceUid} shop={shop} />
              <BreadcrumbLinkItem
                to={`/a/${workspaceUid}/shops/${shopId}/insight${location.search}`}
              >
                統計データ（レポート）
              </BreadcrumbLinkItem>
              <BreadcrumbTextItem>時間帯別詳細</BreadcrumbTextItem>
            </Breadcrumbs>
          }
        >
          <PageTitleAndDescription
            title="店舗"
            subTitle="統計データ（レポート）: 時間帯別詳細"
            description="選択した期間内の予約件数の時間帯別統計データの確認を行います。"
            themeColor={globalColors.shop}
          />
          {buildContents()}
        </ShopPageLayout>
      </Main>
    </Root>
  );
}
