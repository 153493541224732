/** @jsxImportSource @emotion/react */

import { Fragment, useState } from 'react';
import { ShopImage } from '../@interfaces/shop-setting';
import { Box, IconButton } from '@material-ui/core';
import { css } from '@emotion/react';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import { SM_BREAKPOINT } from '../hooks/use-size-type';
import CloseIcon from '@material-ui/icons/Close';

const cssStyles = {
  uploadLogoWrapper: css`
    position: relative;
    width: 200px;
    margin: 0;

    @media screen and (max-width: ${SM_BREAKPOINT}px) {
      width: 100%;
    }
  `,
  sortableElementWrapper: css`
    cursor: grab;
    display: flex;
    align-items: center;
    width: fit-content;
    border: 1px solid #aaa;
    padding: 10px 5px 5px 5px;
    margin-top: 2px;
    border-radius: 5px;
  `,
  dragIcon: css`
    color: #aaa;
  `,
  closeIconButton: css`
    position: absolute;
    top: -16px;
    right: -16px;
    background: #dddddd;
    width: 32px;
    height: 32px;
  `,
};

export const SortableImages = ({
  images,
  onChangeImages,
}: {
  images: ShopImage[];
  onChangeImages: (newImages: ShopImage[]) => void;
}) => {
  const [moveFromIndex, setMoveFromIndex] = useState<number | null>(null);
  const [moveToIndex, setMoveToIndex] = useState<number | null>(null);
  const PositionHelper = () => (
    <Box
      css={css`
        height: 5px;
        background-color: gray;
        border-radius: 2px;
      `}
    />
  );
  return (
    <Box
      css={css`
        width: fit-content;
      `}
    >
      {images.map((image, i) => (
        <Fragment key={`sortable-image-${i}`}>
          {moveFromIndex !== null &&
            moveToIndex !== null &&
            moveFromIndex !== i &&
            moveToIndex === i &&
            moveFromIndex > moveToIndex && <PositionHelper />}
          <Box
            css={cssStyles.sortableElementWrapper}
            draggable
            onDragStart={() => {
              setMoveFromIndex(i);
            }}
            onDragEnter={() => {
              setMoveToIndex(i);
            }}
            onDragEnd={() => {
              if (moveFromIndex === null || moveToIndex === null) {
                return;
              }
              onChangeImages(moveAt(images, moveFromIndex, moveToIndex));
              setMoveFromIndex(null);
              setMoveToIndex(null);
            }}
          >
            <DragIndicatorIcon css={cssStyles.dragIcon} />
            <Box css={cssStyles.uploadLogoWrapper}>
              <img src={image.url} width="100%" alt="" />
              <IconButton
                css={cssStyles.closeIconButton}
                onClick={() => {
                  onChangeImages([...images.filter((_, j) => i !== j)]);
                }}
              >
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
          {moveFromIndex !== null &&
            moveToIndex !== null &&
            moveFromIndex !== i &&
            moveToIndex === i &&
            moveFromIndex <= moveToIndex && <PositionHelper />}
        </Fragment>
      ))}
    </Box>
  );
};

const moveAt = (array: ShopImage[], index: number, at: number) => {
  const value = array[index];
  const retArray = [...array.slice(0, index), ...array.slice(index + 1)];
  retArray.splice(at, 0, value);
  return retArray;
};
