import axios, { AxiosResponse } from 'axios';
import { useContext, useEffect, useState } from 'react';
import { ResponseBody } from '../../../frontend-api/src/handlers/shop-account/shop-accounts-handler';
import { ShopAccountWithAccountNameAndLastLoginAt } from '../@interfaces/shop-account';
import { Store } from '../context/GlobalStore';
import { API_END_POINT } from './api';

export default function useShopAccounts(shopId: string) {
  const { globalState, setGlobalState } = useContext(Store);
  const [shopAccounts, setShopAccounts] = useState(
    [] as ShopAccountWithAccountNameAndLastLoginAt[]
  );
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    reload();
  }, [shopId, globalState.session?.idToken]);

  const reload = () => {
    if (!globalState.session?.idToken) {
      return;
    }
    axios
      .get(`${API_END_POINT}/app/shops/${shopId}/accounts`, {
        headers: {
          Authorization: globalState.session?.idToken,
        },
      })
      .then((response: AxiosResponse<ResponseBody>) => {
        setShopAccounts(response.data.shopAccounts);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return {
    shopAccounts,
    isLoadingShopAccounts: loading,
    reloadShopAccounts: reload,
  };
}
