import { useCallback, useContext } from 'react';
import { Store } from '../context/GlobalStore';
import useStorage from './use-storage';

export default function useBookmarkedWorkspaces() {
  const { globalState } = useContext(Store);
  const userPrefix = globalState.signedUser?.account.uid || 'unknown';
  const [bookmarkedWorkspaceIds, setBookmarkedWorkspaceIds] = useStorage<
    number[]
  >(`${userPrefix}_bookmarkedWorkspaceIds`, []);

  const toggleBookmarkedWorkspace = useCallback(
    (workspaceId: number) => {
      const targetWorkspaceId = bookmarkedWorkspaceIds.find(
        (id) => id === workspaceId
      );
      if (targetWorkspaceId) {
        const newWorkspaceIds = bookmarkedWorkspaceIds.filter(
          (id) => id !== workspaceId
        );
        setBookmarkedWorkspaceIds(newWorkspaceIds);
      } else {
        setBookmarkedWorkspaceIds([...bookmarkedWorkspaceIds, workspaceId]);
      }
    },
    [setBookmarkedWorkspaceIds, bookmarkedWorkspaceIds]
  );

  return {
    bookmarkedWorkspaceIds,
    toggleBookmarkedWorkspace,
  };
}
