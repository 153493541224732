/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import {
  Box,
  Breadcrumbs,
  Button,
  FormControlLabel,
  Grid,
  InputAdornment,
  Paper,
  Switch,
  TextField,
} from '@material-ui/core';
import LockIcon from '@material-ui/icons/Lock';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import React, { ChangeEvent, useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import useReactRouter from 'use-react-router';
import { API_END_POINT } from '../../../api/api';
import useWorkspaceSetting from '../../../api/use-workspace-setting';
import {
  BreadcrumbLinkItem,
  BreadcrumbTextItem,
  BreadcrumbWorkspaceItem,
} from '../../../components/breadcrumb/BreadcrumbItem';
import { commonCss } from '../../../components/common-css';
import { WorkspacePageLayout } from '../../../components/layouts/WorkspacePageLayout';
import { PageTitleAndDescription } from '../../../components/PageTitleAndDescription';
import {
  FullscreenLoading,
  Head,
  Main,
  Root,
} from '../../../components/Shared';
import { useStyles } from '../../../components/Styles';
import { Store } from '../../../context/GlobalStore';
import useCurrentWorkspace from '../../../context/use-current-workspace';
import { globalColors } from '../../../styles/globalColors';
import { helps } from '../../../utils/helps';

interface PageParams {
  workspaceUid: string;
}

type Form = {
  name: string;
  uid: string;
  enablePrivacyPolicy: boolean;
  privacyPolicy: string;
  shopLabel: string;
  courseLabel: string;
};

export default function WorkspaceThemeSettingPage(props: any) {
  const { globalState, setGlobalState } = useContext(Store);
  const { history, location, match } = useReactRouter<PageParams>();
  const { workspaceUid } = match.params;
  const validationContext = useForm();
  const { handleSubmit, register } = validationContext;
  const styles = useStyles();

  const workspace = useCurrentWorkspace(workspaceUid);
  const { workspaceSetting } = useWorkspaceSetting(workspaceUid);

  const [form, setForm] = useState<Form>({
    uid: '',
    name: '',
    privacyPolicy: '',
    enablePrivacyPolicy: false,
    shopLabel: '',
    courseLabel: '',
  });
  const [lockedId, setLockedId] = useState(true);
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (!workspace || !workspaceSetting) {
      return;
    }
    const { privacyPolicy, shopLabel, courseLabel } = workspaceSetting;
    const enablePrivacyPolicy =
      privacyPolicy !== null &&
      privacyPolicy !== undefined &&
      privacyPolicy !== '';
    setForm({
      uid: workspace.uid,
      name: workspace.name,
      enablePrivacyPolicy: enablePrivacyPolicy,
      privacyPolicy: privacyPolicy || '',
      shopLabel,
      courseLabel,
    });
  }, [workspace, workspaceSetting]);

  const handleChangeUid = (e: ChangeEvent<HTMLInputElement>) => {
    const uid = e.target.value;
    setForm({ ...form, uid });
  };

  const handleChangeName = (e: ChangeEvent<HTMLInputElement>) => {
    const name = e.target.value;
    setForm({ ...form, name });
  };

  const handleClickEditId = () => {
    if (
      !window.confirm(
        'ワークスペースIDを変更するとWeb予約画面のURLが変わるためリンク等の書き換えが必要です。ワークスペースIDを変更してもよろしいですか？'
      )
    ) {
      return;
    }
    setLockedId(false);
  };

  const handleChangeEnablePrivacyPolicy = () => {
    const enablePrivacyPolicy = !form.enablePrivacyPolicy;
    setForm({ ...form, enablePrivacyPolicy });
  };

  const handleChangePrivacyPolicy = (e: ChangeEvent<HTMLInputElement>) => {
    const privacyPolicy = e.target.value;
    setForm({ ...form, privacyPolicy });
  };

  const handleSubmitForm = (_e: React.FormEvent) => {
    if (!workspaceSetting) {
      return;
    }
    const json = {
      uid: form.uid,
      name: form.name,
      privacyPolicy: form.enablePrivacyPolicy ? form.privacyPolicy : '',
      shopLabel: form.shopLabel,
      courseLabel: form.courseLabel,
    };
    setOpenBackdrop(true);
    axios
      .put(`${API_END_POINT}/app/ws/${workspaceUid}`, json, {
        headers: {
          Authorization: globalState.session?.idToken,
        },
      })
      .then(() => {
        enqueueSnackbar('設定を保存しました。', { variant: 'success' });
        if (!globalState?.signedUser?.workspaces || !workspace) {
          return;
        }
        const newWorkspaces = [...globalState.signedUser.workspaces];
        const index = newWorkspaces.findIndex((w) => w.uid == workspaceUid);
        newWorkspaces[index] = {
          ...workspace,
          uid: form.uid,
          name: form.name,
        };
        const newGlobalState = {
          ...globalState,
          signedUser: {
            ...globalState?.signedUser,
            workspaces: newWorkspaces,
          },
        };
        setGlobalState(newGlobalState);
        history.push(`/a/${form.uid}/settings/basic`);
      })
      .catch((e) => {
        if (e.response && e.response.status === 409) {
          enqueueSnackbar(
            '同一IDのワークスペースが存在するため、保存できませんでした。',
            { variant: 'error' }
          );
        } else {
          enqueueSnackbar('設定が保存できませんでした。', {
            variant: 'error',
          });
        }
      })
      .finally(() => {
        setOpenBackdrop(false);
      });
  };

  const handleDelete = () => {
    if (!workspace) {
      return;
    }
    const input = window.prompt(
      `このワークスペースを削除しますか？\n削除するには「${workspace.name}のワークスペースを削除」と入力してください。\nこの操作は元に戻せません。`
    );
    if (!input) {
      return;
    }
    if (input != `${workspace.name}のワークスペースを削除`) {
      alert('入力された文字が一致しません。');
      return;
    }
    void axios
      .delete(`${API_END_POINT}/app/ws/${workspaceUid}`, {
        headers: {
          Authorization: globalState.session?.idToken,
        },
      })
      .then(() => {
        enqueueSnackbar('ワークスペースを削除しました。', {
          variant: 'success',
        });
        history.push(`/`);
        // 'ワークスペースを削除しました。'というメッセージをユーザーに見せるために1秒後実行
        setTimeout(() => {
          // ログイン済みユーザーが保持するワークスペースの一覧を更新するためにリロードする
          window.location.reload();
        }, 1000);
      });
  };

  const buildRightNavigationButtons = () => {
    return (
      <>
        <Button
          css={commonCss.button.right}
          type="submit"
          variant="contained"
          color="primary"
        >
          &nbsp;保存&nbsp;
        </Button>
      </>
    );
  };

  const handleChangeShopLabel = (e: ChangeEvent<HTMLInputElement>) => {
    const shopLabel = e.target.value;
    setForm({ ...form, shopLabel });
  };

  const handleChangeCourseLabel = (e: ChangeEvent<HTMLInputElement>) => {
    const courseLabel = e.target.value;
    setForm({ ...form, courseLabel });
  };

  const buildContents = () => {
    //スーパー管理者かどうか
    const isSuperUser = globalState.signedUser?.account.isSuperUser || false;

    return (
      <form onSubmit={handleSubmit(handleSubmitForm)}>
        <Grid container style={{ marginTop: '20px' }}>
          <h3>基本設定</h3>
        </Grid>
        <Paper className={styles.paper}>
          <TextField
            label="ワークスペースID"
            helperText="ワークスペースのユニークなIDを設定します(例: adlive)。半角英数小文字、ハイフン、アンダーバーが使用できます。Web予約画面のURLに使用されます。"
            value={form.uid}
            onChange={handleChangeUid}
            InputProps={{
              startAdornment: lockedId ? (
                <InputAdornment position="start">
                  <LockIcon />
                </InputAdornment>
              ) : null,
            }}
            disabled={lockedId}
            required
            inputProps={{ maxLength: 30, pattern: '^[a-z0-9_-]+$' }}
          />
          {lockedId ? (
            <Button
              type="button"
              variant="contained"
              color="default"
              onClick={handleClickEditId}
            >
              ワークスペースIDを編集する
            </Button>
          ) : null}
          <TextField
            label="ワークスペース名"
            helperText="ワークスペース名を設定します。"
            value={form.name}
            fullWidth
            inputProps={{ maxLength: 50 }}
            onChange={handleChangeName}
          />
          <FormControlLabel
            control={
              <Switch
                checked={form.enablePrivacyPolicy}
                onChange={handleChangeEnablePrivacyPolicy}
              />
            }
            label="プライバシーポリシーへの同意を表示する"
          />
          {form.enablePrivacyPolicy ? (
            <TextField
              label="プライバシポリシー"
              helperText="Webの予約フォームにプライバシーポリシーへの同意を表示します。プライバシポリシーのURLまたはプライバシポリシーの内容を入力してください。"
              value={form.privacyPolicy}
              fullWidth
              multiline
              required
              maxRows={10}
              onChange={handleChangePrivacyPolicy}
            />
          ) : null}
        </Paper>
        <h3>項目のカスタマイズ</h3>
        <Paper className={styles.paper}>
          <Box>
            <TextField
              label="店舗の項目名"
              helperText="Webの予約画面やメール内での店舗の項目名を変更します。管理画面内の名称は変更されません。未設定の場合は「ご予約店舗」と表示されます。"
              value={form.shopLabel}
              fullWidth
              inputProps={{ maxLength: 30 }}
              onChange={handleChangeShopLabel}
            />
          </Box>
          <Box mt={2}>
            <TextField
              label="コースの項目名"
              helperText="Webの予約画面やメール内でのコースの項目名を変更します。管理画面内の名称は変更されません。未設定の場合は「コース」と表示されます。"
              value={form.courseLabel}
              fullWidth
              inputProps={{ maxLength: 30 }}
              onChange={handleChangeCourseLabel}
            />
          </Box>
        </Paper>
        <Grid container style={{ marginTop: '20px' }}>
          <Grid item xs={6}>
            {isSuperUser && (
              <>
                <Button type="button" color="primary" onClick={handleDelete}>
                  このワークスペースを削除する
                </Button>
                <p
                  css={css`
                    color: red;
                  `}
                >
                  ※スーパー管理者のみワークスペースを削除することができます
                </p>
              </>
            )}
          </Grid>
          <Grid
            item
            container
            xs={6}
            alignItems="center"
            justifyContent="flex-end"
          >
            {buildRightNavigationButtons()}
          </Grid>
        </Grid>
      </form>
    );
  };

  const pageTitle = '基本設定';

  return (
    <Root>
      <Head title={`ワークスペース設定 - ${workspace?.name || ''}`} />
      <Main>
        <WorkspacePageLayout
          workspaceUid={workspaceUid}
          current="basic"
          breadcrumbs={
            <Breadcrumbs aria-label="breadcrumb">
              <BreadcrumbLinkItem to={`/`}>ホーム</BreadcrumbLinkItem>
              <BreadcrumbWorkspaceItem workspaceUid={workspaceUid} />
              <BreadcrumbTextItem>{pageTitle}</BreadcrumbTextItem>
            </Breadcrumbs>
          }
          helpId={helps.workspace.setting.basic}
        >
          <PageTitleAndDescription
            title="ワークスペース"
            subTitle={pageTitle}
            description="ワークスペースID、ワークスペース名やプライバシーポリシーの設定を行います。"
            themeColor={globalColors.workspace}
          />
          {buildContents()}
        </WorkspacePageLayout>
      </Main>
      <FullscreenLoading open={openBackdrop} />
    </Root>
  );
}
