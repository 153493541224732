export function uniq(array: any[]) {
  return array.filter((elem, index, self) => self.indexOf(elem) === index);
}

// see https://qiita.com/nagtkk/items/e1cc3f929b61b1882bd1
// groupBy タプル版
// オブジェクト { key: values } ではなくタプル群 [key, values][] を返すようにしよう。
export const groupBy = <K, V>(
  array: readonly V[],
  getKey: (cur: V, idx: number, src: readonly V[]) => K
): [K, V[]][] =>
  Array.from(
    array.reduce((map, cur, idx, src) => {
      const key = getKey(cur, idx, src);
      const list = map.get(key);
      if (list) list.push(cur);
      else map.set(key, [cur]);
      return map;
    }, new Map<K, V[]>())
  );

export const swap = <T>(array: Array<T>, i1: number, i2: number) => {
  const newArray = [...array];
  const tmp = newArray[i1];
  newArray[i1] = newArray[i2];
  newArray[i2] = tmp;
  return newArray;
};
