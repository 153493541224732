import axios, { AxiosResponse } from 'axios';
import { useContext } from 'react';
import useSWR from 'swr';
import { GetCustomersCountResponse } from '../../../@interfaces/crm/api';
import { API_END_POINT } from '../../../api/api';
import { Store } from '../../../context/GlobalStore';

const fetcher = async (
  token: string,
  workspaceUid: string,
  keywords: string[],
  includesDeleted?: boolean
) =>
  axios
    .get(`${API_END_POINT}/app/ws/${workspaceUid}/customers-count`, {
      headers: {
        Authorization: token,
      },
      params: {
        keywords,
        includesDeleted: (() => {
          if (includesDeleted === undefined) {
            return includesDeleted;
          }
          return includesDeleted ? '1' : '0';
        })(),
      },
    })
    .then((r: AxiosResponse<GetCustomersCountResponse>) => r.data);

export const useCustomersCount = (
  workspaceUid: string | null,
  keywords: string[],
  includesDeleted?: boolean
) => {
  const { globalState } = useContext(Store);
  const { data, isLoading } = useSWR(
    globalState.session?.idToken && workspaceUid
      ? [globalState.session.idToken, workspaceUid, keywords, includesDeleted]
      : null,
    async ([token, workspaceUid, keywords, includesDeleted]) =>
      fetcher(token, workspaceUid, keywords, includesDeleted)
  );

  return {
    data,
    isLoading,
  };
};
