/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import BookmarkBorderIcon from '@material-ui/icons/BookmarkBorder';
import { Alert } from '@material-ui/lab';
import { useCallback, useMemo } from 'react';
import { Shop } from '../@interfaces/shop';
import useInflowSources from '../api/use-inflow-sources';
import useLineMessagingChannelOverviews from '../api/use-line-messaging-channel-overviews';
import LinkRouter from '../components/LinkRouter';
import { ShareButton } from '../components/ShareButton';
import { ROLE_ADMIN } from '../context/GlobalStore';
import useCurrentWorkspace from '../context/use-current-workspace';
import useBookmarkedShops from '../hooks/use-bookmarked-shops';
import { SM_BREAKPOINT } from '../hooks/use-size-type';

const styles = {
  bookmarkCol: css`
    width: 20px;

    @media screen and (max-width: ${SM_BREAKPOINT}px) {
      padding-right: 8px;
    }
  `,
  shopNameCol: css`
    @media screen and (max-width: ${SM_BREAKPOINT}px) {
      padding: 16px 8px;
    }
  `,
  urlCopyCol: css`
    @media screen and (max-width: ${SM_BREAKPOINT}px) {
      width: 164px;
      padding-left: 8px;
    }
  `,
  bookmarked: css`
    cursor: pointer;
  `,
  unbookmarked: css`
    color: #ccc;
    cursor: pointer;
  `,
};

type Props = {
  workspaceUid: string;
  shops: Shop[];
};

export default function ShopsTable(props: Props) {
  const { workspaceUid, shops } = props;
  const { bookmarkedShopIds, toggleBookmarkedShop } = useBookmarkedShops();
  const currentWorkspace = useCurrentWorkspace(workspaceUid);
  const { inflowSources } = useInflowSources(workspaceUid);
  const { lineMessagingChannels } =
    useLineMessagingChannelOverviews(workspaceUid);
  const isAdmin = currentWorkspace
    ? currentWorkspace.role === ROLE_ADMIN
    : false;

  const handleClickToogleBookmarkedShop = useCallback(
    (shop: Shop) => {
      toggleBookmarkedShop(shop.id);
    },
    [toggleBookmarkedShop]
  );

  const sortedShops = useMemo(() => {
    const bookmarked = shops.filter((s) => bookmarkedShopIds.includes(s.id));
    const unbookmarked = shops.filter((s) => !bookmarkedShopIds.includes(s.id));
    return [...bookmarked, ...unbookmarked];
  }, [bookmarkedShopIds, shops]);

  if (sortedShops.length === 0) {
    if (isAdmin) {
      return null;
    } else {
      return (
        <Alert severity="info">
          <strong>表示できる店舗はありません。</strong>
          <div>
            店舗が登録されていないか、店舗への権限がない可能性があります。ワークスペースの管理者に問い合わせてください。
          </div>
        </Alert>
      );
    }
  }

  return (
    <>
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell css={styles.bookmarkCol}></TableCell>
              <TableCell css={styles.shopNameCol}>店舗名</TableCell>
              <TableCell css={styles.urlCopyCol}>Web予約画面URL</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedShops.map((shop, index) => (
              <TableRow key={shop.id}>
                <TableCell scope="row" css={styles.bookmarkCol}>
                  {bookmarkedShopIds.includes(shop.id) ? (
                    <BookmarkIcon
                      css={styles.bookmarked}
                      color="primary"
                      onClick={() => {
                        handleClickToogleBookmarkedShop(shop);
                      }}
                    />
                  ) : (
                    <BookmarkBorderIcon
                      css={styles.unbookmarked}
                      onClick={() => {
                        handleClickToogleBookmarkedShop(shop);
                      }}
                    />
                  )}
                </TableCell>
                <TableCell scope="row" css={styles.shopNameCol}>
                  <LinkRouter to={`/a/${workspaceUid}/shops/${shop.id}`}>
                    {shop.name}
                  </LinkRouter>
                  <br />
                </TableCell>
                <TableCell scope="row" css={styles.urlCopyCol}>
                  <ShareButton
                    workspaceUid={workspaceUid}
                    shopId={String(shop.id)}
                    inflowSources={inflowSources}
                    shops={shops}
                    lineMessagingChannels={lineMessagingChannels}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
