export const globalColors = {
  main: '#f5c835',
  secondary: '#6d6d6d',
  accent1: '#4a90e2',
  accent2: '#ff6347',
  text: '#333333',
  link: '#008000',
  hover: '#daa520',
  button: '#008000',
  icon: '#808080',
  emphasis: '#ff4500',
  workspace: '#d3f5e5',
  shop: '#dcd0c0',
  other: '#e0e0e0',
  currentPage: '#ffd700',
  profile: '#d8d3f5',
};
