/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { ConversionAggregation } from '../../../@interfaces/insight/backend';
import { calcConversionPercent } from '../utils/insight-utils';

type ConversionFunnelGraphProps = {
  totalAggregation: ConversionAggregation;
};

export const ConversionFunnelGraph = ({
  totalAggregation: aggregation,
}: ConversionFunnelGraphProps): JSX.Element => {
  return (
    <div
      css={css`
        max-width: 1024px;
        margin: 2em auto;
      `}
    >
      <svg
        width="100%"
        viewBox="0 0 1906 914"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_315_1056)">
          <path
            d="M1906 885.398C1906 892.984 1899.84 899.108 1892.29 899.108C1884.73 899.108 1878.57 892.949 1878.57 885.398C1878.57 877.848 1884.73 871.689 1892.29 871.689C1899.84 871.689 1906 877.848 1906 885.398Z"
            fill="#0E9AA7"
          />
          <path
            d="M1892.29 888.53H566.861C565.121 888.53 563.729 887.138 563.729 885.398C563.729 883.658 565.121 882.267 566.861 882.267H1892.29C1894.03 882.267 1895.42 883.658 1895.42 885.398C1895.42 887.138 1894.03 888.53 1892.29 888.53Z"
            fill="#0E9AA7"
          />
          <path
            d="M1906 654.358C1906 661.944 1899.84 668.068 1892.29 668.068C1884.73 668.068 1878.57 661.909 1878.57 654.358C1878.57 646.808 1884.73 640.649 1892.29 640.649C1899.84 640.649 1906 646.808 1906 654.358Z"
            fill="#C1C168"
          />
          <path
            d="M1892.29 657.49H655.09C653.35 657.49 651.958 656.098 651.958 654.358C651.958 652.619 653.35 651.227 655.09 651.227H1892.29C1894.03 651.227 1895.42 652.619 1895.42 654.358C1895.42 656.098 1894.03 657.49 1892.29 657.49Z"
            fill="#C1C168"
          />
          <path
            d="M1906 420.43C1906 428.016 1899.84 434.14 1892.29 434.14C1884.73 434.14 1878.57 427.981 1878.57 420.43C1878.57 412.88 1884.73 406.721 1892.29 406.721C1899.84 406.721 1906 412.88 1906 420.43Z"
            fill="#F4BD4D"
          />
          <path
            d="M1892.29 423.562H744.434C742.694 423.562 741.302 422.17 741.302 420.43C741.302 418.69 742.694 417.299 744.434 417.299H1892.25C1893.99 417.299 1895.39 418.69 1895.39 420.43C1895.39 422.17 1893.99 423.562 1892.25 423.562H1892.29Z"
            fill="#F4BD4D"
          />
          <path
            d="M1906 186.502C1906 194.088 1899.84 200.212 1892.29 200.212C1884.73 200.212 1878.57 194.053 1878.57 186.502C1878.57 178.952 1884.73 172.793 1892.29 172.793C1899.84 172.793 1906 178.952 1906 186.502Z"
            fill="#FF6F69"
          />
          <path
            d="M1892.29 189.286H833.917C832.176 189.286 830.784 187.894 830.784 186.154C830.784 184.415 832.176 183.023 833.917 183.023H1892.29C1894.03 183.023 1895.42 184.415 1895.42 186.154C1895.42 187.894 1894.03 189.286 1892.29 189.286Z"
            fill="#FF6F69"
          />
          <path
            d="M579.147 729.446L513.262 886.512H344.077L278.192 729.446H579.147Z"
            fill="#0E9AA7"
          />
          <path
            d="M428.686 756.935C511.783 756.935 579.146 744.628 579.146 729.446C579.146 714.265 511.783 701.958 428.686 701.958C345.59 701.958 278.227 714.265 278.227 729.446C278.227 744.628 345.59 756.935 428.686 756.935Z"
            fill="#0A6F78"
          />
          <path
            d="M428.687 913.965C475.397 913.965 513.262 901.658 513.262 886.477C513.262 871.296 475.397 858.989 428.687 858.989C381.978 858.989 344.112 871.296 344.112 886.477C344.112 901.658 381.978 913.965 428.687 913.965Z"
            fill="#0E9AA7"
          />
          <path
            d="M671.867 495.449L605.982 652.514H251.359L185.474 495.449H671.867Z"
            fill="#C1C168"
          />
          <path
            d="M428.688 522.937C563.011 522.937 671.902 510.63 671.902 495.449C671.902 480.267 563.011 467.96 428.688 467.96C294.364 467.96 185.474 480.267 185.474 495.449C185.474 510.63 294.364 522.937 428.688 522.937Z"
            fill="#A5A542"
          />
          <path
            d="M428.687 680.002C526.624 680.002 606.016 667.695 606.016 652.514C606.016 637.333 526.624 625.026 428.687 625.026C330.751 625.026 251.358 637.333 251.358 652.514C251.358 667.695 330.751 680.002 428.687 680.002Z"
            fill="#C1C168"
          />
          <path
            d="M764.621 261.486L698.736 418.551H158.605L92.7197 261.486H764.621Z"
            fill="#F4BD4D"
          />
          <path
            d="M428.687 288.974C614.218 288.974 764.62 276.667 764.62 261.486C764.62 246.304 614.218 233.998 428.687 233.998C243.156 233.998 92.7539 246.304 92.7539 261.486C92.7539 276.667 243.156 288.974 428.687 288.974Z"
            fill="#CC982F"
          />
          <path
            d="M428.688 446.039C577.831 446.039 698.736 433.733 698.736 418.551C698.736 403.37 577.831 391.063 428.688 391.063C279.544 391.063 158.64 403.37 158.64 418.551C158.64 433.733 279.544 446.039 428.688 446.039Z"
            fill="#F4BD4D"
          />
          <path
            d="M857.375 27.4882L791.49 184.554H65.885L0 27.4882H857.375Z"
            fill="#FF6F69"
          />
          <path
            d="M428.687 54.9764C665.445 54.9764 857.375 42.6695 857.375 27.4882C857.375 12.3069 665.445 0 428.687 0C191.93 0 0 12.3069 0 27.4882C0 42.6695 191.93 54.9764 428.687 54.9764Z"
            fill="#DB524D"
          />
          <path
            d="M428.687 212.042C629.057 212.042 791.489 199.735 791.489 184.554C791.489 169.372 629.057 157.065 428.687 157.065C228.317 157.065 65.8848 169.372 65.8848 184.554C65.8848 199.735 228.317 212.042 428.687 212.042Z"
            fill="#FF6F69"
          />
          <text
            fill="white"
            style={{ whiteSpace: 'pre' }}
            fontFamily="Inter"
            fontSize="64"
            fontWeight="500"
            letterSpacing="0.005em"
            textAnchor="middle"
          >
            <tspan x="432" y="157.273">
              {
                calcConversionPercent(
                  aggregation.courseSelectViewedTotal,
                  aggregation.courseSelectViewedTotal
                ).displayValue
              }
            </tspan>
          </text>
          <text
            fill="#5D5D5D"
            style={{ whiteSpace: 'pre' }}
            fontFamily="Inter"
            fontSize="48"
            fontWeight="500"
            letterSpacing="0.005em"
          >
            <tspan x="909.464" y="72.4545">
              &#x30b3;&#x30fc;&#x30b9;&#x9078;&#x629e;&#x30da;&#x30fc;&#x30b8;&#x95b2;&#x89a7;&#x6570;
            </tspan>
          </text>
          <text
            fill="#5D5D5D"
            style={{ whiteSpace: 'pre' }}
            fontFamily="Inter"
            fontSize="48"
            fontWeight="500"
            letterSpacing="0.005em"
          >
            <tspan x="909.464" y="72.4545">
              &#x30b3;&#x30fc;&#x30b9;&#x9078;&#x629e;&#x30da;&#x30fc;&#x30b8;&#x95b2;&#x89a7;&#x6570;
            </tspan>
          </text>
          <text
            fill="#5D5D5D"
            style={{ whiteSpace: 'pre' }}
            fontFamily="Inter"
            fontSize="48"
            fontWeight="500"
            letterSpacing="0.005em"
          >
            <tspan x="909.464" y="72.4545">
              &#x30b3;&#x30fc;&#x30b9;&#x9078;&#x629e;&#x30da;&#x30fc;&#x30b8;&#x95b2;&#x89a7;&#x6570;
            </tspan>
          </text>
          <text
            fill="#FF6F69"
            style={{ whiteSpace: 'pre' }}
            fontFamily="Inter"
            fontSize="48"
            fontWeight="500"
            letterSpacing="0.005em"
          >
            <tspan x="909.464" y="72.4545">
              &#x30b3;&#x30fc;&#x30b9;&#x9078;&#x629e;&#x30da;&#x30fc;&#x30b8;&#x95b2;&#x89a7;&#x6570;
            </tspan>
          </text>
          <text
            fill="#5D5D5D"
            style={{ whiteSpace: 'pre' }}
            fontFamily="Inter"
            fontSize="48"
            fontWeight="500"
            letterSpacing="0.005em"
          >
            <tspan x="909.389" y="327.455">
              &#x65e5;&#x6642;&#x9078;&#x629e;&#x30da;&#x30fc;&#x30b8;&#x95b2;&#x89a7;&#x6570;
            </tspan>
          </text>
          <text
            fill="#5D5D5D"
            style={{ whiteSpace: 'pre' }}
            fontFamily="Inter"
            fontSize="48"
            fontWeight="500"
            letterSpacing="0.005em"
          >
            <tspan x="909.389" y="327.455">
              &#x65e5;&#x6642;&#x9078;&#x629e;&#x30da;&#x30fc;&#x30b8;&#x95b2;&#x89a7;&#x6570;
            </tspan>
          </text>
          <text
            fill="#5D5D5D"
            style={{ whiteSpace: 'pre' }}
            fontFamily="Inter"
            fontSize="48"
            fontWeight="500"
            letterSpacing="0.005em"
          >
            <tspan x="909.389" y="327.455">
              &#x65e5;&#x6642;&#x9078;&#x629e;&#x30da;&#x30fc;&#x30b8;&#x95b2;&#x89a7;&#x6570;
            </tspan>
          </text>
          <text
            fill="#F4BD4D"
            style={{ whiteSpace: 'pre' }}
            fontFamily="Inter"
            fontSize="48"
            fontWeight="500"
            letterSpacing="0.005em"
          >
            <tspan x="909.389" y="327.455">
              &#x65e5;&#x6642;&#x9078;&#x629e;&#x30da;&#x30fc;&#x30b8;&#x95b2;&#x89a7;&#x6570;
            </tspan>
          </text>
          <text
            fill="#5D5D5D"
            style={{ whiteSpace: 'pre' }}
            fontFamily="Inter"
            fontSize="48"
            fontWeight="500"
            letterSpacing="0.005em"
          >
            <tspan x="909" y="556.455">
              &#x30d5;&#x30a9;&#x30fc;&#x30e0;&#x95b2;&#x89a7;&#x6570;
            </tspan>
          </text>
          <text
            fill="#5D5D5D"
            style={{ whiteSpace: 'pre' }}
            fontFamily="Inter"
            fontSize="48"
            fontWeight="500"
            letterSpacing="0.005em"
          >
            <tspan x="909" y="556.455">
              &#x30d5;&#x30a9;&#x30fc;&#x30e0;&#x95b2;&#x89a7;&#x6570;
            </tspan>
          </text>
          <text
            fill="#5D5D5D"
            style={{ whiteSpace: 'pre' }}
            fontFamily="Inter"
            fontSize="48"
            fontWeight="500"
            letterSpacing="0.005em"
          >
            <tspan x="909" y="556.455">
              &#x30d5;&#x30a9;&#x30fc;&#x30e0;&#x95b2;&#x89a7;&#x6570;
            </tspan>
          </text>
          <text
            fill="#C1C168"
            style={{ whiteSpace: 'pre' }}
            fontFamily="Inter"
            fontSize="48"
            fontWeight="500"
            letterSpacing="0.005em"
          >
            <tspan x="909" y="556.455">
              &#x30d5;&#x30a9;&#x30fc;&#x30e0;&#x95b2;&#x89a7;&#x6570;
            </tspan>
          </text>
          <text
            fill="#5D5D5D"
            style={{ whiteSpace: 'pre' }}
            fontFamily="Inter"
            fontSize="48"
            fontWeight="500"
            letterSpacing="0.005em"
          >
            <tspan x="909" y="786.455">
              &#x4e88;&#x7d04;&#x5b8c;&#x4e86;
            </tspan>
          </text>
          <text
            fill="#5D5D5D"
            style={{ whiteSpace: 'pre' }}
            fontFamily="Inter"
            fontSize="48"
            fontWeight="500"
            letterSpacing="0.005em"
          >
            <tspan x="909" y="786.455">
              &#x4e88;&#x7d04;&#x5b8c;&#x4e86;
            </tspan>
          </text>
          <text
            fill="#5D5D5D"
            style={{ whiteSpace: 'pre' }}
            fontFamily="Inter"
            fontSize="48"
            fontWeight="500"
            letterSpacing="0.005em"
          >
            <tspan x="909" y="786.455">
              &#x4e88;&#x7d04;&#x5b8c;&#x4e86;
            </tspan>
          </text>
          <text
            fill="#0E9AA7"
            style={{ whiteSpace: 'pre' }}
            fontFamily="Inter"
            fontSize="48"
            fontWeight="500"
            letterSpacing="0.005em"
          >
            <tspan x="909" y="786.455">
              &#x4e88;&#x7d04;&#x5b8c;&#x4e86;
            </tspan>
          </text>
          <text
            fill="white"
            style={{ whiteSpace: 'pre' }}
            fontFamily="Inter"
            fontSize="64"
            fontWeight="500"
            letterSpacing="0.005em"
            textAnchor="middle"
          >
            <tspan x="432" y="397.273">
              {
                calcConversionPercent(
                  aggregation.dateSelectViewedTotal,
                  aggregation.courseSelectViewedTotal
                ).displayValue
              }
            </tspan>
          </text>
          <text
            fill="white"
            style={{ whiteSpace: 'pre' }}
            fontFamily="Inter"
            fontSize="64"
            fontWeight="500"
            letterSpacing="0.005em"
            textAnchor="middle"
          >
            <tspan x="432" y="625.273">
              {
                calcConversionPercent(
                  aggregation.formViewedTotal,
                  aggregation.courseSelectViewedTotal
                ).displayValue
              }
            </tspan>
          </text>
          <text
            fill="white"
            style={{ whiteSpace: 'pre' }}
            fontFamily="Inter"
            fontSize="52"
            fontWeight="500"
            letterSpacing="0.005em"
            textAnchor="middle"
          >
            <tspan x="432" y="843.273">
              {
                calcConversionPercent(
                  aggregation.reservedTotal,
                  aggregation.courseSelectViewedTotal
                ).displayValue
              }
            </tspan>
          </text>
          <text
            fill="black"
            style={{ whiteSpace: 'pre' }}
            fontFamily="Inter"
            fontSize="64"
            fontWeight="500"
            letterSpacing="0.005em"
          >
            <tspan x="909" y="402.273">
              {aggregation.dateSelectViewedTotal.toLocaleString()}&#x56de;
            </tspan>
          </text>
          <text
            fill="black"
            style={{ whiteSpace: 'pre' }}
            fontFamily="Inter"
            fontSize="64"
            fontWeight="500"
            letterSpacing="0.005em"
          >
            <tspan x="909" y="152.273">
              {aggregation.courseSelectViewedTotal.toLocaleString()}&#x56de;
            </tspan>
          </text>
          <text
            fill="black"
            style={{ whiteSpace: 'pre' }}
            fontFamily="Inter"
            fontSize="64"
            fontWeight="500"
            letterSpacing="0.005em"
          >
            <tspan x="909" y="631.273">
              {aggregation.formViewedTotal.toLocaleString()}&#x56de;
            </tspan>
          </text>
          <text
            fill="black"
            style={{ whiteSpace: 'pre' }}
            fontFamily="Inter"
            fontSize="64"
            fontWeight="500"
            letterSpacing="0.005em"
          >
            <tspan x="909" y="861.273">
              {aggregation.reservedTotal.toLocaleString()}&#x4ef6;
            </tspan>
          </text>
        </g>
        <defs>
          <clipPath id="clip0_315_1056">
            <rect width="1906" height="914" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};
