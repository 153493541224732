import { css } from '@emotion/react';
import { MD_BREAKPOINT, SM_BREAKPOINT } from '../../../hooks/use-size-type';

export const dialogStyles = {
  dialog: css`
    .MuiPaper-rounded {
      width: 70%;
      max-width: 750px;
      border-radius: 12px;
      padding-bottom: 32px;
    }
  `,
  header: css`
    position: relative;
    height: 80px;
    line-height: 80px;
    padding: 0 150px;
    background: transparent linear-gradient(270deg, #2a9fa1 0%, #f5c835 100%) 0%
      0% no-repeat padding-box;
    @media screen and (max-width: ${MD_BREAKPOINT}px) {
      padding: 0 62px 0 8px;
    }
  `,
  completeHeader: css`
    background: transparent linear-gradient(270deg, #ed5d35 0%, #f5c835 100%) 0%
      0% no-repeat padding-box;
  `,
  headerText: css`
    font-size: 24px;
    font-weight: bold;
    color: #ffffff;
    padding-top: 24px;
    text-align: center;
    @media screen and (max-width: ${SM_BREAKPOINT}px) {
      font-size: 16px;
    }
  `,
  headerAbsolute: css`
    position: absolute;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    top: 50%;
    right: 10px;
    transform: translate(0, -50%);
  `,
  dialogContent: css`
    width: 756px;
  `,
  closeButton: css`
    margin-left: 24px;
    width: 50px;
    height: 50px;
    border: 1px solid #ffffff;
    color: #ffffff;
    background: #2a9fa1;
  `,
  completeCloseButton: css`
    background: #ed5d35;
  `,
  stepNum: css`
    font-size: 12px;
    color: #ffffff;
    span {
      font-size: 24px;
      color: #eeff40;
    }
  `,
  backButtonWrapper: css`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(calc(-50% - 190px), -50%);
  `,
  errorMessage: css`
    color: red;
  `,
};
