/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import {
  Box,
  Breadcrumbs,
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  Paper,
  Switch,
  TextField,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import axios, { AxiosResponse } from 'axios';
import { useSnackbar } from 'notistack';
import React, { ChangeEvent, useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import useReactRouter from 'use-react-router';
import { Shop } from '../../../../frontend-api/src/interfaces/shop';
import { ShopImage } from '../../@interfaces/shop-setting';
import { API_END_POINT } from '../../api/api';
import { useImageUpload } from '../../api/use-image-upload';
import useWorkspaceSetting from '../../api/use-workspace-setting';
import {
  BreadcrumbLinkItem,
  BreadcrumbTextItem,
  BreadcrumbWorkspaceItem,
} from '../../components/breadcrumb/BreadcrumbItem';
import { commonCss } from '../../components/common-css';
import { ImageCropperDialog } from '../../components/ImageCropperDialog';
import { WorkspacePageLayout } from '../../components/layouts/WorkspacePageLayout';
import { PageTitleAndDescription } from '../../components/PageTitleAndDescription';
import { PreviewHeader } from '../../components/PreviewHeader';
import { PreviewSelectCoursePage } from '../../components/PreviewSelectCoursePage';
import { PreviewSelectShopPage } from '../../components/PreviewSelectShopPage';
import { FullscreenLoading, Head, Main, Root } from '../../components/Shared';
import { SortableImages } from '../../components/SortableImages';
import { useStyles } from '../../components/Styles';
import { Store } from '../../context/GlobalStore';
import { SM_BREAKPOINT, useSizeType } from '../../hooks/use-size-type';
import { DEFAULT_THEME_COLOR } from '../../models/theme';
import { globalColors } from '../../styles/globalColors';
import { convertFileToBase64 } from '../../utils/file';
import { helps } from '../../utils/helps';

interface PageParams {
  workspaceUid: string;
}

type Form = {
  uid: string;
  name: string;
};

const defaultForm: Form = {
  uid: '',
  name: '',
};

const cssStyles = {
  uploadImage: css`
    position: relative;
    width: 116px;
    @media screen and (max-width: ${SM_BREAKPOINT}px) {
      width: 100%;
    }
  `,
  closeIcon: css`
    position: absolute;
    top: -16px;
    right: -16px;

    img: {
      width: 16px;
    }
  `,
};

const SHOP_IMAGE_LIMIT = 10;
export default function ShopThemeSettingPage(props: any) {
  const { globalState, setGlobalState } = useContext(Store);
  const { history, match } = useReactRouter<PageParams>();
  const { workspaceUid } = match.params;
  const validationContext = useForm();
  const { handleSubmit } = validationContext;
  const styles = useStyles();
  const [form, setForm] = useState<Form>(defaultForm);
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [isSubmitAvailable, setIsSubmitAvailable] = useState<boolean>(false);
  const [isSettingLogo, setIsSettingLogo] = useState<boolean>(false);
  const [isSettingImage, setIsSettingImage] = useState<boolean>(false);
  const [shopLogo, setShopLogo] = useState<File | undefined>(undefined);
  const [croppingShopImages, setCroppingShopImages] = useState<File[]>([]);
  const [shopImages, setShopImages] = useState<File[]>([]);
  const [isShowIconCropperDialog, setIsShowIconCropperDialog] =
    useState<boolean>(false);
  const [isShowLogoCropperDialog, setIsShowLogoCropperDialog] =
    useState<boolean>(false);
  const { workspaceSetting } = useWorkspaceSetting(workspaceUid);
  const uploadImage = useImageUpload();

  const { enqueueSnackbar } = useSnackbar();
  const { isSpSize } = useSizeType();

  useEffect(() => {
    setIsSubmitAvailable(isValidateFunc());
  }, [form.uid, form.name]);

  const isValidateFunc = (): boolean => {
    if (!form.uid.trim()) {
      return false;
    }

    if (!form.name.trim()) {
      return false;
    }

    return true;
  };

  const save = (completedHandler: () => void) => {
    const json = {
      uid: form.uid,
      name: form.name,
    };
    setOpenBackdrop(true);
    axios
      .post(`${API_END_POINT}/app/ws/${workspaceUid}/shops`, json, {
        headers: {
          Authorization: globalState.session?.idToken,
        },
      })
      .then((res: AxiosResponse<Shop>) => {
        enqueueSnackbar('店舗を追加しました。', { variant: 'success' });
        if (
          (isSettingImage && shopImages.length > 0) ||
          (isSettingLogo && shopLogo !== undefined)
        ) {
          imageUpload(res.data, () => {
            completedHandler();
          });
        } else {
          completedHandler();
          setOpenBackdrop(false);
        }
      })
      .catch((e) => {
        if (e.response && e.response.status === 409) {
          enqueueSnackbar(
            '同一IDの店舗が存在するため、追加できませんでした。',
            { variant: 'error' }
          );
        } else {
          enqueueSnackbar('店舗が追加できませんでした。', { variant: 'error' });
        }
        setOpenBackdrop(false);
      });
  };

  const imageUpload = async (shop: Shop, completedHandler: () => void) => {
    let json = {
      userScriptHead: '',
      userScriptBody: '',
      headline1: '',
      headline2: '',
      images: [] as ShopImage[],
    };
    const params = new FormData();

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const headers: any = {
      Authorization: globalState.session?.idToken,
    };

    let logoFileBase64: string | undefined;
    if (isSettingLogo && shopLogo) {
      if (process.env.REACT_APP_STAGE === 'local') {
        logoFileBase64 = await convertFileToBase64(shopLogo);
      } else {
        params.append('logoFile', shopLogo);
      }
    }

    const images: ShopImage[] = [];
    if (isSettingImage && shopImages.length > 0) {
      setOpenBackdrop(true);
      for (const shopImage of shopImages) {
        const url = await uploadImage(workspaceUid, shop, shopImage);
        images.push({ url });
      }
    }

    json = { ...json, images };

    params.append(
      'json',
      JSON.stringify({
        ...json,
        ...(logoFileBase64 && {
          logoMimetype: shopLogo?.type,
          logoFileBase64,
        }),
      })
    );
    setOpenBackdrop(true);

    axios
      .post(`${API_END_POINT}/app/shops/${shop.id}/setting-theme`, params, {
        headers,
      })
      .then(() => {
        enqueueSnackbar('画像を保存しました。', { variant: 'success' });
        completedHandler();
      })
      .catch((e) => {
        enqueueSnackbar('画像が保存できませんでした。', { variant: 'error' });
      })
      .finally(() => {
        setOpenBackdrop(false);
      });
  };

  const handleClickCancel = () => {
    history.push(`/a/${workspaceUid}/`);
  };

  const handleChangeUid = (e: ChangeEvent<HTMLInputElement>) => {
    const uid = e.target.value;
    setForm({ ...form, uid });
  };

  const handleChangeName = (e: ChangeEvent<HTMLInputElement>) => {
    const name = e.target.value;
    setForm({ ...form, name });
  };

  const handleClickAddContinue = () => {
    save(() => {
      setForm(defaultForm);
    });
  };

  const handleSubmitForm = (e: React.FormEvent) => {
    save(() => {
      history.push(`/a/${workspaceUid}/`);
    });
  };

  const handleUpdatedLogo = function (e: React.ChangeEvent<HTMLInputElement>) {
    e.preventDefault();

    if (e.currentTarget.files === null) throw new Error('event is NULL');

    const file = e.currentTarget.files[0];

    if (file && /image.*/.exec(file.type)) {
      setShopLogo(file);
      setIsShowLogoCropperDialog(true);
    }
  };

  const handleUpdatedImage = function (e: React.ChangeEvent<HTMLInputElement>) {
    e.preventDefault();

    if (e.currentTarget.files === null) throw new Error('event is NULL');

    const files = Array.from(e.currentTarget.files);

    if (files.length + shopImages.length > SHOP_IMAGE_LIMIT) {
      alert(`設定できる画像は最大で${SHOP_IMAGE_LIMIT}枚です`);
      return;
    }

    if (
      files.length !== 0 &&
      files.every((file) => /image.*/.exec(file.type))
    ) {
      setCroppingShopImages(files);
      setIsShowIconCropperDialog(true);
    }
    e.target.value = '';
  };

  const handleCropIconImage = async (croppedImageSrc: string) => {
    const newFile: File = await fetch(croppedImageSrc)
      .then(async (r) => r.blob())
      .then(
        (blobFile) =>
          new File([blobFile], croppingShopImages[0].name, {
            type: croppingShopImages[0].type,
          })
      );
    setShopImages((prev) => [...prev, newFile]);
    if (croppingShopImages.length === 1) {
      setIsShowIconCropperDialog(false);
    }
    setCroppingShopImages((prev) => prev.slice(1));
  };

  const handleCropLogo = async (croppedImageSrc: string) => {
    const newFile: File = await fetch(croppedImageSrc)
      .then(async (r) => r.blob())
      .then(
        (blobFile) =>
          new File([blobFile], shopLogo!.name, {
            type: shopLogo!.type || 'image/jpeg',
          })
      );

    setShopLogo(newFile);
    setIsShowLogoCropperDialog(false);
  };

  const buildNavigationButtons = () => {
    return !isSpSize ? (
      <Grid
        container
        alignItems="center"
        justify="flex-end"
        css={css`
          margin-top: 20px;
        `}
      >
        <Button
          css={commonCss.button.right}
          type="button"
          variant="text"
          color="primary"
          onClick={handleClickCancel}
        >
          キャンセル
        </Button>
        <Button
          css={commonCss.button.right}
          type="button"
          variant="contained"
          color="primary"
          disabled={!isSubmitAvailable}
          onClick={handleClickAddContinue}
        >
          &nbsp;追加後に続けて入力&nbsp;
        </Button>
        <Button
          css={commonCss.button.right}
          type="submit"
          variant="contained"
          color="primary"
          disabled={!isSubmitAvailable}
        >
          &nbsp;追加&nbsp;
        </Button>
      </Grid>
    ) : (
      <Box>
        <Grid
          container
          alignItems="center"
          justify="space-around"
          css={css`
            margin-top: 20px;
          `}
        >
          <Button
            css={commonCss.button.center}
            type="button"
            variant="contained"
            color="primary"
            disabled={!isSubmitAvailable}
            onClick={handleClickAddContinue}
          >
            &nbsp;追加後に続けて入力&nbsp;
          </Button>
          <Button
            css={commonCss.button.center}
            type="submit"
            variant="contained"
            color="primary"
            disabled={!isSubmitAvailable}
          >
            &nbsp;追加&nbsp;
          </Button>
        </Grid>
        <Grid
          container
          alignItems="center"
          justify="center"
          css={css`
            margin-top: 20px;
          `}
        >
          <Button
            css={commonCss.button.center}
            type="button"
            variant="text"
            color="primary"
            onClick={handleClickCancel}
          >
            キャンセル
          </Button>
        </Grid>
      </Box>
    );
  };

  const buildContents = () => {
    return (
      <form onSubmit={handleSubmit(handleSubmitForm)}>
        <Grid
          container
          css={css`
            margin-top: 20px;
          `}
        >
          <Grid item xs={6}>
            <h3>店舗の追加</h3>
          </Grid>
          <Grid
            item
            container
            xs={6}
            alignItems="center"
            justify="flex-end"
          ></Grid>
        </Grid>
        <Paper className={styles.paper}>
          <TextField
            label="店舗ID"
            helperText="店舗の一意なIDを設定します(例: 新宿店ならshinjuku)。半角英数小文字、ハイフン、アンダーバーが使用できます。Web予約画面のURLに使用されます。"
            value={form.uid}
            onChange={handleChangeUid}
            required
            inputProps={{ maxLength: 20, pattern: '^[a-z0-9_-]+$' }}
          />
          <TextField
            label="店舗名"
            helperText="店舗名を設定します（例：新宿店）。"
            value={form.name}
            required
            fullWidth
            onChange={handleChangeName}
          />
        </Paper>
        <Paper className={styles.paper}>
          <Box>
            <FormControl component="fieldset">
              <FormControlLabel
                control={
                  <Switch
                    checked={isSettingLogo}
                    onChange={() => {
                      setIsSettingLogo(!isSettingLogo);
                    }}
                    color="primary"
                  />
                }
                label="店舗のロゴ画像を設定する"
              />
            </FormControl>
            <Box mt={2}>
              <Grid container>
                <Grid item xs={12} sm={6}>
                  {isSettingLogo && (
                    <>
                      <Typography
                        variant="body2"
                        css={css`
                          font-size: 14px;
                          margin-bottom: 4px;
                        `}
                      >
                        推奨サイズ:240×160px
                      </Typography>
                      {shopLogo !== undefined ? (
                        <Box css={cssStyles.uploadImage} mt={1}>
                          <img
                            src={URL.createObjectURL(shopLogo)}
                            width="100%"
                            alt=""
                          />
                          <CloseIcon
                            onClick={() => {
                              setShopLogo(undefined);
                            }}
                            css={cssStyles.closeIcon}
                          />
                        </Box>
                      ) : (
                        <Button
                          variant="contained"
                          component="label"
                          color="default"
                        >
                          ロゴ画像を選択する
                          <input
                            type="file"
                            css={css`
                              display: none;
                            `}
                            accept="image/*"
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              handleUpdatedLogo(e);
                            }}
                          />
                        </Button>
                      )}
                    </>
                  )}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <p
                    css={css`
                      font-weight: bold;
                    `}
                  >
                    プレビュー(ヘッダー)
                  </p>
                  <PreviewHeader
                    color={
                      workspaceSetting?.primaryColor || DEFAULT_THEME_COLOR
                    }
                    shopName={form.name !== '' ? form.name : '店舗名'}
                    imageSrc={
                      shopLogo !== undefined
                        ? URL.createObjectURL(shopLogo)
                        : undefined
                    }
                  />
                  <p
                    css={css`
                      font-weight: bold;
                    `}
                  >
                    プレビュー(店舗選択画面)
                  </p>
                  <PreviewSelectShopPage
                    color={
                      workspaceSetting?.primaryColor || DEFAULT_THEME_COLOR
                    }
                    shopName={form.name !== '' ? form.name : '店舗名'}
                    imageSrc={
                      shopLogo !== undefined
                        ? URL.createObjectURL(shopLogo)
                        : undefined
                    }
                  />
                </Grid>
              </Grid>
            </Box>
          </Box>
          <Box mt={4}>
            <FormControl component="fieldset">
              <FormControlLabel
                control={
                  <Switch
                    checked={isSettingImage}
                    onChange={() => {
                      setIsSettingImage(!isSettingImage);
                    }}
                    color="primary"
                  />
                }
                label="店舗のイメージ画像を設定する(最大10枚)"
              />
            </FormControl>
            <Box mt={2}>
              <Grid container>
                <Grid item xs={12} sm={6}>
                  {isSettingImage && (
                    <>
                      <Typography
                        variant="body2"
                        css={css`
                          font-size: 14px;
                          margin-bottom: 4px;
                        `}
                      >
                        推奨サイズ:750×420px
                      </Typography>
                      <SortableImages
                        images={shopImages.map((s) => ({
                          url: URL.createObjectURL(s),
                        }))}
                        onChangeImages={async (images) => {
                          const newShopImages = [];
                          for (const [index, image] of Object.entries(images)) {
                            const imageBlob = await fetch(image.url).then(
                              async (r) => r.blob()
                            );
                            const imageFile = new File(
                              [imageBlob],
                              `file-${index}`,
                              { type: imageBlob.type }
                            );
                            newShopImages.push(imageFile);
                            URL.revokeObjectURL(image.url);
                          }
                          setShopImages(newShopImages);
                        }}
                      />
                      {shopImages.length < SHOP_IMAGE_LIMIT && (
                        <Button
                          variant="contained"
                          component="label"
                          color="default"
                          css={css`
                            margin-top: 10px;
                          `}
                        >
                          店舗画像を追加する
                          <input
                            type="file"
                            css={css`
                              display: none;
                            `}
                            accept="image/*"
                            multiple
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              handleUpdatedImage(e);
                            }}
                          />
                        </Button>
                      )}
                    </>
                  )}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <p
                    css={css`
                      font-weight: bold;
                    `}
                  >
                    プレビュー(コース選択画面)
                  </p>
                  <PreviewSelectCoursePage
                    color={
                      workspaceSetting?.primaryColor || DEFAULT_THEME_COLOR
                    }
                    shopName={form.name !== '' ? form.name : '店舗名'}
                    headline1={workspaceSetting?.headline1}
                    headline2={workspaceSetting?.headline2}
                    images={shopImages.map((s) => ({
                      url: URL.createObjectURL(s),
                    }))}
                  />
                </Grid>
              </Grid>
            </Box>
          </Box>
          {isShowIconCropperDialog && croppingShopImages.length !== 0 && (
            <ImageCropperDialog
              file={croppingShopImages[0]}
              onCropImage={handleCropIconImage}
              onClose={() => {
                setIsShowIconCropperDialog(false);
              }}
              aspect={375 / 210}
            />
          )}
          {isShowLogoCropperDialog && shopLogo !== undefined && (
            <ImageCropperDialog
              file={shopLogo}
              onCropImage={handleCropLogo}
              onClose={() => {
                setIsShowLogoCropperDialog(false);
              }}
              aspect={260 / 140}
            />
          )}
        </Paper>
        {buildNavigationButtons()}
      </form>
    );
  };

  const pageTitle = '店舗の追加';

  return (
    <Root>
      <Head title={pageTitle} />
      <Main>
        <WorkspacePageLayout
          workspaceUid={workspaceUid}
          current="shops"
          breadcrumbs={
            <Breadcrumbs aria-label="breadcrumb">
              <BreadcrumbLinkItem to={`/`}>ホーム</BreadcrumbLinkItem>
              <BreadcrumbWorkspaceItem workspaceUid={workspaceUid} />
              <BreadcrumbTextItem>{pageTitle}</BreadcrumbTextItem>
            </Breadcrumbs>
          }
          helpId={helps.workspace.shopAdd}
        >
          <PageTitleAndDescription
            title="ワークスペース"
            subTitle={pageTitle}
            description="新しい店舗を追加します。"
            themeColor={globalColors.workspace}
          />
          {buildContents()}
        </WorkspacePageLayout>
      </Main>
      <FullscreenLoading open={openBackdrop} />
    </Root>
  );
}
