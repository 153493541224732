/** @jsxImportSource @emotion/react */

import { Grid } from '@material-ui/core';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import useReactRouter from 'use-react-router';
import useShop from '../../../api/use-shop';
import useSlotSettings from '../../../api/use-slot-settings';
import {
  BreadcrumbLinkItem,
  BreadcrumbShopItem,
  BreadcrumbTextItem,
  BreadcrumbWorkspaceItem,
} from '../../../components/breadcrumb/BreadcrumbItem';
import { commonCss } from '../../../components/common-css';
import { ShopPageLayout } from '../../../components/layouts/ShopPageLayout';
import LinkButton from '../../../components/LinkButton';
import { PageTitleAndDescription } from '../../../components/PageTitleAndDescription';
import { Head, Main, Root } from '../../../components/Shared';
import Spinner from '../../../components/Spinner';
import { globalColors } from '../../../styles/globalColors';
import { helps } from '../../../utils/helps';
import ShopSlotSettingsTable from './ShopSlotSettingsTable';

interface PageParams {
  workspaceUid: string;
  shopId: string;
}

export default function ShopSlotSettingsPage(props: any) {
  const { match } = useReactRouter<PageParams>();
  const { workspaceUid, shopId } = match.params;
  const { shop, isLoadingShop } = useShop(shopId);
  const { slotSettings, isLoadingSlotSettins } = useSlotSettings(shopId);

  const buildContents = () => (
    <>
      <Grid container alignItems="center">
        <Grid item xs={5}>
          <h3>
            予約枠
            <br css={commonCss.forSP} />
            （基本設定）
          </h3>
        </Grid>
        <Grid
          item
          container
          xs={7}
          alignItems="center"
          justifyContent="flex-end"
        >
          <LinkButton
            to={`/a/${workspaceUid}/shops/${shopId}/settings/courses/chooser`}
            label="使用するコースを編集"
          />
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item container xs={12} alignItems="center">
          {isLoadingSlotSettins || isLoadingShop ? (
            <Spinner loading={true} />
          ) : (
            <ShopSlotSettingsTable
              workspaceUid={workspaceUid}
              shopId={shopId}
              shopUid={shop.uid}
              slotSettings={slotSettings}
            />
          )}
        </Grid>
      </Grid>
    </>
  );

  return (
    <Root>
      <Head title={`予約枠（基本設定） - ${shop?.name || ''}`} />
      <Main>
        <ShopPageLayout
          workspaceUid={workspaceUid}
          shopId={shopId}
          current="slot-basic"
          helpId={helps.shop.setting.courseSlot}
          breadcrumbs={
            <Breadcrumbs aria-label="breadcrumb">
              <BreadcrumbLinkItem to={`/`}>ホーム</BreadcrumbLinkItem>
              <BreadcrumbWorkspaceItem workspaceUid={workspaceUid} />
              <BreadcrumbShopItem workspaceUid={workspaceUid} shop={shop} />
              <BreadcrumbTextItem>予約枠基本設定</BreadcrumbTextItem>
            </Breadcrumbs>
          }
        >
          <PageTitleAndDescription
            title="店舗"
            subTitle="予約枠基本設定"
            description="予約受付可能日、可能時間、可能枠数の基本的な設定を行います。"
            themeColor={globalColors.shop}
          />
          {buildContents()}
        </ShopPageLayout>
      </Main>
    </Root>
  );
}
