/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
} from '@material-ui/core';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import OpenInNew from '@material-ui/icons/OpenInNew';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { ShopCourse } from '../../../@interfaces/course';
import LinkRouter from '../../../components/LinkRouter';
import { SM_BREAKPOINT, useSizeType } from '../../../hooks/use-size-type';
import { buildPublicCourseUrl } from '../../../utils/urls';
import useShopCoursesSettingSortOrder from '../../../api/use-shop-courses-setting-sort-order';

const styles = {
  publicShopUrl: css`
    font-size: 11px;
    color: #999;
  `,
  openIcon: css`
    height: 12px;
    vertical-align: middle;
  `,
  nameCell: css`
    width: 40%;
  `,
  urlCell: css`
    width: 60%;
    @media screen and (max-width: ${SM_BREAKPOINT}px) {
      max-width: 120px;
      overflow: scroll;
    }
  `,
  actionCell: css`
    text-align: center;
  `,
};

interface Props {
  workspaceUid: string;
  shopId: string;
  shopUid: string;
  courses: ShopCourse[];
}

export default function ShopCoursesTable(props: Props) {
  const { workspaceUid, shopId, shopUid, courses } = props;
  const { isSpSize } = useSizeType();
  const { shopCoursesSettingSortOrderApi, isLoadingSortOrder } =
    useShopCoursesSettingSortOrder();
  const [coursesState, setCourses] = useState<ShopCourse[]>(courses);

  const handleChangeSortOrder = async (
    courseId: number,
    changeValue: number,
    index: number
  ) => {
    // 配列最初で上、最後で下の場合処理しない
    if (
      (changeValue > 0 && coursesState[0].id === courseId) ||
      (changeValue < 0 && coursesState.slice(-1)[0].id === courseId)
    ) {
      return;
    }
    // コースが削除された場合を考慮しindexを使用する
    const data = coursesState
      .map((c, i) => {
        let order = 0;
        // 上を押すと対象が１つ上に移動
        if (c.id === courseId && changeValue > 0) {
          order = i;
          // 下を押すと対象が１つ下に移動
        } else if (c.id === courseId && changeValue < 0) {
          order = i + 2;
          // 上を押すと対象の１つ上のコースが１つ下に移動
        } else if (i + 1 === index && changeValue > 0) {
          order = i + 2;
          // 下を押すと対象の１つ下のコースが１つ上に移動
        } else if (i - 1 === index && changeValue < 0) {
          order = i;
        } else {
          order = i + 1;
        }
        return {
          ...c,
          sortOrder: order,
        };
      })
      .sort((a, b) => {
        return a.sortOrder < b.sortOrder ? -1 : 1;
      });
    setCourses(data);
    const sortData = data.map((c) => {
      return {
        courseId: c.id,
        sortOrder: c.sortOrder,
      };
    });
    await shopCoursesSettingSortOrderApi(shopId, sortData);
  };

  return isSpSize ? (
    <>
      {coursesState.map((course, index) => (
        <TableContainer
          component={Paper}
          key={index}
          css={css`
            margin: 16px auto;
          `}
        >
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell css={styles.nameCell}>コース名</TableCell>
                <TableCell css={styles.urlCell}>Web予約画面URL</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow key={course.id}>
                <TableCell css={styles.nameCell} scope="row">
                  {course.name}
                </TableCell>
                <TableCell scope="row" css={styles.urlCell}>
                  <a
                    css={styles.publicShopUrl}
                    href={buildPublicCourseUrl(
                      workspaceUid,
                      shopUid,
                      course.uid
                    )}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <OpenInNew css={styles.openIcon} />
                    {buildPublicCourseUrl(workspaceUid, shopUid, course.uid)}
                  </a>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell css={styles.actionCell} scope="row" colSpan={2}>
                  <LinkRouter
                    to={`/a/${workspaceUid}/shops/${shopId}/settings/courses/${course.id}/course-slot-setting`}
                  >
                    編集
                  </LinkRouter>
                  <IconButton
                    onClick={() => handleChangeSortOrder(course.id, 1, index)}
                    size="small"
                  >
                    <ArrowUpwardIcon />
                  </IconButton>
                  <IconButton
                    onClick={() => handleChangeSortOrder(course.id, -1, index)}
                    size="small"
                  >
                    <ArrowDownwardIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      ))}
    </>
  ) : (
    <TableContainer component={Paper}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>コース名</TableCell>
            <TableCell>Web予約画面URL</TableCell>
            <TableCell>アクション</TableCell>
            <TableCell>表示順</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {coursesState.map((course, index) => (
            <TableRow key={course.id}>
              <TableCell scope="row">{course.name}</TableCell>
              <TableCell scope="row">
                <a
                  css={styles.publicShopUrl}
                  href={buildPublicCourseUrl(workspaceUid, shopUid, course.uid)}
                  target="_blank"
                  rel="noreferrer"
                >
                  <OpenInNew css={styles.openIcon} />
                  {buildPublicCourseUrl(workspaceUid, shopUid, course.uid)}
                </a>
              </TableCell>
              <TableCell scope="row">
                <LinkRouter
                  to={`/a/${workspaceUid}/shops/${shopId}/settings/courses/${course.id}/course-slot-setting`}
                >
                  編集
                </LinkRouter>
              </TableCell>
              <TableCell scope="row">
                <IconButton
                  onClick={() => handleChangeSortOrder(course.id, 1, index)}
                  size="small"
                >
                  <ArrowUpwardIcon />
                </IconButton>
                <IconButton
                  onClick={() => handleChangeSortOrder(course.id, -1, index)}
                  size="small"
                >
                  <ArrowDownwardIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
