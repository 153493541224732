/** @jsxImportSource @emotion/react */

import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { LineMessagingChannel } from '../../../@interfaces/line-messaging-channel';
import LinkRouter from '../../../components/LinkRouter';
import { ShareButton } from '../../../components/ShareButton';
import useInflowSources from '../../../api/use-inflow-sources';
import useShops from '../../../api/use-shops';

interface Props {
  workspaceUid: string;
  lineMessagingChannels: LineMessagingChannel[];
}

export default function WorkspaceLineMessagingChannelsTable(props: Props) {
  const { workspaceUid, lineMessagingChannels } = props;
  const { inflowSources } = useInflowSources(workspaceUid);
  const { shops } = useShops(workspaceUid);

  return (
    <TableContainer component={Paper}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>チャネル名(チャネルID)</TableCell>
            <TableCell>LINE用予約URL</TableCell>
            <TableCell>アクション</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {lineMessagingChannels.map((channel, index) => (
            <TableRow key={channel.id}>
              <TableCell scope="row">
                {channel.channelName}({channel.channelId})
              </TableCell>
              <TableCell scope="row">
                <ShareButton
                  workspaceUid={workspaceUid}
                  channelKey={channel.channelKey}
                  inflowSources={inflowSources}
                  shops={shops}
                  lineMessagingChannels={lineMessagingChannels}
                />
              </TableCell>
              <TableCell scope="row">
                <LinkRouter
                  to={`/a/${workspaceUid}/settings/line/messaging-channels/${channel.id}`}
                >
                  編集
                </LinkRouter>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
