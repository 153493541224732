import { css } from '@emotion/react';

export const commonCss = {
  button: {
    right: css`
      margin-left: 10px;
      min-width: 120px;
      padding-right: 10px;
      padding-left: 10px;
    `,
    center: css`
      min-width: 120px;
      padding-right: 10px;
      padding-left: 10px;
    `,
  },
  paper: css`
    padding: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
  `,
  deletedRow: css`
    background-color: #dddddd;
  `,
  noWrap: css`
    white-space: nowrap;
  `,
  noPrint: {
    '@media print': {
      display: 'none',
    },
  },
  print: {
    display: 'none',
    '@media print': {
      display: 'block',
    },
  },
  forPC: css`
    @media (max-width: 599px) {
      display: none;
    }
  `,
  forSP: css`
    display: none;
    @media (max-width: 599px) {
      display: block;
    }
  `,
  previewWrapper: css`
    width: 375px;
    @media (max-width: 599px) {
      width: 300px;
    }
  `,
};
