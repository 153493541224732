import useReactRouter from 'use-react-router';

export const useStoreParams = () => {
  const { history, location } = useReactRouter();
  const storeParams = (paramObj: { [name: string]: string | null }) => {
    const searchParams = new URLSearchParams(location.search);

    const hasChanged = Object.entries(paramObj).some(
      ([key, value]) => searchParams.get(key) !== value
    );

    if (!hasChanged) {
      return;
    }

    Object.entries(paramObj).forEach(([key, value]) => {
      if (value != null) {
        searchParams.set(key, value);
      } else {
        searchParams.delete(key);
      }
    });

    history.push({
      pathname: location.pathname,
      search: `${searchParams.toString()}`,
    });
  };

  return { storeParams };
};
