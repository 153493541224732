/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { Breadcrumbs } from '@material-ui/core';
import React from 'react';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import useReactRouter from 'use-react-router';
import useShop from '../../../api/use-shop';
import {
  BreadcrumbLinkItem,
  BreadcrumbShopItem,
  BreadcrumbTextItem,
  BreadcrumbWorkspaceItem,
} from '../../../components/breadcrumb/BreadcrumbItem';
import { ShopPageLayout } from '../../../components/layouts/ShopPageLayout';
import { PageTitleAndDescription } from '../../../components/PageTitleAndDescription';
import { Head, Main, Root } from '../../../components/Shared';
import { useSizeType } from '../../../hooks/use-size-type';
import { globalColors } from '../../../styles/globalColors';
import { helps } from '../../../utils/helps';
import useInsightReservation from '../api/use-insight-reservation';
import { DateRangePreview } from '../components/DateRangePreview';
import { ItemAggregationTable } from '../components/ItemAggregationTable';
import { LegendWithNoComparison } from '../components/LegendWithNoComparison';
import { TimeLineTable } from '../components/TimeLineTable';
import { useComparisonCheckbox } from '../hooks/useComparison';
import { useDateRange } from '../hooks/useDateRange';
import { useItemFilter } from '../hooks/useItemFilter';
import { useTargetDateType } from '../hooks/useTargetDateType';
import {
  colors,
  comparisonColors,
  dateRanges,
  generateUniqueKey,
  mergeDisplayData,
  mergeUniqueKey,
  toDisplayData,
  toDisplayDate,
} from '../utils/insight-utils';

type PageParams = {
  workspaceUid: string;
  shopId: string;
};

export default function InflowSourcePage() {
  const { match, location } = useReactRouter<PageParams>();
  const { workspaceUid, shopId } = match.params;
  const { shop } = useShop(shopId);
  const {
    startDate,
    endDate,
    comparisonStartDate,
    comparisonEndDate,
    aggregationUnit,
    dateRangePicker,
  } = useDateRange();
  const { targetDateType, targetDateTypeSelector } = useTargetDateType();
  const { isSpSize } = useSizeType();
  const { results: originalPrimaryResults } = useInsightReservation(
    shopId,
    startDate.format('YYYY-MM-DD'),
    endDate.format('YYYY-MM-DD'),
    aggregationUnit,
    targetDateType,
    'inflowSource'
  );
  const { showComparison, comparisonCheckbox } = useComparisonCheckbox();
  const { results: originalComparisonResults } = useInsightReservation(
    shopId,
    comparisonStartDate.format('YYYY-MM-DD'),
    comparisonEndDate.format('YYYY-MM-DD'),
    aggregationUnit,
    targetDateType,
    'inflowSource',
    showComparison
  );
  const primaryUniqueKeys = generateUniqueKey(originalPrimaryResults);
  const comparisonUniqueKeys = generateUniqueKey(originalComparisonResults);
  const uniqueKeys = showComparison
    ? mergeUniqueKey(primaryUniqueKeys, comparisonUniqueKeys)
    : primaryUniqueKeys;

  const { itemFilter, filteredItems } = useItemFilter(uniqueKeys, '流入元');

  const primaryTargetDates = dateRanges(
    startDate,
    endDate,
    aggregationUnit === 'daily' ? 'day' : 'month'
  );

  const comparisonTargetDates = dateRanges(
    comparisonStartDate,
    comparisonEndDate,
    aggregationUnit === 'daily' ? 'day' : 'month'
  );
  const primaryResults = originalPrimaryResults?.filter((result) =>
    filteredItems.some((i) => i.key === result.aggregationId)
  );
  const comparisonResults = originalComparisonResults.filter((result) =>
    filteredItems.some((i) => i.key === result.aggregationId)
  );
  // 将来的にバックエンド側で歯抜けを埋める処理を追加するのもあり
  const primaryDisplayData = toDisplayData(
    uniqueKeys,
    primaryTargetDates,
    primaryResults
  );
  const comparisonDisplayData = toDisplayData(
    uniqueKeys,
    comparisonTargetDates,
    comparisonResults
  );
  const displayData = showComparison
    ? mergeDisplayData(primaryDisplayData, comparisonDisplayData)
    : primaryDisplayData;

  const buildContents = () => {
    return (
      <div>
        <div
          css={css`
            display: flex;
            justify-content: space-between;
            margin-bottom: 1em;
            gap: 2em;
            flex-wrap: wrap;
          `}
        >
          <div
            css={css`
              display: flex;
              gap: 2em;
              flex-wrap: wrap;
            `}
          >
            {dateRangePicker}
            {targetDateTypeSelector}
            {comparisonCheckbox}
          </div>
          <div
            css={css`
              margin-bottom: 1em;
            `}
          >
            <DateRangePreview
              primaryStartDate={startDate}
              primaryEndDate={endDate}
              comparisonStartDate={comparisonStartDate}
              comparisonEndDate={comparisonEndDate}
              showComparison={showComparison}
            />
          </div>
        </div>
        <div>{itemFilter}</div>
        <ResponsiveContainer
          css={css`
            margin-top: 1.5em;
          `}
          width="100%"
          height={isSpSize ? 600 : 400}
        >
          <BarChart
            width={1000}
            data={displayData}
            margin={{
              top: 20,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="__date"
              tickFormatter={(tick) =>
                toDisplayDate(tick as string, aggregationUnit)
              }
            />
            <YAxis />
            <Tooltip />
            <Legend
              content={(props) => <LegendWithNoComparison {...props} />}
            />
            {uniqueKeys.map((result, i) => {
              // フィルタにより同じデータで色が変わらないようにするために、全件で回して hide プロパティで非表示にする
              const hide = !filteredItems.some(
                (item) => item.key === result.key
              );

              return (
                <React.Fragment key={result.key}>
                  {showComparison && (
                    <Bar
                      dataKey={`comparison_${result.key}`}
                      name={`${result.label} (比較)`}
                      stackId="comparison"
                      fill={comparisonColors[i % comparisonColors.length]}
                      hide={hide}
                    />
                  )}
                  <Bar
                    dataKey={result.key}
                    name={`${result.label}`}
                    stackId="primary"
                    fill={colors[i % colors.length]}
                    hide={hide}
                  />
                </React.Fragment>
              );
            })}
          </BarChart>
        </ResponsiveContainer>
        <ItemAggregationTable
          uniqueKeys={filteredItems}
          primary={{
            displayData: primaryDisplayData,
            results: primaryResults,
          }}
          comparison={
            showComparison
              ? {
                  displayData: comparisonDisplayData,
                  results: comparisonResults,
                }
              : undefined
          }
          primaryStartDate={startDate}
          primaryEndDate={endDate}
          comparisonStartDate={comparisonStartDate}
          comparisonEndDate={comparisonEndDate}
          itemLabel="コース"
        />
        <TimeLineTable
          uniqueKeys={filteredItems}
          aggregationUnit={aggregationUnit}
          primary={{ displayData: primaryDisplayData, results: primaryResults }}
          comparison={
            showComparison
              ? {
                  displayData: comparisonDisplayData,
                  results: comparisonResults,
                }
              : undefined
          }
        />
      </div>
    );
  };

  return (
    <Root>
      <Head title="統計データ（レポート）- 流入元別詳細" />
      <Main>
        <ShopPageLayout
          workspaceUid={workspaceUid}
          shopId={shopId}
          current="insight"
          helpId={helps.customer.list}
          breadcrumbs={
            <Breadcrumbs aria-label="breadcrumb">
              <BreadcrumbLinkItem to={`/`}>ホーム</BreadcrumbLinkItem>
              <BreadcrumbWorkspaceItem workspaceUid={workspaceUid} />
              <BreadcrumbShopItem workspaceUid={workspaceUid} shop={shop} />
              <BreadcrumbLinkItem
                to={`/a/${workspaceUid}/shops/${shopId}/insight${location.search}`}
              >
                統計データ（レポート）
              </BreadcrumbLinkItem>
              <BreadcrumbTextItem>流入元別詳細</BreadcrumbTextItem>
            </Breadcrumbs>
          }
        >
          <PageTitleAndDescription
            title="店舗"
            subTitle="統計データ（レポート）: 流入元別詳細"
            description="選択した期間内の予約件数の流入元別統計データの確認を行います。"
            themeColor={globalColors.shop}
          />
          {buildContents()}
        </ShopPageLayout>
      </Main>
    </Root>
  );
}
