/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import {
  Box,
  Breadcrumbs,
  Button,
  Chip,
  Collapse,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  IconButton,
  Input,
  InputLabel,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem as MuiMenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import CloseIcon from '@material-ui/icons/Close';
import CreateNewFolderIcon from '@material-ui/icons/CreateNewFolder';
import DeleteIcon from '@material-ui/icons/Delete';
import FolderIcon from '@material-ui/icons/Folder';
import NoteIcon from '@material-ui/icons/Note';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import { Alert } from '@material-ui/lab';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import React, {
  ChangeEvent,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useForm } from 'react-hook-form';
import useReactRouter from 'use-react-router';
import { Course, ShopCourse } from '../../../@interfaces/course';
import { Reminder } from '../../../@interfaces/reminder';
import { Shop } from '../../../@interfaces/shop';
import {
  MenuCourse,
  MenuGroup,
  MenuItem,
  MenuSetting,
  ReminderType,
  WebReservationLimitType,
} from '../../../@interfaces/shop-setting';
import { API_END_POINT } from '../../../api/api';
import useShop from '../../../api/use-shop';
import useShopCourses from '../../../api/use-shop-courses';
import useShopSetting from '../../../api/use-shop-setting';
import { useWorkspaceFeature } from '../../../api/use-workspace-feature';
import {
  BreadcrumbLinkItem,
  BreadcrumbShopItem,
  BreadcrumbTextItem,
  BreadcrumbWorkspaceItem,
} from '../../../components/breadcrumb/BreadcrumbItem';
import { commonCss } from '../../../components/common-css';
import { ImageCropperDialog } from '../../../components/ImageCropperDialog';
import { ShopPageLayout } from '../../../components/layouts/ShopPageLayout';
import { PageTitleAndDescription } from '../../../components/PageTitleAndDescription';
import {
  FullscreenLoading,
  Head,
  Main,
  Root,
} from '../../../components/Shared';
import Spinner from '../../../components/Spinner';
import { Store } from '../../../context/GlobalStore';
import { LOGO_BASE_URL } from '../../../models/theme';
import { globalColors } from '../../../styles/globalColors';
import { convertFileToBase64 } from '../../../utils/file';
import { helps } from '../../../utils/helps';
import { randomString } from '../../../utils/string';

interface PageParams {
  workspaceUid: string;
  shopId: string;
}

type Form = {
  webReservationLimitType: WebReservationLimitType;
  webReservationLimitDays: number | undefined;
  webReservationLimitHours: number | undefined;
  webReservationAdvanceDays: number | undefined;
  reminderType: ReminderType;
  emailHeader: string;
  emailFooter: string;
  changeByCustomer: boolean;
  cancelByCustomer: boolean;
  hideCancelReason: boolean;
  menuSetting: MenuSetting | null;
  published: boolean;
  enableResource: boolean;
  reminders: Reminder[];
  canSendSms: boolean;
  smsShopName: string;
};

export default function ShopReservationSettingPage(props: any) {
  const { globalState } = useContext(Store);
  const { match } = useReactRouter<PageParams>();
  const { workspaceUid, shopId } = match.params;
  const validationContext = useForm();
  const { handleSubmit, register } = validationContext;

  const { shop } = useShop(shopId);
  const { shopSetting, isLoadingShopSetting, reloadShopSetting } =
    useShopSetting(shopId);

  const { workspaceFeature, isLoadingWorkspaceFeature } =
    useWorkspaceFeature(workspaceUid);

  const [form, setForm] = useState<Form>({
    webReservationLimitType: 'none',
    webReservationLimitDays: undefined,
    webReservationLimitHours: undefined,
    webReservationAdvanceDays: undefined,
    reminderType: 'none',
    emailHeader: '',
    emailFooter: '',
    changeByCustomer: true,
    cancelByCustomer: true,
    hideCancelReason: false,
    menuSetting: null,
    published: true,
    enableResource: false,
    reminders: [],
    canSendSms: false,
    smsShopName: '',
  });
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    if (!shopSetting) {
      return;
    }
    setForm({
      webReservationLimitType: shopSetting.webReservationLimitType || 'none',
      webReservationLimitDays:
        shopSetting.webReservationLimitDays == undefined
          ? 1
          : shopSetting.webReservationLimitDays,
      webReservationLimitHours:
        shopSetting.webReservationLimitHours == undefined
          ? 0
          : shopSetting.webReservationLimitHours,
      webReservationAdvanceDays: shopSetting.webReservationAdvanceDays,
      reminderType:
        shopSetting?.reminders && shopSetting.reminders.length
          ? 'email'
          : 'none',
      emailHeader: shopSetting.emailHeader,
      emailFooter: shopSetting.emailFooter,
      changeByCustomer: Boolean(shopSetting.changeByCustomer), // 実態は number なので MUI の警告解消のためにキャストする (他も同様)
      cancelByCustomer: Boolean(shopSetting.cancelByCustomer),
      hideCancelReason: Boolean(shopSetting.hideCancelReason),
      menuSetting: shopSetting.menuSetting,
      published: Boolean(shopSetting.published),
      enableResource: Boolean(shopSetting.enableResource),
      reminders:
        shopSetting?.reminders && shopSetting.reminders.length
          ? shopSetting.reminders
          : [
              {
                shopId: Number(shopId),
                reminderDays: 1,
                reminderHours: 18,
              },
            ],
      canSendSms: !!shopSetting.canSendSms,
      smsShopName: shopSetting.smsShopName ?? '',
    });
  }, [shopId, shopSetting]);

  const handleChangeWebReservationLimitType = (
    e: ChangeEvent<HTMLInputElement>
  ) => {
    const webReservationLimitType = e.target.value as WebReservationLimitType;
    setForm({ ...form, webReservationLimitType });
  };

  const handleChangeWebReservationLimitDays = (
    e: React.ChangeEvent<{ value: unknown }>
  ) => {
    const webReservationLimitDays = e.target.value as number;
    if (webReservationLimitDays >= 0 && webReservationLimitDays <= 90) {
      setForm({ ...form, webReservationLimitDays });
    }
  };

  const handleChangeWebReservationLimitHours = (
    e: ChangeEvent<HTMLInputElement>
  ) => {
    const webReservationLimitHours = parseInt(e.target.value);
    if (webReservationLimitHours >= 0 && webReservationLimitHours <= 23) {
      setForm({ ...form, webReservationLimitHours });
    }
  };

  const handleChangeWebReservationAdvanceDaysType = (
    e: ChangeEvent<HTMLInputElement>
  ) => {
    const webReservationAdvanceDaysType = e.target.value as 'none' | 'deadline';
    if (webReservationAdvanceDaysType == 'none') {
      setForm({ ...form, webReservationAdvanceDays: undefined });
    } else if (webReservationAdvanceDaysType == 'deadline') {
      setForm({ ...form, webReservationAdvanceDays: 30 });
    }
  };

  const handleChangeWebReservationAdvanceDays = (
    e: ChangeEvent<HTMLInputElement>
  ) => {
    const webReservationAdvanceDays = e.target.value
      ? parseInt(e.target.value)
      : undefined;
    if (
      webReservationAdvanceDays == undefined ||
      webReservationAdvanceDays > 0
    ) {
      setForm({ ...form, webReservationAdvanceDays });
    }
  };

  const handleChangeReminderType = (e: ChangeEvent<HTMLInputElement>) => {
    const reminderType = e.target.value as ReminderType;
    setForm({ ...form, reminderType });
  };

  const handleChangeReminderDays = (value: string, i: number) => {
    const reminderDays = parseInt(value);
    if (reminderDays >= 0 && reminderDays <= 365) {
      setForm({
        ...form,
        reminders: form.reminders.map((reminder, index) => {
          return index == i ? { ...reminder, reminderDays } : reminder;
        }),
      });
    }
  };

  const handleChangeReminderHours = (value: string, i: number) => {
    const reminderHours = parseInt(value);
    if (reminderHours >= 0 && reminderHours <= 23) {
      setForm({
        ...form,
        reminders: form.reminders.map((reminder, index) => {
          return index == i ? { ...reminder, reminderHours } : reminder;
        }),
      });
    }
  };

  const handleChangeEmailHeader = (e: ChangeEvent<HTMLInputElement>) => {
    const emailHeader = e.target.value;
    setForm({ ...form, emailHeader });
  };

  const handleChangeEmailFooter = (e: ChangeEvent<HTMLInputElement>) => {
    const emailFooter = e.target.value;
    setForm({ ...form, emailFooter });
  };

  const handleUpdateMenuSetting = (menuSetting: MenuSetting | null) => {
    setForm({ ...form, menuSetting });
  };

  const handleChangeChangeByCustomer = () => {
    setForm({ ...form, changeByCustomer: !form.changeByCustomer });
  };

  const handleChangeCancelByCustomer = () => {
    setForm({ ...form, cancelByCustomer: !form.cancelByCustomer });
  };

  const handleHideCancelReason = () => {
    setForm({ ...form, hideCancelReason: !form.hideCancelReason });
  };

  const handleChangeChangeByPublished = () => {
    setForm({ ...form, published: !form.published });
  };

  const handleEnableResource = () => {
    setForm({ ...form, enableResource: !form.enableResource });
  };
  const handleSubmitForm = (e: React.FormEvent) => {
    // if (email.length == 0) {
    //   return;
    // }
    const json = {
      webReservationLimitType: form.webReservationLimitType,
      webReservationLimitDays: form.webReservationLimitDays,
      webReservationLimitHours: form.webReservationLimitHours,
      webReservationAdvanceDays: form.webReservationAdvanceDays,
      emailHeader: form.emailHeader,
      emailFooter: form.emailFooter,
      menuSetting: form.menuSetting,
      changeByCustomer: form.changeByCustomer,
      cancelByCustomer: form.cancelByCustomer,
      hideCancelReason: form.hideCancelReason,
      published: form.published,
      enableResource: form.enableResource,
      reminders: form.reminderType == 'email' ? form.reminders : [],
      canSendSms: form.canSendSms,
      smsShopName: form.smsShopName,
    };
    setOpenBackdrop(true);
    axios
      .post(`${API_END_POINT}/app/shops/${shopId}/setting-reservation`, json, {
        headers: {
          Authorization: globalState.session?.idToken,
        },
      })
      .then(() => {
        enqueueSnackbar('設定を保存しました。', { variant: 'success' });
        if (
          Boolean(form.enableResource) !== Boolean(shopSetting?.enableResource)
        ) {
          window.location.reload();
        }
        reloadShopSetting();
      })
      .catch((e) => {
        enqueueSnackbar('設定が保存できませんでした。', { variant: 'error' });
      })
      .finally(() => {
        setOpenBackdrop(false);
      });
  };

  const handleClickDelete = (index: number) => {
    setForm({
      ...form,
      reminders: form.reminders.filter((reminder, i) => {
        return i != index;
      }),
    });
  };

  const handleClickAdd = () => {
    setForm({
      ...form,
      reminders: [
        ...form.reminders,
        {
          shopId: Number(shopId),
          reminderDays: 1,
          reminderHours: 18,
        },
      ],
    });
  };

  const buildContents = () => {
    if (isLoadingShopSetting || isLoadingWorkspaceFeature || !shopSetting) {
      return <Spinner loading={true} />;
    }

    return (
      <form onSubmit={handleSubmit(handleSubmitForm)}>
        <Grid
          container
          css={css`
            margin-top: 20px;
          `}
        >
          <Grid item xs={6}>
            <h3>予約</h3>
          </Grid>
          <Grid
            item
            container
            xs={6}
            alignItems="center"
            justifyContent="flex-end"
          >
            <Button
              css={commonCss.button.right}
              type="submit"
              variant="contained"
              color="primary"
            >
              &nbsp;保存&nbsp;
            </Button>
          </Grid>
        </Grid>
        <Alert severity="info">予約に関する設定をおこないます。</Alert>
        <Paper css={[commonCss.paper]}>
          <FormControl component="fieldset">
            <FormLabel component="legend">Webからの予約の制限</FormLabel>
            <FormControlLabel
              control={
                <Switch
                  checked={form.published}
                  onChange={handleChangeChangeByPublished}
                />
              }
              label="この店舗でWeb予約を可能にする"
            />
            <FormHelperText>
              Webで予約可能をオフにした場合、この店舗の予約は管理画面のみでの利用になります。
            </FormHelperText>
          </FormControl>
          <Divider
            css={css`
              margin: 20px 0px;
            `}
          />
          <FormControl component="fieldset">
            <FormLabel component="legend">
              Webからの直前予約可能時間の制限
            </FormLabel>
            <RadioGroup
              name="webReservationLimitType"
              value={form.webReservationLimitType}
              onChange={handleChangeWebReservationLimitType}
            >
              <FormControlLabel
                value="none"
                control={<Radio />}
                label="予約時間の前なら予約可能"
              />
              <Grid container justifyContent="flex-start" alignItems="center">
                <Grid item>
                  <FormControlLabel
                    value="deadline"
                    control={<Radio />}
                    label="期限を指定"
                  />
                </Grid>
                <Grid item>
                  <Typography
                    css={css`
                      font-size: 14px;
                      color: rgba(0, 0, 0, 0.54);
                    `}
                  >
                    ※ 前日まで予約可能な場合「当日の0時まで」で設定します。
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                container
                alignItems="center"
                css={css`
                  margin-left: 16px;
                `}
              >
                予約時間の
                <FormControl>
                  <Select
                    value={
                      form.webReservationLimitType === 'deadline'
                        ? form.webReservationLimitDays
                        : ''
                    }
                    onChange={handleChangeWebReservationLimitDays}
                    disabled={form.webReservationLimitType !== 'deadline'}
                  >
                    {[...Array(91)].map((_, index) => (
                      <MuiMenuItem value={index} key={index}>
                        {index === 0 ? '当日' : `${index}日前`}
                      </MuiMenuItem>
                    ))}
                  </Select>
                </FormControl>
                の
                <Input
                  type="number"
                  name="deadlineWebReservationLimitHours"
                  inputRef={register({
                    required: form.webReservationLimitType === 'deadline',
                    min: 0,
                    max: 23,
                  })}
                  value={
                    form.webReservationLimitType === 'deadline'
                      ? form.webReservationLimitHours
                      : ''
                  }
                  onChange={handleChangeWebReservationLimitHours}
                  css={css`
                    width: 50px;
                  `}
                  disabled={form.webReservationLimitType !== 'deadline'}
                />
                時までで予約可能
              </Grid>
              <FormControlLabel
                value="before"
                control={<Radio />}
                label="指定時間前までを指定"
              />
              <Grid
                container
                alignItems="center"
                css={css`
                  margin-left: 16px;
                `}
              >
                予約時間の
                <Input
                  type="number"
                  name="beforeWebReservationLimitDays"
                  inputRef={register({
                    required: form.webReservationLimitType === 'before',
                    min: 0,
                    max: 365,
                  })}
                  value={
                    form.webReservationLimitType === 'before'
                      ? form.webReservationLimitDays
                      : ''
                  }
                  onChange={handleChangeWebReservationLimitDays}
                  css={css`
                    width: 50px;
                  `}
                  disabled={form.webReservationLimitType !== 'before'}
                />
                日と
                <Input
                  type="number"
                  name="beforeWebReservationLimitHours"
                  inputRef={register({
                    required: form.webReservationLimitType === 'before',
                    min: 0,
                    max: 23,
                  })}
                  value={
                    form.webReservationLimitType === 'before'
                      ? form.webReservationLimitHours
                      : ''
                  }
                  onChange={handleChangeWebReservationLimitHours}
                  css={css`
                    width: 50px;
                  `}
                  disabled={form.webReservationLimitType !== 'before'}
                />
                時間前まで予約可能
              </Grid>
            </RadioGroup>
          </FormControl>
          <Divider
            css={css`
              margin: 20px 0px;
            `}
          />
          <FormControl component="fieldset">
            <FormLabel component="legend">
              Webからの事前予約日数の制限
            </FormLabel>
            <RadioGroup
              name="reminderType"
              value={form.webReservationAdvanceDays ? 'deadline' : 'none'}
              onChange={handleChangeWebReservationAdvanceDaysType}
            >
              <FormControlLabel
                value="none"
                control={<Radio />}
                label="制限しない"
              />
              <FormControlLabel
                value="deadline"
                control={<Radio />}
                label="制限する"
              />
              <Grid
                container
                alignItems="center"
                css={css`
                  margin-left: 16px;
                `}
              >
                <Input
                  type="number"
                  name="webReservationAdvanceDays"
                  value={form.webReservationAdvanceDays}
                  onChange={handleChangeWebReservationAdvanceDays}
                  css={css`
                    width: 50px;
                  `}
                  disabled={!form.webReservationAdvanceDays}
                />
                日前からWeb予約可能にする
              </Grid>
            </RadioGroup>
          </FormControl>
          <Divider
            css={css`
              margin: 20px 0px;
            `}
          />
          <FormControl component="fieldset">
            <FormLabel component="legend">リマインド</FormLabel>
            <RadioGroup
              name="reminderType"
              value={form.reminderType}
              onChange={handleChangeReminderType}
            >
              <FormControlLabel
                value="none"
                control={<Radio />}
                label="リマインドを送らない"
              />
              <FormControlLabel
                value="email"
                control={<Radio />}
                label="リマインドを送信"
              />
              {form.reminders.map((data, i) => {
                return (
                  <Grid
                    key={i}
                    container
                    alignItems="center"
                    css={css`
                      margin-left: 16px;
                    `}
                  >
                    予約時間の
                    <Input
                      type="number"
                      name="reminderDays"
                      inputRef={register({
                        required: form.reminderType === 'email',
                      })}
                      value={
                        form.reminderType === 'email' ? data.reminderDays : ''
                      }
                      onChange={(e) => {
                        handleChangeReminderDays(e.target.value, i);
                      }}
                      css={css`
                        width: 50px;
                      `}
                      disabled={form.reminderType !== 'email'}
                    />
                    日前の
                    <Input
                      type="number"
                      name="reminderHours"
                      inputRef={register({
                        required: form.reminderType === 'email',
                      })}
                      value={
                        form.reminderType === 'email' ? data.reminderHours : ''
                      }
                      onChange={(e) => {
                        handleChangeReminderHours(e.target.value, i);
                      }}
                      css={css`
                        width: 50px;
                      `}
                      disabled={form.reminderType != 'email'}
                    />
                    時にメールを送信
                    {form.reminders.length > 1 && (
                      <IconButton
                        onClick={() => {
                          handleClickDelete(i);
                        }}
                      >
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    )}
                  </Grid>
                );
              })}
              {form.reminders.length < 5 && (
                <div>
                  <Button
                    variant="text"
                    color="primary"
                    onClick={handleClickAdd}
                  >
                    リマインド設定を追加(5件まで)
                  </Button>
                </div>
              )}
            </RadioGroup>
          </FormControl>
          <Divider
            css={css`
              margin: 20px 0px;
            `}
          />
          <FormControl component="fieldset">
            <FormLabel component="legend">予約変更/キャンセルリンク</FormLabel>
            <FormControlLabel
              control={
                <Switch
                  checked={form.changeByCustomer}
                  onChange={handleChangeChangeByCustomer}
                />
              }
              label="予約変更リンクをメールに表示する"
            />
            <FormControlLabel
              control={
                <Switch
                  checked={form.cancelByCustomer}
                  onChange={handleChangeCancelByCustomer}
                />
              }
              label="予約キャンセルリンクをメールに表示する"
            />
            <FormControlLabel
              control={
                <Switch
                  checked={form.hideCancelReason}
                  onChange={handleHideCancelReason}
                  disabled={!form.cancelByCustomer}
                />
              }
              label="キャンセル理由の入力欄を表示しない"
            />
          </FormControl>
          <Divider
            css={css`
              margin: 20px 0px;
            `}
          />
          <FormControl component="fieldset">
            <FormLabel component="legend">リソースベースの制約</FormLabel>
            <FormControlLabel
              control={
                <Switch
                  checked={form.enableResource}
                  onChange={handleEnableResource}
                />
              }
              label="リソースベースの制約を使用する"
            />
          </FormControl>
        </Paper>
        <h3>店舗メール設定</h3>
        <Paper css={commonCss.paper}>
          <TextField
            label="メール共通ヘッダー"
            name="emailHeader"
            helperText={
              <>
                店舗から送信されるメール（予約確認メール、リマインダなど）の共通ヘッダーを設定します。入力内容に「
                <strong>{'{ご予約者}'}</strong>
                」を入れると、予約者のお名前に置換されます。
              </>
            }
            value={form.emailHeader}
            fullWidth
            multiline
            onChange={handleChangeEmailHeader}
          />
          <TextField
            label="メール共通フッター"
            name="emailFooter"
            helperText={
              <>
                店舗から送信されるメール（予約確認メール、リマインダなど）の共通フッターを設定します。入力内容に「
                <strong>{'{ご予約者}'}</strong>
                」を入れると、予約者のお名前に置換されます。
              </>
            }
            value={form.emailFooter}
            fullWidth
            multiline
            onChange={handleChangeEmailFooter}
          />
        </Paper>
        <h3>SMS送信設定</h3>
        <Paper css={commonCss.paper}>
          <FormControl component="fieldset">
            {workspaceFeature?.isSmsEnabled ? (
              <>
                <FormControlLabel
                  control={
                    <Switch
                      checked={form.canSendSms}
                      onChange={() => {
                        setForm((f) => ({ ...f, canSendSms: !f.canSendSms }));
                      }}
                    />
                  }
                  label="この店舗でSMSを利用する"
                />
                <FormHelperText>
                  本設定をオンにすることで、予約完了メール、予約変更完了メール、キャンセル完了メール、リマインドメールがSMSでも送信されます。
                </FormHelperText>
                {form.canSendSms && (
                  <TextField
                    label="SMS用店舗名"
                    helperText="送信されるSMS（予約確認メール、リマインダなど）で利用する店舗名を入力します(最大17文字)"
                    value={form.smsShopName}
                    fullWidth
                    onChange={(e) => {
                      setForm({ ...form, smsShopName: e.target.value });
                    }}
                    inputProps={{
                      maxLength: 17,
                    }}
                  />
                )}
              </>
            ) : (
              <>
                <Alert severity="warning">
                  SMS送信設定の利用には申請が必要です。詳しくはお問い合わせください。
                  <FormHelperText>
                    本設定を利用することで、予約完了メール、予約変更完了メール、キャンセル完了メール、リマインドメールをSMSでも送信できるようになります。
                  </FormHelperText>
                </Alert>
              </>
            )}
          </FormControl>
        </Paper>
        <MenuSettingSection
          shopId={shopId}
          shop={shop}
          menuSetting={form.menuSetting}
          workspaceUid={workspaceUid}
          onUpdateMenuSetting={handleUpdateMenuSetting}
        />
        <Grid
          container
          css={css`
            margin-top: 20px;
          `}
        >
          <Grid item xs={6}></Grid>
          <Grid
            item
            container
            xs={6}
            alignItems="center"
            justifyContent="flex-end"
          >
            <Button
              css={commonCss.button.right}
              type="submit"
              variant="contained"
              color="primary"
            >
              &nbsp;保存&nbsp;
            </Button>
          </Grid>
        </Grid>
      </form>
    );
  };

  const pageTitle = '予約一般設定';

  return (
    <Root>
      <Head title={`店舗設定 - ${shop?.name || ''}`} />
      <Main>
        <ShopPageLayout
          workspaceUid={workspaceUid}
          shopId={shopId}
          current="reservation-rule"
          helpId={helps.shop.setting.reservation}
          breadcrumbs={
            <Breadcrumbs aria-label="breadcrumb">
              <BreadcrumbLinkItem to={`/`}>ホーム</BreadcrumbLinkItem>
              <BreadcrumbWorkspaceItem workspaceUid={workspaceUid} />
              <BreadcrumbShopItem workspaceUid={workspaceUid} shop={shop} />
              <BreadcrumbTextItem>{pageTitle}</BreadcrumbTextItem>
            </Breadcrumbs>
          }
        >
          <PageTitleAndDescription
            title="店舗"
            subTitle={pageTitle}
            description="店舗の予約の受付期間の設定、メール配信の制御設定を行います。"
            themeColor={globalColors.shop}
          />
          {buildContents()}
        </ShopPageLayout>
      </Main>
      <FullscreenLoading open={openBackdrop} />
    </Root>
  );
}

const createMenuGroup = (index: number) => {
  const menuGroup: MenuGroup = {
    type: 'group',
    uid: randomString(6),
    title: `グループ${index}`,
    description: '',
    buttonLabel: '',
    items: [],
  };
  return menuGroup;
};

const createMenuCourse = (index: number) => {
  const menuGroup: MenuCourse = {
    type: 'course',
    uid: randomString(6),
    courseId: -1,
  };
  return menuGroup;
};

const MenuSettingSection = ({
  shopId,
  shop,
  menuSetting,
  workspaceUid,
  onUpdateMenuSetting,
}: {
  shopId: string;
  shop: Shop;
  menuSetting: MenuSetting | null;
  workspaceUid: string;
  onUpdateMenuSetting: (menuSetting: MenuSetting | null) => void;
}): JSX.Element => {
  const { shopCourses: _shopCourses } = useShopCourses(shopId);

  const shopCourses = useMemo(() => {
    return _shopCourses.filter((c) => c.listing);
  }, [_shopCourses]);

  const usedCourses = useMemo(() => {
    if (!menuSetting) {
      return [];
    }

    const findCoursesInChildren = (items: MenuItem[]): ShopCourse[] => {
      return items.flatMap((item) => {
        if (item.type === 'course') {
          const course = shopCourses.find((c) => c.id === item.courseId);
          return course ? [course] : [];
        } else if (item.type === 'group') {
          return findCoursesInChildren(item.items);
        } else {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const _exhaustiveCheck: never = item;
          return [];
        }
      });
    };

    return findCoursesInChildren(menuSetting.items);
  }, [menuSetting, shopCourses]);

  const unuseCourses: Course[] = useMemo(() => {
    return shopCourses.filter((c) => !usedCourses.includes(c));
  }, [shopCourses, usedCourses]);

  const [selectedItem, setSelectedItem] = useState<MenuItem>();

  const handleChangeEnableMenuSetting = () => {
    if (menuSetting) {
      onUpdateMenuSetting(null);
      setSelectedItem(undefined);
    } else {
      const items: MenuItem[] = [createMenuGroup(1), createMenuGroup(2)];
      onUpdateMenuSetting({ items });
      setSelectedItem(undefined);
    }
  };

  const handleClickItem = (item: MenuItem) => {
    setSelectedItem(item);
  };

  const buildMenuGroup = (items: MenuItem[], item: MenuGroup, deps: number) => {
    const handleClickDelete = () => {
      if (!menuSetting) {
        return;
      }
      const index = items.indexOf(item);
      items.splice(index, 1);
      onUpdateMenuSetting({ ...menuSetting });
    };

    const handleClickUp = () => {
      if (!menuSetting) {
        return;
      }
      const index = items.indexOf(item);
      if (index < 1) {
        return;
      }
      items.splice(index, 1);
      items.splice(index - 1, 0, item);
      onUpdateMenuSetting({ ...menuSetting });
    };

    const handleClickDown = () => {
      if (!menuSetting) {
        return;
      }
      const index = items.indexOf(item);
      if (index === -1 || index === items.length - 1) {
        return;
      }
      items.splice(index, 1);
      items.splice(index + 1, 0, item);
      onUpdateMenuSetting({ ...menuSetting });
    };

    const handleClickAddGroup = () => {
      if (!menuSetting) {
        return;
      }
      item.items.push(createMenuGroup(item.items.length + 1));
      onUpdateMenuSetting({ ...menuSetting });
    };

    const handleClickAddCourse = () => {
      if (!menuSetting) {
        return;
      }
      item.items.push(createMenuCourse(item.items.length + 1));
      onUpdateMenuSetting({ ...menuSetting });
    };

    return (
      <Box key={item.uid}>
        <ListItem
          button
          selected={selectedItem?.uid === item.uid}
          onClick={() => {
            handleClickItem(item);
          }}
          css={css`
            padding-left: ${deps * 50}px;
          `}
        >
          <ListItemIcon>
            <FolderIcon />
          </ListItemIcon>
          <ListItemText primary={item.title} />
          <Tooltip title="グループを追加">
            <IconButton edge="end" onClick={handleClickAddGroup}>
              <CreateNewFolderIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="コースを追加">
            <IconButton edge="end" onClick={handleClickAddCourse}>
              <NoteAddIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="上へ移動">
            <IconButton edge="end" onClick={handleClickUp}>
              <ArrowUpwardIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="下へ移動">
            <IconButton edge="end" onClick={handleClickDown}>
              <ArrowDownwardIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="削除">
            <IconButton edge="end" onClick={handleClickDelete}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </ListItem>
        <Collapse in={true} timeout="auto" unmountOnExit>
          {buildItems(item.items, deps + 1)}
        </Collapse>
      </Box>
    );
  };

  const buildMenuCourse = (
    items: MenuItem[],
    item: MenuCourse,
    deps: number
  ) => {
    const course = shopCourses.find((c) => c.id === item.courseId);
    const handleClickDelete = () => {
      if (!menuSetting) {
        return;
      }
      const index = items.indexOf(item);
      items.splice(index, 1);
      onUpdateMenuSetting({ ...menuSetting });
    };

    const handleClickUp = () => {
      if (!menuSetting) {
        return;
      }
      const index = items.indexOf(item);
      if (index < 1) {
        return;
      }
      items.splice(index, 1);
      items.splice(index - 1, 0, item);
      onUpdateMenuSetting({ ...menuSetting });
    };

    const handleClickDown = () => {
      if (!menuSetting) {
        return;
      }
      const index = items.indexOf(item);
      if (index === -1 || index === items.length - 1) {
        return;
      }
      items.splice(index, 1);
      items.splice(index + 1, 0, item);
      onUpdateMenuSetting({ ...menuSetting });
    };

    const handleChangeCourse = (
      event: React.ChangeEvent<{ value: unknown }>
    ) => {
      if (!menuSetting) {
        return;
      }
      item.courseId = event.target.value as number;
      onUpdateMenuSetting({ ...menuSetting });
    };

    return (
      <ListItem
        key={item.uid}
        button
        selected={selectedItem?.uid === item.uid}
        onClick={() => {
          handleClickItem(item);
        }}
        css={css`
          padding-left: ${deps * 50}px;
        `}
      >
        <ListItemIcon>
          <NoteIcon />
        </ListItemIcon>
        {selectedItem?.uid === item.uid || course?.name ? (
          <FormControl
            css={css`
              width: 100%;
            `}
          >
            <InputLabel>コース</InputLabel>
            <Select
              fullWidth
              value={item.courseId}
              onChange={handleChangeCourse}
              css={css`
                width: calc(100% - 96px);
              `}
            >
              {shopCourses.map((course) => (
                <MuiMenuItem key={course.id} value={course.id}>
                  {course.name}
                </MuiMenuItem>
              ))}
            </Select>
          </FormControl>
        ) : (
          <ListItemText
            primary={'(コースが選択されていません)'}
            css={css`
              padding-right: 72px;
            `}
          />
        )}
        <Tooltip title="上へ移動">
          <IconButton edge="end" onClick={handleClickUp}>
            <ArrowUpwardIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="下へ移動">
          <IconButton edge="end" onClick={handleClickDown}>
            <ArrowDownwardIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="削除">
          <IconButton edge="end" onClick={handleClickDelete}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </ListItem>
    );
  };

  const buildItem = (items: MenuItem[], item: MenuItem, deps: number) => {
    if (item.type === 'group') {
      return buildMenuGroup(items, item, deps);
    } else if (item.type === 'course') {
      return buildMenuCourse(items, item, deps);
    } else {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const _exhaustiveCheck: never = item;
    }
  };

  const buildItems = (items: MenuItem[], deps: number) => {
    return (
      <List component="nav">
        {items.map((item) => buildItem(items, item, deps))}
      </List>
    );
  };

  const buildRootItems = (items: MenuItem[]) => {
    const handleClickAddGroup = () => {
      const newGroup = createMenuGroup(items.length + 1);
      const newItems = [...items, newGroup];
      onUpdateMenuSetting({ ...menuSetting, items: newItems });
    };
    const handleClickAddCourse = () => {
      const newCourse = createMenuCourse(items.length + 1);
      const newItems = [...items, newCourse];
      onUpdateMenuSetting({ ...menuSetting, items: newItems });
    };
    return (
      <List component="nav">
        {items.map((item) => buildItem(items, item, 0))}
        <ListItem button selected={false}>
          <Button variant="contained" onClick={handleClickAddGroup}>
            <CreateNewFolderIcon />
            グループを追加
          </Button>

          <Button
            variant="contained"
            onClick={handleClickAddCourse}
            css={css`
              margin-left: 10px;
            `}
          >
            <NoteAddIcon />
            コースを追加
          </Button>
        </ListItem>
      </List>
    );
  };

  const buildMenuGroupDetail = (item: MenuGroup) => {
    const handleChangeValue = (
      e: ChangeEvent<HTMLInputElement>,
      key: 'title' | 'description' | 'buttonLabel'
    ) => {
      if (!menuSetting) {
        return;
      }
      item[key] = e.target.value;
      onUpdateMenuSetting({ ...menuSetting });
    };
    const handleChangeImage = (newImagePath: string) => {
      if (!menuSetting) {
        return;
      }
      item.imagePath = newImagePath;
      onUpdateMenuSetting({ ...menuSetting });
    };
    return (
      <>
        <Grid item container>
          <TextField
            label="タイトル"
            helperText="グループのタイトルを入力します。"
            value={item.title}
            fullWidth
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              handleChangeValue(event, 'title');
            }}
          />
        </Grid>
        <Grid item container>
          <TextField
            label="説明"
            helperText="グループの説明文を入力します。"
            value={item.description}
            fullWidth
            multiline
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              handleChangeValue(event, 'description');
            }}
          />
        </Grid>
        <Grid item container>
          <TextField
            label="選択ボタンのラベル"
            helperText="選択ボタンのラベルを入力します。未設定の場合は「空席確認・予約する」と表示されます。"
            value={item.buttonLabel || ''}
            fullWidth
            multiline
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              handleChangeValue(event, 'buttonLabel');
            }}
          />
        </Grid>
        <ImageUpload
          workspaceUid={workspaceUid}
          shop={shop}
          uid={item.uid}
          imagePath={item.imagePath}
          updateImagePath={handleChangeImage}
        />
      </>
    );
  };

  const buildDetail = () => {
    const item = selectedItem;
    if (!item) {
      return null;
    } else if (item.type === 'group') {
      return buildMenuGroupDetail(item);
    } else if (item.type === 'course') {
      return null;
    } else {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const _exhaustiveCheck: never = item;
    }
  };

  return (
    <>
      <h3>Web予約画面のメニュー設定</h3>
      <Paper css={commonCss.paper}>
        <FormControlLabel
          control={
            <Switch
              checked={menuSetting != null}
              onChange={handleChangeEnableMenuSetting}
            />
          }
          label="Web予約のコース一覧画面をグルーピングして表示する"
        />
        {menuSetting && (
          <Grid container>
            <Grid item xs={12}>
              未選択のコース:
              {unuseCourses.map((course) => {
                return <Chip key={course.id} label={course.name} />;
              })}
              {unuseCourses.length === 0 && <> 未選択のコースはありません</>}
            </Grid>
            <Grid
              item
              xs={8}
              css={css`
                border-right: solid 1px #ccc;
                margin-bottom: 20px;
              `}
            >
              {menuSetting && buildRootItems(menuSetting.items)}
            </Grid>
            <Grid item xs={4}>
              {buildDetail()}
            </Grid>
          </Grid>
        )}
      </Paper>
    </>
  );
};

const ImageUpload: React.FC<{
  workspaceUid: string;
  shop: Shop;
  uid: string;
  imagePath: string | undefined;
  updateImagePath: (newImagePath: string) => void;
}> = ({ workspaceUid, shop, uid, imagePath, updateImagePath }) => {
  const [beforeLogoFile, setBeforeLogoFile] = useState<File>();
  const [isShowIconCropperDialog, setIsShowIconCropperDialog] =
    useState<boolean>(false);
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const { globalState, setGlobalState } = useContext(Store);
  const { enqueueSnackbar } = useSnackbar();

  const handleChangeFile = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return;
    }
    const uploadFile = Array.from(e.target.files)[0];
    setBeforeLogoFile(uploadFile);
    setIsShowIconCropperDialog(true);
  };

  const handleCropImage = async (croppedImageSrc: string) => {
    const encodeText = (text: string) => {
      return process.env.REACT_APP_STAGE === 'local'
        ? encodeURIComponent(text)
        : text;
    };

    const croppedLogoFile: File = await fetch(croppedImageSrc)
      .then(async (r) => r.blob())
      .then(
        (blobFile) =>
          new File([blobFile], beforeLogoFile?.name || '', {
            type: beforeLogoFile?.type || 'image/jpeg',
          })
      );

    const uploadPath = `${shop.workspaceId}/shops/${shop.id}/uid/${uid}/logo`;
    const params = new FormData();
    const json = {
      uploadPath: encodeText(uploadPath),
    };

    if (process.env.REACT_APP_STAGE === 'local') {
      const fileBase64 = await convertFileToBase64(croppedLogoFile);
      params.append(
        'json',
        JSON.stringify({
          ...json,
          mimetype: croppedLogoFile.type,
          fileBase64,
        })
      );
    } else {
      params.append('json', JSON.stringify(json));
      params.append('file', croppedLogoFile);
    }

    setIsShowIconCropperDialog(false);
    setOpenBackdrop(true);
    axios
      .post(
        `${API_END_POINT}/app/ws/${workspaceUid}/shops/${shop.id}/image-upload`,
        params,
        {
          headers: {
            Authorization: globalState.session?.idToken,
          },
        }
      )
      .then(() => {
        updateImagePath(uploadPath);
      })
      .catch((e) => {
        updateImagePath('');
        enqueueSnackbar('画像を保存できませんでした。', { variant: 'error' });
      })
      .finally(() => {
        setBeforeLogoFile(undefined);
        setOpenBackdrop(false);
      });
  };
  const handleRemoveFile = () => {
    setBeforeLogoFile(undefined);
    updateImagePath('');
  };

  return (
    <>
      <Grid item>
        <p>
          グループ画像
          <span
            css={css`
              font-size: 12px;
            `}
          >
            {' '}
            (推奨サイズ:192×160px)
          </span>
        </p>
        {imagePath ? (
          <>
            <div
              css={css`
                position: relative;
                width: fit-content;
                margin: 0 auto;
              `}
            >
              <img
                src={`${LOGO_BASE_URL}/${imagePath}`}
                width={150}
                css={css`
                  display: block;
                  margin: 0 auto;
                `}
                alt=""
              />
              <IconButton
                css={css`
                  position: absolute;
                  top: -8px;
                  right: -8px;
                  background: #dddddd;
                  width: 32px;
                  height: 32px;
                `}
                onClick={handleRemoveFile}
              >
                <CloseIcon />
              </IconButton>
            </div>
          </>
        ) : (
          <Box mt={2}>
            <Button variant="contained" component="label" color="default">
              画像を選択する
              <input
                type="file"
                css={css`
                  display: none;
                `}
                onChange={handleChangeFile}
              />
            </Button>
          </Box>
        )}
      </Grid>
      <FullscreenLoading open={openBackdrop} />
      {isShowIconCropperDialog && beforeLogoFile && (
        <ImageCropperDialog
          file={beforeLogoFile}
          onCropImage={handleCropImage}
          onClose={() => {
            setIsShowIconCropperDialog(false);
          }}
          aspect={192 / 160}
        />
      )}
    </>
  );
};
