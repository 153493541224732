/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import {
  Box,
  Breadcrumbs,
  Button,
  FormControlLabel,
  Grid,
  Switch,
  TextField,
  Typography,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useState } from 'react';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import useReactRouter from 'use-react-router';
import { CustomerInfo } from '../../../@interfaces/customer';
import useShop from '../../../api/use-shop';
import useWorkspaceSetting from '../../../api/use-workspace-setting';
import {
  BreadcrumbLinkItem,
  BreadcrumbShopItem,
  BreadcrumbTextItem,
  BreadcrumbWorkspaceItem,
} from '../../../components/breadcrumb/BreadcrumbItem';
import { commonCss } from '../../../components/common-css';
import { ShopPageLayout } from '../../../components/layouts/ShopPageLayout';
import LinkButton from '../../../components/LinkButton';
import { Pager } from '../../../components/Pager';
import { PageTitleAndDescription } from '../../../components/PageTitleAndDescription';
import { Head, Main, Root } from '../../../components/Shared';
import Spinner from '../../../components/Spinner';
import { MD_BREAKPOINT, SM_BREAKPOINT } from '../../../hooks/use-size-type';
import { globalColors } from '../../../styles/globalColors';
import { helps } from '../../../utils/helps';
import { useCustomers } from '../api/useCustomers';
import { useCustomersCount } from '../api/useCustomersCount';
import { useShopCustomers } from '../api/useShopCustomers';
import { useShopCustomersCount } from '../api/useShopCustomersCount';
import { CustomerMergeDialog } from '../components/CustomerMergeDialog';
import { CustomerTable } from '../components/CustomerSelector/CustomerTable';

const styles = {
  gridWrapper: css`
    margin-top: 20px;
    @media screen and (max-width: ${MD_BREAKPOINT}px) {
      margin-top: 0;
    }
  `,
  sidebarWrapper: css`
    max-width: 400px;
    @media screen and (max-width: ${MD_BREAKPOINT}px) {
      max-width: none;
    }
  `,
  tableWrapper: css`
    max-width: calc(100vw - 54px);
    @media screen and (max-width: ${SM_BREAKPOINT}px) {
      max-width: calc(100vw - 38px);
      overflow: scroll;
    }
  `,
  title: css`
    display: inline-block;
    @media screen and (max-width: ${SM_BREAKPOINT}px) {
      font-size: 18px;
    }
  `,
  shareUrlButtonWrapper: css`
    @media screen and (max-width: ${SM_BREAKPOINT}px) {
      width: 150px;
    }
  `,
  textField: css`
    width: 100%;
  `,
  tableActions: css`
    padding-left: 15px;
    padding-right: 15px;
  `,
};

type PageParams = {
  workspaceUid: string;
  shopId: string;
};

const COUNT_PER_PAGE = 20;

const splitKeyword = (keyword: string): string[] =>
  keyword
    .trim()
    .split(new RegExp(' |　'))
    .filter((s) => !!s);

export default function CustomerListPage(props: any) {
  const { match } = useReactRouter<PageParams>();
  const { workspaceUid, shopId } = match.params;
  const [keyword, setKeyword] = useState('');
  const [keywords, setKeywords] = useState<string[]>([]);
  const [page, setPage] = useState(1);
  const [includesDeleted, setIncludesDeleted] = useState(false);
  const [viewsAllShops, setViewsAllShops] = useState(false);
  const { data: customers } = useShopCustomers(
    shopId,
    keywords,
    COUNT_PER_PAGE,
    COUNT_PER_PAGE * (page - 1),
    includesDeleted
  );
  const { data: customersCount, isLoading: isLoadingCount } =
    useShopCustomersCount(parseInt(shopId), keywords, includesDeleted);
  const { data: workspaceCustomers } = useCustomers(
    viewsAllShops ? workspaceUid : null,
    keywords,
    COUNT_PER_PAGE,
    COUNT_PER_PAGE * (page - 1),
    includesDeleted
  );
  const { data: workspaceCustomersCount } = useCustomersCount(
    viewsAllShops ? workspaceUid : null,
    keywords,
    includesDeleted
  );
  const { workspaceSetting, isLoadingWorkspaceSetting } =
    useWorkspaceSetting(workspaceUid);

  const count =
    (viewsAllShops ? workspaceCustomersCount?.count : customersCount?.count) ??
    0;
  const maxPage = Math.ceil(count / COUNT_PER_PAGE);
  const listCustomers = viewsAllShops ? workspaceCustomers : customers;

  const [selectedCustomers, setSelectedCustomers] = useState<CustomerInfo[]>(
    []
  );
  const handleAddSelected = (customerId: number) => {
    const customer = listCustomers.find((c) => c.id === customerId);
    if (customer) {
      // 選択された順番に関わらず元の並び順をキープする
      setSelectedCustomers((prev) =>
        [...prev, { ...customer }].sort(
          (a, b) =>
            customers.findIndex((c) => c.id === a.id) -
            customers.findIndex((c) => c.id === b.id)
        )
      );
    }
  };
  const handleRemoveSelected = (customerId: number) => {
    setSelectedCustomers((prev) => prev.filter((p) => p.id !== customerId));
  };

  const canMerge = selectedCustomers.filter((s) => s.lineUserId).length <= 1;

  const handleClickMergeButton = () => {
    if (!canMerge) {
      return;
    }
    setIsOpenModal(true);
  };

  const handleClickUnselectAll = () => {
    setSelectedCustomers([]);
  };

  const [isOpenModal, setIsOpenModal] = useState(false);

  const { shop, isLoadingShop } = useShop(shopId);
  const buildContents = () => {
    if (isLoadingShop || isLoadingWorkspaceSetting) {
      return <Spinner loading={true} />;
    }
    if (!shop) {
      return <div>店舗情報が取得できませんでした。</div>;
    }
    if (!(workspaceSetting?.enableCrm || false)) {
      return <div>顧客管理が有効ではありません。</div>;
    }
    return (
      <Grid container spacing={3} css={styles.gridWrapper}>
        <Grid item xs={12} css={[styles.sidebarWrapper, commonCss.noPrint]}>
          <h3>顧客一覧 ({count !== 0 ? count : '-'})</h3>
        </Grid>
        <Grid item xs={12}>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              setPage(1);
              setKeywords(splitKeyword(keyword));
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs style={{ maxWidth: '300px' }}>
                <TextField
                  value={keyword}
                  onChange={(e) => {
                    setKeyword(e.target.value);
                  }}
                  css={styles.textField}
                  placeholder="キーワードで検索"
                />
              </Grid>
              <Grid item>
                <Button color="primary" type="submit" variant="contained">
                  検索
                </Button>
              </Grid>
              <Grid item>
                <Button
                  type="button"
                  variant="contained"
                  onClick={() => {
                    setKeyword('');
                    setKeywords([]);
                    setPage(1);
                  }}
                >
                  クリア
                </Button>
              </Grid>
            </Grid>
          </form>
          <Grid
            container
            css={css`
              gap: 10px;
              margin-top: 10px;
            `}
          >
            <FormControlLabel
              control={
                <Switch
                  checked={includesDeleted}
                  onChange={() => {
                    setIncludesDeleted((prev) => !prev);
                  }}
                />
              }
              label="削除済みの顧客を含める"
            />
            <FormControlLabel
              control={
                <Switch
                  checked={viewsAllShops}
                  onChange={() => {
                    setViewsAllShops((prev) => !prev);
                  }}
                />
              }
              label="全店舗の顧客を表示"
            />
          </Grid>
        </Grid>
        <Grid container css={styles.tableActions}>
          <Grid item xs={6}>
            {count > 0 && (
              <Pager
                page={page}
                count={count}
                countPerPage={COUNT_PER_PAGE}
                onChangePage={(page) => {
                  if (page <= maxPage) {
                    setPage(page);
                  }
                }}
              />
            )}
          </Grid>
          <Grid
            item
            container
            xs={6}
            css={[commonCss.noPrint]}
            alignItems="flex-end"
            justifyContent="flex-end"
          >
            <LinkButton
              to={`/a/${workspaceUid}/shops/${shopId}/customers/new`}
              label="顧客を追加"
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Box css={styles.tableWrapper}>
            {selectedCustomers.length > 0 && (
              <Alert severity="warning" icon={false}>
                {selectedCustomers.length}件 の顧客を選択中
                <>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={handleClickMergeButton}
                    disabled={!canMerge || selectedCustomers.length <= 1}
                    css={css`
                      margin-left: 10px;
                    `}
                  >
                    顧客を統合
                  </Button>
                  <Button
                    color="primary"
                    variant="text"
                    onClick={handleClickUnselectAll}
                    css={css`
                      margin-left: 10px;
                    `}
                  >
                    選択を解除
                  </Button>
                  {!canMerge && (
                    <Typography
                      color="error"
                      css={css`
                        line-height: 37px;
                        font-size: 14px;
                      `}
                    >
                      ※LINEユーザ同士は統合できません。LINEユーザーを1人に絞ってください。
                    </Typography>
                  )}
                </>
              </Alert>
            )}
            <CustomerTable
              workspaceUid={workspaceUid}
              shopId={shopId}
              customers={listCustomers}
              selectedCustomerIds={selectedCustomers.map((s) => s.id)}
              onAddSelected={handleAddSelected}
              onRemoveSelected={handleRemoveSelected}
            />
          </Box>
        </Grid>
        <Grid item xs={12}>
          {count > 0 && (
            <Pager
              page={page}
              count={count}
              countPerPage={COUNT_PER_PAGE}
              onChangePage={(page) => {
                if (page <= maxPage) {
                  setPage(page);
                }
              }}
            />
          )}
        </Grid>
      </Grid>
    );
  };

  const pageTitle = '顧客管理';

  return (
    <Root>
      <Head title={`${shop?.name || ''}`} />
      <Main>
        <ShopPageLayout
          workspaceUid={workspaceUid}
          shopId={shopId}
          current="customers"
          helpId={helps.customer.list}
          breadcrumbs={
            <Breadcrumbs aria-label="breadcrumb">
              <BreadcrumbLinkItem to={`/`}>ホーム</BreadcrumbLinkItem>
              <BreadcrumbWorkspaceItem workspaceUid={workspaceUid} />
              <BreadcrumbShopItem workspaceUid={workspaceUid} shop={shop} />
              <BreadcrumbTextItem>{pageTitle}</BreadcrumbTextItem>
            </Breadcrumbs>
          }
        >
          <PageTitleAndDescription
            title="店舗"
            subTitle={pageTitle}
            description="顧客ごとの個人情報、利用回数等の管理を行います。"
            themeColor={globalColors.shop}
          />
          {buildContents()}
        </ShopPageLayout>
      </Main>
      <CustomerMergeDialog
        workspaceUid={workspaceUid}
        customers={selectedCustomers}
        open={isOpenModal}
        onClose={() => {
          setIsOpenModal(false);
        }}
      />
    </Root>
  );
}
