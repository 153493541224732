/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { Dialog, DialogTitle, Tab, Tabs } from '@material-ui/core';
import { extend, locale } from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { useState } from 'react';
import {
  CustomerInfo,
  SelectedCustomer,
} from '../../../../@interfaces/customer';
import {
  AnyField,
  FieldResponse,
} from '../../../../core/types/reservation-form-types';
import { ExistCustomerSelector } from './ExistCustomerSelector';
import { NewCustomerSelector } from './NewCustomerSelector';
locale('ja');
extend(relativeTime);

export const styles = {
  reservationCount: css`
    font-size: 20px;
  `,
  canceledReservationCount: css`
    font-size: 12px;
    color: #777;
  `,
  canceledReservationCountAlert: css`
    color: red;
    font-weight: bold;
  `,
  lastReservationInfo: css`
    font-size: 12px;
    color: #777;
    margin-left: 5px;
  `,
  tableHeaderForSp: css`
    width: 110px;
    padding: 8px;
  `,
  tableBodyForSp: css`
    padding: 8px;
  `,
};

export type CustomerSelectorSuggestions = {
  name?: string;
  tel?: string;
  email?: string;
};

export const collectSuggestions = (
  formSettingFields: AnyField[],
  formResponseFields: FieldResponse[]
): CustomerSelectorSuggestions => {
  const personalInfoFields = formSettingFields.filter((formSettingField) => {
    return ['name', 'tel', 'email'].includes(formSettingField.type);
  });
  return personalInfoFields.reduce((suggestion, field) => {
    const type = field.type as keyof CustomerSelectorSuggestions;
    suggestion[type] = formResponseFields.find((formResponseField) => {
      return formResponseField.uid === field.uid;
    })?.values[0] as string;
    return suggestion;
  }, {} as CustomerSelectorSuggestions);
};

type CustomerSelectorProps = {
  shopId: string;
  suggestions?: CustomerSelectorSuggestions;
  initialTab: 'exist' | 'new';
  onClose: () => void;
  onSelect: (customer: SelectedCustomer | undefined) => void;
};

const tabs = ['exist', 'new'] as const;

export const CustomerSelector = (props: CustomerSelectorProps): JSX.Element => {
  const { shopId, suggestions, onClose, onSelect } = props;
  const [tabIndex, setTabIndex] = useState(tabs.indexOf(props.initialTab));

  // eslint-disable-next-line @typescript-eslint/ban-types
  const handleTabChanged = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabIndex(newValue);
  };

  const handleSelect = (customer: CustomerInfo | undefined) => {
    if (!customer) {
      onSelect(undefined);
      onClose();
      return;
    }
    onSelect({
      id: customer.id,
      name: customer.name,
      nameKana: customer.nameKana,
      tel: customer.tel,
      email: customer.email,
      note: customer.note,
      lineUserId: customer.lineUserId,
      lineDisplayName: customer.lineDisplayName,
      reservationCount: customer.reservationCount,
      canceledReservationCount: customer.canceledReservationCount,
    });
    onClose();
  };

  return (
    <>
      <Dialog open={true} onClose={onClose} maxWidth="xl">
        <DialogTitle>
          <Tabs value={tabIndex} onChange={handleTabChanged}>
            <Tab label="既存の顧客を選択" />
            <Tab label="顧客を新規登録" />
          </Tabs>
        </DialogTitle>
        {tabIndex === 0 && (
          <ExistCustomerSelector
            shopId={shopId}
            suggestions={suggestions}
            onClose={onClose}
            onSelect={onSelect}
            onMoveToNewCustomer={() => {
              setTabIndex(1);
            }}
          />
        )}
        {tabIndex === 1 && (
          <NewCustomerSelector
            shopId={shopId}
            suggestions={suggestions}
            onClose={onClose}
            onSelect={handleSelect}
          />
        )}
      </Dialog>
    </>
  );
};
