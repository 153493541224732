import { AxiosResponse } from 'axios';
import {
  NewCustomerRequestData,
  NewCustomerResponse,
} from '../../../@interfaces/crm/api';
import { API_END_POINT } from '../../../api/api';
import { useMutation } from '../../common/api/use-mutation';

export const useCreateShopCustomer = (): ((
  shopId: string,
  data: NewCustomerRequestData
) => Promise<AxiosResponse<NewCustomerResponse>>) => {
  const { request } = useMutation<
    NewCustomerResponse,
    NewCustomerRequestData
  >();
  return async (shopId, data) => {
    return request(
      'POST',
      `${API_END_POINT}/app/shops/${shopId}/customers`,
      {},
      data
    );
  };
};
