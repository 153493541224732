/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { Box, Grid } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import PermContactCalendarIcon from '@material-ui/icons/PermContactCalendar';
import { CustomerActivity } from '../../../@interfaces/crm/api';
import {
  ActivityType,
  CustomerActivityDiff,
  MergedActivityJson,
} from '../../../@interfaces/crm/backend';

const styles = {
  container: css`
    padding: 16px;
  `,
  reserveContainer: css`
    padding: 12px 0 16px;
    border-bottom: 2px solid lightgray;
  `,
  reserveCourseName: css`
    font-weight: bold;
    color: #333;
  `,
  reserveDateTime: css`
    color: #888888;
  `,
  activityIcon: css`
    color: #777;
  `,
};

const CustomerAdded = () => {
  return <></>;
};

const CustomerModified = ({ detail }: { detail: CustomerActivityDiff }) => {
  return (
    <Box>
      {detail.diffs.map((diff) => (
        <Box>
          {diff.name} : {diff.old} → {diff.new}
        </Box>
      ))}
    </Box>
  );
};

const CustomerDeleted = () => {
  return <></>;
};

const CustomerMerged = ({ detail }: { detail: MergedActivityJson }) => {
  return (
    <Box>
      {detail.type === 'from' ? (
        <Box>
          customerId : {detail.customerId.join(', ')}
          を統合
        </Box>
      ) : (
        <Box>
          customerId : {detail.customerId}
          へ統合
        </Box>
      )}
    </Box>
  );
};

const CommonContent = ({
  createdAt,
  icon,
  title,
  children,
}: {
  createdAt: string;
  icon: React.ReactNode;
  title: string;
  children: React.ReactNode;
}) => {
  return (
    <Grid container css={styles.reserveContainer}>
      <Grid item>{icon}</Grid>
      <Grid item style={{ marginLeft: '4px' }}>
        <Box css={styles.reserveCourseName}>{title}</Box>
        <Box css={styles.reserveDateTime}>{createdAt}</Box>
        {children}
      </Grid>
    </Grid>
  );
};

const content = (activity: CustomerActivity) => {
  switch (activity.type) {
    case ActivityType.customerAdded:
      return (
        <CommonContent
          createdAt={activity.createdAt}
          icon={<PermContactCalendarIcon color="primary" />}
          title={`${activity.operator}が顧客を追加`}
        >
          <CustomerAdded />
        </CommonContent>
      );
    case ActivityType.customerModified:
      return (
        <CommonContent
          createdAt={activity.createdAt}
          icon={<EditIcon css={styles.activityIcon} color="primary" />}
          title={`${activity.operator}が顧客を編集`}
        >
          <CustomerModified detail={activity.detail} />
        </CommonContent>
      );
    case ActivityType.customerDeleted:
      return (
        <CommonContent
          createdAt={activity.createdAt}
          icon={<PermContactCalendarIcon color="primary" />}
          title={`${activity.operator}が顧客を削除`}
        >
          <CustomerDeleted />
        </CommonContent>
      );
    case ActivityType.customerMerged:
      return (
        <CommonContent
          createdAt={activity.createdAt}
          icon={<PermContactCalendarIcon color="primary" />}
          title={`${activity.operator}が顧客を統合`}
        >
          <CustomerMerged detail={activity.detail} />
        </CommonContent>
      );
  }
};

export function CustomerActivityList({
  customerActivities,
}: {
  customerActivities: CustomerActivity[];
}): JSX.Element {
  return (
    <Box css={styles.container}>
      <Box>
        {customerActivities.length === 0 && (
          <Box mt={4}>更新履歴はまだありません。</Box>
        )}
        {customerActivities.map((activity, index) => (
          <Box key={index}>{content(activity)}</Box>
        ))}
      </Box>
    </Box>
  );
}
