/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import {
  AggregationUnit,
  DateAggregation,
} from '../../../@interfaces/insight/backend';
import {
  DisplayData,
  styles,
  toDisplayDate,
  UniqueKey,
} from '../utils/insight-utils';
import { ComparisonValue } from './ComparisonValue';
import { ComparisonValueWithArrow } from './ComparisonValueWithArrow';

type TimeLineTableProps = {
  aggregationUnit: AggregationUnit;
  uniqueKeys: UniqueKey[];
  primary: {
    results: DateAggregation[];
    displayData: DisplayData[];
  };
  comparison?: {
    results: DateAggregation[];
    displayData: DisplayData[];
  };
};

export const TimeLineTable = ({
  aggregationUnit,
  uniqueKeys,
  primary,
  comparison,
}: TimeLineTableProps): JSX.Element => {
  const { results, displayData } = primary;

  const buildTotalRow = () => {
    const allTotal = results.reduce((prev, current) => {
      return prev + current.total;
    }, 0);
    const comparisonAllTotal = comparison?.results.reduce((prev, current) => {
      return prev + current.total;
    }, 0);

    return (
      <TableRow
        css={css`
          background-color: #f5f5f5;
        `}
      >
        <TableCell
          scope="row"
          css={css`
            font-weight: bold;
          `}
        >
          合計
        </TableCell>
        {uniqueKeys.map((key) => {
          const total = displayData.reduce((prev, current) => {
            return prev + ((current[key.key] as number) || 0);
          }, 0);

          const comparisonTotal = comparison?.displayData.reduce(
            (prev, current) => {
              return prev + ((current[key.key] as number) || 0);
            },
            0
          );

          return (
            <TableCell
              key={key.key}
              align="center"
              scope="row"
              css={css`
                font-weight: bold;
              `}
            >
              <div css={styles.tableNumberCell}>{total.toLocaleString()}</div>
              {comparisonTotal !== undefined && (
                <ComparisonValueWithArrow
                  primaryValue={total}
                  comparisonValue={comparisonTotal}
                />
              )}
            </TableCell>
          );
        })}
        <TableCell
          scope="row"
          align="center"
          css={css`
            font-weight: bold;
          `}
        >
          <div css={styles.tableNumberCell}>{allTotal.toLocaleString()}</div>
          {comparisonAllTotal !== undefined && (
            <ComparisonValueWithArrow
              primaryValue={allTotal}
              comparisonValue={comparisonAllTotal}
            />
          )}
        </TableCell>
      </TableRow>
    );
  };

  const buildTableRow = (row: DisplayData, index: number) => {
    const total = Object.entries(row)
      .filter(([key]) => key !== '__date')
      .reduce((prev, [, value]) => prev + Number(value), 0);

    const comparisonRow = comparison?.displayData[index];
    const comparisonTotal =
      comparisonRow === undefined
        ? undefined
        : Object.entries(comparisonRow)
            .filter(([key]) => key !== '__date')
            .reduce((prev, [, value]) => prev + Number(value), 0);

    return (
      <TableRow key={row.__date}>
        <TableCell scope="row">
          <div>{toDisplayDate(row.__date, aggregationUnit)}</div>
          {comparisonRow !== undefined && (
            <>
              <div>{toDisplayDate(comparisonRow.__date, aggregationUnit)}</div>
              <div>推移(%)</div>
            </>
          )}
        </TableCell>
        {uniqueKeys.map((key) => {
          const primaryValue = row[key.key];
          const comparisonValue = comparisonRow?.[key.key];

          return (
            <TableCell key={key.key} align="center" scope="row">
              <div css={styles.tableNumberCell}>{primaryValue}</div>
              {comparisonValue !== undefined && (
                <ComparisonValue
                  primaryValue={Number(primaryValue)}
                  comparisonValue={Number(comparisonValue)}
                />
              )}
            </TableCell>
          );
        })}
        <TableCell scope="row" align="center">
          <div css={styles.tableNumberCell}>{total}</div>
          {comparisonTotal !== undefined && (
            <ComparisonValue
              primaryValue={Number(total)}
              comparisonValue={Number(comparisonTotal)}
            />
          )}
        </TableCell>
      </TableRow>
    );
  };

  return (
    <TableContainer
      css={css`
        margin-top: 3em;
      `}
      component={Paper}
    >
      <Table size="small" aria-label="a dense table">
        <TableHead
          css={css`
            background-color: black;
          `}
        >
          <TableRow>
            <TableCell
              css={styles.tableHeader(uniqueKeys.length + 2)}
              align="center"
            >
              年月日/項目
            </TableCell>
            {uniqueKeys.map((result) => (
              <TableCell
                css={styles.tableHeader(uniqueKeys.length + 2)}
                key={result.key}
                align="center"
              >
                {result.label}
              </TableCell>
            ))}
            <TableCell
              css={styles.tableHeader(uniqueKeys.length + 2)}
              align="center"
            >
              合計
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {buildTotalRow()}
          {displayData.map(buildTableRow)}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
