/** @jsxImportSource @emotion/react */

import { TextField } from '@material-ui/core';
import React, { ChangeEvent } from 'react';
import {
  AnyField,
  FormSetting,
} from '../../../../core/types/reservation-form-types';

type FormSettingSingleDefaultValueProps = {
  field: AnyField;
  setting: FormSetting;
  onChangeSetting: (setting: FormSetting) => void;
  type: 'text' | 'textarea' | 'number';
};

export const FormSettingSingleDefaultValue = (
  props: FormSettingSingleDefaultValueProps
): JSX.Element => {
  const { field, setting, onChangeSetting, type } = props;

  const handleChangeDefaultValue = (e: ChangeEvent<HTMLInputElement>) => {
    field.defaultValues = e.target.value ? [e.target.value] : undefined;
    onChangeSetting({ ...setting });
  };

  return (
    <TextField
      type={type}
      label="初期値"
      value={field.defaultValues?.[0]}
      fullWidth
      multiline={type === 'textarea'}
      onChange={handleChangeDefaultValue}
    />
  );
};
