/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import {
  Breadcrumbs,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import axios from 'axios';
import dayjs from 'dayjs';
import { useSnackbar } from 'notistack';
import React, { useContext, useState } from 'react';
import useReactRouter from 'use-react-router';
import { SlotSettingRule } from '../../../@interfaces/slot-setting-rule';
import { API_END_POINT } from '../../../api/api';
import useShop from '../../../api/use-shop';
import useShops from '../../../api/use-shops';
import useSlotSetting from '../../../api/use-slot-setting';
import useSlotSettings from '../../../api/use-slot-settings';
import {
  BreadcrumbLinkItem,
  BreadcrumbShopItem,
  BreadcrumbTextItem,
  BreadcrumbWorkspaceItem,
} from '../../../components/breadcrumb/BreadcrumbItem';
import { commonCss } from '../../../components/common-css';
import { ShopPageLayout } from '../../../components/layouts/ShopPageLayout';
import LinkRouter from '../../../components/LinkRouter';
import { PageTitleAndDescription } from '../../../components/PageTitleAndDescription';
import {
  FullscreenLoading,
  Head,
  Main,
  Root,
} from '../../../components/Shared';
import Spinner from '../../../components/Spinner';
import { Store } from '../../../context/GlobalStore';
import { getSlotsByTimeRanges } from '../../../core/services/reservation-table-service';
import {
  Capacity,
  CustomWeeklyCondition,
  HolidayRule,
  IDate,
  MonthlyCondition,
  RegularRule,
  Schedule,
  ScheduleCondition,
  TimeRangeCapacity,
  toDateStringByDate,
  toDayOfWeeksLabelDayOfWeek,
  toTimeStringByTime,
  WeeklyCondition,
} from '../../../core/types/reservation-types';
import { SM_BREAKPOINT, useSizeType } from '../../../hooks/use-size-type';
import { globalColors } from '../../../styles/globalColors';
import { helps } from '../../../utils/helps';

const styles = {
  labelCell: css`
    @media screen and (max-width: ${SM_BREAKPOINT}px) {
      padding: 16px 8px 16px 16px;
      width: 100px;
    }
  `,
  contentCell: css`
    @media screen and (max-width: ${SM_BREAKPOINT}px) {
      padding: 16px 16px 16px 8px;
    }
  `,
  startNotAllowed: css`
    color: #ff0000;
  `,
};

interface PageParams {
  workspaceUid: string;
  shopId: string;
  slotSettingId: string;
}

export default function ShopSlotSettingPage() {
  const { history, match } = useReactRouter<PageParams>();
  const { workspaceUid, shopId, slotSettingId } = match.params;
  const { globalState } = useContext(Store);

  const { shop, isLoadingShop } = useShop(shopId);
  const { slotSetting, slotSettingRules, reloadSlotSetting } = useSlotSetting(
    shopId,
    slotSettingId
  );

  const [openImportDialog, setOpenImportDialog] = useState(false);
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { isSpSize } = useSizeType();

  const canCreateNewSetting = () => {
    return (
      slotSettingRules.find(
        (s) => !s.rule.dateRange.start || !s.rule.dateRange.end
      ) == undefined
    );
  };

  const handleClickAdd = () => {
    if (!canCreateNewSetting()) {
      alert(
        '登録済みの設定の開始日、終了日が設定されていません。\n開始日、終了日を設定して再実行してください。'
      );
      return;
    }
    history.push(
      `/a/${workspaceUid}/shops/${shopId}/settings/slot-settings/${slotSettingId}/rules/new`
    );
  };

  const handleClickCancel = () => {
    history.push(`/a/${workspaceUid}/shops/${shopId}/settings/`);
  };

  const handleShowImportDialog = () => {
    setOpenImportDialog(true);
  };

  const handleHideImportDialog = () => {
    setOpenImportDialog(false);
  };

  const handleImport = (fromSlotSettingId: number) => {
    if (!globalState.session?.idToken) {
      return;
    }
    const json = {
      fromSlotSettingId,
    };
    setOpenBackdrop(true);
    axios
      .post(
        `${API_END_POINT}/app/shops/${shopId}/slot-settings/${slotSettingId}/import`,
        json,
        {
          headers: {
            Authorization: globalState.session?.idToken,
          },
        }
      )
      .then(() => {
        enqueueSnackbar('予約枠をインポートしました。', { variant: 'success' });
        reloadSlotSetting();
      })
      .catch((e) => {
        enqueueSnackbar('予約枠をインポートできませんでした。', {
          variant: 'error',
        });
      })
      .finally(() => {
        setOpenBackdrop(false);
        setOpenImportDialog(false);
      });
  };

  const buildContents = () => {
    if (isLoadingShop || !slotSetting) {
      return <Spinner loading={true} />;
    }
    return (
      <>
        <Grid
          container
          css={css`
            margin-top: 20px;
          `}
        >
          <Grid item xs={12} sm={6}>
            <h3>
              {slotSetting.courses.map((c) => c.name).join('/')}
              の予約枠（基本設定）
            </h3>
          </Grid>
          <Grid
            item
            container
            xs={12}
            sm={6}
            alignItems="center"
            justifyContent="flex-end"
          >
            <Button
              css={commonCss.button.right}
              type="button"
              variant="text"
              color="primary"
              onClick={handleClickCancel}
            >
              キャンセル
            </Button>
            <Button
              css={commonCss.button.right}
              type="button"
              variant="contained"
              color="primary"
              onClick={handleShowImportDialog}
            >
              設定をインポート...
            </Button>
            <Button
              css={commonCss.button.right}
              type="button"
              variant="contained"
              color="primary"
              onClick={handleClickAdd}
            >
              設定を追加
            </Button>
          </Grid>
        </Grid>
        <SlotSettingRulesTable
          workspaceUid={workspaceUid}
          shopId={shopId}
          slotSettingId={slotSetting.id}
          slotSettingRules={slotSettingRules}
          isSpSize={isSpSize}
        />
      </>
    );
  };

  const buildImportDialog = () => {
    if (!openImportDialog) {
      return null;
    }
    return (
      <ImportDialog
        open={openImportDialog}
        workspaceUid={workspaceUid}
        shopId={shopId}
        onHideImportDialog={handleHideImportDialog}
        onImport={handleImport}
      />
    );
  };

  const pageTitle = 'コースの予約枠';

  return (
    <Root>
      <Head
        title={`${slotSetting?.courses
          .map((c) => c.name)
          .join('/')}の予約枠（基本設定）- ${shop?.name || ''} ${
          slotSetting?.courses.map((c) => c.name).join('/') || ''
        }`}
      />
      <Main>
        <ShopPageLayout
          workspaceUid={workspaceUid}
          shopId={shopId}
          current="slot-basic"
          helpId={helps.shop.setting.courseSlot}
          breadcrumbs={
            <Breadcrumbs aria-label="breadcrumb">
              <BreadcrumbLinkItem to={`/`}>ホーム</BreadcrumbLinkItem>
              <BreadcrumbWorkspaceItem workspaceUid={workspaceUid} />
              <BreadcrumbShopItem workspaceUid={workspaceUid} shop={shop} />
              <BreadcrumbLinkItem
                to={`/a/${workspaceUid}/shops/${shopId}/settings/slot-settings`}
              >
                予約枠基本設定
              </BreadcrumbLinkItem>
              <BreadcrumbTextItem>{pageTitle}</BreadcrumbTextItem>
            </Breadcrumbs>
          }
        >
          <PageTitleAndDescription
            title="店舗"
            subTitle={pageTitle}
            description="コースごとの予約受付可能枠の設定を行います。"
            themeColor={globalColors.shop}
          />
          {buildContents()}
        </ShopPageLayout>
      </Main>
      <FullscreenLoading open={openBackdrop} />
      {buildImportDialog()}
    </Root>
  );
}

const getTotal = (schedule: Schedule, timeRange: TimeRangeCapacity) => {
  if (
    timeRange.capacity?.total !== undefined &&
    timeRange.capacity?.total !== null
  ) {
    return timeRange.capacity.total;
  } else if (
    schedule.capacity?.total !== undefined &&
    schedule.capacity?.total !== null
  ) {
    return schedule.capacity.total;
  } else {
    return 0;
  }
};

function SlotSettingRulesTable(props: {
  workspaceUid: string;
  shopId: string;
  slotSettingId: number;
  slotSettingRules: SlotSettingRule[];
  isSpSize: boolean;
}) {
  const { workspaceUid, shopId, slotSettingId, slotSettingRules, isSpSize } =
    props;

  const buildTerm = (
    startDate: IDate | undefined,
    endDate: IDate | undefined
  ) => {
    if (startDate && endDate) {
      return (
        <>
          {toDateStringByDate(startDate)}〜{toDateStringByDate(endDate)}
        </>
      );
    } else if (endDate) {
      return <>{toDateStringByDate(endDate)}まで</>;
    } else if (startDate) {
      return <>{toDateStringByDate(startDate)}から</>;
    } else {
      return <>期間指定なし</>;
    }
  };

  const buildMonthlyConditionInfo = (condition: MonthlyCondition) => {
    return <>毎月「{condition.dates.join(',')}」の以下の時間帯に</>;
  };

  const buildWeeklyConditionInfo = (condition: WeeklyCondition) => {
    return (
      <>
        毎週「
        {condition.dayOfWeeks
          .map((d) => toDayOfWeeksLabelDayOfWeek(d as string))
          .join(',')}
        」{condition.holiday ? 'および「祝日」' : ''}の以下の時間帯に
      </>
    );
  };

  const buildCustomWeeklyConditionInfo = (condition: CustomWeeklyCondition) => {
    return (
      <>
        {condition.dayOfWeeks
          .map(
            (d) =>
              `毎月第${d.target}${toDayOfWeeksLabelDayOfWeek(d.dayOfWeek)}曜日`
          )
          .join(',')}
      </>
    );
  };

  const buildConditionInfo = (condition: ScheduleCondition) => {
    if (condition.type == 'weekly') {
      return buildWeeklyConditionInfo(condition);
    } else if (condition.type == 'monthly') {
      return buildMonthlyConditionInfo(condition);
    } else if (condition.type == 'custom-weekly') {
      return buildCustomWeeklyConditionInfo(condition);
    } else {
      return null;
    }
  };

  const buildSummarySlotInfo = (
    schedule: Schedule,
    timeRange: TimeRangeCapacity,
    unit: number,
    capacity: Capacity | undefined,
    index: number
  ) => {
    const slots = getSlotsByTimeRanges([timeRange], unit, capacity);
    return (
      <li key={index}>
        {toTimeStringByTime(timeRange.start)}〜
        {toTimeStringByTime(timeRange.end)} 予約可能数:{' '}
        {getTotal(schedule, timeRange)}
        (全{slots.length}枠
        {timeRange.capacity?.startNotAllowed && (
          <>
            ,{' '}
            <span css={styles.startNotAllowed}>この時間から予約開始は不可</span>
          </>
        )}
        )
      </li>
    );
  };

  const buildSummarySlotsInfo = (
    schedule: Schedule,
    timeRanges: TimeRangeCapacity[],
    unit: number,
    capacity: Capacity | undefined
  ) => {
    if (timeRanges.length == 0) {
      return (
        <ul>
          <li>枠なし</li>
        </ul>
      );
    }
    return (
      <ul>
        {timeRanges.map((timeRange, i) =>
          buildSummarySlotInfo(schedule, timeRange, unit, capacity, i)
        )}
      </ul>
    );
  };

  const buildScheduleInfo = (
    schedule: Schedule,
    unit: number,
    index: number
  ) => {
    const { condition } = schedule;
    const detail = buildSummarySlotsInfo(
      schedule,
      schedule.timeRanges,
      unit,
      schedule.capacity
    );

    return (
      <li key={index}>
        {buildConditionInfo(condition)}
        <br />
        {detail}
      </li>
    );
  };

  const buildSchedulesInfo = (schedules: Schedule[], unit: number) => {
    return (
      <ul>{schedules.map((s, index) => buildScheduleInfo(s, unit, index))}</ul>
    );
  };

  const buildRegularRuleInfo = (rule: RegularRule) => {
    return (
      <>
        <div>
          ルール種別: 繰り返し
          <br />
          予約間隔: {rule.unit}分ごと
          <br />
          {buildSchedulesInfo(rule.schedules, rule.unit)}
        </div>
      </>
    );
  };

  const buildHolidayRuleInfo = (rule: HolidayRule) => {
    return (
      <>
        <div>ルール種別: 休日</div>
      </>
    );
  };

  const buildRuleInfo = (slotSettingRule: SlotSettingRule) => {
    const { rule } = slotSettingRule;
    if (rule.type == 'regular') {
      return buildRegularRuleInfo(rule);
    } else if (rule.type == 'holiday') {
      return buildHolidayRuleInfo(rule);
    } else {
      return null;
    }
  };

  return !isSpSize ? (
    <TableContainer
      component={Paper}
      css={css`
        margin-top: 24px;
      `}
    >
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>期間</TableCell>
            <TableCell>内容</TableCell>
            <TableCell>更新日時</TableCell>
            <TableCell>アクション</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {slotSettingRules.map((slotSettingRule, index) => (
            <TableRow key={slotSettingRule.id}>
              <TableCell scope="row">
                {buildTerm(
                  slotSettingRule.rule.dateRange.start,
                  slotSettingRule.rule.dateRange.end
                )}
              </TableCell>
              <TableCell scope="row">
                {buildRuleInfo(slotSettingRule)}
              </TableCell>
              <TableCell scope="row">
                {dayjs(slotSettingRule.updatedAt).format('YYYY-MM-DD')}
                <br />
                {dayjs(slotSettingRule.updatedAt).format('H:mm')}
              </TableCell>
              <TableCell scope="row">
                <LinkRouter
                  to={`/a/${workspaceUid}/shops/${shopId}/settings/slot-settings/${slotSettingId}/rules/${slotSettingRule.id}`}
                >
                  編集
                </LinkRouter>
                &nbsp;|&nbsp;
                <LinkRouter
                  to={`/a/${workspaceUid}/shops/${shopId}/settings/slot-settings/${slotSettingId}/rules/new?fromId=${slotSettingRule.id}`}
                >
                  コピー
                </LinkRouter>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  ) : (
    <>
      {slotSettingRules.map((slotSettingRule, index) => (
        <TableContainer
          key={slotSettingRule.id}
          component={Paper}
          css={css`
            margin-top: 24px;
          `}
        >
          <Table aria-label="simple table">
            <TableBody>
              <TableRow key={slotSettingRule.id}>
                <TableCell css={styles.labelCell}>期間</TableCell>
                <TableCell scope="row" css={styles.contentCell}>
                  {buildTerm(
                    slotSettingRule.rule.dateRange.start,
                    slotSettingRule.rule.dateRange.end
                  )}
                </TableCell>
              </TableRow>
              <TableRow key={slotSettingRule.id}>
                <TableCell css={styles.labelCell}>内容</TableCell>
                <TableCell scope="row" css={styles.contentCell}>
                  {buildRuleInfo(slotSettingRule)}
                </TableCell>
              </TableRow>
              <TableRow key={slotSettingRule.id}>
                <TableCell css={styles.labelCell}>更新日時</TableCell>
                <TableCell scope="row" css={styles.contentCell}>
                  {dayjs(slotSettingRule.updatedAt).format('YYYY-MM-DD')}
                  <br />
                  {dayjs(slotSettingRule.updatedAt).format('H:mm')}
                </TableCell>
              </TableRow>
              <TableRow key={slotSettingRule.id}>
                <TableCell css={styles.labelCell}>アクション</TableCell>
                <TableCell scope="row" css={styles.contentCell}>
                  <LinkRouter
                    to={`/a/${workspaceUid}/shops/${shopId}/settings/slot-settings/${slotSettingId}/rules/${slotSettingRule.id}`}
                  >
                    編集
                  </LinkRouter>
                  &nbsp;|&nbsp;
                  <LinkRouter
                    to={`/a/${workspaceUid}/shops/${shopId}/settings/slot-settings/${slotSettingId}/rules/new?fromId=${slotSettingRule.id}`}
                  >
                    コピー
                  </LinkRouter>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      ))}
    </>
  );
}

function ImportDialog(props: {
  open: boolean;
  workspaceUid: string;
  shopId: string;
  onHideImportDialog: () => void;
  onImport: (fromSlotSettingId: number) => void;
}) {
  const { open, workspaceUid, shopId, onHideImportDialog, onImport } = props;
  const [selectedShopId, setSelectedShopId] = useState(-1);
  const [selectedSlotSettingId, setSelectedSlotSettingId] = useState(-1);
  const { shops, isLoadingShops } = useShops(workspaceUid);
  const { slotSettings, isLoadingSlotSettins } = useSlotSettings(
    selectedShopId == -1 ? undefined : String(selectedShopId)
  );

  const handleSelectedShopId = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    const selectedShopId = event.target.value as number;
    setSelectedShopId(selectedShopId);
  };

  const handleSelectedSlotSettingId = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    const selectedSlotSettingId = event.target.value as number;
    setSelectedSlotSettingId(selectedSlotSettingId);
  };

  const handleImport = () => {
    if (
      !window.confirm(
        '予約枠をインポートしてもよろしいですか？設定済みの予約枠設定は削除されます。'
      )
    ) {
      return;
    }
    onImport(selectedSlotSettingId);
  };

  const buildShopSelector = () => {
    if (isLoadingShops) {
      return <Spinner loading={true} />;
    }
    return (
      <Select
        value={selectedShopId}
        css={css`
          min-width: 100px;
        `}
        onChange={handleSelectedShopId}
      >
        <MenuItem value={-1}>店舗を選択してください</MenuItem>
        {shops.map((shop) => {
          return (
            <MenuItem key={shop.uid} value={shop.id}>
              {shop.name}
            </MenuItem>
          );
        })}
      </Select>
    );
  };

  const buildSlotSettingSelector = () => {
    if (isLoadingSlotSettins) {
      return <Spinner loading={true} />;
    }
    return (
      <Select
        value={selectedSlotSettingId}
        css={css`
          min-width: 100px;
        `}
        onChange={handleSelectedSlotSettingId}
        disabled={selectedShopId == -1}
      >
        <MenuItem value={-1}>インポート元のコースを選択してください</MenuItem>
        {slotSettings.map((slotSetting) => {
          return (
            <MenuItem key={slotSetting.id} value={slotSetting.id}>
              {slotSetting.courses.map((c) => c.name).join('/')}
            </MenuItem>
          );
        })}
      </Select>
    );
  };

  return (
    <Dialog open={open} onClose={onHideImportDialog}>
      <DialogTitle>他の予約枠設定をインポート</DialogTitle>
      <DialogContent>
        <DialogContentText>
          他の予約枠設定をインポートします。 現在の設定は全て上書きされます。
        </DialogContentText>
        <div>{buildShopSelector()}</div>
        <div>{buildSlotSettingSelector()}</div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onHideImportDialog} color="primary">
          キャンセル
        </Button>
        <Button
          onClick={handleImport}
          color="primary"
          disabled={selectedShopId === -1 || selectedSlotSettingId === -1}
        >
          予約枠をインポートする
        </Button>
      </DialogActions>
    </Dialog>
  );
}
