/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { CourseReservationTable } from '../../../frontend-api/src/interfaces/reservation';
import { Course } from '../@interfaces/course';
import { ReservationTimeTable } from '../api/use-reservation-time-table';
import {
  ReservedSlotCapacity,
  Time,
  toTimeStringByTime,
} from '../core/types/reservation-types';
import { useShopPageDisplaySetting } from '../pages/shop/ShopPageDisplySetting';
import { globalColors } from '../styles/globalColors';

type Props = {
  reservationTimeTable: ReservationTimeTable;
  onClickCell?: (course: Course, time: Time) => void;
  shopId: string;
};

const styles = {
  tableContainer: css`
    height: 230px;
  `,
  table: css`
    width: 100px;
  `,
  timeHeaderCell: css`
    font-size: 11px;
    z-index: 3;
  `,
  headerCell: css`
    max-width: 100px;
    min-width: 100px;
    font-size: 11px;
    line-height: 1.2;
    padding: 4px 8px 4px 8px;
  `,
  cell: css`
    max-width: 100px;
    min-width: 100px;
  `,
  notAvailableCell: css`
    background-color: #eee;
  `,
  fullCell: css`
    background-color: #ffb0a3;
  `,
  reservedCell: css`
    background-color: #ffd8d1;
    &:hover {
      background-color: ${globalColors.hover};
      cursor: pointer;
    }
  `,
  availableCell: css`
    background-color: #fff;
    &:hover {
      background-color: ${globalColors.hover};
      cursor: pointer;
    }
  `,
  disableCell: css`
    background-color: #aaa;
  `,
  minutesRequired: css`
    font-size: 10px;
  `,
  leftCell: css`
    position: sticky;
    left: 0;
    background-color: white;
  `,
};

export default function ReservationTimeTableView(props: Props) {
  const { reservationTimeTable, onClickCell, shopId } = props;
  const [displaySetting] = useShopPageDisplaySetting(shopId);

  const handleClickCell = (
    table: CourseReservationTable,
    reservedSlot: ReservedSlotCapacity
  ) => {
    const { course } = table;
    const time = reservedSlot.slot.timeRange.start;
    onClickCell?.(course, time);
  };

  const buildCell = (
    time: Time,
    table: CourseReservationTable,
    index: number
  ) => {
    const reservedSlot = table.dates[0]?.slots.find((slot) => {
      return (
        slot.slot.timeRange.start.hour === time.hour &&
        slot.slot.timeRange.start.minute === time.minute
      );
    });
    if (!reservedSlot) {
      // 予約枠無し
      return (
        <TableCell
          css={[styles.cell, styles.notAvailableCell]}
          scope="row"
          align="center"
          key={index}
        >
          -
        </TableCell>
      );
    }
    // 予約可能か？
    const available = (() => {
      const availableSlotCapacity =
        reservedSlot.capacity.total - reservedSlot.reserved.total;
      // 予約枠の残りがない場合は、予約不可
      if (availableSlotCapacity <= 0) {
        return false;
      }
      // リソースの残数が設定されている場合は、リソースの残数が0の場合は予約不可
      if (reservedSlot.resourceCapacity?.total !== undefined) {
        const availableResourceCapacity: number =
          reservedSlot.resourceCapacity.total;
        return Math.min(availableSlotCapacity, availableResourceCapacity) > 0;
      }
      // 予約枠が1以上あれば予約可能
      return true;
    })();
    // 予約数上限
    const totalCapacity = (() => {
      if (reservedSlot.resourceCapacity?.total !== undefined) {
        // リソースの残数が設定されている場合は、
        // リソースの残数+予約済みの数 or 予約枠の上限のどちらか小さい方を上限として表示する
        return Math.min(
          reservedSlot.resourceCapacity.total + reservedSlot.reserved.total,
          reservedSlot.capacity.total
        );
      } else {
        //  予約枠の上限のどちらか小さい方を上限として表示する
        return reservedSlot.capacity.total;
      }
    })();
    if (!available) {
      // 満席(fullCell) or 枠の上限が0(disableCell)
      // クリックできないようにする
      const cellStyle =
        totalCapacity === 0 ? styles.disableCell : styles.fullCell;
      return (
        <TableCell
          css={[styles.cell, cellStyle]}
          align="center"
          scope="row"
          key={index}
        >
          {reservedSlot.reserved.total}/{totalCapacity}(
          {reservedSlot.reserved.courseTotal})
        </TableCell>
      );
    }
    const cellStyle =
      reservedSlot.reserved.total > 0
        ? styles.reservedCell
        : reservedSlot.reserved.canReserve
        ? styles.availableCell
        : styles.disableCell;
    return (
      // 空きあり
      // クリックできるようにする
      <TableCell
        css={[styles.cell, cellStyle]}
        scope="row"
        align="center"
        onClick={() => {
          handleClickCell(table, reservedSlot);
        }}
        key={index}
      >
        {reservedSlot.reserved.total}/{totalCapacity}(
        {reservedSlot.reserved.courseTotal})
      </TableCell>
    );
  };

  const { reservationTimeTable: displayOptions } = displaySetting;
  const tableHeightCss = displayOptions.height
    ? css({ height: displayOptions.height })
    : null;
  const cellWidthCss = displayOptions.cellWidth
    ? css({
        minWidth: displayOptions.cellWidth,
        maxWidth: displayOptions.cellWidth,
      })
    : null;

  return (
    <TableContainer
      css={[styles.tableContainer, tableHeightCss]}
      component={Paper}
    >
      <Table css={styles.table} size="small" stickyHeader={true}>
        <TableHead>
          <TableRow>
            <TableCell css={styles.timeHeaderCell} align="center">
              時間
            </TableCell>
            {reservationTimeTable.tables.map((table, index) => {
              return (
                <TableCell
                  css={[styles.headerCell, cellWidthCss]}
                  align="center"
                  key={index}
                >
                  {table.course.name}
                  {table.minutesRequired ? (
                    <>
                      <br />
                      <span css={styles.minutesRequired}>
                        (所要時間: {table.minutesRequired}分)
                      </span>
                    </>
                  ) : null}
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {reservationTimeTable.times.map((time, index) => {
            return (
              <TableRow key={`${toTimeStringByTime(time)}_${index}`}>
                <TableCell scope="row" css={styles.leftCell}>
                  {toTimeStringByTime(time)}
                </TableCell>
                {reservationTimeTable.tables.map((table, cellIndex) => {
                  return buildCell(time, table, cellIndex);
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
