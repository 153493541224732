/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { Breadcrumbs, Button, Grid } from '@material-ui/core';
import PrintIcon from '@material-ui/icons/Print';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { TbReportSearch } from 'react-icons/tb';
import useReactRouter from 'use-react-router';
import useShop from '../../../api/use-shop';
import {
  BreadcrumbLinkItem,
  BreadcrumbShopItem,
  BreadcrumbTextItem,
  BreadcrumbWorkspaceItem,
} from '../../../components/breadcrumb/BreadcrumbItem';
import { ShopPageLayout } from '../../../components/layouts/ShopPageLayout';
import { PageTitleAndDescription } from '../../../components/PageTitleAndDescription';
import { Head, Main, Root } from '../../../components/Shared';
import { globalColors } from '../../../styles/globalColors';
import { helps } from '../../../utils/helps';
import useInsightReportTest from '../api/use-insight-report-test';
import { CancelTotalCard } from '../components/CancelTotalCard';
import { ConversionSummary } from '../components/ConversionSummary';
import { DateRangePreview } from '../components/DateRangePreview';
import { InsightHourCard } from '../components/InsightHourCard';
import { InsightItemCard } from '../components/InsightItemCard';
import { InsightSummary } from '../components/InsightSummary';
import { useDateRange } from '../hooks/useDateRange';
import { useTargetDateType } from '../hooks/useTargetDateType';
import { styles } from '../utils/insight-utils';

type PageParams = {
  workspaceUid: string;
  shopId: string;
};

export default function InsightPage() {
  const { match, location } = useReactRouter<PageParams>();
  const { workspaceUid, shopId } = match.params;
  const { shop } = useShop(shopId);
  const {
    startDate: primaryStartDate,
    endDate: primaryEndDate,
    comparisonStartDate,
    comparisonEndDate,
    aggregationUnit,
    dateRangePicker,
  } = useDateRange();
  const { targetDateType, targetDateTypeSelector } = useTargetDateType();
  const { sendTestReport, isLoading: isSending } = useInsightReportTest(shopId);

  const buildContents = () => {
    return (
      <div css={styles.printRoot}>
        <div
          css={[
            css`
              display: flex;
              justify-content: flex-end;
              margin-bottom: 1em;
              gap: 2em;
            `,
            styles.noPrint,
          ]}
        >
          {process.env.REACT_APP_STAGE === 'local' && (
            <Button
              variant="outlined"
              color="secondary"
              size="small"
              startIcon={<TbReportSearch />}
              onClick={async () => {
                await sendTestReport(
                  String(primaryStartDate.get('year')),
                  String(primaryStartDate.get('month') + 1)
                );
              }}
              disabled={isSending}
            >
              レポート送信 (ローカルテスト用)
            </Button>
          )}
          <Button
            variant="outlined"
            color="primary"
            size="small"
            startIcon={<PrintIcon />}
            onClick={() => {
              window.print();
            }}
          >
            印刷
          </Button>
        </div>
        <div
          css={css`
            display: flex;
            justify-content: space-between;
            margin-bottom: 1em;
            gap: 2em;
            flex-wrap: wrap;
          `}
        >
          <div
            css={css`
              display: flex;
              gap: 2em;
              flex-wrap: wrap;
            `}
          >
            {dateRangePicker}
            {targetDateTypeSelector}
          </div>
          <div
            css={css`
              margin-bottom: 1em;
            `}
          >
            <DateRangePreview
              primaryStartDate={primaryStartDate}
              primaryEndDate={primaryEndDate}
              comparisonStartDate={comparisonStartDate}
              comparisonEndDate={comparisonEndDate}
              showComparison={true}
            />
          </div>
        </div>
        <Grid
          container
          alignItems="stretch"
          justifyContent="center"
          spacing={3}
        >
          <Grid item xs={12} md={12} lg={6}>
            <InsightSummary
              shopId={shopId}
              primaryStartDate={primaryStartDate}
              primaryEndDate={primaryEndDate}
              comparisonStartDate={comparisonStartDate}
              comparisonEndDate={comparisonEndDate}
              aggregationUnit={aggregationUnit}
              targetDateType={targetDateType}
            />
          </Grid>
          <Grid css={styles.pageBreakAfter} item xs={12} md={12} lg={6}>
            <ConversionSummary
              shopId={shopId}
              primaryStartDate={primaryStartDate}
              primaryEndDate={primaryEndDate}
              comparisonStartDate={comparisonStartDate}
              comparisonEndDate={comparisonEndDate}
              aggregationUnit={aggregationUnit}
              targetDateType={targetDateType}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <InsightItemCard
              shopId={shopId}
              primaryStartDate={primaryStartDate}
              primaryEndDate={primaryEndDate}
              comparisonStartDate={comparisonStartDate}
              comparisonEndDate={comparisonEndDate}
              title="コース"
              url={`insight/course${location.search}`}
              group="course"
              targetDateType={targetDateType}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <InsightItemCard
              shopId={shopId}
              primaryStartDate={primaryStartDate}
              primaryEndDate={primaryEndDate}
              comparisonStartDate={comparisonStartDate}
              comparisonEndDate={comparisonEndDate}
              title="流入元"
              url={`insight/inflow-source${location.search}`}
              group="inflowSource"
              targetDateType={targetDateType}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <CancelTotalCard
              shopId={shopId}
              primaryStartDate={primaryStartDate}
              primaryEndDate={primaryEndDate}
              comparisonStartDate={comparisonStartDate}
              comparisonEndDate={comparisonEndDate}
              aggregationUnit={aggregationUnit}
              targetDateType={targetDateType}
            />
          </Grid>
          <Grid xs={12} md={6} lg={3} item>
            <InsightHourCard
              shopId={shopId}
              primaryStartDate={primaryStartDate}
              primaryEndDate={primaryEndDate}
              comparisonStartDate={comparisonStartDate}
              comparisonEndDate={comparisonEndDate}
              title="時間帯別"
              url={`insight/hourly${location.search}`}
              targetDateType={targetDateType}
            />
          </Grid>
        </Grid>
      </div>
    );
  };

  const pageTitle = '統計データ（レポート）';

  return (
    <Root>
      <Head title={pageTitle} />
      <Main>
        <ShopPageLayout
          workspaceUid={workspaceUid}
          shopId={shopId}
          current="insight"
          helpId={helps.customer.list}
          breadcrumbs={
            <Breadcrumbs aria-label="breadcrumb">
              <BreadcrumbLinkItem to={`/`}>ホーム</BreadcrumbLinkItem>
              <BreadcrumbWorkspaceItem workspaceUid={workspaceUid} />
              <BreadcrumbShopItem workspaceUid={workspaceUid} shop={shop} />
              <BreadcrumbTextItem>{pageTitle}</BreadcrumbTextItem>
            </Breadcrumbs>
          }
        >
          <PageTitleAndDescription
            title="店舗"
            subTitle="統計データ（レポート）: 概要"
            description="選択した期間内の予約件数の統計データの確認を行います。"
            themeColor={globalColors.shop}
          />
          {buildContents()}
        </ShopPageLayout>
      </Main>
    </Root>
  );
}
