import axios, { AxiosResponse } from 'axios';
import { useContext } from 'react';
import useSWR from 'swr';
import { SlotSettingRulesApiResponse } from '../@interfaces/slot-setting-rule';
import { Store } from '../context/GlobalStore';
import { API_END_POINT } from './api';

const fetcher = async (token: string, shopId: number) =>
  axios
    .get(`${API_END_POINT}/app/shops/${shopId}/slot-setting-rules`, {
      headers: {
        Authorization: token,
      },
    })
    .then(
      (response: AxiosResponse<SlotSettingRulesApiResponse>) => response.data
    );

export default function useSlotSettingRules(shopId: number) {
  const { globalState } = useContext(Store);
  const { data, isLoading } = useSWR(
    globalState.session?.idToken ? [globalState.session.idToken, shopId] : null,
    async ([token, shopId]) => fetcher(token, shopId)
  );
  return {
    rulesWithSlotSettingIds: data?.rulesWithSlotSettingIds ?? [],
    isLoading,
  };
}
