/** @jsxImportSource @emotion/react */

import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import React from 'react';
import { Label } from '../../../@interfaces/label';
import { LabelPreview, labelStyles } from '../../../components/LabelChooser';
import LinkRouter from '../../../components/LinkRouter';

interface Props {
  workspaceUid: string;
  labels: Label[];
}

export default function WorkspaceLabelsTable(props: Props) {
  const { workspaceUid, labels } = props;

  return (
    <TableContainer component={Paper}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>ラベル</TableCell>
            <TableCell>アクション</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {labels.map((label, index) => (
            <TableRow key={label.id}>
              <TableCell scope="row">
                <LabelPreview label={label} />
              </TableCell>
              <TableCell scope="row">
                <LinkRouter
                  to={`/a/${workspaceUid}/settings/labels/${label.id}`}
                >
                  編集
                </LinkRouter>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
