/** @jsxImportSource @emotion/react */

import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Container from '@material-ui/core/Container';
import { useContext, useEffect } from 'react';
import useReactRouter from 'use-react-router';
import useGoogleTokenIntegration from '../../../api/integration/use-google-token-integration';
import {
  Head,
  Main,
  Root,
  TopAppBar,
  TopAppBarSpacer,
} from '../../../components/Shared';
import Spinner from '../../../components/Spinner';
import { Store } from '../../../context/GlobalStore';
import { useGoogleRedirectUrl } from '../../../hooks/use-google-redirect-url';

interface QueryString {
  [key: string]: string;
}

export default function ShopGoogleOauthCallbackPage(): JSX.Element {
  const { history, location } = useReactRouter();
  const { googleTokenIntegration, googleTokenIntegrationApi } =
    useGoogleTokenIntegration();
  const { globalState } = useContext(Store);
  const search = location.search;
  const query = search
    .slice(1)
    .split('&')
    .map((str) => [str.split('=')[0], str.split('=')[1]])
    .reduce((acc, a) => {
      acc[a[0]] = a[1];
      return acc;
    }, {} as QueryString);
  const { googleRedirectUrlState } = useGoogleRedirectUrl();
  if (!googleRedirectUrlState.redirectUrl) {
    history.push({
      pathname: window.location.origin,
    });
  }
  const shopId = googleRedirectUrlState.redirectUrl
    .split('shops/')
    .slice(-1)[0]
    .replace('/settings/integrations/new', '');
  const url = `${window.location.origin}/google/oauth/callback`;

  useEffect(() => {
    if (globalState.session?.idToken)
      googleTokenIntegrationApi(shopId, query.code, url);
  }, [globalState.session?.idToken]);

  useEffect(() => {
    if (googleTokenIntegration && globalState.session?.idToken) {
      history.push({
        pathname: googleRedirectUrlState.redirectUrl,
        state: { calendars: googleTokenIntegration },
      });
    }
  }, [googleTokenIntegration, globalState.session?.idToken]);

  return (
    <Root>
      <Head title="google oauth" />
      <TopAppBar />
      <Main>
        <TopAppBarSpacer />
        <Container maxWidth="lg">
          <Breadcrumbs aria-label="breadcrumb" />
          <Spinner loading={true} />
        </Container>
      </Main>
    </Root>
  );
}
