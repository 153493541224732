import axios, { AxiosResponse } from 'axios';
import { useContext } from 'react';
import useSWR from 'swr';
import {
  GetReservationTotalParams,
  GetReservationTotalResponse,
} from '../../../@interfaces/insight/api';
import {
  AggregationUnit,
  TargetDateType,
} from '../../../@interfaces/insight/backend';
import { API_END_POINT } from '../../../api/api';
import { Store } from '../../../context/GlobalStore';

const fetcher = async (
  url: string,
  token: string,
  primaryStartDate: string,
  primaryEndDate: string,
  comparisonStartDate: string,
  comparisonEndDate: string,
  aggregationUnit: AggregationUnit,
  targetDateType: TargetDateType
) => {
  return axios
    .get<GetReservationTotalParams, AxiosResponse<GetReservationTotalResponse>>(
      url,
      {
        params: {
          primaryStartDate,
          primaryEndDate,
          comparisonStartDate,
          comparisonEndDate,
          aggregationUnit,
          targetDateType,
        },
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => response.data);
};

export default function useInsightReservationTotal(
  shopId: string,
  primaryStartDate: string,
  primaryEndDate: string,
  comparisonStartDate: string,
  comparisonEndDate: string,
  aggregationUnit: AggregationUnit,
  targetDateType: TargetDateType
) {
  const { globalState } = useContext(Store);

  const { data, isLoading } = useSWR(
    globalState.session?.idToken
      ? [
          `${API_END_POINT}/app/shops/${shopId}/insight/reservation/total`,
          globalState.session.idToken,
          primaryStartDate,
          primaryEndDate,
          comparisonStartDate,
          comparisonEndDate,
          aggregationUnit,
          targetDateType,
        ]
      : null,
    async ([
      url,
      token,
      primaryStartDate,
      primaryEndDate,
      comparisonStartDate,
      comparisonEndDate,
      aggregationUnit,
      targetDateType,
    ]) =>
      fetcher(
        url,
        token,
        primaryStartDate,
        primaryEndDate,
        comparisonStartDate,
        comparisonEndDate,
        aggregationUnit,
        targetDateType
      )
  );

  return {
    results: data,
    isLoading: isLoading,
  };
}
