import { atom, useRecoilState } from 'recoil';
import { recoilPersist } from 'recoil-persist';

export const useGoogleRedirectUrl = () => {
  const { persistAtom } = recoilPersist({
    key: 'recoil-persist',
    storage: sessionStorage,
  });
  const googleRedirectUrlState = atom<{ redirectUrl: string }>({
    key: 'googleRedirectUrlState',
    default: { redirectUrl: '' },
    effects_UNSTABLE: [persistAtom],
  });
  const [googleRedirectUrlRecoil, setGoogleRedirectUrl] = useRecoilState(
    googleRedirectUrlState
  );

  return {
    googleRedirectUrlState: googleRedirectUrlRecoil,
    setGoogleRedirectUrl: setGoogleRedirectUrl,
  };
};
