/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Breadcrumbs,
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  Input,
  MenuItem as MuiMenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import React, { ChangeEvent, useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import useReactRouter from 'use-react-router';
import {
  DisplayMode,
  FormField,
  ResourceConstantSetting,
  ShopCourseSetting,
} from '../../../@interfaces/course-slot-setting';
import {
  NewShopSetting,
  WebReservationLimitType,
} from '../../../@interfaces/shop-setting';
import { API_END_POINT } from '../../../api/api';
import useCourseSlotSetting from '../../../api/use-course-slot-setting';
import useFormSetting from '../../../api/use-form-setting';
import useShop from '../../../api/use-shop';
import useShopCourse from '../../../api/use-shop-course';
import useShopSetting from '../../../api/use-shop-setting';
import {
  BreadcrumbLinkItem,
  BreadcrumbShopItem,
  BreadcrumbTextItem,
  BreadcrumbWorkspaceItem,
} from '../../../components/breadcrumb/BreadcrumbItem';
import { commonCss } from '../../../components/common-css';
import { ShopPageLayout } from '../../../components/layouts/ShopPageLayout';
import { PageTitleAndDescription } from '../../../components/PageTitleAndDescription';
import {
  FullscreenLoading,
  Head,
  Main,
  Root,
} from '../../../components/Shared';
import Spinner from '../../../components/Spinner';
import { useStyles } from '../../../components/Styles';
import { Store } from '../../../context/GlobalStore';
import {
  AnyField,
  CheckboxField,
  FormSetting,
  Option,
  RadioField,
} from '../../../core/types/reservation-form-types';
import { ResourceConstantSettingForm } from '../../../features/resource/components/constant/ResourceConstantSettingForm';
import { UpdateResourceConstantSettingHandler } from '../../../features/resource/types';
import { globalColors } from '../../../styles/globalColors';
import { helps } from '../../../utils/helps';

const cssStyles = {
  sectionWrapper: css`
    margin-top: 20px;
  `,
  name: css`
    font-size: 15px;
    padding: 0;
  `,
  fontBold: css`
    font-weight: bold;
  `,
  tableHeadOption: css`
    width: 20%;
  `,
  description: css`
    background: #eee;
    padding: 10px;
  `,
  accordion: css`
    border: 1px solid #eee;
    box-shadow: none;
  `,
  accordionSummary: css`
    background: #eee;
  `,
};

const minutesRequiredRange = {
  min: 5,
  max: 60 * 8,
};

const createDefaultShopCourseSetting = (): ShopCourseSetting => {
  return {
    minutesRequired: undefined,
    fields: [],
  };
};

type PageParams = {
  workspaceUid: string;
  shopId: string;
  courseId: string;
};

type Form = {
  published: boolean;
  listing: boolean;
  shopCourseSetting: ShopCourseSetting;
  resourceConstantSetting: ResourceConstantSetting | undefined;
  description: string;
  formHeader: string;
  formFooter: string;
  thanksTitle: string | null;
  thanksMessage: string | null;
  webReservationLimitType: WebReservationLimitType;
  webReservationLimitDays: number | undefined;
  webReservationLimitHours: number | undefined;
  webReservationAdvanceDays: number | undefined;
  overrideWebReservationLimit: boolean;
  displayMode: DisplayMode;
  displayDays: number | undefined;
  showsSlotCapacity: 0 | 1;
  hidesNoSlotDate: boolean;
  cancelMessage: string;
};

export default function ShopCourseSlotSettingPage(props: any) {
  const { globalState } = useContext(Store);
  const { history, match } = useReactRouter<PageParams>();
  const { workspaceUid, shopId, courseId } = match.params;
  const validationContext = useForm();
  const { handleSubmit } = validationContext;
  const styles = useStyles();

  const { shop, isLoadingShop } = useShop(shopId);
  const { shopCourse, isLoadingShopCourse } = useShopCourse(shopId, courseId);
  const { courseSlotSetting, isLoadingCourseSlotSetting } =
    useCourseSlotSetting(shopId, courseId);
  const { formSetting, isLoadingFormSetting } = useFormSetting(
    workspaceUid,
    courseId
  );
  const { shopSetting } = useShopSetting(shopId);
  const [form, setForm] = useState<Form>({
    published: true,
    listing: true,
    shopCourseSetting: createDefaultShopCourseSetting(),
    resourceConstantSetting: undefined,
    description: '',
    formHeader: '',
    formFooter: '',
    thanksTitle: '',
    thanksMessage: '',
    webReservationLimitType: 'none',
    webReservationLimitDays: undefined,
    webReservationLimitHours: undefined,
    webReservationAdvanceDays: undefined,
    overrideWebReservationLimit: false,
    displayMode: 'default',
    displayDays: undefined,
    showsSlotCapacity: 0,
    hidesNoSlotDate: false,
    cancelMessage: '',
  });

  useEffect(() => {
    if (!courseSlotSetting) {
      return;
    }
    // 存在しないプロパティに初期値を設定
    const { resourceConstantSetting } = courseSlotSetting;
    resourceConstantSetting?.constants.forEach((constant) => {
      if (constant.type === 'serial') {
        constant.targets.forEach((target) => {
          if (!Array.isArray(target.optionalFormFields)) {
            target.optionalFormFields = [];
          }
        });
      }
    });
    setForm({
      published: courseSlotSetting.published,
      listing: courseSlotSetting.listing,
      shopCourseSetting:
        courseSlotSetting.shopCourseSetting || createDefaultShopCourseSetting(),
      resourceConstantSetting: courseSlotSetting.resourceConstantSetting,
      description: courseSlotSetting.description,
      formHeader: courseSlotSetting.formHeader,
      formFooter: courseSlotSetting.formFooter,
      thanksTitle: courseSlotSetting.thanksTitle,
      thanksMessage: courseSlotSetting.thanksMessage,
      webReservationLimitType: courseSlotSetting.webReservationLimitType,
      webReservationLimitDays: courseSlotSetting.webReservationLimitDays,
      webReservationLimitHours: courseSlotSetting.webReservationLimitHours,
      webReservationAdvanceDays: courseSlotSetting.webReservationAdvanceDays,
      overrideWebReservationLimit:
        courseSlotSetting.overrideWebReservationLimit,
      displayMode: courseSlotSetting.displayMode,
      displayDays: courseSlotSetting.displayDays ?? undefined,
      showsSlotCapacity: courseSlotSetting.showsSlotCapacity ?? 0,
      hidesNoSlotDate: courseSlotSetting.hidesNoSlotDate,
      cancelMessage: courseSlotSetting.cancelMessage ?? '',
    });
  }, [courseSlotSetting]);

  const [openBackdrop, setOpenBackdrop] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const handleChangePublished = (e: React.ChangeEvent<HTMLInputElement>) => {
    const published = !form.published;
    if (published) {
      setForm({ ...form, published });
    } else {
      setForm({ ...form, published, listing: false });
    }
  };

  const handleChangeListing = (e: React.ChangeEvent<HTMLInputElement>) => {
    const listing = !form.listing;
    setForm({ ...form, listing });
  };

  const handleChangeDisplayMode = (e: React.ChangeEvent<HTMLInputElement>) => {
    const displayMode = e.target.checked ? 'table' : 'default';
    if (displayMode === 'table') {
      setForm({
        ...form,
        displayMode,
        hidesNoSlotDate: false, // テーブル表示の場合は非表示にできない
        displayDays: undefined, // テーブル表示の場合は表示日数を指定できない
      });
    } else {
      setForm({ ...form, displayMode });
    }
  };

  const handleChangeIsHideNoReservationDate = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setForm({ ...form, hidesNoSlotDate: e.target.checked });
  };

  const handleChangeDisplayDaysMode = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (form.displayDays) {
      setForm({ ...form, displayDays: undefined });
    } else {
      setForm({ ...form, displayDays: 30 });
    }
  };

  const handleChangeDisplayDaysNumber = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (e.target.value) {
      const displayDays = parseInt(e.target.value);
      setForm({ ...form, displayDays });
    } else {
      setForm({ ...form, displayDays: undefined });
    }
  };

  const handleChangeDescription = (e: ChangeEvent<HTMLInputElement>) => {
    const description = e.target.value;
    setForm({ ...form, description });
  };

  const handleChangeFormHeader = (e: ChangeEvent<HTMLInputElement>) => {
    const formHeader = e.target.value;
    setForm({ ...form, formHeader });
  };

  const handleChangeFormFooter = (e: ChangeEvent<HTMLInputElement>) => {
    const formFooter = e.target.value;
    setForm({ ...form, formFooter });
  };

  const handleChangeThanksTitle = (e: ChangeEvent<HTMLInputElement>) => {
    const thanksTitle = e.target.value;
    setForm({ ...form, thanksTitle });
  };

  const handleChangeCancelMessage = (e: ChangeEvent<HTMLInputElement>) => {
    const cancelMessage = e.target.value;
    setForm({ ...form, cancelMessage });
  };

  const handleChangeThanksMessage = (e: ChangeEvent<HTMLInputElement>) => {
    const thanksMessage = e.target.value;
    setForm({ ...form, thanksMessage });
  };

  const handleUpdateShopCourseSetting: UpdateShopCourseSettingHandler = (
    shopCourseSetting: ShopCourseSetting
  ) => {
    setForm({ ...form, shopCourseSetting });
  };

  const handleUpdateCourseSetting: UpdateCourseSettingHandler = (
    formCourseSetting: Form
  ) => {
    console.log(formCourseSetting);
    setForm(formCourseSetting);
  };

  const handleUpdateResourceConstantSetting: UpdateResourceConstantSettingHandler =
    (resourceConstantSetting) => {
      setForm({ ...form, resourceConstantSetting });
    };

  const handleClickCancel = () => {
    history.push(`/a/${workspaceUid}/shops/${shopId}/settings/courses`);
  };

  const handleSubmitForm = () => {
    if (!globalState.session?.idToken) {
      return;
    }
    if (
      form.shopCourseSetting?.minutesRequired !== undefined &&
      (form.shopCourseSetting?.minutesRequired < minutesRequiredRange.min ||
        form.shopCourseSetting?.minutesRequired > minutesRequiredRange.max)
    ) {
      alert(
        `所要時間は${minutesRequiredRange.min}分〜${minutesRequiredRange.max}分の範囲内で設定してください。`
      );
      return;
    }
    if (
      form.resourceConstantSetting &&
      !form.shopCourseSetting.minutesRequired
    ) {
      alert('リソースによる制約を使用する場合は所要時間の設定が必須です。');
      return;
    }
    const json = {
      published: form.published,
      listing: form.listing,
      shopCourseSetting: form.shopCourseSetting,
      resourceConstantSetting: form.resourceConstantSetting,
      description: form.description,
      formHeader: form.formHeader,
      formFooter: form.formFooter,
      thanksTitle: form.thanksTitle,
      thanksMessage: form.thanksMessage,
      webReservationLimitType: form.webReservationLimitType,
      webReservationLimitDays: form.webReservationLimitDays,
      webReservationLimitHours: form.webReservationLimitHours,
      webReservationAdvanceDays: form.webReservationAdvanceDays,
      overrideWebReservationLimit: form.overrideWebReservationLimit,
      displayMode: form.displayMode,
      displayDays: form.displayDays,
      showsSlotCapacity: form.showsSlotCapacity,
      hidesNoSlotDate: form.hidesNoSlotDate,
      cancelMessage: form.cancelMessage,
    };
    setOpenBackdrop(true);
    axios
      .put(
        `${API_END_POINT}/app/shops/${shopId}/courses/${courseId}/course-slot-setting`,
        json,
        {
          headers: {
            Authorization: globalState.session?.idToken,
          },
        }
      )
      .then(() => {
        enqueueSnackbar('設定を保存しました。', { variant: 'success' });
      })
      .catch((e) => {
        enqueueSnackbar('設定が保存できませんでした。', { variant: 'error' });
      })
      .finally(() => {
        setOpenBackdrop(false);
      });
  };

  const validate = () => {
    if (form.description.length > 1000) {
      return false;
    }
    if ((form.thanksMessage?.length || 0) > 1000) {
      return false;
    }
    return true;
  };

  const buildRightNavigationButtons = () => {
    return (
      <>
        <Button
          css={commonCss.button.right}
          type="button"
          variant="text"
          color="primary"
          onClick={handleClickCancel}
        >
          キャンセル
        </Button>
        <Button
          css={commonCss.button.right}
          type="submit"
          variant="contained"
          color="primary"
          disabled={!validate()}
        >
          保存
        </Button>
      </>
    );
  };

  const buildContents = () => {
    if (
      isLoadingShop ||
      isLoadingShopCourse ||
      isLoadingCourseSlotSetting ||
      isLoadingFormSetting
    ) {
      return <Spinner loading={true} />;
    }
    return (
      <>
        <form onSubmit={handleSubmit(handleSubmitForm)}>
          <Grid container css={cssStyles.sectionWrapper}>
            <Grid item xs={6}>
              <h3>{shopCourse?.name}のコース設定</h3>
            </Grid>
            <Grid item container xs={6} alignItems="center" justify="flex-end">
              {buildRightNavigationButtons()}
            </Grid>
          </Grid>
          <Paper className={styles.paper}>
            <FormControl component="fieldset">
              <FormControlLabel
                control={
                  <Switch
                    checked={form.published}
                    onChange={handleChangePublished}
                    color="primary"
                  />
                }
                label="Webで予約可能"
              />
              <FormHelperText>
                Webで予約可能をオフにした場合、このコースは管理画面のみでの利用になります。
              </FormHelperText>
            </FormControl>
            <FormControl component="fieldset">
              <FormControlLabel
                control={
                  <Switch
                    checked={form.listing}
                    onChange={handleChangeListing}
                    color="primary"
                  />
                }
                label="Webのコース一覧に表示する"
                disabled={!form.published}
              />
              <FormHelperText>
                Webのコース一覧画面にこのコースを表示します。表示しない場合、コース一覧には表示されずコース詳細のURLを知っている人だけがWebから予約できます。
              </FormHelperText>
            </FormControl>
          </Paper>
          <Grid container css={cssStyles.sectionWrapper}>
            <Grid item xs={12}>
              <h3>Web予約画面の表示設定</h3>
            </Grid>
          </Grid>
          <Paper className={styles.paper}>
            <div>
              <FormControl component="fieldset">
                <FormControlLabel
                  control={
                    <Switch
                      checked={!!form.showsSlotCapacity}
                      onChange={(e) => {
                        setForm((form) => ({
                          ...form,
                          showsSlotCapacity: e.target.checked ? 1 : 0,
                        }));
                      }}
                      color="primary"
                    />
                  }
                  label="予約可能数を表示する"
                />
                <FormHelperText>
                  予約枠毎に予約可能数を数値で表示します（例:「残り3」）。OFFの場合は◎、△、☓を表示します。
                </FormHelperText>
              </FormControl>
            </div>
            <div>
              <FormControl component="fieldset">
                <FormControlLabel
                  control={
                    <Switch
                      checked={form.displayMode == 'default' ? false : true}
                      onChange={handleChangeDisplayMode}
                      color="primary"
                    />
                  }
                  label="テーブル形式で表示する"
                />
                <FormHelperText>
                  Web予約画面の予約枠の表示をデフォルトから週単位のテーブル表示に変更します。
                </FormHelperText>
              </FormControl>
            </div>
            <div>
              <FormControl component="fieldset">
                <FormControlLabel
                  control={
                    <Switch
                      checked={form.hidesNoSlotDate}
                      disabled={form.displayMode === 'table'}
                      onChange={handleChangeIsHideNoReservationDate}
                      color="primary"
                    />
                  }
                  label="予約枠がない日付を隠す"
                />
                <FormHelperText>
                  Web予約画面で予約枠が存在する日付のみを表示します。テーブル形式で表示する場合は、有効にできません。
                </FormHelperText>
              </FormControl>
            </div>
            <div>
              <FormControl component="fieldset">
                <FormControlLabel
                  control={
                    <Switch
                      checked={Boolean(form.displayDays)}
                      disabled={form.displayMode === 'table'}
                      onChange={handleChangeDisplayDaysMode}
                      color="primary"
                    />
                  }
                  label="表示日数を指定する"
                />
                {form.displayDays && (
                  <TextField
                    label="1画面の予約枠表示日数"
                    type="number"
                    inputProps={{ min: 7, max: 100, step: 1 }}
                    value={form.displayDays}
                    onChange={handleChangeDisplayDaysNumber}
                    helperText="7日以上100日以下"
                  />
                )}
                <FormHelperText>
                  Web予約画面で1画面で表示する予約枠の日数を指定します。未指定の場合は7日(1週間)です。テーブル形式で表示する場合は、有効にできません。
                </FormHelperText>
              </FormControl>
            </div>
          </Paper>
          <Grid container css={cssStyles.sectionWrapper}>
            <Grid item xs={12}>
              <h3>所要時間</h3>
            </Grid>
          </Grid>
          <Paper className={styles.paper}>
            {formSetting ? (
              <ShopCourseSettingForm
                shopCourseSetting={form.shopCourseSetting}
                formSetting={formSetting}
                onUpdateShopCourseSetting={handleUpdateShopCourseSetting}
              />
            ) : null}
          </Paper>
          {shopSetting?.enableResource ? (
            <ResourceConstantSettingForm
              shopId={shopId}
              resourceConstantSetting={form.resourceConstantSetting}
              shopCourseSetting={form.shopCourseSetting}
              formSetting={formSetting}
              onUpdateResourceConstantSetting={
                handleUpdateResourceConstantSetting
              }
            />
          ) : null}
          <Grid container css={cssStyles.sectionWrapper}>
            <Grid item xs={12}>
              <h3>予約</h3>
            </Grid>
          </Grid>
          <Paper className={styles.paper}>
            {form ? (
              <ShopCourseSettingReservationForm
                form={form}
                onUpdateCourseSetting={handleUpdateCourseSetting}
                shopSetting={shopSetting}
              />
            ) : null}
          </Paper>
          <Grid container css={cssStyles.sectionWrapper}>
            <Grid item xs={12}>
              <h3>コースの説明文</h3>
            </Grid>
          </Grid>
          <Paper className={styles.paper}>
            ワークスペースに設定されたコースの説明文
            <div css={cssStyles.description}>{shopCourse?.description}</div>
            <TextField
              label="店舗のコースの説明文"
              value={form.description}
              multiline
              fullWidth
              onChange={handleChangeDescription}
              helperText="店舗の説明文がワークスペースの説明文より優先されます。HTMLタグはimg, a, span, table, tr, th, tdが使用できます。(上限1000文字)"
              error={form.description.length > 1000}
            />
            <TextField
              label="入力フォームのヘッダーの文章"
              value={form.formHeader}
              multiline
              fullWidth
              onChange={handleChangeFormHeader}
              helperText="入力フォームの上部に表示されます"
            />
            <TextField
              label="入力フォームのフッターの文章"
              value={form.formFooter}
              multiline
              fullWidth
              onChange={handleChangeFormFooter}
              helperText="入力フォームの下部に表示されます"
            />
          </Paper>
          <Grid container css={cssStyles.sectionWrapper}>
            <Grid item xs={12}>
              <h3>予約完了時のメッセージ</h3>
            </Grid>
          </Grid>
          <Paper className={styles.paper}>
            <TextField
              label="予約完了時のメッセージ"
              value={form.thanksTitle}
              multiline
              fullWidth
              onChange={handleChangeThanksTitle}
              helperText="店舗ごとにメッセージを設定することができます。設定しない場合、コースに設定されたメッセージが表示されます。"
            />
            <TextField
              label="予約完了時の補足文"
              value={form.thanksMessage}
              multiline
              fullWidth
              onChange={handleChangeThanksMessage}
              helperText="店舗ごとに補足文を設定することができます。設定しない場合、コースに設定された補足文が表示されます。HTMLタグはimg, a, span, table, tr, th, tdが使用できます。(上限1000文字)"
              error={(form.thanksMessage?.length || 0) > 1000}
            />
          </Paper>
          <Grid container css={cssStyles.sectionWrapper}>
            <Grid item xs={12}>
              <h3>キャンセル画面のメッセージ</h3>
            </Grid>
          </Grid>
          <Paper className={styles.paper}>
            <TextField
              label="キャンセル画面のメッセージ"
              value={form.cancelMessage}
              multiline
              fullWidth
              onChange={handleChangeCancelMessage}
              helperText="キャンセル画面に表示するメッセージを設定します。(上限1000文字)"
              error={(form.cancelMessage?.length || 0) > 1000}
            />
          </Paper>
          <Grid container css={cssStyles.sectionWrapper}>
            <Grid item xs={6}></Grid>
            <Grid item container xs={6} alignItems="center" justify="flex-end">
              {buildRightNavigationButtons()}
            </Grid>
          </Grid>
        </form>
      </>
    );
  };

  const pageTitle = '設定の編集';

  return (
    <Root>
      <Head
        title={`店舗設定 - ${shop?.name || ''} ${shopCourse?.name || ''}`}
      />
      <Main>
        <ShopPageLayout
          workspaceUid={workspaceUid}
          shopId={shopId}
          current="course-detail"
          helpId={helps.shop.setting.course}
          breadcrumbs={
            <Breadcrumbs aria-label="breadcrumb">
              <BreadcrumbLinkItem to={`/`}>ホーム</BreadcrumbLinkItem>
              <BreadcrumbWorkspaceItem workspaceUid={workspaceUid} />
              <BreadcrumbShopItem workspaceUid={workspaceUid} shop={shop} />
              <BreadcrumbLinkItem
                to={`/a/${workspaceUid}/shops/${shopId}/settings/courses`}
              >
                コース詳細設定
              </BreadcrumbLinkItem>
              <BreadcrumbTextItem>{pageTitle}</BreadcrumbTextItem>
            </Breadcrumbs>
          }
        >
          <PageTitleAndDescription
            title="店舗"
            subTitle={pageTitle}
            description="Web表示のオンオフの切り替え等の設定を行います。"
            themeColor={globalColors.shop}
          />
          {buildContents()}
        </ShopPageLayout>
      </Main>
      <FullscreenLoading open={openBackdrop} />
    </Root>
  );
}

type UpdateShopCourseSettingHandler = (setting: ShopCourseSetting) => void;
type UpdateCourseSettingHandler = (formCourseSetting: Form) => void;

const ShopCourseSettingForm = ({
  shopCourseSetting,
  formSetting,
  onUpdateShopCourseSetting,
}: {
  shopCourseSetting: ShopCourseSetting;
  formSetting: FormSetting;
  onUpdateShopCourseSetting: UpdateShopCourseSettingHandler;
}): JSX.Element => {
  const handleChangeMinutesRequired = (e: ChangeEvent<HTMLInputElement>) => {
    const minutesRequired = e.target.value
      ? parseInt(e.target.value)
      : undefined;
    const newShopCourseSetting = {
      ...(shopCourseSetting || ({} as ShopCourseSetting)),
      minutesRequired,
    };
    onUpdateShopCourseSetting(newShopCourseSetting);
  };

  const handleChangeUseMinutesRequired = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (shopCourseSetting?.minutesRequired) {
      const minutesRequired = undefined;
      const newShopCourseSetting = {
        ...(shopCourseSetting || ({} as ShopCourseSetting)),
        minutesRequired,
        showsMinutesRequired: false,
      };
      onUpdateShopCourseSetting(newShopCourseSetting);
    } else {
      const minutesRequired = 60;
      const newShopCourseSetting = {
        ...(shopCourseSetting || ({} as ShopCourseSetting)),
        minutesRequired,
      };
      onUpdateShopCourseSetting(newShopCourseSetting);
    }
  };

  const handleChangeShowsMinutesRequired = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    onUpdateShopCourseSetting({
      ...shopCourseSetting,
      showsMinutesRequired: e.target.checked,
    });
  };

  return (
    <div>
      <FormControl component="fieldset">
        <FormControlLabel
          control={
            <Switch
              checked={shopCourseSetting?.minutesRequired != undefined}
              onChange={handleChangeUseMinutesRequired}
              color="primary"
            />
          }
          label="所要時間を設定する"
        />
        <FormHelperText>
          このコースに所要時間を設定します。所要時間をもとに次に予約できる予約枠が決定されます。設定しない場合予約枠の間隔が所要時間となります。
        </FormHelperText>
      </FormControl>
      {shopCourseSetting?.minutesRequired !== undefined && (
        <>
          <TextField
            label="所要時間（分）"
            type="number"
            value={shopCourseSetting?.minutesRequired}
            onChange={handleChangeMinutesRequired || ''}
            helperText={`このコースにかかる所要時間です。${minutesRequiredRange.min}分〜${minutesRequiredRange.max}分で設定してください。`}
          />
          {formSetting.fields.map((field, index) => {
            return (
              (field.type === 'checkbox' || field.type === 'radio') && (
                <FieldSettingForm
                  shopCourseSetting={shopCourseSetting}
                  field={field}
                  onUpdateShopCourseSetting={onUpdateShopCourseSetting}
                  key={index}
                />
              )
            );
          })}
          <div>
            <FormControl
              component="fieldset"
              css={css`
                margin-top: 20px;
              `}
            >
              <FormControlLabel
                control={
                  <Switch
                    checked={shopCourseSetting?.showsMinutesRequired}
                    onChange={handleChangeShowsMinutesRequired}
                    color="primary"
                  />
                }
                label="所要時間を予約画面に表示する"
              />
              <FormHelperText>
                このコースの所要時間をWeb予約画面のコース一覧画面、予約画面に表示します。
              </FormHelperText>
            </FormControl>
          </div>
        </>
      )}
    </div>
  );
};

const ShopCourseSettingReservationForm = ({
  form,
  onUpdateCourseSetting,
  shopSetting,
}: {
  form: Form;
  onUpdateCourseSetting: UpdateCourseSettingHandler;
  shopSetting: NewShopSetting | undefined;
}): JSX.Element => {
  const validationContext = useForm();
  const { register } = validationContext;
  const handleChangeChangeByoverrideWebReservationLimit = () => {
    const newCourseSetting = {
      ...(form || ({} as Form)),
      overrideWebReservationLimit: !form.overrideWebReservationLimit,
    };
    if (newCourseSetting.overrideWebReservationLimit) {
      newCourseSetting.webReservationLimitType =
        shopSetting?.webReservationLimitType || 'none';
      newCourseSetting.webReservationLimitDays =
        shopSetting?.webReservationLimitDays;
      newCourseSetting.webReservationLimitHours =
        shopSetting?.webReservationLimitHours;
      newCourseSetting.webReservationAdvanceDays =
        shopSetting?.webReservationAdvanceDays;
    }
    onUpdateCourseSetting(newCourseSetting);
  };

  const handleChangeWebReservationLimitType = (
    e: ChangeEvent<HTMLInputElement>
  ) => {
    const webReservationLimitType = e.target.value as WebReservationLimitType;
    const newCourseSetting = {
      ...(form || ({} as Form)),
      webReservationLimitType,
    };
    onUpdateCourseSetting(newCourseSetting);
  };

  const handleChangeWebReservationLimitDays = (
    e: React.ChangeEvent<{ value: unknown }>
  ) => {
    const webReservationLimitDays = e.target.value as number;
    const newCourseSetting = {
      ...(form || ({} as Form)),
      webReservationLimitDays,
    };
    onUpdateCourseSetting(newCourseSetting);
  };

  const handleChangeWebReservationLimitHours = (
    e: ChangeEvent<HTMLInputElement>
  ) => {
    const webReservationLimitHours = parseInt(e.target.value);
    if (webReservationLimitHours >= 0 && webReservationLimitHours <= 23) {
      const newCourseSetting = {
        ...(form || ({} as Form)),
        webReservationLimitHours,
      };
      onUpdateCourseSetting(newCourseSetting);
    }
  };

  const handleChangeWebReservationAdvanceDaysType = (
    e: ChangeEvent<HTMLInputElement>
  ) => {
    const webReservationAdvanceDaysType = e.target.value as 'none' | 'deadline';
    if (webReservationAdvanceDaysType == 'none') {
      const newCourseSetting = {
        ...(form || ({} as Form)),
        webReservationAdvanceDays: undefined,
      };
      onUpdateCourseSetting(newCourseSetting);
    } else if (webReservationAdvanceDaysType == 'deadline') {
      const newCourseSetting = {
        ...(form || ({} as Form)),
        webReservationAdvanceDays: 30,
      };
      onUpdateCourseSetting(newCourseSetting);
    }
  };

  const handleChangeWebReservationAdvanceDays = (
    e: ChangeEvent<HTMLInputElement>
  ) => {
    const webReservationAdvanceDays = e.target.value
      ? parseInt(e.target.value)
      : undefined;
    if (
      webReservationAdvanceDays == undefined ||
      webReservationAdvanceDays > 0
    ) {
      const newCourseSetting = {
        ...(form || ({} as Form)),
        webReservationAdvanceDays,
      };
      onUpdateCourseSetting(newCourseSetting);
    }
  };

  const webReservationLimitText = () => {
    if (shopSetting?.webReservationLimitType == 'deadline') {
      return `予約時間の${shopSetting?.webReservationLimitDays}日前の${shopSetting?.webReservationLimitHours}時まで予約可能`;
    } else if (shopSetting?.webReservationLimitType == 'before') {
      return `予約時間の${shopSetting?.webReservationLimitDays}日と${shopSetting?.webReservationLimitHours}時間前まで予約可能`;
    } else {
      return '予約時間の前なら予約可能';
    }
  };

  return (
    <div>
      <FormControl component="fieldset">
        <FormControlLabel
          control={
            <Switch
              checked={form.overrideWebReservationLimit}
              onChange={handleChangeChangeByoverrideWebReservationLimit}
              color="primary"
            />
          }
          label="このコース独自に予約可能時間を設定する"
        />
        <FormHelperText>
          店舗での設定値：
          <span
            css={css`
              text-decoration: ${form.overrideWebReservationLimit
                ? 'line-through'
                : 'none'};
            `}
          >
            {webReservationLimitText()}、
            {shopSetting?.webReservationAdvanceDays == null
              ? '事前予約の設定を制限しない'
              : `${shopSetting?.webReservationAdvanceDays}日前からWeb予約可能にする`}
          </span>
        </FormHelperText>
      </FormControl>
      {form.overrideWebReservationLimit == true && (
        <>
          <Divider
            css={css`
              margin: 20px 0px;
            `}
          />
          <FormControl component="fieldset">
            <FormLabel component="legend">
              Webからの直前予約可能時間の制限
            </FormLabel>
            <RadioGroup
              name="webReservationLimitType"
              value={form.webReservationLimitType}
              onChange={handleChangeWebReservationLimitType}
            >
              <FormControlLabel
                value="none"
                control={<Radio />}
                label="予約時間の前なら予約可能"
              />
              <Grid container justifyContent="flex-start" alignItems="center">
                <Grid item>
                  <FormControlLabel
                    value="deadline"
                    control={<Radio />}
                    label="期限を指定"
                  />
                </Grid>
                <Grid item>
                  <Typography
                    css={css`
                      font-size: 14px;
                      color: rgba(0, 0, 0, 0.54);
                    `}
                  >
                    ※ 前日まで予約可能な場合「当日の0時まで」で設定します。
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                container
                alignItems="center"
                css={css`
                  margin-left: 16px;
                `}
              >
                予約時間の
                <FormControl>
                  <Select
                    value={
                      form.webReservationLimitType === 'deadline'
                        ? form.webReservationLimitDays
                        : ''
                    }
                    onChange={handleChangeWebReservationLimitDays}
                    disabled={form.webReservationLimitType !== 'deadline'}
                  >
                    {[...Array(91)].map((_, index) => (
                      <MuiMenuItem value={index} key={index}>
                        {index === 0 ? '当日' : index + '日前'}
                      </MuiMenuItem>
                    ))}
                  </Select>
                </FormControl>
                の
                <Input
                  type="number"
                  inputRef={register({
                    required: form.webReservationLimitType === 'deadline',
                    min: 0,
                    max: 23,
                  })}
                  value={
                    form.webReservationLimitType === 'deadline'
                      ? form.webReservationLimitHours
                      : ''
                  }
                  onChange={handleChangeWebReservationLimitHours}
                  css={css`
                    width: 50px;
                  `}
                  disabled={form.webReservationLimitType !== 'deadline'}
                />
                時までで予約可能
              </Grid>
              <FormControlLabel
                value="before"
                control={<Radio />}
                label="指定時間前までを指定"
              />
              <Grid
                container
                alignItems="center"
                css={css`
                  margin-left: 16px;
                `}
              >
                予約時間の
                <Input
                  type="number"
                  inputRef={register({
                    required: form.webReservationLimitType === 'before',
                    min: 0,
                    max: 365,
                  })}
                  value={
                    form.webReservationLimitType === 'before'
                      ? form.webReservationLimitDays
                      : ''
                  }
                  onChange={handleChangeWebReservationLimitDays}
                  css={css`
                    width: 50px;
                  `}
                  disabled={form.webReservationLimitType !== 'before'}
                />
                日と
                <Input
                  type="number"
                  inputRef={register({
                    required: form.webReservationLimitType === 'before',
                    min: 0,
                    max: 23,
                  })}
                  value={
                    form.webReservationLimitType === 'before'
                      ? form.webReservationLimitHours
                      : ''
                  }
                  onChange={handleChangeWebReservationLimitHours}
                  css={css`
                    width: 50px;
                  `}
                  disabled={form.webReservationLimitType !== 'before'}
                />
                時間前まで予約可能
              </Grid>
            </RadioGroup>
          </FormControl>
          <Divider
            css={css`
              margin: 20px 0px;
            `}
          />
          <FormControl component="fieldset">
            <FormLabel component="legend">
              Webからの事前予約日数の制限
            </FormLabel>
            <RadioGroup
              name="reminderType"
              value={form.webReservationAdvanceDays ? 'deadline' : 'none'}
              onChange={handleChangeWebReservationAdvanceDaysType}
            >
              <FormControlLabel
                value="none"
                control={<Radio />}
                label="制限しない"
              />
              <FormControlLabel
                value="deadline"
                control={<Radio />}
                label="制限する"
              />
              <Grid
                container
                alignItems="center"
                css={css`
                  margin-left: 16px;
                `}
              >
                <Input
                  type="number"
                  value={form.webReservationAdvanceDays}
                  onChange={handleChangeWebReservationAdvanceDays}
                  css={css`
                    width: 50px;
                  `}
                  disabled={!form.webReservationAdvanceDays}
                />
                日前からWeb予約可能にする
              </Grid>
            </RadioGroup>
          </FormControl>
        </>
      )}
    </div>
  );
};

const FieldSettingForm = ({
  shopCourseSetting,
  field,
  onUpdateShopCourseSetting,
}: {
  shopCourseSetting: ShopCourseSetting;
  field: RadioField | CheckboxField;
  onUpdateShopCourseSetting: UpdateShopCourseSettingHandler;
}): JSX.Element | null => {
  const fieldSetting =
    shopCourseSetting.fields.find((f) => f.uid === field.uid) ||
    ({
      uid: field.uid,
      options: [],
    } as FormField);
  const accordionInitialValue = () =>
    fieldSetting.options.some((option) => (option.minutesRequired || 0) > 0);
  const [expanded, setExpanded] = useState<boolean>(accordionInitialValue());

  const handleChangeAccordion =
    (panel: string) => (event: React.ChangeEvent<{}>, newExpanded: boolean) => {
      setExpanded(newExpanded);
    };
  return (
    <Box css={cssStyles.sectionWrapper}>
      <Accordion
        expanded={expanded}
        onChange={handleChangeAccordion(field.uid)}
        css={cssStyles.accordion}
      >
        <AccordionSummary
          aria-controls={`panel${field.uid}-content`}
          id={`panel${field.uid}-header`}
          expandIcon={<ExpandMoreIcon />}
          css={cssStyles.accordionSummary}
        >
          <Typography css={cssStyles.name} style={{ width: '100%' }}>
            {field.name}
            {field.type === 'checkbox' ? '（複数選択可能）' : '（単一選択）'}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell
                  variant="head"
                  css={[cssStyles.fontBold, cssStyles.tableHeadOption]}
                >
                  オプション
                </TableCell>
                <TableCell variant="head" css={cssStyles.fontBold}>
                  追加の所要時間（分）
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {field.options.map((option, index) => {
                return (
                  <OptionSettingForm
                    shopCourseSetting={shopCourseSetting}
                    field={field}
                    fieldSetting={fieldSetting}
                    option={option}
                    onUpdateShopCourseSetting={onUpdateShopCourseSetting}
                    key={index}
                  />
                );
              })}
            </TableBody>
          </Table>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

const OptionSettingForm = ({
  shopCourseSetting,
  field,
  fieldSetting,
  option,
  onUpdateShopCourseSetting,
}: {
  shopCourseSetting: ShopCourseSetting;
  field: AnyField;
  fieldSetting: FormField;
  option: Option;
  onUpdateShopCourseSetting: UpdateShopCourseSettingHandler;
}): JSX.Element | null => {
  const handleChangeMinutesRequired = (e: ChangeEvent<HTMLInputElement>) => {
    const newShopCourseSetting = { ...shopCourseSetting };
    const existField =
      newShopCourseSetting.fields.findIndex((f) => f.uid === field.uid) !== -1;
    if (!existField) {
      newShopCourseSetting.fields.push(fieldSetting);
    }
    const minutesRequired = e.target.value
      ? parseInt(e.target.value)
      : undefined;
    const optionSetting = fieldSetting.options.find(
      (op) => op.uid === option.uid
    );
    if (optionSetting) {
      optionSetting.minutesRequired = minutesRequired;
    } else {
      const newOptionSetting = {
        uid: option.uid,
        minutesRequired: minutesRequired,
      };
      fieldSetting.options.push(newOptionSetting);
    }
    onUpdateShopCourseSetting(newShopCourseSetting);
  };
  const optionSetting = fieldSetting.options.find(
    (os) => os.uid === option.uid
  );
  const minutesRequired = optionSetting?.minutesRequired;
  return (
    <TableRow>
      <TableCell variant="head">{option.text}</TableCell>
      <TableCell>
        <TextField
          type="number"
          value={minutesRequired}
          onChange={handleChangeMinutesRequired || ''}
        />
      </TableCell>
    </TableRow>
  );
};
