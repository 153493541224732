import { GetCustomDomainsResponse } from '../../../@interfaces/custom-domain/api';
import { API_END_POINT } from '../../../api/api';
import { useQuery } from '../../common/api/use-query';

export const useCustomDomains = (workspaceUid: string) => {
  const { response, isLoading, reload } = useQuery<GetCustomDomainsResponse>(
    `${API_END_POINT}/app/ws/${workspaceUid}/custom-domains`
  );
  return {
    data: response?.customDomains || [],
    isLoading: isLoading,
    reload: reload,
  };
};
