/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import {
  Box,
  Button,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  ListSubheader,
  Menu,
  MenuItem,
  Paper,
  Switch,
  TextField,
} from '@material-ui/core';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import DeleteIcon from '@material-ui/icons/Delete';
import React, { ChangeEvent, useEffect } from 'react';
import useFormSettingFields from '../../../../api/use-form-setting-fields';
import { commonCss } from '../../../../components/common-css';
import {
  AnyField,
  createDefaultFieldCondition,
  defaultFieldCondition,
  FieldType,
  FormSetting,
  isSpecialFieldType,
  Option,
} from '../../../../core/types/reservation-form-types';
import { randomString } from '../../../../utils/string';
import { FormSettingField } from './FormSettingField';

const styles = {
  specialType: css`
    color: green;
  `,
  bottomButtonGroupLabel: css`
    min-height: 38px;
    margin-top: 10px;
    margin-right: 15px;
    padding-top: 4px;
    min-width: 90px;
  `,
};

interface FieldTypeLabel {
  label: string;
}

const fieldTypeLabels: {
  [filedType in FieldType]: FieldTypeLabel;
} = {
  text: {
    label: '自由回答（１行）',
  },
  textarea: {
    label: '自由回答（複数行）',
  },
  radio: {
    label: '選択肢（単一選択）',
  },
  checkbox: {
    label: '選択肢（複数選択）',
  },
  tel: {
    label: '電話番号',
  },
  email: {
    label: 'メールアドレス',
  },
  name: {
    label: 'お名前',
  },
  number: {
    label: '数値',
  },
};

const fieldTypes = [
  'name',
  'email',
  'tel',
  'text',
  'textarea',
  'radio',
  'checkbox',
  'number',
] as FieldType[];

type FormSettingFieldsProps = {
  workspaceUid: string;
  setting: FormSetting;
  onChangeSetting: (setting: FormSetting) => void;
  setIsExistsField: (newValue: boolean) => void;
  setAddItems: (newValue: string[]) => void;
  isSetDefaultValue: boolean;
  originalSetting: FormSetting;
};

export function FormSettingFields(props: FormSettingFieldsProps) {
  const {
    workspaceUid,
    setting,
    onChangeSetting,
    setIsExistsField,
    setAddItems,
    isSetDefaultValue,
    originalSetting,
  } = props;

  const { formSettingFields, isLoadedFormSettingFields } =
    useFormSettingFields(workspaceUid);
  const [newFieldMenuAnchorEl, setNewFieldMenuAnchorEl] =
    React.useState<null | HTMLElement>(null);

  useEffect(() => {
    if (isLoadedFormSettingFields && isSetDefaultValue) {
      if (setting.fields.length === 0) {
        defaultValueSetting();
      }

      if (notExistFields.length > 0) {
        setIsExistsField(true);
      }
    }
    // 初回のみ実行したいため依存を絞っている
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSetDefaultValue, isLoadedFormSettingFields]);

  const defaultValueSetting = () => {
    const fieldValues = formSettingFields.filter((field) => {
      return (
        field.type === 'name' || field.type === 'email' || field.type === 'tel'
      );
    });

    if (fieldValues.length > 0) {
      onChangeSetting({
        ...setting,
        fields: fieldValues,
      });
      setAddItems(fieldValues.map((f) => f.name));
    } else {
      const nameField = createNewField('name');
      const emailField = createNewField('email');
      const telField = createNewField('tel');
      const fields = [...setting.fields, nameField, emailField, telField];
      onChangeSetting({ ...setting, fields });
      setAddItems(fields.map((f) => f.name));
    }
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setNewFieldMenuAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setNewFieldMenuAnchorEl(null);
  };

  const handleChangeFieldType = (
    event: React.ChangeEvent<{ name?: string; value: unknown }>,
    field: AnyField
  ) => {
    const type = event.target.value as FieldType;
    const isSpecialTypeNext = isSpecialFieldType(type);
    if (isSpecialTypeNext) {
      const existsField = setting.fields.find((f) => f.type === type);
      if (existsField) {
        alert(`設定できる${fieldTypeLabels[type]?.label}項目は1つのみです。`);
        return;
      }
    }
    field.type = type;
    if (
      (field.type === 'checkbox' || field.type === 'radio') &&
      !field.options
    ) {
      field.options = [];
    }
    onChangeSetting({ ...setting });
  };

  const handleChangeName = (
    e: ChangeEvent<HTMLInputElement>,
    field: AnyField
  ) => {
    field.name = e.target.value;
    onChangeSetting({ ...setting });
  };

  const handleChangeDescription = (
    e: ChangeEvent<HTMLInputElement>,
    field: AnyField
  ) => {
    field.description = e.target.value;
    onChangeSetting({ ...setting });
  };

  const handleUpField = (field: AnyField) => {
    const { fields } = setting;
    const index = fields.indexOf(field);
    if (index === 0) {
      return;
    }
    const tmp = fields[index - 1];
    fields[index - 1] = field;
    fields[index] = tmp;
    onChangeSetting({ ...setting, fields: [...fields] });
  };

  const handleDownField = (field: AnyField) => {
    const { fields } = setting;
    const index = fields.findIndex((f) => f.uid === field.uid);
    if (index >= fields.length - 1) {
      return;
    }
    const tmp = fields[index + 1];
    fields[index + 1] = field;
    fields[index] = tmp;
    onChangeSetting({ ...setting, fields: [...fields] });
  };

  const handleDeleteField = (field: AnyField) => {
    const { fields, deletedFields } = setting;
    let deletedFields2 = deletedFields || [];
    const newFields = fields.filter((f) => f !== field);
    const existDeleteField = deletedFields2?.find((f) => f.uid === field.uid);
    let newDeletedFields: AnyField[] = [];
    if (existDeleteField) {
      if (
        (existDeleteField.type === 'checkbox' && field.type === 'checkbox') ||
        (existDeleteField.type === 'radio' && field.type === 'radio')
      ) {
        newDeletedFields = [
          {
            ...field,
            options: [...existDeleteField?.options, ...field.options],
          },
        ] as AnyField[];
        deletedFields2 = deletedFields2?.filter((f) => f.uid !== field.uid);
      }
    } else {
      newDeletedFields = fields?.filter((f) => f === field);
    }

    onChangeSetting({
      ...setting,
      fields: newFields,
      deletedFields: [...deletedFields2, ...newDeletedFields],
    });
  };

  const createNewField = (fieldType: FieldType): AnyField => {
    if (fieldType === 'checkbox' || fieldType === 'radio') {
      const options: Option[] = [1, 2, 3].map((n) => {
        return {
          uid: randomString(6),
          text: `選択肢${n}`,
        };
      });
      return {
        type: fieldType,
        uid: randomString(6),
        name: `項目${setting.fields.length + 1}`,
        description: '',
        options,
      };
    } else if (
      fieldType === 'name' ||
      fieldType === 'email' ||
      fieldType === 'tel'
    ) {
      return {
        type: fieldType,
        uid: randomString(6),
        name: fieldTypeLabels[fieldType].label,
        description: fieldTypeLabels[fieldType].label + 'を入力してください',
      };
    } else {
      return {
        type: fieldType,
        uid: randomString(6),
        name: isSpecialFieldType(fieldType)
          ? fieldTypeLabels[fieldType].label
          : `項目${setting.fields.length + 1}`,
        description: '',
      };
    }
  };

  const handleClickFieldAdd = (fieldType: FieldType) => {
    const newField = createNewField(fieldType);
    const fields = [...setting.fields, newField];
    onChangeSetting({ ...setting, fields });
    handleClose();
  };

  const handleClickExistFieldAdd = (existField: AnyField) => {
    if (isSpecialFieldType(existField.type)) {
      const exist = setting.fields.find((f) => f.type === existField.type);
      if (exist) {
        alert(
          `設定できる${
            fieldTypeLabels[existField.type]?.label
          }項目は1つのみです。`
        );
        return;
      }
    }
    const field = JSON.parse(JSON.stringify(existField)) as AnyField;
    const fields = [...setting.fields, field];
    const { deletedFields } = setting;
    const deletedFields2 = deletedFields || [];
    const newDeleteFields = deletedFields2.filter((f) => f.uid !== field.uid);
    onChangeSetting({
      ...setting,
      fields: fields,
      deletedFields: newDeleteFields,
    });
    handleClose();
  };

  const handleChangePublicRequired = (
    event: React.ChangeEvent<HTMLInputElement>,
    field: AnyField
  ) => {
    const condition = field.condition || createDefaultFieldCondition();
    condition.public.required = !condition.public.required;
    field.condition = condition;
    onChangeSetting({ ...setting });
  };

  const handleChangePublicVisibled = (
    event: React.ChangeEvent<HTMLInputElement>,
    field: AnyField
  ) => {
    const condition = field.condition || createDefaultFieldCondition();
    condition.public.visibled = !condition.public.visibled;
    field.condition = condition;
    onChangeSetting({ ...setting });
  };

  const handleChangeInternalRequired = (
    event: React.ChangeEvent<HTMLInputElement>,
    field: AnyField
  ) => {
    const condition = field.condition || createDefaultFieldCondition();
    condition.internal.required = !condition.internal.required;
    field.condition = condition;
    onChangeSetting({ ...setting });
  };

  const buildFormSettingField = (field: AnyField, index: number) => {
    const condition = field.condition || defaultFieldCondition;
    const originalField = originalSetting.fields.find(
      (f) => f.uid === field.uid
    );
    return (
      <Box key={index}>
        <Grid container>
          <Grid item xs={6}>
            <h3 style={{ marginBlockEnd: '0px' }}>
              項目{index + 1}
              <span style={{ color: '#aaa' }}>(ID: {field.uid})</span>
            </h3>
          </Grid>
          <Grid
            item
            container
            xs={6}
            alignItems="center"
            justifyContent="flex-end"
          >
            <IconButton
              onClick={() => {
                handleUpField(field);
              }}
              size="small"
            >
              <ArrowUpwardIcon />
            </IconButton>
            <IconButton
              onClick={() => {
                handleDownField(field);
              }}
              size="small"
            >
              <ArrowDownwardIcon />
            </IconButton>
            <IconButton
              onClick={() => {
                handleDeleteField(field);
              }}
              size="small"
            >
              <DeleteIcon />
            </IconButton>
          </Grid>
        </Grid>
        <Paper css={commonCss.paper} style={{ marginTop: '5px' }}>
          <Grid container>
            <TextField
              select
              label="項目の種別"
              value={field.type}
              onChange={(
                event: React.ChangeEvent<{ name?: string; value: unknown }>
              ) => {
                handleChangeFieldType(event, field);
              }}
              style={{ width: '200px' }}
              SelectProps={{
                style: {
                  color: isSpecialFieldType(field.type)
                    ? 'green'
                    : 'currentcolor',
                },
              }}
            >
              <ListSubheader>特別な種別（予約一覧に表示）</ListSubheader>
              {fieldTypes
                .filter((f) => isSpecialFieldType(f))
                .map((fieldType) => (
                  <MenuItem
                    key={fieldType}
                    value={fieldType}
                    css={styles.specialType}
                  >
                    {fieldTypeLabels[fieldType].label}
                  </MenuItem>
                ))}
              <Divider />
              <ListSubheader>汎用の種別</ListSubheader>
              {fieldTypes
                .filter((f) => !isSpecialFieldType(f))
                .map((fieldType) => (
                  <MenuItem key={fieldType} value={fieldType}>
                    {fieldTypeLabels[fieldType].label}
                  </MenuItem>
                ))}
            </TextField>
          </Grid>
          <TextField
            label="項目名"
            value={field.name}
            fullWidth
            required={true}
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              handleChangeName(event, field);
            }}
          />
          <TextField
            label="説明"
            value={field.description}
            helperText="HTMLタグのimg, a, span, table, tr, th, tdが使用できます。"
            fullWidth
            multiline
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              handleChangeDescription(event, field);
            }}
          />
          <FormSettingField
            field={field}
            setting={setting}
            onChangeSetting={onChangeSetting}
            originalField={originalField}
          />
          <Grid item container alignItems="center" xs={6}>
            <span css={styles.bottomButtonGroupLabel}>Web予約画面:</span>
            <FormControlLabel
              control={
                <Switch
                  checked={condition.public.required}
                  color="primary"
                  onChange={(e) => {
                    handleChangePublicRequired(e, field);
                  }}
                />
              }
              label="入力を必須にする"
            />
            <FormControlLabel
              control={
                <Switch
                  checked={condition.public.visibled}
                  color="primary"
                  onChange={(e) => {
                    handleChangePublicVisibled(e, field);
                  }}
                />
              }
              label="画面に表示する"
            />
          </Grid>
          <Grid item container alignItems="center" xs={6}>
            <span css={styles.bottomButtonGroupLabel}>管理画面:</span>
            <FormControlLabel
              control={
                <Switch
                  checked={condition.internal.required}
                  color="primary"
                  onChange={(e) => {
                    handleChangeInternalRequired(e, field);
                  }}
                />
              }
              label="入力を必須にする"
            />{' '}
          </Grid>
        </Paper>
      </Box>
    );
  };

  const notExistFields = formSettingFields.filter(
    (f) =>
      setting.fields.find((existsField) => existsField.uid === f.uid) ===
      undefined
  );

  const newFields = fieldTypes.filter((fieldType) => {
    if (!isSpecialFieldType(fieldType)) {
      return true;
    }
    const allFields = [...setting.fields, ...notExistFields];
    return allFields.find((f) => f.type === fieldType) === undefined;
  });
  return (
    <>
      {setting.fields.map((field, index) =>
        buildFormSettingField(field, index)
      )}
      <Grid item xs={12}>
        <Button variant="contained" color="default" onClick={handleClick}>
          項目を追加...
        </Button>
        <Menu
          anchorEl={newFieldMenuAnchorEl}
          keepMounted
          open={Boolean(newFieldMenuAnchorEl)}
          onClose={handleClose}
        >
          <ListSubheader>他のコースの既存項目からコピー</ListSubheader>
          {notExistFields.length === 0 ? (
            <MenuItem disabled>追加できる既存の項目はありません</MenuItem>
          ) : null}
          {notExistFields.map((f, index) => {
            return (
              <MenuItem
                onClick={() => {
                  handleClickExistFieldAdd(f);
                }}
                css={isSpecialFieldType(f.type) ? styles.specialType : null}
                key={index}
              >
                {f.name} - {fieldTypeLabels[f.type]?.label}
              </MenuItem>
            );
          })}
          <ListSubheader>新規項目を追加</ListSubheader>
          {newFields.map((fieldType, index) => {
            return (
              <MenuItem
                onClick={() => {
                  handleClickFieldAdd(fieldType);
                }}
                css={isSpecialFieldType(fieldType) ? styles.specialType : null}
                key={index}
              >
                {fieldTypeLabels[fieldType].label}
              </MenuItem>
            );
          })}
        </Menu>
      </Grid>
    </>
  );
}
