import axios, { AxiosResponse } from 'axios';
import { useContext, useEffect, useState } from 'react';
import { ResponseBody } from '../../../frontend-api/src/handlers/inflow-source/inflow-source-handler';
import { InflowSource } from '../@interfaces/inflow-source';
import { Store } from '../context/GlobalStore';
import { API_END_POINT } from './api';

const cache: { [inflowSourceId: string]: InflowSource } = {};

export default function useInflowSource(
  workspaceUid: string,
  inflowSourceId: string | undefined
) {
  const { globalState, setGlobalState } = useContext(Store);
  const [inflowSource, setInflowSource] = useState<InflowSource>(
    cache[inflowSourceId || '']
  );
  const [loading, setLoading] = useState(
    cache[inflowSourceId || ''] == undefined
  );
  const reload = () => {
    if (!globalState.session?.idToken || !inflowSourceId) {
      return;
    }
    axios
      .get(
        `${API_END_POINT}/app/ws/${workspaceUid}/inflow-sources/${inflowSourceId}`,
        {
          headers: {
            Authorization: globalState.session?.idToken,
          },
        }
      )
      .then((response: AxiosResponse<ResponseBody>) => {
        setInflowSource(response.data.inflowSource);
        cache[inflowSourceId] = response.data.inflowSource;
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    reload();
  }, [workspaceUid, inflowSourceId, globalState.session?.idToken]);
  return {
    inflowSource,
    isLoadingInflowSource: loading,
  };
}
