import { AxiosResponse } from 'axios';
import { CustomDomainCreateRequestParam } from '../../../@interfaces/custom-domain/api';
import { API_END_POINT } from '../../../api/api';
import { useMutation } from '../../common/api/use-mutation';

export const useCreateCustomDomain = (): ((
  workspaceUid: string,
  param: CustomDomainCreateRequestParam
) => Promise<AxiosResponse<''>>) => {
  const { request } = useMutation<'', CustomDomainCreateRequestParam>();
  return async (workspaceUid, params) => {
    return request(
      'POST',
      `${API_END_POINT}/app/ws/${workspaceUid}/custom-domains`,
      {},
      params
    );
  };
};
