/** @jsxImportSource @emotion/react */

import { Breadcrumbs, Button, Grid, Paper, TextField } from '@material-ui/core';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import React, { ChangeEvent, useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import useReactRouter from 'use-react-router';
import { API_END_POINT } from '../../../api/api';
import useInflowSource from '../../../api/use-inflow-source';
import {
  BreadcrumbLinkItem,
  BreadcrumbTextItem,
  BreadcrumbWorkspaceItem,
} from '../../../components/breadcrumb/BreadcrumbItem';
import { commonCss } from '../../../components/common-css';
import { WorkspacePageLayout } from '../../../components/layouts/WorkspacePageLayout';
import { PageTitleAndDescription } from '../../../components/PageTitleAndDescription';
import {
  FullscreenLoading,
  Head,
  Main,
  Root,
} from '../../../components/Shared';
import { Store } from '../../../context/GlobalStore';
import { globalColors } from '../../../styles/globalColors';
import { helps } from '../../../utils/helps';

interface PageParams {
  workspaceUid: string;
  inflowSourceId: string | undefined;
}

type Form = {
  uid: string;
  name: string;
};

export default function WorkspaceInflowSourceSettingEditPage(props: any) {
  const { globalState, setGlobalState } = useContext(Store);
  const { history, location, match } = useReactRouter<PageParams>();
  const { workspaceUid, inflowSourceId } = match.params;
  const validationContext = useForm();
  const { handleSubmit, register } = validationContext;
  const { inflowSource, isLoadingInflowSource } = useInflowSource(
    workspaceUid,
    inflowSourceId
  );

  const [form, setForm] = useState<Form>({
    uid: '',
    name: '',
  });
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (!inflowSource) {
      return;
    }
    setForm({
      uid: inflowSource.uid,
      name: inflowSource.name,
    });
  }, [JSON.stringify(inflowSource)]);

  const handleChangeUid = (e: ChangeEvent<HTMLInputElement>) => {
    const uid = e.target.value;
    setForm({ ...form, uid });
  };

  const handleChangeName = (e: ChangeEvent<HTMLInputElement>) => {
    const name = e.target.value;
    setForm({ ...form, name });
  };

  const handleClickDelete = () => {
    if (!globalState.session?.idToken) {
      return;
    }
    if (
      prompt('この流入元を削除するには「削除」と入力してください。') === '削除'
    ) {
      setOpenBackdrop(true);
      axios
        .delete(
          `${API_END_POINT}/app/ws/${workspaceUid}/inflow-sources/${inflowSourceId}`,
          {
            headers: {
              Authorization: globalState.session?.idToken,
            },
          }
        )
        .then(() => {
          enqueueSnackbar('流入元を削除しました。', { variant: 'success' });
          history.push(`/a/${workspaceUid}/settings/inflow-sources`);
        })
        .catch((e) => {
          alert('削除に失敗しました。');
        })
        .finally(() => {
          setOpenBackdrop(false);
        });
    }
  };

  const handleClickCancel = () => {
    history.push(`/a/${workspaceUid}/settings/inflow-sources`);
  };

  const update = () => {
    const json = {
      uid: form.uid,
      name: form.name,
    };
    setOpenBackdrop(true);
    axios
      .put(
        `${API_END_POINT}/app/ws/${workspaceUid}/inflow-sources/${inflowSourceId}`,
        json,
        {
          headers: {
            Authorization: globalState.session?.idToken,
          },
        }
      )
      .then(() => {
        enqueueSnackbar('流入元を保存しました。', { variant: 'success' });
        history.push(`/a/${workspaceUid}/settings/inflow-sources`);
      })
      .catch((e) => {
        enqueueSnackbar('流入元が保存できませんでした。', { variant: 'error' });
      })
      .finally(() => {
        setOpenBackdrop(false);
      });
  };

  const insert = () => {
    const json = {
      uid: form.uid,
      name: form.name,
    };
    setOpenBackdrop(true);
    axios
      .post(`${API_END_POINT}/app/ws/${workspaceUid}/inflow-sources`, json, {
        headers: {
          Authorization: globalState.session?.idToken,
        },
      })
      .then(() => {
        enqueueSnackbar('流入元を追加しました。', { variant: 'success' });
        history.push(`/a/${workspaceUid}/settings/inflow-sources`);
      })
      .catch((e) => {
        enqueueSnackbar('流入元が追加できませんでした。', { variant: 'error' });
      })
      .finally(() => {
        setOpenBackdrop(false);
      });
  };

  const handleSubmitForm = (e: React.FormEvent) => {
    if (inflowSourceId) {
      update();
    } else {
      insert();
    }
  };

  const buildRightNavigationButtons = () => {
    return (
      <>
        <Button
          css={commonCss.button.right}
          type="button"
          variant="text"
          color="primary"
          onClick={handleClickCancel}
        >
          キャンセル
        </Button>
        <Button
          css={commonCss.button.right}
          type="submit"
          variant="contained"
          color="primary"
        >
          &nbsp;保存&nbsp;
        </Button>
      </>
    );
  };

  const buildContents = () => {
    return (
      <form onSubmit={handleSubmit(handleSubmitForm)}>
        <Grid container style={{ marginTop: '20px' }}>
          <Grid item xs={6}>
            <h3>
              {inflowSourceId ? `${inflowSource?.name}の編集` : '流入元の追加'}
            </h3>
          </Grid>
          <Grid
            item
            container
            xs={6}
            alignItems="center"
            justifyContent="flex-end"
          >
            {buildRightNavigationButtons()}
          </Grid>
        </Grid>
        <Paper css={commonCss.paper}>
          <TextField
            label="流入元ID"
            helperText="流入元の一意なIDを設定します(例: hp, gmb, flyer, web-ad)。半角英数小文字、ハイフン、アンダーバーが使用できます。URLの流入元を示すパラメータ(_src=xxx)に使用されます。"
            value={form.uid}
            fullWidth
            onChange={handleChangeUid}
            required
            inputProps={{ maxLength: 20, pattern: '^[a-z0-9_-]+$' }}
          />
          <TextField
            label="流入元名"
            helperText="流入元名を設定します。（例: ホームページ）"
            value={form.name}
            fullWidth
            required
            onChange={handleChangeName}
          />
        </Paper>
        <Grid container style={{ marginTop: '20px' }}>
          <Grid item xs={6}>
            {inflowSourceId ? (
              <Button
                type="button"
                variant="contained"
                color="default"
                onClick={handleClickDelete}
              >
                削除する
              </Button>
            ) : null}
          </Grid>
          <Grid
            item
            container
            xs={6}
            alignItems="center"
            justifyContent="flex-end"
          >
            {buildRightNavigationButtons()}
          </Grid>
        </Grid>
      </form>
    );
  };

  const addOrEdit = inflowSourceId === undefined ? '追加' : '編集';
  const pageTitle = `流入元の${addOrEdit}`;

  return (
    <Root>
      <Head
        title={`ワークスペース設定 - ${inflowSource?.name || '流入元'}の編集`}
      />
      <Main>
        <WorkspacePageLayout
          workspaceUid={workspaceUid}
          current="inflow-source"
          breadcrumbs={
            <Breadcrumbs aria-label="breadcrumb">
              <BreadcrumbLinkItem to={`/`}>ホーム</BreadcrumbLinkItem>
              <BreadcrumbWorkspaceItem workspaceUid={workspaceUid} />
              <BreadcrumbLinkItem
                to={`/a/${workspaceUid}/settings/inflow-sources`}
              >
                流入元設定
              </BreadcrumbLinkItem>
              <BreadcrumbTextItem>{pageTitle}</BreadcrumbTextItem>
            </Breadcrumbs>
          }
          helpId={helps.workspace.setting.inflowSource}
        >
          <PageTitleAndDescription
            title="ワークスペース"
            subTitle={pageTitle}
            description={`流入元を判定するための設定の${addOrEdit}を行います。`}
            themeColor={globalColors.workspace}
          />
          {buildContents()}
        </WorkspacePageLayout>
      </Main>
      <FullscreenLoading open={openBackdrop} />
    </Root>
  );
}
