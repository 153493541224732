import { Breadcrumbs, Grid } from '@material-ui/core';
import useReactRouter from 'use-react-router';
import useWorkspaceCourses from '../../../api/use-workspace-courses';
import {
  BreadcrumbLinkItem,
  BreadcrumbTextItem,
  BreadcrumbWorkspaceItem,
} from '../../../components/breadcrumb/BreadcrumbItem';
import { WorkspacePageLayout } from '../../../components/layouts/WorkspacePageLayout';
import LinkButton from '../../../components/LinkButton';
import { PageTitleAndDescription } from '../../../components/PageTitleAndDescription';
import { Head, Main, Root } from '../../../components/Shared';
import Spinner from '../../../components/Spinner';
import { globalColors } from '../../../styles/globalColors';
import { helps } from '../../../utils/helps';
import WorkspaceCoursesTable from './WorkspaceCoursesTable';

interface PageParams {
  workspaceUid: string;
}

export default function WorkspaceCourseSettingPage() {
  const { match } = useReactRouter<PageParams>();
  const { workspaceUid } = match.params;
  const { courses, isLoadingCourses, reloadCourses } =
    useWorkspaceCourses(workspaceUid);

  const buildContent = () => (
    <>
      <Grid container alignItems="center">
        <Grid item xs={6}>
          <h3>コース</h3>
        </Grid>
        <Grid
          item
          container
          xs={6}
          alignItems="center"
          justifyContent="flex-end"
        >
          <LinkButton
            to={`/a/${workspaceUid}/settings/courses/new`}
            label="コースを追加"
          />
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item container xs={12} alignItems="center">
          {isLoadingCourses ? (
            <Spinner loading={true} />
          ) : (
            <WorkspaceCoursesTable
              workspaceUid={workspaceUid}
              courses={courses}
              reloadCourses={reloadCourses}
            />
          )}
        </Grid>
      </Grid>
    </>
  );

  const pageTitle = 'コース設定';

  return (
    <Root>
      <Head title={`ワークスペース設定 - コース設定`} />
      <Main>
        <WorkspacePageLayout
          workspaceUid={workspaceUid}
          current="course"
          breadcrumbs={
            <Breadcrumbs aria-label="breadcrumb">
              <BreadcrumbLinkItem to={`/`}>ホーム</BreadcrumbLinkItem>
              <BreadcrumbWorkspaceItem workspaceUid={workspaceUid} />
              <BreadcrumbTextItem>{pageTitle}</BreadcrumbTextItem>
            </Breadcrumbs>
          }
          helpId={helps.workspace.setting.course}
        >
          <PageTitleAndDescription
            title="ワークスペース"
            subTitle={pageTitle}
            description="ワークスペースで使用するコースを設定を行います。"
            themeColor={globalColors.workspace}
          />
          {buildContent()}
        </WorkspacePageLayout>
      </Main>
    </Root>
  );
}
