import { AxiosResponse } from 'axios';
import { MergeCustomersRequestData } from '../../../@interfaces/crm/api';
import { API_END_POINT } from '../../../api/api';
import { useMutation } from '../../common/api/use-mutation';

export const useMergeCustomer = (): ((
  workspaceUid: string,
  data: MergeCustomersRequestData
) => Promise<AxiosResponse<''>>) => {
  const { request } = useMutation<'', MergeCustomersRequestData>();
  return async (workspaceUid, data) => {
    return request(
      'POST',
      `${API_END_POINT}/app/ws/${workspaceUid}/merge-customers`,
      {},
      data
    );
  };
};
