import { Grid, IconButton, MenuItem, TextField } from '@material-ui/core';
import {
  ResourceConstantSetting,
  ShopCourseSetting,
} from '../../../../@interfaces/course-slot-setting';
import { ResourceGroupWithMember } from '../../../../@interfaces/resource/resource-group';
import { FormSetting } from '../../../../core/types/reservation-form-types';
import {
  ResourceConstant,
  ResourceConstantType,
  resourceConstantTypes,
} from '../../../../core/types/reservation-resource-types';
import { createDefaultResourceConstant } from '../../models/resource-constant';
import { UpdateResourceConstantSettingHandler } from '../../types';
import { PararelResourceConstantEditor } from './PararelResourceConstantEditor';
import { SerialResourceConstantEditor } from './SerialResourceConstantEditor';
import DeleteIcon from '@material-ui/icons/Delete';

type ResourceConstantEditorProps = {
  constant: ResourceConstant;
  resourceConstantSetting: ResourceConstantSetting;
  shopCourseSetting: ShopCourseSetting;
  formSetting: FormSetting;
  resourceGroups: ResourceGroupWithMember[];
  onUpdateResourceConstantSetting: UpdateResourceConstantSettingHandler;
  onDeleteConstrant: (constant: ResourceConstant) => void;
};

type ResourceConstantTypeOption = {
  label: string;
};

const resourceConstantTypeOptions: {
  [resourceConstantType in ResourceConstantType]: ResourceConstantTypeOption;
} = {
  pararel: {
    label: 'リソースを所要時間全てに割当',
  },
  serial: {
    label: 'リソースを順番に指定された時間だけ割り当て',
  },
};

export const ResourceConstantEditor: React.VFC<ResourceConstantEditorProps> = (
  props
) => {
  const {
    constant,
    resourceConstantSetting,
    shopCourseSetting,
    formSetting,
    resourceGroups,
    onUpdateResourceConstantSetting,
    onDeleteConstrant,
  } = props;
  const index = resourceConstantSetting.constants.indexOf(constant);

  const handleUpdateConstant = () => {
    onUpdateResourceConstantSetting(resourceConstantSetting);
  };

  const buildConstantEditor = (constant: ResourceConstant) => {
    switch (constant.type) {
      case 'pararel': {
        return (
          <PararelResourceConstantEditor
            constant={constant}
            resourceGroups={resourceGroups}
            onUpdate={handleUpdateConstant}
          />
        );
      }
      case 'serial': {
        return (
          <SerialResourceConstantEditor
            constant={constant}
            resourceGroups={resourceGroups}
            shopCourseSetting={shopCourseSetting}
            formSetting={formSetting}
            onUpdate={handleUpdateConstant}
          />
        );
      }
      default: {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const _exhaustiveCheck: never = constant;
      }
    }
  };

  const handleChangeConstantType = (
    event: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    const type = event.target.value as ResourceConstantType;
    // 変更時は新規にターゲットを作成する
    const newConsntant = createDefaultResourceConstant(type);
    resourceConstantSetting.constants[index] = newConsntant;
    onUpdateResourceConstantSetting(resourceConstantSetting);
  };

  const handleDeleteConstrant = () => {
    onDeleteConstrant(constant);
  };

  return (
    <>
      <Grid container item>
        <Grid item container alignItems="center">
          <h4>制約{index + 1}</h4>
          <IconButton
            onClick={handleDeleteConstrant}
            size="small"
            style={{ height: '30px' }}
          >
            <DeleteIcon />
          </IconButton>
        </Grid>
      </Grid>
      <Grid container item>
        <TextField
          select
          label="制約の種類"
          value={constant.type}
          onChange={handleChangeConstantType}
          fullWidth
          required
        >
          {resourceConstantTypes.map((constantType) => (
            <MenuItem key={constantType} value={constantType}>
              {resourceConstantTypeOptions[constantType].label}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid container item>
        {buildConstantEditor(constant)}
      </Grid>
    </>
  );
};
