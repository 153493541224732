/** @jsxImportSource @emotion/react */

import { FieldValues, RegisterOptions, UseFormMethods } from 'react-hook-form';
import {
  defaultFieldCondition,
  Field,
  FieldResponse,
  FieldResponseValue,
} from '../../../../core/types/reservation-form-types';

export const fieldRequired = (field: Field): boolean => {
  return (field.condition || defaultFieldCondition).internal.required;
};

export const buildFieldValidation = (
  field: Field,
  readonly: boolean
): RegisterOptions => {
  if (readonly) {
    return {};
  }
  const required = fieldRequired(field);
  return { required };
};

export const buildPlaceholder = (field: Field): string => {
  const required = fieldRequired(field);
  return `${field.name}${required ? ' *' : ''}`;
};

export type FormFieldProps = {
  fieldResponse: FieldResponse;
  onChangeValue?: (field: Field, newValues: FieldResponseValue[]) => void;
  validationContext: UseFormMethods<FieldValues>;
};

export const handleEnterDisable = (e: React.KeyboardEvent) => {
  if (e.key === 'Enter') {
    e.preventDefault();
  }
};
