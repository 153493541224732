/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { Card, CardContent } from '@material-ui/core';
import dayjs from 'dayjs';
import {
  FaLongArrowAltDown,
  FaLongArrowAltRight,
  FaLongArrowAltUp,
} from 'react-icons/fa';
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { GetReservationTotalResponse } from '../../../@interfaces/insight/api';
import {
  AggregationUnit,
  AllAggregation,
  TargetDateType,
} from '../../../@interfaces/insight/backend';
import useInsightReservationTotal from '../api/use-insight-reservation-total';
import {
  calcGrowthPercent,
  dateRanges,
  toDisplayDate,
} from '../utils/insight-utils';
import { CardSpinner } from './CardSpinner';

type InsightSummaryProps = {
  shopId: string;
  primaryStartDate: dayjs.Dayjs;
  primaryEndDate: dayjs.Dayjs;
  comparisonStartDate: dayjs.Dayjs;
  comparisonEndDate: dayjs.Dayjs;
  aggregationUnit: AggregationUnit;
  targetDateType: TargetDateType;
};

// input data
// {
//   primaryResults: [
//     { date: '2024-05-01', total: 4 },
//     { date: '2024-05-02', total: 3 },
//   ],
//   comparisonResults: [
//     { date: '2024-05-01', total: 5 },
//     { date: '2024-05-02', total: 2 },
//   ],
// }

// output data
// [
//   { primaryDate: '2024-05-01', comparisonDate: '2024-04-01', primary: 5, comparison: 4 },
//   { primaryDate: '2024-05-02', comparisonDate: '2024-04-02', primary: 2, comparison: 3 },
// ]

type DisplayData = {
  primaryDate: string;
  comparisonDate: string;
  primary: number;
  comparison: number;
};

export const toDisplayData = (
  primaryTargetDates: string[],
  comparisonTargetDates: string[],
  primaryResults: AllAggregation[],
  comparisonResults: AllAggregation[]
): DisplayData[] => {
  const results = [];
  for (let i = 0; i < primaryTargetDates.length; i++) {
    const primaryDate = primaryTargetDates[i];
    const comparisonDate = comparisonTargetDates[i];

    results.push({
      primaryDate,
      comparisonDate,
      primary: primaryResults.find((r) => r.date === primaryDate)?.total ?? 0,
      comparison:
        comparisonResults.find((r) => r.date === comparisonDate)?.total ?? 0,
    });
  }

  return results;
};

export const InsightSummary = ({
  shopId,
  primaryStartDate,
  primaryEndDate,
  comparisonStartDate,
  comparisonEndDate,
  aggregationUnit,
  targetDateType,
}: InsightSummaryProps): JSX.Element => {
  const { results, isLoading } = useInsightReservationTotal(
    shopId,
    primaryStartDate.format('YYYY-MM-DD'),
    primaryEndDate.format('YYYY-MM-DD'),
    comparisonStartDate.format('YYYY-MM-DD'),
    comparisonEndDate.format('YYYY-MM-DD'),
    aggregationUnit,
    targetDateType
  );

  const buildContent = () => {
    if (results === undefined || isLoading) {
      return <CardSpinner />;
    }

    return (
      <>
        <div
          css={css`
            display: flex;
            align-items: center;
            flex-wrap: wrap;
          `}
        >
          <p
            css={css`
              font-size: 60px;
              line-height: 1;
              margin: 0;
            `}
          >
            {results.primary.total.toLocaleString()}件
          </p>
          <CalculatedPercent results={results} />
        </div>
        <ResponsiveContainer
          css={css`
            margin-top: 1.5em;
          `}
          width="100%"
          height={300}
        >
          <LineChart
            data={toDisplayData(
              dateRanges(
                primaryStartDate,
                primaryEndDate,
                aggregationUnit === 'daily' ? 'day' : 'month'
              ),
              dateRanges(
                comparisonStartDate,
                comparisonEndDate,
                aggregationUnit === 'daily' ? 'day' : 'month'
              ),
              // なぜか警告が出るので抑制 (results が二回登場しているせいで誤作動している？)
              results.primary.results, // eslint-disable-line @typescript-eslint/no-unsafe-argument
              results.comparison.results // eslint-disable-line @typescript-eslint/no-unsafe-argument
            )}
            margin={{
              top: 5,
              right: 45,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="primaryDate"
              tickFormatter={(tick) =>
                toDisplayDate(tick as string, aggregationUnit)
              }
            />
            <YAxis />
            <Tooltip
              formatter={(value, name, item) => {
                if (name === 'primary') {
                  return [value, item.payload.primaryDate];
                }
                if (name === 'comparison') {
                  return [value, item.payload.comparisonDate];
                }
                return [value, name];
              }}
            />
            <Legend
              formatter={(value) => {
                if (value === 'primary') return '選択中の期間';
                if (value === 'comparison') return '前の期間';
                return value;
              }}
            />
            <Line
              dataKey="primary"
              label="選択中の期間"
              stroke="#8884d8"
              strokeWidth={2}
            />
            <Line
              dataKey="comparison"
              label="前の期間"
              activeDot={{ r: 8 }}
              strokeDasharray="2 2"
              stroke="#82ca9d"
              strokeWidth={2}
            />
          </LineChart>
        </ResponsiveContainer>
      </>
    );
  };

  return (
    <>
      <Card
        css={css`
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        `}
      >
        <CardContent>
          <h3
            css={css`
              font-size: 24px;
              font-weight: normal;
              margin: 0.5em 0;
              padding: 0;
            `}
          >
            予約数
          </h3>
          {buildContent()}
        </CardContent>
      </Card>
    </>
  );
};

const CalculatedPercent = ({
  results,
}: {
  results: GetReservationTotalResponse;
}) => {
  const { value, displayValue } = calcGrowthPercent(
    results.primary.total,
    results.comparison.total
  );
  const comparisonTotalExists = results.comparison.total !== 0;

  const buildContent = () => {
    if (value === 0) {
      return (
        <span
          css={css`
            display: flex;
          `}
        >
          <FaLongArrowAltRight
            css={css`
              color: grey;
              margin-right: 5px;
            `}
          />
          {displayValue}
        </span>
      );
    }

    if (value > 0) {
      return (
        <span
          css={css`
            display: flex;
          `}
        >
          <FaLongArrowAltUp
            css={css`
              color: green;
            `}
          />
          {displayValue}
        </span>
      );
    }
    return (
      <span
        css={css`
          display: flex;
        `}
      >
        <FaLongArrowAltDown
          css={css`
            color: red;
          `}
        />
        {displayValue}
      </span>
    );
  };

  return (
    <p
      css={css`
        margin: 0.5em;
        line-height: 1;
        font-size: 40px;
        background-color: ${value === 0 || !comparisonTotalExists
          ? '#e1e1e1'
          : value > 0
          ? '#e7f4ee'
          : '#F4E7E7'};
        color: ${value === 0 || !comparisonTotalExists
          ? '#9f9f9f'
          : value > 0
          ? '#0d894f'
          : '#890D0D'};
        border-radius: 40px;
        padding: 5px 10px;
        visibility: ${!comparisonTotalExists ? 'hidden' : 'visible'};
      `}
    >
      {buildContent()}
    </p>
  );
};
