/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { Breadcrumbs, Grid } from '@material-ui/core';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { useHistory } from 'react-router';
import useReactRouter from 'use-react-router';
import useShop from '../../../api/use-shop';
import useWorkspaceSetting from '../../../api/use-workspace-setting';
import {
  BreadcrumbLinkItem,
  BreadcrumbShopItem,
  BreadcrumbTextItem,
  BreadcrumbWorkspaceItem,
} from '../../../components/breadcrumb/BreadcrumbItem';
import { ShopPageLayout } from '../../../components/layouts/ShopPageLayout';
import { PageTitleAndDescription } from '../../../components/PageTitleAndDescription';
import { Head, Main, Root } from '../../../components/Shared';
import Spinner from '../../../components/Spinner';
import { MD_BREAKPOINT, SM_BREAKPOINT } from '../../../hooks/use-size-type';
import { globalColors } from '../../../styles/globalColors';
import { helps } from '../../../utils/helps';
import { useCreateShopCustomer } from '../api/useCreateShopCustomer';
import { useCustomer } from '../api/useCustomer';
import { useDeleteCustomer } from '../api/useDeleteCustomer';
import { useUpdateCustomer } from '../api/useUpdateCustomer';
import {
  CustomerEditForm,
  CustomerEditFormSaveHandler,
} from '../components/CustomerSelector/CustomerEditForm';

const styles = {
  gridWrapper: css`
    margin-top: 20px;
    @media screen and (max-width: ${MD_BREAKPOINT}px) {
      margin-top: 0;
    }
  `,
  sidebarWrapper: css`
    max-width: 400px;
    @media screen and (max-width: ${MD_BREAKPOINT}px) {
      max-width: none;
    }
  `,
  buttonsWrapper: css`
    @media screen and (max-width: ${SM_BREAKPOINT}px) {
      margin-top: 16px;
    }
  `,
};

type PageParams = {
  workspaceUid: string;
  shopId: string;
  customerId: string | undefined;
};

export default function CustomerEditPage(props: any) {
  const { match } = useReactRouter<PageParams>();
  const { workspaceUid, shopId, customerId } = match.params;
  const { shop, isLoadingShop } = useShop(shopId);
  const { workspaceSetting, isLoadingWorkspaceSetting } =
    useWorkspaceSetting(workspaceUid);

  const buildContents = () => {
    if (isLoadingShop || isLoadingWorkspaceSetting) {
      return <Spinner loading={true} />;
    }
    if (!shop) {
      return <div>店舗情報が取得できませんでした。</div>;
    }
    if (!(workspaceSetting?.enableCrm || false)) {
      return <div>顧客管理が有効ではありません。</div>;
    }
    const editForm = customerId ? (
      <CustomerEditContent
        workspaceUid={workspaceUid}
        shopId={shopId}
        customerId={customerId}
      />
    ) : (
      <CustomerNewContent workspaceUid={workspaceUid} shopId={shopId} />
    );
    return (
      <Grid container spacing={3} css={styles.gridWrapper}>
        <Grid item xs={12}>
          {editForm}
        </Grid>
      </Grid>
    );
  };

  const pageTitle = customerId ? '顧客の編集' : '顧客の登録';
  const description = customerId
    ? '登録済みの顧客の情報の編集を行います。'
    : '管理する顧客の追加を行います。';

  return (
    <Root>
      <Head title={`${shop?.name || ''}`} />
      <Main>
        <ShopPageLayout
          workspaceUid={workspaceUid}
          shopId={shopId}
          current="customers"
          helpId={helps.customer.edit}
          breadcrumbs={
            <Breadcrumbs aria-label="breadcrumb">
              <BreadcrumbLinkItem to={`/`}>ホーム</BreadcrumbLinkItem>
              <BreadcrumbWorkspaceItem workspaceUid={workspaceUid} />
              <BreadcrumbShopItem workspaceUid={workspaceUid} shop={shop} />
              <BreadcrumbLinkItem
                to={`/a/${workspaceUid}/shops/${shopId}/customers`}
              >
                顧客管理
              </BreadcrumbLinkItem>
              <BreadcrumbTextItem>{pageTitle}</BreadcrumbTextItem>
            </Breadcrumbs>
          }
        >
          <PageTitleAndDescription
            title="店舗"
            subTitle={pageTitle}
            description={description}
            themeColor={globalColors.shop}
          />
          {buildContents()}
        </ShopPageLayout>
      </Main>
    </Root>
  );
}

const goCustomerList = (history: any, workspaceUid: string, shopId: string) => {
  history.push(`/a/${workspaceUid}/shops/${shopId}/customers`);
};

type CustomerEditContentProps = {
  workspaceUid: string;
  shopId: string;
  customerId: string;
};

/**
 * 既存の顧客を編集
 */
const CustomerEditContent = (props: CustomerEditContentProps) => {
  const { workspaceUid, shopId, customerId } = props;
  const history = useHistory();
  const updateCustomer = useUpdateCustomer();
  const { data: customer, isLoading } = useCustomer(
    workspaceUid,
    parseInt(customerId)
  );
  const deleteCustomer = useDeleteCustomer();

  const handleSave: CustomerEditFormSaveHandler = (data, mode) => {
    void updateCustomer(workspaceUid, parseInt(customerId), data).then(() => {
      // 保存時はmodeは無視
      goCustomerList(history, workspaceUid, shopId);
    });
  };

  const handleCancel = () => {
    goCustomerList(history, workspaceUid, shopId);
  };

  if (!isLoading && !customer) {
    return <div>顧客情報が取得できませんでした。</div>;
  }

  if (isLoading) {
    return <Spinner loading={true} />;
  }

  const handleDeleteCustomer = (customerId: number) => {
    if (!window.confirm('顧客を削除します。よろしいですか？')) {
      return;
    }
    void deleteCustomer(workspaceUid, customerId).then(() => {
      alert('顧客を削除しました');
      goCustomerList(history, workspaceUid, shopId);
    });
  };

  return (
    <CustomerEditForm
      customer={customer}
      shopId={parseInt(shopId)}
      onCancel={handleCancel}
      onSave={handleSave}
      onDelete={handleDeleteCustomer}
    />
  );
};

type CustomerNewContentProps = {
  workspaceUid: string;
  shopId: string;
};

/**
 * 顧客の新規作成時
 */
const CustomerNewContent = (props: CustomerNewContentProps) => {
  const { workspaceUid, shopId } = props;

  const history = useHistory();
  const createCustomer = useCreateShopCustomer();

  const handleSave: CustomerEditFormSaveHandler = (data, mode) => {
    void createCustomer(shopId, data).then(() => {
      if (mode === 'single') {
        goCustomerList(history, workspaceUid, shopId);
      } else if (mode === 'continuous') {
        // 連続登録の場合は画面遷移なしなので何もしない
        // フォームのクリアはCustomerEditForm側で行う
      }
    });
  };

  const handleCancel = () => {
    history.push(`/a/${workspaceUid}/shops/${shopId}/customers`);
  };

  return (
    <CustomerEditForm
      customer={undefined}
      shopId={parseInt(shopId)}
      onCancel={handleCancel}
      onSave={handleSave}
    />
  );
};
