/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import React from 'react';

type LineInfoProps = {
  lineUser?: {
    lineUserId?: string;
    lineDisplayName?: string;
  };
};
export const LineInfo = (props: LineInfoProps): JSX.Element | null => {
  const { lineUser } = props;
  if (!lineUser?.lineUserId) {
    return null;
  }
  return (
    <>
      <img
        src="/img/line.svg"
        css={css`
          width: 16px;
          margin-left: 5px;
          vertical-align: middle;
        `}
        alt=""
      />
      <span
        css={css`
          color: #777;
          margin-left: 3px;
        `}
      >
        {lineUser.lineDisplayName}
      </span>
    </>
  );
};
