import axios, { AxiosResponse } from 'axios';
import { useContext, useEffect, useState } from 'react';
import { ResponseBody } from '../../../frontend-api/src/handlers/course/shop-course-slot-setting-handler';
import { CourseSlotSetting } from '../@interfaces/course-slot-setting';
import { Store } from '../context/GlobalStore';
import { API_END_POINT } from './api';

export default function useCourseSlotSetting(
  shopId: string,
  courseId: string | undefined
) {
  const { globalState, setGlobalState } = useContext(Store);
  const [courseSlotSetting, setCourseSlotSetting] =
    useState<CourseSlotSetting>();
  const [loading, setLoading] = useState(false);
  const reload = () => {
    if (!globalState.session?.idToken || !courseId) {
      return;
    }
    axios
      .get(
        `${API_END_POINT}/app/shops/${shopId}/courses/${courseId}/course-slot-setting`,
        {
          headers: {
            Authorization: globalState.session?.idToken,
          },
        }
      )
      .then((response: AxiosResponse<ResponseBody>) => {
        setCourseSlotSetting(response.data.courseSlotSetting);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    reload();
  }, [shopId, courseId, globalState.session?.idToken]);
  return {
    courseSlotSetting,
    isLoadingCourseSlotSetting: loading,
  };
}
