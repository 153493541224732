import axios, { AxiosResponse } from 'axios';
import { useContext, useEffect, useState } from 'react';
import { ResponseBody } from '../../../frontend-api/src/handlers/course/shop-courses-handler';
import { ShopCourse } from '../../../frontend-api/src/interfaces/course';
import { Course } from '../@interfaces/course';
import { Store } from '../context/GlobalStore';
import { API_END_POINT } from './api';

type CacheKey = `${string}`;

const createCacheKey = (workspaceUid: string): CacheKey => {
  return `${workspaceUid}` as CacheKey;
};
const cache: { [cacheKey: string]: ShopCourse[] } = {};

export default function useShopCourses(
  shopId: string,
  includingDeleted = false
) {
  const { globalState, setGlobalState } = useContext(Store);
  const cacheKey = createCacheKey(shopId);
  const [courses, setCourses] = useState(cache[cacheKey] || ([] as Course[]));
  const [loading, setLoading] = useState(cache[cacheKey] == undefined);

  const reload = () => {
    if (!globalState.session?.idToken) {
      return;
    }
    const params = {
      includingDeleted,
    };
    axios
      .get(`${API_END_POINT}/app/shops/${shopId}/courses`, {
        params,
        headers: {
          Authorization: globalState.session?.idToken,
        },
      })
      .then((response: AxiosResponse<ResponseBody>) => {
        setCourses(response.data.courses);
        cache[cacheKey] = response.data.courses;
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    reload();
  }, [shopId, includingDeleted, globalState.session?.idToken]);

  return {
    shopCourses: courses,
    isLoadingShopCourses: loading,
    reloadShopCourses: reload,
  };
}
