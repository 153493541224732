import axios, { AxiosResponse } from 'axios';
import { useContext, useEffect, useState } from 'react';
import { ResponseBody } from '../../../frontend-api/src/handlers/label/label-handler';
import { Label } from '../@interfaces/label';
import { Store } from '../context/GlobalStore';
import { API_END_POINT } from './api';

const cache: { [labelId: string]: Label } = {};

export default function useLabel(
  workspaceUid: string,
  labelId: string | undefined
) {
  const { globalState, setGlobalState } = useContext(Store);
  const [label, setLabel] = useState<Label>(cache[labelId || '']);
  const [loading, setLoading] = useState(cache[labelId || ''] == undefined);
  const reload = () => {
    if (!globalState.session?.idToken || !labelId) {
      return;
    }
    axios
      .get(`${API_END_POINT}/app/ws/${workspaceUid}/labels/${labelId}`, {
        headers: {
          Authorization: globalState.session?.idToken,
        },
      })
      .then((response: AxiosResponse<ResponseBody>) => {
        setLabel(response.data.label);
        cache[labelId] = response.data.label;
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    reload();
  }, [workspaceUid, labelId, globalState.session?.idToken]);
  return {
    label,
    isLoadingLabel: loading,
  };
}
