import { useCallback, useContext, useState } from 'react';
import axios, { AxiosError } from 'axios';
import { API_END_POINT } from './api';
import { Store } from '../context/GlobalStore';

export default function useCreateChangeEmailRequest(): {
  createChangeEmailRequest: (newMailAddress: string) => Promise<void>;
  loading: boolean;
} {
  const { globalState } = useContext(Store);
  const [loading, setLoading] = useState(false);

  const createChangeEmailRequest = useCallback(
    async (newMailAddress: string): Promise<void> => {
      if (!globalState.session?.idToken) {
        return;
      }
      setLoading(true);
      try {
        await axios.post(
          `${API_END_POINT}/app/account/me/change-email-request`,
          { email: newMailAddress },
          {
            headers: {
              Authorization: globalState.session?.idToken,
              'Content-Type': 'application/json',
            },
          }
        );
      } catch (e) {
        throw Error(
          (e as AxiosError).response?.data.message ??
            'システムで問題が発生しています。しばらく経ってから再度お試しください。'
        );
      } finally {
        setLoading(false);
      }
    },
    [globalState.session?.idToken]
  );
  return {
    createChangeEmailRequest,
    loading,
  };
}
