/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import {
  Button,
  Grid,
  MenuItem,
  Paper,
  Select,
  Typography,
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import useReactRouter from 'use-react-router';
import { CustomDomain } from '../../../@interfaces/custom-domain/api';
import { CheckStatusType } from '../../../@interfaces/custom-domain/base';
import { Shop } from '../../../@interfaces/shop';
import useShop from '../../../api/use-shop';
import Spinner from '../../../components/Spinner';
import {
  useAttachCustomDomain,
  useDetachCustomDomain,
} from '../api/customDomainShop';
import { useCustomDomainAttachedShop } from '../api/useCustomDomainAttachedShop';
import { useCustomDomains } from '../api/useCustomDomains';

interface PageParams {
  workspaceUid: string;
  shopId: string;
}

export const ShopCustomDomainSettingContent = () => {
  const { match } = useReactRouter<PageParams>();
  const { workspaceUid, shopId } = match.params;
  const { shop, isLoadingShop } = useShop(shopId);
  const [openChangeMenu, setOpenChangeMenu] = useState(false);
  const {
    data: customDomain,
    isLoading: isLoadingCustomDomain,
    reload,
  } = useCustomDomainAttachedShop(parseInt(shopId));
  const settingCustomDomain: string | null = customDomain?.fqdn ?? null;
  const { data: customDomains, isLoading: isLoadingCustomDomains } =
    useCustomDomains(workspaceUid);
  const { enqueueSnackbar } = useSnackbar();

  const usableCustomDomains = customDomains.filter(
    (c) => c.checkStatus === CheckStatusType.OK
  );
  return (
    <Paper
      css={css`
        width: 100%;
        padding: 16px;
      `}
    >
      {isLoadingShop || isLoadingCustomDomain ? (
        <Spinner loading={isLoadingShop} />
      ) : openChangeMenu ? (
        isLoadingCustomDomains ? (
          <Spinner loading={isLoadingCustomDomains} />
        ) : (
          <SettingMenu
            shop={shop}
            usableCustomDomains={usableCustomDomains}
            settingDomain={settingCustomDomain}
            onSubmit={() => {
              enqueueSnackbar('独自ドメインを設定しました。');
              reload();
            }}
            onCloseDomainChangeMenu={() => {
              setOpenChangeMenu(false);
            }}
          />
        )
      ) : settingCustomDomain === null ? (
        <NotSetting
          onOpenDomainChangeMenu={() => {
            setOpenChangeMenu(true);
          }}
        />
      ) : (
        <Setting
          shop={shop}
          settingDomain={settingCustomDomain}
          onDetachCustomDomain={() => {
            enqueueSnackbar('独自ドメイン設定を解除しました。');
            reload();
          }}
          onOpenDomainChangeMenu={() => {
            setOpenChangeMenu(true);
          }}
        />
      )}
    </Paper>
  );
};

const Setting = ({
  shop,
  settingDomain,
  onDetachCustomDomain,
  onOpenDomainChangeMenu,
}: {
  shop: Shop;
  settingDomain: string;
  onDetachCustomDomain: () => void;
  onOpenDomainChangeMenu: () => void;
}) => {
  const detachCustomDomain = useDetachCustomDomain();
  const handleDetachDomain = () => {
    if (!window.confirm('独自ドメインの利用をやめてよろしいですか')) {
      return;
    }
    detachCustomDomain(shop.id)
      .then(() => {
        onDetachCustomDomain();
      })
      .catch((e) => {
        console.error(e);
      });
  };

  return (
    <>
      <Grid container>
        <Typography>設定中の独自ドメイン:</Typography>
        <Typography
          css={css`
            margin-left: 20px;
            font-weight: bold;
          `}
        >
          {settingDomain}
        </Typography>
      </Grid>
      <Grid
        container
        css={css`
          margin-top: 20px;
          gap: 20px;
        `}
      >
        <Button
          color="primary"
          variant="contained"
          onClick={onOpenDomainChangeMenu}
        >
          ドメインを変更する
        </Button>
        <Button
          color="secondary"
          variant="contained"
          onClick={handleDetachDomain}
        >
          ドメインの利用をやめる
        </Button>
      </Grid>
    </>
  );
};

const NotSetting = ({
  onOpenDomainChangeMenu,
}: {
  onOpenDomainChangeMenu: () => void;
}) => {
  return (
    <Grid container>
      <Button
        color="primary"
        variant="contained"
        onClick={onOpenDomainChangeMenu}
      >
        独自ドメイン利用を開始する
      </Button>
    </Grid>
  );
};

const SettingMenu = ({
  shop,
  settingDomain,
  usableCustomDomains,
  onSubmit,
  onCloseDomainChangeMenu,
}: {
  shop: Shop;
  settingDomain: string | null;
  usableCustomDomains: CustomDomain[];
  onSubmit: () => void;
  onCloseDomainChangeMenu: () => void;
}) => {
  const [settingId, setSettingId] = useState<null | number>(
    usableCustomDomains.find((c) => c.fqdn === settingDomain)?.id ?? null
  );

  const attachCustomDomain = useAttachCustomDomain();
  return (
    <>
      <Grid>
        <Select
          value={settingId}
          onChange={(e) => {
            setSettingId(e.target.value as number);
          }}
        >
          {usableCustomDomains.map((c) => (
            <MenuItem key={c.id} value={c.id}>
              {c.fqdn}
            </MenuItem>
          ))}
        </Select>
      </Grid>
      <Grid
        container
        css={css`
          margin-top: 20px;
          gap: 20px;
        `}
      >
        <Button
          color="primary"
          variant="contained"
          onClick={() => {
            if (!settingId) {
              return;
            }
            attachCustomDomain(shop.id, settingId)
              .then(() => {
                onSubmit();
              })
              .catch((e) => {
                console.error(e);
              })
              .finally(() => {
                onCloseDomainChangeMenu();
              });
          }}
          disabled={settingId === null}
        >
          確定
        </Button>
        <Button
          color="inherit"
          variant="contained"
          onClick={onCloseDomainChangeMenu}
        >
          設定をやめる
        </Button>
      </Grid>
    </>
  );
};
