/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import {
  Box,
  Button,
  Checkbox,
  Grid,
  Paper,
  TextField,
} from '@material-ui/core';
import { AccessTime } from '@material-ui/icons';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import ListAltIcon from '@material-ui/icons/ListAlt';
import { Alert, AlertTitle, Autocomplete } from '@material-ui/lab';
import {
  FormEvent,
  forwardRef,
  Fragment,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useKana } from 'react-use-kana';
import useReactRouter from 'use-react-router';
import { UpdateCustomerRequestData } from '../../../../@interfaces/crm/api';
import { RichCustomerInfo } from '../../../../@interfaces/crm/backend';
import { Shop } from '../../../../@interfaces/shop';
import useShops from '../../../../api/use-shops';
import { useSizeType } from '../../../../hooks/use-size-type';
import { formatPhoneNumber } from '../../../../utils/phonenumber-utils';
import {
  isKana,
  kanaHalfToFull,
  kanaToHiragana,
  romanToKana,
} from '../../../../utils/string';
import { getSeparateShopIds } from '../../utils';
import { HistoryDrawer } from '../HistoryDrawer';

const styles = {
  rightInfo: css`
    font-size: 14px;
    color: #777;
    margin-left: 10px;
  `,
};

export type CustomerEditFormRef = {
  // 外部からsubmitFormを呼び出すための参照
  // ダイアログ等でフォームを開いた状態でsubmitFormを呼び出す際に使用する
  submitForm: () => void;
};

type CustomerEditFormProps = {
  shopId: number;
  customer: RichCustomerInfo | undefined;
  defaultNewCustomer?: {
    name?: string;
    tel?: string;
    email?: string;
  };
  onCancel?: () => void;
  onSave: CustomerEditFormSaveHandler;
  hideContinuousButton?: boolean;
  hideButtonButtons?: boolean;
  onDelete?: (customerId: number) => void;
  isShowInDialog?: boolean;
};

export type CustomerEditFormSaveHandler = (
  form: UpdateCustomerRequestData,
  mode: RegistrationMode
) => void;

type Form = {
  name: string;
  nameKana: string;
  tel: string;
  email: string;
  postalCode: string;
  address: string;
  note: string;
  selectedShopIds: number[];
};

const defaultForm: Form = {
  name: '',
  nameKana: '',
  tel: '',
  email: '',
  postalCode: '',
  address: '',
  note: '',
  selectedShopIds: [],
};

type FormKey =
  | 'name'
  | 'nameKana'
  | 'tel'
  | 'email'
  | 'postalCode'
  | 'address'
  | 'note'
  | 'selectedShopIds';

// 登録時のモード
// none: 登録前状態
// single: １件登録して処理を終える
// continuous: 連続登録して編集画面のまま
//
// シーケンス
// 1. none
// 2. single or continuous
// 3. reloading
// 4. none
type RegistrationMode = 'none' | 'single' | 'continuous';

export const CustomerEditForm = forwardRef<
  CustomerEditFormRef,
  CustomerEditFormProps
>((props, ref): JSX.Element => {
  const {
    shopId,
    customer,
    defaultNewCustomer,
    onCancel,
    onSave,
    hideContinuousButton,
    hideButtonButtons,
    onDelete,
    isShowInDialog,
  } = props;

  useImperativeHandle(ref, () => ({
    submitForm: () => {
      if (formRef.current?.checkValidity()) {
        submit();
      } else {
        formRef.current?.reportValidity();
      }
    },
  }));

  const formRef = useRef<HTMLFormElement>(null);
  const [form, setForm] = useState(defaultForm);
  const [errorKey, setErrorKey] = useState<FormKey[]>([]);
  const [registrationMode, setRegistrationMode] =
    useState<RegistrationMode>('none');
  const { match } = useReactRouter<{
    workspaceUid: string;
  }>();
  const { workspaceUid } = match.params;
  const { shops } = useShops(workspaceUid);
  const [isHistoryOpened, setIsHistoryOpened] = useState(false);
  const { kana, setKanaSource } = useKana();
  const [isEditedNameKana, setIsEditedNameKana] = useState<boolean>(
    customer !== undefined
  );
  const { isSpSize } = useSizeType();

  useEffect(() => {
    if (!customer) {
      setForm({
        ...defaultForm,
        name: defaultNewCustomer?.name || '',
        tel: defaultNewCustomer?.tel || '',
        email: defaultNewCustomer?.email || '',
        selectedShopIds: [shopId],
      });
    } else {
      const selectedShopIds = customer.shopCustomers.map((s) => s.shopId);
      setForm({
        name: customer.customer.name || '',
        nameKana: customer.customer.nameKana || '',
        tel: customer.customer.tel || '',
        email: customer.customer.email || '',
        postalCode: customer.customer.postalCode || '',
        address: customer.customer.address || '',
        note: customer.customer.note || '',
        selectedShopIds,
      });
    }
  }, [customer, defaultNewCustomer, shopId]);

  useEffect(() => {
    setKanaSource(form.name);
  }, [form.name]);

  useEffect(() => {
    if (!isEditedNameKana) {
      setForm((prev) => ({ ...prev, nameKana: kana }));
    }
  }, [kana]);

  const submit = () => {
    const data: UpdateCustomerRequestData = form;
    onSave(data, registrationMode);
  };

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (hasError()) return;
    submit();
  };

  const addErrorKey = (key: FormKey) => {
    const baseArray = [...errorKey];

    if (!baseArray.includes(key)) {
      baseArray.push(key);
    }
    setErrorKey(baseArray);
  };

  const removeErrorKey = (key: FormKey) => {
    const baseArray = [...errorKey];
    baseArray.splice(baseArray.indexOf(key), 1);
    setErrorKey(baseArray);
  };

  const hasError = () => {
    if (
      getSeparateShopIds(shops, form.selectedShopIds).applicableShopIds
        .length === 0
    ) {
      addErrorKey('selectedShopIds');
      return true;
    } else if (form.nameKana && !isKana(form.nameKana)) {
      addErrorKey('nameKana');
      return true;
    }
    return false;
  };

  const createChangeHandler = (key: keyof Form) => {
    return (e: React.ChangeEvent<HTMLInputElement>) => {
      setForm({
        ...form,
        [key]: e.target.value,
      });
      removeErrorKey(key);
    };
  };

  const onBlurNameKanaHandler = () => {
    setIsEditedNameKana(true);

    // 半角カナ or ローマ字 => 全角カナ => 全角ひらがな
    const value = kanaToHiragana(kanaHalfToFull(romanToKana(form.nameKana)));
    if (!isKana(value)) {
      addErrorKey('nameKana');
    }

    setForm({
      ...form,
      nameKana: value,
    });
  };

  const handleDelete = () => {
    customer && onDelete?.(customer.customer.id);
  };

  const updateSelectedShopIds = (newValue: number[]) => {
    setForm({
      ...form,
      selectedShopIds: newValue,
    });
    removeErrorKey('selectedShopIds');
  };

  const buildRightButtons = () => {
    return (
      <>
        <Button
          onClick={onCancel}
          color="primary"
          css={css`
            margin-right: 20px;
          `}
        >
          キャンセル
        </Button>
        {!customer && hideContinuousButton !== true && (
          <Button
            type="submit"
            variant="outlined"
            color="primary"
            onClick={() => {
              setRegistrationMode('continuous');
            }}
            css={css`
              margin-right: 20px;
            `}
          >
            登録して続けて入力
          </Button>
        )}
        <Button
          type="submit"
          variant="contained"
          color="primary"
          onClick={() => {
            setRegistrationMode('single');
          }}
        >
          {customer ? '更新' : '登録'}する
        </Button>
      </>
    );
  };

  const buildHeader = () => {
    return (
      <>
        <Grid
          container
          justifyContent="space-between"
          alignItems="flex-start"
          css={css`
            margin: 0 auto 16px auto;
          `}
        >
          <Grid item>
            <h2
              css={css`
                display: flex;
                align-items: center;
                margin: 0;
              `}
            >
              {customer?.customer.deletedAt && '(削除済みの顧客)'}
            </h2>
          </Grid>
          <Grid item xs>
            {customer && (
              <Grid container alignItems="center" justifyContent="flex-end">
                <Button
                  variant="outlined"
                  color="primary"
                  startIcon={<AccessTime />}
                  onClick={() => {
                    setIsHistoryOpened(true);
                  }}
                >
                  履歴 ({customer.customer.reservationCount}回予約、
                  {customer.customer.canceledReservationCount}回キャンセル)
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid container alignItems="center" justifyContent="flex-end">
          {customer?.customer?.createdAt && (
            <div css={styles.rightInfo}>
              作成: {customer.customer.createdAt}
            </div>
          )}
          {customer?.customer?.updatedAt && (
            <div css={styles.rightInfo}>
              更新: {customer.customer.updatedAt}
            </div>
          )}
        </Grid>
      </>
    );
  };

  return (
    <>
      <form onSubmit={handleSubmit} ref={formRef}>
        {buildHeader()}
        <Paper
          css={
            isSpSize && isShowInDialog
              ? css`
                  box-shadow: none;
                `
              : css`
                  padding: 20px;
                `
          }
        >
          <SelectShops
            selectedShopIds={form.selectedShopIds}
            updateSelectedShopIds={updateSelectedShopIds}
            shops={shops}
            errorKey={errorKey}
          />

          <TextField
            autoFocus
            label="名前"
            helperText="名前を入力してください。"
            fullWidth
            value={form.name}
            required
            inputProps={{
              maxLength: 100,
            }}
            onChange={createChangeHandler('name')}
          />
          <TextField
            label="ふりがな"
            helperText="名前のふりがなを入力してください。"
            fullWidth
            value={form.nameKana}
            inputProps={{
              maxLength: 100,
            }}
            onChange={createChangeHandler('nameKana')}
            onBlur={onBlurNameKanaHandler}
            error={errorKey.includes('nameKana')}
          />
          <TextField
            label="電話番号(ハイフンなし)"
            helperText="電話番号を入力してください。"
            fullWidth
            value={form.tel}
            inputProps={{
              maxLength: 15,
            }}
            inputMode="tel"
            onChange={createChangeHandler('tel')}
          />
          <TextField
            label="メールアドレス"
            helperText="メールアドレスを入力してください。"
            fullWidth
            value={form.email}
            inputMode="email"
            inputProps={{
              maxLength: 100,
            }}
            onChange={createChangeHandler('email')}
          />
          <TextField
            label="郵便番号(ハイフン不要)"
            helperText="郵便番号を入力してください。"
            fullWidth
            value={form.postalCode}
            inputMode="numeric"
            inputProps={{
              maxLength: 7,
            }}
            onChange={createChangeHandler('postalCode')}
          />
          <TextField
            label="住所"
            helperText="住所を入力してください。"
            fullWidth
            value={form.address}
            onChange={createChangeHandler('address')}
          />
          <TextField
            label="顧客メモ"
            helperText="この顧客に関するメモ、内部的な連絡事項等を自由に登録できます。"
            multiline
            fullWidth
            value={form.note}
            onChange={createChangeHandler('note')}
          />
          <LineInfo lineUser={customer?.lineUser} />
          <CustomerLastInputInfo
            customerLastInput={customer?.customerLastInput}
          />
        </Paper>
        {hideButtonButtons !== true && (
          <Grid
            container
            css={css`
              margin-top: 20px;
              margin-bottom: 20px;
            `}
          >
            <Grid
              item
              container
              alignItems="center"
              justifyContent="flex-start"
              style={{ width: 'fit-content' }}
            >
              {customer && customer.customer.deletedAt === null && (
                <Button variant="contained" onClick={handleDelete}>
                  顧客を削除
                </Button>
              )}
            </Grid>
            <Grid
              item
              container
              xs
              alignItems="center"
              justifyContent="flex-end"
              style={{ width: 'fit-content' }}
            >
              {buildRightButtons()}
            </Grid>
          </Grid>
        )}
      </form>
      {customer && (
        <HistoryDrawer
          isOpen={isHistoryOpened}
          onClose={() => {
            setIsHistoryOpened(false);
          }}
          customer={customer}
        />
      )}
    </>
  );
});

const SelectShops = ({
  selectedShopIds,
  updateSelectedShopIds,
  shops,
  errorKey,
}: {
  selectedShopIds: number[];
  updateSelectedShopIds: (newValue: number[]) => void;
  shops: Shop[];
  errorKey: FormKey[];
}) => {
  const separateSelectedShopIds = getSeparateShopIds(shops, selectedShopIds);
  // eslint-disable-next-line @typescript-eslint/ban-types
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number[]) => {
    updateSelectedShopIds([
      ...newValue,
      ...separateSelectedShopIds.notApplicableShopIds,
    ]);
  };
  const isError = errorKey.includes('selectedShopIds');

  return (
    <Box>
      <Autocomplete
        id="multiple-shops-for-customer-edit"
        multiple
        fullWidth
        disableCloseOnSelect
        options={shops.map((shop) => shop.id)}
        value={separateSelectedShopIds.applicableShopIds}
        onChange={handleChange}
        getOptionLabel={(option) => {
          const shop = shops.find((shop) => shop.id === option);
          return shop?.name || '';
        }}
        renderOption={(option) => {
          const shop = shops.find((shop) => shop.id === option);
          if (!shop) return;
          return (
            <Fragment>
              <Checkbox
                icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                checkedIcon={<CheckBoxIcon fontSize="small" />}
                style={{ marginRight: 8 }}
                checked={separateSelectedShopIds.applicableShopIds.includes(
                  shop.id
                )}
              />
              {shop.name}
            </Fragment>
          );
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="standard"
            label="店舗を選択*"
            helperText={'顧客が所属する店舗を選択してください。(1店舗以上)'}
            error={isError}
          />
        )}
        getOptionSelected={(option, value) => option === value}
        css={css`
          min-width: 210px;
        `}
      />
    </Box>
  );
};

const LineInfo = (props: { lineUser: RichCustomerInfo['lineUser'] }) => {
  const { lineUser } = props;
  const lineConnected = !!lineUser?.userId;
  return (
    <Alert
      severity={lineConnected ? 'success' : 'info'}
      icon={
        <img
          src="/img/line.svg"
          css={css`
            width: 16px;
            vertical-align: middle;
          `}
          alt=""
        />
      }
      css={css`
        margin-top: 20px;
      `}
    >
      {lineConnected ? `${lineUser.displayName}(LINE連携済み)` : 'LINE未連携'}
    </Alert>
  );
};

const CustomerLastInputInfo = (props: {
  customerLastInput: RichCustomerInfo['customerLastInput'];
}) => {
  const { customerLastInput } = props;

  const buildInfo = (value: string | undefined) => {
    if (!value) {
      return null;
    }
    return (
      <div
        css={css`
          color: #555;
        `}
      >
        {value}
      </div>
    );
  };

  const lines = useMemo(() => {
    if (!customerLastInput) {
      return [];
    }
    return [
      customerLastInput.name,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      formatPhoneNumber(customerLastInput.tel || ''),
      customerLastInput.email,
    ].map((value) => buildInfo(value));
  }, [customerLastInput]);

  if (!customerLastInput) {
    return null;
  }

  return (
    <Alert
      severity={'warning'}
      iconMapping={{
        warning: <ListAltIcon fontSize="inherit" />,
      }}
      css={css`
        margin-top: 20px;
      `}
    >
      <AlertTitle>最終予約時にフォームに入力された個人情報</AlertTitle>
      {lines}
    </Alert>
  );
};
