/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { FormControl, FormLabel, Menu, MenuItem } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import dayjs from 'dayjs';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router';
import { AggregationUnit } from '../../../@interfaces/insight/backend';
import { useStoreParams } from './useStoreParams';

type Condition = {
  id: string;
  label: string;
  calcRange: (baseDate: dayjs.Dayjs) => {
    start: dayjs.Dayjs;
    end: dayjs.Dayjs;
    aggregationUnit: AggregationUnit;
    comparisonStart: dayjs.Dayjs;
    comparisonEnd: dayjs.Dayjs;
  };
};

const conditions: Condition[] = [
  {
    id: '28d',
    label: '直近28日(日別)',
    calcRange: (baseDate) => {
      const start = baseDate.subtract(27, 'day');
      const end = baseDate.clone();
      const comparisonStart = start.subtract(28, 'day');
      const comparisonEnd = end.subtract(28, 'day');

      return {
        start,
        end,
        aggregationUnit: 'daily',
        comparisonStart,
        comparisonEnd,
      };
    },
  },
  {
    id: 'thisMonth',
    label: '今月(日別)',
    calcRange: (baseDate) => {
      const start = baseDate.startOf('month');
      const end = baseDate.endOf('month');
      const comparisonStart = baseDate.subtract(1, 'month').startOf('month');
      const comparisonEnd = baseDate.subtract(1, 'month').endOf('month');

      return {
        start,
        end,
        aggregationUnit: 'daily',
        comparisonStart,
        comparisonEnd,
      };
    },
  },
  {
    id: '6m',
    label: '半年（月別）',
    calcRange: (baseDate) => {
      const start = baseDate.subtract(5, 'month').startOf('month');
      const end = baseDate.endOf('month');
      const comparisonStart = start.subtract(6, 'month').startOf('month');
      const comparisonEnd = end.subtract(6, 'month').endOf('month');

      return {
        start,
        end,
        aggregationUnit: 'monthly',
        comparisonStart,
        comparisonEnd,
      };
    },
  },
  {
    id: '1y',
    label: '1年（月別）',
    calcRange: (baseDate) => {
      const start = baseDate.subtract(11, 'month').startOf('month');
      const end = baseDate.endOf('month');
      const comparisonStart = start.subtract(1, 'year').startOf('month');
      const comparisonEnd = end.subtract(1, 'year').endOf('month');
      return {
        start,
        end,
        aggregationUnit: 'monthly',
        comparisonStart,
        comparisonEnd,
      };
    },
  },
  {
    id: 'month',
    label: '月指定',
    calcRange: (baseDate) => {
      const start = baseDate.startOf('month');
      const end = baseDate.endOf('month');
      const comparisonStart = start.subtract(1, 'month').startOf('month');
      const comparisonEnd = end.subtract(1, 'month').endOf('month');
      return {
        start,
        end,
        aggregationUnit: 'daily',
        comparisonStart,
        comparisonEnd,
      };
    },
  },
];

const defaultCondition = conditions[0];

export const useDateRange = () => {
  const location = useLocation();
  const searchParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );
  const [selectedConditionId, setSelectedConditionId] = useState<string>(
    (searchParams.get('range') as string) ?? defaultCondition.id
  );

  const createDefaultBaseDate = useCallback((): dayjs.Dayjs => {
    if (searchParams.has('baseDate')) {
      return dayjs(searchParams.get('baseDate') as string);
    }
    return dayjs();
  }, [searchParams]);

  const restoredRange = (
    conditions.find((c) => c.id === selectedConditionId) ?? defaultCondition
  ).calcRange(createDefaultBaseDate());
  const [aggregationUnit, setAggregationUnit] = useState<AggregationUnit>(
    restoredRange.aggregationUnit
  );
  const [startDate, setStartDate] = useState<dayjs.Dayjs>(restoredRange.start);
  const [endDate, setEndDate] = useState<dayjs.Dayjs>(restoredRange.end);
  const [comparisonStartDate, setComparisonStartDate] = useState<dayjs.Dayjs>(
    restoredRange.comparisonStart
  );
  const [comparisonEndDate, setComparisonEndDate] = useState<dayjs.Dayjs>(
    restoredRange.comparisonEnd
  );
  const { storeParams } = useStoreParams();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const updateSelectedConditionId = useCallback(
    (selectedConditionId: string) => {
      setSelectedConditionId(selectedConditionId);
      const range = conditions
        .find((c) => c.id === selectedConditionId)
        ?.calcRange(createDefaultBaseDate());
      if (range === undefined) {
        return;
      }
      const { start, end, aggregationUnit, comparisonStart, comparisonEnd } =
        range;
      setStartDate(start);
      setEndDate(end);
      setComparisonStartDate(comparisonStart);
      setComparisonEndDate(comparisonEnd);
      setAggregationUnit(aggregationUnit);
    },
    [createDefaultBaseDate]
  );

  useEffect(() => {
    // ブラウザバック実行時にURLパラメータから集計期間を復元
    const searchParams = new URLSearchParams(location.search);
    const selectedConditionId =
      searchParams.get('range') ?? defaultCondition.id;
    updateSelectedConditionId(selectedConditionId);
  }, [location, setSelectedConditionId, updateSelectedConditionId]);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const buildMonthMenuItems = () => {
    const baseDate = dayjs();
    const items = Array.from({ length: 13 }).map((_, index) => {
      const date = baseDate.subtract(index, 'month');
      return (
        <MenuItem
          key={date.format('YYYY-MM')}
          onClick={() => {
            const range = 'month';
            const baseDate = date.format('YYYY-MM');
            storeParams({ range, baseDate });
            updateSelectedConditionId(range);
            handleClose();
          }}
        >
          {date.format('YYYY年MM月')}
        </MenuItem>
      );
    });
    return items;
  };

  const dateRangePicker = (
    <FormControl component="fieldset">
      <FormLabel
        css={css`
          margin-bottom: 0.5em;
        `}
        component="legend"
      >
        集計期間
      </FormLabel>
      <ToggleButtonGroup
        size="small"
        exclusive
        value={selectedConditionId}
        onChange={(_event, value) => {
          if (!value || value === 'month') {
            // 月指定の場合は表示されたメニューのアイテムを選択後に期間が切り替わって欲しいので、ボタンクリックの段階では何もしない
            return;
          }
          updateSelectedConditionId(value as string);
          storeParams({ range: value, baseDate: null });
        }}
        aria-label="集計期間"
      >
        {conditions.map((c) => {
          if (c.id === 'month') {
            return (
              <ToggleButton
                key="month"
                value="month"
                css={css`
                  padding: 0.5em 1em;
                `}
                onClick={(event) => {
                  setAnchorEl(event.currentTarget);
                }}
              >
                {selectedConditionId === 'month'
                  ? startDate.format('YYYY年MM月')
                  : '月指定'}

                <ArrowDropDownIcon />
              </ToggleButton>
            );
          }

          return (
            <ToggleButton
              key={c.id}
              value={c.id}
              aria-label={c.label}
              css={css`
                padding: 0.5em 1em;
              `}
            >
              {c.label}
            </ToggleButton>
          );
        })}
      </ToggleButtonGroup>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {buildMonthMenuItems()}
      </Menu>
    </FormControl>
  );

  return {
    startDate,
    endDate,
    comparisonStartDate,
    comparisonEndDate,
    aggregationUnit,
    dateRangePicker,
  };
};
