/** @jsxImportSource @emotion/react */

import React from 'react';
import { css } from '@emotion/react';

const courseStyles = {
  container: css`
    background: #ffffff;
    border-radius: 10px;
    padding: 10px;
    box-shadow: 0 3px 6px rgba(44, 40, 40, 0.11);
  `,
  courseName: css`
    font-weight: bold;
    font-size: 18px;
  `,
  button: css`
    display: block;
    background: #172b4d;
    color: #ffffff;
    border-radius: 56px;
    padding: 9px 22px;
    margin-left: 8px;
    border: none;
    font-size: 14px;
    text-align: center;
    font-weight: bold;

    @media screen and (max-width: 320px) {
      padding: 9px 8px;
      font-size: 11px;
    }
  `,
  description: css`
    p {
      margin-top: 16px;
      font-size: 14px;
    }
  `,
};

export const PreviewCourse: React.VFC<{ primaryColor: string }> = ({
  primaryColor,
}) => {
  return (
    <div css={courseStyles.container}>
      <h3 css={courseStyles.courseName} style={{ color: primaryColor }}>
        コース名 〇〇
      </h3>
      <div
        css={css`
          width: 100%;
          margin-top: 20px;
          display: flex;
          justify-content: flex-end;
        `}
      >
        <div css={courseStyles.button} style={{ background: primaryColor }}>
          空席確認・予約する
        </div>
      </div>
    </div>
  );
};
