/**
 * アプリで使用するlocalStorage/sessionStorageのキーをすべて集約します。
 */

export const localStorageKeys = {
  includingDeletedReservations: 'includingDeletedReservations',
  isExpandedSidebar: 'isExpandedSidebar',
};

export const sessionStorageKeys = {};
