/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Typography,
} from '@material-ui/core';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import CloseIcon from '@material-ui/icons/Close';
import dayjs from 'dayjs';
import { useEffect, useMemo, useRef, useState } from 'react';
import { colors } from '../../../colors';
import useStorage from '../../../hooks/use-storage';
import { dialogStyles } from '../../common/components/dialog-styles';
import { MixedInformation } from '../types/mixed-information';

export const overrideDialogStyles = {
  dialog: css`
    .MuiPaper-rounded {
      border-radius: 12px;
      padding-bottom: 32px;
    }
  `,
};

const styles = {
  title: css`
    font-size: 1.75rem;
    font-weight: bold;
  `,
  content: css`
    .MuiTypography-colorTextSecondary {
      color: #333;
    }
    a {
      color: ${colors.primary};
    }
    img {
      max-width: 100%;
      height: auto !important;
    }
  `,
  releaseDate: css`
    font-size: 1rem;
    font-weight: bold;
    margin-bottom: 1rem;
    color: #fff;
    background-color: #999;
    display: inline-block;
    padding: 2px 10px;
  `,
};

type Props = {
  informations: MixedInformation[];
};

type LastShwoingTime = {
  time: string;
};

// お知らせ最新表示ダイアログ
export const LatestInfomationDialog = (props: Props): JSX.Element | null => {
  const { informations } = props;
  const [open, setOpen] = useState(true);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [lastShowingTime, setLastShowingTime] =
    useStorage<LastShwoingTime | null>(
      'LatestInfomationDialog_LastShwoingTime',
      null
    );
  const contentElementRef = useRef<HTMLDivElement>();

  const latestInformations: MixedInformation[] = useMemo(() => {
    // 初回は表示せずに、今の時間を最終表示時間として保存
    if (!lastShowingTime) {
      setLastShowingTime({
        time: dayjs().format('YYYY-MM-DD HH:mm:ss'),
      });
      return [];
    }
    const lastTime = dayjs(lastShowingTime.time);
    // HTMLがあり、最終表示日時以降のものの最新5件を表示
    return informations
      .filter((i) => {
        const releaseTime = dayjs(i.releaseDate);
        return releaseTime.isAfter(lastTime) && i.contentHtml;
      })
      .slice(0, 5);
  }, [informations, lastShowingTime]);

  const [currentInformation, html]: [MixedInformation | undefined, string] =
    useMemo(() => {
      const target = latestInformations[currentIndex];
      // 本文末尾にある元ポストへのリンクを除去
      const html =
        target?.contentHtml?.substring(
          0,
          target?.contentHtml?.indexOf('The post')
        ) || '';
      return [target, html];
    }, [latestInformations, currentIndex]);

  useEffect(() => {
    // 次へを実行したあとにスクロール位置を戻す
    if (contentElementRef.current) {
      contentElementRef.current.scrollTop = 0;
    }
  }, [currentInformation]);

  const handleClose = () => {
    // 最終表示時間を更新
    setLastShowingTime({
      time: dayjs().format('YYYY-MM-DD HH:mm:ss'),
    });
    setOpen(false);
  };

  const handleNext = () => {
    setCurrentIndex(currentIndex + 1);
  };

  const hasNext = currentIndex < latestInformations.length - 1;

  if (!currentInformation) {
    return null;
  }

  return (
    <Dialog open={open} css={overrideDialogStyles.dialog} maxWidth="md">
      <DialogTitle css={dialogStyles.header}>
        <Typography css={dialogStyles.headerText}>お知らせ</Typography>
        <Box css={dialogStyles.headerAbsolute}>
          {latestInformations.length > 0 && (
            <Box css={dialogStyles.stepNum}>
              <span>{currentIndex + 1}</span>/ {latestInformations.length}
            </Box>
          )}
          <Box>
            <IconButton css={dialogStyles.closeButton} onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent
        css={styles.content}
        ref={(elem) => (contentElementRef.current = elem as HTMLDivElement)}
      >
        <DialogContentText>
          <div css={styles.releaseDate}>
            {currentInformation?.releaseDate.substring(0, 10)}
          </div>
          <div css={styles.title}>{currentInformation?.title}</div>
          <div dangerouslySetInnerHTML={{ __html: html }}></div>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          variant={hasNext ? 'text' : 'contained'}
          color="primary"
          onClick={handleClose}
        >
          &nbsp;閉じる&nbsp;
        </Button>
        {hasNext && (
          <Button
            variant="contained"
            color="primary"
            onClick={handleNext}
            endIcon={<ArrowForwardIcon />}
          >
            次のお知らせを表示
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};
