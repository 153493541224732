import axios, { AxiosResponse } from 'axios';
import { useContext } from 'react';
import useSWR from 'swr';
import { GetConversionWeekdayResponse } from '../../../@interfaces/insight/api';
import { WeekdayConversionAggregation } from '../../../@interfaces/insight/backend';
import { API_END_POINT } from '../../../api/api';
import { Store } from '../../../context/GlobalStore';

const fetcher = async (
  url: string,
  token: string,
  startDate: string,
  endDate: string,
  courseIds: string[],
  inflowSources: string[]
) => {
  return axios
    .get(url, {
      params: {
        startDate,
        endDate,
        courseIds,
        inflowSources,
      },
      headers: {
        Authorization: token,
      },
    })
    .then(
      (response: AxiosResponse<GetConversionWeekdayResponse>) => response.data
    );
};

export default function useInsightConversionWeekday(
  shopId: string,
  startDate: string,
  endDate: string,
  courseIds: string[],
  inflowSources: string[],
  shouldFetch = true
): {
  results: WeekdayConversionAggregation[];
  isLoading: boolean;
} {
  const { globalState } = useContext(Store);

  const { data, isLoading } = useSWR(
    globalState.session?.idToken && shouldFetch
      ? [
          `${API_END_POINT}/app/shops/${shopId}/insight/conversion/weekday`,
          globalState.session.idToken,
          startDate,
          endDate,
          courseIds,
          inflowSources,
        ]
      : null,
    async ([url, token, startDate, endDate, courseIds, inflowSources]) =>
      fetcher(url, token, startDate, endDate, courseIds, inflowSources)
  );

  return {
    results: data?.results ?? [],
    isLoading,
  };
}
