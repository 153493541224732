/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { Button, Grid, Typography } from '@material-ui/core';
import { useSizeType } from '../hooks/use-size-type';

export const Pager = ({
  count,
  countPerPage,
  page,
  onChangePage,
}: {
  count: number;
  countPerPage: number;
  page: number;
  onChangePage: (page: number) => void;
}) => {
  const { isSpSize } = useSizeType();
  if (countPerPage === 0) {
    return null;
  }
  const maxPage = Math.ceil(count / countPerPage);
  const sideButtonCount = isSpSize ? 2 : 4;
  const visibleButtonCount = 2 * sideButtonCount + 1;
  const fits = visibleButtonCount >= maxPage;
  const startPage =
    fits || page - sideButtonCount <= 0 ? 1 : page - sideButtonCount;
  const ends = startPage + visibleButtonCount >= maxPage;

  return (
    <>
      <Typography
        css={css`
          margin-bottom: 20px;
        `}
      >
        全{count}件中 {countPerPage * (page - 1) + 1} ~{' '}
        {page === maxPage ? count : countPerPage * page} 件を表示
      </Typography>
      <Grid
        css={css`
          display: flex;
          gap: 10px;
          flex-wrap: wrap;
        `}
      >
        <Button
          onClick={() => {
            onChangePage(page + 1);
          }}
          css={css`
            height: 30px;
            font-size: 10px;
            border-radius: 20px;
            margin-right: 20px;
          `}
          variant="contained"
          size="small"
        >
          {'次へ >'}
        </Button>
        {startPage !== 1 && '...'}
        {[...Array(Math.min(visibleButtonCount, maxPage))].map((_, i) => {
          const pageNum = (() => {
            if (fits || !ends) {
              return startPage + i;
            } else {
              return maxPage - visibleButtonCount + 1 + i;
            }
          })();

          return (
            <Button
              key={`page-${pageNum}`}
              onClick={() => {
                onChangePage(pageNum);
              }}
              css={css`
                min-width: 30px;
                height: 30px;
                font-size: 10px;
                border-radius: 20px;
              `}
              variant="contained"
              size="small"
              color={pageNum === page ? 'primary' : undefined}
            >
              {pageNum}
            </Button>
          );
        })}
        {!ends && '...'}
      </Grid>
    </>
  );
};
