/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  Grid,
  Paper,
  Radio,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useState } from 'react';
import { CustomerInfo } from '../../../@interfaces/customer';
import { commonCss } from '../../../components/common-css';
import { useSizeType } from '../../../hooks/use-size-type';
import { formatPhoneNumber } from '../../../utils/phonenumber-utils';
import { useMergeCustomer } from '../api/useMergeCustomers';
import { LineInfo } from './CustomerSelector/LineInfo';
import { ReservationCountInfo } from './CustomerSelector/ReservationCountInfo';

const styles = {
  dialog: css`
    .MuiPaper-root {
      max-width: 1200px;
    }
  `,
  reservationCount: css`
    font-size: 20px;
  `,
  table: css`
    width: 100%;
    table-layout: fixed;
    border-collapse: collapse;
    border-spacing: 0;
  `,
  tableHeaderForSp: css`
    width: 100px;
    padding: 8px;
    border-right: 1px solid #e0e0e0;
    border-bottom: 1px solid #e0e0e0;
    font-size: 12px;
  `,
  tableBodyForSp: css`
    padding: 8px;
    border-bottom: 1px solid #e0e0e0;
    max-width: calc(100% - 100px);
    overflow-x: auto;
  `,
};

type ElementsKey =
  | 'radio'
  | 'name'
  | 'tel'
  | 'email'
  | 'reservationCount'
  | 'note';

export const CustomerMergeDialog = ({
  workspaceUid,
  customers,
  ...props
}: { workspaceUid: string; customers: CustomerInfo[] } & Omit<
  DialogProps,
  'children'
>) => {
  const [mainCustomerId, setMeinCustomerId] = useState<number | null>(null);
  const [isMerging, setIsMerging] = useState(false);
  const { isSpSize } = useSizeType();
  const mergeCustomers = useMergeCustomer();
  // eslint-disable-next-line @typescript-eslint/ban-types
  const handleClose = (e: {}, reason: 'backdropClick' | 'escapeKeyDown') => {
    setMeinCustomerId(null);
    props.onClose?.(e, reason);
  };

  const handleCancel = () => {
    setMeinCustomerId(null);
    props.onClose?.({}, 'backdropClick');
  };

  const handleMerge = () => {
    setIsMerging(true);
    if (mainCustomerId === null) {
      setIsMerging(false);
      return;
    }

    if (
      !window.confirm(
        `${customers.length}件の顧客を統合します。
メインに選択した顧客以外は削除されます。
この操作は元も戻せませんがよろしいですか？`
      )
    ) {
      setIsMerging(false);
      return;
    }

    mergeCustomers(workspaceUid, {
      mainCustomerId,
      subCustomerIds: customers
        .filter((c) => c.id !== mainCustomerId)
        .map((c) => c.id),
    })
      .then(() => {
        alert('統合が完了しました。');
        window.location.reload();
      })
      .catch((e) => {
        alert('エラーが発生しました。時間を置いてから再度実行をお願いします。');
      })
      .finally(() => {
        setIsMerging(false);
      });
  };

  const elements: {
    key: ElementsKey;
    headerBody: JSX.Element | string;
    rowBody: (customer: CustomerInfo) => JSX.Element;
  }[] = [
    {
      key: 'radio',
      headerBody: 'メイン',
      rowBody: (customer: CustomerInfo) => (
        <Radio
          checked={mainCustomerId === customer.id}
          onClick={() => {
            setMeinCustomerId(customer.id);
          }}
        />
      ),
    },
    {
      key: 'name',
      headerBody: '名前',
      rowBody: (customer: CustomerInfo) => (
        <>
          <div
            css={css`
              font-size: 10px;
            `}
          >
            {customer.nameKana}
          </div>
          <div>
            {customer.name} <LineInfo lineUser={customer} />
          </div>
          {customer.deletedAt && <div>(削除済み)</div>}
        </>
      ),
    },
    {
      key: 'tel',
      headerBody: '電話番号',
      rowBody: (customer: CustomerInfo) => (
        <>{formatPhoneNumber(customer.tel)}</>
      ),
    },
    {
      key: 'email',
      headerBody: 'メールアドレス',
      rowBody: (customer: CustomerInfo) => <>{customer.email}</>,
    },
    {
      key: 'reservationCount',
      headerBody: (
        <>
          利用回数
          <br />
          最終予約日
        </>
      ),
      rowBody: (customer: CustomerInfo) => (
        <ReservationCountInfo customer={customer} />
      ),
    },
    {
      key: 'note',
      headerBody: '顧客メモ',
      rowBody: (customer: CustomerInfo) => <>{customer.note}</>,
    },
  ];

  return (
    <Dialog {...props} onClose={handleClose} css={styles.dialog}>
      <DialogContent>
        <Alert severity="warning">
          <div>以下の顧客を統合します。</div>
          <div>
            <strong>統合先のメインになる顧客情報を選択してください。</strong>
            <ul>
              <li>
                予約履歴、LINEユーザー情報はメインの顧客にすべて統合されます。
              </li>
              <li>顧客メモはメインの顧客に結合されます。</li>
              <li>メインに選択した顧客以外は削除されます。</li>
            </ul>
          </div>
        </Alert>
        {!isSpSize ? (
          <TableContainer
            css={css`
              margin-top: 30px;
              max-height: 500px;
            `}
          >
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  {elements.map((elem, index) => (
                    <TableCell key={index}>{elem.headerBody}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {customers.map((customer) => (
                  <TableRow
                    key={customer.id}
                    css={
                      customer.deletedAt
                        ? css`
                            background-color: #dddddd;
                          `
                        : null
                    }
                  >
                    {elements.map((elem, index) => (
                      <TableCell
                        key={index}
                        scope={index > 0 ? 'row' : undefined}
                      >
                        {elem.rowBody(customer)}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <>
            {customers.map((customer, index) => (
              <Box
                key={index}
                mt={3}
                css={css`
                  width: 100%;
                  border: 1px solid #e0e0e0;
                `}
              >
                <Box css={customer.deletedAt && commonCss.deletedRow}>
                  {elements.map((elem, elemIndex) => (
                    <Box key={`elem${elemIndex}`}>
                      <Box>
                        <Grid container>
                          <Grid
                            item
                            css={[
                              commonCss.noWrap,
                              commonCss.noPrint,
                              styles.tableHeaderForSp,
                            ]}
                          >
                            {elem.headerBody}
                          </Grid>
                          <Grid
                            item
                            xs
                            css={[commonCss.noPrint, styles.tableBodyForSp]}
                          >
                            {elem.rowBody(customer)}
                          </Grid>
                        </Grid>
                      </Box>
                    </Box>
                  ))}
                </Box>
              </Box>
            ))}
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Grid container justifyContent="center">
          <Button color="primary" onClick={handleCancel}>
            キャンセル
          </Button>
          <Button
            variant="contained"
            color="primary"
            css={css`
              align-items: right;
              margin-left: 20px;
            `}
            disabled={mainCustomerId === null || isMerging}
            onClick={handleMerge}
          >
            {customers.length}件の顧客を統合する
          </Button>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};
