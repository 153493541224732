/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { Card, CardContent } from '@material-ui/core';
import dayjs from 'dayjs';
import {
  FaLongArrowAltDown,
  FaLongArrowAltRight,
  FaLongArrowAltUp,
} from 'react-icons/fa';
import { GetReservationTotalResponse } from '../../../@interfaces/insight/api';
import {
  AggregationUnit,
  TargetDateType,
} from '../../../@interfaces/insight/backend';
import useInsightReservationTotal from '../api/use-insight-reservation-total';
import { calcGrowthPercent } from '../utils/insight-utils';
import { CardSpinner } from './CardSpinner';

type CancelTotalCardProps = {
  shopId: string;
  primaryStartDate: dayjs.Dayjs;
  primaryEndDate: dayjs.Dayjs;
  comparisonStartDate: dayjs.Dayjs;
  comparisonEndDate: dayjs.Dayjs;
  // 固定値でもAPI呼び出しは可能だが、SWR でキャッシュを効かせるために InsightSummary で使用している値を同じものを使うために必要
  aggregationUnit: AggregationUnit;
  targetDateType: TargetDateType;
};

export const CancelTotalCard = ({
  shopId,
  primaryStartDate,
  primaryEndDate,
  comparisonStartDate,
  comparisonEndDate,
  aggregationUnit,
  targetDateType,
}: CancelTotalCardProps): JSX.Element => {
  const { results, isLoading } = useInsightReservationTotal(
    shopId,
    primaryStartDate.format('YYYY-MM-DD'),
    primaryEndDate.format('YYYY-MM-DD'),
    comparisonStartDate.format('YYYY-MM-DD'),
    comparisonEndDate.format('YYYY-MM-DD'),
    aggregationUnit,
    targetDateType
  );

  const buildContent = () => {
    if (results === undefined || isLoading) {
      return <CardSpinner />;
    }
    return (
      <div
        css={css`
          display: flex;
          align-items: center;
        `}
      >
        <p
          css={css`
            font-size: 32px;
            line-height: 0;
            margin: 0;
          `}
        >
          {results.primary.cancelTotal}件
        </p>
        <CalculatedPercent results={results} />
      </div>
    );
  };

  return (
    <>
      <Card
        css={css`
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        `}
      >
        <CardContent>
          <h3
            css={css`
              font-size: 24px;
              font-weight: normal;
              margin: 0.5em 0;
              padding: 0;
            `}
          >
            キャンセル数
          </h3>
          {buildContent()}
        </CardContent>
      </Card>
    </>
  );
};

const CalculatedPercent = ({
  results,
}: {
  results: GetReservationTotalResponse;
}) => {
  const { value, displayValue } = calcGrowthPercent(
    results.primary.cancelTotal,
    results.comparison.cancelTotal
  );

  const comparisonTotalExists =
    results.comparison.total + results.comparison.cancelTotal !== 0;

  const buildContent = () => {
    if (!Number.isFinite(value)) {
      return null;
    }

    if (value === 0) {
      return (
        <span
          css={css`
            display: flex;
          `}
        >
          <FaLongArrowAltRight
            css={css`
              color: grey;
              margin-right: 5px;
            `}
          />
          {displayValue}
        </span>
      );
    }
    if (value > 0) {
      return (
        <span
          css={css`
            display: flex;
          `}
        >
          <FaLongArrowAltUp
            css={css`
              color: red;
            `}
          />
          {displayValue}
        </span>
      );
    }
    return (
      <span
        css={css`
          display: flex;
        `}
      >
        <FaLongArrowAltDown
          css={css`
            color: green;
          `}
        />
        {displayValue}
      </span>
    );
  };

  return (
    <p
      css={css`
        margin: 0.5em;
        line-height: 1;
        font-size: 16px;
        background-color: ${value === 0 || !Number.isFinite(value)
          ? '#e1e1e1'
          : value < 0
          ? '#e7f4ee'
          : '#F4E7E7'};
        color: ${value === 0 || !Number.isFinite(value)
          ? '#9f9f9f'
          : value < 0
          ? '#0d894f'
          : '#890D0D'};
        border-radius: 16px;
        padding: 5px 10px;
        visibility: ${!comparisonTotalExists || !Number.isFinite(value)
          ? 'hidden'
          : 'visible'};
      `}
    >
      {buildContent()}
    </p>
  );
};
