/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Input,
  Typography,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { useSnackbar } from 'notistack';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { ColorResult, CompactPicker } from 'react-color';
import useReactRouter from 'use-react-router';
import { NewResourceSchedulePatternRequestData } from '../../../@interfaces/resource/apis';
import { ResourceSchedulePattern } from '../../../@interfaces/resource/resource-schedule-pattern';
import { ErrorMessage } from '../../../components/ErrorMessage';
import { TimeRange } from '../../../core/types/reservation-types';
import {
  validateTimeRange,
  validateTimeRanges,
  ValidationError,
} from '../../../core/types/reservation-types-validation';
import { LIGHT_COLORS } from '../../../models/theme';
import { useCreateResourceSchedulePatterns } from '../api/createResourceSchedulePatterns';
import { useDeleteResourceSchedulePatterns } from '../api/deleteResourceSchedulePatterns';
import { useUpdateResourceSchedulePatterns } from '../api/updateResourceSchedulePatterns';
import { TimeRangeEditor } from './TimeRangeEditor';

const styles = {
  preview: (color: string) =>
    css`
      display: inline-block;
      padding: 2px 12px;
      background-color: ${color === '' ? '#FFFFFF' : color};
    `,
  reserveSetting: css`
    display: flex;
    justify-content: flex-start;
    align-items: center;
  `,
};

const timeRangeInitialData = {
  start: {
    hour: 10,
    minute: 0,
  },
  end: {
    hour: 18,
    minute: 0,
  },
};

interface Props {
  editData?: ResourceSchedulePattern;
  dialogClose: () => void;
  reloadData: () => void;
}

export const SchedulePatternCreateOrUpdate: React.VFC<Props> = ({
  editData,
  dialogClose,
  reloadData,
}) => {
  const { match } = useReactRouter<{ shopId: string }>();
  const { shopId } = match.params;
  const [isAvailableSubmit, setIsAvailableSubmit] = useState<boolean>(true);
  const { enqueueSnackbar } = useSnackbar();
  const createResourceSchedulePatterns = useCreateResourceSchedulePatterns();
  const updateResourceSchedulePatterns = useUpdateResourceSchedulePatterns();
  const deleteResourceSchedulePatterns = useDeleteResourceSchedulePatterns();
  const [form, setForm] = useState<NewResourceSchedulePatternRequestData>({
    name: '',
    color: '#FFFFFF',
    schedule: {
      timeRanges: [timeRangeInitialData],
    },
  });
  const [errors, setErrors] = useState<ValidationError[]>([]);

  useEffect(() => {
    if (editData) {
      setForm({
        name: editData.name,
        color: editData.color,
        schedule: editData.schedule,
      });
    }
  }, [editData]);

  const isValidationError = (): boolean => {
    // パターン名チェック
    if (form.name.trim() === '') {
      setErrors([
        {
          message: 'パターン名を入力してください。',
          path: 'schedule_pattern/pattern_name',
        },
      ]);
      return true;
    }

    return false;
  };

  const removeError = (path: string) => {
    const updateData = errors.filter((error) => {
      return error.path !== path;
    });
    setErrors(updateData);
  };

  const handleChangeName = (e: ChangeEvent<HTMLInputElement>) => {
    const name = e.target.value;
    setForm({ ...form, name });
  };

  const handleChangeColor = (
    selectColor: ColorResult,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const color = selectColor.hex;
    setForm({ ...form, color });
  };

  const clickSave = (isContinue: boolean) => {
    if (isValidationError()) return;
    setIsAvailableSubmit(false);
    createResourceSchedulePatterns(shopId, form)
      .then(() => {
        enqueueSnackbar(`稼働パターンを追加しました。`, { variant: 'success' });
      })
      .catch((e) => {
        enqueueSnackbar('稼働パターンの追加に失敗しました。', {
          variant: 'error',
        });
        console.error(e);
      })
      .finally(() => {
        setIsAvailableSubmit(true);
        reloadData();
        if (!isContinue) {
          dialogClose();
        }
      });
  };

  const clickEdit = () => {
    if (isValidationError()) return;

    if (editData) {
      setIsAvailableSubmit(false);
      updateResourceSchedulePatterns(shopId, editData.id, form)
        .then(() => {
          enqueueSnackbar(`稼働パターンを編集しました。`, {
            variant: 'success',
          });
        })
        .catch((e) => {
          enqueueSnackbar('稼働パターンの編集に失敗しました。', {
            variant: 'error',
          });
          console.error(e);
        })
        .finally(() => {
          setIsAvailableSubmit(true);
          reloadData();
          dialogClose();
        });
    }
  };

  const clickCancel = () => {
    reloadData();
    dialogClose();
  };

  const clickDelete = () => {
    if (
      editData &&
      prompt('この稼働パターンを削除するには「削除」と入力してください。') ===
        '削除'
    ) {
      setIsAvailableSubmit(false);
      deleteResourceSchedulePatterns(shopId, editData.id)
        .then(() => {
          enqueueSnackbar(`稼働パターンを削除しました。`, {
            variant: 'success',
          });
        })
        .catch((e) => {
          enqueueSnackbar('稼働パターンの削除に失敗しました。', {
            variant: 'error',
          });
          console.error(e);
        })
        .finally(() => {
          setIsAvailableSubmit(true);
          reloadData();
          dialogClose();
        });
    }
  };

  return (
    <div>
      <DialogContent>
        <FormControl required component="fieldset" fullWidth>
          <Box>
            <Typography color="primary">パターン名</Typography>
            <FormControlLabel
              control={
                <Input
                  fullWidth
                  type="text"
                  value={form.name}
                  onChange={handleChangeName}
                  onFocus={() => {
                    removeError('schedule_pattern/pattern_name');
                  }}
                />
              }
              labelPlacement="top"
              label={``}
              css={css`
                width: 100%;
                margin: 0;
              `}
            />
            <ErrorMessage
              errors={errors}
              path="schedule_pattern/pattern_name"
            />
          </Box>
          <Box mt={2}>
            <Typography color="primary">色</Typography>
            <FormControlLabel
              control={
                <CompactPicker
                  colors={LIGHT_COLORS}
                  color={form.color}
                  onChangeComplete={handleChangeColor}
                />
              }
              labelPlacement="top"
              label={``}
            />
            <ErrorMessage errors={errors} path="schedule_pattern/color" />
          </Box>
          <Box mt={2}>
            <Typography color="primary">プレビュー</Typography>
            <Box css={styles.preview(form.color)}>{form.name}</Box>
          </Box>
          <Box mt={2}>
            <Typography color="primary">時間</Typography>
            <TimeRanges
              form={form}
              setForm={setForm}
              errors={errors}
              setErrors={setErrors}
            />
          </Box>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid
            item
            css={css`
              margin-right: 32px;
            `}
          >
            {editData && (
              <Button variant="contained" onClick={clickDelete}>
                削除
              </Button>
            )}
          </Grid>
          <Grid
            item
            container
            justifyContent="flex-end"
            alignItems="center"
            css={css`
              width: fit-content;
            `}
          >
            <Button
              color="primary"
              variant="outlined"
              css={css`
                margin-right: 16px;
              `}
              onClick={clickCancel}
            >
              キャンセル
            </Button>
            {!editData && (
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  clickSave(true);
                }}
                disabled={!isAvailableSubmit || errors.length > 0}
                style={{ marginRight: '16px' }}
              >
                保存後に続けて入力
              </Button>
            )}
            <Button
              color="primary"
              variant="contained"
              onClick={
                editData
                  ? clickEdit
                  : () => {
                      clickSave(false);
                    }
              }
              disabled={!isAvailableSubmit || errors.length > 0}
            >
              保存
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </div>
  );
};

const TimeRanges: React.VFC<{
  form: NewResourceSchedulePatternRequestData;
  setForm: React.Dispatch<
    React.SetStateAction<NewResourceSchedulePatternRequestData>
  >;
  errors: ValidationError[];
  setErrors: React.Dispatch<React.SetStateAction<ValidationError[]>>;
}> = ({ form, setForm, errors, setErrors }) => {
  const addTimeRange = () => {
    const newTimeRanges = [...form.schedule.timeRanges, timeRangeInitialData];
    setForm({
      ...form,
      schedule: {
        timeRanges: [...form.schedule.timeRanges, timeRangeInitialData],
      },
    });

    setErrors([...validateTimeRanges(newTimeRanges, 0, 0)]);
  };

  const removeTimeRange = (index: number) => {
    setErrors([]);
    const basedData = [...form.schedule.timeRanges];
    basedData.splice(index, 1);
    setForm({ ...form, schedule: { timeRanges: basedData } });

    setErrors([...validateTimeRanges(basedData, 0, 0)]);
  };

  const handleChangeTimeRange = (timeRange: TimeRange, index: number) => {
    setErrors([]);
    const newTimeRanges = [
      ...form.schedule.timeRanges.slice(0, index),
      timeRange,
      ...form.schedule.timeRanges.slice(
        index + 1,
        form.schedule.timeRanges.length
      ),
    ];
    setForm({
      ...form,
      schedule: {
        timeRanges: newTimeRanges,
      },
    });

    setErrors([
      ...validateTimeRange(timeRange, 0, 0, index),
      ...validateTimeRanges(newTimeRanges, 0, 0),
    ]);
  };

  return (
    <Box>
      {form.schedule.timeRanges.map((timeRange, index) => (
        <Grid
          key={index}
          container
          justifyContent="flex-start"
          alignItems="center"
        >
          <Grid item>
            <TimeRangeEditor
              timeRange={timeRange}
              onChange={(newTimeRange) => {
                handleChangeTimeRange(newTimeRange, index);
              }}
            />
            <ErrorMessage
              errors={errors}
              path={`rules/0/schedules/0/timeRanges/${index}`}
            />
          </Grid>
          <Grid item>
            <IconButton
              onClick={() => {
                removeTimeRange(index);
              }}
            >
              <DeleteIcon />
            </IconButton>
          </Grid>
        </Grid>
      ))}
      <Box mt={2}>
        <Button color="primary" variant="text" onClick={addTimeRange}>
          時間帯を追加
        </Button>
      </Box>
    </Box>
  );
};
