/** @jsxImportSource @emotion/react */

import { Grid, IconButton, InputAdornment, TextField } from '@material-ui/core';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import DeleteIcon from '@material-ui/icons/Delete';
import React, { ChangeEvent } from 'react';
import {
  AnyField,
  FormSetting,
  Option,
} from '../../../../../core/types/reservation-form-types';
import { SelectedField } from './FormSettingOptions';

type FormSettingOptionProps = {
  field: SelectedField;
  option: Option;
  setting: FormSetting;
  onChangeSetting: (setting: FormSetting) => void;
  originalOption: Option | undefined;
};

export const FormSettingOption = (
  props: FormSettingOptionProps
): JSX.Element => {
  const { field, option, setting, onChangeSetting, originalOption } = props;

  const handleChangeOptionText = (
    e: ChangeEvent<HTMLInputElement>,
    option: Option
  ) => {
    option.text = e.target.value;
    onChangeSetting({ ...setting });
  };

  const handleUpOption = (option: Option) => {
    const { options } = field;
    const index = options.indexOf(option);
    if (index === 0) {
      return;
    }
    const tmp = options[index - 1];
    options[index - 1] = option;
    options[index] = tmp;
    onChangeSetting({ ...setting });
  };

  const handleDownOption = (option: Option) => {
    const { options } = field;
    const index = options.findIndex((f) => f.uid === option.uid);
    if (index >= options.length - 1) {
      return;
    }
    const tmp = options[index + 1];
    options[index + 1] = option;
    options[index] = tmp;
    onChangeSetting({ ...setting });
  };

  const handleDeleteOption = (field: SelectedField, option: Option) => {
    const { deletedFields } = setting;
    const deletedFields2 = deletedFields || [];
    const existDeleteField = deletedFields2?.find(
      (f) => f.uid === field.uid
    ) as SelectedField;
    let newDeletedFields: AnyField[] = [];
    if (existDeleteField) {
      newDeletedFields = [
        ...deletedFields2.filter((f) => f.uid !== field.uid),
        { ...field, options: [...existDeleteField?.options, option] },
      ];
    } else {
      newDeletedFields = [...deletedFields2, { ...field, options: [option] }];
    }
    field.options = field.options.filter((o) => o !== option);
    onChangeSetting({
      ...setting,
      deletedFields: newDeletedFields,
    });
  };

  return (
    <Grid
      item
      container
      xs={12}
      direction="row"
      key={option.uid}
      alignItems="baseline"
    >
      <TextField
        placeholder="選択肢を入力"
        value={option.text}
        onChange={(event: ChangeEvent<HTMLInputElement>) => {
          handleChangeOptionText(event, option);
        }}
        margin="normal"
        required
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <>
                <IconButton
                  onClick={() => {
                    handleUpOption(option);
                  }}
                  size="small"
                >
                  <ArrowUpwardIcon />
                </IconButton>
                <IconButton
                  onClick={() => {
                    handleDownOption(option);
                  }}
                  size="small"
                >
                  <ArrowDownwardIcon />
                </IconButton>
                <IconButton
                  onClick={() => {
                    handleDeleteOption(field, option);
                  }}
                  size="small"
                >
                  <DeleteIcon />
                </IconButton>
              </>
            </InputAdornment>
          ),
        }}
      />
      <div style={{ color: '#aaa' }}>(ID: {option.uid})</div>
      {originalOption && originalOption.text !== option.text ? (
        <div style={{ color: 'red' }}>
          変更あり。過去に「
          <strong>{originalOption.text}</strong>
          」で予約されていた予約情報も「
          <strong>{option.text}</strong>
          」と表示されるようになります。
        </div>
      ) : null}
    </Grid>
  );
};
