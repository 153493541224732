/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { Drawer, IconButton, useTheme } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { ReservationActivityList } from './ReservationActivityList';

const styles = {
  container: css`
    width: 400px;
    height: 100%;
    display: flex;
    flex-direction: column;
  `,

  header: (primaryColor: string) => css`
    color: #fff;
    background-color: ${primaryColor};
    padding: 0 0 0 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 1.1rem;
  `,

  listContainer: css`
    overflow: auto;
  `,

  closeButton: css`
    color: #fff;
  `,
};

type Props = {
  isOpen: boolean;
  onClose: () => void;
  shopId: string;
  resId: string;
};

export function ReservationActivityComponent({
  isOpen,
  onClose,
  shopId,
  resId,
}: Props): JSX.Element {
  const theme = useTheme();

  return (
    <Drawer anchor="right" open={isOpen} onClose={onClose}>
      {isOpen ? (
        <div css={styles.container}>
          <div css={styles.header(theme.palette.primary.main)}>
            <div>更新履歴</div>
            <IconButton onClick={onClose}>
              <CloseIcon css={styles.closeButton} />
            </IconButton>
          </div>
          <div css={styles.listContainer}>
            <ReservationActivityList shopId={shopId} reservationId={resId} />
          </div>
        </div>
      ) : null}
    </Drawer>
  );
}
