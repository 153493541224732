/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { Box, Button, Grid, Paper, TextField } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import HomeRoudedIcon from '@material-ui/icons/HomeRounded';
import React, {
  ChangeEvent,
  FormEvent,
  useState,
  useContext,
  useEffect,
} from 'react';
import useReactRouter from 'use-react-router';
import { signin } from '../../api/auth/cognito';
import {
  Head,
  Main,
  Root,
  TopAppBar,
  TopAppBarSpacer,
} from '../../components/Shared';
import { useStyles } from '../../components/Styles';
import { Store, Session } from '../../context/GlobalStore';
import LinkRouter from '../../components/LinkRouter';
import { useSizeType } from '../../hooks/use-size-type';

interface Form {
  email: string;
  password: string;
}

/**
 * クエリーパラメータ
 * - email: ログイン用のメールアドレス
 * - password: パスワード
 * - next: ログイン成功後に遷移するURL
 * - auto: "1"なら画面表示後パラメータに設定されたemail/passwordでログインを試みる
 */
export default function SignInPage() {
  const { history } = useReactRouter();
  const params = new URLSearchParams(window.location.search);
  const [form, setForm] = useState<Form>({
    email: params.get('email') || '',
    password: params.get('password') || '',
  });
  const { globalState, setGlobalState } = useContext(Store);
  const { isSpSize } = useSizeType();
  const classes = useStyles();

  useEffect(() => {
    const auto = params.get('auto');
    if (auto == '1') {
      trySignin();
    }
  }, []);

  const trySignin = () => {
    if (form.email && form.password) {
      signin(form.email, form.password, {
        onSuccess: function (result) {
          const idToken = result.getIdToken().getJwtToken();
          const accessToken = result.getAccessToken().getJwtToken();
          const refreshToken = result.getRefreshToken().getToken();
          const session = {
            idToken,
            accessToken,
            refreshToken,
          } as Session;
          const state = {
            session,
          };
          const params = new URLSearchParams(window.location.search);
          setGlobalState(state);
          const next = params.get('next') || '/';
          history.push(next);
        },
        onFailure: function (err) {
          if (!err) {
            alert('エラーが発生しました。');
            return;
          }
          if (err.code == 'NotAuthorizedException') {
            alert('メールアドレスかパスワード間違っています。');
          } else {
            alert(err.message);
          }
        },
      });
    }
  };

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    trySignin();
  };

  const handleChangeEmail = (e: ChangeEvent<HTMLInputElement>) => {
    form.email = e.target.value;
    setForm(Object.assign({}, form));
  };

  const handleChangePassword = (e: ChangeEvent<HTMLInputElement>) => {
    form.password = e.target.value;
    setForm(Object.assign({}, form));
  };

  return (
    <Root>
      <Head title="ログイン" />
      <TopAppBar />
      <Main>
        <TopAppBarSpacer />
        <Container maxWidth="lg">
          <Grid
            container
            spacing={3}
            alignItems="flex-start"
            direction={isSpSize ? 'column-reverse' : 'row'}
          >
            <Grid item xs={12} sm={6}>
              <img src="/img/signin.svg" alt="" width="100%" />
              <span
                css={css`
                  color: #777;
                  font-size: 10px;
                `}
              >
                Illustration by Stories by{' '}
                <a
                  href="https://stories.freepik.com/"
                  target="_blank"
                  css={css`
                    color: #777;
                  `}
                  rel="noreferrer"
                >
                  Freepik
                </a>
              </span>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              css={css`
                width: 100%;
              `}
            >
              <Grid container alignItems="center">
                <HomeRoudedIcon />
                <h2>Locaop予約にログイン</h2>
              </Grid>
              <Paper className={classes.paper}>
                <form onSubmit={handleSubmit}>
                  <TextField
                    label="メールアドレス"
                    placeholder="yamada@example.com"
                    type="email"
                    value={form.email}
                    name="email"
                    onChange={handleChangeEmail}
                    margin="normal"
                    fullWidth
                    required
                  />
                  <TextField
                    label="パスワード"
                    placeholder=""
                    type="password"
                    name="password"
                    value={form.password}
                    onChange={handleChangePassword}
                    margin="normal"
                    fullWidth
                    required
                  />
                  <Grid container spacing={3} alignItems="center">
                    <Grid item xs={6} />
                    <Grid
                      item
                      container
                      xs={6}
                      alignItems="center"
                      justifyContent="flex-end"
                    >
                      <Button type="submit" variant="contained" color="primary">
                        &nbsp;ログイン&nbsp;
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              </Paper>
              <Box
                css={css`
                  padding: 10px;
                  text-align: center;
                `}
              >
                <LinkRouter to={`/reminder`}>
                  パスワードを忘れた方はこちら
                </LinkRouter>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Main>
    </Root>
  );
}
